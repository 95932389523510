const arkade_brawler_bears_metadata = {
    "1303": {
        "name": "Brawler Bears #1303",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1303.png",
        "dna": "4c26c697ca9d2a2cb07725481664387b1513e03e",
        "edition": "1303",
        "type": "Brown Bear",
        "date": 1677693997485,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1303",
        "rarity_score": 365.78400459957334,
        "rarity_level": "RARE"
    },
    "469": {
        "name": "Brawler Bears #469",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/469.png",
        "dna": "5c0e9f053f63004fd3395a6e5515d9c340de318d",
        "edition": "469",
        "type": "Galactic Cyborg",
        "date": 1677693998429,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 64,
        "maxHealth": 367,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "469",
        "rarity_score": 224.0130250551076,
        "rarity_level": "COMMON"
    },
    "274": {
        "name": "Brawler Bears #274",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/274.png",
        "dna": "c463cd09c9de02f810bd1c7a3eb4a35a6dfdb200",
        "edition": "274",
        "type": "Black Bear",
        "date": 1677694000044,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 27,
        "maxHealth": 156,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "274",
        "rarity_score": 249.63404596929269,
        "rarity_level": "COMMON"
    },
    "1882": {
        "name": "Brawler Bears #1882",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1882.png",
        "dna": "18278c906de0fb93ac8c6a7eabbd290f6b7aa8be",
        "edition": "1882",
        "type": "Brown Bear",
        "date": 1677694001377,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1882",
        "rarity_score": 444.8905059369031,
        "rarity_level": "EPIC"
    },
    "1104": {
        "name": "Brawler Bears #1104",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1104.png",
        "dna": "78183ff394d6d1a8c50d98872f6023ba123ee7e5",
        "edition": "1104",
        "type": "Acid Trip",
        "date": 1677694002395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 35,
        "maxHealth": 202,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1104",
        "rarity_score": 387.84656087133055,
        "rarity_level": "RARE"
    },
    "1717": {
        "name": "Brawler Bears #1717",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1717.png",
        "dna": "92b4dc91dbf301583a38962b45c453e667e53f74",
        "edition": "1717",
        "type": "Brown Bear",
        "date": 1677694003915,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 27,
        "maxHealth": 159,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1717",
        "rarity_score": 280.7581223511026,
        "rarity_level": "RARE"
    },
    "266": {
        "name": "Brawler Bears #266",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/266.png",
        "dna": "32d1f92180f816759dc9d48594325dad9729d378",
        "edition": "266",
        "type": "Ice",
        "date": 1677694004841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 41,
        "maxHealth": 236,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "266",
        "rarity_score": 212.07334210464907,
        "rarity_level": "COMMON"
    },
    "1744": {
        "name": "Brawler Bears #1744",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1744.png",
        "dna": "0174ab099ca6669be5a0c5457c01a37022759bfe",
        "edition": "1744",
        "type": "Black Bear",
        "date": 1677694006057,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1744",
        "rarity_score": 208.40693492158294,
        "rarity_level": "COMMON"
    },
    "1197": {
        "name": "Brawler Bears #1197",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1197.png",
        "dna": "f95786b264b128a467875bdfba960f8202241812",
        "edition": "1197",
        "type": "Ice",
        "date": 1677694007077,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1197",
        "rarity_score": 439.28843130553656,
        "rarity_level": "EPIC"
    },
    "1136": {
        "name": "Brawler Bears #1136",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1136.png",
        "dna": "0d1de75c38b1eba3de4b9fbf16cdc6a64be7b998",
        "edition": "1136",
        "type": "Galaxy",
        "date": 1677694008057,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 48,
        "maxHealth": 275,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1136",
        "rarity_score": 267.8931178049141,
        "rarity_level": "COMMON"
    },
    "211": {
        "name": "Brawler Bears #211",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/211.png",
        "dna": "aaa6da96ccd123d554dc71c829eb61d0bede195e",
        "edition": "211",
        "type": "Brown Bear",
        "date": 1677694009060,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "211",
        "rarity_score": 300.4842589762155,
        "rarity_level": "RARE"
    },
    "1658": {
        "name": "Brawler Bears #1658",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1658.png",
        "dna": "e7d454c06f2fb5ed708a000921727bc5802d44d9",
        "edition": "1658",
        "type": "Black Bear",
        "date": 1677694010577,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 69,
        "maxHealth": 394,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1658",
        "rarity_score": 255.766660155406,
        "rarity_level": "COMMON"
    },
    "2236": {
        "name": "Brawler Bears #2236",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2236.png",
        "dna": "c5101a2a0c2f1f3475c1178b59304a9ef626d9ea",
        "edition": "2236",
        "type": "Cheetah",
        "date": 1677694012251,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "2236",
        "rarity_score": 535.6335007367617,
        "rarity_level": "LEGENDARY"
    },
    "2307": {
        "name": "Brawler Bears #2307",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2307.png",
        "dna": "51ac545a007c35d904a2fe2827e1792a6f4dfcb7",
        "edition": "2307",
        "type": "Brown Bear",
        "date": 1677694013653,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "2307",
        "rarity_score": 427.3120814234804,
        "rarity_level": "EPIC"
    },
    "573": {
        "name": "Brawler Bears #573",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/573.png",
        "dna": "f35c25cd4fcc2bba416d9f9c187c68e73ba49578",
        "edition": "573",
        "type": "Galactic Cyborg",
        "date": 1677694015110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "573",
        "rarity_score": 426.8636171962996,
        "rarity_level": "EPIC"
    },
    "350": {
        "name": "Brawler Bears #350",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/350.png",
        "dna": "0c3902c6b21418898292dee7caf9cf56e68a0e8d",
        "edition": "350",
        "type": "Acid Trip",
        "date": 1677694016065,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "350",
        "rarity_score": 227.57140321373174,
        "rarity_level": "COMMON"
    },
    "601": {
        "name": "Brawler Bears #601",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/601.png",
        "dna": "fd6dbe70c562957fda9cfb8517fea4605253bb8c",
        "edition": "601",
        "type": "Galaxy",
        "date": 1677694017119,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 17,
        "maxHealth": 100,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "601",
        "rarity_score": 431.1932835280712,
        "rarity_level": "EPIC"
    },
    "2321": {
        "name": "Brawler Bears #2321",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2321.png",
        "dna": "db17c0a1c19dc1d6d01fb24fb8fba7c55471ab18",
        "edition": "2321",
        "type": "Galactic Cyborg",
        "date": 1677694017889,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "2321",
        "rarity_score": 461.2648139404439,
        "rarity_level": "EPIC"
    },
    "1176": {
        "name": "Brawler Bears #1176",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1176.png",
        "dna": "3d7360cdc1a9bb05e45d0823316a537ef5e26e4d",
        "edition": "1176",
        "type": "Lava",
        "date": 1677694018983,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1176",
        "rarity_score": 404.5858840085907,
        "rarity_level": "RARE"
    },
    "857": {
        "name": "Brawler Bears #857",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/857.png",
        "dna": "8b310783a0b42ff4e1a1e1cb00e047ebf2f5c18e",
        "edition": "857",
        "type": "Brown Bear",
        "date": 1677694020197,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "857",
        "rarity_score": 392.80458906777386,
        "rarity_level": "RARE"
    },
    "131": {
        "name": "Brawler Bears #131",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/131.png",
        "dna": "ea2a45a401c956c785af2a052891a62190fbf4f0",
        "edition": "131",
        "type": "Panda",
        "date": 1677694021535,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "131",
        "rarity_score": 409.3376498389156,
        "rarity_level": "RARE"
    },
    "1921": {
        "name": "Brawler Bears #1921",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1921.png",
        "dna": "13da00e954d96d899bc84efb5b27fb98fc7f5189",
        "edition": "1921",
        "type": "Black Bear",
        "date": 1677694022293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1921",
        "rarity_score": 420.3235075302289,
        "rarity_level": "EPIC"
    },
    "2163": {
        "name": "Brawler Bears #2163",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2163.png",
        "dna": "fe692b8671640a9d126f815cb77396a39179b0c5",
        "edition": "2163",
        "type": "Deep Martian Cyborg",
        "date": 1677694023216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2163",
        "rarity_score": 251.03532493448608,
        "rarity_level": "COMMON"
    },
    "2086": {
        "name": "Brawler Bears #2086",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2086.png",
        "dna": "56b92fbe12c587fde242b35d5272ea1a114b9fec",
        "edition": "2086",
        "type": "Brown Bear",
        "date": 1677694023942,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2086",
        "rarity_score": 402.3227148963139,
        "rarity_level": "RARE"
    },
    "2151": {
        "name": "Brawler Bears #2151",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2151.png",
        "dna": "945c3ea232dafaa117d01a21324381e00af3bd04",
        "edition": "2151",
        "type": "Brown Bear",
        "date": 1677694025617,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            { "trait_type": "Head", "value": "Officer", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "2151",
        "rarity_score": 452.23430532243515,
        "rarity_level": "EPIC"
    },
    "1948": {
        "name": "Brawler Bears #1948",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1948.png",
        "dna": "6c9b0832516f6452b899e59b86f0cd50cfe61716",
        "edition": "1948",
        "type": "Galactic Cyborg",
        "date": 1677694026544,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 17,
        "maxHealth": 102,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1948",
        "rarity_score": 381.998932165029,
        "rarity_level": "RARE"
    },
    "972": {
        "name": "Brawler Bears #972",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/972.png",
        "dna": "6ebb2ff02d09145423ec259b93ecdc73377d1bc6",
        "edition": "972",
        "type": "Ice",
        "date": 1677694027391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "972",
        "rarity_score": 317.04764268963515,
        "rarity_level": "RARE"
    },
    "1402": {
        "name": "Brawler Bears #1402",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1402.png",
        "dna": "8887a186c3a1a00f03a4aedf26fadd29d2f38122",
        "edition": "1402",
        "type": "Lava",
        "date": 1677694028424,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 40,
        "maxHealth": 230,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1402",
        "rarity_score": 254.51615250438627,
        "rarity_level": "COMMON"
    },
    "2253": {
        "name": "Brawler Bears #2253",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2253.png",
        "dna": "a3c9adb9adcb605a9bb2adf7367ab340edaf6329",
        "edition": "2253",
        "type": "Black Bear",
        "date": 1677694029241,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2253",
        "rarity_score": 449.95926648254294,
        "rarity_level": "EPIC"
    },
    "775": {
        "name": "Brawler Bears #775",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/775.png",
        "dna": "29befe796a6534bc8eb017b7cc3a5a9921b9b06e",
        "edition": "775",
        "type": "Ice",
        "date": 1677694030392,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "775",
        "rarity_score": 403.49009367767985,
        "rarity_level": "RARE"
    },
    "1419": {
        "name": "Brawler Bears #1419",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1419.png",
        "dna": "65eacc2da2336d9bd1a874c8a3525bc5f97f760a",
        "edition": "1419",
        "type": "Acid Trip",
        "date": 1677694032323,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 51,
        "maxHealth": 296,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1419",
        "rarity_score": 278.29371621331677,
        "rarity_level": "RARE"
    },
    "1142": {
        "name": "Brawler Bears #1142",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1142.png",
        "dna": "598c97fe0e8183a317987f6f12b0a9b3f652cfb4",
        "edition": "1142",
        "type": "Black Bear",
        "date": 1677694033752,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1142",
        "rarity_score": 263.7553789030136,
        "rarity_level": "COMMON"
    },
    "2158": {
        "name": "Brawler Bears #2158",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2158.png",
        "dna": "c0977217a26f0e74e88fdb8d1044874d740e6585",
        "edition": "2158",
        "type": "Brown Bear",
        "date": 1677694034888,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "2158",
        "rarity_score": 339.86278268715466,
        "rarity_level": "RARE"
    },
    "1154": {
        "name": "Brawler Bears #1154",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1154.png",
        "dna": "f5038632d003b0ebd49488069eebeb74e0f96b78",
        "edition": "1154",
        "type": "Black Bear",
        "date": 1677694036028,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1154",
        "rarity_score": 350.85362073110974,
        "rarity_level": "RARE"
    },
    "175": {
        "name": "Brawler Bears #175",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/175.png",
        "dna": "98c067412e3a64cdfee3c9fb4d55e810460fc78d",
        "edition": "175",
        "type": "Lava",
        "date": 1677694037714,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 18,
        "maxHealth": 107,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "175",
        "rarity_score": 294.09988152312275,
        "rarity_level": "RARE"
    },
    "1446": {
        "name": "Brawler Bears #1446",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1446.png",
        "dna": "43d61264dea2681710fb651cbc21b2c7cde71710",
        "edition": "1446",
        "type": "Brown Bear",
        "date": 1677694038477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1446",
        "rarity_score": 328.3289810053595,
        "rarity_level": "RARE"
    },
    "738": {
        "name": "Brawler Bears #738",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/738.png",
        "dna": "ff2e67816553db1d7bb4693893b7e383ff85cd4c",
        "edition": "738",
        "type": "Black Bear",
        "date": 1677694039165,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "738",
        "rarity_score": 418.94055452284556,
        "rarity_level": "EPIC"
    },
    "915": {
        "name": "Brawler Bears #915",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/915.png",
        "dna": "34ff55d1290aa15a2b006121325dde6329800a77",
        "edition": "915",
        "type": "Ice",
        "date": 1677694040601,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "915",
        "rarity_score": 441.30790186312277,
        "rarity_level": "EPIC"
    },
    "1943": {
        "name": "Brawler Bears #1943",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1943.png",
        "dna": "6d5bd0637aeaeb7dbdcaf190a2d579bfb23d97a5",
        "edition": "1943",
        "type": "Brown Bear",
        "date": 1677694041663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 27,
        "maxHealth": 158,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1943",
        "rarity_score": 251.74690684268475,
        "rarity_level": "COMMON"
    },
    "1065": {
        "name": "Brawler Bears #1065",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1065.png",
        "dna": "aca8c3da45be588cf8c64628f5b112c1e1b6ed0b",
        "edition": "1065",
        "type": "Brown Bear",
        "date": 1677694042817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 44,
        "maxHealth": 251,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1065",
        "rarity_score": 332.54149883023996,
        "rarity_level": "RARE"
    },
    "399": {
        "name": "Brawler Bears #399",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/399.png",
        "dna": "6767fe260b13216c45f4005ce0d1f0445031160b",
        "edition": "399",
        "type": "Lava",
        "date": 1677694044093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 13,
        "maxHealth": 75,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "399",
        "rarity_score": 399.42092877235285,
        "rarity_level": "RARE"
    },
    "1581": {
        "name": "Brawler Bears #1581",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1581.png",
        "dna": "ae03c681f4de832892131c85151dcbdc5d6214ef",
        "edition": "1581",
        "type": "Galactic Cyborg",
        "date": 1677694045141,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 22,
        "maxHealth": 125,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1581",
        "rarity_score": 256.0937821514367,
        "rarity_level": "COMMON"
    },
    "1217": {
        "name": "Brawler Bears #1217",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1217.png",
        "dna": "928b03720f1f15608a50a188ec54cdd2219086f9",
        "edition": "1217",
        "type": "Ice",
        "date": 1677694046070,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1217",
        "rarity_score": 360.9744516286131,
        "rarity_level": "RARE"
    },
    "864": {
        "name": "Brawler Bears #864",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/864.png",
        "dna": "5160a74d157011168979b7653caa1b1f0d2f8f3c",
        "edition": "864",
        "type": "Lava",
        "date": 1677694047255,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "864",
        "rarity_score": 381.1533277340596,
        "rarity_level": "RARE"
    },
    "1073": {
        "name": "Brawler Bears #1073",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1073.png",
        "dna": "4df00ca2a17a080ea9ab900e18cb1cea16fa6c22",
        "edition": "1073",
        "type": "Acid Trip",
        "date": 1677694048349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1073",
        "rarity_score": 399.6116913549771,
        "rarity_level": "RARE"
    },
    "308": {
        "name": "Brawler Bears #308",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/308.png",
        "dna": "5d5d470c152b0959f063ccc94582355d3a9e76b6",
        "edition": "308",
        "type": "Lava",
        "date": 1677694049230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "308",
        "rarity_score": 362.71689868688844,
        "rarity_level": "RARE"
    },
    "1585": {
        "name": "Brawler Bears #1585",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1585.png",
        "dna": "e6eba6af9c07ad325979057b488d0a8265ef7fe2",
        "edition": "1585",
        "type": "Cheetah",
        "date": 1677694050147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 28,
        "maxHealth": 160,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1585",
        "rarity_score": 199.49911660258692,
        "rarity_level": "COMMON"
    },
    "565": {
        "name": "Brawler Bears #565",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/565.png",
        "dna": "cc43912394fcf3207f7f1a67c98e95643ff18fa1",
        "edition": "565",
        "type": "Acid Trip",
        "date": 1677694051846,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "565",
        "rarity_score": 255.87989347648926,
        "rarity_level": "COMMON"
    },
    "2131": {
        "name": "Brawler Bears #2131",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2131.png",
        "dna": "85810eb133acf314896eafa6d9faca1107d09c9d",
        "edition": "2131",
        "type": "Galactic Cyborg",
        "date": 1677694052773,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 33,
        "maxHealth": 188,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "2131",
        "rarity_score": 444.86217069361913,
        "rarity_level": "EPIC"
    },
    "632": {
        "name": "Brawler Bears #632",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/632.png",
        "dna": "96fd81c3fa8c03793cc34a3cbc5c50b8bc987611",
        "edition": "632",
        "type": "Panda",
        "date": 1677694053858,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 20,
        "maxHealth": 116,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "632",
        "rarity_score": 251.7454226537702,
        "rarity_level": "COMMON"
    },
    "279": {
        "name": "Brawler Bears #279",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/279.png",
        "dna": "12045bcc1b3da78a7daf372e144722889208cfec",
        "edition": "279",
        "type": "Deep Martian Cyborg",
        "date": 1677694054585,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "279",
        "rarity_score": 479.0896363568129,
        "rarity_level": "EPIC"
    },
    "1767": {
        "name": "Brawler Bears #1767",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1767.png",
        "dna": "1226362941ee8a0efd9a15854c6c7705a386d457",
        "edition": "1767",
        "type": "Brown Bear",
        "date": 1677694055409,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1767",
        "rarity_score": 383.9462719988445,
        "rarity_level": "RARE"
    },
    "2305": {
        "name": "Brawler Bears #2305",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2305.png",
        "dna": "0b0dcb0082aaf353d669b994f57401a0d709800b",
        "edition": "2305",
        "type": "Deep Martian Cyborg",
        "date": 1677694056155,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2305",
        "rarity_score": 354.25248217290834,
        "rarity_level": "RARE"
    },
    "386": {
        "name": "Brawler Bears #386",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/386.png",
        "dna": "2944fbd677e5d1a75dae24053619dd563ef95df8",
        "edition": "386",
        "type": "Brown Bear",
        "date": 1677694056911,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 60,
        "maxHealth": 344,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "386",
        "rarity_score": 333.5167626239676,
        "rarity_level": "RARE"
    },
    "329": {
        "name": "Brawler Bears #329",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/329.png",
        "dna": "be35624ceb30cbe175f6ed4ae6eb42cf7d89fc83",
        "edition": "329",
        "type": "Black Bear",
        "date": 1677694057782,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "329",
        "rarity_score": 263.58786999605474,
        "rarity_level": "COMMON"
    },
    "2186": {
        "name": "Brawler Bears #2186",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2186.png",
        "dna": "a29b2e8ddea26a811972edb483a602aff7f0ef53",
        "edition": "2186",
        "type": "Brown Bear",
        "date": 1677694058527,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 48,
        "maxHealth": 277,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2186",
        "rarity_score": 224.56728864847588,
        "rarity_level": "COMMON"
    },
    "749": {
        "name": "Brawler Bears #749",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/749.png",
        "dna": "49c9fda396b4796bad7d22751cb3228cabd4f37d",
        "edition": "749",
        "type": "Brown Bear",
        "date": 1677694059368,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "General",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 69,
        "maxHealth": 397,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "749",
        "rarity_score": 367.1783192373625,
        "rarity_level": "RARE"
    },
    "865": {
        "name": "Brawler Bears #865",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/865.png",
        "dna": "d61017ed227859dcc1c305cc05961a3863293742",
        "edition": "865",
        "type": "Black Bear",
        "date": 1677694060511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "865",
        "rarity_score": 334.2728340453137,
        "rarity_level": "RARE"
    },
    "1491": {
        "name": "Brawler Bears #1491",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1491.png",
        "dna": "aeec33693273daec879d47593d20cd874d479cef",
        "edition": "1491",
        "type": "Brown Bear",
        "date": 1677694061566,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1491",
        "rarity_score": 257.0640074141531,
        "rarity_level": "COMMON"
    },
    "1249": {
        "name": "Brawler Bears #1249",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1249.png",
        "dna": "2683d8695aa3dd212302b906830fbe23e4960eed",
        "edition": "1249",
        "type": "Panda",
        "date": 1677694062672,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1249",
        "rarity_score": 389.370428523726,
        "rarity_level": "RARE"
    },
    "83": {
        "name": "Brawler Bears #83",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/83.png",
        "dna": "988f3809816bab4a73cd8e5d0158f1ce09ae1065",
        "edition": "83",
        "type": "Galactic Cyborg",
        "date": 1677694063935,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "83",
        "rarity_score": 226.27795871520732,
        "rarity_level": "COMMON"
    },
    "2030": {
        "name": "Brawler Bears #2030",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2030.png",
        "dna": "0142f88173338b2c424e735e665f55ea058bd7e2",
        "edition": "2030",
        "type": "Lava",
        "date": 1677694065989,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2030",
        "rarity_score": 426.09107811814,
        "rarity_level": "EPIC"
    },
    "599": {
        "name": "Brawler Bears #599",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/599.png",
        "dna": "b2eb7881ba52211d967176f1f144a2ec4cf5d3c0",
        "edition": "599",
        "type": "Acid Trip",
        "date": 1677694067961,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "599",
        "rarity_score": 436.4155031436856,
        "rarity_level": "EPIC"
    },
    "1425": {
        "name": "Brawler Bears #1425",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1425.png",
        "dna": "0a19ab4059d49b3091bf8dd9f9d52205d30c1a0c",
        "edition": "1425",
        "type": "Panda",
        "date": 1677694069233,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1425",
        "rarity_score": 406.27556778128223,
        "rarity_level": "RARE"
    },
    "225": {
        "name": "Brawler Bears #225",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/225.png",
        "dna": "8b5e2d7824a462106e286d540d9b01a3aec87eaf",
        "edition": "225",
        "type": "Brown Bear",
        "date": 1677694070257,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "225",
        "rarity_score": 392.05235477469773,
        "rarity_level": "RARE"
    },
    "428": {
        "name": "Brawler Bears #428",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/428.png",
        "dna": "6a69d2ac50d5114b37d32382afd5dffa818f2b66",
        "edition": "428",
        "type": "Galaxy",
        "date": 1677694071998,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "428",
        "rarity_score": 420.6431777998078,
        "rarity_level": "EPIC"
    },
    "2100": {
        "name": "Brawler Bears #2100",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2100.png",
        "dna": "5197d4f3d5efe4cd1fdb2451528ac7efb8409ce6",
        "edition": "2100",
        "type": "Acid Trip",
        "date": 1677694073019,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 36,
        "maxHealth": 205,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2100",
        "rarity_score": 390.4442924452215,
        "rarity_level": "RARE"
    },
    "880": {
        "name": "Brawler Bears #880",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/880.png",
        "dna": "f3bdca6560da0a295703097ecff2d6c577f49013",
        "edition": "880",
        "type": "Acid Trip",
        "date": 1677694073797,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Samurai Warrior",
                "score": 398.0
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "880",
        "rarity_score": 680.5040430983873,
        "rarity_level": "LEGENDARY"
    },
    "550": {
        "name": "Brawler Bears #550",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/550.png",
        "dna": "4a0cd0dc3b97fa247f2de49c9c05cffe9b56298b",
        "edition": "550",
        "type": "Deep Martian Cyborg",
        "date": 1677694076047,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "550",
        "rarity_score": 380.99565123055396,
        "rarity_level": "RARE"
    },
    "897": {
        "name": "Brawler Bears #897",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/897.png",
        "dna": "763ae6c9fc8ca3a0548ddd04726f3ef17d932b90",
        "edition": "897",
        "type": "Acid Trip",
        "date": 1677694077070,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 30,
        "maxHealth": 173,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "897",
        "rarity_score": 401.7572770945645,
        "rarity_level": "RARE"
    },
    "1045": {
        "name": "Brawler Bears #1045",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1045.png",
        "dna": "06c478c6d2159c1de5835345ba7169dd30eeff23",
        "edition": "1045",
        "type": "Panda",
        "date": 1677694078274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 25,
        "maxHealth": 147,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1045",
        "rarity_score": 330.2439110063911,
        "rarity_level": "RARE"
    },
    "292": {
        "name": "Brawler Bears #292",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/292.png",
        "dna": "886e5528cc7108f5c7fb88619d795eb1b610d618",
        "edition": "292",
        "type": "Black Bear",
        "date": 1677694079363,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "292",
        "rarity_score": 200.8280906168767,
        "rarity_level": "COMMON"
    },
    "1280": {
        "name": "Brawler Bears #1280",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1280.png",
        "dna": "e9c7603b2f89a7b1cecfd69997fa4d23a7402c6f",
        "edition": "1280",
        "type": "Black Bear",
        "date": 1677694080466,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1280",
        "rarity_score": 188.76429737797773,
        "rarity_level": "COMMON"
    },
    "1746": {
        "name": "Brawler Bears #1746",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1746.png",
        "dna": "19dbf4516f3a66c8b9c8c48e4a2223834d948f16",
        "edition": "1746",
        "type": "Cheetah",
        "date": 1677694081588,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1746",
        "rarity_score": 400.3291324570123,
        "rarity_level": "RARE"
    },
    "852": {
        "name": "Brawler Bears #852",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/852.png",
        "dna": "14da4e0a6c2943ea4fc1d0fac167044faee5928a",
        "edition": "852",
        "type": "Acid Trip",
        "date": 1677694082442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "852",
        "rarity_score": 321.51732540192876,
        "rarity_level": "RARE"
    },
    "18": {
        "name": "Brawler Bears #18",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/18.png",
        "dna": "315487ed65b3c580d907714464dec4908c2ade2f",
        "edition": "18",
        "type": "Galactic Cyborg",
        "date": 1677694083664,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 69,
        "maxHealth": 398,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "18",
        "rarity_score": 82.77535989452699,
        "rarity_level": "COMMON"
    },
    "932": {
        "name": "Brawler Bears #932",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/932.png",
        "dna": "9c58c81643142c7c1a0d40ad6297c918060fd869",
        "edition": "932",
        "type": "Black Bear",
        "date": 1677694084673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Royalty", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 59,
        "maxHealth": 342,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "932",
        "rarity_score": 400.5189888631748,
        "rarity_level": "RARE"
    },
    "1185": {
        "name": "Brawler Bears #1185",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1185.png",
        "dna": "a25c06ae66afc98d941b28706342e194610df2d1",
        "edition": "1185",
        "type": "Deep Martian Cyborg",
        "date": 1677694085450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 53,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1185",
        "rarity_score": 296.1590881173221,
        "rarity_level": "RARE"
    },
    "1278": {
        "name": "Brawler Bears #1278",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1278.png",
        "dna": "d01f6b6ad634e69ada9c831c49ce72c3fdd4bbab",
        "edition": "1278",
        "type": "Brown Bear",
        "date": 1677694086368,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1278",
        "rarity_score": 195.12629507538563,
        "rarity_level": "COMMON"
    },
    "1194": {
        "name": "Brawler Bears #1194",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1194.png",
        "dna": "e507a9f2e84a3f8a5a856851cf6fb5a549d9c835",
        "edition": "1194",
        "type": "Ice",
        "date": 1677694087222,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1194",
        "rarity_score": 437.4278758028758,
        "rarity_level": "EPIC"
    },
    "1424": {
        "name": "Brawler Bears #1424",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1424.png",
        "dna": "efbc28e61acfcbfa2576394ff750ecef41b39939",
        "edition": "1424",
        "type": "Ice",
        "date": 1677694089152,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1424",
        "rarity_score": 480.04139901411776,
        "rarity_level": "EPIC"
    },
    "1370": {
        "name": "Brawler Bears #1370",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1370.png",
        "dna": "093dfb00c3eda727ac0ece16c36ca8f74aaa588f",
        "edition": "1370",
        "type": "Panda",
        "date": 1677694090693,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1370",
        "rarity_score": 408.89595356819063,
        "rarity_level": "RARE"
    },
    "1396": {
        "name": "Brawler Bears #1396",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1396.png",
        "dna": "54a9d904ebed6ebcefebd79e941e006f85763ccf",
        "edition": "1396",
        "type": "Black Bear",
        "date": 1677694092132,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1396",
        "rarity_score": 415.98169827595194,
        "rarity_level": "EPIC"
    },
    "1177": {
        "name": "Brawler Bears #1177",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1177.png",
        "dna": "84a3e88e82d248faf05b5786dea48023f92a68c3",
        "edition": "1177",
        "type": "Black Bear",
        "date": 1677694092950,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 42,
        "maxHealth": 245,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1177",
        "rarity_score": 212.2869736647247,
        "rarity_level": "COMMON"
    },
    "1603": {
        "name": "Brawler Bears #1603",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1603.png",
        "dna": "331ffd0338b56801b828bac6f48778649e2471f4",
        "edition": "1603",
        "type": "Black Bear",
        "date": 1677694094444,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "1603",
        "rarity_score": 439.74300659123713,
        "rarity_level": "EPIC"
    },
    "1778": {
        "name": "Brawler Bears #1778",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1778.png",
        "dna": "2b479f07d04943ea6545baf6ba5d378e0e04bba2",
        "edition": "1778",
        "type": "Deep Martian Cyborg",
        "date": 1677694095439,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Royalty", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 46,
        "maxHealth": 268,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1778",
        "rarity_score": 377.98327270452904,
        "rarity_level": "RARE"
    },
    "1236": {
        "name": "Brawler Bears #1236",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1236.png",
        "dna": "d746be0edb11e5a2655c76ba1c745d1d9972eff8",
        "edition": "1236",
        "type": "Acid Trip",
        "date": 1677694096812,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 24,
        "maxHealth": 139,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1236",
        "rarity_score": 297.44182466585374,
        "rarity_level": "RARE"
    },
    "1514": {
        "name": "Brawler Bears #1514",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1514.png",
        "dna": "0305ca247b7deb70445447c58815e77ccabbc2b6",
        "edition": "1514",
        "type": "Brown Bear",
        "date": 1677694098081,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1514",
        "rarity_score": 404.261651539518,
        "rarity_level": "RARE"
    },
    "1483": {
        "name": "Brawler Bears #1483",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1483.png",
        "dna": "fe720c6dd46d98c26c964ffa446f2c3849e5effd",
        "edition": "1483",
        "type": "Black Bear",
        "date": 1677694098961,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1483",
        "rarity_score": 174.24319370679365,
        "rarity_level": "COMMON"
    },
    "162": {
        "name": "Brawler Bears #162",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/162.png",
        "dna": "8bed1b5231d6f486b6811ab3aa4bb26767adf4e5",
        "edition": "162",
        "type": "Ice",
        "date": 1677694100121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "162",
        "rarity_score": 465.6937976180143,
        "rarity_level": "EPIC"
    },
    "1416": {
        "name": "Brawler Bears #1416",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1416.png",
        "dna": "d5702d9a0592c9ac3bc1a73901cfdcdb2abca4e5",
        "edition": "1416",
        "type": "Black Bear",
        "date": 1677694101063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 63,
        "maxHealth": 364,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1416",
        "rarity_score": 220.18278630413153,
        "rarity_level": "COMMON"
    },
    "1237": {
        "name": "Brawler Bears #1237",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1237.png",
        "dna": "6736800541d8e5df9d66853b17998a8aa81bead0",
        "edition": "1237",
        "type": "Ice",
        "date": 1677694102195,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1237",
        "rarity_score": 349.066647232441,
        "rarity_level": "RARE"
    },
    "2137": {
        "name": "Brawler Bears #2137",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2137.png",
        "dna": "d54ac627308c7bac231ca7f5109eec654211fdf5",
        "edition": "2137",
        "type": "Galaxy",
        "date": 1677694103453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2137",
        "rarity_score": 290.67317625009383,
        "rarity_level": "RARE"
    },
    "2270": {
        "name": "Brawler Bears #2270",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2270.png",
        "dna": "95efe7b6f90a92f8f4a4821eaad60f0178917344",
        "edition": "2270",
        "type": "Deep Martian Cyborg",
        "date": 1677694104841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 15,
        "maxHealth": 91,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2270",
        "rarity_score": 466.57096632944786,
        "rarity_level": "EPIC"
    },
    "746": {
        "name": "Brawler Bears #746",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/746.png",
        "dna": "20b634e3425835c8058d5711c4f2bec5daab8da2",
        "edition": "746",
        "type": "Brown Bear",
        "date": 1677694105955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "746",
        "rarity_score": 407.1397777895129,
        "rarity_level": "RARE"
    },
    "1586": {
        "name": "Brawler Bears #1586",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1586.png",
        "dna": "a80ffc34f64449264c1269936af48306d673e881",
        "edition": "1586",
        "type": "Panda",
        "date": 1677694106994,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1586",
        "rarity_score": 452.8405368469833,
        "rarity_level": "EPIC"
    },
    "1023": {
        "name": "Brawler Bears #1023",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1023.png",
        "dna": "83f6f1f115da9399e392e854e7a358e8b877a040",
        "edition": "1023",
        "type": "Brown Bear",
        "date": 1677694108189,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 59,
        "maxHealth": 340,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1023",
        "rarity_score": 337.94804519728666,
        "rarity_level": "RARE"
    },
    "792": {
        "name": "Brawler Bears #792",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/792.png",
        "dna": "040262fc309098fc47bf508ad66cc88f709a1326",
        "edition": "792",
        "type": "Black Bear",
        "date": 1677694109860,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 27,
        "maxHealth": 156,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "792",
        "rarity_score": 254.5387437724867,
        "rarity_level": "COMMON"
    },
    "1878": {
        "name": "Brawler Bears #1878",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1878.png",
        "dna": "e8022efbc2ae5bd705f58135ea24d7dcd4422ed5",
        "edition": "1878",
        "type": "Ice",
        "date": 1677694111377,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1878",
        "rarity_score": 368.8482185600098,
        "rarity_level": "RARE"
    },
    "906": {
        "name": "Brawler Bears #906",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/906.png",
        "dna": "8b0523f17090f54dac5d1414fb6866c85ce1d6c6",
        "edition": "906",
        "type": "Brown Bear",
        "date": 1677694112954,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 22,
        "maxHealth": 125,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "906",
        "rarity_score": 460.3069185170394,
        "rarity_level": "EPIC"
    },
    "1356": {
        "name": "Brawler Bears #1356",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1356.png",
        "dna": "f9da34737ca1fa3ad9de4b843634096fad1bc24a",
        "edition": "1356",
        "type": "Lava",
        "date": 1677694114404,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1356",
        "rarity_score": 368.2447828066463,
        "rarity_level": "RARE"
    },
    "911": {
        "name": "Brawler Bears #911",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/911.png",
        "dna": "d257645408cf66f28410e9432eed1808f0db6ed2",
        "edition": "911",
        "type": "Galaxy",
        "date": 1677694115491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "911",
        "rarity_score": 364.7364286318945,
        "rarity_level": "RARE"
    },
    "1009": {
        "name": "Brawler Bears #1009",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1009.png",
        "dna": "047b8f5201d9b33c64afb4deea36ed3560f5037f",
        "edition": "1009",
        "type": "Galaxy",
        "date": 1677694116284,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            { "trait_type": "Head", "value": "Bandit", "score": 398.0 },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1009",
        "rarity_score": 665.3745727199846,
        "rarity_level": "LEGENDARY"
    },
    "755": {
        "name": "Brawler Bears #755",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/755.png",
        "dna": "e450594df4530d90074307fceeba9cf36ad75a19",
        "edition": "755",
        "type": "Black Bear",
        "date": 1677694117158,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 39,
        "maxHealth": 222,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "755",
        "rarity_score": 357.7979126702031,
        "rarity_level": "RARE"
    },
    "2280": {
        "name": "Brawler Bears #2280",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2280.png",
        "dna": "f3213389fb402667280befc5a148032c59c63346",
        "edition": "2280",
        "type": "Deep Martian Cyborg",
        "date": 1677694118177,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 20,
        "maxHealth": 117,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2280",
        "rarity_score": 321.44960001468917,
        "rarity_level": "RARE"
    },
    "982": {
        "name": "Brawler Bears #982",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/982.png",
        "dna": "f0c3a5c467efeebedaa4af063f0260b046756f30",
        "edition": "982",
        "type": "Lava",
        "date": 1677694119527,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "982",
        "rarity_score": 400.19358204484763,
        "rarity_level": "RARE"
    },
    "706": {
        "name": "Brawler Bears #706",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/706.png",
        "dna": "79dd88c7f6ac12123d3187601ad1712b0a920dc5",
        "edition": "706",
        "type": "Brown Bear",
        "date": 1677694120570,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "706",
        "rarity_score": 407.83987942768147,
        "rarity_level": "RARE"
    },
    "250": {
        "name": "Brawler Bears #250",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/250.png",
        "dna": "886c836296b25799b04400665b8e5f525809b353",
        "edition": "250",
        "type": "Brown Bear",
        "date": 1677694121947,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 23,
        "maxHealth": 131,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "250",
        "rarity_score": 436.1928582689877,
        "rarity_level": "EPIC"
    },
    "531": {
        "name": "Brawler Bears #531",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/531.png",
        "dna": "de43257677b1e5ad244d57fea80cd15f2f4d9190",
        "edition": "531",
        "type": "Brown Bear",
        "date": 1677694122846,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 60,
        "maxHealth": 344,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "531",
        "rarity_score": 343.32333741126735,
        "rarity_level": "RARE"
    },
    "1001": {
        "name": "Brawler Bears #1001",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1001.png",
        "dna": "eaeede6dcac9438a0845b41517e3cc50e26ce7c6",
        "edition": "1001",
        "type": "Deep Martian Cyborg",
        "date": 1677694123922,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1001",
        "rarity_score": 446.9386272223096,
        "rarity_level": "EPIC"
    },
    "571": {
        "name": "Brawler Bears #571",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/571.png",
        "dna": "8ce951cb9dc12d8b47b457baca2751c67ef2db7e",
        "edition": "571",
        "type": "Panda",
        "date": 1677694124823,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 19,
        "maxHealth": 113,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "571",
        "rarity_score": 320.1245389762992,
        "rarity_level": "RARE"
    },
    "233": {
        "name": "Brawler Bears #233",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/233.png",
        "dna": "1f9a1e732f600b28bae89b1853a145aa8ca12f83",
        "edition": "233",
        "type": "Brown Bear",
        "date": 1677694125589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 58,
        "maxHealth": 332,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "233",
        "rarity_score": 139.67949057548728,
        "rarity_level": "COMMON"
    },
    "1982": {
        "name": "Brawler Bears #1982",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1982.png",
        "dna": "6aade5ed35763ba70079c862f7df38f04447b940",
        "edition": "1982",
        "type": "Acid Trip",
        "date": 1677694126455,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1982",
        "rarity_score": 236.80560656942083,
        "rarity_level": "COMMON"
    },
    "620": {
        "name": "Brawler Bears #620",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/620.png",
        "dna": "7e996cac6df89dfbb510ba3667875ef0bcb594bc",
        "edition": "620",
        "type": "Lava",
        "date": 1677694127349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 55,
        "maxHealth": 317,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "620",
        "rarity_score": 181.49744625215698,
        "rarity_level": "COMMON"
    },
    "604": {
        "name": "Brawler Bears #604",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/604.png",
        "dna": "d781a34282b122b1941d94e1b1c4331721594b50",
        "edition": "604",
        "type": "Cheetah",
        "date": 1677694129155,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "604",
        "rarity_score": 152.45688034960727,
        "rarity_level": "COMMON"
    },
    "1288": {
        "name": "Brawler Bears #1288",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1288.png",
        "dna": "ea7d1cc1308ae22fa1ebfa49d2c358758de85b58",
        "edition": "1288",
        "type": "Black Bear",
        "date": 1677694129808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 58,
        "maxHealth": 335,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1288",
        "rarity_score": 333.6930269400751,
        "rarity_level": "RARE"
    },
    "798": {
        "name": "Brawler Bears #798",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/798.png",
        "dna": "7d4483c472ac3127d1c23ea517a4f416aad0eff7",
        "edition": "798",
        "type": "Ice",
        "date": 1677694130808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "798",
        "rarity_score": 408.59551095783974,
        "rarity_level": "RARE"
    },
    "1766": {
        "name": "Brawler Bears #1766",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1766.png",
        "dna": "ae92312ae939063f17acb945b03075b0b7cc92a2",
        "edition": "1766",
        "type": "Deep Martian Cyborg",
        "date": 1677694131616,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1766",
        "rarity_score": 425.13145636825897,
        "rarity_level": "EPIC"
    },
    "618": {
        "name": "Brawler Bears #618",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/618.png",
        "dna": "90c5150c95a80140390fb08484e91ca85665a8ce",
        "edition": "618",
        "type": "Panda",
        "date": 1677694133173,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Fire Dept",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 24,
        "maxHealth": 139,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "618",
        "rarity_score": 472.4224365098032,
        "rarity_level": "EPIC"
    },
    "1": {
        "name": "Brawler Bears #1",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1.png",
        "dna": "9c001d30dece95dea09db97e2fa3b763d175fe50",
        "edition": "1",
        "type": "Black Bear",
        "date": 1677694133835,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1",
        "rarity_score": 361.89666111990135,
        "rarity_level": "RARE"
    },
    "523": {
        "name": "Brawler Bears #523",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/523.png",
        "dna": "4bddcf88e829a686a9c5fca3a3b8435fcdcee6a7",
        "edition": "523",
        "type": "Brown Bear",
        "date": 1677694134556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "523",
        "rarity_score": 230.15244026738813,
        "rarity_level": "COMMON"
    },
    "41": {
        "name": "Brawler Bears #41",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/41.png",
        "dna": "34829c05f7925645582e9ae7ef0e835d881f9cc2",
        "edition": "41",
        "type": "Acid Trip",
        "date": 1677694136267,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 45,
        "maxHealth": 261,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "41",
        "rarity_score": 284.3029261508531,
        "rarity_level": "RARE"
    },
    "240": {
        "name": "Brawler Bears #240",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/240.png",
        "dna": "8e87becd911cd5aea93da681facffe916f4d4bb8",
        "edition": "240",
        "type": "Brown Bear",
        "date": 1677694137251,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "240",
        "rarity_score": 428.0627677012566,
        "rarity_level": "EPIC"
    },
    "158": {
        "name": "Brawler Bears #158",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/158.png",
        "dna": "10ab09cb2c83077fa6a69e7b0c164bf7cb1a8360",
        "edition": "158",
        "type": "Brown Bear",
        "date": 1677694138365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 47,
        "maxHealth": 272,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "158",
        "rarity_score": 236.29459463546377,
        "rarity_level": "COMMON"
    },
    "941": {
        "name": "Brawler Bears #941",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/941.png",
        "dna": "bc152f27baa45c61824160db21c97e418d845aa5",
        "edition": "941",
        "type": "Cheetah",
        "date": 1677694139152,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 30,
        "maxHealth": 174,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "941",
        "rarity_score": 393.08134757527307,
        "rarity_level": "RARE"
    },
    "147": {
        "name": "Brawler Bears #147",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/147.png",
        "dna": "7c49b8ffeccaf0f70de4f44c37e3f8c4181bad66",
        "edition": "147",
        "type": "Black Bear",
        "date": 1677694139955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 53,
        "maxHealth": 308,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "147",
        "rarity_score": 279.2297643825136,
        "rarity_level": "RARE"
    },
    "1324": {
        "name": "Brawler Bears #1324",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1324.png",
        "dna": "94cdc91ba838eb2b96afcfadf4659efb94f30088",
        "edition": "1324",
        "type": "Brown Bear",
        "date": 1677694140857,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 49,
        "maxHealth": 280,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1324",
        "rarity_score": 197.58077944311776,
        "rarity_level": "COMMON"
    },
    "2256": {
        "name": "Brawler Bears #2256",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2256.png",
        "dna": "792bcd4c6772b78c7ea426f3e5d2aba32ba7d8ee",
        "edition": "2256",
        "type": "Lava",
        "date": 1677694142592,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 24,
        "maxHealth": 142,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2256",
        "rarity_score": 277.5248359504513,
        "rarity_level": "RARE"
    },
    "1919": {
        "name": "Brawler Bears #1919",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1919.png",
        "dna": "2154df0d4fba360f48027e0ecbbb3aa7b46c1e3e",
        "edition": "1919",
        "type": "Black Bear",
        "date": 1677694143416,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1919",
        "rarity_score": 379.50325198672294,
        "rarity_level": "RARE"
    },
    "290": {
        "name": "Brawler Bears #290",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/290.png",
        "dna": "9cd8875a03544684126fa25440438ec2d2952666",
        "edition": "290",
        "type": "Brown Bear",
        "date": 1677694145002,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 27,
        "maxHealth": 159,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "290",
        "rarity_score": 254.78307614304543,
        "rarity_level": "COMMON"
    },
    "1901": {
        "name": "Brawler Bears #1901",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1901.png",
        "dna": "a99adfad393dfbe6ce31d2f3f9da72c1f0929c63",
        "edition": "1901",
        "type": "Ice",
        "date": 1677694146084,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1901",
        "rarity_score": 394.133149249372,
        "rarity_level": "RARE"
    },
    "1215": {
        "name": "Brawler Bears #1215",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1215.png",
        "dna": "b1d77711a9b4ab0e2f72bb81c89ba560ed706fab",
        "edition": "1215",
        "type": "Black Bear",
        "date": 1677694146881,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 27,
        "maxHealth": 156,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1215",
        "rarity_score": 310.4462989809083,
        "rarity_level": "RARE"
    },
    "2065": {
        "name": "Brawler Bears #2065",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2065.png",
        "dna": "9584c066b9df42d607223a01412f777c6a2ee045",
        "edition": "2065",
        "type": "Brown Bear",
        "date": 1677694147934,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            { "trait_type": "Head", "value": "Chad", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "2065",
        "rarity_score": 582.4540386089658,
        "rarity_level": "LEGENDARY"
    },
    "1883": {
        "name": "Brawler Bears #1883",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1883.png",
        "dna": "49417cfd9c0e4e7c9cfdedb15cef3eb89d64e703",
        "edition": "1883",
        "type": "Cheetah",
        "date": 1677694148811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1883",
        "rarity_score": 529.1708856913125,
        "rarity_level": "LEGENDARY"
    },
    "835": {
        "name": "Brawler Bears #835",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/835.png",
        "dna": "e36a1099357ec541027b211308f417ff7f224c9b",
        "edition": "835",
        "type": "Galactic Cyborg",
        "date": 1677694150253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "835",
        "rarity_score": 386.76992518606323,
        "rarity_level": "RARE"
    },
    "1736": {
        "name": "Brawler Bears #1736",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1736.png",
        "dna": "4719d25ff4609dc761d72c915a069348837f7514",
        "edition": "1736",
        "type": "Brown Bear",
        "date": 1677694151290,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "General",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "1736",
        "rarity_score": 535.845631874148,
        "rarity_level": "LEGENDARY"
    },
    "1315": {
        "name": "Brawler Bears #1315",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1315.png",
        "dna": "cf8e2ef5ce20051816572f1e88193531272ad95e",
        "edition": "1315",
        "type": "Brown Bear",
        "date": 1677694153037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1315",
        "rarity_score": 476.56972446286954,
        "rarity_level": "EPIC"
    },
    "413": {
        "name": "Brawler Bears #413",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/413.png",
        "dna": "8af7dda70d7158c1fba3b2ce294ebe16b639b95b",
        "edition": "413",
        "type": "Galactic Cyborg",
        "date": 1677694153842,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "413",
        "rarity_score": 478.24618725081683,
        "rarity_level": "EPIC"
    },
    "37": {
        "name": "Brawler Bears #37",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/37.png",
        "dna": "25b8bfd2a59b18657b5bc42a3cf56720362fd439",
        "edition": "37",
        "type": "Galactic Cyborg",
        "date": 1677694155591,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "37",
        "rarity_score": 340.39986161077684,
        "rarity_level": "RARE"
    },
    "964": {
        "name": "Brawler Bears #964",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/964.png",
        "dna": "184b4edb225fd9846dc2ab3f2880f5e4dc2aa63d",
        "edition": "964",
        "type": "Panda",
        "date": 1677694156630,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 67,
        "maxHealth": 383,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "964",
        "rarity_score": 93.24157355752894,
        "rarity_level": "COMMON"
    },
    "2010": {
        "name": "Brawler Bears #2010",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2010.png",
        "dna": "2f0dea5e740d6c30ff543ff8640f3d7908397f9e",
        "edition": "2010",
        "type": "Panda",
        "date": 1677694157590,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 25,
        "maxHealth": 147,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "2010",
        "rarity_score": 316.6983468905628,
        "rarity_level": "RARE"
    },
    "1831": {
        "name": "Brawler Bears #1831",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1831.png",
        "dna": "16bbd0626058e6621dd66ee2b027225c7f76221f",
        "edition": "1831",
        "type": "Deep Martian Cyborg",
        "date": 1677694159212,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 58,
        "maxHealth": 335,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1831",
        "rarity_score": 173.31328478841874,
        "rarity_level": "COMMON"
    },
    "578": {
        "name": "Brawler Bears #578",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/578.png",
        "dna": "7a568d266fce43115a6d2d00555a07db749b16e1",
        "edition": "578",
        "type": "Black Bear",
        "date": 1677694160322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "578",
        "rarity_score": 408.1334402486852,
        "rarity_level": "RARE"
    },
    "1787": {
        "name": "Brawler Bears #1787",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1787.png",
        "dna": "532c025cc2e15efbf65cab530b4e49eced8ad866",
        "edition": "1787",
        "type": "Black Bear",
        "date": 1677694161329,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1787",
        "rarity_score": 382.02542369693,
        "rarity_level": "RARE"
    },
    "2237": {
        "name": "Brawler Bears #2237",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2237.png",
        "dna": "819c882e23527fa9818cdcd7fefb329504b54975",
        "edition": "2237",
        "type": "Deep Martian Cyborg",
        "date": 1677694162105,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Fire Dept",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2237",
        "rarity_score": 470.91608238175604,
        "rarity_level": "EPIC"
    },
    "1479": {
        "name": "Brawler Bears #1479",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1479.png",
        "dna": "3b237fb52bd0fdafcaf28bb9cb7798c0585be3e6",
        "edition": "1479",
        "type": "Panda",
        "date": 1677694162785,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 25,
        "maxHealth": 144,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1479",
        "rarity_score": 350.24720739415034,
        "rarity_level": "RARE"
    },
    "1157": {
        "name": "Brawler Bears #1157",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1157.png",
        "dna": "4005880f6f68e31423990debb244b4c16605bd08",
        "edition": "1157",
        "type": "Brown Bear",
        "date": 1677694163784,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1157",
        "rarity_score": 374.9197173994561,
        "rarity_level": "RARE"
    },
    "884": {
        "name": "Brawler Bears #884",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/884.png",
        "dna": "b49998fefcb122d36b43028385a8c5b66b0e0629",
        "edition": "884",
        "type": "Brown Bear",
        "date": 1677694164542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "884",
        "rarity_score": 194.949202037502,
        "rarity_level": "COMMON"
    },
    "1039": {
        "name": "Brawler Bears #1039",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1039.png",
        "dna": "cc9e60c99f5d56a15b0021e2ce136bef1add7b48",
        "edition": "1039",
        "type": "Acid Trip",
        "date": 1677694165432,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1039",
        "rarity_score": 379.9958127594682,
        "rarity_level": "RARE"
    },
    "2188": {
        "name": "Brawler Bears #2188",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2188.png",
        "dna": "e85e5db898146238ea60cf658e1c0130ef5e9e4e",
        "edition": "2188",
        "type": "Brown Bear",
        "date": 1677694166180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "2188",
        "rarity_score": 236.8565082033872,
        "rarity_level": "COMMON"
    },
    "2072": {
        "name": "Brawler Bears #2072",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2072.png",
        "dna": "98ab77e0110b7c7d5ee1fcc8a685b330c3345f99",
        "edition": "2072",
        "type": "Brown Bear",
        "date": 1677694166961,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 48,
        "maxHealth": 276,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2072",
        "rarity_score": 242.61654174130794,
        "rarity_level": "COMMON"
    },
    "1610": {
        "name": "Brawler Bears #1610",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1610.png",
        "dna": "e6a46b421debde5539ce577ae8b8545134b9fef7",
        "edition": "1610",
        "type": "Ice",
        "date": 1677694167765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Peaky",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1610",
        "rarity_score": 514.9639936507856,
        "rarity_level": "EPIC"
    },
    "2301": {
        "name": "Brawler Bears #2301",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2301.png",
        "dna": "3b9e22bf3881ce29588e86fc9f398d9be9ddb9ce",
        "edition": "2301",
        "type": "Galaxy",
        "date": 1677694168774,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "2301",
        "rarity_score": 184.92952171136145,
        "rarity_level": "COMMON"
    },
    "1421": {
        "name": "Brawler Bears #1421",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1421.png",
        "dna": "2026550b192e05b125fc358ce53bdd7a5fc8ee06",
        "edition": "1421",
        "type": "Brown Bear",
        "date": 1677694169668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 49,
        "maxHealth": 280,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1421",
        "rarity_score": 214.6265006595707,
        "rarity_level": "COMMON"
    },
    "1431": {
        "name": "Brawler Bears #1431",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1431.png",
        "dna": "85a17c968f511820ccc78be6045a37175006f34d",
        "edition": "1431",
        "type": "Deep Martian Cyborg",
        "date": 1677694171253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "1431",
        "rarity_score": 417.0258574647748,
        "rarity_level": "EPIC"
    },
    "999": {
        "name": "Brawler Bears #999",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/999.png",
        "dna": "5ee3266b414969af0aba669116f52a02060b75ee",
        "edition": "999",
        "type": "Panda",
        "date": 1677694172293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "999",
        "rarity_score": 261.131749118925,
        "rarity_level": "COMMON"
    },
    "924": {
        "name": "Brawler Bears #924",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/924.png",
        "dna": "6feff1fc2d67aa3b6a83e3f7fe6593420dffbd97",
        "edition": "924",
        "type": "Galaxy",
        "date": 1677694173649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "924",
        "rarity_score": 399.1880551207094,
        "rarity_level": "RARE"
    },
    "1155": {
        "name": "Brawler Bears #1155",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1155.png",
        "dna": "45b3f678762b20e2fdd0956bbb99c003cef1fb9b",
        "edition": "1155",
        "type": "Galaxy",
        "date": 1677694175030,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1155",
        "rarity_score": 220.4904160908647,
        "rarity_level": "COMMON"
    },
    "1629": {
        "name": "Brawler Bears #1629",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1629.png",
        "dna": "bae3ecb6182cc4f1bfffe955abf8591b00b81323",
        "edition": "1629",
        "type": "Ice",
        "date": 1677694176225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 41,
        "maxHealth": 239,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1629",
        "rarity_score": 237.55275982070663,
        "rarity_level": "COMMON"
    },
    "801": {
        "name": "Brawler Bears #801",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/801.png",
        "dna": "b79ba3fd0099d2896246d1f047031d8485e90a79",
        "edition": "801",
        "type": "Brown Bear",
        "date": 1677694177105,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "801",
        "rarity_score": 440.3041121380072,
        "rarity_level": "EPIC"
    },
    "490": {
        "name": "Brawler Bears #490",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/490.png",
        "dna": "ffcfe9b4b3388a0ed752d53ee437b494b6ddfd4d",
        "edition": "490",
        "type": "Black Bear",
        "date": 1677694178375,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "490",
        "rarity_score": 384.2031420896114,
        "rarity_level": "RARE"
    },
    "2143": {
        "name": "Brawler Bears #2143",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2143.png",
        "dna": "d893d99538835413201b19e53fa6fa138a3a0a4e",
        "edition": "2143",
        "type": "Galactic Cyborg",
        "date": 1677694180141,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2143",
        "rarity_score": 443.261025425573,
        "rarity_level": "EPIC"
    },
    "226": {
        "name": "Brawler Bears #226",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/226.png",
        "dna": "f3c40ab6e3846a8cb036e09241aec390f2708671",
        "edition": "226",
        "type": "Brown Bear",
        "date": 1677694181507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Officer", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "226",
        "rarity_score": 512.1096428786683,
        "rarity_level": "EPIC"
    },
    "1331": {
        "name": "Brawler Bears #1331",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1331.png",
        "dna": "fb8c86ffff611a8ce1ef0e7727309bc7a06fb2b2",
        "edition": "1331",
        "type": "Acid Trip",
        "date": 1677694183127,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1331",
        "rarity_score": 356.48094876285495,
        "rarity_level": "RARE"
    },
    "506": {
        "name": "Brawler Bears #506",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/506.png",
        "dna": "ca4a941cddc939b279658e7aefab680038744563",
        "edition": "506",
        "type": "Galactic Cyborg",
        "date": 1677694183926,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "506",
        "rarity_score": 359.32957844776547,
        "rarity_level": "RARE"
    },
    "109": {
        "name": "Brawler Bears #109",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/109.png",
        "dna": "a3986202d2579656238e38e8403d388255db1579",
        "edition": "109",
        "type": "Black Bear",
        "date": 1677694185097,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 44,
        "maxHealth": 251,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "109",
        "rarity_score": 418.9262640067162,
        "rarity_level": "EPIC"
    },
    "1743": {
        "name": "Brawler Bears #1743",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1743.png",
        "dna": "b6f6046262be30d29d85aa2634d171ad027095e5",
        "edition": "1743",
        "type": "Ice",
        "date": 1677694186439,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1743",
        "rarity_score": 215.94535512022105,
        "rarity_level": "COMMON"
    },
    "416": {
        "name": "Brawler Bears #416",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/416.png",
        "dna": "d08185bb59e16acc6a9b9c45a5290f2a8a1b1566",
        "edition": "416",
        "type": "Brown Bear",
        "date": 1677694187514,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 34,
        "maxHealth": 196,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "416",
        "rarity_score": 235.6638954366404,
        "rarity_level": "COMMON"
    },
    "1410": {
        "name": "Brawler Bears #1410",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1410.png",
        "dna": "2a940f8826156aafb28fd9e10492d1006916eeb3",
        "edition": "1410",
        "type": "Brown Bear",
        "date": 1677694188318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 245,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1410",
        "rarity_score": 357.88208522880285,
        "rarity_level": "RARE"
    },
    "644": {
        "name": "Brawler Bears #644",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/644.png",
        "dna": "10efe6656cb3a811a0cf44911b932242781bb35c",
        "edition": "644",
        "type": "Ice",
        "date": 1677694189315,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "644",
        "rarity_score": 507.78731838521463,
        "rarity_level": "EPIC"
    },
    "1720": {
        "name": "Brawler Bears #1720",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1720.png",
        "dna": "1835ec571903abc897def3b3a378719e19cc25fb",
        "edition": "1720",
        "type": "Brown Bear",
        "date": 1677694190342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1720",
        "rarity_score": 395.85775103747795,
        "rarity_level": "RARE"
    },
    "760": {
        "name": "Brawler Bears #760",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/760.png",
        "dna": "b282306ebad68ddb8e53bc86ef6029eff5223585",
        "edition": "760",
        "type": "Deep Martian Cyborg",
        "date": 1677694191344,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 25,
        "maxHealth": 148,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "760",
        "rarity_score": 320.6022127412492,
        "rarity_level": "RARE"
    },
    "1397": {
        "name": "Brawler Bears #1397",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1397.png",
        "dna": "2fe24d610b9a9226a6b176d1fc2216541b7994ec",
        "edition": "1397",
        "type": "Brown Bear",
        "date": 1677694192252,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 45,
        "maxHealth": 258,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1397",
        "rarity_score": 317.53662020849424,
        "rarity_level": "RARE"
    },
    "1405": {
        "name": "Brawler Bears #1405",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1405.png",
        "dna": "7b6539f6aab128c888d46f907d5ed7d59fc5c7db",
        "edition": "1405",
        "type": "Panda",
        "date": 1677694193785,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 40,
        "maxHealth": 231,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1405",
        "rarity_score": 222.78114469286461,
        "rarity_level": "COMMON"
    },
    "1938": {
        "name": "Brawler Bears #1938",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1938.png",
        "dna": "cc614f46f149dda27ca8f4b8e8263ca1c81b50a3",
        "edition": "1938",
        "type": "Lava",
        "date": 1677694194759,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1938",
        "rarity_score": 357.3708258541949,
        "rarity_level": "RARE"
    },
    "1559": {
        "name": "Brawler Bears #1559",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1559.png",
        "dna": "0c8235f8baa87fd56b5a8af0c0760a104ec6b399",
        "edition": "1559",
        "type": "Black Bear",
        "date": 1677694196102,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1559",
        "rarity_score": 531.026551791061,
        "rarity_level": "LEGENDARY"
    },
    "1721": {
        "name": "Brawler Bears #1721",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1721.png",
        "dna": "0e1170e4dbabc46df42324f46aacc6b2dc330ad4",
        "edition": "1721",
        "type": "Galactic Cyborg",
        "date": 1677694197167,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1721",
        "rarity_score": 380.54332794174974,
        "rarity_level": "RARE"
    },
    "860": {
        "name": "Brawler Bears #860",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/860.png",
        "dna": "9666ac442993291e52cdce904a4b3fd3c57b9935",
        "edition": "860",
        "type": "Galactic Cyborg",
        "date": 1677694198277,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "860",
        "rarity_score": 383.05670987075797,
        "rarity_level": "RARE"
    },
    "100": {
        "name": "Brawler Bears #100",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/100.png",
        "dna": "dac59270f15bb7e1d00526357cc2fff3bc876c46",
        "edition": "100",
        "type": "Galaxy",
        "date": 1677694199311,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 97,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "100",
        "rarity_score": 411.8738568577862,
        "rarity_level": "RARE"
    },
    "551": {
        "name": "Brawler Bears #551",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/551.png",
        "dna": "3978abc0cebe1bc1ac6ae0e9b16c8834a2b3197e",
        "edition": "551",
        "type": "Brown Bear",
        "date": 1677694200470,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "551",
        "rarity_score": 174.5867084646159,
        "rarity_level": "COMMON"
    },
    "698": {
        "name": "Brawler Bears #698",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/698.png",
        "dna": "f22a1791fe651bbfbbdaf41de01a7e79e410bc30",
        "edition": "698",
        "type": "Brown Bear",
        "date": 1677694201617,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 23,
        "maxHealth": 131,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "698",
        "rarity_score": 437.128268702538,
        "rarity_level": "EPIC"
    },
    "124": {
        "name": "Brawler Bears #124",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/124.png",
        "dna": "450dbf3200da255eb5a7d70028c92a268fb23397",
        "edition": "124",
        "type": "Panda",
        "date": 1677694203006,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "124",
        "rarity_score": 439.51949576202355,
        "rarity_level": "EPIC"
    },
    "419": {
        "name": "Brawler Bears #419",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/419.png",
        "dna": "1da461d48271f7558a8cdaeded1d710319a0d4b6",
        "edition": "419",
        "type": "Lava",
        "date": 1677694204201,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 30,
        "maxHealth": 172,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "419",
        "rarity_score": 392.40749677278285,
        "rarity_level": "RARE"
    },
    "1211": {
        "name": "Brawler Bears #1211",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1211.png",
        "dna": "7caf8eba11e60782e9f831b9538969468ea8fd6e",
        "edition": "1211",
        "type": "Ice",
        "date": 1677694205790,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 25,
        "maxHealth": 147,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1211",
        "rarity_score": 401.28005504391,
        "rarity_level": "RARE"
    },
    "1668": {
        "name": "Brawler Bears #1668",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1668.png",
        "dna": "bd350fb93c35f2609246c1789b63b17eb1efa2ee",
        "edition": "1668",
        "type": "Panda",
        "date": 1677694206714,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 40,
        "maxHealth": 234,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1668",
        "rarity_score": 303.30498737013653,
        "rarity_level": "RARE"
    },
    "1985": {
        "name": "Brawler Bears #1985",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1985.png",
        "dna": "0cd6b885bd2beea850630a3579827bbb69c9ee92",
        "edition": "1985",
        "type": "Brown Bear",
        "date": 1677694207666,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1985",
        "rarity_score": 383.4336472448409,
        "rarity_level": "RARE"
    },
    "816": {
        "name": "Brawler Bears #816",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/816.png",
        "dna": "fad949581c6a00efbbcb3f66b76dc0c77b049efa",
        "edition": "816",
        "type": "Galactic Cyborg",
        "date": 1677694209491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "816",
        "rarity_score": 442.29721984131515,
        "rarity_level": "EPIC"
    },
    "2050": {
        "name": "Brawler Bears #2050",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2050.png",
        "dna": "34ff1da0a901729e75f36d00e52e303591cf0460",
        "edition": "2050",
        "type": "Black Bear",
        "date": 1677694210619,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "2050",
        "rarity_score": 419.35523280132514,
        "rarity_level": "EPIC"
    },
    "2091": {
        "name": "Brawler Bears #2091",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2091.png",
        "dna": "5fc4fc961a1caf3878272978b2942e4b593ec168",
        "edition": "2091",
        "type": "Panda",
        "date": 1677694211995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "2091",
        "rarity_score": 379.319716339811,
        "rarity_level": "RARE"
    },
    "40": {
        "name": "Brawler Bears #40",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/40.png",
        "dna": "20bf2342051ee1ddd6b98ff7ffd74cc553bb6246",
        "edition": "40",
        "type": "Black Bear",
        "date": 1677694213106,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "40",
        "rarity_score": 414.4039770580149,
        "rarity_level": "EPIC"
    },
    "1056": {
        "name": "Brawler Bears #1056",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1056.png",
        "dna": "2e380dc352423351aa8e17cc321db121a8829bfd",
        "edition": "1056",
        "type": "Black Bear",
        "date": 1677694214397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1056",
        "rarity_score": 351.30422673534315,
        "rarity_level": "RARE"
    },
    "229": {
        "name": "Brawler Bears #229",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/229.png",
        "dna": "da4cc6869fdbd0124e13b70ebccadc9502cf643e",
        "edition": "229",
        "type": "Galaxy",
        "date": 1677694215218,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "229",
        "rarity_score": 230.48593784269883,
        "rarity_level": "COMMON"
    },
    "95": {
        "name": "Brawler Bears #95",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/95.png",
        "dna": "e7d734c99a7d3227c0f10c6e4dc43a1955f842bc",
        "edition": "95",
        "type": "Lava",
        "date": 1677694216327,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            { "trait_type": "Head", "value": "Bandit", "score": 398.0 },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "95",
        "rarity_score": 549.8740007603857,
        "rarity_level": "LEGENDARY"
    },
    "1628": {
        "name": "Brawler Bears #1628",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1628.png",
        "dna": "2bed925285af789adc5a6d92d9a0097a99d7aa4c",
        "edition": "1628",
        "type": "Brown Bear",
        "date": 1677694217854,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1628",
        "rarity_score": 395.6586689339525,
        "rarity_level": "RARE"
    },
    "617": {
        "name": "Brawler Bears #617",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/617.png",
        "dna": "41e11e09488ec41355def27fbd3acb69698a3df8",
        "edition": "617",
        "type": "Brown Bear",
        "date": 1677694219322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "617",
        "rarity_score": 380.1514820542212,
        "rarity_level": "RARE"
    },
    "2264": {
        "name": "Brawler Bears #2264",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2264.png",
        "dna": "fcca5e4938d6d392250d50119b3dd098496d727e",
        "edition": "2264",
        "type": "Lava",
        "date": 1677694220292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 18,
        "maxHealth": 103,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2264",
        "rarity_score": 265.1285792224106,
        "rarity_level": "COMMON"
    },
    "635": {
        "name": "Brawler Bears #635",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/635.png",
        "dna": "7c437ab4450e7c279005adbc7a26eb4cc6774a83",
        "edition": "635",
        "type": "Acid Trip",
        "date": 1677694221475,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 51,
        "maxHealth": 295,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "635",
        "rarity_score": 339.1853497688662,
        "rarity_level": "RARE"
    },
    "1448": {
        "name": "Brawler Bears #1448",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1448.png",
        "dna": "d3fa9a1c9fed325923da1a1b021526ce8ab11bb7",
        "edition": "1448",
        "type": "Galaxy",
        "date": 1677694223141,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1448",
        "rarity_score": 455.8487191965816,
        "rarity_level": "EPIC"
    },
    "2087": {
        "name": "Brawler Bears #2087",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2087.png",
        "dna": "02c78973cecf374a216bff09c68b4748bcd70696",
        "edition": "2087",
        "type": "Black Bear",
        "date": 1677694224340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 63,
        "maxHealth": 361,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2087",
        "rarity_score": 171.31084839754965,
        "rarity_level": "COMMON"
    },
    "1478": {
        "name": "Brawler Bears #1478",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1478.png",
        "dna": "4b081dfd931ee68d6c73abc6a2104ba8703441d9",
        "edition": "1478",
        "type": "Galactic Cyborg",
        "date": 1677694225969,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1478",
        "rarity_score": 382.2220647707168,
        "rarity_level": "RARE"
    },
    "509": {
        "name": "Brawler Bears #509",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/509.png",
        "dna": "12dc129e7dee95596e5132d9b44f0875934b5eae",
        "edition": "509",
        "type": "Cheetah",
        "date": 1677694227340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 35,
        "maxHealth": 202,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "509",
        "rarity_score": 356.1860638978478,
        "rarity_level": "RARE"
    },
    "238": {
        "name": "Brawler Bears #238",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/238.png",
        "dna": "09b93bd9c2c4551adf0c28482240151975a7ec02",
        "edition": "238",
        "type": "Black Bear",
        "date": 1677694228491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 33,
        "maxHealth": 188,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "238",
        "rarity_score": 341.10452081330294,
        "rarity_level": "RARE"
    },
    "480": {
        "name": "Brawler Bears #480",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/480.png",
        "dna": "ee393d51b9f8b403e4639e1f7783ef9eb56c9eba",
        "edition": "480",
        "type": "Brown Bear",
        "date": 1677694229564,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 44,
        "maxHealth": 251,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "480",
        "rarity_score": 412.87635449893287,
        "rarity_level": "EPIC"
    },
    "851": {
        "name": "Brawler Bears #851",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/851.png",
        "dna": "5e61a1f874893e3b897df81f6ba18fedb2f893a5",
        "edition": "851",
        "type": "Black Bear",
        "date": 1677694230553,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 22,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "851",
        "rarity_score": 447.21461362427516,
        "rarity_level": "EPIC"
    },
    "1554": {
        "name": "Brawler Bears #1554",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1554.png",
        "dna": "aea4fe9ee63d617f8bc5971c2598b8fa6d801eab",
        "edition": "1554",
        "type": "Galactic Cyborg",
        "date": 1677694231952,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1554",
        "rarity_score": 362.254048714341,
        "rarity_level": "RARE"
    },
    "697": {
        "name": "Brawler Bears #697",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/697.png",
        "dna": "d91538fa4db43a6dffb52ae43f040a1c24550f5b",
        "edition": "697",
        "type": "Galaxy",
        "date": 1677694233669,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "697",
        "rarity_score": 447.01377255524693,
        "rarity_level": "EPIC"
    },
    "626": {
        "name": "Brawler Bears #626",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/626.png",
        "dna": "65388043fd7bd13f9f86b03c9579144417580024",
        "edition": "626",
        "type": "Brown Bear",
        "date": 1677694235148,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "626",
        "rarity_score": 472.01302529721954,
        "rarity_level": "EPIC"
    },
    "1870": {
        "name": "Brawler Bears #1870",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1870.png",
        "dna": "f9ce8703c4879d3f19e9b2fbd48687d4d8a39bde",
        "edition": "1870",
        "type": "Galactic Cyborg",
        "date": 1677694235975,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1870",
        "rarity_score": 366.0585103995277,
        "rarity_level": "RARE"
    },
    "843": {
        "name": "Brawler Bears #843",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/843.png",
        "dna": "5f3d87449c48c878bfc25872bdd791088e9f47ed",
        "edition": "843",
        "type": "Galactic Cyborg",
        "date": 1677694237546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "843",
        "rarity_score": 267.90899652685636,
        "rarity_level": "COMMON"
    },
    "21": {
        "name": "Brawler Bears #21",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/21.png",
        "dna": "421974e924ea288224b74e3d215ee3baf6da508e",
        "edition": "21",
        "type": "Brown Bear",
        "date": 1677694238825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "21",
        "rarity_score": 380.16014722978065,
        "rarity_level": "RARE"
    },
    "1650": {
        "name": "Brawler Bears #1650",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1650.png",
        "dna": "a1a26498aafb1ba62a015e5df863e0f8b0c6b171",
        "edition": "1650",
        "type": "Galaxy",
        "date": 1677694239787,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 91,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1650",
        "rarity_score": 418.07823555754595,
        "rarity_level": "EPIC"
    },
    "1389": {
        "name": "Brawler Bears #1389",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1389.png",
        "dna": "f8886f320a2c9097f201025d535ff75e8c007907",
        "edition": "1389",
        "type": "Black Bear",
        "date": 1677694240812,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 26,
        "maxHealth": 151,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1389",
        "rarity_score": 267.0032815383429,
        "rarity_level": "COMMON"
    },
    "1946": {
        "name": "Brawler Bears #1946",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1946.png",
        "dna": "53459e71ae2250ec8c93ff1e1188301c2ccff9e3",
        "edition": "1946",
        "type": "Galaxy",
        "date": 1677694241950,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1946",
        "rarity_score": 510.33829099466317,
        "rarity_level": "EPIC"
    },
    "2180": {
        "name": "Brawler Bears #2180",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2180.png",
        "dna": "39ace013d594addb12593e284015b19bf421cbdb",
        "edition": "2180",
        "type": "Black Bear",
        "date": 1677694243772,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 47,
        "maxHealth": 271,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2180",
        "rarity_score": 244.49571233894412,
        "rarity_level": "COMMON"
    },
    "1247": {
        "name": "Brawler Bears #1247",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1247.png",
        "dna": "dcd41a2450d86bcf56bdf68937c64dbe10d3e46b",
        "edition": "1247",
        "type": "Lava",
        "date": 1677694244734,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 30,
        "maxHealth": 173,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1247",
        "rarity_score": 425.81278463208,
        "rarity_level": "EPIC"
    },
    "1601": {
        "name": "Brawler Bears #1601",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1601.png",
        "dna": "8c16111643bee266c39f4b0e0ebd97ab400d7cc8",
        "edition": "1601",
        "type": "Galactic Cyborg",
        "date": 1677694245720,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1601",
        "rarity_score": 308.3852798319245,
        "rarity_level": "RARE"
    },
    "1709": {
        "name": "Brawler Bears #1709",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1709.png",
        "dna": "54497d0b3419e3e35abceaae00bca977ef1aafc1",
        "edition": "1709",
        "type": "Ice",
        "date": 1677694246540,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 91,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1709",
        "rarity_score": 397.03238636363636,
        "rarity_level": "RARE"
    },
    "1823": {
        "name": "Brawler Bears #1823",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1823.png",
        "dna": "9706510688e65723cdd336fb44423aaac5650d50",
        "edition": "1823",
        "type": "Brown Bear",
        "date": 1677694247381,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1823",
        "rarity_score": 451.0345009687405,
        "rarity_level": "EPIC"
    },
    "995": {
        "name": "Brawler Bears #995",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/995.png",
        "dna": "fcea50fe7bbeacac66b660f297e55aea74659a1d",
        "edition": "995",
        "type": "Ice",
        "date": 1677694249237,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 63,
        "maxHealth": 361,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "995",
        "rarity_score": 302.69205033818065,
        "rarity_level": "RARE"
    },
    "1314": {
        "name": "Brawler Bears #1314",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1314.png",
        "dna": "bf6cfb1d95ebd973d0273c40dfd8d63a91a67594",
        "edition": "1314",
        "type": "Brown Bear",
        "date": 1677694250369,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1314",
        "rarity_score": 426.47493008435566,
        "rarity_level": "EPIC"
    },
    "1404": {
        "name": "Brawler Bears #1404",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1404.png",
        "dna": "e2d4caf4bca5b541dfef27a60a96850cce696ce4",
        "edition": "1404",
        "type": "Cheetah",
        "date": 1677694251944,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 18,
        "maxHealth": 104,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1404",
        "rarity_score": 266.0382456729388,
        "rarity_level": "COMMON"
    },
    "1596": {
        "name": "Brawler Bears #1596",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1596.png",
        "dna": "d0ef9056bfd3038ffe33bc5a2400b1f9c9890422",
        "edition": "1596",
        "type": "Brown Bear",
        "date": 1677694253794,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 26,
        "maxHealth": 154,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1596",
        "rarity_score": 344.297168785425,
        "rarity_level": "RARE"
    },
    "1486": {
        "name": "Brawler Bears #1486",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1486.png",
        "dna": "306c645add00ef196350cc415b0bcadd7219dfcd",
        "edition": "1486",
        "type": "Cheetah",
        "date": 1677694255016,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1486",
        "rarity_score": 300.431276443515,
        "rarity_level": "RARE"
    },
    "345": {
        "name": "Brawler Bears #345",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/345.png",
        "dna": "3878870b58b927c42a99bd0294b71470492cde89",
        "edition": "345",
        "type": "Black Bear",
        "date": 1677694256612,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 58,
        "maxHealth": 334,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "345",
        "rarity_score": 412.5338531139498,
        "rarity_level": "EPIC"
    },
    "600": {
        "name": "Brawler Bears #600",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/600.png",
        "dna": "81cb97406c87ac8415f25083ddf2bf560a664014",
        "edition": "600",
        "type": "Black Bear",
        "date": 1677694258494,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 47,
        "maxHealth": 271,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "600",
        "rarity_score": 248.9701190489545,
        "rarity_level": "COMMON"
    },
    "156": {
        "name": "Brawler Bears #156",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/156.png",
        "dna": "e0cd0302b26a02e5ef018fa5602d27cf79105686",
        "edition": "156",
        "type": "Brown Bear",
        "date": 1677694260379,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Samurai Warrior",
                "score": 398.0
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "156",
        "rarity_score": 636.9911046750852,
        "rarity_level": "LEGENDARY"
    },
    "1412": {
        "name": "Brawler Bears #1412",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1412.png",
        "dna": "a2df21d262c397b6f6c8f58f07d9b2a90c8a73f3",
        "edition": "1412",
        "type": "Black Bear",
        "date": 1677694262009,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1412",
        "rarity_score": 401.22427414504807,
        "rarity_level": "RARE"
    },
    "1271": {
        "name": "Brawler Bears #1271",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1271.png",
        "dna": "c3f37fdd112ceb4cc68aae055fd0d535b6f64ce5",
        "edition": "1271",
        "type": "Brown Bear",
        "date": 1677694263128,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 49,
        "maxHealth": 280,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1271",
        "rarity_score": 255.18096752495754,
        "rarity_level": "COMMON"
    },
    "410": {
        "name": "Brawler Bears #410",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/410.png",
        "dna": "f2894dbab586e54388fca507e9e90e3be96918e5",
        "edition": "410",
        "type": "Cheetah",
        "date": 1677694263997,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 54,
        "maxHealth": 312,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "410",
        "rarity_score": 202.9491734484494,
        "rarity_level": "COMMON"
    },
    "722": {
        "name": "Brawler Bears #722",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/722.png",
        "dna": "df19b6bcc14af4b9de177891dc3d6278d23e42ec",
        "edition": "722",
        "type": "Panda",
        "date": 1677694265118,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "722",
        "rarity_score": 289.23535503921124,
        "rarity_level": "RARE"
    },
    "1441": {
        "name": "Brawler Bears #1441",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1441.png",
        "dna": "a0ca0c979f2ba6b7fb4f57d4b24e0198008dbcfb",
        "edition": "1441",
        "type": "Brown Bear",
        "date": 1677694266825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Ribbit",
                "score": 331.6666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1441",
        "rarity_score": 451.45600976545313,
        "rarity_level": "EPIC"
    },
    "1532": {
        "name": "Brawler Bears #1532",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1532.png",
        "dna": "3cae96ea49d0aa0ba55b7b7ea6e372c458a238de",
        "edition": "1532",
        "type": "Deep Martian Cyborg",
        "date": 1677694267723,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1532",
        "rarity_score": 292.2663065868471,
        "rarity_level": "RARE"
    },
    "254": {
        "name": "Brawler Bears #254",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/254.png",
        "dna": "911b0ce70f238025dac041d3e73fef5f16a4adf8",
        "edition": "254",
        "type": "Black Bear",
        "date": 1677694268506,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "254",
        "rarity_score": 307.74665302882886,
        "rarity_level": "RARE"
    },
    "257": {
        "name": "Brawler Bears #257",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/257.png",
        "dna": "3743fd49980cf101baf5117f0018454e64a26ddf",
        "edition": "257",
        "type": "Brown Bear",
        "date": 1677694269818,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "257",
        "rarity_score": 342.85914615997837,
        "rarity_level": "RARE"
    },
    "473": {
        "name": "Brawler Bears #473",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/473.png",
        "dna": "74b6397aae8b32b909b6d04a31f6d064c84fef50",
        "edition": "473",
        "type": "Deep Martian Cyborg",
        "date": 1677694271057,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 42,
        "maxHealth": 245,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "473",
        "rarity_score": 290.27735064390083,
        "rarity_level": "RARE"
    },
    "1513": {
        "name": "Brawler Bears #1513",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1513.png",
        "dna": "91557073671cace6c4d73c526294535bf6aaff8a",
        "edition": "1513",
        "type": "Black Bear",
        "date": 1677694272043,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1513",
        "rarity_score": 409.31583793019126,
        "rarity_level": "RARE"
    },
    "344": {
        "name": "Brawler Bears #344",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/344.png",
        "dna": "769556d0c1a94443c730ca962e2ec7c2550114a5",
        "edition": "344",
        "type": "Brown Bear",
        "date": 1677694273062,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "344",
        "rarity_score": 366.75876245007083,
        "rarity_level": "RARE"
    },
    "470": {
        "name": "Brawler Bears #470",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/470.png",
        "dna": "ce4509860f46ad583af392f90a06c38ebd905e54",
        "edition": "470",
        "type": "Black Bear",
        "date": 1677694274223,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 31,
        "maxHealth": 177,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "470",
        "rarity_score": 297.7369417121873,
        "rarity_level": "RARE"
    },
    "325": {
        "name": "Brawler Bears #325",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/325.png",
        "dna": "d773a3f7cf774cfb231bf66f6dcdcac8e440a0a3",
        "edition": "325",
        "type": "Black Bear",
        "date": 1677694275210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "325",
        "rarity_score": 459.74722789205856,
        "rarity_level": "EPIC"
    },
    "1572": {
        "name": "Brawler Bears #1572",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1572.png",
        "dna": "2b76055101efeb08bb3631d4f914d4f382394311",
        "edition": "1572",
        "type": "Brown Bear",
        "date": 1677694276921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1572",
        "rarity_score": 459.71784059556046,
        "rarity_level": "EPIC"
    },
    "2312": {
        "name": "Brawler Bears #2312",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2312.png",
        "dna": "9a5f573e06bdf8178992473442224e329fd8f1b5",
        "edition": "2312",
        "type": "Black Bear",
        "date": 1677694278421,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "2312",
        "rarity_score": 450.74260962899314,
        "rarity_level": "EPIC"
    },
    "1167": {
        "name": "Brawler Bears #1167",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1167.png",
        "dna": "6dbb4dd309d8549152698def1467728117dc439f",
        "edition": "1167",
        "type": "Brown Bear",
        "date": 1677694279431,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 48,
        "maxHealth": 274,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1167",
        "rarity_score": 231.98741855455418,
        "rarity_level": "COMMON"
    },
    "2258": {
        "name": "Brawler Bears #2258",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2258.png",
        "dna": "e8f6094ce513ca9af0e8b47c36f8db60394a08e3",
        "edition": "2258",
        "type": "Black Bear",
        "date": 1677694280596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "2258",
        "rarity_score": 295.22631829027347,
        "rarity_level": "RARE"
    },
    "1480": {
        "name": "Brawler Bears #1480",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1480.png",
        "dna": "388bed64f69818a9c94c4e8bd529d5d728d5812e",
        "edition": "1480",
        "type": "Galaxy",
        "date": 1677694282055,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 33,
        "maxHealth": 194,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1480",
        "rarity_score": 348.94394389062217,
        "rarity_level": "RARE"
    },
    "486": {
        "name": "Brawler Bears #486",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/486.png",
        "dna": "8bba855ee1a61a724d0a9032cf25928e7c38e44b",
        "edition": "486",
        "type": "Brown Bear",
        "date": 1677694283528,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 49,
        "maxHealth": 283,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "486",
        "rarity_score": 209.3035575109786,
        "rarity_level": "COMMON"
    },
    "1093": {
        "name": "Brawler Bears #1093",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1093.png",
        "dna": "844c726a3caa199e3350577f94adf00f49846b0c",
        "edition": "1093",
        "type": "Galactic Cyborg",
        "date": 1677694284423,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 18,
        "maxHealth": 103,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1093",
        "rarity_score": 434.1144732400903,
        "rarity_level": "EPIC"
    },
    "215": {
        "name": "Brawler Bears #215",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/215.png",
        "dna": "73925ff5a294bee687f57f1956d5e9994fb17fb0",
        "edition": "215",
        "type": "Black Bear",
        "date": 1677694285760,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 26,
        "maxHealth": 151,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "215",
        "rarity_score": 279.50618888446434,
        "rarity_level": "RARE"
    },
    "1966": {
        "name": "Brawler Bears #1966",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1966.png",
        "dna": "27a34c9c56f4185a152f0e7e557470867025acbb",
        "edition": "1966",
        "type": "Deep Martian Cyborg",
        "date": 1677694287229,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1966",
        "rarity_score": 217.77769699824574,
        "rarity_level": "COMMON"
    },
    "54": {
        "name": "Brawler Bears #54",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/54.png",
        "dna": "c23c64690dc51f6277acfa2aaedfdd7ce7fa0249",
        "edition": "54",
        "type": "Cheetah",
        "date": 1677694288246,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 29,
        "maxHealth": 169,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "54",
        "rarity_score": 458.2483786880969,
        "rarity_level": "EPIC"
    },
    "661": {
        "name": "Brawler Bears #661",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/661.png",
        "dna": "4da7b64accf6d4cbb868b5d7e6b0b372f67e6021",
        "edition": "661",
        "type": "Ice",
        "date": 1677694289358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "661",
        "rarity_score": 437.2034544584199,
        "rarity_level": "EPIC"
    },
    "1052": {
        "name": "Brawler Bears #1052",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1052.png",
        "dna": "98780fb0db5a15df14f1df8e10d1ae06ac845e56",
        "edition": "1052",
        "type": "Black Bear",
        "date": 1677694290062,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 70,
        "maxHealth": 401,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1052",
        "rarity_score": 270.4425201224751,
        "rarity_level": "COMMON"
    },
    "1070": {
        "name": "Brawler Bears #1070",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1070.png",
        "dna": "56111eef9e15e39dd883394f2c91fb4fc19defda",
        "edition": "1070",
        "type": "Lava",
        "date": 1677694291410,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 65,
        "maxHealth": 375,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1070",
        "rarity_score": 107.93788301973022,
        "rarity_level": "COMMON"
    },
    "1114": {
        "name": "Brawler Bears #1114",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1114.png",
        "dna": "d45eb6de5ce2c0d99059ef9a2a4673986befefbb",
        "edition": "1114",
        "type": "Lava",
        "date": 1677694292828,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 13,
        "maxHealth": 75,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1114",
        "rarity_score": 473.68121803687745,
        "rarity_level": "EPIC"
    },
    "2037": {
        "name": "Brawler Bears #2037",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2037.png",
        "dna": "18ec1a806a3f77fa6af29d22281241c7644333fc",
        "edition": "2037",
        "type": "Acid Trip",
        "date": 1677694293908,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2037",
        "rarity_score": 383.95718047439254,
        "rarity_level": "RARE"
    },
    "1005": {
        "name": "Brawler Bears #1005",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1005.png",
        "dna": "e0540db61bd01f729eb317de920410f148c1c12e",
        "edition": "1005",
        "type": "Galactic Cyborg",
        "date": 1677694295223,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1005",
        "rarity_score": 236.70997976092212,
        "rarity_level": "COMMON"
    },
    "1517": {
        "name": "Brawler Bears #1517",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1517.png",
        "dna": "d087e01f2b8e2dee7658ea4b2a6d45ecebde215c",
        "edition": "1517",
        "type": "Black Bear",
        "date": 1677694296703,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1517",
        "rarity_score": 450.0369455430805,
        "rarity_level": "EPIC"
    },
    "1196": {
        "name": "Brawler Bears #1196",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1196.png",
        "dna": "b75c35519ec90618971712b20dd9f0e0519356b8",
        "edition": "1196",
        "type": "Panda",
        "date": 1677694297771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1196",
        "rarity_score": 402.4181856393173,
        "rarity_level": "RARE"
    },
    "1664": {
        "name": "Brawler Bears #1664",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1664.png",
        "dna": "2ac3d40670c6b912b8e3c21e98ffb89d07e58dc9",
        "edition": "1664",
        "type": "Brown Bear",
        "date": 1677694299025,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 58,
        "maxHealth": 335,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1664",
        "rarity_score": 144.14529849723192,
        "rarity_level": "COMMON"
    },
    "248": {
        "name": "Brawler Bears #248",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/248.png",
        "dna": "91eda815e320ff6f10f418fcf8cdda38b192a936",
        "edition": "248",
        "type": "Cheetah",
        "date": 1677694300237,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 50,
        "maxHealth": 288,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "248",
        "rarity_score": 411.65579819040533,
        "rarity_level": "RARE"
    },
    "930": {
        "name": "Brawler Bears #930",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/930.png",
        "dna": "97b7bbf5761b734860e392684b629bf583593bc8",
        "edition": "930",
        "type": "Galaxy",
        "date": 1677694301686,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "930",
        "rarity_score": 526.3974661914107,
        "rarity_level": "LEGENDARY"
    },
    "1654": {
        "name": "Brawler Bears #1654",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1654.png",
        "dna": "b259c39274919ba7d15ba0a9fc0c43ac213da073",
        "edition": "1654",
        "type": "Galactic Cyborg",
        "date": 1677694302573,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 57,
        "maxHealth": 328,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1654",
        "rarity_score": 176.06437214225426,
        "rarity_level": "COMMON"
    },
    "658": {
        "name": "Brawler Bears #658",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/658.png",
        "dna": "6dcc0dbadd3b9216220af5dd78600a8962b0a8f0",
        "edition": "658",
        "type": "Black Bear",
        "date": 1677694303842,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "658",
        "rarity_score": 398.4783083847661,
        "rarity_level": "RARE"
    },
    "1024": {
        "name": "Brawler Bears #1024",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1024.png",
        "dna": "4aff634f52de2938e3ce14d60fa8db3e048c816d",
        "edition": "1024",
        "type": "Galaxy",
        "date": 1677694304728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1024",
        "rarity_score": 233.20658480078063,
        "rarity_level": "COMMON"
    },
    "173": {
        "name": "Brawler Bears #173",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/173.png",
        "dna": "530c3aee082d9403e61401fb081485977960c18f",
        "edition": "173",
        "type": "Black Bear",
        "date": 1677694306232,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 36,
        "maxHealth": 211,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "173",
        "rarity_score": 173.1999971486667,
        "rarity_level": "COMMON"
    },
    "1454": {
        "name": "Brawler Bears #1454",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1454.png",
        "dna": "a89d5f8eaad1e16a0d83818f2ccf5e1ac54983fa",
        "edition": "1454",
        "type": "Deep Martian Cyborg",
        "date": 1677694307547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1454",
        "rarity_score": 430.04192330501877,
        "rarity_level": "EPIC"
    },
    "536": {
        "name": "Brawler Bears #536",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/536.png",
        "dna": "bbb528ea28a75d8571146f09da2b6f3ef0b270ec",
        "edition": "536",
        "type": "Acid Trip",
        "date": 1677694308370,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "General",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "536",
        "rarity_score": 468.2552025763903,
        "rarity_level": "EPIC"
    },
    "874": {
        "name": "Brawler Bears #874",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/874.png",
        "dna": "b4480aa08d06eb56052a9ca0b37bfa6aacf91986",
        "edition": "874",
        "type": "Brown Bear",
        "date": 1677694309792,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "874",
        "rarity_score": 353.7905296316363,
        "rarity_level": "RARE"
    },
    "752": {
        "name": "Brawler Bears #752",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/752.png",
        "dna": "b82190b45522f5503c1bd9f9b47fa7016b215bfc",
        "edition": "752",
        "type": "Ice",
        "date": 1677694311037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "752",
        "rarity_score": 396.9579105599721,
        "rarity_level": "RARE"
    },
    "1672": {
        "name": "Brawler Bears #1672",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1672.png",
        "dna": "84415e6571f86d24f6b3d01298f01ac45401e399",
        "edition": "1672",
        "type": "Lava",
        "date": 1677694311902,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 34,
        "maxHealth": 198,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1672",
        "rarity_score": 361.200831884793,
        "rarity_level": "RARE"
    },
    "1374": {
        "name": "Brawler Bears #1374",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1374.png",
        "dna": "9d055822d43ac3452936e40fbbd161528883d81c",
        "edition": "1374",
        "type": "Brown Bear",
        "date": 1677694313137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 40,
        "maxHealth": 229,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1374",
        "rarity_score": 335.85008112293167,
        "rarity_level": "RARE"
    },
    "1918": {
        "name": "Brawler Bears #1918",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1918.png",
        "dna": "21f1a5aaff798e911b147a9f7c4824af9fc0cbbb",
        "edition": "1918",
        "type": "Galaxy",
        "date": 1677694314788,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Chad", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1918",
        "rarity_score": 452.43163613412736,
        "rarity_level": "EPIC"
    },
    "2177": {
        "name": "Brawler Bears #2177",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2177.png",
        "dna": "49267578277d79d7fb3fccad128fbb24a901d929",
        "edition": "2177",
        "type": "Panda",
        "date": 1677694315506,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 40,
        "maxHealth": 230,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2177",
        "rarity_score": 253.79343037636437,
        "rarity_level": "COMMON"
    },
    "938": {
        "name": "Brawler Bears #938",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/938.png",
        "dna": "6f7fee9d2f0fc7c04728650c0c0ba533a475bbc1",
        "edition": "938",
        "type": "Deep Martian Cyborg",
        "date": 1677694316356,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "938",
        "rarity_score": 445.541553862791,
        "rarity_level": "EPIC"
    },
    "989": {
        "name": "Brawler Bears #989",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/989.png",
        "dna": "ad4553a2787787468992bbd4ac14be40fbfbf164",
        "edition": "989",
        "type": "Galaxy",
        "date": 1677694317343,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "989",
        "rarity_score": 439.9393509127789,
        "rarity_level": "EPIC"
    },
    "881": {
        "name": "Brawler Bears #881",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/881.png",
        "dna": "54f33394a46216b3a1490c98e891ff1da6fe0ad6",
        "edition": "881",
        "type": "Cheetah",
        "date": 1677694319508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 18,
        "maxHealth": 104,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "881",
        "rarity_score": 287.7102653189461,
        "rarity_level": "RARE"
    },
    "808": {
        "name": "Brawler Bears #808",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/808.png",
        "dna": "bfe24880fce37688eaed6c7f64ad21c6b4252c33",
        "edition": "808",
        "type": "Black Bear",
        "date": 1677694320440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 59,
        "maxHealth": 340,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "808",
        "rarity_score": 309.7614409598384,
        "rarity_level": "RARE"
    },
    "1816": {
        "name": "Brawler Bears #1816",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1816.png",
        "dna": "abdd8026fe0c4bb5c132652cb7ffa102eb8c5110",
        "edition": "1816",
        "type": "Deep Martian Cyborg",
        "date": 1677694321761,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1816",
        "rarity_score": 221.67181519001693,
        "rarity_level": "COMMON"
    },
    "984": {
        "name": "Brawler Bears #984",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/984.png",
        "dna": "5e733a0734ffe5f76092755181036ace3971659f",
        "edition": "984",
        "type": "Panda",
        "date": 1677694323593,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "984",
        "rarity_score": 399.8686138373455,
        "rarity_level": "RARE"
    },
    "2052": {
        "name": "Brawler Bears #2052",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2052.png",
        "dna": "72ecaa411c1bb3ac559f1031d04eb18f748d8cf8",
        "edition": "2052",
        "type": "Galactic Cyborg",
        "date": 1677694325029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2052",
        "rarity_score": 367.87702151005993,
        "rarity_level": "RARE"
    },
    "1781": {
        "name": "Brawler Bears #1781",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1781.png",
        "dna": "ba33e1be8f2eedc4962f2577a926edd897e9c7af",
        "edition": "1781",
        "type": "Lava",
        "date": 1677694326079,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1781",
        "rarity_score": 356.72828880297436,
        "rarity_level": "RARE"
    },
    "1311": {
        "name": "Brawler Bears #1311",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1311.png",
        "dna": "4f2df5113e7edd64465cc75620d59e1b10631822",
        "edition": "1311",
        "type": "Acid Trip",
        "date": 1677694327038,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1311",
        "rarity_score": 323.4601020536632,
        "rarity_level": "RARE"
    },
    "519": {
        "name": "Brawler Bears #519",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/519.png",
        "dna": "9837c7df9d06450c5f9e49e98845921658a4de01",
        "edition": "519",
        "type": "Acid Trip",
        "date": 1677694328927,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 46,
        "maxHealth": 264,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "519",
        "rarity_score": 255.60184044865787,
        "rarity_level": "COMMON"
    },
    "1265": {
        "name": "Brawler Bears #1265",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1265.png",
        "dna": "47db54e7d6c2634d86b5015528c14e9549c39acb",
        "edition": "1265",
        "type": "Galaxy",
        "date": 1677694330115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1265",
        "rarity_score": 336.4110095269412,
        "rarity_level": "RARE"
    },
    "1877": {
        "name": "Brawler Bears #1877",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1877.png",
        "dna": "8fdcef0926f44cf50c7430e981b439dac541e6f4",
        "edition": "1877",
        "type": "Brown Bear",
        "date": 1677694331571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 28,
        "maxHealth": 163,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1877",
        "rarity_score": 233.03603347446588,
        "rarity_level": "COMMON"
    },
    "584": {
        "name": "Brawler Bears #584",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/584.png",
        "dna": "8bdf1577d78a686be646bd925623bc3e38b5d373",
        "edition": "584",
        "type": "Panda",
        "date": 1677694333265,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "584",
        "rarity_score": 229.11080837052444,
        "rarity_level": "COMMON"
    },
    "703": {
        "name": "Brawler Bears #703",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/703.png",
        "dna": "f745d1580c5068ec78088cd184bfb5a4f8556071",
        "edition": "703",
        "type": "Acid Trip",
        "date": 1677694334378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 19,
        "maxHealth": 111,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "703",
        "rarity_score": 286.4705805218269,
        "rarity_level": "RARE"
    },
    "76": {
        "name": "Brawler Bears #76",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/76.png",
        "dna": "9c21a13745eab58bb4700966bf1c555b9dae7823",
        "edition": "76",
        "type": "Black Bear",
        "date": 1677694336089,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 48,
        "maxHealth": 277,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "76",
        "rarity_score": 312.46138765589905,
        "rarity_level": "RARE"
    },
    "1761": {
        "name": "Brawler Bears #1761",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1761.png",
        "dna": "2b9f401fbf85df23a418ccb3e4964676a0b6fd80",
        "edition": "1761",
        "type": "Brown Bear",
        "date": 1677694337884,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1761",
        "rarity_score": 356.3532024214221,
        "rarity_level": "RARE"
    },
    "878": {
        "name": "Brawler Bears #878",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/878.png",
        "dna": "822572ef236d12531a58f81ab38fed40808d0dda",
        "edition": "878",
        "type": "Brown Bear",
        "date": 1677694338839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 54,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "878",
        "rarity_score": 326.3527011309671,
        "rarity_level": "RARE"
    },
    "595": {
        "name": "Brawler Bears #595",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/595.png",
        "dna": "07dc96370afb64341e0afc51ee361c06921419d9",
        "edition": "595",
        "type": "Cheetah",
        "date": 1677694340258,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 60,
        "maxHealth": 347,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "595",
        "rarity_score": 253.89103641109003,
        "rarity_level": "COMMON"
    },
    "797": {
        "name": "Brawler Bears #797",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/797.png",
        "dna": "f159e5c622f9e642fc2a9eabf5867d8d209e0e69",
        "edition": "797",
        "type": "Black Bear",
        "date": 1677694342528,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "797",
        "rarity_score": 419.9292287161981,
        "rarity_level": "EPIC"
    },
    "826": {
        "name": "Brawler Bears #826",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/826.png",
        "dna": "6de653bcbb6881cd08d2fa1c11e1d4a2f33a76cc",
        "edition": "826",
        "type": "Deep Martian Cyborg",
        "date": 1677694343556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "826",
        "rarity_score": 404.7106860378055,
        "rarity_level": "RARE"
    },
    "948": {
        "name": "Brawler Bears #948",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/948.png",
        "dna": "a0c7e2b190a8721d04713f066cc6b598d053a383",
        "edition": "948",
        "type": "Black Bear",
        "date": 1677694345375,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "948",
        "rarity_score": 311.2433009634605,
        "rarity_level": "RARE"
    },
    "1028": {
        "name": "Brawler Bears #1028",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1028.png",
        "dna": "7ebaa4f2df0734934443ad5b4b4f48ce74245c07",
        "edition": "1028",
        "type": "Acid Trip",
        "date": 1677694346308,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1028",
        "rarity_score": 472.5825004382611,
        "rarity_level": "EPIC"
    },
    "1967": {
        "name": "Brawler Bears #1967",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1967.png",
        "dna": "4dac4fe4048b6089fef6789d125001d8cf1d3267",
        "edition": "1967",
        "type": "Black Bear",
        "date": 1677694347898,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1967",
        "rarity_score": 371.330696041821,
        "rarity_level": "RARE"
    },
    "1725": {
        "name": "Brawler Bears #1725",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1725.png",
        "dna": "4b3c2535b6f7b3ffed30eaa1b93d2e786f0cbbf1",
        "edition": "1725",
        "type": "Black Bear",
        "date": 1677694349347,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 48,
        "maxHealth": 278,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1725",
        "rarity_score": 227.23665345563214,
        "rarity_level": "COMMON"
    },
    "1758": {
        "name": "Brawler Bears #1758",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1758.png",
        "dna": "67347938b662471052d2ecd3006f739c27513ee1",
        "edition": "1758",
        "type": "Galaxy",
        "date": 1677694351069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1758",
        "rarity_score": 276.8465397694158,
        "rarity_level": "RARE"
    },
    "1673": {
        "name": "Brawler Bears #1673",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1673.png",
        "dna": "05c427e73b5c18cd953a6ee7a0d23977633b7f4a",
        "edition": "1673",
        "type": "Brown Bear",
        "date": 1677694352120,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 59,
        "maxHealth": 340,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1673",
        "rarity_score": 349.6776390692952,
        "rarity_level": "RARE"
    },
    "742": {
        "name": "Brawler Bears #742",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/742.png",
        "dna": "f8a04b79731478e3f05a6839338d3c0bc5c3d6e5",
        "edition": "742",
        "type": "Galactic Cyborg",
        "date": 1677694354055,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "742",
        "rarity_score": 427.0428341387308,
        "rarity_level": "EPIC"
    },
    "75": {
        "name": "Brawler Bears #75",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/75.png",
        "dna": "e485014d22786cbf3c68a280f96e38ad841a2776",
        "edition": "75",
        "type": "Black Bear",
        "date": 1677694354909,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 42,
        "maxHealth": 245,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "75",
        "rarity_score": 206.05905010099536,
        "rarity_level": "COMMON"
    },
    "1400": {
        "name": "Brawler Bears #1400",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1400.png",
        "dna": "f65bffe48c263dbb3e2a11a1c1c8458cccac2851",
        "edition": "1400",
        "type": "Galaxy",
        "date": 1677694356105,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Peaky",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1400",
        "rarity_score": 509.98404609014653,
        "rarity_level": "EPIC"
    },
    "1444": {
        "name": "Brawler Bears #1444",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1444.png",
        "dna": "1d58139973a6c37c018304880a59ae650032d0f4",
        "edition": "1444",
        "type": "Brown Bear",
        "date": 1677694357093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            { "trait_type": "Head", "value": "Officer", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1444",
        "rarity_score": 461.51419226990464,
        "rarity_level": "EPIC"
    },
    "838": {
        "name": "Brawler Bears #838",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/838.png",
        "dna": "444b7f67d8e891528458c2849adbb5c402c226d5",
        "edition": "838",
        "type": "Black Bear",
        "date": 1677694359031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 43,
        "maxHealth": 245,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "838",
        "rarity_score": 357.0240401300324,
        "rarity_level": "RARE"
    },
    "1945": {
        "name": "Brawler Bears #1945",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1945.png",
        "dna": "2409c4c618585dcf29d53c7e26d53fc0635e8ac4",
        "edition": "1945",
        "type": "Ice",
        "date": 1677694360507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            { "trait_type": "Head", "value": "Officer", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1945",
        "rarity_score": 518.5496229457469,
        "rarity_level": "LEGENDARY"
    },
    "2225": {
        "name": "Brawler Bears #2225",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2225.png",
        "dna": "dc97d472da7ee60da48c0959f0dc24f3480d6bd8",
        "edition": "2225",
        "type": "Black Bear",
        "date": 1677694361295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            { "trait_type": "Head", "value": "Royalty", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 47,
        "maxHealth": 270,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "2225",
        "rarity_score": 381.04908479851883,
        "rarity_level": "RARE"
    },
    "700": {
        "name": "Brawler Bears #700",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/700.png",
        "dna": "36df32785e4ca1f709876cf927921b4656c3ee0c",
        "edition": "700",
        "type": "Brown Bear",
        "date": 1677694362595,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "700",
        "rarity_score": 422.2831147992408,
        "rarity_level": "EPIC"
    },
    "294": {
        "name": "Brawler Bears #294",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/294.png",
        "dna": "6e318313c28ef9ac86097f3e852c0092bfe00a1c",
        "edition": "294",
        "type": "Black Bear",
        "date": 1677694363949,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "294",
        "rarity_score": 389.7560691974359,
        "rarity_level": "RARE"
    },
    "940": {
        "name": "Brawler Bears #940",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/940.png",
        "dna": "84cf2a105ea90762b055e7e9f61236f9312bb966",
        "edition": "940",
        "type": "Deep Martian Cyborg",
        "date": 1677694365340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "940",
        "rarity_score": 473.1439590590655,
        "rarity_level": "EPIC"
    },
    "608": {
        "name": "Brawler Bears #608",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/608.png",
        "dna": "7dbd75cf4a4bf0d944dea958187638f7dd35b1e9",
        "edition": "608",
        "type": "Brown Bear",
        "date": 1677694367290,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "608",
        "rarity_score": 390.6179805165152,
        "rarity_level": "RARE"
    },
    "2033": {
        "name": "Brawler Bears #2033",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2033.png",
        "dna": "ff63ef4413434dde54103d8ee9e65ff4ccace678",
        "edition": "2033",
        "type": "Cheetah",
        "date": 1677694368697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 51,
        "maxHealth": 295,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2033",
        "rarity_score": 309.1007047134932,
        "rarity_level": "RARE"
    },
    "781": {
        "name": "Brawler Bears #781",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/781.png",
        "dna": "28f1dc365829a5052b724d4c6f5fd65a9d555c28",
        "edition": "781",
        "type": "Panda",
        "date": 1677694370039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 31,
        "maxHealth": 178,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "781",
        "rarity_score": 346.35388968722515,
        "rarity_level": "RARE"
    },
    "160": {
        "name": "Brawler Bears #160",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/160.png",
        "dna": "ff9e755ac1034317b8ee2b2655a019f53c8d876b",
        "edition": "160",
        "type": "Ice",
        "date": 1677694371268,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 51,
        "maxHealth": 294,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "160",
        "rarity_score": 383.4106231507708,
        "rarity_level": "RARE"
    },
    "2252": {
        "name": "Brawler Bears #2252",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2252.png",
        "dna": "0809eb5fbff3661a22d7fc8ca2a837afbc5ba98c",
        "edition": "2252",
        "type": "Acid Trip",
        "date": 1677694372062,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 61,
        "maxHealth": 353,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2252",
        "rarity_score": 238.21200315266682,
        "rarity_level": "COMMON"
    },
    "1701": {
        "name": "Brawler Bears #1701",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1701.png",
        "dna": "81b019551abb077ae6a6c3082ac4010f36938b5a",
        "edition": "1701",
        "type": "Galaxy",
        "date": 1677694373518,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1701",
        "rarity_score": 321.2573339690738,
        "rarity_level": "RARE"
    },
    "1613": {
        "name": "Brawler Bears #1613",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1613.png",
        "dna": "09dc86b264c185d56e1dbb8ff5cdef89aa6cc87c",
        "edition": "1613",
        "type": "Galaxy",
        "date": 1677694374895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 17,
        "maxHealth": 102,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1613",
        "rarity_score": 414.0812627668962,
        "rarity_level": "EPIC"
    },
    "553": {
        "name": "Brawler Bears #553",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/553.png",
        "dna": "9b55d8e719513d8fb115b75f6de29f0cef53b01e",
        "edition": "553",
        "type": "Galactic Cyborg",
        "date": 1677694375963,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Officer", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "553",
        "rarity_score": 488.3745454197089,
        "rarity_level": "EPIC"
    },
    "1553": {
        "name": "Brawler Bears #1553",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1553.png",
        "dna": "d77e391c2376bdeb60422d87efb5868b223f6660",
        "edition": "1553",
        "type": "Lava",
        "date": 1677694377093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 30,
        "maxHealth": 172,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1553",
        "rarity_score": 287.9421022577582,
        "rarity_level": "RARE"
    },
    "270": {
        "name": "Brawler Bears #270",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/270.png",
        "dna": "d95e19c22ba3ee6100bb152bbd55bdb546e4f249",
        "edition": "270",
        "type": "Deep Martian Cyborg",
        "date": 1677694378428,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "270",
        "rarity_score": 381.18629479144386,
        "rarity_level": "RARE"
    },
    "1836": {
        "name": "Brawler Bears #1836",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1836.png",
        "dna": "3e357312d3891934f8b786a7189f7b8b19a05c3e",
        "edition": "1836",
        "type": "Black Bear",
        "date": 1677694380056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 47,
        "maxHealth": 273,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1836",
        "rarity_score": 248.8454183637811,
        "rarity_level": "COMMON"
    },
    "714": {
        "name": "Brawler Bears #714",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/714.png",
        "dna": "586c2c6818f25e2ccae1c23168fbaae7961c051c",
        "edition": "714",
        "type": "Galaxy",
        "date": 1677694381143,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "714",
        "rarity_score": 379.8116767769309,
        "rarity_level": "RARE"
    },
    "1630": {
        "name": "Brawler Bears #1630",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1630.png",
        "dna": "a636d552a00b70c37f20ddf4316aeeed6351206c",
        "edition": "1630",
        "type": "Brown Bear",
        "date": 1677694381958,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1630",
        "rarity_score": 297.68777169152287,
        "rarity_level": "RARE"
    },
    "487": {
        "name": "Brawler Bears #487",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/487.png",
        "dna": "bc617825827f554956a19a3f136e8a57ed4aec20",
        "edition": "487",
        "type": "Galactic Cyborg",
        "date": 1677694383016,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "487",
        "rarity_score": 423.2789467960986,
        "rarity_level": "EPIC"
    },
    "660": {
        "name": "Brawler Bears #660",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/660.png",
        "dna": "5f196eff519adc6d822e06fed418dbc516961f20",
        "edition": "660",
        "type": "Brown Bear",
        "date": 1677694384240,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 48,
        "maxHealth": 279,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "660",
        "rarity_score": 243.81149805532323,
        "rarity_level": "COMMON"
    },
    "1846": {
        "name": "Brawler Bears #1846",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1846.png",
        "dna": "bf05266229b10c331650dfbfc665e2b5a57e6a68",
        "edition": "1846",
        "type": "Black Bear",
        "date": 1677694385038,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 73,
        "maxHealth": 420,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1846",
        "rarity_score": 90.74186253903575,
        "rarity_level": "COMMON"
    },
    "1944": {
        "name": "Brawler Bears #1944",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1944.png",
        "dna": "3902c9d81f2fd9339c26f1752eba15f6e30c3520",
        "edition": "1944",
        "type": "Panda",
        "date": 1677694387244,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1944",
        "rarity_score": 425.3751436505068,
        "rarity_level": "EPIC"
    },
    "1472": {
        "name": "Brawler Bears #1472",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1472.png",
        "dna": "75549b0e87bd2f5b83a4803bc1ef86d9a8126b86",
        "edition": "1472",
        "type": "Brown Bear",
        "date": 1677694389832,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 49,
        "maxHealth": 281,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1472",
        "rarity_score": 289.57632978046735,
        "rarity_level": "RARE"
    },
    "1730": {
        "name": "Brawler Bears #1730",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1730.png",
        "dna": "b7f5acfeff68ebf7308179eaf4ae2618de906faf",
        "edition": "1730",
        "type": "Black Bear",
        "date": 1677694391164,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 47,
        "maxHealth": 273,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1730",
        "rarity_score": 290.4103932850024,
        "rarity_level": "RARE"
    },
    "2317": {
        "name": "Brawler Bears #2317",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2317.png",
        "dna": "888a2d923d5bb87e92d61dd938586179b70eb271",
        "edition": "2317",
        "type": "Lava",
        "date": 1677694393179,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 13,
        "maxHealth": 76,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2317",
        "rarity_score": 470.82056965083757,
        "rarity_level": "EPIC"
    },
    "1223": {
        "name": "Brawler Bears #1223",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1223.png",
        "dna": "315ad24d537196ee83dd0e1873bf2026b18cdf0d",
        "edition": "1223",
        "type": "Galaxy",
        "date": 1677694394676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Fire Dept",
                "score": 284.2857142857143
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1223",
        "rarity_score": 544.3241211168511,
        "rarity_level": "LEGENDARY"
    },
    "1799": {
        "name": "Brawler Bears #1799",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1799.png",
        "dna": "510fc2ef028119782d8e0b694ec743e9da48501c",
        "edition": "1799",
        "type": "Lava",
        "date": 1677694395796,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Ribbit",
                "score": 331.6666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1799",
        "rarity_score": 603.1395940906483,
        "rarity_level": "LEGENDARY"
    },
    "10": {
        "name": "Brawler Bears #10",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/10.png",
        "dna": "a9eff73001ad69a38d8177457bafd540178253c8",
        "edition": "10",
        "type": "Brown Bear",
        "date": 1677694397204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "10",
        "rarity_score": 451.7461120424916,
        "rarity_level": "EPIC"
    },
    "1927": {
        "name": "Brawler Bears #1927",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1927.png",
        "dna": "5a2c1ef773a074397676ae8822c89a13e7702393",
        "edition": "1927",
        "type": "Galactic Cyborg",
        "date": 1677694398685,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1927",
        "rarity_score": 435.1239059837797,
        "rarity_level": "EPIC"
    },
    "1665": {
        "name": "Brawler Bears #1665",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1665.png",
        "dna": "d2c1f2430c2482f4b1ff4bc6a75e2f0e2323fd38",
        "edition": "1665",
        "type": "Cheetah",
        "date": 1677694400036,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 18,
        "maxHealth": 105,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1665",
        "rarity_score": 256.2769677323024,
        "rarity_level": "COMMON"
    },
    "892": {
        "name": "Brawler Bears #892",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/892.png",
        "dna": "3ffe8caeb1a424b27357fc1540311254ce94f8cc",
        "edition": "892",
        "type": "Acid Trip",
        "date": 1677694401036,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 40,
        "maxHealth": 228,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "892",
        "rarity_score": 241.27168388404982,
        "rarity_level": "COMMON"
    },
    "1711": {
        "name": "Brawler Bears #1711",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1711.png",
        "dna": "d525579717f0e17cfa767b8ad2560a8528ffba0b",
        "edition": "1711",
        "type": "Ice",
        "date": 1677694402176,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1711",
        "rarity_score": 194.55779367883,
        "rarity_level": "COMMON"
    },
    "783": {
        "name": "Brawler Bears #783",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/783.png",
        "dna": "a10f81664f3348f84d688f8e2355708b26c7bceb",
        "edition": "783",
        "type": "Lava",
        "date": 1677694403193,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "General",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 34,
        "maxHealth": 199,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "783",
        "rarity_score": 542.9149061902547,
        "rarity_level": "LEGENDARY"
    },
    "2153": {
        "name": "Brawler Bears #2153",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2153.png",
        "dna": "02e0db2e9f0e3650910a42c8567399f82ee476a2",
        "edition": "2153",
        "type": "Black Bear",
        "date": 1677694405476,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "2153",
        "rarity_score": 405.96721917051,
        "rarity_level": "RARE"
    },
    "962": {
        "name": "Brawler Bears #962",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/962.png",
        "dna": "05206b37adc456a050bd471c9f73a4eadedb0dc5",
        "edition": "962",
        "type": "Black Bear",
        "date": 1677694407249,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "962",
        "rarity_score": 361.56166097669046,
        "rarity_level": "RARE"
    },
    "237": {
        "name": "Brawler Bears #237",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/237.png",
        "dna": "eca45ae1d27f56ae089add6acf7ceaade01c7e32",
        "edition": "237",
        "type": "Deep Martian Cyborg",
        "date": 1677694408528,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "237",
        "rarity_score": 449.93173815621,
        "rarity_level": "EPIC"
    },
    "244": {
        "name": "Brawler Bears #244",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/244.png",
        "dna": "b2624d67a9eeee87ef8aca1b4524745ab5be7bcb",
        "edition": "244",
        "type": "Cheetah",
        "date": 1677694409618,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 49,
        "maxHealth": 284,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "244",
        "rarity_score": 169.45457236333476,
        "rarity_level": "COMMON"
    },
    "1941": {
        "name": "Brawler Bears #1941",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1941.png",
        "dna": "be3d486e6f075a863892d152ba54a63fe5f6eb25",
        "edition": "1941",
        "type": "Brown Bear",
        "date": 1677694411562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1941",
        "rarity_score": 381.43512843789244,
        "rarity_level": "RARE"
    },
    "1551": {
        "name": "Brawler Bears #1551",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1551.png",
        "dna": "1b7d95dcdf2431fc7d4b960cc9c5288a28234369",
        "edition": "1551",
        "type": "Brown Bear",
        "date": 1677694412760,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1551",
        "rarity_score": 358.14991163903176,
        "rarity_level": "RARE"
    },
    "318": {
        "name": "Brawler Bears #318",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/318.png",
        "dna": "fe0836d7c4fa4ffd944c848c40742c875af3fff3",
        "edition": "318",
        "type": "Black Bear",
        "date": 1677694414024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "318",
        "rarity_score": 424.40292127472804,
        "rarity_level": "EPIC"
    },
    "1411": {
        "name": "Brawler Bears #1411",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1411.png",
        "dna": "d5e156f3d9ae17ce3be5b27372a2c3ae7de8e25a",
        "edition": "1411",
        "type": "Brown Bear",
        "date": 1677694416498,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 27,
        "maxHealth": 155,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1411",
        "rarity_score": 275.47023082218686,
        "rarity_level": "RARE"
    },
    "1100": {
        "name": "Brawler Bears #1100",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1100.png",
        "dna": "b9bfd3105a6bb1cf2d2658b155e3ab853bd35d2a",
        "edition": "1100",
        "type": "Ice",
        "date": 1677694418939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 20,
        "maxHealth": 117,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1100",
        "rarity_score": 280.67962723476023,
        "rarity_level": "RARE"
    },
    "2048": {
        "name": "Brawler Bears #2048",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2048.png",
        "dna": "abac21877b0be05ee79117feb8ff2798528ed084",
        "edition": "2048",
        "type": "Galactic Cyborg",
        "date": 1677694421102,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "2048",
        "rarity_score": 427.2027311454815,
        "rarity_level": "EPIC"
    },
    "99": {
        "name": "Brawler Bears #99",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/99.png",
        "dna": "217b29486fdb81252d6ee2f05ac9604d192273ce",
        "edition": "99",
        "type": "Ice",
        "date": 1677694422725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 46,
        "maxHealth": 263,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "99",
        "rarity_score": 346.2138738053491,
        "rarity_level": "RARE"
    },
    "743": {
        "name": "Brawler Bears #743",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/743.png",
        "dna": "e000fe642430dce7d985d74203adb2c843ebeed4",
        "edition": "743",
        "type": "Black Bear",
        "date": 1677694423877,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Royalty", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "743",
        "rarity_score": 479.54787264756004,
        "rarity_level": "EPIC"
    },
    "1470": {
        "name": "Brawler Bears #1470",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1470.png",
        "dna": "7f5a47f4122cbe7063cdec38b0aee4312f41acf5",
        "edition": "1470",
        "type": "Black Bear",
        "date": 1677694425615,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 47,
        "maxHealth": 269,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1470",
        "rarity_score": 256.3508021435149,
        "rarity_level": "COMMON"
    },
    "1583": {
        "name": "Brawler Bears #1583",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1583.png",
        "dna": "9eb83532b2e2d78e367aa01883bda639e9695201",
        "edition": "1583",
        "type": "Ice",
        "date": 1677694426761,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 41,
        "maxHealth": 238,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1583",
        "rarity_score": 350.9398322235757,
        "rarity_level": "RARE"
    },
    "1742": {
        "name": "Brawler Bears #1742",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1742.png",
        "dna": "9f18590d377f36bad400245e11388406f1c1617d",
        "edition": "1742",
        "type": "Cheetah",
        "date": 1677694428180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 34,
        "maxHealth": 198,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1742",
        "rarity_score": 350.1725628144833,
        "rarity_level": "RARE"
    },
    "150": {
        "name": "Brawler Bears #150",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/150.png",
        "dna": "6921a7e731c62f2dae9eef0e53a5903995a7aff6",
        "edition": "150",
        "type": "Panda",
        "date": 1677694428924,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "150",
        "rarity_score": 401.5806676659653,
        "rarity_level": "RARE"
    },
    "1135": {
        "name": "Brawler Bears #1135",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1135.png",
        "dna": "4f66b5d1cc91fa75330db0eb9b0e19faba829eeb",
        "edition": "1135",
        "type": "Ice",
        "date": 1677694430963,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 46,
        "maxHealth": 267,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1135",
        "rarity_score": 346.91649521438984,
        "rarity_level": "RARE"
    },
    "1745": {
        "name": "Brawler Bears #1745",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1745.png",
        "dna": "3ebeb2f91a0df236c9cfcbdc6db776f0b5245b44",
        "edition": "1745",
        "type": "Galaxy",
        "date": 1677694431939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 48,
        "maxHealth": 276,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1745",
        "rarity_score": 298.1411170922119,
        "rarity_level": "RARE"
    },
    "544": {
        "name": "Brawler Bears #544",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/544.png",
        "dna": "52c84feee6177ca395f8b75a23257a9e3be4e75b",
        "edition": "544",
        "type": "Brown Bear",
        "date": 1677694432652,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 27,
        "maxHealth": 159,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "544",
        "rarity_score": 270.8736914982168,
        "rarity_level": "COMMON"
    },
    "1126": {
        "name": "Brawler Bears #1126",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1126.png",
        "dna": "ee68a54e5bed4ec83c3eb4653bbe4db6c7718698",
        "edition": "1126",
        "type": "Brown Bear",
        "date": 1677694433528,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 24,
        "maxHealth": 138,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1126",
        "rarity_score": 365.928748688129,
        "rarity_level": "RARE"
    },
    "1850": {
        "name": "Brawler Bears #1850",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1850.png",
        "dna": "8119164f48b6003df6770c4188459ab7fed81464",
        "edition": "1850",
        "type": "Cheetah",
        "date": 1677694434655,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 40,
        "maxHealth": 230,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1850",
        "rarity_score": 225.0390900345163,
        "rarity_level": "COMMON"
    },
    "1253": {
        "name": "Brawler Bears #1253",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1253.png",
        "dna": "3f4512b0f264397ad971aede5078612b435d79bd",
        "edition": "1253",
        "type": "Black Bear",
        "date": 1677694435453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "General",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1253",
        "rarity_score": 537.8913326896077,
        "rarity_level": "LEGENDARY"
    },
    "2169": {
        "name": "Brawler Bears #2169",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2169.png",
        "dna": "64d2ad6275777cc1507a246aba40fa62e914c659",
        "edition": "2169",
        "type": "Lava",
        "date": 1677694436803,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 50,
        "maxHealth": 290,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2169",
        "rarity_score": 316.43327491760857,
        "rarity_level": "RARE"
    },
    "1450": {
        "name": "Brawler Bears #1450",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1450.png",
        "dna": "4f4990a7f622bab06ec41d3457fabe820a0dcd6b",
        "edition": "1450",
        "type": "Brown Bear",
        "date": 1677694437669,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1450",
        "rarity_score": 401.93475855600394,
        "rarity_level": "RARE"
    },
    "532": {
        "name": "Brawler Bears #532",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/532.png",
        "dna": "6dfa0b74bfa03d7160a765b74b6c81a099a641ce",
        "edition": "532",
        "type": "Cheetah",
        "date": 1677694438554,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "532",
        "rarity_score": 257.41360779410013,
        "rarity_level": "COMMON"
    },
    "2315": {
        "name": "Brawler Bears #2315",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2315.png",
        "dna": "9724bf508583707e10cd6028cba4f08a511129b4",
        "edition": "2315",
        "type": "Deep Martian Cyborg",
        "date": 1677694440056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "2315",
        "rarity_score": 443.60893954071634,
        "rarity_level": "EPIC"
    },
    "521": {
        "name": "Brawler Bears #521",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/521.png",
        "dna": "b6caa44e8e9f918c82195a20f9590b26bce0a3e2",
        "edition": "521",
        "type": "Black Bear",
        "date": 1677694441724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 26,
        "maxHealth": 151,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "521",
        "rarity_score": 265.8162921900631,
        "rarity_level": "COMMON"
    },
    "1575": {
        "name": "Brawler Bears #1575",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1575.png",
        "dna": "2e212edb1dc664f1dae754bc6bb023cdd970b9ac",
        "edition": "1575",
        "type": "Black Bear",
        "date": 1677694442863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1575",
        "rarity_score": 327.7478451788754,
        "rarity_level": "RARE"
    },
    "873": {
        "name": "Brawler Bears #873",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/873.png",
        "dna": "93c4e8a6dcb428f8edd2dcd30fb55c814e0b6b73",
        "edition": "873",
        "type": "Black Bear",
        "date": 1677694443667,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 58,
        "maxHealth": 335,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "873",
        "rarity_score": 298.85806991998993,
        "rarity_level": "RARE"
    },
    "1519": {
        "name": "Brawler Bears #1519",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1519.png",
        "dna": "e45f87ec948b1ea59cef1200ebf1e9296f321fb6",
        "edition": "1519",
        "type": "Galactic Cyborg",
        "date": 1677694444574,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Peaky",
                "score": 284.2857142857143
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1519",
        "rarity_score": 514.1830319586728,
        "rarity_level": "EPIC"
    },
    "978": {
        "name": "Brawler Bears #978",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/978.png",
        "dna": "80ca5f171d8938bc44e9afbef1d8c157b2f817e4",
        "edition": "978",
        "type": "Galaxy",
        "date": 1677694445613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "978",
        "rarity_score": 242.56602305123837,
        "rarity_level": "COMMON"
    },
    "447": {
        "name": "Brawler Bears #447",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/447.png",
        "dna": "7e60057fe41f3f659b55a6f68aaa4dd831ef0470",
        "edition": "447",
        "type": "Panda",
        "date": 1677694446335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 56,
        "maxHealth": 325,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "447",
        "rarity_score": 201.87306716954564,
        "rarity_level": "COMMON"
    },
    "2182": {
        "name": "Brawler Bears #2182",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2182.png",
        "dna": "52619edb398f204a51fd722c2120c133131cc42d",
        "edition": "2182",
        "type": "Acid Trip",
        "date": 1677694447419,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2182",
        "rarity_score": 373.363890145067,
        "rarity_level": "RARE"
    },
    "1011": {
        "name": "Brawler Bears #1011",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1011.png",
        "dna": "e1b20b68f98ba7a211bedb4d428a747f7d4327da",
        "edition": "1011",
        "type": "Panda",
        "date": 1677694448362,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 19,
        "maxHealth": 111,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "1011",
        "rarity_score": 275.90525570823814,
        "rarity_level": "RARE"
    },
    "898": {
        "name": "Brawler Bears #898",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/898.png",
        "dna": "01ad54004bdd4d333374d3c1f35edf4749fa8baf",
        "edition": "898",
        "type": "Deep Martian Cyborg",
        "date": 1677694450324,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "898",
        "rarity_score": 393.11827781283245,
        "rarity_level": "RARE"
    },
    "908": {
        "name": "Brawler Bears #908",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/908.png",
        "dna": "9fccf98446c0018305f12f2c981155b8793497df",
        "edition": "908",
        "type": "Black Bear",
        "date": 1677694451529,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "908",
        "rarity_score": 395.6304619590727,
        "rarity_level": "RARE"
    },
    "1696": {
        "name": "Brawler Bears #1696",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1696.png",
        "dna": "5abc2928996e2880dd12cc065e3385db809ce7b0",
        "edition": "1696",
        "type": "Deep Martian Cyborg",
        "date": 1677694453201,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 31,
        "maxHealth": 178,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1696",
        "rarity_score": 188.0130017389017,
        "rarity_level": "COMMON"
    },
    "273": {
        "name": "Brawler Bears #273",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/273.png",
        "dna": "a2ae99454a9dd2789b382d4caf5533b8ba1a52b7",
        "edition": "273",
        "type": "Acid Trip",
        "date": 1677694454463,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 29,
        "maxHealth": 166,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "273",
        "rarity_score": 196.92046198201948,
        "rarity_level": "COMMON"
    },
    "1429": {
        "name": "Brawler Bears #1429",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1429.png",
        "dna": "5fe2cbd8c80b124a39502edfc77bc924e35c4ae6",
        "edition": "1429",
        "type": "Black Bear",
        "date": 1677694455169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1429",
        "rarity_score": 494.2370583802432,
        "rarity_level": "EPIC"
    },
    "494": {
        "name": "Brawler Bears #494",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/494.png",
        "dna": "7b91a9a3011c8b9b67d0d59dab86be6ff0403ee5",
        "edition": "494",
        "type": "Black Bear",
        "date": 1677694456005,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "494",
        "rarity_score": 267.9851309329498,
        "rarity_level": "COMMON"
    },
    "909": {
        "name": "Brawler Bears #909",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/909.png",
        "dna": "877fd763a79f2bcb10e78060dc02cd27108bb0b1",
        "edition": "909",
        "type": "Lava",
        "date": 1677694457840,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 49,
        "maxHealth": 281,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "909",
        "rarity_score": 149.4912232156997,
        "rarity_level": "COMMON"
    },
    "2206": {
        "name": "Brawler Bears #2206",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2206.png",
        "dna": "394813819829990692195b8df311ea1bf57b1df2",
        "edition": "2206",
        "type": "Cheetah",
        "date": 1677694458828,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 13,
        "maxHealth": 75,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "2206",
        "rarity_score": 460.1343478903772,
        "rarity_level": "EPIC"
    },
    "1813": {
        "name": "Brawler Bears #1813",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1813.png",
        "dna": "1e64d5df2be6d7494972521999d710d54d43203d",
        "edition": "1813",
        "type": "Galaxy",
        "date": 1677694460389,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1813",
        "rarity_score": 490.9415967899264,
        "rarity_level": "EPIC"
    },
    "120": {
        "name": "Brawler Bears #120",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/120.png",
        "dna": "fdb4f677ec846427baa90cff11391c8efc6663ed",
        "edition": "120",
        "type": "Black Bear",
        "date": 1677694461819,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "120",
        "rarity_score": 346.82935268235394,
        "rarity_level": "RARE"
    },
    "1158": {
        "name": "Brawler Bears #1158",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1158.png",
        "dna": "4cc4e07f7e0ee1d797462fe59e83d40090ddc4f8",
        "edition": "1158",
        "type": "Panda",
        "date": 1677694463721,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 25,
        "maxHealth": 144,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1158",
        "rarity_score": 389.1474658496023,
        "rarity_level": "RARE"
    },
    "33": {
        "name": "Brawler Bears #33",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/33.png",
        "dna": "23c005700f0b919a4d075511d4388358a2d1778a",
        "edition": "33",
        "type": "Brown Bear",
        "date": 1677694465559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 64,
        "maxHealth": 368,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "33",
        "rarity_score": 186.14177187962974,
        "rarity_level": "COMMON"
    },
    "1942": {
        "name": "Brawler Bears #1942",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1942.png",
        "dna": "8c7c524cacb686c8abdaf1fdb985df0a0d73c0a2",
        "edition": "1942",
        "type": "Brown Bear",
        "date": 1677694467111,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 131,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1942",
        "rarity_score": 376.32673851851416,
        "rarity_level": "RARE"
    },
    "280": {
        "name": "Brawler Bears #280",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/280.png",
        "dna": "81b5288becab4f726c281471badf43bb3469e605",
        "edition": "280",
        "type": "Black Bear",
        "date": 1677694467801,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "280",
        "rarity_score": 348.97202408120495,
        "rarity_level": "RARE"
    },
    "2311": {
        "name": "Brawler Bears #2311",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2311.png",
        "dna": "a3e199b84a1390dbdc38f6ec8037291b6440a39d",
        "edition": "2311",
        "type": "Brown Bear",
        "date": 1677694469164,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 27,
        "maxHealth": 155,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "2311",
        "rarity_score": 263.6666165254302,
        "rarity_level": "COMMON"
    },
    "1863": {
        "name": "Brawler Bears #1863",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1863.png",
        "dna": "9000f98a66eb85adaf604ea34fcc7708e8469a09",
        "edition": "1863",
        "type": "Ice",
        "date": 1677694470267,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1863",
        "rarity_score": 284.19305220649943,
        "rarity_level": "RARE"
    },
    "634": {
        "name": "Brawler Bears #634",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/634.png",
        "dna": "e77c84edef9e726b8f74888a5f4dc527bb94e5ad",
        "edition": "634",
        "type": "Panda",
        "date": 1677694471338,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "634",
        "rarity_score": 346.9281958182358,
        "rarity_level": "RARE"
    },
    "675": {
        "name": "Brawler Bears #675",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/675.png",
        "dna": "eb0346ff5efbdc08a1ae3052e382daad10dfc5ad",
        "edition": "675",
        "type": "Brown Bear",
        "date": 1677694472989,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "675",
        "rarity_score": 321.639972174412,
        "rarity_level": "RARE"
    },
    "872": {
        "name": "Brawler Bears #872",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/872.png",
        "dna": "c18e1574e5c0036eab3db9846c185f802561c241",
        "edition": "872",
        "type": "Lava",
        "date": 1677694475063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 40,
        "maxHealth": 230,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "872",
        "rarity_score": 216.23516270909295,
        "rarity_level": "COMMON"
    },
    "1193": {
        "name": "Brawler Bears #1193",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1193.png",
        "dna": "ae547bc1d93cec52ffe7fb6c4b1233feae7a5435",
        "edition": "1193",
        "type": "Black Bear",
        "date": 1677694476927,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1193",
        "rarity_score": 424.5432163709861,
        "rarity_level": "EPIC"
    },
    "2178": {
        "name": "Brawler Bears #2178",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2178.png",
        "dna": "ac71111f881e116902bf1aa30ec2a53f5c772194",
        "edition": "2178",
        "type": "Panda",
        "date": 1677694478405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 30,
        "maxHealth": 173,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "2178",
        "rarity_score": 187.405206682493,
        "rarity_level": "COMMON"
    },
    "1579": {
        "name": "Brawler Bears #1579",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1579.png",
        "dna": "9a6d2e9bb9996eac283337ac2ec752c061b11728",
        "edition": "1579",
        "type": "Ice",
        "date": 1677694480086,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1579",
        "rarity_score": 392.7633261479708,
        "rarity_level": "RARE"
    },
    "47": {
        "name": "Brawler Bears #47",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/47.png",
        "dna": "a769019e16455f6ace1d443ddaad6836925b3dc8",
        "edition": "47",
        "type": "Galactic Cyborg",
        "date": 1677694481496,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 58,
        "maxHealth": 333,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "47",
        "rarity_score": 158.3970840177374,
        "rarity_level": "COMMON"
    },
    "1991": {
        "name": "Brawler Bears #1991",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1991.png",
        "dna": "35f61b30a2574a4d700c428caaf66b5db5693618",
        "edition": "1991",
        "type": "Black Bear",
        "date": 1677694483292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 58,
        "maxHealth": 333,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1991",
        "rarity_score": 390.50186206630156,
        "rarity_level": "RARE"
    },
    "628": {
        "name": "Brawler Bears #628",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/628.png",
        "dna": "d09b4501fbb04a9de779b8a278e5018549883703",
        "edition": "628",
        "type": "Brown Bear",
        "date": 1677694484660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "628",
        "rarity_score": 368.12276904450107,
        "rarity_level": "RARE"
    },
    "974": {
        "name": "Brawler Bears #974",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/974.png",
        "dna": "3907febc303c17e448d242709ea220c3775d4fcd",
        "edition": "974",
        "type": "Brown Bear",
        "date": 1677694485834,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            { "trait_type": "Head", "value": "Officer", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "974",
        "rarity_score": 481.98150129037583,
        "rarity_level": "EPIC"
    },
    "2273": {
        "name": "Brawler Bears #2273",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2273.png",
        "dna": "71f5dcaa8ad5feee1d43b2922220afb54c8fefa9",
        "edition": "2273",
        "type": "Galactic Cyborg",
        "date": 1677694487825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2273",
        "rarity_score": 499.41535295666523,
        "rarity_level": "EPIC"
    },
    "224": {
        "name": "Brawler Bears #224",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/224.png",
        "dna": "b2cfaf16d98e9aa4854c858179c6280c1ff2fe4c",
        "edition": "224",
        "type": "Brown Bear",
        "date": 1677694489932,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 27,
        "maxHealth": 158,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "224",
        "rarity_score": 268.9508982256302,
        "rarity_level": "COMMON"
    },
    "1662": {
        "name": "Brawler Bears #1662",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1662.png",
        "dna": "1f05a165cbe7f63757aeea6155aaaee5e1556273",
        "edition": "1662",
        "type": "Galaxy",
        "date": 1677694491415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1662",
        "rarity_score": 421.87019423138713,
        "rarity_level": "EPIC"
    },
    "1550": {
        "name": "Brawler Bears #1550",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1550.png",
        "dna": "00d5be61b3bc5be14c0813e39068e2751cbdddfc",
        "edition": "1550",
        "type": "Black Bear",
        "date": 1677694493090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1550",
        "rarity_score": 401.25426901305775,
        "rarity_level": "RARE"
    },
    "380": {
        "name": "Brawler Bears #380",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/380.png",
        "dna": "ae65300c0403460a2598ce7ce57844a67a32871b",
        "edition": "380",
        "type": "Brown Bear",
        "date": 1677694494579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 59,
        "maxHealth": 338,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "380",
        "rarity_score": 145.80203143941188,
        "rarity_level": "COMMON"
    },
    "900": {
        "name": "Brawler Bears #900",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/900.png",
        "dna": "141e35de24a1f7ffd19922dfa377075c1eb05345",
        "edition": "900",
        "type": "Galaxy",
        "date": 1677694495716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "900",
        "rarity_score": 240.2081804216594,
        "rarity_level": "COMMON"
    },
    "1792": {
        "name": "Brawler Bears #1792",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1792.png",
        "dna": "0de741af95e5fdaae1a549495401243d6610d628",
        "edition": "1792",
        "type": "Galaxy",
        "date": 1677694497163,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1792",
        "rarity_score": 248.4584116689523,
        "rarity_level": "COMMON"
    },
    "2018": {
        "name": "Brawler Bears #2018",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2018.png",
        "dna": "d9092ea7f10966e2ac13e4dbcf83e919132a49b8",
        "edition": "2018",
        "type": "Acid Trip",
        "date": 1677694498854,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Royalty", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2018",
        "rarity_score": 526.8416260230383,
        "rarity_level": "LEGENDARY"
    },
    "563": {
        "name": "Brawler Bears #563",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/563.png",
        "dna": "be44d37d3a6bd80f1620cb288e9d61837271c241",
        "edition": "563",
        "type": "Galaxy",
        "date": 1677694501088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "563",
        "rarity_score": 489.68567389873607,
        "rarity_level": "EPIC"
    },
    "1693": {
        "name": "Brawler Bears #1693",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1693.png",
        "dna": "6a1c6cef0c783a6a13ee96ccfaf3960bf392c194",
        "edition": "1693",
        "type": "Panda",
        "date": 1677694502232,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1693",
        "rarity_score": 392.5824621714715,
        "rarity_level": "RARE"
    },
    "950": {
        "name": "Brawler Bears #950",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/950.png",
        "dna": "2f84f560ec3275a22fa021cfa694144d74e5a33f",
        "edition": "950",
        "type": "Galactic Cyborg",
        "date": 1677694503725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "950",
        "rarity_score": 370.7289466471336,
        "rarity_level": "RARE"
    },
    "1032": {
        "name": "Brawler Bears #1032",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1032.png",
        "dna": "35b38c7316c999aa95960d4210b4e2402b66c207",
        "edition": "1032",
        "type": "Black Bear",
        "date": 1677694506081,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "General",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1032",
        "rarity_score": 508.1604940229581,
        "rarity_level": "EPIC"
    },
    "379": {
        "name": "Brawler Bears #379",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/379.png",
        "dna": "604d887b41eedeee16a274adc7fb5c5e13c95b57",
        "edition": "379",
        "type": "Panda",
        "date": 1677694507103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 41,
        "maxHealth": 236,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "379",
        "rarity_score": 225.02343353779676,
        "rarity_level": "COMMON"
    },
    "2022": {
        "name": "Brawler Bears #2022",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2022.png",
        "dna": "c5bcc3fbbaaaf9fb4f0f6132ba74540be9b704fd",
        "edition": "2022",
        "type": "Cheetah",
        "date": 1677694509304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "2022",
        "rarity_score": 402.0955987286087,
        "rarity_level": "RARE"
    },
    "773": {
        "name": "Brawler Bears #773",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/773.png",
        "dna": "6e3a1d3c4f5dad3ccf868e7d6aca3b4f12330878",
        "edition": "773",
        "type": "Acid Trip",
        "date": 1677694511588,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 24,
        "maxHealth": 140,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "773",
        "rarity_score": 360.02853130572123,
        "rarity_level": "RARE"
    },
    "2095": {
        "name": "Brawler Bears #2095",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2095.png",
        "dna": "4223ba5ae98439c4c8f5f2307e33e9a9ba9f813e",
        "edition": "2095",
        "type": "Black Bear",
        "date": 1677694512340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Samurai Warrior",
                "score": 398.0
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 69,
        "maxHealth": 395,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2095",
        "rarity_score": 470.90769966486494,
        "rarity_level": "EPIC"
    },
    "2262": {
        "name": "Brawler Bears #2262",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2262.png",
        "dna": "b3be53b89f680e32cabf53363b95c85113d98a4b",
        "edition": "2262",
        "type": "Brown Bear",
        "date": 1677694513823,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "2262",
        "rarity_score": 417.24908093706375,
        "rarity_level": "EPIC"
    },
    "2059": {
        "name": "Brawler Bears #2059",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2059.png",
        "dna": "a4a6f154dfbcdc488638bb0ddf42814212f51bc1",
        "edition": "2059",
        "type": "Lava",
        "date": 1677694515086,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 19,
        "maxHealth": 111,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2059",
        "rarity_score": 287.88266819327526,
        "rarity_level": "RARE"
    },
    "922": {
        "name": "Brawler Bears #922",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/922.png",
        "dna": "35f154b8628659749c4a51de8ba0c613e3e74a24",
        "edition": "922",
        "type": "Black Bear",
        "date": 1677694516609,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "922",
        "rarity_score": 346.51151869527916,
        "rarity_level": "RARE"
    },
    "1997": {
        "name": "Brawler Bears #1997",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1997.png",
        "dna": "f186478d55cb288a5ddb4d5a0763ece75b4bc410",
        "edition": "1997",
        "type": "Galaxy",
        "date": 1677694518075,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 25,
        "maxHealth": 147,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1997",
        "rarity_score": 300.1826088313225,
        "rarity_level": "RARE"
    },
    "400": {
        "name": "Brawler Bears #400",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/400.png",
        "dna": "2c65cc225c251662cd66342eeebe8aee77175a39",
        "edition": "400",
        "type": "Brown Bear",
        "date": 1677694519663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 28,
        "maxHealth": 161,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "400",
        "rarity_score": 312.98981418396744,
        "rarity_level": "RARE"
    },
    "2156": {
        "name": "Brawler Bears #2156",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2156.png",
        "dna": "26330f457ea2a206dffb69fcc9985161d98a258e",
        "edition": "2156",
        "type": "Black Bear",
        "date": 1677694520954,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 64,
        "maxHealth": 370,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2156",
        "rarity_score": 144.45295580813786,
        "rarity_level": "COMMON"
    },
    "1390": {
        "name": "Brawler Bears #1390",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1390.png",
        "dna": "0cbd639c6f94c95d29be1f7b454aca8ed8f08361",
        "edition": "1390",
        "type": "Ice",
        "date": 1677694522955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1390",
        "rarity_score": 341.9128430049483,
        "rarity_level": "RARE"
    },
    "818": {
        "name": "Brawler Bears #818",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/818.png",
        "dna": "192304394bb17ab2ff29f8c293024f489e595d6f",
        "edition": "818",
        "type": "Galactic Cyborg",
        "date": 1677694524282,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 20,
        "maxHealth": 117,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "818",
        "rarity_score": 289.4365681164594,
        "rarity_level": "RARE"
    },
    "284": {
        "name": "Brawler Bears #284",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/284.png",
        "dna": "3e28c22ae26d5ec542c935bdbec016974db12610",
        "edition": "284",
        "type": "Acid Trip",
        "date": 1677694527093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "284",
        "rarity_score": 357.7732152846832,
        "rarity_level": "RARE"
    },
    "1088": {
        "name": "Brawler Bears #1088",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1088.png",
        "dna": "7d55d98d3acde57a9f9b0b7a53a20c0334b946f3",
        "edition": "1088",
        "type": "Black Bear",
        "date": 1677694528384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Fire Dept",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 59,
        "maxHealth": 339,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1088",
        "rarity_score": 417.6879032198552,
        "rarity_level": "EPIC"
    },
    "800": {
        "name": "Brawler Bears #800",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/800.png",
        "dna": "3738e4cf1957f06bf8ac756df8bf7e2f5af286f1",
        "edition": "800",
        "type": "Cheetah",
        "date": 1677694529748,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 13,
        "maxHealth": 76,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "800",
        "rarity_score": 468.4960975760324,
        "rarity_level": "EPIC"
    },
    "2229": {
        "name": "Brawler Bears #2229",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2229.png",
        "dna": "5ab5396d0d0d35b2645497aabc827c221e85339c",
        "edition": "2229",
        "type": "Deep Martian Cyborg",
        "date": 1677694531375,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 30,
        "maxHealth": 175,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "2229",
        "rarity_score": 181.88408396863778,
        "rarity_level": "COMMON"
    },
    "1091": {
        "name": "Brawler Bears #1091",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1091.png",
        "dna": "82525454bfbf5a5f9780b3ddf820ba24cf332580",
        "edition": "1091",
        "type": "Brown Bear",
        "date": 1677694533432,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1091",
        "rarity_score": 419.04788400771605,
        "rarity_level": "EPIC"
    },
    "691": {
        "name": "Brawler Bears #691",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/691.png",
        "dna": "f8dac899a3d5c9a4ffca6fd6ef780e4f0d67b5da",
        "edition": "691",
        "type": "Lava",
        "date": 1677694534560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "691",
        "rarity_score": 436.67553893750636,
        "rarity_level": "EPIC"
    },
    "646": {
        "name": "Brawler Bears #646",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/646.png",
        "dna": "c8b223262dccfb62322508e0f5495f8c47dd8ddf",
        "edition": "646",
        "type": "Black Bear",
        "date": 1677694535776,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "646",
        "rarity_score": 337.8809673261221,
        "rarity_level": "RARE"
    },
    "1663": {
        "name": "Brawler Bears #1663",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1663.png",
        "dna": "38a2e26c61b9d71502d1dcf2dba5615df824645f",
        "edition": "1663",
        "type": "Brown Bear",
        "date": 1677694537115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1663",
        "rarity_score": 193.66002121380114,
        "rarity_level": "COMMON"
    },
    "1931": {
        "name": "Brawler Bears #1931",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1931.png",
        "dna": "1c4c5d85d9bcabd89db3338e0923a85640287d4a",
        "edition": "1931",
        "type": "Black Bear",
        "date": 1677694538208,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1931",
        "rarity_score": 295.1341555192122,
        "rarity_level": "RARE"
    },
    "1640": {
        "name": "Brawler Bears #1640",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1640.png",
        "dna": "21b9de7fdba2c26c5be543885d6ba009419e0887",
        "edition": "1640",
        "type": "Brown Bear",
        "date": 1677694539532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 27,
        "maxHealth": 156,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1640",
        "rarity_score": 288.49823377874674,
        "rarity_level": "RARE"
    },
    "432": {
        "name": "Brawler Bears #432",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/432.png",
        "dna": "473b18456a4b0e797a81db2ddc9ac189714da7a2",
        "edition": "432",
        "type": "Galactic Cyborg",
        "date": 1677694540643,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 48,
        "maxHealth": 279,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "432",
        "rarity_score": 130.38691276310462,
        "rarity_level": "COMMON"
    },
    "643": {
        "name": "Brawler Bears #643",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/643.png",
        "dna": "b28086e575b32cb058f73dda0474a6d961422c14",
        "edition": "643",
        "type": "Galaxy",
        "date": 1677694542251,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "643",
        "rarity_score": 427.04983015511056,
        "rarity_level": "EPIC"
    },
    "2130": {
        "name": "Brawler Bears #2130",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2130.png",
        "dna": "652519406166de426374a7444c1227543fdcc5fa",
        "edition": "2130",
        "type": "Acid Trip",
        "date": 1677694543707,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2130",
        "rarity_score": 316.29717884922366,
        "rarity_level": "RARE"
    },
    "46": {
        "name": "Brawler Bears #46",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/46.png",
        "dna": "12f5a5f82acb90d5c9737dfa21a914da4d5b83a8",
        "edition": "46",
        "type": "Panda",
        "date": 1677694544777,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "46",
        "rarity_score": 410.4600261925775,
        "rarity_level": "RARE"
    },
    "1712": {
        "name": "Brawler Bears #1712",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1712.png",
        "dna": "3041aab2f2300cc88a5970fed381d6dc696c7fad",
        "edition": "1712",
        "type": "Galaxy",
        "date": 1677694546318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            { "trait_type": "Head", "value": "Chad", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1712",
        "rarity_score": 476.12701308262973,
        "rarity_level": "EPIC"
    },
    "439": {
        "name": "Brawler Bears #439",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/439.png",
        "dna": "76dae04c57dc174d2c17fb5948eb9a25394900d4",
        "edition": "439",
        "type": "Black Bear",
        "date": 1677694547395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 27,
        "maxHealth": 156,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "439",
        "rarity_score": 264.0874501266135,
        "rarity_level": "COMMON"
    },
    "991": {
        "name": "Brawler Bears #991",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/991.png",
        "dna": "a278b6df0a48fc539b6f1da57b4f1ab6d9e4d461",
        "edition": "991",
        "type": "Black Bear",
        "date": 1677694548657,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "991",
        "rarity_score": 347.0127556283169,
        "rarity_level": "RARE"
    },
    "2285": {
        "name": "Brawler Bears #2285",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2285.png",
        "dna": "1dc5f303dc567cbba1258b16b04ff444c24bb13d",
        "edition": "2285",
        "type": "Deep Martian Cyborg",
        "date": 1677694550764,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "2285",
        "rarity_score": 399.6214637910656,
        "rarity_level": "RARE"
    },
    "855": {
        "name": "Brawler Bears #855",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/855.png",
        "dna": "22bebc6a45ed8c0f2a117c5b2ba45915a3e713d7",
        "edition": "855",
        "type": "Black Bear",
        "date": 1677694552770,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "855",
        "rarity_score": 406.1289757377444,
        "rarity_level": "RARE"
    },
    "1498": {
        "name": "Brawler Bears #1498",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1498.png",
        "dna": "d5a8c239aa0aac69d0ea3d7f2fa36b3e3415d3f1",
        "edition": "1498",
        "type": "Galaxy",
        "date": 1677694554634,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1498",
        "rarity_score": 238.24332899028485,
        "rarity_level": "COMMON"
    },
    "1368": {
        "name": "Brawler Bears #1368",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1368.png",
        "dna": "359efd2c922de003d54305bc33598054b3da0d03",
        "edition": "1368",
        "type": "Deep Martian Cyborg",
        "date": 1677694555917,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1368",
        "rarity_score": 186.1291822701772,
        "rarity_level": "COMMON"
    },
    "1026": {
        "name": "Brawler Bears #1026",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1026.png",
        "dna": "6b944e63cf298331f275a11b7ef19658feac478d",
        "edition": "1026",
        "type": "Galactic Cyborg",
        "date": 1677694556970,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1026",
        "rarity_score": 380.172336395833,
        "rarity_level": "RARE"
    },
    "1558": {
        "name": "Brawler Bears #1558",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1558.png",
        "dna": "de3b84f576031c534d6fabc716fc6314c7651bf3",
        "edition": "1558",
        "type": "Galaxy",
        "date": 1677694558253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1558",
        "rarity_score": 433.29607207021,
        "rarity_level": "EPIC"
    },
    "1493": {
        "name": "Brawler Bears #1493",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1493.png",
        "dna": "0b75411f379230259101cf2da4a3ac75cd7dbd75",
        "edition": "1493",
        "type": "Black Bear",
        "date": 1677694560403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1493",
        "rarity_score": 418.31798202177805,
        "rarity_level": "EPIC"
    },
    "763": {
        "name": "Brawler Bears #763",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/763.png",
        "dna": "70a74518ec548796fb7acf3efa34ecf46c7608f4",
        "edition": "763",
        "type": "Black Bear",
        "date": 1677694561809,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "763",
        "rarity_score": 465.6927937414592,
        "rarity_level": "EPIC"
    },
    "1624": {
        "name": "Brawler Bears #1624",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1624.png",
        "dna": "89e1e39ba42c4eed2075e55f48997053b4e6c514",
        "edition": "1624",
        "type": "Panda",
        "date": 1677694562606,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 47,
        "maxHealth": 270,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1624",
        "rarity_score": 289.5257892335151,
        "rarity_level": "RARE"
    },
    "850": {
        "name": "Brawler Bears #850",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/850.png",
        "dna": "69ebca0ec74860b9ae3b014a73f24773d00c6a83",
        "edition": "850",
        "type": "Brown Bear",
        "date": 1677694563638,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "850",
        "rarity_score": 392.9944707489778,
        "rarity_level": "RARE"
    },
    "1771": {
        "name": "Brawler Bears #1771",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1771.png",
        "dna": "53a2ba7c212a8e2decaded2b94e64b7c0c3174b1",
        "edition": "1771",
        "type": "Brown Bear",
        "date": 1677694564542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1771",
        "rarity_score": 362.73118378708637,
        "rarity_level": "RARE"
    },
    "1099": {
        "name": "Brawler Bears #1099",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1099.png",
        "dna": "4684274861877540b5c4f5cb73b5dd9635d3df05",
        "edition": "1099",
        "type": "Ice",
        "date": 1677694565586,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1099",
        "rarity_score": 391.2747705931415,
        "rarity_level": "RARE"
    },
    "1644": {
        "name": "Brawler Bears #1644",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1644.png",
        "dna": "9807739850c877229b220a02e9058bc5f318aa6d",
        "edition": "1644",
        "type": "Deep Martian Cyborg",
        "date": 1677694568032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1644",
        "rarity_score": 485.9026675875596,
        "rarity_level": "EPIC"
    },
    "1723": {
        "name": "Brawler Bears #1723",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1723.png",
        "dna": "0770429a13818805d934d7d8529cc7b11406dc73",
        "edition": "1723",
        "type": "Galactic Cyborg",
        "date": 1677694570422,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 32,
        "maxHealth": 188,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1723",
        "rarity_score": 409.3507650536127,
        "rarity_level": "RARE"
    },
    "761": {
        "name": "Brawler Bears #761",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/761.png",
        "dna": "8260adbd3125663188dc4e8aa67c5dd8c7bbea57",
        "edition": "761",
        "type": "Deep Martian Cyborg",
        "date": 1677694572221,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "761",
        "rarity_score": 413.4420550609466,
        "rarity_level": "EPIC"
    },
    "1669": {
        "name": "Brawler Bears #1669",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1669.png",
        "dna": "09b5cae370cbd2febd6ce82601422d00665d3a79",
        "edition": "1669",
        "type": "Cheetah",
        "date": 1677694574216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Commander",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 50,
        "maxHealth": 290,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1669",
        "rarity_score": 381.54728558626783,
        "rarity_level": "RARE"
    },
    "1212": {
        "name": "Brawler Bears #1212",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1212.png",
        "dna": "29338ada7ccc3efe5ca089402e94752eca12d4b8",
        "edition": "1212",
        "type": "Galactic Cyborg",
        "date": 1677694577316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Head", "value": "Royalty", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1212",
        "rarity_score": 527.7686744101867,
        "rarity_level": "LEGENDARY"
    },
    "1186": {
        "name": "Brawler Bears #1186",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1186.png",
        "dna": "50815bb63c9d49f9568b4488b3ec300f736aed2e",
        "edition": "1186",
        "type": "Acid Trip",
        "date": 1677694578449,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 24,
        "maxHealth": 138,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1186",
        "rarity_score": 400.2721514337084,
        "rarity_level": "RARE"
    },
    "2250": {
        "name": "Brawler Bears #2250",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2250.png",
        "dna": "c50c744147204b27e0446415924133e03cd2dc94",
        "edition": "2250",
        "type": "Black Bear",
        "date": 1677694579375,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 47,
        "maxHealth": 270,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2250",
        "rarity_score": 227.72583544492534,
        "rarity_level": "COMMON"
    },
    "1038": {
        "name": "Brawler Bears #1038",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1038.png",
        "dna": "3f2d39ff2062a9e4b03c7065c73150b69c7eb4d0",
        "edition": "1038",
        "type": "Ice",
        "date": 1677694581087,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1038",
        "rarity_score": 424.7175449518439,
        "rarity_level": "EPIC"
    },
    "858": {
        "name": "Brawler Bears #858",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/858.png",
        "dna": "a5d3bc9a7d81fa357fa890a7e74f56df6a94fa9b",
        "edition": "858",
        "type": "Ice",
        "date": 1677694582484,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 41,
        "maxHealth": 235,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "858",
        "rarity_score": 217.5096163718057,
        "rarity_level": "COMMON"
    },
    "260": {
        "name": "Brawler Bears #260",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/260.png",
        "dna": "9c76b7c9c133567faae6af92fd288f5f47f0633f",
        "edition": "260",
        "type": "Galactic Cyborg",
        "date": 1677694584403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "260",
        "rarity_score": 455.175659939864,
        "rarity_level": "EPIC"
    },
    "1815": {
        "name": "Brawler Bears #1815",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1815.png",
        "dna": "ef1e3bd9398b1cd91c20d81b7b13d635e2a6a1ca",
        "edition": "1815",
        "type": "Galactic Cyborg",
        "date": 1677694586578,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1815",
        "rarity_score": 430.45621940686834,
        "rarity_level": "EPIC"
    },
    "586": {
        "name": "Brawler Bears #586",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/586.png",
        "dna": "fbb7e57800e3c60e08ddc0a623a0751658a15f0c",
        "edition": "586",
        "type": "Lava",
        "date": 1677694588343,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "586",
        "rarity_score": 363.06593526794757,
        "rarity_level": "RARE"
    },
    "1097": {
        "name": "Brawler Bears #1097",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1097.png",
        "dna": "b5a90c24ab6b43970d9478a5db07505814b96b37",
        "edition": "1097",
        "type": "Lava",
        "date": 1677694590060,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 19,
        "maxHealth": 110,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1097",
        "rarity_score": 259.33309515288573,
        "rarity_level": "COMMON"
    },
    "374": {
        "name": "Brawler Bears #374",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/374.png",
        "dna": "dba15d6548409200aab21fda5f64981f0cf99eb6",
        "edition": "374",
        "type": "Lava",
        "date": 1677694591621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 61,
        "maxHealth": 353,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "374",
        "rarity_score": 267.59541505551437,
        "rarity_level": "COMMON"
    },
    "882": {
        "name": "Brawler Bears #882",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/882.png",
        "dna": "1dd3a15fabd61b9e73b42eac0dd6bb91847290bb",
        "edition": "882",
        "type": "Galaxy",
        "date": 1677694593218,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "882",
        "rarity_score": 364.3142052366947,
        "rarity_level": "RARE"
    },
    "1153": {
        "name": "Brawler Bears #1153",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1153.png",
        "dna": "caa8bbfe9939d903899e26036c4ef8dc070fcdbc",
        "edition": "1153",
        "type": "Ice",
        "date": 1677694596508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1153",
        "rarity_score": 395.9857315698051,
        "rarity_level": "RARE"
    },
    "477": {
        "name": "Brawler Bears #477",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/477.png",
        "dna": "5299fe7937f590c25297fbf9fb621d49b4a41542",
        "edition": "477",
        "type": "Brown Bear",
        "date": 1677694599970,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "477",
        "rarity_score": 428.66357722940154,
        "rarity_level": "EPIC"
    },
    "2265": {
        "name": "Brawler Bears #2265",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2265.png",
        "dna": "c370b8a488560cc3eba3bea361d7f66814cb17f8",
        "edition": "2265",
        "type": "Panda",
        "date": 1677694601149,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 41,
        "maxHealth": 234,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2265",
        "rarity_score": 288.36087285417983,
        "rarity_level": "RARE"
    },
    "2230": {
        "name": "Brawler Bears #2230",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2230.png",
        "dna": "d63b57a5504baf49f1c2b67b328f685404d32213",
        "edition": "2230",
        "type": "Panda",
        "date": 1677694603163,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 20,
        "maxHealth": 114,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "2230",
        "rarity_score": 274.7890840047341,
        "rarity_level": "COMMON"
    },
    "1903": {
        "name": "Brawler Bears #1903",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1903.png",
        "dna": "4f49a3d781b08736fe004b60b33d86583d6c58a6",
        "edition": "1903",
        "type": "Brown Bear",
        "date": 1677694604430,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 48,
        "maxHealth": 276,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1903",
        "rarity_score": 202.0875816408257,
        "rarity_level": "COMMON"
    },
    "1988": {
        "name": "Brawler Bears #1988",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1988.png",
        "dna": "02e1b2e04f4dc94c68cc5440efa55b870950dbad",
        "edition": "1988",
        "type": "Black Bear",
        "date": 1677694605462,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 46,
        "maxHealth": 266,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1988",
        "rarity_score": 245.5749728805334,
        "rarity_level": "COMMON"
    },
    "1205": {
        "name": "Brawler Bears #1205",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1205.png",
        "dna": "eda823cc245c66f69ff6c6784b22f7e461926e3d",
        "edition": "1205",
        "type": "Lava",
        "date": 1677694607031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1205",
        "rarity_score": 420.1606927641033,
        "rarity_level": "EPIC"
    },
    "70": {
        "name": "Brawler Bears #70",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/70.png",
        "dna": "be7f9ce6e2b7e0210e1a761b120b01381a6cb1e7",
        "edition": "70",
        "type": "Deep Martian Cyborg",
        "date": 1677694607939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 63,
        "maxHealth": 361,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "70",
        "rarity_score": 224.9918637100437,
        "rarity_level": "COMMON"
    },
    "102": {
        "name": "Brawler Bears #102",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/102.png",
        "dna": "b39abda7f41f5ba099ffc7b302065d42fc8e1926",
        "edition": "102",
        "type": "Brown Bear",
        "date": 1677694608866,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "102",
        "rarity_score": 327.97472624679386,
        "rarity_level": "RARE"
    },
    "231": {
        "name": "Brawler Bears #231",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/231.png",
        "dna": "4e49cbc8e198aa8d381eb113bc3c04cc5e5ad30f",
        "edition": "231",
        "type": "Ice",
        "date": 1677694610739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 47,
        "maxHealth": 272,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "231",
        "rarity_score": 295.42470495263547,
        "rarity_level": "RARE"
    },
    "1318": {
        "name": "Brawler Bears #1318",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1318.png",
        "dna": "579a5bf2b1f227bc238f90c2433c26f601426243",
        "edition": "1318",
        "type": "Galaxy",
        "date": 1677694612966,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1318",
        "rarity_score": 397.5452581343577,
        "rarity_level": "RARE"
    },
    "779": {
        "name": "Brawler Bears #779",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/779.png",
        "dna": "1cbc05d4a51da68602261a9fadc3f6d2c33129ab",
        "edition": "779",
        "type": "Lava",
        "date": 1677694614193,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Royalty", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 29,
        "maxHealth": 170,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "779",
        "rarity_score": 509.51488290980717,
        "rarity_level": "EPIC"
    },
    "50": {
        "name": "Brawler Bears #50",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/50.png",
        "dna": "255ed9c9bf9ebfb780550f4f0a34772481603363",
        "edition": "50",
        "type": "Black Bear",
        "date": 1677694616541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "50",
        "rarity_score": 130.2542629649313,
        "rarity_level": "COMMON"
    },
    "1344": {
        "name": "Brawler Bears #1344",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1344.png",
        "dna": "ab79e54a3bbc2f50c790f4ab5a6d27041453675f",
        "edition": "1344",
        "type": "Brown Bear",
        "date": 1677694618292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 48,
        "maxHealth": 279,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1344",
        "rarity_score": 228.25155963982078,
        "rarity_level": "COMMON"
    },
    "645": {
        "name": "Brawler Bears #645",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/645.png",
        "dna": "6fa5600640a4da72495fd844ef96992b063a5121",
        "edition": "645",
        "type": "Brown Bear",
        "date": 1677694619815,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "645",
        "rarity_score": 429.49266903116654,
        "rarity_level": "EPIC"
    },
    "1806": {
        "name": "Brawler Bears #1806",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1806.png",
        "dna": "5e4151a389f1136a623af574b073d99123a4330f",
        "edition": "1806",
        "type": "Black Bear",
        "date": 1677694621011,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1806",
        "rarity_score": 341.4373512725989,
        "rarity_level": "RARE"
    },
    "1050": {
        "name": "Brawler Bears #1050",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1050.png",
        "dna": "1b7cb37b62a3d33d841551ebb85e9dbf4184c3ef",
        "edition": "1050",
        "type": "Deep Martian Cyborg",
        "date": 1677694622621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 47,
        "maxHealth": 270,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1050",
        "rarity_score": 238.9995995016708,
        "rarity_level": "COMMON"
    },
    "268": {
        "name": "Brawler Bears #268",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/268.png",
        "dna": "e90be20746ca480ec81914059a8221e0352492f0",
        "edition": "268",
        "type": "Galaxy",
        "date": 1677694624114,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 27,
        "maxHealth": 156,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "268",
        "rarity_score": 358.51990841118567,
        "rarity_level": "RARE"
    },
    "2274": {
        "name": "Brawler Bears #2274",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2274.png",
        "dna": "d9606800e30869f13d6fe7952d1d2599d83a7201",
        "edition": "2274",
        "type": "Brown Bear",
        "date": 1677694625109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2274",
        "rarity_score": 371.96094492692754,
        "rarity_level": "RARE"
    },
    "939": {
        "name": "Brawler Bears #939",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/939.png",
        "dna": "e634c9e073d0d7d2bea3dd6c017387c56202f539",
        "edition": "939",
        "type": "Deep Martian Cyborg",
        "date": 1677694626220,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "939",
        "rarity_score": 263.3806466611412,
        "rarity_level": "COMMON"
    },
    "1605": {
        "name": "Brawler Bears #1605",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1605.png",
        "dna": "527601039dd202de651d8cd80522a9a120dcd215",
        "edition": "1605",
        "type": "Galactic Cyborg",
        "date": 1677694627838,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 36,
        "maxHealth": 211,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1605",
        "rarity_score": 397.6243825270931,
        "rarity_level": "RARE"
    },
    "2146": {
        "name": "Brawler Bears #2146",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2146.png",
        "dna": "886b6176f46698913fa9f7d2f4bf888f8d516b1c",
        "edition": "2146",
        "type": "Brown Bear",
        "date": 1677694630082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2146",
        "rarity_score": 379.4665097450129,
        "rarity_level": "RARE"
    },
    "1687": {
        "name": "Brawler Bears #1687",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1687.png",
        "dna": "96ff5bd583e759d1adcc1a86eb1743b81bb7e895",
        "edition": "1687",
        "type": "Galaxy",
        "date": 1677694631823,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1687",
        "rarity_score": 322.18643198452014,
        "rarity_level": "RARE"
    },
    "2096": {
        "name": "Brawler Bears #2096",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2096.png",
        "dna": "3d0632bf4afca674847590112be8310c273003ec",
        "edition": "2096",
        "type": "Acid Trip",
        "date": 1677694633430,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 19,
        "maxHealth": 113,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2096",
        "rarity_score": 266.45892798345704,
        "rarity_level": "COMMON"
    },
    "876": {
        "name": "Brawler Bears #876",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/876.png",
        "dna": "0374d24f49be739e05a15dc1d3a8e2034ba9cbfe",
        "edition": "876",
        "type": "Lava",
        "date": 1677694634805,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 19,
        "maxHealth": 112,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "876",
        "rarity_score": 274.2436120775944,
        "rarity_level": "COMMON"
    },
    "1165": {
        "name": "Brawler Bears #1165",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1165.png",
        "dna": "d91e24ad0e2b04e579d7b959c77e9da70794848a",
        "edition": "1165",
        "type": "Acid Trip",
        "date": 1677694636162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 30,
        "maxHealth": 174,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1165",
        "rarity_score": 437.36753538194387,
        "rarity_level": "EPIC"
    },
    "1334": {
        "name": "Brawler Bears #1334",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1334.png",
        "dna": "0f97d429ff5dd94b2221383902ab861bef93b241",
        "edition": "1334",
        "type": "Galactic Cyborg",
        "date": 1677694637703,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1334",
        "rarity_score": 450.79140142627546,
        "rarity_level": "EPIC"
    },
    "55": {
        "name": "Brawler Bears #55",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/55.png",
        "dna": "092985e55fafccf2d2367d63eabcd00f889736ad",
        "edition": "55",
        "type": "Ice",
        "date": 1677694639709,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "55",
        "rarity_score": 396.022325951286,
        "rarity_level": "RARE"
    },
    "2260": {
        "name": "Brawler Bears #2260",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2260.png",
        "dna": "077a584ea13695debacc42339c45209c175f6075",
        "edition": "2260",
        "type": "Ice",
        "date": 1677694641090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2260",
        "rarity_score": 364.45669611270813,
        "rarity_level": "RARE"
    },
    "1875": {
        "name": "Brawler Bears #1875",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1875.png",
        "dna": "61267297ee93fdcb5be309c9eb52c7774a41772e",
        "edition": "1875",
        "type": "Brown Bear",
        "date": 1677694642426,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 26,
        "maxHealth": 152,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "1875",
        "rarity_score": 293.84353561082355,
        "rarity_level": "RARE"
    },
    "2": {
        "name": "Brawler Bears #2",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2.png",
        "dna": "703bbd3ba006ca93f113e0efdcac694df1f98853",
        "edition": "2",
        "type": "Galaxy",
        "date": 1677694644649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2",
        "rarity_score": 274.35171898925773,
        "rarity_level": "COMMON"
    },
    "824": {
        "name": "Brawler Bears #824",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/824.png",
        "dna": "721f2dec42368e3b4e608ed48cf7e4a238ece2e3",
        "edition": "824",
        "type": "Ice",
        "date": 1677694646031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "824",
        "rarity_score": 512.0973424457503,
        "rarity_level": "EPIC"
    },
    "168": {
        "name": "Brawler Bears #168",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/168.png",
        "dna": "439870018195150785f2b51861782dff561a687d",
        "edition": "168",
        "type": "Deep Martian Cyborg",
        "date": 1677694647316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "168",
        "rarity_score": 472.64408483730665,
        "rarity_level": "EPIC"
    },
    "1092": {
        "name": "Brawler Bears #1092",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1092.png",
        "dna": "879da3a82e1d05a2bc7f379c7f6a9d3adba6d13f",
        "edition": "1092",
        "type": "Galaxy",
        "date": 1677694650516,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1092",
        "rarity_score": 256.95591965029683,
        "rarity_level": "COMMON"
    },
    "1699": {
        "name": "Brawler Bears #1699",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1699.png",
        "dna": "642e8e96448853253681fad899e9f1c50f568e3f",
        "edition": "1699",
        "type": "Acid Trip",
        "date": 1677694651799,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Chad", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1699",
        "rarity_score": 367.6658457004326,
        "rarity_level": "RARE"
    },
    "1112": {
        "name": "Brawler Bears #1112",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1112.png",
        "dna": "25b4a048c944156321bb5dc97db23e24f3dfabd5",
        "edition": "1112",
        "type": "Black Bear",
        "date": 1677694653085,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1112",
        "rarity_score": 382.3299966999076,
        "rarity_level": "RARE"
    },
    "1259": {
        "name": "Brawler Bears #1259",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1259.png",
        "dna": "065f3fa08df15594e4d19aa7101376d26cffba94",
        "edition": "1259",
        "type": "Galaxy",
        "date": 1677694655749,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Peaky",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 27,
        "maxHealth": 154,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1259",
        "rarity_score": 467.23311460142673,
        "rarity_level": "EPIC"
    },
    "1970": {
        "name": "Brawler Bears #1970",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1970.png",
        "dna": "7fb407e59e3f849cc375db892f1496871692ccf2",
        "edition": "1970",
        "type": "Deep Martian Cyborg",
        "date": 1677694657497,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Fire Dept",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1970",
        "rarity_score": 565.2270303099166,
        "rarity_level": "LEGENDARY"
    },
    "307": {
        "name": "Brawler Bears #307",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/307.png",
        "dna": "2317c759ef1391d3df42ec33ec61f497f9b0fd1f",
        "edition": "307",
        "type": "Galaxy",
        "date": 1677694659126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "307",
        "rarity_score": 296.4468103166003,
        "rarity_level": "RARE"
    },
    "1243": {
        "name": "Brawler Bears #1243",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1243.png",
        "dna": "9edb08aeba256c99d4db181aac08b7ccaa993d01",
        "edition": "1243",
        "type": "Galaxy",
        "date": 1677694660701,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1243",
        "rarity_score": 275.12050399530796,
        "rarity_level": "RARE"
    },
    "341": {
        "name": "Brawler Bears #341",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/341.png",
        "dna": "f76e60be76eeabd3f67f9a5f8c6b79f9125e2c90",
        "edition": "341",
        "type": "Deep Martian Cyborg",
        "date": 1677694661871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "341",
        "rarity_score": 132.22792036211277,
        "rarity_level": "COMMON"
    },
    "1686": {
        "name": "Brawler Bears #1686",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1686.png",
        "dna": "8dae771bfa4697f0719cd1a3c33b7583ee79b127",
        "edition": "1686",
        "type": "Lava",
        "date": 1677694663126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Chad", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 50,
        "maxHealth": 287,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1686",
        "rarity_score": 476.1966498388593,
        "rarity_level": "EPIC"
    },
    "847": {
        "name": "Brawler Bears #847",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/847.png",
        "dna": "3f4bb0959164fb8a9b04b11ea68f6d3c1746a605",
        "edition": "847",
        "type": "Black Bear",
        "date": 1677694664895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 52,
        "maxHealth": 301,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "847",
        "rarity_score": 131.88455987308066,
        "rarity_level": "COMMON"
    },
    "1035": {
        "name": "Brawler Bears #1035",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1035.png",
        "dna": "4d1cd9a55e876e054f0b01bf76b27ce99ca8fc01",
        "edition": "1035",
        "type": "Panda",
        "date": 1677694665790,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1035",
        "rarity_score": 194.38728190371305,
        "rarity_level": "COMMON"
    },
    "1349": {
        "name": "Brawler Bears #1349",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1349.png",
        "dna": "62340549f111b6f570fa41e7d3a01fd47ff006ce",
        "edition": "1349",
        "type": "Acid Trip",
        "date": 1677694667326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1349",
        "rarity_score": 397.5554281484935,
        "rarity_level": "RARE"
    },
    "866": {
        "name": "Brawler Bears #866",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/866.png",
        "dna": "4a76e7b88b9f8f3b74fe55f74b84aedb4e5e98c8",
        "edition": "866",
        "type": "Deep Martian Cyborg",
        "date": 1677694668499,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "866",
        "rarity_score": 514.1151458580063,
        "rarity_level": "EPIC"
    },
    "1000": {
        "name": "Brawler Bears #1000",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1000.png",
        "dna": "8fa4a4a5ee29864eca5ce2ccd96659a8779a5c55",
        "edition": "1000",
        "type": "Galactic Cyborg",
        "date": 1677694669324,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 54,
        "maxHealth": 311,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1000",
        "rarity_score": 297.33678552806504,
        "rarity_level": "RARE"
    },
    "1617": {
        "name": "Brawler Bears #1617",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1617.png",
        "dna": "4a98d53525156f0b88188b78dbcb0a63537c49d5",
        "edition": "1617",
        "type": "Black Bear",
        "date": 1677694670760,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 27,
        "maxHealth": 159,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1617",
        "rarity_score": 237.01276637157227,
        "rarity_level": "COMMON"
    },
    "154": {
        "name": "Brawler Bears #154",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/154.png",
        "dna": "acdd56c7a3204fa45d0c345abe3958e78517d96a",
        "edition": "154",
        "type": "Galaxy",
        "date": 1677694671841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "154",
        "rarity_score": 405.2130850229163,
        "rarity_level": "RARE"
    },
    "732": {
        "name": "Brawler Bears #732",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/732.png",
        "dna": "4cac30d3fe673d2ee36d8c8a7033fb354de804a9",
        "edition": "732",
        "type": "Cheetah",
        "date": 1677694673035,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "732",
        "rarity_score": 385.04755100387285,
        "rarity_level": "RARE"
    },
    "272": {
        "name": "Brawler Bears #272",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/272.png",
        "dna": "11c1db3e9f3264523a6f4c7272679fdcc8af977f",
        "edition": "272",
        "type": "Brown Bear",
        "date": 1677694674149,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 54,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "272",
        "rarity_score": 288.948255616917,
        "rarity_level": "RARE"
    },
    "771": {
        "name": "Brawler Bears #771",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/771.png",
        "dna": "be92ecef07840c3bf938a46c34cff0e929caca6a",
        "edition": "771",
        "type": "Cheetah",
        "date": 1677694676132,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 12,
        "maxHealth": 73,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "771",
        "rarity_score": 392.4092129264543,
        "rarity_level": "RARE"
    },
    "1108": {
        "name": "Brawler Bears #1108",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1108.png",
        "dna": "ecf91796e8d902997d5467e36bf909a8f7449115",
        "edition": "1108",
        "type": "Deep Martian Cyborg",
        "date": 1677694677247,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1108",
        "rarity_score": 206.28759257757758,
        "rarity_level": "COMMON"
    },
    "814": {
        "name": "Brawler Bears #814",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/814.png",
        "dna": "14495ede1993f7bd5629e383fc875eee58d6e4ea",
        "edition": "814",
        "type": "Galaxy",
        "date": 1677694679656,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "814",
        "rarity_score": 253.39365743143335,
        "rarity_level": "COMMON"
    },
    "1961": {
        "name": "Brawler Bears #1961",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1961.png",
        "dna": "9d556a93f767ac7ad7ba0bfe8ea195f46239fca3",
        "edition": "1961",
        "type": "Panda",
        "date": 1677694681218,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1961",
        "rarity_score": 510.38427258931137,
        "rarity_level": "EPIC"
    },
    "489": {
        "name": "Brawler Bears #489",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/489.png",
        "dna": "fcd137923024def5fc67e17914be1d19e32ffdee",
        "edition": "489",
        "type": "Black Bear",
        "date": 1677694682222,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 59,
        "maxHealth": 338,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "489",
        "rarity_score": 290.68844510036513,
        "rarity_level": "RARE"
    },
    "785": {
        "name": "Brawler Bears #785",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/785.png",
        "dna": "ec5638374e3d7a964ac169e88085217fe9ff8dc4",
        "edition": "785",
        "type": "Panda",
        "date": 1677694683987,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 40,
        "maxHealth": 232,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "785",
        "rarity_score": 232.1524749869235,
        "rarity_level": "COMMON"
    },
    "2123": {
        "name": "Brawler Bears #2123",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2123.png",
        "dna": "84ff580630ebd3c115245c2d5093c5de0dfeab01",
        "edition": "2123",
        "type": "Ice",
        "date": 1677694686225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2123",
        "rarity_score": 280.0252958041085,
        "rarity_level": "RARE"
    },
    "9": {
        "name": "Brawler Bears #9",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/9.png",
        "dna": "be492d019e83676e70c80e774ba3c5199aa8df37",
        "edition": "9",
        "type": "Panda",
        "date": 1677694687397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "9",
        "rarity_score": 446.99243373585483,
        "rarity_level": "EPIC"
    },
    "481": {
        "name": "Brawler Bears #481",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/481.png",
        "dna": "8effd41d95f1b9426e58693cc5bc03f050ca6f09",
        "edition": "481",
        "type": "Galaxy",
        "date": 1677694689056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 26,
        "maxHealth": 151,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "481",
        "rarity_score": 317.86294206160386,
        "rarity_level": "RARE"
    },
    "2302": {
        "name": "Brawler Bears #2302",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2302.png",
        "dna": "42fc730444c6eed8e9f11e624a2abaf3969508c2",
        "edition": "2302",
        "type": "Black Bear",
        "date": 1677694690097,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 245,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2302",
        "rarity_score": 431.2014693323571,
        "rarity_level": "EPIC"
    },
    "2193": {
        "name": "Brawler Bears #2193",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2193.png",
        "dna": "56990e0216d9183f191ad27ad19b785fb47ee9aa",
        "edition": "2193",
        "type": "Black Bear",
        "date": 1677694692644,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "2193",
        "rarity_score": 434.0665975287383,
        "rarity_level": "EPIC"
    },
    "135": {
        "name": "Brawler Bears #135",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/135.png",
        "dna": "5950bfde66ee892df9b9625c84d3cca651aa2130",
        "edition": "135",
        "type": "Brown Bear",
        "date": 1677694693893,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "135",
        "rarity_score": 403.7273912329563,
        "rarity_level": "RARE"
    },
    "1845": {
        "name": "Brawler Bears #1845",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1845.png",
        "dna": "28e35c4363082e5f886d0c27d5d1f53e945f0a94",
        "edition": "1845",
        "type": "Black Bear",
        "date": 1677694696112,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1845",
        "rarity_score": 412.11484405177634,
        "rarity_level": "RARE"
    },
    "464": {
        "name": "Brawler Bears #464",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/464.png",
        "dna": "7d62bfbb216b82277a4f94b59bdf8342432b5f66",
        "edition": "464",
        "type": "Acid Trip",
        "date": 1677694699153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            { "trait_type": "Head", "value": "Officer", "score": 248.75 },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "464",
        "rarity_score": 576.0221665599613,
        "rarity_level": "LEGENDARY"
    },
    "1352": {
        "name": "Brawler Bears #1352",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1352.png",
        "dna": "b753e4ff8b9e2b7c353cb2dcb93c68d7017bab78",
        "edition": "1352",
        "type": "Brown Bear",
        "date": 1677694701674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 45,
        "maxHealth": 258,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1352",
        "rarity_score": 339.4814513788515,
        "rarity_level": "RARE"
    },
    "1808": {
        "name": "Brawler Bears #1808",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1808.png",
        "dna": "b593009c10242244621e433ddc9b0b39d1609875",
        "edition": "1808",
        "type": "Ice",
        "date": 1677694704977,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 46,
        "maxHealth": 267,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1808",
        "rarity_score": 148.6550474070836,
        "rarity_level": "COMMON"
    },
    "1110": {
        "name": "Brawler Bears #1110",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1110.png",
        "dna": "b36d40f0fed510ddb145cb8d98a6593aff4a695d",
        "edition": "1110",
        "type": "Brown Bear",
        "date": 1677694707639,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1110",
        "rarity_score": 338.594727512468,
        "rarity_level": "RARE"
    },
    "548": {
        "name": "Brawler Bears #548",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/548.png",
        "dna": "10dd02e1619a7296dbf414a48dd02f3c7ed7ce7f",
        "edition": "548",
        "type": "Acid Trip",
        "date": 1677694710986,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 50,
        "maxHealth": 290,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "548",
        "rarity_score": 136.29867438789978,
        "rarity_level": "COMMON"
    },
    "590": {
        "name": "Brawler Bears #590",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/590.png",
        "dna": "547afda7b9947435cef770a853f1b6f7dc1e4084",
        "edition": "590",
        "type": "Deep Martian Cyborg",
        "date": 1677694712530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 20,
        "maxHealth": 116,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "590",
        "rarity_score": 317.0305179151263,
        "rarity_level": "RARE"
    },
    "518": {
        "name": "Brawler Bears #518",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/518.png",
        "dna": "e5612000c154d693650f054742e7cd1778fc2925",
        "edition": "518",
        "type": "Black Bear",
        "date": 1677694714777,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "518",
        "rarity_score": 428.79226062795226,
        "rarity_level": "EPIC"
    },
    "1413": {
        "name": "Brawler Bears #1413",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1413.png",
        "dna": "284bb1a668539ffd519c63d760747b2cd4b6561c",
        "edition": "1413",
        "type": "Deep Martian Cyborg",
        "date": 1677694718113,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 48,
        "maxHealth": 275,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1413",
        "rarity_score": 271.12911202131966,
        "rarity_level": "COMMON"
    },
    "466": {
        "name": "Brawler Bears #466",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/466.png",
        "dna": "4909f27261e723450c2628af4bb81dce7661e956",
        "edition": "466",
        "type": "Galaxy",
        "date": 1677694720382,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Ribbit",
                "score": 331.6666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 54,
        "maxHealth": 310,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "466",
        "rarity_score": 495.339436186826,
        "rarity_level": "EPIC"
    },
    "1950": {
        "name": "Brawler Bears #1950",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1950.png",
        "dna": "6a4074aadef79d306dc14300369d3e968cf5fa97",
        "edition": "1950",
        "type": "Brown Bear",
        "date": 1677694721660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1950",
        "rarity_score": 373.97916288787314,
        "rarity_level": "RARE"
    },
    "1043": {
        "name": "Brawler Bears #1043",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1043.png",
        "dna": "87e8b5e048f4f979caa513a103e199188495e704",
        "edition": "1043",
        "type": "Black Bear",
        "date": 1677694723630,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1043",
        "rarity_score": 383.02974955106765,
        "rarity_level": "RARE"
    },
    "1926": {
        "name": "Brawler Bears #1926",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1926.png",
        "dna": "474b7d168838d001424e008b3184bc090dd3890b",
        "edition": "1926",
        "type": "Brown Bear",
        "date": 1677694724634,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1926",
        "rarity_score": 367.60517002784877,
        "rarity_level": "RARE"
    },
    "31": {
        "name": "Brawler Bears #31",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/31.png",
        "dna": "f4cc1f860b2b57acbe3634dcae07f8ab0095e7e7",
        "edition": "31",
        "type": "Galaxy",
        "date": 1677694725771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 41,
        "maxHealth": 238,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "31",
        "rarity_score": 232.20506378788212,
        "rarity_level": "COMMON"
    },
    "663": {
        "name": "Brawler Bears #663",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/663.png",
        "dna": "bd3ab5c9965b81f6a1eb0650740841815c657c0e",
        "edition": "663",
        "type": "Lava",
        "date": 1677694727461,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 45,
        "maxHealth": 259,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "663",
        "rarity_score": 320.514866971599,
        "rarity_level": "RARE"
    },
    "2047": {
        "name": "Brawler Bears #2047",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2047.png",
        "dna": "1570baf906e56aebd5ee59f8ce36bbef376d8d2f",
        "edition": "2047",
        "type": "Brown Bear",
        "date": 1677694728443,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Fire Dept",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2047",
        "rarity_score": 506.75059111163137,
        "rarity_level": "EPIC"
    },
    "707": {
        "name": "Brawler Bears #707",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/707.png",
        "dna": "554a3f1b740bef459ca092731918613629e0d853",
        "edition": "707",
        "type": "Brown Bear",
        "date": 1677694729403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 50,
        "maxHealth": 285,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "707",
        "rarity_score": 293.36834685010257,
        "rarity_level": "RARE"
    },
    "458": {
        "name": "Brawler Bears #458",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/458.png",
        "dna": "8f81f579dc81ba3027bdc3697a05ebb0aac83a35",
        "edition": "458",
        "type": "Acid Trip",
        "date": 1677694731465,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Peaky",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "458",
        "rarity_score": 570.6281772375773,
        "rarity_level": "LEGENDARY"
    },
    "1233": {
        "name": "Brawler Bears #1233",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1233.png",
        "dna": "b2c3c035e982710a84834180279a287d795bd9db",
        "edition": "1233",
        "type": "Brown Bear",
        "date": 1677694732825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 43,
        "maxHealth": 251,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1233",
        "rarity_score": 187.46718311811767,
        "rarity_level": "COMMON"
    },
    "731": {
        "name": "Brawler Bears #731",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/731.png",
        "dna": "89c0701c6e2135e357069e7009937057c8e38582",
        "edition": "731",
        "type": "Brown Bear",
        "date": 1677694733515,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "731",
        "rarity_score": 328.1445666841723,
        "rarity_level": "RARE"
    },
    "1190": {
        "name": "Brawler Bears #1190",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1190.png",
        "dna": "6c2ddc7f45ba34a2e0a06d69503537e8a06639cd",
        "edition": "1190",
        "type": "Galactic Cyborg",
        "date": 1677694734925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1190",
        "rarity_score": 409.5088227936027,
        "rarity_level": "RARE"
    },
    "603": {
        "name": "Brawler Bears #603",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/603.png",
        "dna": "342d9728992712780fb36512f0af9bc7337f514d",
        "edition": "603",
        "type": "Panda",
        "date": 1677694735855,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 40,
        "maxHealth": 229,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "603",
        "rarity_score": 246.6460101314423,
        "rarity_level": "COMMON"
    },
    "1527": {
        "name": "Brawler Bears #1527",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1527.png",
        "dna": "604ed8884536c5bbbfd19986b4b3fe480e14da4f",
        "edition": "1527",
        "type": "Galaxy",
        "date": 1677694736919,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1527",
        "rarity_score": 310.55024651231236,
        "rarity_level": "RARE"
    },
    "685": {
        "name": "Brawler Bears #685",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/685.png",
        "dna": "edf848e987a3698de7fbdfd71ffa3c36d4c56f62",
        "edition": "685",
        "type": "Cheetah",
        "date": 1677694737997,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "685",
        "rarity_score": 467.1931593164904,
        "rarity_level": "EPIC"
    },
    "388": {
        "name": "Brawler Bears #388",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/388.png",
        "dna": "e343f9d696f563ff8ceb204e9242721e73e4a26b",
        "edition": "388",
        "type": "Acid Trip",
        "date": 1677694739051,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 20,
        "maxHealth": 118,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "388",
        "rarity_score": 263.24881283245907,
        "rarity_level": "COMMON"
    },
    "1488": {
        "name": "Brawler Bears #1488",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1488.png",
        "dna": "a89a6145337db5f5a78d9f1ef1b293f44632eb7b",
        "edition": "1488",
        "type": "Brown Bear",
        "date": 1677694740686,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Ribbit",
                "score": 331.6666666666667
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1488",
        "rarity_score": 554.260178615141,
        "rarity_level": "LEGENDARY"
    },
    "2239": {
        "name": "Brawler Bears #2239",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2239.png",
        "dna": "65d457db040b7efc969c89c1c193c68929e88972",
        "edition": "2239",
        "type": "Lava",
        "date": 1677694741746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2239",
        "rarity_score": 412.08085000313866,
        "rarity_level": "RARE"
    },
    "719": {
        "name": "Brawler Bears #719",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/719.png",
        "dna": "8573a2f5a4dc898c493483baedb68d03db381bbe",
        "edition": "719",
        "type": "Black Bear",
        "date": 1677694743003,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "719",
        "rarity_score": 323.5827778205233,
        "rarity_level": "RARE"
    },
    "1147": {
        "name": "Brawler Bears #1147",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1147.png",
        "dna": "b910da5ac49d23a2ee62f6aaf1ac96fc1b0d6486",
        "edition": "1147",
        "type": "Galaxy",
        "date": 1677694745161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1147",
        "rarity_score": 189.14908547982395,
        "rarity_level": "COMMON"
    },
    "1828": {
        "name": "Brawler Bears #1828",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1828.png",
        "dna": "d63d67a1e2c886df32b987660e82e7497fa488ab",
        "edition": "1828",
        "type": "Black Bear",
        "date": 1677694746319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 73,
        "maxHealth": 418,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1828",
        "rarity_score": 92.78353478783811,
        "rarity_level": "COMMON"
    },
    "1842": {
        "name": "Brawler Bears #1842",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1842.png",
        "dna": "3b7fe1690331892fa151cd52e6f6f2d16445285f",
        "edition": "1842",
        "type": "Deep Martian Cyborg",
        "date": 1677694747500,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 63,
        "maxHealth": 364,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1842",
        "rarity_score": 261.30189991919457,
        "rarity_level": "COMMON"
    },
    "1678": {
        "name": "Brawler Bears #1678",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1678.png",
        "dna": "202db153eac1c2359203a90e84fbd26c136ea57a",
        "edition": "1678",
        "type": "Galaxy",
        "date": 1677694748468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1678",
        "rarity_score": 427.18531940100075,
        "rarity_level": "EPIC"
    },
    "293": {
        "name": "Brawler Bears #293",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/293.png",
        "dna": "00cbd14d30ee2a40b8f74eaafd90b1a21f78c701",
        "edition": "293",
        "type": "Black Bear",
        "date": 1677694749712,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "293",
        "rarity_score": 378.5627686229269,
        "rarity_level": "RARE"
    },
    "2118": {
        "name": "Brawler Bears #2118",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2118.png",
        "dna": "76b5471b98e302ffd5db1621a0b49eef9aa14f29",
        "edition": "2118",
        "type": "Acid Trip",
        "date": 1677694751134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2118",
        "rarity_score": 287.13367366719007,
        "rarity_level": "RARE"
    },
    "610": {
        "name": "Brawler Bears #610",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/610.png",
        "dna": "fb23da8a85fbc927b0e6befc3b6373b6a8cc219c",
        "edition": "610",
        "type": "Black Bear",
        "date": 1677694752526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "610",
        "rarity_score": 302.9498981720788,
        "rarity_level": "RARE"
    },
    "2006": {
        "name": "Brawler Bears #2006",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2006.png",
        "dna": "38d38d57594c97ec219b2f4a23dc8ffa746e9eac",
        "edition": "2006",
        "type": "Black Bear",
        "date": 1677694754391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 42,
        "maxHealth": 242,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2006",
        "rarity_score": 329.2686773421736,
        "rarity_level": "RARE"
    },
    "516": {
        "name": "Brawler Bears #516",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/516.png",
        "dna": "a6add63a35e60b5f48e96f4dd17916d72183a04f",
        "edition": "516",
        "type": "Lava",
        "date": 1677694755427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "516",
        "rarity_score": 386.76157700047975,
        "rarity_level": "RARE"
    },
    "287": {
        "name": "Brawler Bears #287",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/287.png",
        "dna": "8e4107cd18f8f295df13e358f9a87043669156e6",
        "edition": "287",
        "type": "Black Bear",
        "date": 1677694756560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "287",
        "rarity_score": 439.24513692800423,
        "rarity_level": "EPIC"
    },
    "799": {
        "name": "Brawler Bears #799",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/799.png",
        "dna": "fea378142a364a3e08b0535dc500f5f1cc844bbf",
        "edition": "799",
        "type": "Black Bear",
        "date": 1677694757382,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "799",
        "rarity_score": 412.9541797254239,
        "rarity_level": "EPIC"
    },
    "1661": {
        "name": "Brawler Bears #1661",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1661.png",
        "dna": "9ca60ec1b84f06301e111a79f0007ea2f09a39ee",
        "edition": "1661",
        "type": "Galaxy",
        "date": 1677694758440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1661",
        "rarity_score": 486.42508780721766,
        "rarity_level": "EPIC"
    },
    "1782": {
        "name": "Brawler Bears #1782",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1782.png",
        "dna": "e6b7a43b9e7473e54731c0ed9ad2c910a31bf2c9",
        "edition": "1782",
        "type": "Brown Bear",
        "date": 1677694759434,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1782",
        "rarity_score": 347.73534816227436,
        "rarity_level": "RARE"
    },
    "1689": {
        "name": "Brawler Bears #1689",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1689.png",
        "dna": "d7852cad0208a444b281b0969b5260a488980e56",
        "edition": "1689",
        "type": "Lava",
        "date": 1677694760963,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Bandit", "score": 398.0 },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1689",
        "rarity_score": 604.6615261162225,
        "rarity_level": "LEGENDARY"
    },
    "48": {
        "name": "Brawler Bears #48",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/48.png",
        "dna": "240e1d0ca27e5a1ae3edede424b4a120b32f6eb3",
        "edition": "48",
        "type": "Brown Bear",
        "date": 1677694761861,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "48",
        "rarity_score": 419.0345945186187,
        "rarity_level": "EPIC"
    },
    "871": {
        "name": "Brawler Bears #871",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/871.png",
        "dna": "7d71effbb0e20c8181db41084f923b51561a835f",
        "edition": "871",
        "type": "Acid Trip",
        "date": 1677694762936,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 46,
        "maxHealth": 263,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "871",
        "rarity_score": 312.32285067077765,
        "rarity_level": "RARE"
    },
    "622": {
        "name": "Brawler Bears #622",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/622.png",
        "dna": "7a5e77b4e6cc603b9aa02ff67347782f194f0fa0",
        "edition": "622",
        "type": "Galaxy",
        "date": 1677694763891,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "622",
        "rarity_score": 128.0907453920611,
        "rarity_level": "COMMON"
    },
    "1123": {
        "name": "Brawler Bears #1123",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1123.png",
        "dna": "5226683293c94712232ff8792e328000d8940af7",
        "edition": "1123",
        "type": "Black Bear",
        "date": 1677694764843,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1123",
        "rarity_score": 264.6873524686754,
        "rarity_level": "COMMON"
    },
    "2228": {
        "name": "Brawler Bears #2228",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2228.png",
        "dna": "90aad6c1fa2481ec17f73302fc5f9c6959dfaa22",
        "edition": "2228",
        "type": "Black Bear",
        "date": 1677694766336,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Peaky",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "2228",
        "rarity_score": 535.182450918799,
        "rarity_level": "LEGENDARY"
    },
    "2083": {
        "name": "Brawler Bears #2083",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2083.png",
        "dna": "7d8a5cbf82b7e8716722f0211224e6cfadf44f78",
        "edition": "2083",
        "type": "Brown Bear",
        "date": 1677694767400,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 43,
        "maxHealth": 251,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "2083",
        "rarity_score": 248.91940795903378,
        "rarity_level": "COMMON"
    },
    "1615": {
        "name": "Brawler Bears #1615",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1615.png",
        "dna": "fd1978e2e2711ccb5217b5a27f0c5bc745db4958",
        "edition": "1615",
        "type": "Galactic Cyborg",
        "date": 1677694768619,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1615",
        "rarity_score": 379.4069038145014,
        "rarity_level": "RARE"
    },
    "1420": {
        "name": "Brawler Bears #1420",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1420.png",
        "dna": "615b53a4d533715c6e2ddc3f9386e95edd5d1513",
        "edition": "1420",
        "type": "Black Bear",
        "date": 1677694769484,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1420",
        "rarity_score": 237.6030194708729,
        "rarity_level": "COMMON"
    },
    "2263": {
        "name": "Brawler Bears #2263",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2263.png",
        "dna": "c876eedc44a95cdcb313beba7de349a5261d6106",
        "edition": "2263",
        "type": "Acid Trip",
        "date": 1677694770698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Samurai Warrior",
                "score": 398.0
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2263",
        "rarity_score": 651.8266450633847,
        "rarity_level": "LEGENDARY"
    },
    "2319": {
        "name": "Brawler Bears #2319",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2319.png",
        "dna": "5b1e0cb90ff1e69853b0625b2b85c90eb8bf7f28",
        "edition": "2319",
        "type": "Black Bear",
        "date": 1677694771530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 48,
        "maxHealth": 278,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2319",
        "rarity_score": 286.3678200151922,
        "rarity_level": "RARE"
    },
    "85": {
        "name": "Brawler Bears #85",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/85.png",
        "dna": "dffccfea054de1c3fbaa136a8a4b9198e99ebb7f",
        "edition": "85",
        "type": "Galactic Cyborg",
        "date": 1677694773255,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "85",
        "rarity_score": 465.32742087709795,
        "rarity_level": "EPIC"
    },
    "1359": {
        "name": "Brawler Bears #1359",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1359.png",
        "dna": "20b215ec9ccd1e72a5da04d7e02e821bbe83efc6",
        "edition": "1359",
        "type": "Brown Bear",
        "date": 1677694774446,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Ribbit",
                "score": 331.6666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1359",
        "rarity_score": 533.5381529096451,
        "rarity_level": "LEGENDARY"
    },
    "517": {
        "name": "Brawler Bears #517",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/517.png",
        "dna": "bbadfba3bd96e39bc0a11e92f9df6c810d84673d",
        "edition": "517",
        "type": "Ice",
        "date": 1677694775818,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "517",
        "rarity_score": 484.2724156193106,
        "rarity_level": "EPIC"
    },
    "903": {
        "name": "Brawler Bears #903",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/903.png",
        "dna": "1364fedc11e208fdbd17df34cbac3cb65d3568ca",
        "edition": "903",
        "type": "Acid Trip",
        "date": 1677694777108,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 40,
        "maxHealth": 231,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "903",
        "rarity_score": 214.2671553380789,
        "rarity_level": "COMMON"
    },
    "1580": {
        "name": "Brawler Bears #1580",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1580.png",
        "dna": "51612f7c7d3ebf93431157e40247c7616d2f5af5",
        "edition": "1580",
        "type": "Galactic Cyborg",
        "date": 1677694778427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1580",
        "rarity_score": 312.074323425669,
        "rarity_level": "RARE"
    },
    "929": {
        "name": "Brawler Bears #929",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/929.png",
        "dna": "a4b925e1cbca227e5ac04e12df3063a43cf913ad",
        "edition": "929",
        "type": "Galactic Cyborg",
        "date": 1677694779807,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "929",
        "rarity_score": 368.6893861429431,
        "rarity_level": "RARE"
    },
    "436": {
        "name": "Brawler Bears #436",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/436.png",
        "dna": "2846fb7dc0a7fa1b0bee35abda833cff3787eba0",
        "edition": "436",
        "type": "Panda",
        "date": 1677694780562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "436",
        "rarity_score": 311.2717203247179,
        "rarity_level": "RARE"
    },
    "2308": {
        "name": "Brawler Bears #2308",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2308.png",
        "dna": "77d604906d6570b4077201daf8250944c76097f1",
        "edition": "2308",
        "type": "Galactic Cyborg",
        "date": 1677694781685,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "2308",
        "rarity_score": 437.98868904423404,
        "rarity_level": "EPIC"
    },
    "418": {
        "name": "Brawler Bears #418",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/418.png",
        "dna": "d86dfc488c09cbbd854fd8cc6ba3b7bfe2f2e6bb",
        "edition": "418",
        "type": "Panda",
        "date": 1677694782445,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 46,
        "maxHealth": 266,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "418",
        "rarity_score": 295.9319321391331,
        "rarity_level": "RARE"
    },
    "1462": {
        "name": "Brawler Bears #1462",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1462.png",
        "dna": "7045b90c50ab89e4929a01fc5321f5465589d04e",
        "edition": "1462",
        "type": "Galaxy",
        "date": 1677694783894,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1462",
        "rarity_score": 320.8496655734596,
        "rarity_level": "RARE"
    },
    "2099": {
        "name": "Brawler Bears #2099",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2099.png",
        "dna": "da9c9b64eaa4fa55812af7bef47fed2f52d02820",
        "edition": "2099",
        "type": "Deep Martian Cyborg",
        "date": 1677694784938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2099",
        "rarity_score": 220.78826020127582,
        "rarity_level": "COMMON"
    },
    "1755": {
        "name": "Brawler Bears #1755",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1755.png",
        "dna": "149d35438762644ba341d8db1b1408e18c87b3e4",
        "edition": "1755",
        "type": "Galaxy",
        "date": 1677694786256,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1755",
        "rarity_score": 246.7097581173047,
        "rarity_level": "COMMON"
    },
    "2136": {
        "name": "Brawler Bears #2136",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2136.png",
        "dna": "de553021b060c061e3dca0db732d5a3ca30244fd",
        "edition": "2136",
        "type": "Deep Martian Cyborg",
        "date": 1677694787132,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "2136",
        "rarity_score": 435.73539977237164,
        "rarity_level": "EPIC"
    },
    "552": {
        "name": "Brawler Bears #552",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/552.png",
        "dna": "c3361fcaf3b47d55bf79c8ed3c2353c81b7247b1",
        "edition": "552",
        "type": "Galactic Cyborg",
        "date": 1677694788050,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 46,
        "maxHealth": 265,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "552",
        "rarity_score": 146.861232898735,
        "rarity_level": "COMMON"
    },
    "176": {
        "name": "Brawler Bears #176",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/176.png",
        "dna": "461ba07178502f9fd1d2c956093e87005178a4d2",
        "edition": "176",
        "type": "Galaxy",
        "date": 1677694789416,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "176",
        "rarity_score": 403.5976942185612,
        "rarity_level": "RARE"
    },
    "1342": {
        "name": "Brawler Bears #1342",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1342.png",
        "dna": "e05c68607eb966e9aa4ba6b90ab2681ab060fefc",
        "edition": "1342",
        "type": "Brown Bear",
        "date": 1677694790649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 49,
        "maxHealth": 281,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1342",
        "rarity_score": 191.73727035931194,
        "rarity_level": "COMMON"
    },
    "306": {
        "name": "Brawler Bears #306",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/306.png",
        "dna": "1563a2181c83861dac82eb836ba6aa3d7e23a934",
        "edition": "306",
        "type": "Ice",
        "date": 1677694791912,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "306",
        "rarity_score": 298.3625984206224,
        "rarity_level": "RARE"
    },
    "1436": {
        "name": "Brawler Bears #1436",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1436.png",
        "dna": "d1b29643fe441afd0efb3ce3bded06b21aed6548",
        "edition": "1436",
        "type": "Black Bear",
        "date": 1677694793701,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1436",
        "rarity_score": 509.866919633228,
        "rarity_level": "EPIC"
    },
    "1268": {
        "name": "Brawler Bears #1268",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1268.png",
        "dna": "074d1d0f1647b435dbc1772371fa73e289d8520c",
        "edition": "1268",
        "type": "Black Bear",
        "date": 1677694795140,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 37,
        "maxHealth": 217,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1268",
        "rarity_score": 326.4958715088834,
        "rarity_level": "RARE"
    },
    "1549": {
        "name": "Brawler Bears #1549",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1549.png",
        "dna": "5dc423b1e21b3641b33364688509b9c8c2aae752",
        "edition": "1549",
        "type": "Brown Bear",
        "date": 1677694796364,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1549",
        "rarity_score": 455.8868914606283,
        "rarity_level": "EPIC"
    },
    "2198": {
        "name": "Brawler Bears #2198",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2198.png",
        "dna": "6ec08859f1d14d10b6a886b075aa2b4ea7a3a052",
        "edition": "2198",
        "type": "Brown Bear",
        "date": 1677694798096,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 65,
        "maxHealth": 373,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2198",
        "rarity_score": 151.23107036229754,
        "rarity_level": "COMMON"
    },
    "484": {
        "name": "Brawler Bears #484",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/484.png",
        "dna": "b53a9b71cf3c04713a3aa340216e97a2a473f11e",
        "edition": "484",
        "type": "Cheetah",
        "date": 1677694799090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "General",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "484",
        "rarity_score": 581.5612688156081,
        "rarity_level": "LEGENDARY"
    },
    "1267": {
        "name": "Brawler Bears #1267",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1267.png",
        "dna": "980410f980ae47afb7fe8d6e19c0ddea22dea84f",
        "edition": "1267",
        "type": "Black Bear",
        "date": 1677694800340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1267",
        "rarity_score": 329.103471726829,
        "rarity_level": "RARE"
    },
    "2117": {
        "name": "Brawler Bears #2117",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2117.png",
        "dna": "4649d6f5ff67747662d2bb9762f15c2c3ee55525",
        "edition": "2117",
        "type": "Galactic Cyborg",
        "date": 1677694801590,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2117",
        "rarity_score": 341.10434383911496,
        "rarity_level": "RARE"
    },
    "1544": {
        "name": "Brawler Bears #1544",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1544.png",
        "dna": "f2dd3a791e3739249295339717b7cec4a90ffb2f",
        "edition": "1544",
        "type": "Brown Bear",
        "date": 1677694802574,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 48,
        "maxHealth": 279,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1544",
        "rarity_score": 242.9545391400709,
        "rarity_level": "COMMON"
    },
    "1796": {
        "name": "Brawler Bears #1796",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1796.png",
        "dna": "df8182e0275e77fbbd921e10fa276482ad142202",
        "edition": "1796",
        "type": "Acid Trip",
        "date": 1677694803793,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1796",
        "rarity_score": 415.56884574272857,
        "rarity_level": "EPIC"
    },
    "1287": {
        "name": "Brawler Bears #1287",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1287.png",
        "dna": "6cbe9040aedf4afdb37db4ef7a30b0443f63e67f",
        "edition": "1287",
        "type": "Galaxy",
        "date": 1677694805230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Head", "value": "Royalty", "score": 248.75 },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1287",
        "rarity_score": 515.066457777376,
        "rarity_level": "EPIC"
    },
    "1085": {
        "name": "Brawler Bears #1085",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1085.png",
        "dna": "79911cb9b750329984b13141be587d505ee46712",
        "edition": "1085",
        "type": "Galactic Cyborg",
        "date": 1677694806094,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1085",
        "rarity_score": 166.02210068736625,
        "rarity_level": "COMMON"
    },
    "1759": {
        "name": "Brawler Bears #1759",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1759.png",
        "dna": "e54f12574a04cfcf4f17ac8a22ab194094609206",
        "edition": "1759",
        "type": "Brown Bear",
        "date": 1677694807032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Peaky",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 60,
        "maxHealth": 345,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1759",
        "rarity_score": 453.86265605634554,
        "rarity_level": "EPIC"
    },
    "2292": {
        "name": "Brawler Bears #2292",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2292.png",
        "dna": "dfee1a388f0b6007df0b0f3adbe1aee176351bb4",
        "edition": "2292",
        "type": "Black Bear",
        "date": 1677694807890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "2292",
        "rarity_score": 342.9916542683238,
        "rarity_level": "RARE"
    },
    "331": {
        "name": "Brawler Bears #331",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/331.png",
        "dna": "997f596f6ceecc45f567953440a3594ed49b66d1",
        "edition": "331",
        "type": "Brown Bear",
        "date": 1677694809530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "331",
        "rarity_score": 338.92350399623786,
        "rarity_level": "RARE"
    },
    "1538": {
        "name": "Brawler Bears #1538",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1538.png",
        "dna": "58b7e45bf0624e53ba41bf77ab0555bfdb207f7a",
        "edition": "1538",
        "type": "Deep Martian Cyborg",
        "date": 1677694811911,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1538",
        "rarity_score": 404.3889325635279,
        "rarity_level": "RARE"
    },
    "1040": {
        "name": "Brawler Bears #1040",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1040.png",
        "dna": "0831f28bc85595a7dc8b44524d588bb2e9e2afff",
        "edition": "1040",
        "type": "Brown Bear",
        "date": 1677694812897,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 28,
        "maxHealth": 160,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1040",
        "rarity_score": 257.1624764218234,
        "rarity_level": "COMMON"
    },
    "443": {
        "name": "Brawler Bears #443",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/443.png",
        "dna": "aaba2198bf36bb8a85a766860f7f8ba398fbd10f",
        "edition": "443",
        "type": "Black Bear",
        "date": 1677694814159,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "443",
        "rarity_score": 331.67745860556295,
        "rarity_level": "RARE"
    },
    "1058": {
        "name": "Brawler Bears #1058",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1058.png",
        "dna": "0474747e7979960b3bc423d5236729ca1e9f816e",
        "edition": "1058",
        "type": "Galactic Cyborg",
        "date": 1677694815203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 26,
        "maxHealth": 152,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1058",
        "rarity_score": 310.80302333659733,
        "rarity_level": "RARE"
    },
    "963": {
        "name": "Brawler Bears #963",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/963.png",
        "dna": "1861989ad8fc589540132da5ce26e475e7c921b0",
        "edition": "963",
        "type": "Acid Trip",
        "date": 1677694816318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 20,
        "maxHealth": 114,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "963",
        "rarity_score": 311.4579623648074,
        "rarity_level": "RARE"
    },
    "1042": {
        "name": "Brawler Bears #1042",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1042.png",
        "dna": "cfe78acccafe6f3af261b1f12cd00830c19ef891",
        "edition": "1042",
        "type": "Black Bear",
        "date": 1677694817532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1042",
        "rarity_score": 239.83314589255235,
        "rarity_level": "COMMON"
    },
    "1762": {
        "name": "Brawler Bears #1762",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1762.png",
        "dna": "6172ca0152475b4fae06fb2a4605a6fbdf0deacf",
        "edition": "1762",
        "type": "Ice",
        "date": 1677694818811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1762",
        "rarity_score": 264.1738198569889,
        "rarity_level": "COMMON"
    },
    "2028": {
        "name": "Brawler Bears #2028",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2028.png",
        "dna": "b8e8af499f1a39e015565eff6fc8bd87151f46e5",
        "edition": "2028",
        "type": "Panda",
        "date": 1677694819525,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "2028",
        "rarity_score": 396.1280860799926,
        "rarity_level": "RARE"
    },
    "1361": {
        "name": "Brawler Bears #1361",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1361.png",
        "dna": "9034a377f0fe9e413370d59763a8505ac91fa024",
        "edition": "1361",
        "type": "Galactic Cyborg",
        "date": 1677694820866,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Clowning",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 17,
        "maxHealth": 100,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1361",
        "rarity_score": 526.7174138572361,
        "rarity_level": "LEGENDARY"
    },
    "708": {
        "name": "Brawler Bears #708",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/708.png",
        "dna": "cbe68d410137549fa83d5418abd9600a83e5ca15",
        "edition": "708",
        "type": "Brown Bear",
        "date": 1677694821820,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Flossy Magician",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "708",
        "rarity_score": 407.0661955070771,
        "rarity_level": "RARE"
    },
    "726": {
        "name": "Brawler Bears #726",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/726.png",
        "dna": "c12aa95aadbee6baab71963022d8833911d8bcf6",
        "edition": "726",
        "type": "Brown Bear",
        "date": 1677694823856,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "726",
        "rarity_score": 387.1501079762374,
        "rarity_level": "RARE"
    },
    "1075": {
        "name": "Brawler Bears #1075",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1075.png",
        "dna": "dbe49e7f3ce4035bea429670b2aa829fa553900c",
        "edition": "1075",
        "type": "Galaxy",
        "date": 1677694825336,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 26,
        "maxHealth": 149,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1075",
        "rarity_score": 335.912806694322,
        "rarity_level": "RARE"
    },
    "2199": {
        "name": "Brawler Bears #2199",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2199.png",
        "dna": "399f9346f408b7fce09dae02259924d8843dca95",
        "edition": "2199",
        "type": "Galaxy",
        "date": 1677694826635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "2199",
        "rarity_score": 362.0501510749917,
        "rarity_level": "RARE"
    },
    "2106": {
        "name": "Brawler Bears #2106",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2106.png",
        "dna": "c68a00df984e57ed836f98b55a1fbe081a3830c6",
        "edition": "2106",
        "type": "Deep Martian Cyborg",
        "date": 1677694827678,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 58,
        "maxHealth": 335,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2106",
        "rarity_score": 187.6688915867402,
        "rarity_level": "COMMON"
    },
    "1600": {
        "name": "Brawler Bears #1600",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1600.png",
        "dna": "d2dc4e37b8eeffbaffea26946931934d6b1ed817",
        "edition": "1600",
        "type": "Ice",
        "date": 1677694829203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1600",
        "rarity_score": 291.804057317184,
        "rarity_level": "RARE"
    },
    "817": {
        "name": "Brawler Bears #817",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/817.png",
        "dna": "28c17505e4f6d83133b0ffe2562f55be44abd1e4",
        "edition": "817",
        "type": "Galactic Cyborg",
        "date": 1677694830595,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "817",
        "rarity_score": 263.7214030738244,
        "rarity_level": "COMMON"
    },
    "36": {
        "name": "Brawler Bears #36",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/36.png",
        "dna": "d9c11a81edfafa0770cf63b24e744b2b240e1ae9",
        "edition": "36",
        "type": "Acid Trip",
        "date": 1677694831874,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "36",
        "rarity_score": 324.2409145365793,
        "rarity_level": "RARE"
    },
    "149": {
        "name": "Brawler Bears #149",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/149.png",
        "dna": "92f0acb633af6514cd9a74fe97a935cb55a0f2a7",
        "edition": "149",
        "type": "Deep Martian Cyborg",
        "date": 1677694833112,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 20,
        "maxHealth": 117,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "149",
        "rarity_score": 312.32472218603976,
        "rarity_level": "RARE"
    },
    "2300": {
        "name": "Brawler Bears #2300",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2300.png",
        "dna": "1aca7c19fa27fcc4bb5d5547587c0e6e8f9924ea",
        "edition": "2300",
        "type": "Deep Martian Cyborg",
        "date": 1677694834585,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Chad", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2300",
        "rarity_score": 469.4220349520278,
        "rarity_level": "EPIC"
    },
    "454": {
        "name": "Brawler Bears #454",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/454.png",
        "dna": "2c901cf1743eb6c614fa069ff20508a54608d380",
        "edition": "454",
        "type": "Acid Trip",
        "date": 1677694835434,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 12,
        "maxHealth": 73,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "454",
        "rarity_score": 526.2594352640156,
        "rarity_level": "LEGENDARY"
    },
    "1621": {
        "name": "Brawler Bears #1621",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1621.png",
        "dna": "7d00555fcc8f2da4313270a2f2d6dd1d59e3a187",
        "edition": "1621",
        "type": "Brown Bear",
        "date": 1677694836969,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1621",
        "rarity_score": 437.6470367013408,
        "rarity_level": "EPIC"
    },
    "502": {
        "name": "Brawler Bears #502",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/502.png",
        "dna": "8dcb199609efcf0f6d3e59757169da1905a98c83",
        "edition": "502",
        "type": "Brown Bear",
        "date": 1677694838728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 49,
        "maxHealth": 282,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "502",
        "rarity_score": 214.05389464768396,
        "rarity_level": "COMMON"
    },
    "255": {
        "name": "Brawler Bears #255",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/255.png",
        "dna": "a2fa4bce614ec524e15eaf844fa40f5010bfe33a",
        "edition": "255",
        "type": "Deep Martian Cyborg",
        "date": 1677694840483,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "255",
        "rarity_score": 237.80271045870666,
        "rarity_level": "COMMON"
    },
    "936": {
        "name": "Brawler Bears #936",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/936.png",
        "dna": "6156f71254bf8e1a0b5d184cb4f916f012bd1586",
        "edition": "936",
        "type": "Brown Bear",
        "date": 1677694843056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "936",
        "rarity_score": 403.2038826872055,
        "rarity_level": "RARE"
    },
    "1192": {
        "name": "Brawler Bears #1192",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1192.png",
        "dna": "11cf4db0429bf1739f74e397c6624f5add96528a",
        "edition": "1192",
        "type": "Deep Martian Cyborg",
        "date": 1677694844878,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1192",
        "rarity_score": 390.9594833129445,
        "rarity_level": "RARE"
    },
    "2069": {
        "name": "Brawler Bears #2069",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2069.png",
        "dna": "fc5a9a5e23b0815840245493161d98d10651311a",
        "edition": "2069",
        "type": "Panda",
        "date": 1677694847915,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Sonic Hearing Aid",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "2069",
        "rarity_score": 436.4185061433767,
        "rarity_level": "EPIC"
    },
    "1620": {
        "name": "Brawler Bears #1620",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1620.png",
        "dna": "a7c5883af220fb9cde4a529290f0e0102a4b76c9",
        "edition": "1620",
        "type": "Ice",
        "date": 1677694851013,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 51,
        "maxHealth": 296,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1620",
        "rarity_score": 155.3325169209829,
        "rarity_level": "COMMON"
    },
    "2296": {
        "name": "Brawler Bears #2296",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2296.png",
        "dna": "3ff8dc1ad814199ee263c0aadaffc3bf30963917",
        "edition": "2296",
        "type": "Brown Bear",
        "date": 1677694852487,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "2296",
        "rarity_score": 264.16793438992323,
        "rarity_level": "COMMON"
    },
    "411": {
        "name": "Brawler Bears #411",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/411.png",
        "dna": "a0bb06906bd0efe5c228158dc7f1f79a6413d025",
        "edition": "411",
        "type": "Ice",
        "date": 1677694854182,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "411",
        "rarity_score": 468.10699023199027,
        "rarity_level": "EPIC"
    },
    "2132": {
        "name": "Brawler Bears #2132",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2132.png",
        "dna": "29d6b40ab659eb4e57175421095af723b166307d",
        "edition": "2132",
        "type": "Brown Bear",
        "date": 1677694856650,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 27,
        "maxHealth": 157,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "2132",
        "rarity_score": 278.16684799026956,
        "rarity_level": "RARE"
    },
    "1061": {
        "name": "Brawler Bears #1061",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1061.png",
        "dna": "02e7b6f01dc3f39b96a7dfb0ced8377f9cc37f8b",
        "edition": "1061",
        "type": "Black Bear",
        "date": 1677694860032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Chad", "score": 248.75 },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1061",
        "rarity_score": 481.54561043366624,
        "rarity_level": "EPIC"
    },
    "2021": {
        "name": "Brawler Bears #2021",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2021.png",
        "dna": "3445f040610e7ebb1aa67de9b5ba22737343b959",
        "edition": "2021",
        "type": "Cheetah",
        "date": 1677694861819,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Stark",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 50,
        "maxHealth": 291,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2021",
        "rarity_score": 358.8640038904898,
        "rarity_level": "RARE"
    },
    "1453": {
        "name": "Brawler Bears #1453",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1453.png",
        "dna": "f304efd4533b262a0029592b6760f0173647453d",
        "edition": "1453",
        "type": "Ice",
        "date": 1677694865273,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 51,
        "maxHealth": 295,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1453",
        "rarity_score": 154.66085033691706,
        "rarity_level": "COMMON"
    },
    "51": {
        "name": "Brawler Bears #51",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/51.png",
        "dna": "e39dacd9cd79ac36bf7f1e633ed5480d8fabdbad",
        "edition": "51",
        "type": "Galaxy",
        "date": 1677694870741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "51",
        "rarity_score": 379.0116582887309,
        "rarity_level": "RARE"
    },
    "1716": {
        "name": "Brawler Bears #1716",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1716.png",
        "dna": "4a37437932dbc61f369518992b924d20dca74f5c",
        "edition": "1716",
        "type": "Brown Bear",
        "date": 1677694872728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1716",
        "rarity_score": 324.57525808302506,
        "rarity_level": "RARE"
    },
    "1684": {
        "name": "Brawler Bears #1684",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1684.png",
        "dna": "ac7b515de527c1b0cfd175ff7ef7c8f895c3922d",
        "edition": "1684",
        "type": "Black Bear",
        "date": 1677694874578,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Ribbit",
                "score": 331.6666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1684",
        "rarity_score": 562.8747878846948,
        "rarity_level": "LEGENDARY"
    },
    "869": {
        "name": "Brawler Bears #869",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/869.png",
        "dna": "ae2b9875e07cd8ff05bdcbda7f71d1585dfef443",
        "edition": "869",
        "type": "Lava",
        "date": 1677694877938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Officer", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "869",
        "rarity_score": 494.2297045988717,
        "rarity_level": "EPIC"
    },
    "2261": {
        "name": "Brawler Bears #2261",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2261.png",
        "dna": "2a74cfab2b66589fedc5c94594ff32bd878b6c42",
        "edition": "2261",
        "type": "Black Bear",
        "date": 1677694880560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2261",
        "rarity_score": 486.39270726277437,
        "rarity_level": "EPIC"
    },
    "138": {
        "name": "Brawler Bears #138",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/138.png",
        "dna": "00e830bb8ee3c8b9c42e456ea76fc9bff53d699d",
        "edition": "138",
        "type": "Brown Bear",
        "date": 1677694882796,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "138",
        "rarity_score": 469.48668292573905,
        "rarity_level": "EPIC"
    },
    "1812": {
        "name": "Brawler Bears #1812",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1812.png",
        "dna": "49f88bf2aa91693b89c744e89ff8895e27de7c8a",
        "edition": "1812",
        "type": "Black Bear",
        "date": 1677694883987,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1812",
        "rarity_score": 443.8690369213465,
        "rarity_level": "EPIC"
    },
    "1460": {
        "name": "Brawler Bears #1460",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1460.png",
        "dna": "fd8963ad9b061bc1a20100957f931bb4131de5c5",
        "edition": "1460",
        "type": "Brown Bear",
        "date": 1677694886136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Bandit", "score": 398.0 },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1460",
        "rarity_score": 687.298744203313,
        "rarity_level": "LEGENDARY"
    },
    "1320": {
        "name": "Brawler Bears #1320",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1320.png",
        "dna": "85644760780844222047de7bedaf7c901629dc0d",
        "edition": "1320",
        "type": "Brown Bear",
        "date": 1677694887661,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Vibez",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1320",
        "rarity_score": 413.37075347948405,
        "rarity_level": "EPIC"
    },
    "977": {
        "name": "Brawler Bears #977",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/977.png",
        "dna": "0d4cd717e341109adfd83f14e210c613a7e5cdaf",
        "edition": "977",
        "type": "Panda",
        "date": 1677694888537,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "977",
        "rarity_score": 232.0457254453769,
        "rarity_level": "COMMON"
    },
    "81": {
        "name": "Brawler Bears #81",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/81.png",
        "dna": "e83759c9916324366045759ac53bdca308396f60",
        "edition": "81",
        "type": "Deep Martian Cyborg",
        "date": 1677694891262,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 25,
        "maxHealth": 147,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "81",
        "rarity_score": 329.70481164980237,
        "rarity_level": "RARE"
    },
    "1323": {
        "name": "Brawler Bears #1323",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1323.png",
        "dna": "d9fc545c86fca2ec1585b3d31c7cfaeccec4822d",
        "edition": "1323",
        "type": "Black Bear",
        "date": 1677694892507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 48,
        "maxHealth": 276,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1323",
        "rarity_score": 230.4133660921957,
        "rarity_level": "COMMON"
    },
    "1864": {
        "name": "Brawler Bears #1864",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1864.png",
        "dna": "00365e484fca4413cc40452eebb109c2836d95b6",
        "edition": "1864",
        "type": "Lava",
        "date": 1677694894068,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1864",
        "rarity_score": 397.0935796649965,
        "rarity_level": "RARE"
    },
    "1827": {
        "name": "Brawler Bears #1827",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1827.png",
        "dna": "3707dc9d8b2321380845993c119a05674c6d8285",
        "edition": "1827",
        "type": "Deep Martian Cyborg",
        "date": 1677694895250,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1827",
        "rarity_score": 142.8407472231862,
        "rarity_level": "COMMON"
    },
    "1694": {
        "name": "Brawler Bears #1694",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1694.png",
        "dna": "a8479388d4637eef633385ac39449e6bd62fca8a",
        "edition": "1694",
        "type": "Galactic Cyborg",
        "date": 1677694896713,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1694",
        "rarity_score": 369.7271231957458,
        "rarity_level": "RARE"
    },
    "246": {
        "name": "Brawler Bears #246",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/246.png",
        "dna": "7b05b67ef10a8b8e57ee053bae598cb1ecc280f8",
        "edition": "246",
        "type": "Brown Bear",
        "date": 1677694897564,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 69,
        "maxHealth": 396,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "246",
        "rarity_score": 210.91179675758403,
        "rarity_level": "COMMON"
    },
    "110": {
        "name": "Brawler Bears #110",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/110.png",
        "dna": "ca1dc58d0fb0905560334be1748229c86f3c2d5e",
        "edition": "110",
        "type": "Panda",
        "date": 1677694899325,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "110",
        "rarity_score": 444.5405945961166,
        "rarity_level": "EPIC"
    },
    "1922": {
        "name": "Brawler Bears #1922",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1922.png",
        "dna": "c243809dc8b62382c007a343e27501f5d9b4583f",
        "edition": "1922",
        "type": "Brown Bear",
        "date": 1677694900882,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1922",
        "rarity_score": 379.0385874369182,
        "rarity_level": "RARE"
    },
    "1588": {
        "name": "Brawler Bears #1588",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1588.png",
        "dna": "e3f83835c3922b1732ec6a4b3d74d9fa4f4d5ae9",
        "edition": "1588",
        "type": "Brown Bear",
        "date": 1677694902061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1588",
        "rarity_score": 385.6637668169086,
        "rarity_level": "RARE"
    },
    "689": {
        "name": "Brawler Bears #689",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/689.png",
        "dna": "ba82bb7baa7a797e0a9851008dd7fc03a230580c",
        "edition": "689",
        "type": "Brown Bear",
        "date": 1677694904420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "689",
        "rarity_score": 378.5751235928587,
        "rarity_level": "RARE"
    },
    "1463": {
        "name": "Brawler Bears #1463",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1463.png",
        "dna": "804039e76b3a6f07c6df1414490dacf4030624db",
        "edition": "1463",
        "type": "Deep Martian Cyborg",
        "date": 1677694906132,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            { "trait_type": "Head", "value": "AI", "score": 180.9090909090909 },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1463",
        "rarity_score": 307.40289027999916,
        "rarity_level": "RARE"
    },
    "1741": {
        "name": "Brawler Bears #1741",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1741.png",
        "dna": "f2e8bf713deaea462916a4ac5b6acfec147ec670",
        "edition": "1741",
        "type": "Brown Bear",
        "date": 1677694907980,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 48,
        "maxHealth": 275,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1741",
        "rarity_score": 228.4416757528048,
        "rarity_level": "COMMON"
    },
    "1952": {
        "name": "Brawler Bears #1952",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1952.png",
        "dna": "a7dae4825c561393e5e03c6a4f6967cc580bf7f9",
        "edition": "1952",
        "type": "Brown Bear",
        "date": 1677694909979,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1952",
        "rarity_score": 454.7208432065063,
        "rarity_level": "EPIC"
    },
    "774": {
        "name": "Brawler Bears #774",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/774.png",
        "dna": "c8b0be8d5aae9c43dc6980821801548a9cf33a01",
        "edition": "774",
        "type": "Acid Trip",
        "date": 1677694911419,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Luff",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 51,
        "maxHealth": 295,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "774",
        "rarity_score": 399.03750443232275,
        "rarity_level": "RARE"
    },
    "1960": {
        "name": "Brawler Bears #1960",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1960.png",
        "dna": "f0843f9962db86a6baf5c2832a095e8b5e93be6f",
        "edition": "1960",
        "type": "Brown Bear",
        "date": 1677694913790,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Faded",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1960",
        "rarity_score": 350.4876861891164,
        "rarity_level": "RARE"
    },
    "820": {
        "name": "Brawler Bears #820",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/820.png",
        "dna": "000ee187eebf786198fa6797e0d3fe2eb0f0ef18",
        "edition": "820",
        "type": "Panda",
        "date": 1677694915616,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "820",
        "rarity_score": 234.33121020334912,
        "rarity_level": "COMMON"
    },
    "1798": {
        "name": "Brawler Bears #1798",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1798.png",
        "dna": "736bb510f8761d6ba49c9571b11745c41afc0e37",
        "edition": "1798",
        "type": "Panda",
        "date": 1677694917353,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1798",
        "rarity_score": 440.8355547960811,
        "rarity_level": "EPIC"
    },
    "2126": {
        "name": "Brawler Bears #2126",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2126.png",
        "dna": "177185fc19cde4f5c8e2b1d4ea34cf07f0bb184a",
        "edition": "2126",
        "type": "Brown Bear",
        "date": 1677694919139,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2126",
        "rarity_score": 346.2587547236149,
        "rarity_level": "RARE"
    },
    "130": {
        "name": "Brawler Bears #130",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/130.png",
        "dna": "01c904107e563eaf6d0b28194fd4b21e8c06c513",
        "edition": "130",
        "type": "Ice",
        "date": 1677694920571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "130",
        "rarity_score": 476.56156585048046,
        "rarity_level": "EPIC"
    },
    "2142": {
        "name": "Brawler Bears #2142",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2142.png",
        "dna": "9404d4225f853ff6ab49f229a04f0aa470208e12",
        "edition": "2142",
        "type": "Galaxy",
        "date": 1677694923287,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Kufiya",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2142",
        "rarity_score": 396.29125642022,
        "rarity_level": "RARE"
    },
    "1733": {
        "name": "Brawler Bears #1733",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1733.png",
        "dna": "52a1f176496c8e62f043ed1d37ade4a9445d9b6f",
        "edition": "1733",
        "type": "Deep Martian Cyborg",
        "date": 1677694924522,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 41,
        "maxHealth": 236,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1733",
        "rarity_score": 239.40506004793687,
        "rarity_level": "COMMON"
    },
    "558": {
        "name": "Brawler Bears #558",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/558.png",
        "dna": "1a32f1958bf8c6dc8f82ddc1f6f47f0098e335f2",
        "edition": "558",
        "type": "Brown Bear",
        "date": 1677694925765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "558",
        "rarity_score": 441.62127135254536,
        "rarity_level": "EPIC"
    },
    "2304": {
        "name": "Brawler Bears #2304",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2304.png",
        "dna": "505293835140634ce1dc477f799ec2b83a6ad662",
        "edition": "2304",
        "type": "Brown Bear",
        "date": 1677694927030,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "2304",
        "rarity_score": 374.3180010148265,
        "rarity_level": "RARE"
    },
    "1552": {
        "name": "Brawler Bears #1552",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1552.png",
        "dna": "5edbd3d8a49f300c133449fdb46460e4a4c85385",
        "edition": "1552",
        "type": "Lava",
        "date": 1677694928172,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1552",
        "rarity_score": 413.6163439519418,
        "rarity_level": "EPIC"
    },
    "1589": {
        "name": "Brawler Bears #1589",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1589.png",
        "dna": "ec50719d35a78911973af548ad38865983c5185e",
        "edition": "1589",
        "type": "Panda",
        "date": 1677694929188,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1589",
        "rarity_score": 359.70302660677095,
        "rarity_level": "RARE"
    },
    "1330": {
        "name": "Brawler Bears #1330",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1330.png",
        "dna": "7c2b20f92f441d30d9cede3a250f479435c80456",
        "edition": "1330",
        "type": "Black Bear",
        "date": 1677694930437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 242,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1330",
        "rarity_score": 311.4496674588281,
        "rarity_level": "RARE"
    },
    "197": {
        "name": "Brawler Bears #197",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/197.png",
        "dna": "1c64c5e2e6dd06e3ef90da14f317fe87d983723b",
        "edition": "197",
        "type": "Brown Bear",
        "date": 1677694932396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 60,
        "maxHealth": 344,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "197",
        "rarity_score": 312.3791609014866,
        "rarity_level": "RARE"
    },
    "1179": {
        "name": "Brawler Bears #1179",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1179.png",
        "dna": "10e65cb19aaea5650f17e33ade0a9115ee30640a",
        "edition": "1179",
        "type": "Galaxy",
        "date": 1677694933646,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Samurai Warrior",
                "score": 398.0
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1179",
        "rarity_score": 617.6293762202464,
        "rarity_level": "LEGENDARY"
    },
    "1923": {
        "name": "Brawler Bears #1923",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1923.png",
        "dna": "4c6c752028ac86f72687f865403fd7ef31aec77b",
        "edition": "1923",
        "type": "Ice",
        "date": 1677694935491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Miestro",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1923",
        "rarity_score": 327.95640772739614,
        "rarity_level": "RARE"
    },
    "925": {
        "name": "Brawler Bears #925",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/925.png",
        "dna": "dbc49d01c16212c08e1f317e397b7034ace07d8e",
        "edition": "925",
        "type": "Galactic Cyborg",
        "date": 1677694937500,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 26,
        "maxHealth": 151,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "925",
        "rarity_score": 407.8743787824335,
        "rarity_level": "RARE"
    },
    "721": {
        "name": "Brawler Bears #721",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/721.png",
        "dna": "23420321fcea0eabbb5acaed4a650c6c2736d655",
        "edition": "721",
        "type": "Black Bear",
        "date": 1677694938453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "721",
        "rarity_score": 370.55283399345166,
        "rarity_level": "RARE"
    },
    "1019": {
        "name": "Brawler Bears #1019",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1019.png",
        "dna": "8b8ca811f6d46692b7a5db57f50cc2d1607725bc",
        "edition": "1019",
        "type": "Black Bear",
        "date": 1677694939741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 48,
        "maxHealth": 278,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1019",
        "rarity_score": 202.76916208607807,
        "rarity_level": "COMMON"
    },
    "854": {
        "name": "Brawler Bears #854",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/854.png",
        "dna": "9a3ed3ef59d56e9ee5d17231e8c4b63845176161",
        "edition": "854",
        "type": "Galaxy",
        "date": 1677694940896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "854",
        "rarity_score": 354.1203984093354,
        "rarity_level": "RARE"
    },
    "1675": {
        "name": "Brawler Bears #1675",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1675.png",
        "dna": "7dfb54ce7cb3149a9095eb1b3949bb36ed9afd5d",
        "edition": "1675",
        "type": "Galaxy",
        "date": 1677694942322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1675",
        "rarity_score": 232.45631891025005,
        "rarity_level": "COMMON"
    },
    "837": {
        "name": "Brawler Bears #837",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/837.png",
        "dna": "b85444a39211d46382cfef0a77f5a3b39d638d45",
        "edition": "837",
        "type": "Brown Bear",
        "date": 1677694943524,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "837",
        "rarity_score": 372.0985452181205,
        "rarity_level": "RARE"
    },
    "179": {
        "name": "Brawler Bears #179",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/179.png",
        "dna": "45eb79c9b0f0b24cb0c68dbedb331920afca39f9",
        "edition": "179",
        "type": "Panda",
        "date": 1677694945272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "179",
        "rarity_score": 293.32183906970255,
        "rarity_level": "RARE"
    },
    "861": {
        "name": "Brawler Bears #861",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/861.png",
        "dna": "509ad714c924126d476e82f9c401c503300300df",
        "edition": "861",
        "type": "Black Bear",
        "date": 1677694946641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Grand Master",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "861",
        "rarity_score": 420.92027155749463,
        "rarity_level": "EPIC"
    },
    "1841": {
        "name": "Brawler Bears #1841",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1841.png",
        "dna": "3a91d3bd0058bb4f8d3f6fa1d379bdf1acd2a26a",
        "edition": "1841",
        "type": "Ice",
        "date": 1677694947993,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1841",
        "rarity_score": 387.906675392389,
        "rarity_level": "RARE"
    },
    "296": {
        "name": "Brawler Bears #296",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/296.png",
        "dna": "4d4d372a504a78cabb088fbf6c45b5fbb4727adf",
        "edition": "296",
        "type": "Black Bear",
        "date": 1677694949488,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Chief",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "296",
        "rarity_score": 321.31176645520424,
        "rarity_level": "RARE"
    },
    "701": {
        "name": "Brawler Bears #701",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/701.png",
        "dna": "a35d599e7742427ce2ee23596345c31f6b693a9a",
        "edition": "701",
        "type": "Brown Bear",
        "date": 1677694950316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 60,
        "maxHealth": 345,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "701",
        "rarity_score": 238.84763052710224,
        "rarity_level": "COMMON"
    },
    "1487": {
        "name": "Brawler Bears #1487",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1487.png",
        "dna": "aa046a4a159a19c7dc7f7877a1cb6840f3a0d5dd",
        "edition": "1487",
        "type": "Ice",
        "date": 1677694952777,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Head",
                "value": "Maid",
                "score": 142.14285714285714
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 15,
        "maxHealth": 85,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1487",
        "rarity_score": 460.5079633854989,
        "rarity_level": "EPIC"
    },
    "538": {
        "name": "Brawler Bears #538",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/538.png",
        "dna": "c0ba7a442670c43dbe7e9a8c29228dbc3dfc0c64",
        "edition": "538",
        "type": "Black Bear",
        "date": 1677694954697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "538",
        "rarity_score": 354.4596607740141,
        "rarity_level": "RARE"
    },
    "710": {
        "name": "Brawler Bears #710",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/710.png",
        "dna": "f60b12c2e9c1d24407d58eac2254ffd459d7ca8a",
        "edition": "710",
        "type": "Lava",
        "date": 1677694955715,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Winter Wonderland",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "710",
        "rarity_score": 407.4223851148389,
        "rarity_level": "RARE"
    },
    "363": {
        "name": "Brawler Bears #363",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/363.png",
        "dna": "c2dd67b6898416fe71662f9d9130ae15148a2965",
        "edition": "363",
        "type": "Brown Bear",
        "date": 1677694958202,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            { "trait_type": "Head", "value": "Chad", "score": 248.75 },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "363",
        "rarity_score": 516.7172124254303,
        "rarity_level": "LEGENDARY"
    },
    "2003": {
        "name": "Brawler Bears #2003",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2003.png",
        "dna": "099038a06630eb12c0f8bbb550026f110ed05bea",
        "edition": "2003",
        "type": "Deep Martian Cyborg",
        "date": 1677694960433,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2003",
        "rarity_score": 417.939973858106,
        "rarity_level": "EPIC"
    },
    "1959": {
        "name": "Brawler Bears #1959",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1959.png",
        "dna": "72a6032557abfa5aa2d2ef7614c81b50f3d6b3f0",
        "edition": "1959",
        "type": "Black Bear",
        "date": 1677694961977,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Technicolor",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1959",
        "rarity_score": 517.3979167678576,
        "rarity_level": "LEGENDARY"
    },
    "2214": {
        "name": "Brawler Bears #2214",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2214.png",
        "dna": "81d45d494d534e14ca69a5bde0dd8ba841d2b989",
        "edition": "2214",
        "type": "Panda",
        "date": 1677694963782,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "2214",
        "rarity_score": 411.9473726210858,
        "rarity_level": "RARE"
    },
    "455": {
        "name": "Brawler Bears #455",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/455.png",
        "dna": "169088b40c5110eb7c2cb108ebcad50560d2480c",
        "edition": "455",
        "type": "Deep Martian Cyborg",
        "date": 1677694965151,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "455",
        "rarity_score": 282.5003321494933,
        "rarity_level": "RARE"
    },
    "1848": {
        "name": "Brawler Bears #1848",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1848.png",
        "dna": "7cd4caf9d32100f3a4b0c9d446b37730960b91c1",
        "edition": "1848",
        "type": "Deep Martian Cyborg",
        "date": 1677694966290,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "1848",
        "rarity_score": 250.9960717358313,
        "rarity_level": "COMMON"
    },
    "690": {
        "name": "Brawler Bears #690",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/690.png",
        "dna": "185bb9535556a7930611060d318f8f80283a78a5",
        "edition": "690",
        "type": "Black Bear",
        "date": 1677694968527,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "690",
        "rarity_score": 301.9669445495041,
        "rarity_level": "RARE"
    },
    "1060": {
        "name": "Brawler Bears #1060",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1060.png",
        "dna": "721a6ef5a7348440f0a5225e873357c97f126c3c",
        "edition": "1060",
        "type": "Brown Bear",
        "date": 1677694969755,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1060",
        "rarity_score": 344.3097124489328,
        "rarity_level": "RARE"
    },
    "1105": {
        "name": "Brawler Bears #1105",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1105.png",
        "dna": "13cb641cf686de760e373b00a3855aef1dd6cf93",
        "edition": "1105",
        "type": "Ice",
        "date": 1677694971229,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Visor",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1105",
        "rarity_score": 432.5776479295359,
        "rarity_level": "EPIC"
    },
    "952": {
        "name": "Brawler Bears #952",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/952.png",
        "dna": "f479c09f943089b0371db666237a77c1a13d31cd",
        "edition": "952",
        "type": "Black Bear",
        "date": 1677694972201,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "Green Winter",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 58,
        "maxHealth": 336,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "952",
        "rarity_score": 314.29216883171506,
        "rarity_level": "RARE"
    },
    "934": {
        "name": "Brawler Bears #934",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/934.png",
        "dna": "16bc910dfa9f045e1c7609d392043b9c12aae270",
        "edition": "934",
        "type": "Panda",
        "date": 1677694974615,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 20,
        "maxHealth": 117,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "934",
        "rarity_score": 252.69782997765827,
        "rarity_level": "COMMON"
    },
    "2194": {
        "name": "Brawler Bears #2194",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2194.png",
        "dna": "4b7cbee226a6c2eb8aa26d0e26c258c950f56f6f",
        "edition": "2194",
        "type": "Deep Martian Cyborg",
        "date": 1677694976325,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Head", "value": "Papi", "score": 199.0 },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "2194",
        "rarity_score": 418.09675890407163,
        "rarity_level": "EPIC"
    },
    "1160": {
        "name": "Brawler Bears #1160",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1160.png",
        "dna": "41c481df5a4cfa7cda317bd58651f62e25391999",
        "edition": "1160",
        "type": "Acid Trip",
        "date": 1677694977751,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1160",
        "rarity_score": 415.5044759963923,
        "rarity_level": "EPIC"
    },
    "1251": {
        "name": "Brawler Bears #1251",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1251.png",
        "dna": "ce67bad89b041cfafbf29f5bf69685234c7f45e8",
        "edition": "1251",
        "type": "Brown Bear",
        "date": 1677694979191,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1251",
        "rarity_score": 314.3805417194744,
        "rarity_level": "RARE"
    },
    "1609": {
        "name": "Brawler Bears #1609",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1609.png",
        "dna": "c46702523f9d35403bc6554782677627530ea47f",
        "edition": "1609",
        "type": "Galactic Cyborg",
        "date": 1677694980176,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 58,
        "maxHealth": 332,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1609",
        "rarity_score": 191.12669879359686,
        "rarity_level": "COMMON"
    },
    "241": {
        "name": "Brawler Bears #241",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/241.png",
        "dna": "939e5f1673cad96a3a4c7560be3de6c949de36f1",
        "edition": "241",
        "type": "Black Bear",
        "date": 1677694981545,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "241",
        "rarity_score": 253.68324930026313,
        "rarity_level": "COMMON"
    },
    "2173": {
        "name": "Brawler Bears #2173",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2173.png",
        "dna": "ec6ed11a3747ff82bf9262319a450219b789fb80",
        "edition": "2173",
        "type": "Black Bear",
        "date": 1677694982677,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "2173",
        "rarity_score": 345.0457290091671,
        "rarity_level": "RARE"
    },
    "342": {
        "name": "Brawler Bears #342",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/342.png",
        "dna": "f34a1134a2f89b8af44efadb6788b6e1439f90fb",
        "edition": "342",
        "type": "Galaxy",
        "date": 1677694984283,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "342",
        "rarity_score": 441.337897643796,
        "rarity_level": "EPIC"
    },
    "264": {
        "name": "Brawler Bears #264",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/264.png",
        "dna": "a9ae0027dd6b323ab9e60b8ab814fa69069a64f4",
        "edition": "264",
        "type": "Galaxy",
        "date": 1677694985911,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 20,
        "maxHealth": 118,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "264",
        "rarity_score": 282.375076523667,
        "rarity_level": "RARE"
    },
    "971": {
        "name": "Brawler Bears #971",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/971.png",
        "dna": "498902ec01a4411a901a4d682d90c556b230ea70",
        "edition": "971",
        "type": "Acid Trip",
        "date": 1677694987668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Reading Glasses",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "971",
        "rarity_score": 417.06864655847966,
        "rarity_level": "EPIC"
    },
    "725": {
        "name": "Brawler Bears #725",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/725.png",
        "dna": "8d3e8e9cdbcacca365dbc9e98f3377005a769a05",
        "edition": "725",
        "type": "Deep Martian Cyborg",
        "date": 1677694988708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "725",
        "rarity_score": 346.21825620315946,
        "rarity_level": "RARE"
    },
    "385": {
        "name": "Brawler Bears #385",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/385.png",
        "dna": "269aa5a20c2ce5de116626e32052acdf566bd47e",
        "edition": "385",
        "type": "Galactic Cyborg",
        "date": 1677694989772,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 27,
        "maxHealth": 157,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "385",
        "rarity_score": 264.14009436047974,
        "rarity_level": "COMMON"
    },
    "1791": {
        "name": "Brawler Bears #1791",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1791.png",
        "dna": "04d4bd39f197c2677432c6f73b26eb343217f7a1",
        "edition": "1791",
        "type": "Cheetah",
        "date": 1677694990768,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Head",
                "value": "Pixel Shades",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 13,
        "maxHealth": 76,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1791",
        "rarity_score": 439.3402204964705,
        "rarity_level": "EPIC"
    },
    "1888": {
        "name": "Brawler Bears #1888",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1888.png",
        "dna": "57733916b083bb2c67b43c136f2c9ed05f3ea859",
        "edition": "1888",
        "type": "Panda",
        "date": 1677694992661,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1888",
        "rarity_score": 314.73181901128834,
        "rarity_level": "RARE"
    },
    "1990": {
        "name": "Brawler Bears #1990",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1990.png",
        "dna": "6551350b68e6390ba29893925f9db6f52b14dc23",
        "edition": "1990",
        "type": "Galactic Cyborg",
        "date": 1677694993607,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Bandit", "score": 398.0 },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 54,
        "maxHealth": 313,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1990",
        "rarity_score": 572.8793160969113,
        "rarity_level": "LEGENDARY"
    },
    "445": {
        "name": "Brawler Bears #445",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/445.png",
        "dna": "0c36613d3c6702e7456dc4ec00713f40a36db4e8",
        "edition": "445",
        "type": "Galactic Cyborg",
        "date": 1677694995476,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Aviator",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "445",
        "rarity_score": 434.8092814512029,
        "rarity_level": "EPIC"
    },
    "507": {
        "name": "Brawler Bears #507",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/507.png",
        "dna": "9275ee1611fda635c3a01f3c19ed6280ad2c64bc",
        "edition": "507",
        "type": "Ice",
        "date": 1677694996898,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Emperor",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "507",
        "rarity_score": 318.12416773499086,
        "rarity_level": "RARE"
    },
    "1591": {
        "name": "Brawler Bears #1591",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1591.png",
        "dna": "5e0183e81e520c0334c873b5551107677480241e",
        "edition": "1591",
        "type": "Ice",
        "date": 1677694999011,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1591",
        "rarity_score": 354.9737324870821,
        "rarity_level": "RARE"
    },
    "914": {
        "name": "Brawler Bears #914",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/914.png",
        "dna": "008d15e52fd170fe9748b2e23096948941879034",
        "edition": "914",
        "type": "Acid Trip",
        "date": 1677695000615,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "914",
        "rarity_score": 266.4890167690426,
        "rarity_level": "COMMON"
    },
    "642": {
        "name": "Brawler Bears #642",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/642.png",
        "dna": "832b0b5e61642e06ac6b66f0e0922f4c4af881c0",
        "edition": "642",
        "type": "Galactic Cyborg",
        "date": 1677695001657,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "642",
        "rarity_score": 360.72645448041163,
        "rarity_level": "RARE"
    },
    "2174": {
        "name": "Brawler Bears #2174",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2174.png",
        "dna": "035ed2b83632bf15a84c9d6485092c9e0dbf2565",
        "edition": "2174",
        "type": "Black Bear",
        "date": 1677695002570,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "2174",
        "rarity_score": 341.9217739535625,
        "rarity_level": "RARE"
    },
    "1326": {
        "name": "Brawler Bears #1326",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1326.png",
        "dna": "166d026bb485297580193f6c0ac7a0268491ea3e",
        "edition": "1326",
        "type": "Brown Bear",
        "date": 1677695003504,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 49,
        "maxHealth": 280,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1326",
        "rarity_score": 240.73017435827546,
        "rarity_level": "COMMON"
    },
    "2167": {
        "name": "Brawler Bears #2167",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2167.png",
        "dna": "24f8f13ede86a751e330b6b7abf40b65729c9534",
        "edition": "2167",
        "type": "Lava",
        "date": 1677695004457,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 18,
        "maxHealth": 106,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2167",
        "rarity_score": 289.5295253151537,
        "rarity_level": "RARE"
    },
    "1183": {
        "name": "Brawler Bears #1183",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1183.png",
        "dna": "8e38a09899dbdbab354fa3260a789f98a4321822",
        "edition": "1183",
        "type": "Galactic Cyborg",
        "date": 1677695005540,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 26,
        "maxHealth": 153,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1183",
        "rarity_score": 366.8979421329859,
        "rarity_level": "RARE"
    },
    "2248": {
        "name": "Brawler Bears #2248",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2248.png",
        "dna": "8f71eec914ccc687bf3c3d6ce9207e4a4b38dd42",
        "edition": "2248",
        "type": "Black Bear",
        "date": 1677695006829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 59,
        "maxHealth": 337,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2248",
        "rarity_score": 385.3254293886186,
        "rarity_level": "RARE"
    },
    "730": {
        "name": "Brawler Bears #730",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/730.png",
        "dna": "c931d7cf728c7d19ed1c2439b1da3164d774af8f",
        "edition": "730",
        "type": "Brown Bear",
        "date": 1677695008363,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            }
        ],
        "level": 32,
        "maxHealth": 188,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "730",
        "rarity_score": 331.8748262811933,
        "rarity_level": "RARE"
    },
    "810": {
        "name": "Brawler Bears #810",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/810.png",
        "dna": "f7fa418fb623be64fc1636d76361f78bc6293117",
        "edition": "810",
        "type": "Galactic Cyborg",
        "date": 1677695009562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "810",
        "rarity_score": 243.8027087060471,
        "rarity_level": "COMMON"
    },
    "2080": {
        "name": "Brawler Bears #2080",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2080.png",
        "dna": "1d9c0b415f9db278930bd1c3f0581f2dd5ddcca3",
        "edition": "2080",
        "type": "Brown Bear",
        "date": 1677695011082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "2080",
        "rarity_score": 384.62348246221507,
        "rarity_level": "RARE"
    },
    "164": {
        "name": "Brawler Bears #164",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/164.png",
        "dna": "853a793fa0984743b805fe04343458f85ecfa13c",
        "edition": "164",
        "type": "Panda",
        "date": 1677695012263,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 46,
        "maxHealth": 263,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "164",
        "rarity_score": 275.70790915144636,
        "rarity_level": "RARE"
    },
    "2078": {
        "name": "Brawler Bears #2078",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2078.png",
        "dna": "456b2d863ce6603308aed0164ff224e531cd7207",
        "edition": "2078",
        "type": "Galactic Cyborg",
        "date": 1677695013203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "2078",
        "rarity_score": 319.6914399979612,
        "rarity_level": "RARE"
    },
    "1089": {
        "name": "Brawler Bears #1089",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1089.png",
        "dna": "3ff44b3d06ca8d7c8a01009589916970be06d6bd",
        "edition": "1089",
        "type": "Panda",
        "date": 1677695014309,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 57,
        "maxHealth": 327,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1089",
        "rarity_score": 182.0059297277457,
        "rarity_level": "COMMON"
    },
    "24": {
        "name": "Brawler Bears #24",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/24.png",
        "dna": "d62aa4a4ca63f2616b645bc13bda817d32c34d52",
        "edition": "24",
        "type": "Galaxy",
        "date": 1677695015779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "24",
        "rarity_score": 371.8876296160285,
        "rarity_level": "RARE"
    },
    "2025": {
        "name": "Brawler Bears #2025",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2025.png",
        "dna": "ccd13d7391065a595032ce9b8632f91bf7438910",
        "edition": "2025",
        "type": "Brown Bear",
        "date": 1677695016583,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Straw Warrior",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "2025",
        "rarity_score": 345.04910215053286,
        "rarity_level": "RARE"
    },
    "2076": {
        "name": "Brawler Bears #2076",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2076.png",
        "dna": "7753fe662b9a69d327f512a57b81d603f9c9c69e",
        "edition": "2076",
        "type": "Brown Bear",
        "date": 1677695017451,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 49,
        "maxHealth": 281,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "2076",
        "rarity_score": 227.92016937583037,
        "rarity_level": "COMMON"
    },
    "769": {
        "name": "Brawler Bears #769",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/769.png",
        "dna": "c13ad08a13c1745bf549bd0b353f5a6fb7a65039",
        "edition": "769",
        "type": "Brown Bear",
        "date": 1677695018331,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "Hipster Sister",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "769",
        "rarity_score": 328.95043374681825,
        "rarity_level": "RARE"
    },
    "245": {
        "name": "Brawler Bears #245",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/245.png",
        "dna": "c5e307c6a8b0bdb4437db1c714ec6da66d9ebadd",
        "edition": "245",
        "type": "Brown Bear",
        "date": 1677695019466,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            }
        ],
        "level": 28,
        "maxHealth": 160,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "245",
        "rarity_score": 252.10731777062887,
        "rarity_level": "COMMON"
    },
    "382": {
        "name": "Brawler Bears #382",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/382.png",
        "dna": "31832066f231380865e0eb8648e3791e6067cdba",
        "edition": "382",
        "type": "Galaxy",
        "date": 1677695021217,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "382",
        "rarity_score": 426.5651101816266,
        "rarity_level": "EPIC"
    },
    "392": {
        "name": "Brawler Bears #392",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/392.png",
        "dna": "3813276ffdbd80bd150772ecb4818dfffe7ee79c",
        "edition": "392",
        "type": "Brown Bear",
        "date": 1677695022102,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "392",
        "rarity_score": 127.9089253517935,
        "rarity_level": "COMMON"
    },
    "1714": {
        "name": "Brawler Bears #1714",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1714.png",
        "dna": "ea7cfbf928db422fe1e3384dbf945473d867a4ad",
        "edition": "1714",
        "type": "Deep Martian Cyborg",
        "date": 1677695023520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 31,
        "maxHealth": 181,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "1714",
        "rarity_score": 476.24128835814105,
        "rarity_level": "EPIC"
    },
    "1299": {
        "name": "Brawler Bears #1299",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1299.png",
        "dna": "bdcbc84769537870f4e0ae695db77fe42a5b87d8",
        "edition": "1299",
        "type": "Black Bear",
        "date": 1677695024504,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1299",
        "rarity_score": 323.5408301493011,
        "rarity_level": "RARE"
    },
    "512": {
        "name": "Brawler Bears #512",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/512.png",
        "dna": "dec45159d34a7504cadd96ee92c2d2534cd93120",
        "edition": "512",
        "type": "Black Bear",
        "date": 1677695025642,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "512",
        "rarity_score": 387.5495848247066,
        "rarity_level": "RARE"
    },
    "2116": {
        "name": "Brawler Bears #2116",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2116.png",
        "dna": "a5d0861ac122ece8157307d2dcd25202259600fe",
        "edition": "2116",
        "type": "Ice",
        "date": 1677695027819,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Construction",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2116",
        "rarity_score": 380.90383513545277,
        "rarity_level": "RARE"
    },
    "1635": {
        "name": "Brawler Bears #1635",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1635.png",
        "dna": "eccba5ff0fbb716966d4c3ec6e92e85409a06bdd",
        "edition": "1635",
        "type": "Panda",
        "date": 1677695028976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 24,
        "maxHealth": 140,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1635",
        "rarity_score": 337.25854204355556,
        "rarity_level": "RARE"
    },
    "537": {
        "name": "Brawler Bears #537",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/537.png",
        "dna": "1fe1aa7569a210d3f80787f7c4bf5339d4b8bcfc",
        "edition": "537",
        "type": "Galactic Cyborg",
        "date": 1677695031031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "537",
        "rarity_score": 262.80920358626156,
        "rarity_level": "COMMON"
    },
    "2092": {
        "name": "Brawler Bears #2092",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2092.png",
        "dna": "b57c8ee5c0c8ac08f1baeefde22e4dc4d4889b5e",
        "edition": "2092",
        "type": "Panda",
        "date": 1677695031754,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            }
        ],
        "level": 56,
        "maxHealth": 324,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2092",
        "rarity_score": 179.50532858721323,
        "rarity_level": "COMMON"
    },
    "32": {
        "name": "Brawler Bears #32",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/32.png",
        "dna": "55f9de564348e2276c10ae0eb341893856947e5a",
        "edition": "32",
        "type": "Deep Martian Cyborg",
        "date": 1677695032765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Head",
                "value": "Umpire",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "32",
        "rarity_score": 289.7244846184948,
        "rarity_level": "RARE"
    },
    "358": {
        "name": "Brawler Bears #358",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/358.png",
        "dna": "f351e713c1393c5260759bf5d885ccbe68bd2dda",
        "edition": "358",
        "type": "Galactic Cyborg",
        "date": 1677695034011,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 26,
        "maxHealth": 148,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "358",
        "rarity_score": 311.0793121542524,
        "rarity_level": "RARE"
    },
    "472": {
        "name": "Brawler Bears #472",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/472.png",
        "dna": "4301834790f298d2e364f5e00f50b16dd4c11040",
        "edition": "472",
        "type": "Brown Bear",
        "date": 1677695035203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            { "trait_type": "Head", "value": "Kitty Mushroom", "score": 199.0 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "472",
        "rarity_score": 422.3589706686704,
        "rarity_level": "EPIC"
    },
    "504": {
        "name": "Brawler Bears #504",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/504.png",
        "dna": "f75750a21b862b78233870838b34ca6fbcc7257d",
        "edition": "504",
        "type": "Lava",
        "date": 1677695036914,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 }
        ],
        "level": 19,
        "maxHealth": 109,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "504",
        "rarity_score": 249.41283920015093,
        "rarity_level": "COMMON"
    },
    "1395": {
        "name": "Brawler Bears #1395",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1395.png",
        "dna": "e9bd3dd1064c243b13a95c6f8901379d6c09291d",
        "edition": "1395",
        "type": "Ice",
        "date": 1677695038705,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Augmented Reality",
                "score": 221.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1395",
        "rarity_score": 445.29147052890795,
        "rarity_level": "EPIC"
    },
    "1632": {
        "name": "Brawler Bears #1632",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1632.png",
        "dna": "82e23586ca3ac49c81e542bb5871f3567a925582",
        "edition": "1632",
        "type": "Acid Trip",
        "date": 1677695039557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1632",
        "rarity_score": 404.4026462388305,
        "rarity_level": "RARE"
    },
    "629": {
        "name": "Brawler Bears #629",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/629.png",
        "dna": "cc0dec5f3e0460b6c870d813624ac3d9b2120308",
        "edition": "629",
        "type": "Galactic Cyborg",
        "date": 1677695040774,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "629",
        "rarity_score": 273.71798725120055,
        "rarity_level": "COMMON"
    },
    "1726": {
        "name": "Brawler Bears #1726",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1726.png",
        "dna": "57191567388c921620b4b8424692b43ce6204c37",
        "edition": "1726",
        "type": "Galaxy",
        "date": 1677695041603,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1726",
        "rarity_score": 222.14240196283683,
        "rarity_level": "COMMON"
    },
    "200": {
        "name": "Brawler Bears #200",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/200.png",
        "dna": "8ec9b3d94eeb343c7d1b8f90f784d6d757677638",
        "edition": "200",
        "type": "Black Bear",
        "date": 1677695042615,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "200",
        "rarity_score": 319.66754936765847,
        "rarity_level": "RARE"
    },
    "669": {
        "name": "Brawler Bears #669",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/669.png",
        "dna": "9fc6ee756daac153233f411ad0e91633f6ee4575",
        "edition": "669",
        "type": "Lava",
        "date": 1677695043979,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Mini",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "669",
        "rarity_score": 416.7422958463929,
        "rarity_level": "EPIC"
    },
    "727": {
        "name": "Brawler Bears #727",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/727.png",
        "dna": "75e98bdcaa006e7d44077dacf15728ee60e80248",
        "edition": "727",
        "type": "Black Bear",
        "date": 1677695044779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Chef",
                "score": 132.66666666666666
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 }
        ],
        "level": 39,
        "maxHealth": 222,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "727",
        "rarity_score": 310.76738244510784,
        "rarity_level": "RARE"
    },
    "2310": {
        "name": "Brawler Bears #2310",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2310.png",
        "dna": "ec339f726d833fff324f58e5c24e76a89dca48be",
        "edition": "2310",
        "type": "Deep Martian Cyborg",
        "date": 1677695045877,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "2310",
        "rarity_score": 210.7055937664351,
        "rarity_level": "COMMON"
    },
    "1993": {
        "name": "Brawler Bears #1993",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1993.png",
        "dna": "5411e4ae89f24b31bb23e147e44ea4d5422b839d",
        "edition": "1993",
        "type": "Cheetah",
        "date": 1677695047649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Head",
                "value": "Rainy Day",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            }
        ],
        "level": 30,
        "maxHealth": 171,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1993",
        "rarity_score": 287.9269992573706,
        "rarity_level": "RARE"
    },
    "2295": {
        "name": "Brawler Bears #2295",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2295.png",
        "dna": "ef482b2d62f76bf5d52a98925f22ea1cb3704b82",
        "edition": "2295",
        "type": "Acid Trip",
        "date": 1677695048850,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cowboy",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2295",
        "rarity_score": 305.44839652603065,
        "rarity_level": "RARE"
    },
    "2276": {
        "name": "Brawler Bears #2276",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2276.png",
        "dna": "be36753798b40037832dde18f5726e672eb571b6",
        "edition": "2276",
        "type": "Deep Martian Cyborg",
        "date": 1677695050694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "King",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "2276",
        "rarity_score": 315.7899214727429,
        "rarity_level": "RARE"
    },
    "1254": {
        "name": "Brawler Bears #1254",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1254.png",
        "dna": "96e926c9bf0d959831e093b3c54d88251a686df5",
        "edition": "1254",
        "type": "Brown Bear",
        "date": 1677695052511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            }
        ],
        "level": 64,
        "maxHealth": 369,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1254",
        "rarity_score": 211.38899840181753,
        "rarity_level": "COMMON"
    },
    "598": {
        "name": "Brawler Bears #598",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/598.png",
        "dna": "27018b0cfe5231d5c7dd0070bb5124d15c036bd6",
        "edition": "598",
        "type": "Galaxy",
        "date": 1677695053636,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "598",
        "rarity_score": 272.8246785767081,
        "rarity_level": "COMMON"
    },
    "1496": {
        "name": "Brawler Bears #1496",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1496.png",
        "dna": "4cb6af68afc343e78e57ef226667c048e16c1fe4",
        "edition": "1496",
        "type": "Brown Bear",
        "date": 1677695054593,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Head",
                "value": "Fire Dept",
                "score": 284.2857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1496",
        "rarity_score": 529.7113510068185,
        "rarity_level": "LEGENDARY"
    },
    "1541": {
        "name": "Brawler Bears #1541",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1541.png",
        "dna": "ca267111ae01056d87994de74dbbc9f40c5ebfda",
        "edition": "1541",
        "type": "Black Bear",
        "date": 1677695055625,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Head",
                "value": "Pharoh",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1541",
        "rarity_score": 376.7731483067756,
        "rarity_level": "RARE"
    },
    "1137": {
        "name": "Brawler Bears #1137",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1137.png",
        "dna": "c2a8aa580d95d4c0710f3cadc1b4f22df4e5f9c9",
        "edition": "1137",
        "type": "Black Bear",
        "date": 1677695056725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Pilot",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1137",
        "rarity_score": 376.0458511590391,
        "rarity_level": "RARE"
    },
    "2109": {
        "name": "Brawler Bears #2109",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2109.png",
        "dna": "b86fd0fb0418fb9d40aaa65cc3604807c4fd559a",
        "edition": "2109",
        "type": "Acid Trip",
        "date": 1677695057846,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Head",
                "value": "Pirate",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            }
        ],
        "level": 41,
        "maxHealth": 235,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2109",
        "rarity_score": 294.1282656631931,
        "rarity_level": "RARE"
    },
    "1037": {
        "name": "Brawler Bears #1037",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1037.png",
        "dna": "931c4a568078bc3865b482e24990e33f85522404",
        "edition": "1037",
        "type": "Brown Bear",
        "date": 1677695059033,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Head",
                "value": "Turban",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1037",
        "rarity_score": 372.78096857380154,
        "rarity_level": "RARE"
    },
    "887": {
        "name": "Brawler Bears #887",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/887.png",
        "dna": "78078f799bea8dcf5da0b1f16c014feb19101620",
        "edition": "887",
        "type": "Ice",
        "date": 1677695060391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Head",
                "value": "Umbrella Top",
                "score": 180.9090909090909
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "887",
        "rarity_score": 454.13112340016426,
        "rarity_level": "EPIC"
    },
    "302": {
        "name": "Brawler Bears #302",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/302.png",
        "dna": "93786e13bd8763a8a10adc8b7f3c498ff4645974",
        "edition": "302",
        "type": "Galaxy",
        "date": 1677695061401,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 9.660194174757281
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "302",
        "rarity_score": 231.6486623195098,
        "rarity_level": "COMMON"
    },
    "928": {
        "name": "Brawler Bears #928",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/928.png",
        "dna": "b03bec043603e6bcfd0dfea59f52a1099960c362",
        "edition": "928",
        "type": "Acid Trip",
        "date": 1677695062965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 30,
        "maxHealth": 173,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "928",
        "rarity_score": 238.56307126276943,
        "rarity_level": "COMMON"
    },
    "2201": {
        "name": "Brawler Bears #2201",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2201.png",
        "dna": "ac3a273e5cb2e1b12516d288b0d51e365edc1c47",
        "edition": "2201",
        "type": "Lava",
        "date": 1677695063744,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 50,
        "maxHealth": 287,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "2201",
        "rarity_score": 196.8481867567554,
        "rarity_level": "COMMON"
    },
    "321": {
        "name": "Brawler Bears #321",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/321.png",
        "dna": "b32a775f274a41a77566c581d255cacb864bf772",
        "edition": "321",
        "type": "Galactic Cyborg",
        "date": 1677695064664,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 47,
        "maxHealth": 269,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "321",
        "rarity_score": 130.62121380167503,
        "rarity_level": "COMMON"
    },
    "787": {
        "name": "Brawler Bears #787",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/787.png",
        "dna": "d8e2b6419653d0486ce5ef8f2d695a21b14fa3d9",
        "edition": "787",
        "type": "Black Bear",
        "date": 1677695065931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "787",
        "rarity_score": 124.42212911576323,
        "rarity_level": "COMMON"
    },
    "1954": {
        "name": "Brawler Bears #1954",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1954.png",
        "dna": "eb3bd8e1d08da2d6b340e7593541528e48e0fd45",
        "edition": "1954",
        "type": "Deep Martian Cyborg",
        "date": 1677695067203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1954",
        "rarity_score": 127.55284283552244,
        "rarity_level": "COMMON"
    },
    "1500": {
        "name": "Brawler Bears #1500",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1500.png",
        "dna": "8ba0f967603dd2282bc25668e0fc5d4e30d20f90",
        "edition": "1500",
        "type": "Galactic Cyborg",
        "date": 1677695068088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1500",
        "rarity_score": 248.84779314596454,
        "rarity_level": "COMMON"
    },
    "1485": {
        "name": "Brawler Bears #1485",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1485.png",
        "dna": "7aa1baa8d8a37fcc7983e900251b058e5c7e424c",
        "edition": "1485",
        "type": "Lava",
        "date": 1677695069700,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 29,
        "maxHealth": 169,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1485",
        "rarity_score": 293.3192689066168,
        "rarity_level": "RARE"
    },
    "371": {
        "name": "Brawler Bears #371",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/371.png",
        "dna": "6296eed98f756229d673c74ea1cc6aea5661a7c6",
        "edition": "371",
        "type": "Cheetah",
        "date": 1677695070665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "371",
        "rarity_score": 219.03877570583444,
        "rarity_level": "COMMON"
    },
    "1347": {
        "name": "Brawler Bears #1347",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1347.png",
        "dna": "b3c07e5aeb056e133c5ac5f914000aada31c3d5d",
        "edition": "1347",
        "type": "Acid Trip",
        "date": 1677695071905,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1347",
        "rarity_score": 222.93876006762568,
        "rarity_level": "COMMON"
    },
    "795": {
        "name": "Brawler Bears #795",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/795.png",
        "dna": "5b31cd3a45e1d5f52a4ae33c531e218d47a54e76",
        "edition": "795",
        "type": "Black Bear",
        "date": 1677695072602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "795",
        "rarity_score": 249.01130980810115,
        "rarity_level": "COMMON"
    },
    "1317": {
        "name": "Brawler Bears #1317",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1317.png",
        "dna": "118f24ce91563172020195fe38517da54fb73324",
        "edition": "1317",
        "type": "Cheetah",
        "date": 1677695073684,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1317",
        "rarity_score": 276.67276630855605,
        "rarity_level": "RARE"
    },
    "2287": {
        "name": "Brawler Bears #2287",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2287.png",
        "dna": "a8f777e1d2723fe75634e7339e7a5f379532f103",
        "edition": "2287",
        "type": "Ice",
        "date": 1677695075196,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "2287",
        "rarity_score": 280.2025252382075,
        "rarity_level": "RARE"
    },
    "688": {
        "name": "Brawler Bears #688",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/688.png",
        "dna": "8ee83ad91135844a3d320d2c9435f80920adb9c9",
        "edition": "688",
        "type": "Panda",
        "date": 1677695076220,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 50,
        "maxHealth": 289,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "688",
        "rarity_score": 210.10811494238268,
        "rarity_level": "COMMON"
    },
    "376": {
        "name": "Brawler Bears #376",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/376.png",
        "dna": "f4868f49aac1cd001204a1970afd51b9b69630df",
        "edition": "376",
        "type": "Black Bear",
        "date": 1677695077242,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "376",
        "rarity_score": 253.69948720439888,
        "rarity_level": "COMMON"
    },
    "2172": {
        "name": "Brawler Bears #2172",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2172.png",
        "dna": "e4d1a91fd2d0a63176a7556982fd2d9af0d23b91",
        "edition": "2172",
        "type": "Deep Martian Cyborg",
        "date": 1677695078125,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 36,
        "maxHealth": 205,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2172",
        "rarity_score": 243.8656084026664,
        "rarity_level": "COMMON"
    },
    "11": {
        "name": "Brawler Bears #11",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/11.png",
        "dna": "ba82802146c148f50208a3e019b15124c6c21a1c",
        "edition": "11",
        "type": "Black Bear",
        "date": 1677695079121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "11",
        "rarity_score": 211.09723449640703,
        "rarity_level": "COMMON"
    },
    "2113": {
        "name": "Brawler Bears #2113",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2113.png",
        "dna": "98e617e0d0a24279e1055b187abccab381566da1",
        "edition": "2113",
        "type": "Black Bear",
        "date": 1677695080891,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 51,
        "maxHealth": 297,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2113",
        "rarity_score": 139.98846344148225,
        "rarity_level": "COMMON"
    },
    "572": {
        "name": "Brawler Bears #572",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/572.png",
        "dna": "78c12b6cb7089b3afd6cdd1f99e020c2ab6f577c",
        "edition": "572",
        "type": "Brown Bear",
        "date": 1677695081605,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "572",
        "rarity_score": 232.44807201284596,
        "rarity_level": "COMMON"
    },
    "180": {
        "name": "Brawler Bears #180",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/180.png",
        "dna": "8ba71e4ca733b6e3c109ea20076ea3e0baab783f",
        "edition": "180",
        "type": "Ice",
        "date": 1677695082799,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "180",
        "rarity_score": 316.846365197344,
        "rarity_level": "RARE"
    },
    "567": {
        "name": "Brawler Bears #567",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/567.png",
        "dna": "cab23d6636af141e3dd7b30459eeb3daaefb9e62",
        "edition": "567",
        "type": "Galaxy",
        "date": 1677695083795,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "567",
        "rarity_score": 267.4219910702912,
        "rarity_level": "COMMON"
    },
    "1130": {
        "name": "Brawler Bears #1130",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1130.png",
        "dna": "ff1c01f79590d2fd60b19a234547ec6683bdfae6",
        "edition": "1130",
        "type": "Black Bear",
        "date": 1677695085790,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1130",
        "rarity_score": 205.15588726998104,
        "rarity_level": "COMMON"
    },
    "1134": {
        "name": "Brawler Bears #1134",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1134.png",
        "dna": "f1f48e5758b10caa8adfd46e2f0c35b3e7fbc25c",
        "edition": "1134",
        "type": "Brown Bear",
        "date": 1677695086907,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1134",
        "rarity_score": 292.6456572034287,
        "rarity_level": "RARE"
    },
    "1068": {
        "name": "Brawler Bears #1068",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1068.png",
        "dna": "7c238297cc36172c173fb60ff2a1c7fd04daf52f",
        "edition": "1068",
        "type": "Galaxy",
        "date": 1677695088356,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1068",
        "rarity_score": 301.6744951459107,
        "rarity_level": "RARE"
    },
    "194": {
        "name": "Brawler Bears #194",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/194.png",
        "dna": "671a1093a73fb03f1d6538f1a8e8f6ebed7ff4b9",
        "edition": "194",
        "type": "Deep Martian Cyborg",
        "date": 1677695089868,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 26,
        "maxHealth": 149,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "194",
        "rarity_score": 186.09421101854954,
        "rarity_level": "COMMON"
    },
    "2202": {
        "name": "Brawler Bears #2202",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2202.png",
        "dna": "c5cdc39e977f8f2fb133eb5c176531259d4f7774",
        "edition": "2202",
        "type": "Black Bear",
        "date": 1677695091292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 242,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2202",
        "rarity_score": 219.55480679636378,
        "rarity_level": "COMMON"
    },
    "128": {
        "name": "Brawler Bears #128",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/128.png",
        "dna": "aa5c99fcecb62a6a91828b3121eaf39f9dc6b310",
        "edition": "128",
        "type": "Galactic Cyborg",
        "date": 1677695092697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "128",
        "rarity_score": 280.13607604962715,
        "rarity_level": "RARE"
    },
    "1298": {
        "name": "Brawler Bears #1298",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1298.png",
        "dna": "8838b7f6cd5dbb2da62780161aec0e40e19bf7fd",
        "edition": "1298",
        "type": "Galaxy",
        "date": 1677695093708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1298",
        "rarity_score": 272.77115024584316,
        "rarity_level": "COMMON"
    },
    "2064": {
        "name": "Brawler Bears #2064",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2064.png",
        "dna": "8fd10fe31959606263d1489bdd2bce98a583f070",
        "edition": "2064",
        "type": "Galactic Cyborg",
        "date": 1677695094916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2064",
        "rarity_score": 256.3903645352674,
        "rarity_level": "COMMON"
    },
    "1881": {
        "name": "Brawler Bears #1881",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1881.png",
        "dna": "86d1c1b434890f9925fffc1a84dc2df78275f439",
        "edition": "1881",
        "type": "Galaxy",
        "date": 1677695096444,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1881",
        "rarity_score": 126.46144290251641,
        "rarity_level": "COMMON"
    },
    "2014": {
        "name": "Brawler Bears #2014",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2014.png",
        "dna": "85163b6debd6d9f0d691ff45b331495f4b478c95",
        "edition": "2014",
        "type": "Panda",
        "date": 1677695097403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2014",
        "rarity_score": 165.30311483782768,
        "rarity_level": "COMMON"
    },
    "1871": {
        "name": "Brawler Bears #1871",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1871.png",
        "dna": "26d3cee2269989259d663ac820569ce5809ce747",
        "edition": "1871",
        "type": "Brown Bear",
        "date": 1677695098428,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1871",
        "rarity_score": 262.06933826098367,
        "rarity_level": "COMMON"
    },
    "587": {
        "name": "Brawler Bears #587",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/587.png",
        "dna": "7b96edd572dc55c36e9a5372d4f61d3ee580e7a6",
        "edition": "587",
        "type": "Black Bear",
        "date": 1677695099672,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 41,
        "maxHealth": 236,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "587",
        "rarity_score": 247.1975699965273,
        "rarity_level": "COMMON"
    },
    "1353": {
        "name": "Brawler Bears #1353",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1353.png",
        "dna": "1747fa379ec1e956a1bde46f48d5d66f467635fa",
        "edition": "1353",
        "type": "Galactic Cyborg",
        "date": 1677695100454,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1353",
        "rarity_score": 253.76293091784032,
        "rarity_level": "COMMON"
    },
    "2306": {
        "name": "Brawler Bears #2306",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2306.png",
        "dna": "478d88f45283377451ffd7e5730631388b557dee",
        "edition": "2306",
        "type": "Brown Bear",
        "date": 1677695101221,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2306",
        "rarity_score": 233.6378280625757,
        "rarity_level": "COMMON"
    },
    "1138": {
        "name": "Brawler Bears #1138",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1138.png",
        "dna": "946e3162c07cb11a5bffb381f1203e301aa43ce5",
        "edition": "1138",
        "type": "Acid Trip",
        "date": 1677695102227,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1138",
        "rarity_score": 253.5237587841166,
        "rarity_level": "COMMON"
    },
    "478": {
        "name": "Brawler Bears #478",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/478.png",
        "dna": "dcc5b93d0e659092d2ec5184635501314805ee9f",
        "edition": "478",
        "type": "Ice",
        "date": 1677695103520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 62,
        "maxHealth": 359,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "478",
        "rarity_score": 88.67301689241293,
        "rarity_level": "COMMON"
    },
    "1286": {
        "name": "Brawler Bears #1286",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1286.png",
        "dna": "4f854b17223ddd8953693f41804fd0d925150861",
        "edition": "1286",
        "type": "Lava",
        "date": 1677695104420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1286",
        "rarity_score": 176.64243866752844,
        "rarity_level": "COMMON"
    },
    "1837": {
        "name": "Brawler Bears #1837",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1837.png",
        "dna": "9eb16424a7b705163845f81eb2e82639ccec2ee8",
        "edition": "1837",
        "type": "Black Bear",
        "date": 1677695105226,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 68,
        "maxHealth": 390,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1837",
        "rarity_score": 82.01344981693747,
        "rarity_level": "COMMON"
    },
    "624": {
        "name": "Brawler Bears #624",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/624.png",
        "dna": "0f71a6f91070ccb7ca69f876d8a9181b76cb63e6",
        "edition": "624",
        "type": "Brown Bear",
        "date": 1677695106244,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 31,
        "maxHealth": 181,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "624",
        "rarity_score": 215.9457439909617,
        "rarity_level": "COMMON"
    },
    "1835": {
        "name": "Brawler Bears #1835",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1835.png",
        "dna": "893dca69381c59fcde5f472c33969af075cf6174",
        "edition": "1835",
        "type": "Black Bear",
        "date": 1677695107134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 41,
        "maxHealth": 239,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1835",
        "rarity_score": 227.48263493630358,
        "rarity_level": "COMMON"
    },
    "1986": {
        "name": "Brawler Bears #1986",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1986.png",
        "dna": "98bc18872eecad91dd7b1a0cb0e4f9a640f09a0a",
        "edition": "1986",
        "type": "Cheetah",
        "date": 1677695108296,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1986",
        "rarity_score": 202.57725153695202,
        "rarity_level": "COMMON"
    },
    "1131": {
        "name": "Brawler Bears #1131",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1131.png",
        "dna": "d70fc2a2bbf8e5f15c671078aad7d6c3cad8dab4",
        "edition": "1131",
        "type": "Black Bear",
        "date": 1677695109394,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 245,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1131",
        "rarity_score": 211.72444283600623,
        "rarity_level": "COMMON"
    },
    "1856": {
        "name": "Brawler Bears #1856",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1856.png",
        "dna": "75837ef74afaee56f5abb8f41c7bcf17f5611583",
        "edition": "1856",
        "type": "Black Bear",
        "date": 1677695111125,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1856",
        "rarity_score": 206.03189114470644,
        "rarity_level": "COMMON"
    },
    "496": {
        "name": "Brawler Bears #496",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/496.png",
        "dna": "0017933184e6ff12498c711b2d1901de21dfeaa0",
        "edition": "496",
        "type": "Deep Martian Cyborg",
        "date": 1677695112725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "496",
        "rarity_score": 217.01606253950638,
        "rarity_level": "COMMON"
    },
    "2005": {
        "name": "Brawler Bears #2005",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2005.png",
        "dna": "d5171e147d3300f30e22a5845f2e82ec6c477e9e",
        "edition": "2005",
        "type": "Brown Bear",
        "date": 1677695114263,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "2005",
        "rarity_score": 270.0184418408149,
        "rarity_level": "COMMON"
    },
    "666": {
        "name": "Brawler Bears #666",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/666.png",
        "dna": "6fe3f24f76ed4e42101e9c096e944c6c33b08681",
        "edition": "666",
        "type": "Black Bear",
        "date": 1677695115596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "666",
        "rarity_score": 291.0229555882295,
        "rarity_level": "RARE"
    },
    "1531": {
        "name": "Brawler Bears #1531",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1531.png",
        "dna": "69f5c0e64eca53f4a66255b2f92b650eac00569b",
        "edition": "1531",
        "type": "Deep Martian Cyborg",
        "date": 1677695116665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1531",
        "rarity_score": 250.30305738213445,
        "rarity_level": "COMMON"
    },
    "1710": {
        "name": "Brawler Bears #1710",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1710.png",
        "dna": "a5e58094407011b9270709de25197eab7a895173",
        "edition": "1710",
        "type": "Panda",
        "date": 1677695117464,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1710",
        "rarity_score": 274.725290338854,
        "rarity_level": "COMMON"
    },
    "1936": {
        "name": "Brawler Bears #1936",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1936.png",
        "dna": "a4209ad1ff97b5cc5eec84d7811c426ff79817c9",
        "edition": "1936",
        "type": "Galaxy",
        "date": 1677695118501,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1936",
        "rarity_score": 193.6238828508194,
        "rarity_level": "COMMON"
    },
    "899": {
        "name": "Brawler Bears #899",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/899.png",
        "dna": "78d517da8fb60183e94545bc3dbe7178ea3f0935",
        "edition": "899",
        "type": "Deep Martian Cyborg",
        "date": 1677695120024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "899",
        "rarity_score": 244.45202293436088,
        "rarity_level": "COMMON"
    },
    "1388": {
        "name": "Brawler Bears #1388",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1388.png",
        "dna": "0a65fefe5f46974dea267e4445484db48d159556",
        "edition": "1388",
        "type": "Brown Bear",
        "date": 1677695122094,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1388",
        "rarity_score": 221.93683386366612,
        "rarity_level": "COMMON"
    },
    "401": {
        "name": "Brawler Bears #401",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/401.png",
        "dna": "b766407c1ba668c48beb9973c022a85d55ef914c",
        "edition": "401",
        "type": "Black Bear",
        "date": 1677695123138,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 69,
        "maxHealth": 394,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "401",
        "rarity_score": 80.20800655610428,
        "rarity_level": "COMMON"
    },
    "1063": {
        "name": "Brawler Bears #1063",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1063.png",
        "dna": "dd138177de321e2995e2cdc5e30f820f230523d1",
        "edition": "1063",
        "type": "Brown Bear",
        "date": 1677695124070,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1063",
        "rarity_score": 273.20737402764934,
        "rarity_level": "COMMON"
    },
    "1082": {
        "name": "Brawler Bears #1082",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1082.png",
        "dna": "0a3116454cd2efe8296b6680357f83245fed7dc2",
        "edition": "1082",
        "type": "Ice",
        "date": 1677695125516,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1082",
        "rarity_score": 276.40039816428407,
        "rarity_level": "RARE"
    },
    "812": {
        "name": "Brawler Bears #812",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/812.png",
        "dna": "0fbdecbe59eba886123332f94de61d3c25add596",
        "edition": "812",
        "type": "Cheetah",
        "date": 1677695127545,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 29,
        "maxHealth": 171,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "812",
        "rarity_score": 195.1362554923373,
        "rarity_level": "COMMON"
    },
    "748": {
        "name": "Brawler Bears #748",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/748.png",
        "dna": "01911dab6a3e7d854f19e62a31bac7477e9111b0",
        "edition": "748",
        "type": "Black Bear",
        "date": 1677695128660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "748",
        "rarity_score": 211.33415813572915,
        "rarity_level": "COMMON"
    },
    "1894": {
        "name": "Brawler Bears #1894",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1894.png",
        "dna": "860f7b28e3339e49ee8065b00882d3a6777a041f",
        "edition": "1894",
        "type": "Brown Bear",
        "date": 1677695129892,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1894",
        "rarity_score": 219.71112282123391,
        "rarity_level": "COMMON"
    },
    "2129": {
        "name": "Brawler Bears #2129",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2129.png",
        "dna": "6a919312830fb3db81a85b27ba846f21ee28ad2a",
        "edition": "2129",
        "type": "Galaxy",
        "date": 1677695131976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 52,
        "maxHealth": 301,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2129",
        "rarity_score": 188.5160961273304,
        "rarity_level": "COMMON"
    },
    "1692": {
        "name": "Brawler Bears #1692",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1692.png",
        "dna": "8e7a83793438396b6cdef93ba5aef6754885d529",
        "edition": "1692",
        "type": "Cheetah",
        "date": 1677695132942,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1692",
        "rarity_score": 149.75068682739715,
        "rarity_level": "COMMON"
    },
    "819": {
        "name": "Brawler Bears #819",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/819.png",
        "dna": "0555c271ae9788ed23cce273480e482d58686a7b",
        "edition": "819",
        "type": "Black Bear",
        "date": 1677695133860,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "819",
        "rarity_score": 283.17041256848717,
        "rarity_level": "RARE"
    },
    "1214": {
        "name": "Brawler Bears #1214",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1214.png",
        "dna": "5e25af5b107da1631c7aa405bbf5e752f9d6f2d8",
        "edition": "1214",
        "type": "Cheetah",
        "date": 1677695135741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1214",
        "rarity_score": 254.4718180490224,
        "rarity_level": "COMMON"
    },
    "2282": {
        "name": "Brawler Bears #2282",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2282.png",
        "dna": "096556e0d7f60138a20c6070c007b2075f492461",
        "edition": "2282",
        "type": "Brown Bear",
        "date": 1677695136935,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 22,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "2282",
        "rarity_score": 228.24071378102357,
        "rarity_level": "COMMON"
    },
    "1291": {
        "name": "Brawler Bears #1291",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1291.png",
        "dna": "3977152f8537467ce1c47d4eaf696b0f9c3057dd",
        "edition": "1291",
        "type": "Ice",
        "date": 1677695138418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 25,
        "maxHealth": 145,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1291",
        "rarity_score": 194.01552895479782,
        "rarity_level": "COMMON"
    },
    "1124": {
        "name": "Brawler Bears #1124",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1124.png",
        "dna": "65006c251222d6502db37130759ce8246afead26",
        "edition": "1124",
        "type": "Galaxy",
        "date": 1677695139549,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1124",
        "rarity_score": 293.62330007667975,
        "rarity_level": "RARE"
    },
    "1414": {
        "name": "Brawler Bears #1414",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1414.png",
        "dna": "fcf69aba729867ffb6ca0c15445bde856b688de0",
        "edition": "1414",
        "type": "Panda",
        "date": 1677695140776,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1414",
        "rarity_score": 244.92132303066964,
        "rarity_level": "COMMON"
    },
    "1238": {
        "name": "Brawler Bears #1238",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1238.png",
        "dna": "36fd264e127548401c713dfbf312b4b7d977820a",
        "edition": "1238",
        "type": "Deep Martian Cyborg",
        "date": 1677695141575,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 47,
        "maxHealth": 271,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1238",
        "rarity_score": 128.1460090156383,
        "rarity_level": "COMMON"
    },
    "448": {
        "name": "Brawler Bears #448",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/448.png",
        "dna": "2629be169abdf9369261f19df629dffffe3e2273",
        "edition": "448",
        "type": "Galaxy",
        "date": 1677695142724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "448",
        "rarity_score": 256.46892349279904,
        "rarity_level": "COMMON"
    },
    "723": {
        "name": "Brawler Bears #723",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/723.png",
        "dna": "cea49e1632030abf484706bda165ca84c71b0fdc",
        "edition": "723",
        "type": "Cheetah",
        "date": 1677695144425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "723",
        "rarity_score": 262.6473817709159,
        "rarity_level": "COMMON"
    },
    "1484": {
        "name": "Brawler Bears #1484",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1484.png",
        "dna": "e70c59184c8b198791b59c634d70c138a87297af",
        "edition": "1484",
        "type": "Deep Martian Cyborg",
        "date": 1677695145863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 26,
        "maxHealth": 149,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1484",
        "rarity_score": 178.53570780678547,
        "rarity_level": "COMMON"
    },
    "1652": {
        "name": "Brawler Bears #1652",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1652.png",
        "dna": "1082fc6029d0d3b9279059084cff2288057795aa",
        "edition": "1652",
        "type": "Black Bear",
        "date": 1677695146529,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1652",
        "rarity_score": 261.93666752933336,
        "rarity_level": "COMMON"
    },
    "1168": {
        "name": "Brawler Bears #1168",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1168.png",
        "dna": "1ed3c98378cbece7b8ff88a355738fc863e0cfca",
        "edition": "1168",
        "type": "Panda",
        "date": 1677695147327,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 45,
        "maxHealth": 258,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1168",
        "rarity_score": 153.90484545944904,
        "rarity_level": "COMMON"
    },
    "593": {
        "name": "Brawler Bears #593",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/593.png",
        "dna": "1570e3a9ff3440c0183c8fa2fc16a2be7df2f30d",
        "edition": "593",
        "type": "Ice",
        "date": 1677695148180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "593",
        "rarity_score": 259.7524918618385,
        "rarity_level": "COMMON"
    },
    "1256": {
        "name": "Brawler Bears #1256",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1256.png",
        "dna": "0fbe2d9f6552b268ad287d076a3637ee14310996",
        "edition": "1256",
        "type": "Deep Martian Cyborg",
        "date": 1677695149113,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1256",
        "rarity_score": 265.90148197190945,
        "rarity_level": "COMMON"
    },
    "935": {
        "name": "Brawler Bears #935",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/935.png",
        "dna": "32d192d82164ff554416927a4d29300f8fe14c22",
        "edition": "935",
        "type": "Galactic Cyborg",
        "date": 1677695150195,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "935",
        "rarity_score": 245.19053964326082,
        "rarity_level": "COMMON"
    },
    "1440": {
        "name": "Brawler Bears #1440",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1440.png",
        "dna": "0b4e9409d8ed6127a5e95d9fe94d66deca1d6923",
        "edition": "1440",
        "type": "Acid Trip",
        "date": 1677695151057,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 34,
        "maxHealth": 198,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1440",
        "rarity_score": 212.4322274097472,
        "rarity_level": "COMMON"
    },
    "1289": {
        "name": "Brawler Bears #1289",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1289.png",
        "dna": "91bb00ad16fbc4805af9b8e869e5d6597311f9f1",
        "edition": "1289",
        "type": "Deep Martian Cyborg",
        "date": 1677695152127,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1289",
        "rarity_score": 282.9338967255855,
        "rarity_level": "RARE"
    },
    "1049": {
        "name": "Brawler Bears #1049",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1049.png",
        "dna": "cdf0cd19d0eedc685b1a82143d18c0b243477725",
        "edition": "1049",
        "type": "Brown Bear",
        "date": 1677695152990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 59,
        "maxHealth": 337,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1049",
        "rarity_score": 169.47300258488224,
        "rarity_level": "COMMON"
    },
    "1107": {
        "name": "Brawler Bears #1107",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1107.png",
        "dna": "213791506fcee8ff7d1cbb4a208c02500a60bc55",
        "edition": "1107",
        "type": "Galactic Cyborg",
        "date": 1677695153786,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "1107",
        "rarity_score": 260.29463364429625,
        "rarity_level": "COMMON"
    },
    "90": {
        "name": "Brawler Bears #90",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/90.png",
        "dna": "3d39137cfab4612548c47a685c1723a473136640",
        "edition": "90",
        "type": "Brown Bear",
        "date": 1677695154664,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "90",
        "rarity_score": 254.13969709095014,
        "rarity_level": "COMMON"
    },
    "1524": {
        "name": "Brawler Bears #1524",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1524.png",
        "dna": "bfe4692a8a1b7de30bc02c828c2da993a057a996",
        "edition": "1524",
        "type": "Deep Martian Cyborg",
        "date": 1677695155692,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1524",
        "rarity_score": 273.858282324602,
        "rarity_level": "COMMON"
    },
    "1908": {
        "name": "Brawler Bears #1908",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1908.png",
        "dna": "f9e45baa29364ded19d18623c2d3c415d7547b00",
        "edition": "1908",
        "type": "Galaxy",
        "date": 1677695157274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1908",
        "rarity_score": 139.54132397775354,
        "rarity_level": "COMMON"
    },
    "1163": {
        "name": "Brawler Bears #1163",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1163.png",
        "dna": "3ef48b105b71468ec4aef7703535ba7a2a86d715",
        "edition": "1163",
        "type": "Brown Bear",
        "date": 1677695158146,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 48,
        "maxHealth": 277,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1163",
        "rarity_score": 124.38780400730798,
        "rarity_level": "COMMON"
    },
    "545": {
        "name": "Brawler Bears #545",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/545.png",
        "dna": "4ac148e1bd921b5d1a2ee23df6a41de34ca24198",
        "edition": "545",
        "type": "Cheetah",
        "date": 1677695159524,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 13,
        "maxHealth": 74,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "545",
        "rarity_score": 252.93199901937038,
        "rarity_level": "COMMON"
    },
    "421": {
        "name": "Brawler Bears #421",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/421.png",
        "dna": "95018a5c9e4ad92e09b3bec4d2a1a0814bff09d0",
        "edition": "421",
        "type": "Brown Bear",
        "date": 1677695161038,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 48,
        "maxHealth": 275,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "421",
        "rarity_score": 132.70809082519094,
        "rarity_level": "COMMON"
    },
    "369": {
        "name": "Brawler Bears #369",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/369.png",
        "dna": "40b17dc2bd476839f339fdf45498248af4a8bf36",
        "edition": "369",
        "type": "Black Bear",
        "date": 1677695162169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "369",
        "rarity_score": 205.73972684642752,
        "rarity_level": "COMMON"
    },
    "276": {
        "name": "Brawler Bears #276",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/276.png",
        "dna": "92b528fd225c83dc51dea01a90170efbab005e47",
        "edition": "276",
        "type": "Ice",
        "date": 1677695163455,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 31,
        "maxHealth": 181,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "276",
        "rarity_score": 256.1480005063222,
        "rarity_level": "COMMON"
    },
    "1674": {
        "name": "Brawler Bears #1674",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1674.png",
        "dna": "3906bb4458f579d2a46ea53030dfb4a38a117f05",
        "edition": "1674",
        "type": "Galaxy",
        "date": 1677695164695,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1674",
        "rarity_score": 288.4841518634622,
        "rarity_level": "RARE"
    },
    "2209": {
        "name": "Brawler Bears #2209",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2209.png",
        "dna": "0c393d8e00bd1fafc811a07585755bee3dbca91b",
        "edition": "2209",
        "type": "Brown Bear",
        "date": 1677695165534,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "2209",
        "rarity_score": 251.82516446814134,
        "rarity_level": "COMMON"
    },
    "1539": {
        "name": "Brawler Bears #1539",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1539.png",
        "dna": "2fd7e38bdb1157f518fbb79997521ba362449c8e",
        "edition": "1539",
        "type": "Acid Trip",
        "date": 1677695166422,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1539",
        "rarity_score": 233.84454820590523,
        "rarity_level": "COMMON"
    },
    "1302": {
        "name": "Brawler Bears #1302",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1302.png",
        "dna": "875e4ae2c817820c56317da265b947d369e234c3",
        "edition": "1302",
        "type": "Cheetah",
        "date": 1677695167565,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1302",
        "rarity_score": 236.02298628044605,
        "rarity_level": "COMMON"
    },
    "955": {
        "name": "Brawler Bears #955",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/955.png",
        "dna": "c4c3f034658d3df07ba591e98597b11717938568",
        "edition": "955",
        "type": "Black Bear",
        "date": 1677695168965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 47,
        "maxHealth": 272,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "955",
        "rarity_score": 121.70453210699212,
        "rarity_level": "COMMON"
    },
    "1523": {
        "name": "Brawler Bears #1523",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1523.png",
        "dna": "f22d801c29701bbcb4049a57d48bf37e21bc867a",
        "edition": "1523",
        "type": "Black Bear",
        "date": 1677695170020,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1523",
        "rarity_score": 259.77759362276487,
        "rarity_level": "COMMON"
    },
    "986": {
        "name": "Brawler Bears #986",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/986.png",
        "dna": "77be214b170d7fdee916be3ab5c621af8045cac4",
        "edition": "986",
        "type": "Black Bear",
        "date": 1677695171630,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 58,
        "maxHealth": 334,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "986",
        "rarity_score": 155.4858520953605,
        "rarity_level": "COMMON"
    },
    "182": {
        "name": "Brawler Bears #182",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/182.png",
        "dna": "dcc0f0a41fc44df85d57190751a9284006355c5c",
        "edition": "182",
        "type": "Lava",
        "date": 1677695172725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "182",
        "rarity_score": 279.75183385075366,
        "rarity_level": "RARE"
    },
    "1899": {
        "name": "Brawler Bears #1899",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1899.png",
        "dna": "501cd484763f00289faba30d51abff4ced7ef1d0",
        "edition": "1899",
        "type": "Galaxy",
        "date": 1677695173726,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1899",
        "rarity_score": 276.81322322731035,
        "rarity_level": "RARE"
    },
    "879": {
        "name": "Brawler Bears #879",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/879.png",
        "dna": "d2488a8b373fa0323adbf470e8e6e71fffd97687",
        "edition": "879",
        "type": "Ice",
        "date": 1677695174730,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 15,
        "maxHealth": 85,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "879",
        "rarity_score": 238.0775157259344,
        "rarity_level": "COMMON"
    },
    "990": {
        "name": "Brawler Bears #990",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/990.png",
        "dna": "d8225503e704d51812586682f9c5fa5d54cd1094",
        "edition": "990",
        "type": "Galaxy",
        "date": 1677695175600,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "990",
        "rarity_score": 212.55192238020163,
        "rarity_level": "COMMON"
    },
    "1375": {
        "name": "Brawler Bears #1375",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1375.png",
        "dna": "6d6195725f2c0bef56fb5f441eba0234187fc600",
        "edition": "1375",
        "type": "Galactic Cyborg",
        "date": 1677695176479,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1375",
        "rarity_score": 165.50891009178,
        "rarity_level": "COMMON"
    },
    "616": {
        "name": "Brawler Bears #616",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/616.png",
        "dna": "9f77addc0b9acacc2c032518666186f6da168c59",
        "edition": "616",
        "type": "Panda",
        "date": 1677695177692,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 45,
        "maxHealth": 259,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "616",
        "rarity_score": 149.5074455854591,
        "rarity_level": "COMMON"
    },
    "994": {
        "name": "Brawler Bears #994",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/994.png",
        "dna": "2a71b95fa0ea814dcc47459857b4e698fa1bab21",
        "edition": "994",
        "type": "Cheetah",
        "date": 1677695178663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "994",
        "rarity_score": 270.75005628170084,
        "rarity_level": "COMMON"
    },
    "1660": {
        "name": "Brawler Bears #1660",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1660.png",
        "dna": "1381bb49d63207e81444589308b8cf9ad86ccfa2",
        "edition": "1660",
        "type": "Brown Bear",
        "date": 1677695180268,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1660",
        "rarity_score": 282.3602581205981,
        "rarity_level": "RARE"
    },
    "1884": {
        "name": "Brawler Bears #1884",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1884.png",
        "dna": "4a3ff57c2950486154ac2747c64b42a74ff1b529",
        "edition": "1884",
        "type": "Black Bear",
        "date": 1677695181802,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1884",
        "rarity_score": 281.1054173259422,
        "rarity_level": "RARE"
    },
    "1718": {
        "name": "Brawler Bears #1718",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1718.png",
        "dna": "2a1adbf07ee934185414b1ac6eafeb38e0be1e13",
        "edition": "1718",
        "type": "Black Bear",
        "date": 1677695183187,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 47,
        "maxHealth": 270,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1718",
        "rarity_score": 129.29696356542325,
        "rarity_level": "COMMON"
    },
    "524": {
        "name": "Brawler Bears #524",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/524.png",
        "dna": "bc2b95aa18bf744aedec78aed6d4f1ee82b412fd",
        "edition": "524",
        "type": "Galactic Cyborg",
        "date": 1677695184399,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 63,
        "maxHealth": 360,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "524",
        "rarity_score": 86.95256446991624,
        "rarity_level": "COMMON"
    },
    "1804": {
        "name": "Brawler Bears #1804",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1804.png",
        "dna": "1c8aabb83d47df2779b80ba9fbad302cb88d3e52",
        "edition": "1804",
        "type": "Galactic Cyborg",
        "date": 1677695185481,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1804",
        "rarity_score": 271.29506195422476,
        "rarity_level": "COMMON"
    },
    "2226": {
        "name": "Brawler Bears #2226",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2226.png",
        "dna": "41e998f54c856add79f534ead4e82b2289b639af",
        "edition": "2226",
        "type": "Black Bear",
        "date": 1677695186125,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "2226",
        "rarity_score": 243.61384639052844,
        "rarity_level": "COMMON"
    },
    "718": {
        "name": "Brawler Bears #718",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/718.png",
        "dna": "4599757b055e54fb74c047ca5fcd6630a3569479",
        "edition": "718",
        "type": "Galactic Cyborg",
        "date": 1677695186937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 52,
        "maxHealth": 301,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "718",
        "rarity_score": 224.48458377761975,
        "rarity_level": "COMMON"
    },
    "1512": {
        "name": "Brawler Bears #1512",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1512.png",
        "dna": "ebd840d599c33e91b1554992f356793aa5e555bc",
        "edition": "1512",
        "type": "Black Bear",
        "date": 1677695188380,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1512",
        "rarity_score": 235.1863469219507,
        "rarity_level": "COMMON"
    },
    "1958": {
        "name": "Brawler Bears #1958",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1958.png",
        "dna": "0cf01a6a9a42e9a16bbf1adf6749e7e104b1e993",
        "edition": "1958",
        "type": "Brown Bear",
        "date": 1677695189850,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1958",
        "rarity_score": 297.9710336893067,
        "rarity_level": "RARE"
    },
    "636": {
        "name": "Brawler Bears #636",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/636.png",
        "dna": "15e9488f52b0494297acd2d2219a7c88cd4fad93",
        "edition": "636",
        "type": "Galactic Cyborg",
        "date": 1677695191030,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 54,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "636",
        "rarity_score": 152.26158588885949,
        "rarity_level": "COMMON"
    },
    "34": {
        "name": "Brawler Bears #34",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/34.png",
        "dna": "5fb6973ed9b78f23d69a90aafd074db89b8a8b52",
        "edition": "34",
        "type": "Panda",
        "date": 1677695192668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 51,
        "maxHealth": 294,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "34",
        "rarity_score": 170.24642197080186,
        "rarity_level": "COMMON"
    },
    "1285": {
        "name": "Brawler Bears #1285",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1285.png",
        "dna": "3a7586aff7b6cac334dac812cef7d95e4c057705",
        "edition": "1285",
        "type": "Brown Bear",
        "date": 1677695193492,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1285",
        "rarity_score": 256.4890196515876,
        "rarity_level": "COMMON"
    },
    "1728": {
        "name": "Brawler Bears #1728",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1728.png",
        "dna": "35ac93a7525d6751adcd4ed0d71868b425d20dc9",
        "edition": "1728",
        "type": "Panda",
        "date": 1677695194761,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1728",
        "rarity_score": 197.5654602712695,
        "rarity_level": "COMMON"
    },
    "711": {
        "name": "Brawler Bears #711",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/711.png",
        "dna": "6ab502b056cc3fa67e4342c9e2e1f17605e4ba04",
        "edition": "711",
        "type": "Galaxy",
        "date": 1677695195978,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "711",
        "rarity_score": 149.87859683769597,
        "rarity_level": "COMMON"
    },
    "1505": {
        "name": "Brawler Bears #1505",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1505.png",
        "dna": "cc04e1de26ca54c793b619961cc62661c474d085",
        "edition": "1505",
        "type": "Galactic Cyborg",
        "date": 1677695196759,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1505",
        "rarity_score": 275.2646573846667,
        "rarity_level": "RARE"
    },
    "1033": {
        "name": "Brawler Bears #1033",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1033.png",
        "dna": "3ad9158757f4962b5821c9b1a1cfd71ee3e85c90",
        "edition": "1033",
        "type": "Panda",
        "date": 1677695197672,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1033",
        "rarity_score": 180.2274336667232,
        "rarity_level": "COMMON"
    },
    "1590": {
        "name": "Brawler Bears #1590",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1590.png",
        "dna": "006252a5dafb890503736b6f82b743ca1e7f7ff9",
        "edition": "1590",
        "type": "Black Bear",
        "date": 1677695198440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1590",
        "rarity_score": 220.9126303190889,
        "rarity_level": "COMMON"
    },
    "1384": {
        "name": "Brawler Bears #1384",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1384.png",
        "dna": "8965e13931a765bc5c6af3de48d203a1c4921bcf",
        "edition": "1384",
        "type": "Ice",
        "date": 1677695199539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1384",
        "rarity_score": 263.5980661247923,
        "rarity_level": "COMMON"
    },
    "6": {
        "name": "Brawler Bears #6",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/6.png",
        "dna": "a9e6872ab15ba2e3e047cda5e5eff206510d5f57",
        "edition": "6",
        "type": "Galactic Cyborg",
        "date": 1677695201200,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "6",
        "rarity_score": 195.61933192754566,
        "rarity_level": "COMMON"
    },
    "1054": {
        "name": "Brawler Bears #1054",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1054.png",
        "dna": "c8c169c72103eb4bda35c37d040aa9b207d0b105",
        "edition": "1054",
        "type": "Black Bear",
        "date": 1677695202548,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1054",
        "rarity_score": 258.4266681962395,
        "rarity_level": "COMMON"
    },
    "1467": {
        "name": "Brawler Bears #1467",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1467.png",
        "dna": "af1054eb42de5a0afbb831d6bfd2a869916cb20b",
        "edition": "1467",
        "type": "Black Bear",
        "date": 1677695203380,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 58,
        "maxHealth": 333,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1467",
        "rarity_score": 164.17904516742328,
        "rarity_level": "COMMON"
    },
    "2227": {
        "name": "Brawler Bears #2227",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2227.png",
        "dna": "769deb827de76fa40b3660f038710e1b13904547",
        "edition": "2227",
        "type": "Galactic Cyborg",
        "date": 1677695204107,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "2227",
        "rarity_score": 233.97391154365195,
        "rarity_level": "COMMON"
    },
    "351": {
        "name": "Brawler Bears #351",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/351.png",
        "dna": "b8a7ed3d13d2023c0d92943bdcc1a32adae6cf9d",
        "edition": "351",
        "type": "Panda",
        "date": 1677695204948,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 30,
        "maxHealth": 175,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "351",
        "rarity_score": 223.46234296197517,
        "rarity_level": "COMMON"
    },
    "2187": {
        "name": "Brawler Bears #2187",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2187.png",
        "dna": "093906b73ae602c4c7dc297483b7223ae9566609",
        "edition": "2187",
        "type": "Galactic Cyborg",
        "date": 1677695206263,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2187",
        "rarity_score": 241.17347515584646,
        "rarity_level": "COMMON"
    },
    "1272": {
        "name": "Brawler Bears #1272",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1272.png",
        "dna": "1c7b3664b4a02476e880a1ff0c12cfd6577b9c20",
        "edition": "1272",
        "type": "Brown Bear",
        "date": 1677695207043,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1272",
        "rarity_score": 246.26251581377605,
        "rarity_level": "COMMON"
    },
    "1341": {
        "name": "Brawler Bears #1341",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1341.png",
        "dna": "0dd9bfcead85abcb97dbf5e7458bb35cd76f9d23",
        "edition": "1341",
        "type": "Black Bear",
        "date": 1677695208186,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1341",
        "rarity_score": 236.65170780739334,
        "rarity_level": "COMMON"
    },
    "1867": {
        "name": "Brawler Bears #1867",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1867.png",
        "dna": "ed4c96869247033c00d5d6a680908ca3733c54c7",
        "edition": "1867",
        "type": "Galaxy",
        "date": 1677695209572,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1867",
        "rarity_score": 279.2950245166167,
        "rarity_level": "RARE"
    },
    "594": {
        "name": "Brawler Bears #594",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/594.png",
        "dna": "8aa992ada04de51e12f2a547e377ff463ae3916a",
        "edition": "594",
        "type": "Brown Bear",
        "date": 1677695211177,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "594",
        "rarity_score": 214.3351269622774,
        "rarity_level": "COMMON"
    },
    "1528": {
        "name": "Brawler Bears #1528",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1528.png",
        "dna": "07961b11d1be41ba67611b6368a886db1e9ac521",
        "edition": "1528",
        "type": "Ice",
        "date": 1677695212395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1528",
        "rarity_score": 239.62009479906447,
        "rarity_level": "COMMON"
    },
    "2181": {
        "name": "Brawler Bears #2181",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2181.png",
        "dna": "01cf1db69f0fa29f1788e4ca7563d29938f6a093",
        "edition": "2181",
        "type": "Black Bear",
        "date": 1677695213522,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 57,
        "maxHealth": 328,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "2181",
        "rarity_score": 254.3565801822694,
        "rarity_level": "COMMON"
    },
    "920": {
        "name": "Brawler Bears #920",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/920.png",
        "dna": "3058efd0973560658dc3a94b70542164c5c93a65",
        "edition": "920",
        "type": "Black Bear",
        "date": 1677695214986,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 20,
        "maxHealth": 118,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "920",
        "rarity_score": 278.14762371802084,
        "rarity_level": "RARE"
    },
    "946": {
        "name": "Brawler Bears #946",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/946.png",
        "dna": "5157dc07731334152885f66fb73cc8baf953767b",
        "edition": "946",
        "type": "Deep Martian Cyborg",
        "date": 1677695215921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "946",
        "rarity_score": 331.9380285445656,
        "rarity_level": "RARE"
    },
    "1169": {
        "name": "Brawler Bears #1169",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1169.png",
        "dna": "9d8e1c7b93f3bee19974854cb5808cda7d095873",
        "edition": "1169",
        "type": "Galactic Cyborg",
        "date": 1677695217208,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1169",
        "rarity_score": 169.695869887326,
        "rarity_level": "COMMON"
    },
    "733": {
        "name": "Brawler Bears #733",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/733.png",
        "dna": "940e579fb4a666357c64aff369e16fca881e0b32",
        "edition": "733",
        "type": "Ice",
        "date": 1677695218366,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 26,
        "maxHealth": 152,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "733",
        "rarity_score": 160.6933153685756,
        "rarity_level": "COMMON"
    },
    "1406": {
        "name": "Brawler Bears #1406",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1406.png",
        "dna": "676b2ca408bf5e54e87b9fa11e370137ee414430",
        "edition": "1406",
        "type": "Brown Bear",
        "date": 1677695219230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1406",
        "rarity_score": 215.46017827534277,
        "rarity_level": "COMMON"
    },
    "1976": {
        "name": "Brawler Bears #1976",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1976.png",
        "dna": "effede9ae94f2034838ab5511d599e6dc1f7be0e",
        "edition": "1976",
        "type": "Black Bear",
        "date": 1677695219949,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 47,
        "maxHealth": 272,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1976",
        "rarity_score": 121.49783073847411,
        "rarity_level": "COMMON"
    },
    "289": {
        "name": "Brawler Bears #289",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/289.png",
        "dna": "f4fbc9e0fd7ffcd23d67fa5a8efbf41f71037dd8",
        "edition": "289",
        "type": "Brown Bear",
        "date": 1677695221349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "289",
        "rarity_score": 255.66759484893817,
        "rarity_level": "COMMON"
    },
    "207": {
        "name": "Brawler Bears #207",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/207.png",
        "dna": "9980b587efd434026e784ee8f565973da5d8a406",
        "edition": "207",
        "type": "Brown Bear",
        "date": 1677695222391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 59,
        "maxHealth": 340,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "207",
        "rarity_score": 167.1244145441523,
        "rarity_level": "COMMON"
    },
    "114": {
        "name": "Brawler Bears #114",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/114.png",
        "dna": "646eca0f189c6f54d761ba989ff5878b68f3eae2",
        "edition": "114",
        "type": "Black Bear",
        "date": 1677695223024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 67,
        "maxHealth": 385,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "114",
        "rarity_score": 93.59788200362321,
        "rarity_level": "COMMON"
    },
    "1832": {
        "name": "Brawler Bears #1832",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1832.png",
        "dna": "38f89b8174b94e7852ae378e67c172068633f3e6",
        "edition": "1832",
        "type": "Panda",
        "date": 1677695224688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 24,
        "maxHealth": 138,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1832",
        "rarity_score": 192.1443425109527,
        "rarity_level": "COMMON"
    },
    "1445": {
        "name": "Brawler Bears #1445",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1445.png",
        "dna": "a0e5f5c72a82e305aadfa00c7487fb5fa632a444",
        "edition": "1445",
        "type": "Ice",
        "date": 1677695225881,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1445",
        "rarity_score": 256.5489421891109,
        "rarity_level": "COMMON"
    },
    "1869": {
        "name": "Brawler Bears #1869",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1869.png",
        "dna": "6025658b3959bcbfbf700116e176b2923cb24f76",
        "edition": "1869",
        "type": "Acid Trip",
        "date": 1677695226856,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1869",
        "rarity_score": 284.0816872008603,
        "rarity_level": "RARE"
    },
    "805": {
        "name": "Brawler Bears #805",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/805.png",
        "dna": "fb321937a90fb869958eb1f7e28b5f4d1eead3ed",
        "edition": "805",
        "type": "Galactic Cyborg",
        "date": 1677695227907,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "805",
        "rarity_score": 270.21613404885807,
        "rarity_level": "COMMON"
    },
    "1213": {
        "name": "Brawler Bears #1213",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1213.png",
        "dna": "18202a467e219e87c183f8910943227153758c26",
        "edition": "1213",
        "type": "Ice",
        "date": 1677695229180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1213",
        "rarity_score": 193.72278316076125,
        "rarity_level": "COMMON"
    },
    "417": {
        "name": "Brawler Bears #417",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/417.png",
        "dna": "abac6e17c45e3d653e5debee87de098ee785e7ea",
        "edition": "417",
        "type": "Brown Bear",
        "date": 1677695230053,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 31,
        "maxHealth": 181,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "417",
        "rarity_score": 181.4243022643903,
        "rarity_level": "COMMON"
    },
    "1667": {
        "name": "Brawler Bears #1667",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1667.png",
        "dna": "2b39479d04681d8cddb54217001dd2c61c6ae3a8",
        "edition": "1667",
        "type": "Brown Bear",
        "date": 1677695231103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1667",
        "rarity_score": 239.9444416797866,
        "rarity_level": "COMMON"
    },
    "2290": {
        "name": "Brawler Bears #2290",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2290.png",
        "dna": "38496daaeef1562013d60181b194c25d698a4536",
        "edition": "2290",
        "type": "Black Bear",
        "date": 1677695232758,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 52,
        "maxHealth": 298,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2290",
        "rarity_score": 132.35613159534947,
        "rarity_level": "COMMON"
    },
    "1677": {
        "name": "Brawler Bears #1677",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1677.png",
        "dna": "af9fc27a57e4808e95e14f676e9e2f4f273e56f6",
        "edition": "1677",
        "type": "Deep Martian Cyborg",
        "date": 1677695233535,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1677",
        "rarity_score": 273.39982604731614,
        "rarity_level": "COMMON"
    },
    "311": {
        "name": "Brawler Bears #311",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/311.png",
        "dna": "8bcaa4081522ed90e985850b376e2d3d974d769c",
        "edition": "311",
        "type": "Panda",
        "date": 1677695234464,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 45,
        "maxHealth": 262,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "311",
        "rarity_score": 128.4389906072474,
        "rarity_level": "COMMON"
    },
    "196": {
        "name": "Brawler Bears #196",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/196.png",
        "dna": "3078b2a8a875506c0d69a20a84012b4bd4cc8346",
        "edition": "196",
        "type": "Galaxy",
        "date": 1677695235875,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 26,
        "maxHealth": 153,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "196",
        "rarity_score": 165.93199770489662,
        "rarity_level": "COMMON"
    },
    "1695": {
        "name": "Brawler Bears #1695",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1695.png",
        "dna": "e8066b8bad6a8675393b91e0fb3d6d5cc453207c",
        "edition": "1695",
        "type": "Galactic Cyborg",
        "date": 1677695236748,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "1695",
        "rarity_score": 323.9990450955293,
        "rarity_level": "RARE"
    },
    "724": {
        "name": "Brawler Bears #724",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/724.png",
        "dna": "1efac4720810c7bf641495e9a0f04c6340e940b4",
        "edition": "724",
        "type": "Panda",
        "date": 1677695237449,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "724",
        "rarity_score": 225.8291682650811,
        "rarity_level": "COMMON"
    },
    "1940": {
        "name": "Brawler Bears #1940",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1940.png",
        "dna": "d76274af8d52c79be3ec0b5bbf9ec767388a7ea7",
        "edition": "1940",
        "type": "Deep Martian Cyborg",
        "date": 1677695239139,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1940",
        "rarity_score": 269.5111469043643,
        "rarity_level": "COMMON"
    },
    "2241": {
        "name": "Brawler Bears #2241",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2241.png",
        "dna": "e28e69d0741a117a6fdf908515aa7853724c8a1b",
        "edition": "2241",
        "type": "Ice",
        "date": 1677695240219,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2241",
        "rarity_score": 255.6084060085623,
        "rarity_level": "COMMON"
    },
    "1474": {
        "name": "Brawler Bears #1474",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1474.png",
        "dna": "8676d0814dd0eccb153fa626744827e05872008a",
        "edition": "1474",
        "type": "Black Bear",
        "date": 1677695241348,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1474",
        "rarity_score": 256.4820459192486,
        "rarity_level": "COMMON"
    },
    "1995": {
        "name": "Brawler Bears #1995",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1995.png",
        "dna": "4c30c2986775d19319a00237f829cc428c0fdace",
        "edition": "1995",
        "type": "Black Bear",
        "date": 1677695242894,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 20,
        "maxHealth": 118,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1995",
        "rarity_score": 293.0157722990505,
        "rarity_level": "RARE"
    },
    "1294": {
        "name": "Brawler Bears #1294",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1294.png",
        "dna": "aacb6102950589f296c9b16e1c74ad10678efeab",
        "edition": "1294",
        "type": "Cheetah",
        "date": 1677695244217,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 13,
        "maxHealth": 76,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1294",
        "rarity_score": 303.7295322574051,
        "rarity_level": "RARE"
    },
    "1805": {
        "name": "Brawler Bears #1805",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1805.png",
        "dna": "58a6dcea402d47943de976d42fd387df43463fcb",
        "edition": "1805",
        "type": "Brown Bear",
        "date": 1677695245148,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1805",
        "rarity_score": 208.3317457095097,
        "rarity_level": "COMMON"
    },
    "2244": {
        "name": "Brawler Bears #2244",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2244.png",
        "dna": "a0ddbac48d714d8f6e061278af3cd1710aa126b3",
        "edition": "2244",
        "type": "Brown Bear",
        "date": 1677695246662,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "2244",
        "rarity_score": 262.20540473972284,
        "rarity_level": "COMMON"
    },
    "148": {
        "name": "Brawler Bears #148",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/148.png",
        "dna": "ccf6b5f3e0c16dea725504b0e10a78a3a275d21f",
        "edition": "148",
        "type": "Lava",
        "date": 1677695247536,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 44,
        "maxHealth": 251,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "148",
        "rarity_score": 187.86613741962287,
        "rarity_level": "COMMON"
    },
    "2055": {
        "name": "Brawler Bears #2055",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2055.png",
        "dna": "b74535d7a1199aaa9c26027a00fe31097916913f",
        "edition": "2055",
        "type": "Brown Bear",
        "date": 1677695248290,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 48,
        "maxHealth": 279,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2055",
        "rarity_score": 117.36615423447807,
        "rarity_level": "COMMON"
    },
    "1030": {
        "name": "Brawler Bears #1030",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1030.png",
        "dna": "6b673b78ead2394efc85e3465141c8f701383034",
        "edition": "1030",
        "type": "Deep Martian Cyborg",
        "date": 1677695249385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1030",
        "rarity_score": 260.1962442383107,
        "rarity_level": "COMMON"
    },
    "1851": {
        "name": "Brawler Bears #1851",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1851.png",
        "dna": "aae08dc36599b86a2c4324f36eafd8d29313afd6",
        "edition": "1851",
        "type": "Panda",
        "date": 1677695250026,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 35,
        "maxHealth": 200,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1851",
        "rarity_score": 208.87879118272264,
        "rarity_level": "COMMON"
    },
    "1172": {
        "name": "Brawler Bears #1172",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1172.png",
        "dna": "e9a228f8725878557f90ee9b9f2bf25d003701a8",
        "edition": "1172",
        "type": "Panda",
        "date": 1677695251612,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1172",
        "rarity_score": 273.8290739555463,
        "rarity_level": "COMMON"
    },
    "2289": {
        "name": "Brawler Bears #2289",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2289.png",
        "dna": "9e1fc72fb035269eb9890c006b122f7753ba76f4",
        "edition": "2289",
        "type": "Deep Martian Cyborg",
        "date": 1677695252593,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 62,
        "maxHealth": 355,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2289",
        "rarity_score": 88.442492214301,
        "rarity_level": "COMMON"
    },
    "1231": {
        "name": "Brawler Bears #1231",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1231.png",
        "dna": "8dd15bbef7c8454d64aa40a66a1628a33e66ab17",
        "edition": "1231",
        "type": "Acid Trip",
        "date": 1677695253625,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1231",
        "rarity_score": 190.7374226572474,
        "rarity_level": "COMMON"
    },
    "933": {
        "name": "Brawler Bears #933",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/933.png",
        "dna": "5bbf9d9fdba10cdc608eced5d969fe9edf1c2aab",
        "edition": "933",
        "type": "Brown Bear",
        "date": 1677695254972,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "933",
        "rarity_score": 257.2315635130946,
        "rarity_level": "COMMON"
    },
    "2200": {
        "name": "Brawler Bears #2200",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2200.png",
        "dna": "f2ce343e68bdcb70b350118d230f1db1e85b9c47",
        "edition": "2200",
        "type": "Deep Martian Cyborg",
        "date": 1677695256058,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "2200",
        "rarity_score": 282.28966711067784,
        "rarity_level": "RARE"
    },
    "1146": {
        "name": "Brawler Bears #1146",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1146.png",
        "dna": "000b104e6ef4ef4746210dbe02e419e8aff02f1b",
        "edition": "1146",
        "type": "Acid Trip",
        "date": 1677695257431,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 13,
        "maxHealth": 76,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1146",
        "rarity_score": 273.5335671581071,
        "rarity_level": "COMMON"
    },
    "1226": {
        "name": "Brawler Bears #1226",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1226.png",
        "dna": "8bc77b16a175e98b78dbe78b3b25a858beeffd60",
        "edition": "1226",
        "type": "Black Bear",
        "date": 1677695258366,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1226",
        "rarity_score": 297.75155768932956,
        "rarity_level": "RARE"
    },
    "1623": {
        "name": "Brawler Bears #1623",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1623.png",
        "dna": "62875546a917421c6b629ab08c4315ce6965f6f7",
        "edition": "1623",
        "type": "Black Bear",
        "date": 1677695259362,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1623",
        "rarity_score": 255.64384642112728,
        "rarity_level": "COMMON"
    },
    "259": {
        "name": "Brawler Bears #259",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/259.png",
        "dna": "375489e0d130a1c8ed800524fff57588b7701749",
        "edition": "259",
        "type": "Cheetah",
        "date": 1677695260716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "259",
        "rarity_score": 240.81688637546796,
        "rarity_level": "COMMON"
    },
    "1210": {
        "name": "Brawler Bears #1210",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1210.png",
        "dna": "c79da7ec8e3fd5e2944d15764ba767c25767171b",
        "edition": "1210",
        "type": "Brown Bear",
        "date": 1677695261579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1210",
        "rarity_score": 212.82327210141386,
        "rarity_level": "COMMON"
    },
    "956": {
        "name": "Brawler Bears #956",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/956.png",
        "dna": "bcb730dc2db71d2e02182acaf5cd0b52e62695a8",
        "edition": "956",
        "type": "Black Bear",
        "date": 1677695262581,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "956",
        "rarity_score": 271.7439999726787,
        "rarity_level": "COMMON"
    },
    "1748": {
        "name": "Brawler Bears #1748",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1748.png",
        "dna": "9e44c4a4788c526adc05882fad028408fc42b777",
        "edition": "1748",
        "type": "Cheetah",
        "date": 1677695263289,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1748",
        "rarity_score": 228.7038756092395,
        "rarity_level": "COMMON"
    },
    "91": {
        "name": "Brawler Bears #91",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/91.png",
        "dna": "ebe03d83ed85e9be0801eaeb00d9d06e5dcde7f7",
        "edition": "91",
        "type": "Brown Bear",
        "date": 1677695264446,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "91",
        "rarity_score": 182.92199136824973,
        "rarity_level": "COMMON"
    },
    "450": {
        "name": "Brawler Bears #450",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/450.png",
        "dna": "6403b47663b74adb406dd519582deb153de09095",
        "edition": "450",
        "type": "Lava",
        "date": 1677695265778,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "450",
        "rarity_score": 192.6983593261624,
        "rarity_level": "COMMON"
    },
    "1880": {
        "name": "Brawler Bears #1880",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1880.png",
        "dna": "75439cf5adba65ceac5f64cec91aa3071ce0ccfc",
        "edition": "1880",
        "type": "Brown Bear",
        "date": 1677695266770,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1880",
        "rarity_score": 278.9405759543733,
        "rarity_level": "RARE"
    },
    "189": {
        "name": "Brawler Bears #189",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/189.png",
        "dna": "a78c789b2c00c5d0b9d2cf0cf3a7e56b110c6c0c",
        "edition": "189",
        "type": "Panda",
        "date": 1677695267597,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "189",
        "rarity_score": 251.77362111039204,
        "rarity_level": "COMMON"
    },
    "1793": {
        "name": "Brawler Bears #1793",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1793.png",
        "dna": "4cb1c35def40d662d584a9d2ee91416dc0311d5e",
        "edition": "1793",
        "type": "Brown Bear",
        "date": 1677695268688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1793",
        "rarity_score": 178.32252107860836,
        "rarity_level": "COMMON"
    },
    "1780": {
        "name": "Brawler Bears #1780",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1780.png",
        "dna": "50fff07936e86011897d50d2ec42e276133b4cc9",
        "edition": "1780",
        "type": "Cheetah",
        "date": 1677695269602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 34,
        "maxHealth": 196,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1780",
        "rarity_score": 217.75300105852202,
        "rarity_level": "COMMON"
    },
    "1062": {
        "name": "Brawler Bears #1062",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1062.png",
        "dna": "7a7a4cbcc7cfbcaa9907874e060ee6e14596c817",
        "edition": "1062",
        "type": "Panda",
        "date": 1677695270632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1062",
        "rarity_score": 287.55590162369185,
        "rarity_level": "RARE"
    },
    "2032": {
        "name": "Brawler Bears #2032",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2032.png",
        "dna": "d8d7c19c45d95276ba2e4c5f37ec703ef7a6828f",
        "edition": "2032",
        "type": "Cheetah",
        "date": 1677695271824,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 60,
        "maxHealth": 344,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2032",
        "rarity_score": 103.42640213741404,
        "rarity_level": "COMMON"
    },
    "1822": {
        "name": "Brawler Bears #1822",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1822.png",
        "dna": "5d6d8c7d582ace04dbd1004d3ed03bc5ff92b043",
        "edition": "1822",
        "type": "Black Bear",
        "date": 1677695272427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 59,
        "maxHealth": 339,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1822",
        "rarity_score": 135.29574511561935,
        "rarity_level": "COMMON"
    },
    "1018": {
        "name": "Brawler Bears #1018",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1018.png",
        "dna": "daa7b5285ff9a6e31b0b9a9c061513d54ee17419",
        "edition": "1018",
        "type": "Black Bear",
        "date": 1677695273923,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1018",
        "rarity_score": 220.2111087772645,
        "rarity_level": "COMMON"
    },
    "336": {
        "name": "Brawler Bears #336",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/336.png",
        "dna": "11e7ae4f193b41e604e465926933a1ab3816e99a",
        "edition": "336",
        "type": "Black Bear",
        "date": 1677695274791,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 31,
        "maxHealth": 178,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "336",
        "rarity_score": 181.61994383381295,
        "rarity_level": "COMMON"
    },
    "739": {
        "name": "Brawler Bears #739",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/739.png",
        "dna": "74300398a58a33618134e1ae1d3a1ec45e07d24a",
        "edition": "739",
        "type": "Cheetah",
        "date": 1677695275956,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 13,
        "maxHealth": 76,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "739",
        "rarity_score": 295.9589542017078,
        "rarity_level": "RARE"
    },
    "434": {
        "name": "Brawler Bears #434",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/434.png",
        "dna": "3b64ceef698f51e363b363462d0fdc1ed44a6b37",
        "edition": "434",
        "type": "Brown Bear",
        "date": 1677695277017,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "434",
        "rarity_score": 193.81302953067177,
        "rarity_level": "COMMON"
    },
    "1392": {
        "name": "Brawler Bears #1392",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1392.png",
        "dna": "1554ef1088b1f380113f7dcc4c96c3d06a873f1e",
        "edition": "1392",
        "type": "Brown Bear",
        "date": 1677695277723,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 68,
        "maxHealth": 393,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1392",
        "rarity_score": 80.32839842217334,
        "rarity_level": "COMMON"
    },
    "515": {
        "name": "Brawler Bears #515",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/515.png",
        "dna": "2a9cd77183cadab5e06dc72a198060484b94cb00",
        "edition": "515",
        "type": "Galactic Cyborg",
        "date": 1677695278530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "515",
        "rarity_score": 257.288350708712,
        "rarity_level": "COMMON"
    },
    "2041": {
        "name": "Brawler Bears #2041",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2041.png",
        "dna": "809fbd17dc1cbf443d99f36dd8299bdd1b086fe5",
        "edition": "2041",
        "type": "Galaxy",
        "date": 1677695279946,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "2041",
        "rarity_score": 257.87162041912296,
        "rarity_level": "COMMON"
    },
    "541": {
        "name": "Brawler Bears #541",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/541.png",
        "dna": "04f8ebced78ad94592221819ec23ae8965f5b4fa",
        "edition": "541",
        "type": "Ice",
        "date": 1677695281257,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "541",
        "rarity_score": 145.87757150504996,
        "rarity_level": "COMMON"
    },
    "1533": {
        "name": "Brawler Bears #1533",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1533.png",
        "dna": "2901e6fb28fe4dc2e36b859465b80ea3bab03b29",
        "edition": "1533",
        "type": "Galactic Cyborg",
        "date": 1677695282906,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1533",
        "rarity_score": 266.31205573100414,
        "rarity_level": "COMMON"
    },
    "682": {
        "name": "Brawler Bears #682",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/682.png",
        "dna": "860864dc44b60981cf93f75323dfa824f4271a0d",
        "edition": "682",
        "type": "Black Bear",
        "date": 1677695283708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "682",
        "rarity_score": 278.7760780973639,
        "rarity_level": "RARE"
    },
    "615": {
        "name": "Brawler Bears #615",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/615.png",
        "dna": "95852be73ec22b43bcce9e1706edc9003b46b1df",
        "edition": "615",
        "type": "Galaxy",
        "date": 1677695285210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "615",
        "rarity_score": 234.9456108177,
        "rarity_level": "COMMON"
    },
    "901": {
        "name": "Brawler Bears #901",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/901.png",
        "dna": "db1a3968039717cfedfc48b088b2f6c7440fbe11",
        "edition": "901",
        "type": "Galaxy",
        "date": 1677695286228,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 46,
        "maxHealth": 264,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "901",
        "rarity_score": 149.69796089541632,
        "rarity_level": "COMMON"
    },
    "741": {
        "name": "Brawler Bears #741",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/741.png",
        "dna": "d38bec215d63abbb5455625c20ce2312c26bfb0a",
        "edition": "741",
        "type": "Brown Bear",
        "date": 1677695287368,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "741",
        "rarity_score": 217.3598792405209,
        "rarity_level": "COMMON"
    },
    "1208": {
        "name": "Brawler Bears #1208",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1208.png",
        "dna": "de0d3e3e603b2bb9a98b95c96570adf0c7054104",
        "edition": "1208",
        "type": "Ice",
        "date": 1677695288145,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1208",
        "rarity_score": 240.10321265634735,
        "rarity_level": "COMMON"
    },
    "69": {
        "name": "Brawler Bears #69",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/69.png",
        "dna": "7b11f8cd54242d661b42b68a9fab01e04a6b335d",
        "edition": "69",
        "type": "Black Bear",
        "date": 1677695289856,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "69",
        "rarity_score": 228.3100207703399,
        "rarity_level": "COMMON"
    },
    "1996": {
        "name": "Brawler Bears #1996",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1996.png",
        "dna": "ca7dad7a01406f8fbbbc60f9b325bde0b3ce65fb",
        "edition": "1996",
        "type": "Black Bear",
        "date": 1677695290424,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 58,
        "maxHealth": 334,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1996",
        "rarity_score": 188.703278647779,
        "rarity_level": "COMMON"
    },
    "737": {
        "name": "Brawler Bears #737",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/737.png",
        "dna": "8c8d5a4285823087a624f530198aa0e0b1570992",
        "edition": "737",
        "type": "Lava",
        "date": 1677695291453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 13,
        "maxHealth": 74,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "737",
        "rarity_score": 284.57032053091837,
        "rarity_level": "RARE"
    },
    "1829": {
        "name": "Brawler Bears #1829",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1829.png",
        "dna": "24f3ab19faeddead968239ca679b813b2f578b41",
        "edition": "1829",
        "type": "Galaxy",
        "date": 1677695292775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 46,
        "maxHealth": 265,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1829",
        "rarity_score": 159.83858856225368,
        "rarity_level": "COMMON"
    },
    "1930": {
        "name": "Brawler Bears #1930",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1930.png",
        "dna": "2da304819b69826f001cc8d03d3ba28a0967e649",
        "edition": "1930",
        "type": "Brown Bear",
        "date": 1677695294239,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1930",
        "rarity_score": 262.5369093432253,
        "rarity_level": "COMMON"
    },
    "1336": {
        "name": "Brawler Bears #1336",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1336.png",
        "dna": "fc935a8dc02294366ae16a7c25bbfaef3119c553",
        "edition": "1336",
        "type": "Ice",
        "date": 1677695295119,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1336",
        "rarity_score": 244.04729437229437,
        "rarity_level": "COMMON"
    },
    "1235": {
        "name": "Brawler Bears #1235",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1235.png",
        "dna": "0518b5fe320818bcc75ea664aec4ceec6c98ef42",
        "edition": "1235",
        "type": "Acid Trip",
        "date": 1677695296029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1235",
        "rarity_score": 255.21360199837426,
        "rarity_level": "COMMON"
    },
    "1565": {
        "name": "Brawler Bears #1565",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1565.png",
        "dna": "905f68ec6453078d6a8e8b9aacf49cfbf140715c",
        "edition": "1565",
        "type": "Panda",
        "date": 1677695296709,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 24,
        "maxHealth": 138,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "1565",
        "rarity_score": 197.5395302647661,
        "rarity_level": "COMMON"
    },
    "14": {
        "name": "Brawler Bears #14",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/14.png",
        "dna": "194077388daf9c9a8273886b3ddcd09189f3f4a6",
        "edition": "14",
        "type": "Brown Bear",
        "date": 1677695297532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "14",
        "rarity_score": 203.83703943533177,
        "rarity_level": "COMMON"
    },
    "1101": {
        "name": "Brawler Bears #1101",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1101.png",
        "dna": "b6a1906b5163ad00deedb6f2308743b9934a1541",
        "edition": "1101",
        "type": "Acid Trip",
        "date": 1677695298473,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1101",
        "rarity_score": 242.35247389084896,
        "rarity_level": "COMMON"
    },
    "543": {
        "name": "Brawler Bears #543",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/543.png",
        "dna": "0b9532faded83b8bee0932436e961cc8a3ae13cc",
        "edition": "543",
        "type": "Brown Bear",
        "date": 1677695299724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 245,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "543",
        "rarity_score": 267.7972888371279,
        "rarity_level": "COMMON"
    },
    "845": {
        "name": "Brawler Bears #845",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/845.png",
        "dna": "7894125f426d11ca8fd628dceded0960c09854fc",
        "edition": "845",
        "type": "Galactic Cyborg",
        "date": 1677695300878,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "845",
        "rarity_score": 249.43324234618942,
        "rarity_level": "COMMON"
    },
    "488": {
        "name": "Brawler Bears #488",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/488.png",
        "dna": "2690d592aa232060372c67d9a59d655f17eddb7c",
        "edition": "488",
        "type": "Acid Trip",
        "date": 1677695301774,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "488",
        "rarity_score": 336.66182726434164,
        "rarity_level": "RARE"
    },
    "111": {
        "name": "Brawler Bears #111",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/111.png",
        "dna": "3c6a657553f7053daa9bb2e919596863b1c2b387",
        "edition": "111",
        "type": "Galaxy",
        "date": 1677695303622,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "111",
        "rarity_score": 206.47012570213678,
        "rarity_level": "COMMON"
    },
    "767": {
        "name": "Brawler Bears #767",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/767.png",
        "dna": "2036bc62b462ec489530db6a37f908ccac5efd00",
        "edition": "767",
        "type": "Panda",
        "date": 1677695305556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "767",
        "rarity_score": 247.38388684345352,
        "rarity_level": "COMMON"
    },
    "1481": {
        "name": "Brawler Bears #1481",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1481.png",
        "dna": "442be440be2a2bd9f76a4ca93f9103b26a4419ef",
        "edition": "1481",
        "type": "Black Bear",
        "date": 1677695306746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 51,
        "maxHealth": 296,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1481",
        "rarity_score": 135.4756721462025,
        "rarity_level": "COMMON"
    },
    "2303": {
        "name": "Brawler Bears #2303",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2303.png",
        "dna": "1ece8576c641ef91b6b40fffbfa47122674e76b3",
        "edition": "2303",
        "type": "Acid Trip",
        "date": 1677695307635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2303",
        "rarity_score": 298.2942542504602,
        "rarity_level": "RARE"
    },
    "981": {
        "name": "Brawler Bears #981",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/981.png",
        "dna": "9fac16f6b363661192d77477ffa726e27f47c5c3",
        "edition": "981",
        "type": "Brown Bear",
        "date": 1677695308505,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 47,
        "maxHealth": 271,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "981",
        "rarity_score": 138.14128812636088,
        "rarity_level": "COMMON"
    },
    "221": {
        "name": "Brawler Bears #221",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/221.png",
        "dna": "8ef0e0a3ac554ffd942eb96b0076be8bf362df29",
        "edition": "221",
        "type": "Brown Bear",
        "date": 1677695309788,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 68,
        "maxHealth": 391,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "221",
        "rarity_score": 88.53794226347274,
        "rarity_level": "COMMON"
    },
    "1367": {
        "name": "Brawler Bears #1367",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1367.png",
        "dna": "c38143e9393c18ff440aaaa8ea20a5cf97e4cfe3",
        "edition": "1367",
        "type": "Panda",
        "date": 1677695311091,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 35,
        "maxHealth": 200,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1367",
        "rarity_score": 304.9811736150384,
        "rarity_level": "RARE"
    },
    "1171": {
        "name": "Brawler Bears #1171",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1171.png",
        "dna": "f1ff0c14dbe429de988af61b39642ed55e066ee9",
        "edition": "1171",
        "type": "Brown Bear",
        "date": 1677695311875,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1171",
        "rarity_score": 224.68174813305797,
        "rarity_level": "COMMON"
    },
    "2088": {
        "name": "Brawler Bears #2088",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2088.png",
        "dna": "b8086df683085f2bbd3ac21cafb1c86ac6d3aebb",
        "edition": "2088",
        "type": "Brown Bear",
        "date": 1677695313232,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "2088",
        "rarity_score": 190.20906980571354,
        "rarity_level": "COMMON"
    },
    "74": {
        "name": "Brawler Bears #74",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/74.png",
        "dna": "910af945a51df122c9963b227627072a7dbe9b4f",
        "edition": "74",
        "type": "Brown Bear",
        "date": 1677695314460,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "74",
        "rarity_score": 354.0851380736527,
        "rarity_level": "RARE"
    },
    "997": {
        "name": "Brawler Bears #997",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/997.png",
        "dna": "0f4608e3e623b92fa1964938183715dc93b1f1ec",
        "edition": "997",
        "type": "Galactic Cyborg",
        "date": 1677695315543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "997",
        "rarity_score": 345.80027949524936,
        "rarity_level": "RARE"
    },
    "535": {
        "name": "Brawler Bears #535",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/535.png",
        "dna": "3223b668bb8c0d4849ce791dc92b618c834bd318",
        "edition": "535",
        "type": "Brown Bear",
        "date": 1677695316793,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "535",
        "rarity_score": 179.47852987049444,
        "rarity_level": "COMMON"
    },
    "324": {
        "name": "Brawler Bears #324",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/324.png",
        "dna": "27a597f7af7d9d4b0f984473306233a94adc76a7",
        "edition": "324",
        "type": "Ice",
        "date": 1677695317843,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 36,
        "maxHealth": 211,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "324",
        "rarity_score": 210.57435080950236,
        "rarity_level": "COMMON"
    },
    "1014": {
        "name": "Brawler Bears #1014",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1014.png",
        "dna": "2e8278ff81fd112f3d206905fc3316c7ffe3fbe1",
        "edition": "1014",
        "type": "Brown Bear",
        "date": 1677695319518,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1014",
        "rarity_score": 190.2063050200566,
        "rarity_level": "COMMON"
    },
    "491": {
        "name": "Brawler Bears #491",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/491.png",
        "dna": "1ac557dd363bef5ae3022fa8f47490ee935681fa",
        "edition": "491",
        "type": "Black Bear",
        "date": 1677695320607,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "491",
        "rarity_score": 278.3152561836657,
        "rarity_level": "RARE"
    },
    "1346": {
        "name": "Brawler Bears #1346",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1346.png",
        "dna": "f2818ccfd1c0e9257aad1e6a734bea3f7b4938ae",
        "edition": "1346",
        "type": "Brown Bear",
        "date": 1677695321532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 22,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1346",
        "rarity_score": 241.19251804765358,
        "rarity_level": "COMMON"
    },
    "2249": {
        "name": "Brawler Bears #2249",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2249.png",
        "dna": "76367007b2c8ea51c2fd1fc888bff46a047cfbb0",
        "edition": "2249",
        "type": "Cheetah",
        "date": 1677695322440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 13,
        "maxHealth": 74,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "2249",
        "rarity_score": 289.6424708479482,
        "rarity_level": "RARE"
    },
    "2051": {
        "name": "Brawler Bears #2051",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2051.png",
        "dna": "781216cacd2fd3f11bc96cfb29a79d8e26cf7341",
        "edition": "2051",
        "type": "Black Bear",
        "date": 1677695323843,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 58,
        "maxHealth": 332,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2051",
        "rarity_score": 171.17181687923292,
        "rarity_level": "COMMON"
    },
    "354": {
        "name": "Brawler Bears #354",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/354.png",
        "dna": "eb2f3d059aacb9bf75fcba7138b2910871a9878a",
        "edition": "354",
        "type": "Brown Bear",
        "date": 1677695324547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 58,
        "maxHealth": 333,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "354",
        "rarity_score": 187.30967635021617,
        "rarity_level": "COMMON"
    },
    "239": {
        "name": "Brawler Bears #239",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/239.png",
        "dna": "8047c2dcc83e52d3a80223a1b7763f601303284f",
        "edition": "239",
        "type": "Deep Martian Cyborg",
        "date": 1677695325379,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "239",
        "rarity_score": 205.55517993342298,
        "rarity_level": "COMMON"
    },
    "1935": {
        "name": "Brawler Bears #1935",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1935.png",
        "dna": "61a2765fd6a25991b76577fe19d523a92a53fa24",
        "edition": "1935",
        "type": "Black Bear",
        "date": 1677695326118,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1935",
        "rarity_score": 233.69409417686853,
        "rarity_level": "COMMON"
    },
    "301": {
        "name": "Brawler Bears #301",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/301.png",
        "dna": "1cd7714051291318ad46e9a66f4ac363ed422aba",
        "edition": "301",
        "type": "Panda",
        "date": 1677695326931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "301",
        "rarity_score": 205.97006511538626,
        "rarity_level": "COMMON"
    },
    "807": {
        "name": "Brawler Bears #807",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/807.png",
        "dna": "47625bbaa0f498eb8d4e26eef0bc4549f4e7d03d",
        "edition": "807",
        "type": "Panda",
        "date": 1677695327786,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "807",
        "rarity_score": 261.2724680852256,
        "rarity_level": "COMMON"
    },
    "1643": {
        "name": "Brawler Bears #1643",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1643.png",
        "dna": "6477ed0ac57f1b90d9ac11b975146feaeaea1065",
        "edition": "1643",
        "type": "Lava",
        "date": 1677695328892,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1643",
        "rarity_score": 264.91415064049335,
        "rarity_level": "COMMON"
    },
    "1380": {
        "name": "Brawler Bears #1380",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1380.png",
        "dna": "fbbdcea11ae15f5e29cd9ba3cc2d9307adbc713e",
        "edition": "1380",
        "type": "Deep Martian Cyborg",
        "date": 1677695329620,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1380",
        "rarity_score": 245.78163962536297,
        "rarity_level": "COMMON"
    },
    "988": {
        "name": "Brawler Bears #988",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/988.png",
        "dna": "d598303c940fb013994e3697ad1f5841d599eaf6",
        "edition": "988",
        "type": "Lava",
        "date": 1677695330581,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 33,
        "maxHealth": 194,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "988",
        "rarity_score": 272.61697946454615,
        "rarity_level": "COMMON"
    },
    "1322": {
        "name": "Brawler Bears #1322",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1322.png",
        "dna": "347b5ea4b0677e6d4e67e5fd5af1941418661606",
        "edition": "1322",
        "type": "Brown Bear",
        "date": 1677695331651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 59,
        "maxHealth": 342,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1322",
        "rarity_score": 155.15260053944354,
        "rarity_level": "COMMON"
    },
    "1516": {
        "name": "Brawler Bears #1516",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1516.png",
        "dna": "76740a98c437f1a56f8afdea30875bd615e47e90",
        "edition": "1516",
        "type": "Brown Bear",
        "date": 1677695332546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1516",
        "rarity_score": 259.88305432388626,
        "rarity_level": "COMMON"
    },
    "25": {
        "name": "Brawler Bears #25",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/25.png",
        "dna": "2feb921e56e674a83d0da5f7f51ada96ac7d22f3",
        "edition": "25",
        "type": "Acid Trip",
        "date": 1677695333455,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "25",
        "rarity_score": 185.41173025413624,
        "rarity_level": "COMMON"
    },
    "1295": {
        "name": "Brawler Bears #1295",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1295.png",
        "dna": "5d75e34d18abcacff31d463d80571b27991db83a",
        "edition": "1295",
        "type": "Galactic Cyborg",
        "date": 1677695334195,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1295",
        "rarity_score": 273.0984340957474,
        "rarity_level": "COMMON"
    },
    "1860": {
        "name": "Brawler Bears #1860",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1860.png",
        "dna": "5696387df6a4cccc4b3b87be55d23710e5cb6eaf",
        "edition": "1860",
        "type": "Brown Bear",
        "date": 1677695335428,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1860",
        "rarity_score": 243.40570991763786,
        "rarity_level": "COMMON"
    },
    "744": {
        "name": "Brawler Bears #744",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/744.png",
        "dna": "e24584f46b76559804a8dee750bec6816ddc2f14",
        "edition": "744",
        "type": "Lava",
        "date": 1677695336319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "744",
        "rarity_score": 234.2746535828776,
        "rarity_level": "COMMON"
    },
    "1557": {
        "name": "Brawler Bears #1557",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1557.png",
        "dna": "07dc43ad642f90108b793023768515545a87ac7f",
        "edition": "1557",
        "type": "Ice",
        "date": 1677695337234,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1557",
        "rarity_score": 313.92492884754256,
        "rarity_level": "RARE"
    },
    "1625": {
        "name": "Brawler Bears #1625",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1625.png",
        "dna": "0d4774437d6e85a6963f1a8341abfe71023f0fed",
        "edition": "1625",
        "type": "Galaxy",
        "date": 1677695338117,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 27,
        "maxHealth": 154,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1625",
        "rarity_score": 175.43852322867198,
        "rarity_level": "COMMON"
    },
    "283": {
        "name": "Brawler Bears #283",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/283.png",
        "dna": "5b36a779bc4d6902f4b8078520cc0abdacf004cf",
        "edition": "283",
        "type": "Galactic Cyborg",
        "date": 1677695339246,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "283",
        "rarity_score": 243.73158311656417,
        "rarity_level": "COMMON"
    },
    "56": {
        "name": "Brawler Bears #56",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/56.png",
        "dna": "5ced9f7d2a3e81a13f76232faf83da30eb6774b5",
        "edition": "56",
        "type": "Deep Martian Cyborg",
        "date": 1677695340430,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "56",
        "rarity_score": 214.1768724830207,
        "rarity_level": "COMMON"
    },
    "1932": {
        "name": "Brawler Bears #1932",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1932.png",
        "dna": "55c814a079a8fd946c48ee6f85f50e1efd3b7278",
        "edition": "1932",
        "type": "Black Bear",
        "date": 1677695341535,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1932",
        "rarity_score": 245.06041949573023,
        "rarity_level": "COMMON"
    },
    "1507": {
        "name": "Brawler Bears #1507",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1507.png",
        "dna": "fda89305ec62343296f21e76e511114fb84ed7ce",
        "edition": "1507",
        "type": "Lava",
        "date": 1677695342501,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1507",
        "rarity_score": 278.2584600886654,
        "rarity_level": "RARE"
    },
    "1364": {
        "name": "Brawler Bears #1364",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1364.png",
        "dna": "6252530e219bf24f1099e788459dcb01a9da9a6f",
        "edition": "1364",
        "type": "Acid Trip",
        "date": 1677695343312,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 29,
        "maxHealth": 170,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1364",
        "rarity_score": 230.85100447133405,
        "rarity_level": "COMMON"
    },
    "2271": {
        "name": "Brawler Bears #2271",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2271.png",
        "dna": "880685b8e9564d947e9d641498eac78aec04a4e0",
        "edition": "2271",
        "type": "Brown Bear",
        "date": 1677695344613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "2271",
        "rarity_score": 292.60331138802394,
        "rarity_level": "RARE"
    },
    "996": {
        "name": "Brawler Bears #996",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/996.png",
        "dna": "9208dc7bb5f5aa063622a431a19d73e52ff969e5",
        "edition": "996",
        "type": "Black Bear",
        "date": 1677695346395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 41,
        "maxHealth": 238,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "996",
        "rarity_score": 241.03734117020252,
        "rarity_level": "COMMON"
    },
    "1203": {
        "name": "Brawler Bears #1203",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1203.png",
        "dna": "7d866fcf7cfc1bf7dfda9f737a8169f3c759eead",
        "edition": "1203",
        "type": "Black Bear",
        "date": 1677695347616,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1203",
        "rarity_score": 281.22730857034423,
        "rarity_level": "RARE"
    },
    "228": {
        "name": "Brawler Bears #228",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/228.png",
        "dna": "30f3beaa9c36c692016e00dc1317d83d60091bee",
        "edition": "228",
        "type": "Ice",
        "date": 1677695348541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "228",
        "rarity_score": 171.1319773426201,
        "rarity_level": "COMMON"
    },
    "2212": {
        "name": "Brawler Bears #2212",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2212.png",
        "dna": "ae80e19c7ce9bdf930cf10d69801a49dd670fd5a",
        "edition": "2212",
        "type": "Deep Martian Cyborg",
        "date": 1677695349589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2212",
        "rarity_score": 190.42053541605992,
        "rarity_level": "COMMON"
    },
    "1316": {
        "name": "Brawler Bears #1316",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1316.png",
        "dna": "a0d3301dba6103d6b6d42acfe63db5f7bb0aa635",
        "edition": "1316",
        "type": "Galactic Cyborg",
        "date": 1677695350342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1316",
        "rarity_score": 234.93476675132675,
        "rarity_level": "COMMON"
    },
    "1802": {
        "name": "Brawler Bears #1802",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1802.png",
        "dna": "548a3532904b2ccd29ea84fbaedf561a1e1c6f8a",
        "edition": "1802",
        "type": "Acid Trip",
        "date": 1677695351870,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 29,
        "maxHealth": 170,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1802",
        "rarity_score": 254.90094845910252,
        "rarity_level": "COMMON"
    },
    "1403": {
        "name": "Brawler Bears #1403",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1403.png",
        "dna": "2a2999c886620e4a894865e7fb286373b8d037a1",
        "edition": "1403",
        "type": "Brown Bear",
        "date": 1677695353207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1403",
        "rarity_score": 270.4432877578455,
        "rarity_level": "COMMON"
    },
    "1839": {
        "name": "Brawler Bears #1839",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1839.png",
        "dna": "31c3e3f8530090bae97777736cbd8b4b08048b0e",
        "edition": "1839",
        "type": "Brown Bear",
        "date": 1677695354087,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 41,
        "maxHealth": 239,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1839",
        "rarity_score": 222.58092897120312,
        "rarity_level": "COMMON"
    },
    "965": {
        "name": "Brawler Bears #965",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/965.png",
        "dna": "6e4b1400ad29eda349022f864ae05bbd458af384",
        "edition": "965",
        "type": "Galaxy",
        "date": 1677695355358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 26,
        "maxHealth": 149,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "965",
        "rarity_score": 187.98274695979515,
        "rarity_level": "COMMON"
    },
    "1504": {
        "name": "Brawler Bears #1504",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1504.png",
        "dna": "84bd0d4fab282c676ce58776c3616e94879a3a94",
        "edition": "1504",
        "type": "Deep Martian Cyborg",
        "date": 1677695356269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 31,
        "maxHealth": 178,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1504",
        "rarity_score": 209.893137423834,
        "rarity_level": "COMMON"
    },
    "1614": {
        "name": "Brawler Bears #1614",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1614.png",
        "dna": "72dba8facc7872252d55ec4d4850b1dcdc011e82",
        "edition": "1614",
        "type": "Brown Bear",
        "date": 1677695357302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1614",
        "rarity_score": 229.96784031729322,
        "rarity_level": "COMMON"
    },
    "1681": {
        "name": "Brawler Bears #1681",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1681.png",
        "dna": "e34c12293ea934e04afd648144fa97c3b738a420",
        "edition": "1681",
        "type": "Brown Bear",
        "date": 1677695358160,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1681",
        "rarity_score": 214.8251463893998,
        "rarity_level": "COMMON"
    },
    "2294": {
        "name": "Brawler Bears #2294",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2294.png",
        "dna": "a15fce2d0c59630ead272d04c57261be20874e85",
        "edition": "2294",
        "type": "Ice",
        "date": 1677695359204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "2294",
        "rarity_score": 223.71412729472195,
        "rarity_level": "COMMON"
    },
    "39": {
        "name": "Brawler Bears #39",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/39.png",
        "dna": "d4b273b3f587a7f0b05f32feddb830c5cd556765",
        "edition": "39",
        "type": "Black Bear",
        "date": 1677695360031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 20,
        "maxHealth": 114,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "39",
        "rarity_score": 263.34423535426885,
        "rarity_level": "COMMON"
    },
    "1340": {
        "name": "Brawler Bears #1340",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1340.png",
        "dna": "cc41c3d89469bf23b96900069d6984960da3ce2b",
        "edition": "1340",
        "type": "Brown Bear",
        "date": 1677695360813,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 58,
        "maxHealth": 336,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1340",
        "rarity_score": 190.76034373088663,
        "rarity_level": "COMMON"
    },
    "1556": {
        "name": "Brawler Bears #1556",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1556.png",
        "dna": "c9bc3f08a04a1ac4de87c112a5d14a9bafe5bee9",
        "edition": "1556",
        "type": "Brown Bear",
        "date": 1677695361539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1556",
        "rarity_score": 287.4150759801454,
        "rarity_level": "RARE"
    },
    "1439": {
        "name": "Brawler Bears #1439",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1439.png",
        "dna": "ecbd4f2d0f8b556e8544d27678c7c6aec37b0662",
        "edition": "1439",
        "type": "Deep Martian Cyborg",
        "date": 1677695363188,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1439",
        "rarity_score": 193.66191051953845,
        "rarity_level": "COMMON"
    },
    "1760": {
        "name": "Brawler Bears #1760",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1760.png",
        "dna": "40550b8b44131c334fec157d0a0777cb35202677",
        "edition": "1760",
        "type": "Lava",
        "date": 1677695364193,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 51,
        "maxHealth": 292,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1760",
        "rarity_score": 169.6749202571731,
        "rarity_level": "COMMON"
    },
    "252": {
        "name": "Brawler Bears #252",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/252.png",
        "dna": "a41fba4530a51caecb603ee730fb129a06bf52d3",
        "edition": "252",
        "type": "Galaxy",
        "date": 1677695365142,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "252",
        "rarity_score": 297.81284307033474,
        "rarity_level": "RARE"
    },
    "22": {
        "name": "Brawler Bears #22",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/22.png",
        "dna": "4e0af687e3615b9e95237434a3826a2bbf7c7982",
        "edition": "22",
        "type": "Brown Bear",
        "date": 1677695366401,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "22",
        "rarity_score": 211.72906300859353,
        "rarity_level": "COMMON"
    },
    "1704": {
        "name": "Brawler Bears #1704",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1704.png",
        "dna": "ac3d09fd7187aa765d8def40f8d70a3851e7c869",
        "edition": "1704",
        "type": "Black Bear",
        "date": 1677695367520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 47,
        "maxHealth": 271,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1704",
        "rarity_score": 133.06371551107168,
        "rarity_level": "COMMON"
    },
    "650": {
        "name": "Brawler Bears #650",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/650.png",
        "dna": "4ae63ea7c953f69da6ada2a3e0a462ab79dfa222",
        "edition": "650",
        "type": "Black Bear",
        "date": 1677695368319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 30,
        "maxHealth": 174,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "650",
        "rarity_score": 174.3066488585985,
        "rarity_level": "COMMON"
    },
    "735": {
        "name": "Brawler Bears #735",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/735.png",
        "dna": "89d77cd93b7290aec6b4923f0dd851327535ac8d",
        "edition": "735",
        "type": "Galactic Cyborg",
        "date": 1677695369904,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "735",
        "rarity_score": 280.0920256171129,
        "rarity_level": "RARE"
    },
    "1820": {
        "name": "Brawler Bears #1820",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1820.png",
        "dna": "47b6347098efefe1ab68a5559ffa2d6681cffbed",
        "edition": "1820",
        "type": "Black Bear",
        "date": 1677695370896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1820",
        "rarity_score": 314.53858518741276,
        "rarity_level": "RARE"
    },
    "93": {
        "name": "Brawler Bears #93",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/93.png",
        "dna": "e617e9994b023afdffb5eef1fc31e6bcee81e95f",
        "edition": "93",
        "type": "Panda",
        "date": 1677695371842,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "93",
        "rarity_score": 202.01697668395104,
        "rarity_level": "COMMON"
    },
    "1379": {
        "name": "Brawler Bears #1379",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1379.png",
        "dna": "e0a9fcfae487f441332cef57a4daa4d50186d5b7",
        "edition": "1379",
        "type": "Galactic Cyborg",
        "date": 1677695373311,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1379",
        "rarity_score": 279.8878779521179,
        "rarity_level": "RARE"
    },
    "1501": {
        "name": "Brawler Bears #1501",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1501.png",
        "dna": "fc5b9cc98c71dcea126dcbe298fdfdf05fb64a3f",
        "edition": "1501",
        "type": "Deep Martian Cyborg",
        "date": 1677695374688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1501",
        "rarity_score": 229.57602138036125,
        "rarity_level": "COMMON"
    },
    "1774": {
        "name": "Brawler Bears #1774",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1774.png",
        "dna": "be9d28e86e2a0e0f1be623f869116f741bc216a7",
        "edition": "1774",
        "type": "Galaxy",
        "date": 1677695375584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1774",
        "rarity_score": 227.12100028163246,
        "rarity_level": "COMMON"
    },
    "1175": {
        "name": "Brawler Bears #1175",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1175.png",
        "dna": "af759b5cf145a0ed4f57822eddeca3fbb4045806",
        "edition": "1175",
        "type": "Ice",
        "date": 1677695376884,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 24,
        "maxHealth": 140,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1175",
        "rarity_score": 199.8300044720735,
        "rarity_level": "COMMON"
    },
    "2103": {
        "name": "Brawler Bears #2103",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2103.png",
        "dna": "0afa2abc8fc1ed200118ab0b0a3519665acc421f",
        "edition": "2103",
        "type": "Brown Bear",
        "date": 1677695377658,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2103",
        "rarity_score": 231.97717409838995,
        "rarity_level": "COMMON"
    },
    "355": {
        "name": "Brawler Bears #355",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/355.png",
        "dna": "b3acd87d821254e3aa25ad9b45c71933c3583ca2",
        "edition": "355",
        "type": "Deep Martian Cyborg",
        "date": 1677695379520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "355",
        "rarity_score": 267.50261463651003,
        "rarity_level": "COMMON"
    },
    "1162": {
        "name": "Brawler Bears #1162",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1162.png",
        "dna": "6d1060ce8e78a38d8180f199438777eb035f34db",
        "edition": "1162",
        "type": "Deep Martian Cyborg",
        "date": 1677695380641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1162",
        "rarity_score": 269.2101534211449,
        "rarity_level": "COMMON"
    },
    "975": {
        "name": "Brawler Bears #975",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/975.png",
        "dna": "ad230c2d519a49c277772ab2dd8fd1a32025536d",
        "edition": "975",
        "type": "Brown Bear",
        "date": 1677695382321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "975",
        "rarity_score": 266.72792059944027,
        "rarity_level": "COMMON"
    },
    "668": {
        "name": "Brawler Bears #668",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/668.png",
        "dna": "fb41d81215b6ed6e9c3e44a50dd0342881079811",
        "edition": "668",
        "type": "Cheetah",
        "date": 1677695383992,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "668",
        "rarity_score": 223.59976312863932,
        "rarity_level": "COMMON"
    },
    "387": {
        "name": "Brawler Bears #387",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/387.png",
        "dna": "71b7ed5cbb1b1bbcd92f6747f7a251b9d7616daf",
        "edition": "387",
        "type": "Acid Trip",
        "date": 1677695385063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "387",
        "rarity_score": 241.89498124206628,
        "rarity_level": "COMMON"
    },
    "2211": {
        "name": "Brawler Bears #2211",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2211.png",
        "dna": "f2786b96699ac1964af45b85e8b376735d2de5e7",
        "edition": "2211",
        "type": "Galactic Cyborg",
        "date": 1677695386051,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "2211",
        "rarity_score": 299.8829950183375,
        "rarity_level": "RARE"
    },
    "683": {
        "name": "Brawler Bears #683",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/683.png",
        "dna": "7b2cb22dbed43c0d0d7728c3a9228c3fbac9750c",
        "edition": "683",
        "type": "Brown Bear",
        "date": 1677695387121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 41,
        "maxHealth": 239,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "683",
        "rarity_score": 260.84437748917827,
        "rarity_level": "COMMON"
    },
    "1443": {
        "name": "Brawler Bears #1443",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1443.png",
        "dna": "a27a2af3c21c65b521beadf827e5a5ec88350b90",
        "edition": "1443",
        "type": "Galaxy",
        "date": 1677695387905,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1443",
        "rarity_score": 243.85067599616974,
        "rarity_level": "COMMON"
    },
    "2175": {
        "name": "Brawler Bears #2175",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2175.png",
        "dna": "1a66058b3ed4ab4c6a6ae6ac29c40d5d1a087796",
        "edition": "2175",
        "type": "Brown Bear",
        "date": 1677695389374,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "2175",
        "rarity_score": 256.74285272092294,
        "rarity_level": "COMMON"
    },
    "1811": {
        "name": "Brawler Bears #1811",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1811.png",
        "dna": "c010633de0ac1c5db0ac9434fd641e836e3a73e3",
        "edition": "1811",
        "type": "Deep Martian Cyborg",
        "date": 1677695390221,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 25,
        "maxHealth": 147,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1811",
        "rarity_score": 183.6675659406418,
        "rarity_level": "COMMON"
    },
    "115": {
        "name": "Brawler Bears #115",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/115.png",
        "dna": "0275f4a4a268b9fab429be6cb53bec43539adfc8",
        "edition": "115",
        "type": "Galactic Cyborg",
        "date": 1677695391455,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "115",
        "rarity_score": 231.06054618961338,
        "rarity_level": "COMMON"
    },
    "405": {
        "name": "Brawler Bears #405",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/405.png",
        "dna": "63d48949e8d5a41ea0822b13cbbe13d3d22bb9c0",
        "edition": "405",
        "type": "Deep Martian Cyborg",
        "date": 1677695392580,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "405",
        "rarity_score": 273.41138002828427,
        "rarity_level": "COMMON"
    },
    "1705": {
        "name": "Brawler Bears #1705",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1705.png",
        "dna": "d82f625595577212d4ed88f79219aab6061c9900",
        "edition": "1705",
        "type": "Panda",
        "date": 1677695393521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 13,
        "maxHealth": 76,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1705",
        "rarity_score": 295.02760068550856,
        "rarity_level": "RARE"
    },
    "829": {
        "name": "Brawler Bears #829",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/829.png",
        "dna": "d480218e11e9d15195d5c0a705e7748d99bf1248",
        "edition": "829",
        "type": "Acid Trip",
        "date": 1677695394357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 31,
        "maxHealth": 178,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "829",
        "rarity_score": 196.56203818306437,
        "rarity_level": "COMMON"
    },
    "435": {
        "name": "Brawler Bears #435",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/435.png",
        "dna": "686989c7a5a03a843de8c3afdaac5e4fcf0a6ab9",
        "edition": "435",
        "type": "Brown Bear",
        "date": 1677695395289,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "435",
        "rarity_score": 263.8325277295092,
        "rarity_level": "COMMON"
    },
    "1751": {
        "name": "Brawler Bears #1751",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1751.png",
        "dna": "9763b855af5465a981ef218eff03c6a6af9f2494",
        "edition": "1751",
        "type": "Brown Bear",
        "date": 1677695396224,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1751",
        "rarity_score": 244.35855754766632,
        "rarity_level": "COMMON"
    },
    "918": {
        "name": "Brawler Bears #918",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/918.png",
        "dna": "153db95f30176b01ada0e3c302543fd546252e04",
        "edition": "918",
        "type": "Brown Bear",
        "date": 1677695397021,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "918",
        "rarity_score": 216.6479374684124,
        "rarity_level": "COMMON"
    },
    "98": {
        "name": "Brawler Bears #98",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/98.png",
        "dna": "d3ce7f7b2fc8c07a68c23dc85a800249b1cb8670",
        "edition": "98",
        "type": "Ice",
        "date": 1677695397759,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "98",
        "rarity_score": 261.91406871062446,
        "rarity_level": "COMMON"
    },
    "1874": {
        "name": "Brawler Bears #1874",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1874.png",
        "dna": "14214a9f4860587a6717cdbde0336e40514def46",
        "edition": "1874",
        "type": "Brown Bear",
        "date": 1677695399366,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1874",
        "rarity_score": 226.41540477405502,
        "rarity_level": "COMMON"
    },
    "122": {
        "name": "Brawler Bears #122",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/122.png",
        "dna": "9b8e48ef9625fda003991d5037b8b7e3ed46b394",
        "edition": "122",
        "type": "Ice",
        "date": 1677695400224,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 31,
        "maxHealth": 181,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "122",
        "rarity_score": 205.16935640708598,
        "rarity_level": "COMMON"
    },
    "1006": {
        "name": "Brawler Bears #1006",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1006.png",
        "dna": "317bf825fb5549fb54ca022da65d5e0b3f70ae19",
        "edition": "1006",
        "type": "Ice",
        "date": 1677695401078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 52,
        "maxHealth": 298,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1006",
        "rarity_score": 172.5765365990578,
        "rarity_level": "COMMON"
    },
    "1229": {
        "name": "Brawler Bears #1229",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1229.png",
        "dna": "2ea425da4625baba6228e97c664b2b4ff09097f3",
        "edition": "1229",
        "type": "Black Bear",
        "date": 1677695401699,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1229",
        "rarity_score": 280.13170447553125,
        "rarity_level": "RARE"
    },
    "247": {
        "name": "Brawler Bears #247",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/247.png",
        "dna": "3d83e7fa75c6f2890ff38e3dfe89270f90579320",
        "edition": "247",
        "type": "Black Bear",
        "date": 1677695403267,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "247",
        "rarity_score": 338.11953763899214,
        "rarity_level": "RARE"
    },
    "1737": {
        "name": "Brawler Bears #1737",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1737.png",
        "dna": "9b75f6f0193f21ba3dba3614608cbc2955d6842a",
        "edition": "1737",
        "type": "Deep Martian Cyborg",
        "date": 1677695404236,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1737",
        "rarity_score": 268.603628124414,
        "rarity_level": "COMMON"
    },
    "1892": {
        "name": "Brawler Bears #1892",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1892.png",
        "dna": "e56e84c18d0e9eda62b38f9d6d6482f6603f63bc",
        "edition": "1892",
        "type": "Galaxy",
        "date": 1677695405420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1892",
        "rarity_score": 132.48927529151067,
        "rarity_level": "COMMON"
    },
    "2105": {
        "name": "Brawler Bears #2105",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2105.png",
        "dna": "db0ca83a6b8f480e49df3c31e20437d3451d603d",
        "edition": "2105",
        "type": "Acid Trip",
        "date": 1677695406490,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2105",
        "rarity_score": 272.7658160062672,
        "rarity_level": "COMMON"
    },
    "894": {
        "name": "Brawler Bears #894",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/894.png",
        "dna": "6d04bea232474f729efd038eef3ff16690fece29",
        "edition": "894",
        "type": "Lava",
        "date": 1677695407585,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 12,
        "maxHealth": 70,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "894",
        "rarity_score": 271.6813781484834,
        "rarity_level": "COMMON"
    },
    "2054": {
        "name": "Brawler Bears #2054",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2054.png",
        "dna": "c96b2691263219bc1acf363fdfa85d675c1318c9",
        "edition": "2054",
        "type": "Ice",
        "date": 1677695408746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 51,
        "maxHealth": 296,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2054",
        "rarity_score": 195.04730728598676,
        "rarity_level": "COMMON"
    },
    "825": {
        "name": "Brawler Bears #825",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/825.png",
        "dna": "eaa33f900a2b5295d083059342f1e1094df849f0",
        "edition": "825",
        "type": "Galactic Cyborg",
        "date": 1677695409792,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "825",
        "rarity_score": 226.35522289256085,
        "rarity_level": "COMMON"
    },
    "312": {
        "name": "Brawler Bears #312",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/312.png",
        "dna": "169621fefefc9c7fa14a55350de45d735fd838e7",
        "edition": "312",
        "type": "Acid Trip",
        "date": 1677695410491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 30,
        "maxHealth": 174,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "312",
        "rarity_score": 228.38246101089248,
        "rarity_level": "COMMON"
    },
    "1656": {
        "name": "Brawler Bears #1656",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1656.png",
        "dna": "3733b219d3bc06d1edd8eb6069b1656b06b8abcc",
        "edition": "1656",
        "type": "Galactic Cyborg",
        "date": 1677695411372,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1656",
        "rarity_score": 233.0651754664435,
        "rarity_level": "COMMON"
    },
    "1595": {
        "name": "Brawler Bears #1595",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1595.png",
        "dna": "1812d362979b89809022ba29db6b66b338c83264",
        "edition": "1595",
        "type": "Galactic Cyborg",
        "date": 1677695412309,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1595",
        "rarity_score": 124.52894079981654,
        "rarity_level": "COMMON"
    },
    "2195": {
        "name": "Brawler Bears #2195",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2195.png",
        "dna": "56483e5d758d8cc9d0c4fe4fbc9e25a817667e9c",
        "edition": "2195",
        "type": "Panda",
        "date": 1677695413216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "2195",
        "rarity_score": 207.84809887555028,
        "rarity_level": "COMMON"
    },
    "560": {
        "name": "Brawler Bears #560",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/560.png",
        "dna": "d95c2082a2ff149768edf21107140bb72530cf4b",
        "edition": "560",
        "type": "Ice",
        "date": 1677695414171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "560",
        "rarity_score": 207.88771196382797,
        "rarity_level": "COMMON"
    },
    "907": {
        "name": "Brawler Bears #907",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/907.png",
        "dna": "451a46993e67e42091a57b32fb59e443615325da",
        "edition": "907",
        "type": "Brown Bear",
        "date": 1677695415393,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "907",
        "rarity_score": 221.38245051464398,
        "rarity_level": "COMMON"
    },
    "412": {
        "name": "Brawler Bears #412",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/412.png",
        "dna": "5ebe2c1606006e926a57217249d2a1f4feb85520",
        "edition": "412",
        "type": "Deep Martian Cyborg",
        "date": 1677695416161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "412",
        "rarity_score": 231.2789055225956,
        "rarity_level": "COMMON"
    },
    "917": {
        "name": "Brawler Bears #917",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/917.png",
        "dna": "2ac08a2d4fc769d36a9660181df0fc279c1b569c",
        "edition": "917",
        "type": "Galaxy",
        "date": 1677695417259,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 26,
        "maxHealth": 153,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "917",
        "rarity_score": 174.80848674663116,
        "rarity_level": "COMMON"
    },
    "533": {
        "name": "Brawler Bears #533",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/533.png",
        "dna": "dc5b56a0855cf8b6adca32fae1eca8df9bf665ec",
        "edition": "533",
        "type": "Brown Bear",
        "date": 1677695418688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "533",
        "rarity_score": 257.91342512763583,
        "rarity_level": "COMMON"
    },
    "142": {
        "name": "Brawler Bears #142",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/142.png",
        "dna": "b38432a4e1315558858071cf112821d589d6744a",
        "edition": "142",
        "type": "Black Bear",
        "date": 1677695419364,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "142",
        "rarity_score": 231.55548927266418,
        "rarity_level": "COMMON"
    },
    "19": {
        "name": "Brawler Bears #19",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/19.png",
        "dna": "e9b9190f5eda9b8cba15b188ae16cec5d3b8c8a8",
        "edition": "19",
        "type": "Brown Bear",
        "date": 1677695420637,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "19",
        "rarity_score": 258.6384400410289,
        "rarity_level": "COMMON"
    },
    "1010": {
        "name": "Brawler Bears #1010",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1010.png",
        "dna": "03614b94145d1c90b116adbb4065bbd2692c5a0b",
        "edition": "1010",
        "type": "Galaxy",
        "date": 1677695421946,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1010",
        "rarity_score": 220.04267349345758,
        "rarity_level": "COMMON"
    },
    "1228": {
        "name": "Brawler Bears #1228",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1228.png",
        "dna": "dae1424b6776648bb5f043ac97557593bbab9a4c",
        "edition": "1228",
        "type": "Galaxy",
        "date": 1677695422957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1228",
        "rarity_score": 272.6193882235803,
        "rarity_level": "COMMON"
    },
    "1139": {
        "name": "Brawler Bears #1139",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1139.png",
        "dna": "9fe43661b3d7240b92a1a8779ae11c8c3b7dcede",
        "edition": "1139",
        "type": "Brown Bear",
        "date": 1677695423663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 59,
        "maxHealth": 341,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1139",
        "rarity_score": 157.94106487053656,
        "rarity_level": "COMMON"
    },
    "348": {
        "name": "Brawler Bears #348",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/348.png",
        "dna": "6c64a8dbe444e5fb46d8b5188559d862a6fd8d79",
        "edition": "348",
        "type": "Black Bear",
        "date": 1677695424839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "348",
        "rarity_score": 269.450740556783,
        "rarity_level": "COMMON"
    },
    "1076": {
        "name": "Brawler Bears #1076",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1076.png",
        "dna": "30425d0252eb68584257bc73e58b5b533c4aa41e",
        "edition": "1076",
        "type": "Black Bear",
        "date": 1677695425601,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 58,
        "maxHealth": 332,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1076",
        "rarity_score": 185.35615086696154,
        "rarity_level": "COMMON"
    },
    "2154": {
        "name": "Brawler Bears #2154",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2154.png",
        "dna": "a71470dc57479718cdedbf16baa81a0ae00aadea",
        "edition": "2154",
        "type": "Brown Bear",
        "date": 1677695426757,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "2154",
        "rarity_score": 266.7194432378203,
        "rarity_level": "COMMON"
    },
    "702": {
        "name": "Brawler Bears #702",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/702.png",
        "dna": "b47961b9cfe2ad499495704b5c2ef92fe5d881a5",
        "edition": "702",
        "type": "Black Bear",
        "date": 1677695427425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "702",
        "rarity_score": 249.8145753039286,
        "rarity_level": "COMMON"
    },
    "770": {
        "name": "Brawler Bears #770",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/770.png",
        "dna": "8ee4277d4f3bfcaa14c6c2202485983de2638730",
        "edition": "770",
        "type": "Galactic Cyborg",
        "date": 1677695428542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "770",
        "rarity_score": 219.60007651750382,
        "rarity_level": "COMMON"
    },
    "1418": {
        "name": "Brawler Bears #1418",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1418.png",
        "dna": "eda9fdb84093f4bc45405d96c87becb9ca1c8592",
        "edition": "1418",
        "type": "Galaxy",
        "date": 1677695429876,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1418",
        "rarity_score": 259.7259751039861,
        "rarity_level": "COMMON"
    },
    "1386": {
        "name": "Brawler Bears #1386",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1386.png",
        "dna": "f6e1ab99704dc6e5abf6af535b30c84681f46c38",
        "edition": "1386",
        "type": "Black Bear",
        "date": 1677695431015,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1386",
        "rarity_score": 259.9375495508128,
        "rarity_level": "COMMON"
    },
    "286": {
        "name": "Brawler Bears #286",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/286.png",
        "dna": "22ab1c3a1e2b72102027377a41af7426a1b65115",
        "edition": "286",
        "type": "Black Bear",
        "date": 1677695431920,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "286",
        "rarity_score": 269.4015467706171,
        "rarity_level": "COMMON"
    },
    "1143": {
        "name": "Brawler Bears #1143",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1143.png",
        "dna": "e9933478c1c7aa7778f52b9b1996e5803400d84a",
        "edition": "1143",
        "type": "Deep Martian Cyborg",
        "date": 1677695433121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1143",
        "rarity_score": 250.48026400146819,
        "rarity_level": "COMMON"
    },
    "1904": {
        "name": "Brawler Bears #1904",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1904.png",
        "dna": "980d810c09ac6aa83a12035ed18efef66961ffda",
        "edition": "1904",
        "type": "Deep Martian Cyborg",
        "date": 1677695434178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1904",
        "rarity_score": 244.41272422300557,
        "rarity_level": "COMMON"
    },
    "426": {
        "name": "Brawler Bears #426",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/426.png",
        "dna": "0f968620330f15d26f23f053f94b37f53f3fff7b",
        "edition": "426",
        "type": "Deep Martian Cyborg",
        "date": 1677695435085,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 25,
        "maxHealth": 146,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "426",
        "rarity_score": 185.34644463367673,
        "rarity_level": "COMMON"
    },
    "1809": {
        "name": "Brawler Bears #1809",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1809.png",
        "dna": "d6f716682a4caeafd0a5a6fe5e1b5410d885e6f0",
        "edition": "1809",
        "type": "Black Bear",
        "date": 1677695436316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 20,
        "maxHealth": 118,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1809",
        "rarity_score": 252.5017203737289,
        "rarity_level": "COMMON"
    },
    "1394": {
        "name": "Brawler Bears #1394",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1394.png",
        "dna": "1cb8c1d869bb647407ea560c385a7a59a6f3241a",
        "edition": "1394",
        "type": "Deep Martian Cyborg",
        "date": 1677695437920,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 31,
        "maxHealth": 178,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1394",
        "rarity_score": 211.2749800941923,
        "rarity_level": "COMMON"
    },
    "104": {
        "name": "Brawler Bears #104",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/104.png",
        "dna": "89a278c61ed92438d68535d7a7593aeaf8106798",
        "edition": "104",
        "type": "Deep Martian Cyborg",
        "date": 1677695438744,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 46,
        "maxHealth": 264,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "104",
        "rarity_score": 157.0857193767689,
        "rarity_level": "COMMON"
    },
    "772": {
        "name": "Brawler Bears #772",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/772.png",
        "dna": "89820592e7758bb634c1f15a32ab0a6c2fd829fd",
        "edition": "772",
        "type": "Black Bear",
        "date": 1677695439563,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "772",
        "rarity_score": 262.84723576429116,
        "rarity_level": "COMMON"
    },
    "913": {
        "name": "Brawler Bears #913",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/913.png",
        "dna": "e00a30736506b54d9fb9eb5031822e3abf76d368",
        "edition": "913",
        "type": "Black Bear",
        "date": 1677695440900,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 41,
        "maxHealth": 239,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "913",
        "rarity_score": 221.47670450935746,
        "rarity_level": "COMMON"
    },
    "695": {
        "name": "Brawler Bears #695",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/695.png",
        "dna": "ee0149a5842da08a2eac1326f04bce4a9f655c4e",
        "edition": "695",
        "type": "Acid Trip",
        "date": 1677695441818,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 35,
        "maxHealth": 200,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "695",
        "rarity_score": 242.86672619708054,
        "rarity_level": "COMMON"
    },
    "71": {
        "name": "Brawler Bears #71",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/71.png",
        "dna": "59f746d2c861903e15921f7436844e507db80a6e",
        "edition": "71",
        "type": "Acid Trip",
        "date": 1677695442623,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "71",
        "rarity_score": 244.00555679918006,
        "rarity_level": "COMMON"
    },
    "1020": {
        "name": "Brawler Bears #1020",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1020.png",
        "dna": "aba27600ebe5439a88ddb4e85426f7b38c3025c0",
        "edition": "1020",
        "type": "Black Bear",
        "date": 1677695444334,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 41,
        "maxHealth": 238,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1020",
        "rarity_score": 222.41803668705364,
        "rarity_level": "COMMON"
    },
    "1461": {
        "name": "Brawler Bears #1461",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1461.png",
        "dna": "b58ad67b1ad50278f395987135fdf1e72ebcc156",
        "edition": "1461",
        "type": "Galactic Cyborg",
        "date": 1677695445844,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 25,
        "maxHealth": 147,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1461",
        "rarity_score": 200.8945373262649,
        "rarity_level": "COMMON"
    },
    "896": {
        "name": "Brawler Bears #896",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/896.png",
        "dna": "78adf5767de30003e11de8b15a2b69c877ffb7e8",
        "edition": "896",
        "type": "Lava",
        "date": 1677695446721,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 50,
        "maxHealth": 287,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "896",
        "rarity_score": 181.95806289637983,
        "rarity_level": "COMMON"
    },
    "2012": {
        "name": "Brawler Bears #2012",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2012.png",
        "dna": "015d242b058c262f47b389debeecb1fc246c6bb2",
        "edition": "2012",
        "type": "Deep Martian Cyborg",
        "date": 1677695447554,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "2012",
        "rarity_score": 251.3383342850829,
        "rarity_level": "COMMON"
    },
    "1408": {
        "name": "Brawler Bears #1408",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1408.png",
        "dna": "53fadc4dd64f7b87def2a45a2b591b9fd3a27876",
        "edition": "1408",
        "type": "Brown Bear",
        "date": 1677695448859,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1408",
        "rarity_score": 197.3703886138948,
        "rarity_level": "COMMON"
    },
    "285": {
        "name": "Brawler Bears #285",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/285.png",
        "dna": "c0adcd977cedf446a4f65a016f9b865442ddb5b9",
        "edition": "285",
        "type": "Acid Trip",
        "date": 1677695449571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 34,
        "maxHealth": 198,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "285",
        "rarity_score": 237.09158014063576,
        "rarity_level": "COMMON"
    },
    "1078": {
        "name": "Brawler Bears #1078",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1078.png",
        "dna": "9e3058644aedbdb36847428051f871a06271c7ac",
        "edition": "1078",
        "type": "Deep Martian Cyborg",
        "date": 1677695450708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1078",
        "rarity_score": 211.65671906941645,
        "rarity_level": "COMMON"
    },
    "649": {
        "name": "Brawler Bears #649",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/649.png",
        "dna": "0c42629534d129576821f6d97ac1469161b506b0",
        "edition": "649",
        "type": "Brown Bear",
        "date": 1677695452273,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "649",
        "rarity_score": 214.72613522404183,
        "rarity_level": "COMMON"
    },
    "1989": {
        "name": "Brawler Bears #1989",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1989.png",
        "dna": "79a3f461f6361e256afad3c19efbb39f213b0b46",
        "edition": "1989",
        "type": "Black Bear",
        "date": 1677695453866,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1989",
        "rarity_score": 261.043110402321,
        "rarity_level": "COMMON"
    },
    "1490": {
        "name": "Brawler Bears #1490",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1490.png",
        "dna": "7b71767ad70995dd290f28a6d085a1e0ba506cae",
        "edition": "1490",
        "type": "Brown Bear",
        "date": 1677695454733,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1490",
        "rarity_score": 216.08108759794976,
        "rarity_level": "COMMON"
    },
    "602": {
        "name": "Brawler Bears #602",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/602.png",
        "dna": "f5b448ac20d01307148d56a4dc635743ea25ddb4",
        "edition": "602",
        "type": "Acid Trip",
        "date": 1677695455467,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "602",
        "rarity_score": 157.27752712256824,
        "rarity_level": "COMMON"
    },
    "1284": {
        "name": "Brawler Bears #1284",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1284.png",
        "dna": "52ccd3a8fb71012479a35f34eb8bddcc8a5e5d8d",
        "edition": "1284",
        "type": "Black Bear",
        "date": 1677695456314,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 47,
        "maxHealth": 272,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1284",
        "rarity_score": 118.90337261158527,
        "rarity_level": "COMMON"
    },
    "1568": {
        "name": "Brawler Bears #1568",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1568.png",
        "dna": "c958a1feb98e58d63b85447bedea1430d8ea4f33",
        "edition": "1568",
        "type": "Black Bear",
        "date": 1677695457117,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1568",
        "rarity_score": 257.28631512538846,
        "rarity_level": "COMMON"
    },
    "916": {
        "name": "Brawler Bears #916",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/916.png",
        "dna": "45a614a0bebd8e27a13c4b135827f17c85703547",
        "edition": "916",
        "type": "Brown Bear",
        "date": 1677695458328,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "916",
        "rarity_score": 282.9000984171098,
        "rarity_level": "RARE"
    },
    "1096": {
        "name": "Brawler Bears #1096",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1096.png",
        "dna": "a203f08d2196c22b94e22a9e8bceddea97e0d14b",
        "edition": "1096",
        "type": "Black Bear",
        "date": 1677695459189,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1096",
        "rarity_score": 214.4580842183318,
        "rarity_level": "COMMON"
    },
    "499": {
        "name": "Brawler Bears #499",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/499.png",
        "dna": "35d7ae3fa493fa7dc8ff5eee3fde9311b619d7fa",
        "edition": "499",
        "type": "Black Bear",
        "date": 1677695460597,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "499",
        "rarity_score": 268.42134625571583,
        "rarity_level": "COMMON"
    },
    "1794": {
        "name": "Brawler Bears #1794",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1794.png",
        "dna": "3c61084322e89f86a5baba422b105af82cebfc1c",
        "edition": "1794",
        "type": "Black Bear",
        "date": 1677695461728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1794",
        "rarity_score": 289.29736983750684,
        "rarity_level": "RARE"
    },
    "2245": {
        "name": "Brawler Bears #2245",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2245.png",
        "dna": "d09030e5281b5080e1aab7826d7f234c3c4ec832",
        "edition": "2245",
        "type": "Deep Martian Cyborg",
        "date": 1677695463112,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "2245",
        "rarity_score": 323.90675423312825,
        "rarity_level": "RARE"
    },
    "2148": {
        "name": "Brawler Bears #2148",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2148.png",
        "dna": "6b6d368826035680c313fef5eefc44cacd8c3777",
        "edition": "2148",
        "type": "Panda",
        "date": 1677695463866,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 35,
        "maxHealth": 200,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2148",
        "rarity_score": 225.74184451635523,
        "rarity_level": "COMMON"
    },
    "2144": {
        "name": "Brawler Bears #2144",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2144.png",
        "dna": "22b8c3d54b0696db2980503655a0ee41e0c87749",
        "edition": "2144",
        "type": "Galaxy",
        "date": 1677695465478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "2144",
        "rarity_score": 261.83613615054264,
        "rarity_level": "COMMON"
    },
    "2082": {
        "name": "Brawler Bears #2082",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2082.png",
        "dna": "05f2c6b81beb5de7da873702b35d7db73db4c1e7",
        "edition": "2082",
        "type": "Acid Trip",
        "date": 1677695466314,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2082",
        "rarity_score": 221.62584694075503,
        "rarity_level": "COMMON"
    },
    "1150": {
        "name": "Brawler Bears #1150",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1150.png",
        "dna": "82f450b9cd07bfaa3123bf6e2dd491414affea80",
        "edition": "1150",
        "type": "Black Bear",
        "date": 1677695467426,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1150",
        "rarity_score": 143.5642800353946,
        "rarity_level": "COMMON"
    },
    "253": {
        "name": "Brawler Bears #253",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/253.png",
        "dna": "e6c8f8bee7f82e94973823083110cf3f316ad17f",
        "edition": "253",
        "type": "Deep Martian Cyborg",
        "date": 1677695468326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "253",
        "rarity_score": 213.27060726390096,
        "rarity_level": "COMMON"
    },
    "1924": {
        "name": "Brawler Bears #1924",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1924.png",
        "dna": "a0f0ad511fea15d91e43c86de80278c59242a396",
        "edition": "1924",
        "type": "Galaxy",
        "date": 1677695469174,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1924",
        "rarity_score": 203.01472788603846,
        "rarity_level": "COMMON"
    },
    "1053": {
        "name": "Brawler Bears #1053",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1053.png",
        "dna": "92b0bb530b57dc099eb3d55147cd1fe07865cd23",
        "edition": "1053",
        "type": "Black Bear",
        "date": 1677695470037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 47,
        "maxHealth": 272,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1053",
        "rarity_score": 127.9572565669146,
        "rarity_level": "COMMON"
    },
    "282": {
        "name": "Brawler Bears #282",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/282.png",
        "dna": "8686badb061239f6b1ea035df28fbad3e5ad1036",
        "edition": "282",
        "type": "Ice",
        "date": 1677695471089,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "282",
        "rarity_score": 241.1220130342666,
        "rarity_level": "COMMON"
    },
    "2093": {
        "name": "Brawler Bears #2093",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2093.png",
        "dna": "b2d3a8665d8f45e44020a23fcc54ce1bc5491e88",
        "edition": "2093",
        "type": "Cheetah",
        "date": 1677695472031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 29,
        "maxHealth": 171,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "2093",
        "rarity_score": 197.9377057888218,
        "rarity_level": "COMMON"
    },
    "768": {
        "name": "Brawler Bears #768",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/768.png",
        "dna": "922b857b8b8671e318e6b3c51dbf23894ab95267",
        "edition": "768",
        "type": "Acid Trip",
        "date": 1677695473441,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "768",
        "rarity_score": 269.6588202807506,
        "rarity_level": "COMMON"
    },
    "1769": {
        "name": "Brawler Bears #1769",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1769.png",
        "dna": "bb458b383ddfe8f9f4c7151289a39affb6ba6769",
        "edition": "1769",
        "type": "Black Bear",
        "date": 1677695474256,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1769",
        "rarity_score": 268.91943237424914,
        "rarity_level": "COMMON"
    },
    "679": {
        "name": "Brawler Bears #679",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/679.png",
        "dna": "8725b48e922d88667a5e56a576d2e236992d1742",
        "edition": "679",
        "type": "Ice",
        "date": 1677695475605,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "679",
        "rarity_score": 231.44462428604407,
        "rarity_level": "COMMON"
    },
    "1520": {
        "name": "Brawler Bears #1520",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1520.png",
        "dna": "e76223cb679c702cd1907dcaceba136304df0de4",
        "edition": "1520",
        "type": "Ice",
        "date": 1677695476527,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 61,
        "maxHealth": 353,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1520",
        "rarity_score": 103.42803467732475,
        "rarity_level": "COMMON"
    },
    "2008": {
        "name": "Brawler Bears #2008",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2008.png",
        "dna": "782daf108ecbec4582e5972ba416ded7396084d2",
        "edition": "2008",
        "type": "Ice",
        "date": 1677695477401,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2008",
        "rarity_score": 228.92176673639656,
        "rarity_level": "COMMON"
    },
    "522": {
        "name": "Brawler Bears #522",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/522.png",
        "dna": "087ce016b1eda2e22e69cf362fddd87038a0c4d4",
        "edition": "522",
        "type": "Brown Bear",
        "date": 1677695478258,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "522",
        "rarity_score": 300.04586698039947,
        "rarity_level": "RARE"
    },
    "1250": {
        "name": "Brawler Bears #1250",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1250.png",
        "dna": "e920e65821510b6349ada53f1438e371ec1aa06a",
        "edition": "1250",
        "type": "Galactic Cyborg",
        "date": 1677695479107,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 37,
        "maxHealth": 217,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1250",
        "rarity_score": 199.01428853673087,
        "rarity_level": "COMMON"
    },
    "1119": {
        "name": "Brawler Bears #1119",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1119.png",
        "dna": "07e283e310e726ea76ad811a312a1e0dbdf370e6",
        "edition": "1119",
        "type": "Galactic Cyborg",
        "date": 1677695479905,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 211,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1119",
        "rarity_score": 234.3905459050935,
        "rarity_level": "COMMON"
    },
    "30": {
        "name": "Brawler Bears #30",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/30.png",
        "dna": "909f5b70bb2b0ebe3c5ae6de1458b3b85c1ff609",
        "edition": "30",
        "type": "Galactic Cyborg",
        "date": 1677695481253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "30",
        "rarity_score": 260.11497499137914,
        "rarity_level": "COMMON"
    },
    "262": {
        "name": "Brawler Bears #262",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/262.png",
        "dna": "3512bd08b0e972de06e1e05fe0892db17aa14cf2",
        "edition": "262",
        "type": "Brown Bear",
        "date": 1677695481912,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "262",
        "rarity_score": 203.66954701678335,
        "rarity_level": "COMMON"
    },
    "1973": {
        "name": "Brawler Bears #1973",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1973.png",
        "dna": "723cbe7a4806ef1c3e033bce929f67adad46a512",
        "edition": "1973",
        "type": "Brown Bear",
        "date": 1677695482754,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1973",
        "rarity_score": 272.1069556777739,
        "rarity_level": "COMMON"
    },
    "1174": {
        "name": "Brawler Bears #1174",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1174.png",
        "dna": "4a75622c6e5765dabba06a08ce3b138e9cbf53fc",
        "edition": "1174",
        "type": "Lava",
        "date": 1677695484541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1174",
        "rarity_score": 253.9486807847102,
        "rarity_level": "COMMON"
    },
    "1865": {
        "name": "Brawler Bears #1865",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1865.png",
        "dna": "26c1504516f21e7cfc6c2d2f043c44620e5bd60e",
        "edition": "1865",
        "type": "Black Bear",
        "date": 1677695485381,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1865",
        "rarity_score": 300.62481301351016,
        "rarity_level": "RARE"
    },
    "1016": {
        "name": "Brawler Bears #1016",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1016.png",
        "dna": "7f44db8bac0854b41e34bef3b82b74875933d856",
        "edition": "1016",
        "type": "Black Bear",
        "date": 1677695486753,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 69,
        "maxHealth": 394,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1016",
        "rarity_score": 74.45563550649243,
        "rarity_level": "COMMON"
    },
    "2291": {
        "name": "Brawler Bears #2291",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2291.png",
        "dna": "6f577617a0cfb4e7347162422dbb4bbb06209323",
        "edition": "2291",
        "type": "Deep Martian Cyborg",
        "date": 1677695487765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "2291",
        "rarity_score": 281.4795428159431,
        "rarity_level": "RARE"
    },
    "1306": {
        "name": "Brawler Bears #1306",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1306.png",
        "dna": "a6cac2aef812bd93080ed37c9cab2eb80ab3f493",
        "edition": "1306",
        "type": "Cheetah",
        "date": 1677695488694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1306",
        "rarity_score": 272.66796468908296,
        "rarity_level": "COMMON"
    },
    "863": {
        "name": "Brawler Bears #863",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/863.png",
        "dna": "65b06b34491814f4ec2991c61db8264638ef4f20",
        "edition": "863",
        "type": "Brown Bear",
        "date": 1677695489478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 59,
        "maxHealth": 340,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "863",
        "rarity_score": 152.87964265492553,
        "rarity_level": "COMMON"
    },
    "1423": {
        "name": "Brawler Bears #1423",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1423.png",
        "dna": "b83758f93c05f00bd0f548c84ab629033da36b0a",
        "edition": "1423",
        "type": "Brown Bear",
        "date": 1677695490394,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1423",
        "rarity_score": 179.4953672287115,
        "rarity_level": "COMMON"
    },
    "1077": {
        "name": "Brawler Bears #1077",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1077.png",
        "dna": "30f1fa39ec9002de58de7d2e22e0a53998ac1892",
        "edition": "1077",
        "type": "Black Bear",
        "date": 1677695491286,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1077",
        "rarity_score": 233.20015635938012,
        "rarity_level": "COMMON"
    },
    "811": {
        "name": "Brawler Bears #811",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/811.png",
        "dna": "f1748ff0128a8ae422c52c57bf42022d6ba0e69e",
        "edition": "811",
        "type": "Ice",
        "date": 1677695492943,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "811",
        "rarity_score": 204.3843709339195,
        "rarity_level": "COMMON"
    },
    "2238": {
        "name": "Brawler Bears #2238",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2238.png",
        "dna": "2d55c20a500030ceb3e0db31c4249c21b53d918a",
        "edition": "2238",
        "type": "Galaxy",
        "date": 1677695494061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "2238",
        "rarity_score": 323.7559771283765,
        "rarity_level": "RARE"
    },
    "501": {
        "name": "Brawler Bears #501",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/501.png",
        "dna": "b6647584d3644dcf89ac274b238808f24c2bb988",
        "edition": "501",
        "type": "Black Bear",
        "date": 1677695494670,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "501",
        "rarity_score": 253.36509397342925,
        "rarity_level": "COMMON"
    },
    "151": {
        "name": "Brawler Bears #151",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/151.png",
        "dna": "a6533c806a274d88cab781220ef962c66c6112e4",
        "edition": "151",
        "type": "Brown Bear",
        "date": 1677695496017,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "151",
        "rarity_score": 262.6561927135407,
        "rarity_level": "COMMON"
    },
    "1409": {
        "name": "Brawler Bears #1409",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1409.png",
        "dna": "8388c082903102804adfb41d0507e76a8d3c63ab",
        "edition": "1409",
        "type": "Galaxy",
        "date": 1677695497527,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 63,
        "maxHealth": 364,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1409",
        "rarity_score": 82.90231596602466,
        "rarity_level": "COMMON"
    },
    "208": {
        "name": "Brawler Bears #208",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/208.png",
        "dna": "78ab3988cf6c3714bc70fc849363b3e222999512",
        "edition": "208",
        "type": "Galactic Cyborg",
        "date": 1677695498603,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "208",
        "rarity_score": 269.1325377688917,
        "rarity_level": "COMMON"
    },
    "1308": {
        "name": "Brawler Bears #1308",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1308.png",
        "dna": "a5e79b92f97bcdc540e854a141c37bc0a5d5c86a",
        "edition": "1308",
        "type": "Deep Martian Cyborg",
        "date": 1677695499410,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 62,
        "maxHealth": 359,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1308",
        "rarity_score": 82.75316879587217,
        "rarity_level": "COMMON"
    },
    "1240": {
        "name": "Brawler Bears #1240",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1240.png",
        "dna": "100d2839a69ca39472c8bea840a09e1fe8b0ff33",
        "edition": "1240",
        "type": "Brown Bear",
        "date": 1677695500713,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1240",
        "rarity_score": 260.177472850813,
        "rarity_level": "COMMON"
    },
    "957": {
        "name": "Brawler Bears #957",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/957.png",
        "dna": "54733262f9b99d0d337ee31e191fa3e768e3c540",
        "edition": "957",
        "type": "Panda",
        "date": 1677695501693,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "957",
        "rarity_score": 278.6680355780756,
        "rarity_level": "RARE"
    },
    "557": {
        "name": "Brawler Bears #557",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/557.png",
        "dna": "4519fd0b72873bf650a6d37475532db4be3a998a",
        "edition": "557",
        "type": "Deep Martian Cyborg",
        "date": 1677695502539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 52,
        "maxHealth": 301,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "557",
        "rarity_score": 182.89687362406676,
        "rarity_level": "COMMON"
    },
    "389": {
        "name": "Brawler Bears #389",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/389.png",
        "dna": "b1478898cdcd3f05493c8b3b09b3ef13a56acc5f",
        "edition": "389",
        "type": "Deep Martian Cyborg",
        "date": 1677695503456,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "389",
        "rarity_score": 210.62013061813187,
        "rarity_level": "COMMON"
    },
    "1612": {
        "name": "Brawler Bears #1612",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1612.png",
        "dna": "bcef85a62424302414d13d12375b824aae5e4113",
        "edition": "1612",
        "type": "Galaxy",
        "date": 1677695504218,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1612",
        "rarity_score": 213.61209447136812,
        "rarity_level": "COMMON"
    },
    "119": {
        "name": "Brawler Bears #119",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/119.png",
        "dna": "8aa952aa50d4a318b655d5c428b48afad1f8be86",
        "edition": "119",
        "type": "Galactic Cyborg",
        "date": 1677695505191,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "119",
        "rarity_score": 215.91772336261934,
        "rarity_level": "COMMON"
    },
    "574": {
        "name": "Brawler Bears #574",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/574.png",
        "dna": "26d72f0414c825ee1642385e6355be92c8eb89e7",
        "edition": "574",
        "type": "Ice",
        "date": 1677695506245,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "574",
        "rarity_score": 211.7936148402871,
        "rarity_level": "COMMON"
    },
    "1529": {
        "name": "Brawler Bears #1529",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1529.png",
        "dna": "3cad04fccbd32f17b29d697c8128a8a2b1ab0701",
        "edition": "1529",
        "type": "Galactic Cyborg",
        "date": 1677695507175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1529",
        "rarity_score": 256.25324671454706,
        "rarity_level": "COMMON"
    },
    "1047": {
        "name": "Brawler Bears #1047",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1047.png",
        "dna": "ebb9a4af36e792ddb66669ac48a85d8e0290e77a",
        "edition": "1047",
        "type": "Ice",
        "date": 1677695508015,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1047",
        "rarity_score": 303.10833633334886,
        "rarity_level": "RARE"
    },
    "2145": {
        "name": "Brawler Bears #2145",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2145.png",
        "dna": "d5edd773ecfbb24f0ad5c4084bf8e4bc8280d4d1",
        "edition": "2145",
        "type": "Galactic Cyborg",
        "date": 1677695509016,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "2145",
        "rarity_score": 193.25223074189242,
        "rarity_level": "COMMON"
    },
    "756": {
        "name": "Brawler Bears #756",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/756.png",
        "dna": "a5334c1b11bbd82011980dfe9b7933263b3e8307",
        "edition": "756",
        "type": "Brown Bear",
        "date": 1677695510230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "756",
        "rarity_score": 201.58475781336875,
        "rarity_level": "COMMON"
    },
    "529": {
        "name": "Brawler Bears #529",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/529.png",
        "dna": "b497c0453129bf306d26bbf99ad634d713c796bf",
        "edition": "529",
        "type": "Galaxy",
        "date": 1677695511326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "529",
        "rarity_score": 214.71353429385874,
        "rarity_level": "COMMON"
    },
    "309": {
        "name": "Brawler Bears #309",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/309.png",
        "dna": "aa4f579ea34d386f7277890da1fbdce50562e497",
        "edition": "309",
        "type": "Black Bear",
        "date": 1677695512687,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "309",
        "rarity_score": 244.51402183593646,
        "rarity_level": "COMMON"
    },
    "1542": {
        "name": "Brawler Bears #1542",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1542.png",
        "dna": "6662a780002ebd2b02db2593d8d3871f2d792f25",
        "edition": "1542",
        "type": "Black Bear",
        "date": 1677695513346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 41,
        "maxHealth": 236,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1542",
        "rarity_score": 209.64251398704585,
        "rarity_level": "COMMON"
    },
    "323": {
        "name": "Brawler Bears #323",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/323.png",
        "dna": "699f6cb613018e8bb2a012cf23e0cd243a59e630",
        "edition": "323",
        "type": "Brown Bear",
        "date": 1677695514161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "323",
        "rarity_score": 201.91514410395644,
        "rarity_level": "COMMON"
    },
    "1333": {
        "name": "Brawler Bears #1333",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1333.png",
        "dna": "b26ebe12e32e7caeb7dd8ab26324c5b3e495a1ed",
        "edition": "1333",
        "type": "Galaxy",
        "date": 1677695515108,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1333",
        "rarity_score": 267.0490980767293,
        "rarity_level": "COMMON"
    },
    "968": {
        "name": "Brawler Bears #968",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/968.png",
        "dna": "80130f54fb7592b261d658f2921d9f8e136e8594",
        "edition": "968",
        "type": "Brown Bear",
        "date": 1677695516638,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "968",
        "rarity_score": 324.96217144582334,
        "rarity_level": "RARE"
    },
    "980": {
        "name": "Brawler Bears #980",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/980.png",
        "dna": "ae7bdd61f7c970025125e2c92cbd4620352d6a50",
        "edition": "980",
        "type": "Acid Trip",
        "date": 1677695517316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 13,
        "maxHealth": 74,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "980",
        "rarity_score": 270.21885647988034,
        "rarity_level": "COMMON"
    },
    "192": {
        "name": "Brawler Bears #192",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/192.png",
        "dna": "6df411246f112175fa103f98761a59a9f87b4d26",
        "edition": "192",
        "type": "Brown Bear",
        "date": 1677695518160,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "192",
        "rarity_score": 222.0969330809572,
        "rarity_level": "COMMON"
    },
    "1715": {
        "name": "Brawler Bears #1715",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1715.png",
        "dna": "461d11b3c980e9044b8c38a6a645139836f204b2",
        "edition": "1715",
        "type": "Black Bear",
        "date": 1677695519421,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1715",
        "rarity_score": 279.77493477889743,
        "rarity_level": "RARE"
    },
    "2062": {
        "name": "Brawler Bears #2062",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2062.png",
        "dna": "be05d53f0c8e78c59c287f8d64523fb78556e0e9",
        "edition": "2062",
        "type": "Black Bear",
        "date": 1677695520193,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "2062",
        "rarity_score": 230.71171024695786,
        "rarity_level": "COMMON"
    },
    "1103": {
        "name": "Brawler Bears #1103",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1103.png",
        "dna": "cdda9153ab9f03f16d035956d185433d13c5fd17",
        "edition": "1103",
        "type": "Panda",
        "date": 1677695521607,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 35,
        "maxHealth": 202,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1103",
        "rarity_score": 247.8357304593017,
        "rarity_level": "COMMON"
    },
    "1540": {
        "name": "Brawler Bears #1540",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1540.png",
        "dna": "faa1d7429ab42d5a5b5e25e9c76d493a51e915cc",
        "edition": "1540",
        "type": "Deep Martian Cyborg",
        "date": 1677695522560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1540",
        "rarity_score": 213.1807881994046,
        "rarity_level": "COMMON"
    },
    "2031": {
        "name": "Brawler Bears #2031",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2031.png",
        "dna": "607869d2d905e67139831fd0a75e0060de624842",
        "edition": "2031",
        "type": "Black Bear",
        "date": 1677695523562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2031",
        "rarity_score": 226.8741350060578,
        "rarity_level": "COMMON"
    },
    "828": {
        "name": "Brawler Bears #828",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/828.png",
        "dna": "8b61a95ec2ab51634b4c5d68c935d6dc2fd5daa3",
        "edition": "828",
        "type": "Galaxy",
        "date": 1677695524487,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "828",
        "rarity_score": 182.63409780116947,
        "rarity_level": "COMMON"
    },
    "217": {
        "name": "Brawler Bears #217",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/217.png",
        "dna": "f8e16872ba8cfc680caf68cbf6c454d1024f241d",
        "edition": "217",
        "type": "Black Bear",
        "date": 1677695525676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "217",
        "rarity_score": 306.78402167624836,
        "rarity_level": "RARE"
    },
    "134": {
        "name": "Brawler Bears #134",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/134.png",
        "dna": "190b9f3e11158bf1c16860bcb3e26c527345a5e7",
        "edition": "134",
        "type": "Cheetah",
        "date": 1677695527668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "134",
        "rarity_score": 228.54984494531155,
        "rarity_level": "COMMON"
    },
    "475": {
        "name": "Brawler Bears #475",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/475.png",
        "dna": "62fa2ec2431f94ef7e26f5a875286d88efdcb462",
        "edition": "475",
        "type": "Black Bear",
        "date": 1677695528685,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "475",
        "rarity_score": 231.63190542429618,
        "rarity_level": "COMMON"
    },
    "1273": {
        "name": "Brawler Bears #1273",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1273.png",
        "dna": "7f0054940b424552d75fcd1457f4b031fb80c093",
        "edition": "1273",
        "type": "Black Bear",
        "date": 1677695529916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1273",
        "rarity_score": 217.4776504062834,
        "rarity_level": "COMMON"
    },
    "163": {
        "name": "Brawler Bears #163",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/163.png",
        "dna": "6a0a103754282a61573d03afaea8ec55a454d83e",
        "edition": "163",
        "type": "Black Bear",
        "date": 1677695531169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 20,
        "maxHealth": 117,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "163",
        "rarity_score": 272.13128646783326,
        "rarity_level": "COMMON"
    },
    "49": {
        "name": "Brawler Bears #49",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/49.png",
        "dna": "a209b6e2ecdd5e71ec61a905560b2bd7d6d70fb0",
        "edition": "49",
        "type": "Deep Martian Cyborg",
        "date": 1677695532175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "49",
        "rarity_score": 209.9574968870595,
        "rarity_level": "COMMON"
    },
    "1151": {
        "name": "Brawler Bears #1151",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1151.png",
        "dna": "7586856e5999499c92cf32a62cae9a4a34736460",
        "edition": "1151",
        "type": "Galaxy",
        "date": 1677695533870,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1151",
        "rarity_score": 346.7705985086045,
        "rarity_level": "RARE"
    },
    "789": {
        "name": "Brawler Bears #789",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/789.png",
        "dna": "ef851354c66348f6d17461bc819059101dee179b",
        "edition": "789",
        "type": "Deep Martian Cyborg",
        "date": 1677695534659,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "789",
        "rarity_score": 230.4356866289803,
        "rarity_level": "COMMON"
    },
    "1866": {
        "name": "Brawler Bears #1866",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1866.png",
        "dna": "a5a907858a4500766c08bfb15f34c096d460df5e",
        "edition": "1866",
        "type": "Black Bear",
        "date": 1677695535559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1866",
        "rarity_score": 231.80242076032084,
        "rarity_level": "COMMON"
    },
    "1442": {
        "name": "Brawler Bears #1442",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1442.png",
        "dna": "c8c77940a37a4c61d6c495b634ec1a0b61bec1ea",
        "edition": "1442",
        "type": "Brown Bear",
        "date": 1677695536597,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1442",
        "rarity_score": 304.35123728082743,
        "rarity_level": "RARE"
    },
    "43": {
        "name": "Brawler Bears #43",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/43.png",
        "dna": "5c36fdcc40f1c40e89462ab0aaaacdeda1480094",
        "edition": "43",
        "type": "Deep Martian Cyborg",
        "date": 1677695537663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "43",
        "rarity_score": 278.031101752984,
        "rarity_level": "RARE"
    },
    "1569": {
        "name": "Brawler Bears #1569",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1569.png",
        "dna": "7f841facf75260e689121f681992b55340b32138",
        "edition": "1569",
        "type": "Deep Martian Cyborg",
        "date": 1677695538524,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1569",
        "rarity_score": 213.70758359646152,
        "rarity_level": "COMMON"
    },
    "969": {
        "name": "Brawler Bears #969",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/969.png",
        "dna": "5f18d503e1504d1e491a2a44c9a1cf01f11ae38c",
        "edition": "969",
        "type": "Brown Bear",
        "date": 1677695539318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 69,
        "maxHealth": 399,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "969",
        "rarity_score": 76.32473214463066,
        "rarity_level": "COMMON"
    },
    "582": {
        "name": "Brawler Bears #582",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/582.png",
        "dna": "a55775dd60e6a93335b148c478ea2421caff67df",
        "edition": "582",
        "type": "Galactic Cyborg",
        "date": 1677695540488,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "582",
        "rarity_score": 154.0824258494238,
        "rarity_level": "COMMON"
    },
    "2125": {
        "name": "Brawler Bears #2125",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2125.png",
        "dna": "d9a8b18dbfcc6b3e43b77429a6831199348d8a31",
        "edition": "2125",
        "type": "Panda",
        "date": 1677695541208,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "2125",
        "rarity_score": 311.1433664374841,
        "rarity_level": "RARE"
    },
    "171": {
        "name": "Brawler Bears #171",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/171.png",
        "dna": "040e3ce2d21ff35db61432a67ab4151b850f241c",
        "edition": "171",
        "type": "Black Bear",
        "date": 1677695542065,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "171",
        "rarity_score": 311.5993532391565,
        "rarity_level": "RARE"
    },
    "802": {
        "name": "Brawler Bears #802",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/802.png",
        "dna": "f2c70496daaa0bbe84b010b06964c76ce3812e36",
        "edition": "802",
        "type": "Deep Martian Cyborg",
        "date": 1677695542800,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "802",
        "rarity_score": 282.2618329531468,
        "rarity_level": "RARE"
    },
    "1025": {
        "name": "Brawler Bears #1025",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1025.png",
        "dna": "83e5cc62513d7a3e0fdf699846893dfbe7a058e4",
        "edition": "1025",
        "type": "Black Bear",
        "date": 1677695544216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1025",
        "rarity_score": 129.1566740202784,
        "rarity_level": "COMMON"
    },
    "1245": {
        "name": "Brawler Bears #1245",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1245.png",
        "dna": "0756deb63f4d0082dd26441d48f1dd74ca9f0807",
        "edition": "1245",
        "type": "Brown Bear",
        "date": 1677695544938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 41,
        "maxHealth": 238,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1245",
        "rarity_score": 280.04376253875364,
        "rarity_level": "RARE"
    },
    "859": {
        "name": "Brawler Bears #859",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/859.png",
        "dna": "b042b07ce8ce6fbca0a44979df444db5dede1e25",
        "edition": "859",
        "type": "Brown Bear",
        "date": 1677695545873,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "859",
        "rarity_score": 226.34186026008163,
        "rarity_level": "COMMON"
    },
    "1522": {
        "name": "Brawler Bears #1522",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1522.png",
        "dna": "931137bca51eb579937a8bdab761cd8cae7fec1e",
        "edition": "1522",
        "type": "Galactic Cyborg",
        "date": 1677695547178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1522",
        "rarity_score": 126.71424776005648,
        "rarity_level": "COMMON"
    },
    "356": {
        "name": "Brawler Bears #356",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/356.png",
        "dna": "875a9734afb6a962d1a7636248a62e090fcef321",
        "edition": "356",
        "type": "Ice",
        "date": 1677695548211,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "356",
        "rarity_score": 250.45274510759887,
        "rarity_level": "COMMON"
    },
    "65": {
        "name": "Brawler Bears #65",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/65.png",
        "dna": "f674a4df5598cf146209c20f2efb40ede675fa52",
        "edition": "65",
        "type": "Brown Bear",
        "date": 1677695548995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "65",
        "rarity_score": 250.31155911197763,
        "rarity_level": "COMMON"
    },
    "256": {
        "name": "Brawler Bears #256",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/256.png",
        "dna": "bb6bedb879fc102e50448b24d39c6c1549e65db2",
        "edition": "256",
        "type": "Deep Martian Cyborg",
        "date": 1677695549884,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "256",
        "rarity_score": 265.94511060426083,
        "rarity_level": "COMMON"
    },
    "1381": {
        "name": "Brawler Bears #1381",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1381.png",
        "dna": "d840e1d6342c1586cc83f906a6e6683cdea698a1",
        "edition": "1381",
        "type": "Black Bear",
        "date": 1677695550782,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1381",
        "rarity_score": 260.1020211543047,
        "rarity_level": "COMMON"
    },
    "886": {
        "name": "Brawler Bears #886",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/886.png",
        "dna": "c790f8a3d0c9b02f341447746a18dc58732c7b45",
        "edition": "886",
        "type": "Galaxy",
        "date": 1677695551542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "886",
        "rarity_score": 260.72887216137866,
        "rarity_level": "COMMON"
    },
    "848": {
        "name": "Brawler Bears #848",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/848.png",
        "dna": "c6b7b7f961e4f2b24f902b78b1a11722c618a995",
        "edition": "848",
        "type": "Ice",
        "date": 1677695552448,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "848",
        "rarity_score": 257.00943030249,
        "rarity_level": "COMMON"
    },
    "210": {
        "name": "Brawler Bears #210",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/210.png",
        "dna": "9211d5146ee67f53393e70b541011fb4314b09b2",
        "edition": "210",
        "type": "Cheetah",
        "date": 1677695553253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 50,
        "maxHealth": 290,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "210",
        "rarity_score": 183.90822629727097,
        "rarity_level": "COMMON"
    },
    "1377": {
        "name": "Brawler Bears #1377",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1377.png",
        "dna": "786829a3cce2a5a6134c4cd54c3169a590950348",
        "edition": "1377",
        "type": "Black Bear",
        "date": 1677695553859,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 48,
        "maxHealth": 276,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1377",
        "rarity_score": 121.00544762428498,
        "rarity_level": "COMMON"
    },
    "508": {
        "name": "Brawler Bears #508",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/508.png",
        "dna": "c08725e8eaeade830d7250ad4d60350ec8236cad",
        "edition": "508",
        "type": "Deep Martian Cyborg",
        "date": 1677695555454,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 63,
        "maxHealth": 360,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "508",
        "rarity_score": 83.65503478078394,
        "rarity_level": "COMMON"
    },
    "1971": {
        "name": "Brawler Bears #1971",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1971.png",
        "dna": "bc41402a8b49e729788e861ad1f321d840819a2f",
        "edition": "1971",
        "type": "Galactic Cyborg",
        "date": 1677695556186,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1971",
        "rarity_score": 276.0816696984199,
        "rarity_level": "RARE"
    },
    "656": {
        "name": "Brawler Bears #656",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/656.png",
        "dna": "5c93f93e6393f3e5792de0000119d5cc4bc59b32",
        "edition": "656",
        "type": "Galactic Cyborg",
        "date": 1677695556920,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "656",
        "rarity_score": 178.90090415797314,
        "rarity_level": "COMMON"
    },
    "1266": {
        "name": "Brawler Bears #1266",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1266.png",
        "dna": "45de289b6781b4d5e647bf6fe021d383c238289e",
        "edition": "1266",
        "type": "Panda",
        "date": 1677695558373,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1266",
        "rarity_score": 277.27983278798496,
        "rarity_level": "RARE"
    },
    "2246": {
        "name": "Brawler Bears #2246",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2246.png",
        "dna": "41a4e73ac5a501b71486473f05e45d869e0b037f",
        "edition": "2246",
        "type": "Black Bear",
        "date": 1677695559209,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "2246",
        "rarity_score": 227.81047336012077,
        "rarity_level": "COMMON"
    },
    "1637": {
        "name": "Brawler Bears #1637",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1637.png",
        "dna": "c8abd34ee6c2f6c8d1357ad33874b0fe9ea6587c",
        "edition": "1637",
        "type": "Panda",
        "date": 1677695560358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 35,
        "maxHealth": 200,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1637",
        "rarity_score": 256.388536178993,
        "rarity_level": "COMMON"
    },
    "1670": {
        "name": "Brawler Bears #1670",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1670.png",
        "dna": "214a5f6ef63d3a082c9279496788302001578798",
        "edition": "1670",
        "type": "Brown Bear",
        "date": 1677695561400,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1670",
        "rarity_score": 290.79645819802187,
        "rarity_level": "RARE"
    },
    "2189": {
        "name": "Brawler Bears #2189",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2189.png",
        "dna": "df47fc37d679aeab3fc00aebda97c5ffea5b8208",
        "edition": "2189",
        "type": "Ice",
        "date": 1677695562644,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2189",
        "rarity_score": 229.19893609677882,
        "rarity_level": "COMMON"
    },
    "1447": {
        "name": "Brawler Bears #1447",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1447.png",
        "dna": "b38af9da673c44aa023a848414d9e837807af116",
        "edition": "1447",
        "type": "Black Bear",
        "date": 1677695563355,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1447",
        "rarity_score": 189.60428723375372,
        "rarity_level": "COMMON"
    },
    "2157": {
        "name": "Brawler Bears #2157",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2157.png",
        "dna": "4871803ebb207755b60902b8542e283efc3ed6e1",
        "edition": "2157",
        "type": "Brown Bear",
        "date": 1677695564237,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "2157",
        "rarity_score": 173.70612040177886,
        "rarity_level": "COMMON"
    },
    "1900": {
        "name": "Brawler Bears #1900",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1900.png",
        "dna": "90618a4e97c16069c172e3047bbd180ed368f384",
        "edition": "1900",
        "type": "Black Bear",
        "date": 1677695565848,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 57,
        "maxHealth": 327,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1900",
        "rarity_score": 223.56255700743125,
        "rarity_level": "COMMON"
    },
    "317": {
        "name": "Brawler Bears #317",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/317.png",
        "dna": "16236e1b3eb4bb22553ba6b815e1f171c8c61ee8",
        "edition": "317",
        "type": "Acid Trip",
        "date": 1677695566839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "317",
        "rarity_score": 198.53404400891228,
        "rarity_level": "COMMON"
    },
    "468": {
        "name": "Brawler Bears #468",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/468.png",
        "dna": "173a2d9bb1bf592747f3bf6876d7678e3588b896",
        "edition": "468",
        "type": "Acid Trip",
        "date": 1677695567765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "468",
        "rarity_score": 336.093624975541,
        "rarity_level": "RARE"
    },
    "2299": {
        "name": "Brawler Bears #2299",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2299.png",
        "dna": "d2b317279595416429ec1edae4bce53f7c2bbc3b",
        "edition": "2299",
        "type": "Black Bear",
        "date": 1677695569435,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "2299",
        "rarity_score": 264.92048079638556,
        "rarity_level": "COMMON"
    },
    "960": {
        "name": "Brawler Bears #960",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/960.png",
        "dna": "bd3e0e4459243810c208299f27c8ca052aba9b63",
        "edition": "960",
        "type": "Ice",
        "date": 1677695570746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "960",
        "rarity_score": 303.70226084684253,
        "rarity_level": "RARE"
    },
    "678": {
        "name": "Brawler Bears #678",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/678.png",
        "dna": "df3dad4f7ab35a30ac5c52024fef77408c0fb8d8",
        "edition": "678",
        "type": "Galaxy",
        "date": 1677695571870,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "678",
        "rarity_score": 287.32335503317387,
        "rarity_level": "RARE"
    },
    "1732": {
        "name": "Brawler Bears #1732",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1732.png",
        "dna": "4dd905fe2d241a6a4674a2ac2e2af0dbf132cd32",
        "edition": "1732",
        "type": "Galaxy",
        "date": 1677695572771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1732",
        "rarity_score": 270.5299217559141,
        "rarity_level": "COMMON"
    },
    "166": {
        "name": "Brawler Bears #166",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/166.png",
        "dna": "7b9fce75ea16f522fad901753bfe0841ccd3a0f5",
        "edition": "166",
        "type": "Brown Bear",
        "date": 1677695573937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "166",
        "rarity_score": 187.58563666731487,
        "rarity_level": "COMMON"
    },
    "145": {
        "name": "Brawler Bears #145",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/145.png",
        "dna": "1db5aca764d5859a77f19b54df2a9fe9b3d7aeff",
        "edition": "145",
        "type": "Black Bear",
        "date": 1677695575073,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 58,
        "maxHealth": 332,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "145",
        "rarity_score": 159.41699684893604,
        "rarity_level": "COMMON"
    },
    "17": {
        "name": "Brawler Bears #17",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/17.png",
        "dna": "3afa3d9b1839c695a2e4afdd70917bbd7e122766",
        "edition": "17",
        "type": "Galaxy",
        "date": 1677695575939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "17",
        "rarity_score": 162.72448420253346,
        "rarity_level": "COMMON"
    },
    "1757": {
        "name": "Brawler Bears #1757",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1757.png",
        "dna": "989abdd29c1718dc826b239be078a3f40cf30ae8",
        "edition": "1757",
        "type": "Deep Martian Cyborg",
        "date": 1677695577161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1757",
        "rarity_score": 268.16754379322526,
        "rarity_level": "COMMON"
    },
    "1279": {
        "name": "Brawler Bears #1279",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1279.png",
        "dna": "4240abc61c624f8d3b81463210ff1e17406886fd",
        "edition": "1279",
        "type": "Brown Bear",
        "date": 1677695577845,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1279",
        "rarity_score": 233.14057105920938,
        "rarity_level": "COMMON"
    },
    "205": {
        "name": "Brawler Bears #205",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/205.png",
        "dna": "914f4e4eea02361162cf316469c3a484191b2baa",
        "edition": "205",
        "type": "Brown Bear",
        "date": 1677695579012,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "205",
        "rarity_score": 260.77656543355494,
        "rarity_level": "COMMON"
    },
    "2111": {
        "name": "Brawler Bears #2111",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2111.png",
        "dna": "ec962d25a22716990abd5f479c02dbf010857060",
        "edition": "2111",
        "type": "Ice",
        "date": 1677695580088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2111",
        "rarity_score": 300.3583022292372,
        "rarity_level": "RARE"
    },
    "269": {
        "name": "Brawler Bears #269",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/269.png",
        "dna": "e0390988e60372c146d7f5afaad5b5b16f71eee2",
        "edition": "269",
        "type": "Black Bear",
        "date": 1677695581917,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "269",
        "rarity_score": 238.5313796987238,
        "rarity_level": "COMMON"
    },
    "1345": {
        "name": "Brawler Bears #1345",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1345.png",
        "dna": "d812a98a9b4c69dbcda6c14defb260cd0f04f12a",
        "edition": "1345",
        "type": "Black Bear",
        "date": 1677695582525,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 59,
        "maxHealth": 337,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1345",
        "rarity_score": 188.85969576007847,
        "rarity_level": "COMMON"
    },
    "1080": {
        "name": "Brawler Bears #1080",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1080.png",
        "dna": "c63cb239645d30c26370c0b7e1fc77e2d558e075",
        "edition": "1080",
        "type": "Galactic Cyborg",
        "date": 1677695584201,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1080",
        "rarity_score": 355.1392977837547,
        "rarity_level": "RARE"
    },
    "2254": {
        "name": "Brawler Bears #2254",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2254.png",
        "dna": "f043ab989b89657b7d3416d5006044955036186c",
        "edition": "2254",
        "type": "Black Bear",
        "date": 1677695585273,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "2254",
        "rarity_score": 254.79863345946907,
        "rarity_level": "COMMON"
    },
    "570": {
        "name": "Brawler Bears #570",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/570.png",
        "dna": "49e65321edf4432fd506bd49f4399752d6b96c99",
        "edition": "570",
        "type": "Lava",
        "date": 1677695586198,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "570",
        "rarity_score": 273.08467673320615,
        "rarity_level": "COMMON"
    },
    "1969": {
        "name": "Brawler Bears #1969",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1969.png",
        "dna": "076a328968bf6809385cac48fed69e0c235116f1",
        "edition": "1969",
        "type": "Galactic Cyborg",
        "date": 1677695587811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 25,
        "maxHealth": 145,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1969",
        "rarity_score": 186.2109063525232,
        "rarity_level": "COMMON"
    },
    "1277": {
        "name": "Brawler Bears #1277",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1277.png",
        "dna": "0f1a428f6f82b1953b13f225b18e7a251a71bc45",
        "edition": "1277",
        "type": "Galactic Cyborg",
        "date": 1677695589395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1277",
        "rarity_score": 262.2501679604399,
        "rarity_level": "COMMON"
    },
    "1325": {
        "name": "Brawler Bears #1325",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1325.png",
        "dna": "da52f26847796f06687fe28b9193a5384c585b6c",
        "edition": "1325",
        "type": "Black Bear",
        "date": 1677695590211,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 22,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1325",
        "rarity_score": 234.8296654122116,
        "rarity_level": "COMMON"
    },
    "430": {
        "name": "Brawler Bears #430",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/430.png",
        "dna": "519933ccc68fa4be2186800b46d21a7c53e703cd",
        "edition": "430",
        "type": "Galactic Cyborg",
        "date": 1677695591565,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "430",
        "rarity_score": 278.8646773930711,
        "rarity_level": "RARE"
    },
    "1198": {
        "name": "Brawler Bears #1198",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1198.png",
        "dna": "2b38c7095832d54136687d920b81ddf9982a7068",
        "edition": "1198",
        "type": "Ice",
        "date": 1677695593017,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1198",
        "rarity_score": 176.53644954306654,
        "rarity_level": "COMMON"
    },
    "1264": {
        "name": "Brawler Bears #1264",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1264.png",
        "dna": "a65d6170c9fff90eb53a9e95932c2739b0f925d7",
        "edition": "1264",
        "type": "Cheetah",
        "date": 1677695594296,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 27,
        "maxHealth": 159,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1264",
        "rarity_score": 227.1478021747107,
        "rarity_level": "COMMON"
    },
    "394": {
        "name": "Brawler Bears #394",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/394.png",
        "dna": "0ea7fd7148f51eb0c4b629e3fba8b44ce54f7316",
        "edition": "394",
        "type": "Galaxy",
        "date": 1677695595278,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "394",
        "rarity_score": 244.8652010299006,
        "rarity_level": "COMMON"
    },
    "764": {
        "name": "Brawler Bears #764",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/764.png",
        "dna": "9b6c4358bc75229ed9b8899d0a67be549dcb6f21",
        "edition": "764",
        "type": "Deep Martian Cyborg",
        "date": 1677695596071,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "764",
        "rarity_score": 185.88986312689286,
        "rarity_level": "COMMON"
    },
    "2053": {
        "name": "Brawler Bears #2053",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2053.png",
        "dna": "acdbe0053f16ce24f2f8357ed0802b513277682a",
        "edition": "2053",
        "type": "Panda",
        "date": 1677695596743,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "2053",
        "rarity_score": 278.0186343967737,
        "rarity_level": "RARE"
    },
    "1509": {
        "name": "Brawler Bears #1509",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1509.png",
        "dna": "6b0f4018d95b1f0889341ae88d6d30e8d85d0b90",
        "edition": "1509",
        "type": "Galaxy",
        "date": 1677695597514,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1509",
        "rarity_score": 208.7435025522469,
        "rarity_level": "COMMON"
    },
    "1004": {
        "name": "Brawler Bears #1004",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1004.png",
        "dna": "8c3d89356d42cde1a9a9e0b58baf8fe670d144cd",
        "edition": "1004",
        "type": "Panda",
        "date": 1677695598304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1004",
        "rarity_score": 200.46661431056756,
        "rarity_level": "COMMON"
    },
    "1029": {
        "name": "Brawler Bears #1029",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1029.png",
        "dna": "05c8982f085761eb827fb883a6210f3f85772e08",
        "edition": "1029",
        "type": "Deep Martian Cyborg",
        "date": 1677695599460,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 46,
        "maxHealth": 263,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1029",
        "rarity_score": 137.51613948393302,
        "rarity_level": "COMMON"
    },
    "204": {
        "name": "Brawler Bears #204",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/204.png",
        "dna": "850d612201581ecdca20a45a6f816b188aeff8bd",
        "edition": "204",
        "type": "Deep Martian Cyborg",
        "date": 1677695600404,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "204",
        "rarity_score": 298.1740606946701,
        "rarity_level": "RARE"
    },
    "1499": {
        "name": "Brawler Bears #1499",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1499.png",
        "dna": "4a16577a0a74008add66bdf58cbaca41aa1b8b8f",
        "edition": "1499",
        "type": "Brown Bear",
        "date": 1677695601572,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 59,
        "maxHealth": 339,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1499",
        "rarity_score": 170.81547520673973,
        "rarity_level": "COMMON"
    },
    "1920": {
        "name": "Brawler Bears #1920",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1920.png",
        "dna": "f94cb4437229a94a2698dc6770de3ae6a276ca5e",
        "edition": "1920",
        "type": "Brown Bear",
        "date": 1677695602261,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1920",
        "rarity_score": 260.86318178124736,
        "rarity_level": "COMMON"
    },
    "1086": {
        "name": "Brawler Bears #1086",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1086.png",
        "dna": "d3f23638f59007e40c90796ea4825e35f0db6d43",
        "edition": "1086",
        "type": "Black Bear",
        "date": 1677695603069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 48,
        "maxHealth": 276,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1086",
        "rarity_score": 122.55881788720922,
        "rarity_level": "COMMON"
    },
    "463": {
        "name": "Brawler Bears #463",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/463.png",
        "dna": "7b80e2a0b09fe815b7e664200cc3fb296c25399b",
        "edition": "463",
        "type": "Brown Bear",
        "date": 1677695604176,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "463",
        "rarity_score": 196.3772666408457,
        "rarity_level": "COMMON"
    },
    "1756": {
        "name": "Brawler Bears #1756",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1756.png",
        "dna": "70df316b7776f5d061db124e5c3d8521a3d6d397",
        "edition": "1756",
        "type": "Brown Bear",
        "date": 1677695605038,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 52,
        "maxHealth": 301,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1756",
        "rarity_score": 136.81635653942885,
        "rarity_level": "COMMON"
    },
    "2268": {
        "name": "Brawler Bears #2268",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2268.png",
        "dna": "84eaf20dda9f347b84cf7a4181542de996565ea7",
        "edition": "2268",
        "type": "Brown Bear",
        "date": 1677695605932,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2268",
        "rarity_score": 208.4220771866098,
        "rarity_level": "COMMON"
    },
    "1260": {
        "name": "Brawler Bears #1260",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1260.png",
        "dna": "e2c418941257d59d1f2bc5c86a4e0ffd8b7828b9",
        "edition": "1260",
        "type": "Galactic Cyborg",
        "date": 1677695607060,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1260",
        "rarity_score": 273.4670058972994,
        "rarity_level": "COMMON"
    },
    "23": {
        "name": "Brawler Bears #23",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/23.png",
        "dna": "3f74751afb7b91b50a9cb2358cbbdb8831e6fa9e",
        "edition": "23",
        "type": "Panda",
        "date": 1677695607663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 24,
        "maxHealth": 141,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "23",
        "rarity_score": 178.3755463234313,
        "rarity_level": "COMMON"
    },
    "107": {
        "name": "Brawler Bears #107",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/107.png",
        "dna": "16e6466f0c3abaa0139d7e4e4d38cd47487b6147",
        "edition": "107",
        "type": "Panda",
        "date": 1677695608361,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 30,
        "maxHealth": 175,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "107",
        "rarity_score": 210.91929198442708,
        "rarity_level": "COMMON"
    },
    "2266": {
        "name": "Brawler Bears #2266",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2266.png",
        "dna": "dd9623e0faa4e2033ccba68d6fe570e623859c02",
        "edition": "2266",
        "type": "Galaxy",
        "date": 1677695609287,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "2266",
        "rarity_score": 203.16707351213026,
        "rarity_level": "COMMON"
    },
    "1373": {
        "name": "Brawler Bears #1373",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1373.png",
        "dna": "cbed9b7cdfbd1b77d5e9cb25022fd458e37b5a79",
        "edition": "1373",
        "type": "Galaxy",
        "date": 1677695610136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1373",
        "rarity_score": 175.53017981411145,
        "rarity_level": "COMMON"
    },
    "625": {
        "name": "Brawler Bears #625",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/625.png",
        "dna": "05f4dcb00f83af8af52a7f959eebb04fc3314652",
        "edition": "625",
        "type": "Galaxy",
        "date": 1677695611039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "625",
        "rarity_score": 197.92538761991926,
        "rarity_level": "COMMON"
    },
    "267": {
        "name": "Brawler Bears #267",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/267.png",
        "dna": "d20ba1f3f285b13dafbe155731c44eec7fd4e59b",
        "edition": "267",
        "type": "Galaxy",
        "date": 1677695612358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "267",
        "rarity_score": 282.26657234153487,
        "rarity_level": "RARE"
    },
    "2114": {
        "name": "Brawler Bears #2114",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2114.png",
        "dna": "3d3378b3f696a7220ca4d491f515bc00ab536fcf",
        "edition": "2114",
        "type": "Brown Bear",
        "date": 1677695613541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "2114",
        "rarity_score": 295.49935946470976,
        "rarity_level": "RARE"
    },
    "1987": {
        "name": "Brawler Bears #1987",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1987.png",
        "dna": "d96812b8c267f720f328f9b67efd0d19ded899ab",
        "edition": "1987",
        "type": "Black Bear",
        "date": 1677695614272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 20,
        "maxHealth": 118,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1987",
        "rarity_score": 269.27491948628426,
        "rarity_level": "COMMON"
    },
    "3": {
        "name": "Brawler Bears #3",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/3.png",
        "dna": "c18ce442d64cbf5cf397ea761398196370459e76",
        "edition": "3",
        "type": "Ice",
        "date": 1677695615174,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "3",
        "rarity_score": 228.2493483144959,
        "rarity_level": "COMMON"
    },
    "1979": {
        "name": "Brawler Bears #1979",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1979.png",
        "dna": "5df3a9464d7cf2f1290720c500b8eb2da2c73491",
        "edition": "1979",
        "type": "Ice",
        "date": 1677695616748,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1979",
        "rarity_score": 268.33255953018244,
        "rarity_level": "COMMON"
    },
    "2213": {
        "name": "Brawler Bears #2213",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2213.png",
        "dna": "7544cf12977035a4a66cc4af00e0f1febd6f7648",
        "edition": "2213",
        "type": "Galaxy",
        "date": 1677695618620,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "2213",
        "rarity_score": 264.2686448863273,
        "rarity_level": "COMMON"
    },
    "1113": {
        "name": "Brawler Bears #1113",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1113.png",
        "dna": "00f203ec26819f12c4b97b79be60465e375c50e5",
        "edition": "1113",
        "type": "Ice",
        "date": 1677695620254,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1113",
        "rarity_score": 201.62967703779506,
        "rarity_level": "COMMON"
    },
    "1296": {
        "name": "Brawler Bears #1296",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1296.png",
        "dna": "afbf8f9c67ad9fbd274a0166b14d67df22fa2971",
        "edition": "1296",
        "type": "Ice",
        "date": 1677695621904,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1296",
        "rarity_score": 257.0765537820622,
        "rarity_level": "COMMON"
    },
    "1079": {
        "name": "Brawler Bears #1079",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1079.png",
        "dna": "8a4aa7d79213ff9f555cb2d9a6ea3b4cba26c79a",
        "edition": "1079",
        "type": "Black Bear",
        "date": 1677695622680,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1079",
        "rarity_score": 229.06472341213194,
        "rarity_level": "COMMON"
    },
    "1473": {
        "name": "Brawler Bears #1473",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1473.png",
        "dna": "0275e59e9ed9576f0d78390d6ff3e19e126cfb26",
        "edition": "1473",
        "type": "Galaxy",
        "date": 1677695623899,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1473",
        "rarity_score": 214.46970157701875,
        "rarity_level": "COMMON"
    },
    "1281": {
        "name": "Brawler Bears #1281",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1281.png",
        "dna": "aa60e6c7f239c0e3f1f1cdce9e133392edda37b4",
        "edition": "1281",
        "type": "Galactic Cyborg",
        "date": 1677695625113,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 42,
        "maxHealth": 241,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1281",
        "rarity_score": 123.02221398955328,
        "rarity_level": "COMMON"
    },
    "1795": {
        "name": "Brawler Bears #1795",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1795.png",
        "dna": "ed4754b920fbaebcce9dfe090ef9fee6cfdabd05",
        "edition": "1795",
        "type": "Ice",
        "date": 1677695626672,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 31,
        "maxHealth": 181,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1795",
        "rarity_score": 206.1639880073103,
        "rarity_level": "COMMON"
    },
    "919": {
        "name": "Brawler Bears #919",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/919.png",
        "dna": "79daf5f46ac0c11cb6468d72d52325eace304c84",
        "edition": "919",
        "type": "Galaxy",
        "date": 1677695627736,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 47,
        "maxHealth": 270,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "919",
        "rarity_score": 133.6588762363808,
        "rarity_level": "COMMON"
    },
    "1530": {
        "name": "Brawler Bears #1530",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1530.png",
        "dna": "1fb7817548d43f435c1054896a9a4f75e4ef4819",
        "edition": "1530",
        "type": "Cheetah",
        "date": 1677695629063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1530",
        "rarity_score": 269.56446419932047,
        "rarity_level": "COMMON"
    },
    "1494": {
        "name": "Brawler Bears #1494",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1494.png",
        "dna": "d3ddd861c71162724c3d31adfc479acfb254a612",
        "edition": "1494",
        "type": "Acid Trip",
        "date": 1677695629775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1494",
        "rarity_score": 172.41074235310182,
        "rarity_level": "COMMON"
    },
    "2176": {
        "name": "Brawler Bears #2176",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2176.png",
        "dna": "2dc034f9b242b9a9aabfd983606b1e980d8afaba",
        "edition": "2176",
        "type": "Galaxy",
        "date": 1677695631436,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "2176",
        "rarity_score": 299.40061439703,
        "rarity_level": "RARE"
    },
    "1125": {
        "name": "Brawler Bears #1125",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1125.png",
        "dna": "072d1296ecebf66cdd3bd626bcdebb4f401af0b4",
        "edition": "1125",
        "type": "Black Bear",
        "date": 1677695632971,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1125",
        "rarity_score": 280.5108290569019,
        "rarity_level": "RARE"
    },
    "777": {
        "name": "Brawler Bears #777",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/777.png",
        "dna": "ef273ea30b7bc25970fed06b9a7471c6953c3eb2",
        "edition": "777",
        "type": "Acid Trip",
        "date": 1677695634078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "777",
        "rarity_score": 290.29342245338836,
        "rarity_level": "RARE"
    },
    "203": {
        "name": "Brawler Bears #203",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/203.png",
        "dna": "0afd7cf6c97e2387def541bcb33b24cb4f8a49db",
        "edition": "203",
        "type": "Galactic Cyborg",
        "date": 1677695635754,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "203",
        "rarity_score": 308.35239111931253,
        "rarity_level": "RARE"
    },
    "1719": {
        "name": "Brawler Bears #1719",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1719.png",
        "dna": "c558f92f3f151e51265a4af98234aac16243256b",
        "edition": "1719",
        "type": "Ice",
        "date": 1677695637261,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1719",
        "rarity_score": 268.4503753308838,
        "rarity_level": "COMMON"
    },
    "758": {
        "name": "Brawler Bears #758",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/758.png",
        "dna": "7ee73934ab666e9b5818adbd3d6073fdb3a335f6",
        "edition": "758",
        "type": "Galaxy",
        "date": 1677695638896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "758",
        "rarity_score": 261.1460349857342,
        "rarity_level": "COMMON"
    },
    "606": {
        "name": "Brawler Bears #606",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/606.png",
        "dna": "fcba9420acad8321a5519c202524bb0293b0eb77",
        "edition": "606",
        "type": "Deep Martian Cyborg",
        "date": 1677695639605,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "606",
        "rarity_score": 229.94362586111865,
        "rarity_level": "COMMON"
    },
    "754": {
        "name": "Brawler Bears #754",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/754.png",
        "dna": "49e8a2b9ff93d25cca1187e63a3b71a6348e4574",
        "edition": "754",
        "type": "Cheetah",
        "date": 1677695641251,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 29,
        "maxHealth": 165,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "754",
        "rarity_score": 225.78986909971545,
        "rarity_level": "COMMON"
    },
    "2231": {
        "name": "Brawler Bears #2231",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2231.png",
        "dna": "b39e09a138653ac0e54cbb9a307103e5176d31e0",
        "edition": "2231",
        "type": "Brown Bear",
        "date": 1677695642072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "2231",
        "rarity_score": 264.41713439187816,
        "rarity_level": "COMMON"
    },
    "609": {
        "name": "Brawler Bears #609",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/609.png",
        "dna": "47e23ea9e5e1f8e78e8589497186ed8bd314723e",
        "edition": "609",
        "type": "Ice",
        "date": 1677695643190,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "609",
        "rarity_score": 265.26901658704844,
        "rarity_level": "COMMON"
    },
    "1132": {
        "name": "Brawler Bears #1132",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1132.png",
        "dna": "0b3d7e79660169fec4886d98c86efcef472a6c47",
        "edition": "1132",
        "type": "Lava",
        "date": 1677695644147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 12,
        "maxHealth": 70,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1132",
        "rarity_score": 295.93986825471285,
        "rarity_level": "RARE"
    },
    "2061": {
        "name": "Brawler Bears #2061",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2061.png",
        "dna": "49e4c7e603b043ed602aa13932fd5c24cb791458",
        "edition": "2061",
        "type": "Acid Trip",
        "date": 1677695645644,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "2061",
        "rarity_score": 185.64423866750317,
        "rarity_level": "COMMON"
    },
    "316": {
        "name": "Brawler Bears #316",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/316.png",
        "dna": "1e93a56d258b45667c43eeb6d8635080938ee36d",
        "edition": "316",
        "type": "Galactic Cyborg",
        "date": 1677695647342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "316",
        "rarity_score": 271.03368552256654,
        "rarity_level": "COMMON"
    },
    "1102": {
        "name": "Brawler Bears #1102",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1102.png",
        "dna": "edc7865d369a864937d648801057399d48657298",
        "edition": "1102",
        "type": "Black Bear",
        "date": 1677695648234,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1102",
        "rarity_score": 202.91055304212333,
        "rarity_level": "COMMON"
    },
    "846": {
        "name": "Brawler Bears #846",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/846.png",
        "dna": "ebfa86aaf9050f601456b941e6a3bf6612ee7ea6",
        "edition": "846",
        "type": "Brown Bear",
        "date": 1677695649240,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "846",
        "rarity_score": 291.9016354602016,
        "rarity_level": "RARE"
    },
    "209": {
        "name": "Brawler Bears #209",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/209.png",
        "dna": "9869a8f6b8c759195b4e34019f6f23abaee929a1",
        "edition": "209",
        "type": "Acid Trip",
        "date": 1677695650572,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "209",
        "rarity_score": 281.12713232559975,
        "rarity_level": "RARE"
    },
    "1698": {
        "name": "Brawler Bears #1698",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1698.png",
        "dna": "e7a18325e68eeb539e60ad70cc8c2595c223c3b9",
        "edition": "1698",
        "type": "Black Bear",
        "date": 1677695651858,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1698",
        "rarity_score": 172.77342533725783,
        "rarity_level": "COMMON"
    },
    "368": {
        "name": "Brawler Bears #368",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/368.png",
        "dna": "3a15f3ed139c62f2fb4c9c0797220fc22fba9b63",
        "edition": "368",
        "type": "Deep Martian Cyborg",
        "date": 1677695653152,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "368",
        "rarity_score": 232.60447819800942,
        "rarity_level": "COMMON"
    },
    "2192": {
        "name": "Brawler Bears #2192",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2192.png",
        "dna": "740492db75f913b201725daacdbba474a5091c9c",
        "edition": "2192",
        "type": "Galaxy",
        "date": 1677695654191,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 46,
        "maxHealth": 268,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2192",
        "rarity_score": 134.16001924250355,
        "rarity_level": "COMMON"
    },
    "1907": {
        "name": "Brawler Bears #1907",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1907.png",
        "dna": "0afd86406f84a24f841b1e1a99ae7a910174d6c0",
        "edition": "1907",
        "type": "Panda",
        "date": 1677695655641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1907",
        "rarity_score": 201.98142365278585,
        "rarity_level": "COMMON"
    },
    "420": {
        "name": "Brawler Bears #420",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/420.png",
        "dna": "5ba2302d8be47397cfc924b80316347f61c7c8b8",
        "edition": "420",
        "type": "Cheetah",
        "date": 1677695656724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "420",
        "rarity_score": 232.30797703532022,
        "rarity_level": "COMMON"
    },
    "243": {
        "name": "Brawler Bears #243",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/243.png",
        "dna": "d0bb59e84a97aba390e8169488d36d931cab1fcc",
        "edition": "243",
        "type": "Black Bear",
        "date": 1677695657525,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "243",
        "rarity_score": 283.6501963339591,
        "rarity_level": "RARE"
    },
    "751": {
        "name": "Brawler Bears #751",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/751.png",
        "dna": "cc8aa263c1de822c85c229ef91525c696355eda3",
        "edition": "751",
        "type": "Ice",
        "date": 1677695658508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 46,
        "maxHealth": 268,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "751",
        "rarity_score": 151.56343751146008,
        "rarity_level": "COMMON"
    },
    "2045": {
        "name": "Brawler Bears #2045",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2045.png",
        "dna": "8725f6e657d7a54c729e78593de51ab8047be39e",
        "edition": "2045",
        "type": "Cheetah",
        "date": 1677695660147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 50,
        "maxHealth": 289,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2045",
        "rarity_score": 176.38412887022787,
        "rarity_level": "COMMON"
    },
    "2019": {
        "name": "Brawler Bears #2019",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2019.png",
        "dna": "1592aa853169d05b5bcffbdfd4b06085da73c28c",
        "edition": "2019",
        "type": "Black Bear",
        "date": 1677695660760,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 68,
        "maxHealth": 390,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2019",
        "rarity_score": 83.14386240234624,
        "rarity_level": "COMMON"
    },
    "2071": {
        "name": "Brawler Bears #2071",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2071.png",
        "dna": "46c5ada3d51192dbaf68eb4a31399ba602eb80de",
        "edition": "2071",
        "type": "Brown Bear",
        "date": 1677695661612,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2071",
        "rarity_score": 129.99869635521304,
        "rarity_level": "COMMON"
    },
    "2243": {
        "name": "Brawler Bears #2243",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2243.png",
        "dna": "d397de27cd17645b5e869401d92dcd23504a4151",
        "edition": "2243",
        "type": "Cheetah",
        "date": 1677695662452,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 12,
        "maxHealth": 71,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "2243",
        "rarity_score": 322.2567210581451,
        "rarity_level": "RARE"
    },
    "1282": {
        "name": "Brawler Bears #1282",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1282.png",
        "dna": "451c21dc771ee06c543bd7316e65e5efb34c6135",
        "edition": "1282",
        "type": "Brown Bear",
        "date": 1677695663217,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 59,
        "maxHealth": 341,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1282",
        "rarity_score": 164.3780643967152,
        "rarity_level": "COMMON"
    },
    "839": {
        "name": "Brawler Bears #839",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/839.png",
        "dna": "83176188c2bc62c0f40ad0f92802bb621552cc2d",
        "edition": "839",
        "type": "Brown Bear",
        "date": 1677695664741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "839",
        "rarity_score": 268.79418462970733,
        "rarity_level": "COMMON"
    },
    "904": {
        "name": "Brawler Bears #904",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/904.png",
        "dna": "e9fec5c2b47be74308893e5f62c797d59de3ac63",
        "edition": "904",
        "type": "Cheetah",
        "date": 1677695665698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 50,
        "maxHealth": 287,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "904",
        "rarity_score": 174.26796133385034,
        "rarity_level": "COMMON"
    },
    "1801": {
        "name": "Brawler Bears #1801",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1801.png",
        "dna": "205461e552b0eb0d5208179d087651b81774d17f",
        "edition": "1801",
        "type": "Galaxy",
        "date": 1677695667563,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 41,
        "maxHealth": 239,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1801",
        "rarity_score": 129.88081820126544,
        "rarity_level": "COMMON"
    },
    "1889": {
        "name": "Brawler Bears #1889",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1889.png",
        "dna": "9e7a439814484dedda53e778100c00506151153d",
        "edition": "1889",
        "type": "Ice",
        "date": 1677695668919,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 46,
        "maxHealth": 265,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1889",
        "rarity_score": 140.73716899403715,
        "rarity_level": "COMMON"
    },
    "1618": {
        "name": "Brawler Bears #1618",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1618.png",
        "dna": "c259f85f388934b35ef6f43eb30328d087a4980d",
        "edition": "1618",
        "type": "Brown Bear",
        "date": 1677695670031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1618",
        "rarity_score": 280.70984780044756,
        "rarity_level": "RARE"
    },
    "1274": {
        "name": "Brawler Bears #1274",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1274.png",
        "dna": "d1fedc9842b058a5b176aa712beb4af129e892a9",
        "edition": "1274",
        "type": "Brown Bear",
        "date": 1677695671640,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1274",
        "rarity_score": 298.3005233555589,
        "rarity_level": "RARE"
    },
    "1084": {
        "name": "Brawler Bears #1084",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1084.png",
        "dna": "9a32b07377bb771bf1c7b23589066a31cfbf82f8",
        "edition": "1084",
        "type": "Cheetah",
        "date": 1677695673045,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 13,
        "maxHealth": 76,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1084",
        "rarity_score": 287.10024463823095,
        "rarity_level": "RARE"
    },
    "2257": {
        "name": "Brawler Bears #2257",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2257.png",
        "dna": "225da16b1b27f0cd97dea495a026e815f63dbc5a",
        "edition": "2257",
        "type": "Panda",
        "date": 1677695673635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 40,
        "maxHealth": 232,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "2257",
        "rarity_score": 119.6684140746138,
        "rarity_level": "COMMON"
    },
    "2067": {
        "name": "Brawler Bears #2067",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2067.png",
        "dna": "d922b50eede415196974cba30a4b301415127632",
        "edition": "2067",
        "type": "Brown Bear",
        "date": 1677695674361,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 48,
        "maxHealth": 277,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "2067",
        "rarity_score": 124.88135559460956,
        "rarity_level": "COMMON"
    },
    "1915": {
        "name": "Brawler Bears #1915",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1915.png",
        "dna": "2b0c72e556e887f36eb5e97fb7facb58cd158fe7",
        "edition": "1915",
        "type": "Galactic Cyborg",
        "date": 1677695675984,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 52,
        "maxHealth": 298,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1915",
        "rarity_score": 168.77919107573376,
        "rarity_level": "COMMON"
    },
    "1775": {
        "name": "Brawler Bears #1775",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1775.png",
        "dna": "0bcdfdda62ad970c27e1d4bc633d3207aab99279",
        "edition": "1775",
        "type": "Galactic Cyborg",
        "date": 1677695677140,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 25,
        "maxHealth": 145,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1775",
        "rarity_score": 196.49982407215404,
        "rarity_level": "COMMON"
    },
    "720": {
        "name": "Brawler Bears #720",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/720.png",
        "dna": "8b97fc9e6a864d9e0953b84ee4ae0aecf20cca3c",
        "edition": "720",
        "type": "Brown Bear",
        "date": 1677695678215,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "720",
        "rarity_score": 272.36817404776417,
        "rarity_level": "COMMON"
    },
    "842": {
        "name": "Brawler Bears #842",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/842.png",
        "dna": "22ab4c08f3241b5b1888bb13ce785e57bff1632b",
        "edition": "842",
        "type": "Deep Martian Cyborg",
        "date": 1677695679531,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "842",
        "rarity_score": 205.33872724127676,
        "rarity_level": "COMMON"
    },
    "931": {
        "name": "Brawler Bears #931",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/931.png",
        "dna": "047d1ef709e05bee95bb70eb313441d354180397",
        "edition": "931",
        "type": "Galactic Cyborg",
        "date": 1677695680312,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "931",
        "rarity_score": 166.4629043588974,
        "rarity_level": "COMMON"
    },
    "1974": {
        "name": "Brawler Bears #1974",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1974.png",
        "dna": "f0500b5ccaca8b829082903f2ea1d62960462c89",
        "edition": "1974",
        "type": "Brown Bear",
        "date": 1677695681170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1974",
        "rarity_score": 175.8077212489603,
        "rarity_level": "COMMON"
    },
    "1902": {
        "name": "Brawler Bears #1902",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1902.png",
        "dna": "b60c94ca4bd2c75953a40fdbf30e8b138de6de77",
        "edition": "1902",
        "type": "Panda",
        "date": 1677695682293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1902",
        "rarity_score": 188.84671495712945,
        "rarity_level": "COMMON"
    },
    "1426": {
        "name": "Brawler Bears #1426",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1426.png",
        "dna": "43bb69690aec40c7c5a4dee49184321fbcb2b4aa",
        "edition": "1426",
        "type": "Brown Bear",
        "date": 1677695682968,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1426",
        "rarity_score": 271.09977935441964,
        "rarity_level": "COMMON"
    },
    "623": {
        "name": "Brawler Bears #623",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/623.png",
        "dna": "c634606207793fdc1f732182748bd376c427cc14",
        "edition": "623",
        "type": "Brown Bear",
        "date": 1677695684453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "623",
        "rarity_score": 239.33986649023677,
        "rarity_level": "COMMON"
    },
    "1891": {
        "name": "Brawler Bears #1891",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1891.png",
        "dna": "11660465fbb10638c123630946d5162e1e247eeb",
        "edition": "1891",
        "type": "Black Bear",
        "date": 1677695685588,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1891",
        "rarity_score": 248.26622289006718,
        "rarity_level": "COMMON"
    },
    "1807": {
        "name": "Brawler Bears #1807",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1807.png",
        "dna": "170233491a4d82867deedaa7cf87e6ce19c86b7b",
        "edition": "1807",
        "type": "Ice",
        "date": 1677695686397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 46,
        "maxHealth": 264,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1807",
        "rarity_score": 145.02406198521555,
        "rarity_level": "COMMON"
    },
    "313": {
        "name": "Brawler Bears #313",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/313.png",
        "dna": "d2c40e711bf6eec0505e9a3b36f1a411c0b2737a",
        "edition": "313",
        "type": "Black Bear",
        "date": 1677695687409,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "313",
        "rarity_score": 264.9368181237008,
        "rarity_level": "COMMON"
    },
    "2286": {
        "name": "Brawler Bears #2286",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2286.png",
        "dna": "a031b56c4666bb3dbd3447e4cc16d98d244c5589",
        "edition": "2286",
        "type": "Brown Bear",
        "date": 1677695688559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "2286",
        "rarity_score": 247.6969772896109,
        "rarity_level": "COMMON"
    },
    "709": {
        "name": "Brawler Bears #709",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/709.png",
        "dna": "d049e43f98975806fa04b12008796386e0e0485c",
        "edition": "709",
        "type": "Black Bear",
        "date": 1677695689367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "709",
        "rarity_score": 192.6373762689974,
        "rarity_level": "COMMON"
    },
    "2208": {
        "name": "Brawler Bears #2208",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2208.png",
        "dna": "21a3d438afab90b4cefaf1fe6b0e7cb29c243449",
        "edition": "2208",
        "type": "Black Bear",
        "date": 1677695691169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 41,
        "maxHealth": 237,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2208",
        "rarity_score": 274.78033449928756,
        "rarity_level": "COMMON"
    },
    "141": {
        "name": "Brawler Bears #141",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/141.png",
        "dna": "284d738d25b4c9a3add2896dc38499a2007546ea",
        "edition": "141",
        "type": "Galactic Cyborg",
        "date": 1677695692226,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "141",
        "rarity_score": 301.0407976219231,
        "rarity_level": "RARE"
    },
    "1437": {
        "name": "Brawler Bears #1437",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1437.png",
        "dna": "c1aad2792421e6efd2f7bf6e4d43599ba06e190e",
        "edition": "1437",
        "type": "Brown Bear",
        "date": 1677695693632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1437",
        "rarity_score": 277.555092604248,
        "rarity_level": "RARE"
    },
    "2233": {
        "name": "Brawler Bears #2233",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2233.png",
        "dna": "799db9684cb94d3d5b48f8e915c838878d424b66",
        "edition": "2233",
        "type": "Brown Bear",
        "date": 1677695694384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 22,
        "maxHealth": 131,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "2233",
        "rarity_score": 270.86460187664164,
        "rarity_level": "COMMON"
    },
    "1890": {
        "name": "Brawler Bears #1890",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1890.png",
        "dna": "1fc047003210718fba8fb8ad38e6c80aea413bbc",
        "edition": "1890",
        "type": "Brown Bear",
        "date": 1677695695489,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 52,
        "maxHealth": 301,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1890",
        "rarity_score": 160.03524920888765,
        "rarity_level": "COMMON"
    },
    "1916": {
        "name": "Brawler Bears #1916",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1916.png",
        "dna": "5f23349af4fcb17ecb12dc2f3f56b6c9d33f46e8",
        "edition": "1916",
        "type": "Brown Bear",
        "date": 1677695696353,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1916",
        "rarity_score": 304.19403330059873,
        "rarity_level": "RARE"
    },
    "1257": {
        "name": "Brawler Bears #1257",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1257.png",
        "dna": "72b0935a3689aca0d0ad65c2518f4a3ac55a0a9b",
        "edition": "1257",
        "type": "Ice",
        "date": 1677695697357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1257",
        "rarity_score": 209.28581544865045,
        "rarity_level": "COMMON"
    },
    "1939": {
        "name": "Brawler Bears #1939",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1939.png",
        "dna": "1d717ee1a9e8d8316977e954a6eba6c1551308e8",
        "edition": "1939",
        "type": "Brown Bear",
        "date": 1677695698225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1939",
        "rarity_score": 194.8323477172557,
        "rarity_level": "COMMON"
    },
    "1763": {
        "name": "Brawler Bears #1763",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1763.png",
        "dna": "f4e0bde214554e47a1323718bcd9f511837a92b7",
        "edition": "1763",
        "type": "Ice",
        "date": 1677695700182,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1763",
        "rarity_score": 276.1709481799666,
        "rarity_level": "RARE"
    },
    "1383": {
        "name": "Brawler Bears #1383",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1383.png",
        "dna": "18c0c24206c612fe18925bb3b4d629b4829f7aed",
        "edition": "1383",
        "type": "Black Bear",
        "date": 1677695700870,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 20,
        "maxHealth": 116,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1383",
        "rarity_score": 276.43564829463764,
        "rarity_level": "RARE"
    },
    "766": {
        "name": "Brawler Bears #766",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/766.png",
        "dna": "d5e364e878970f551d0151677612dd3cc61c08e0",
        "edition": "766",
        "type": "Cheetah",
        "date": 1677695701604,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "766",
        "rarity_score": 304.2108963761941,
        "rarity_level": "RARE"
    },
    "1221": {
        "name": "Brawler Bears #1221",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1221.png",
        "dna": "fb7a168270c57dd6dcd015b4ed28a05655d84a45",
        "edition": "1221",
        "type": "Ice",
        "date": 1677695702570,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 52,
        "maxHealth": 301,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1221",
        "rarity_score": 164.51343229814512,
        "rarity_level": "COMMON"
    },
    "542": {
        "name": "Brawler Bears #542",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/542.png",
        "dna": "26e96c5b87ba691016a58826f440a0c851cc84e2",
        "edition": "542",
        "type": "Brown Bear",
        "date": 1677695703379,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "542",
        "rarity_score": 149.9054458022058,
        "rarity_level": "COMMON"
    },
    "397": {
        "name": "Brawler Bears #397",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/397.png",
        "dna": "5a2f0ef61517968f6e0f0b252512ddcf65afe65e",
        "edition": "397",
        "type": "Black Bear",
        "date": 1677695704776,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 58,
        "maxHealth": 331,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "397",
        "rarity_score": 172.37350770048897,
        "rarity_level": "COMMON"
    },
    "328": {
        "name": "Brawler Bears #328",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/328.png",
        "dna": "14a46bddfe573e0c9221fbb9ed4d0230070d01a2",
        "edition": "328",
        "type": "Deep Martian Cyborg",
        "date": 1677695705715,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 31,
        "maxHealth": 181,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "328",
        "rarity_score": 270.2595094075799,
        "rarity_level": "COMMON"
    },
    "1963": {
        "name": "Brawler Bears #1963",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1963.png",
        "dna": "32a8da54d21454a7a8a65967d87f1ecdb74c31df",
        "edition": "1963",
        "type": "Panda",
        "date": 1677695706587,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1963",
        "rarity_score": 237.88394116365814,
        "rarity_level": "COMMON"
    },
    "1854": {
        "name": "Brawler Bears #1854",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1854.png",
        "dna": "3c05e9199a98f988f965fcf350224140dbc0811a",
        "edition": "1854",
        "type": "Deep Martian Cyborg",
        "date": 1677695707505,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1854",
        "rarity_score": 215.25950495032754,
        "rarity_level": "COMMON"
    },
    "647": {
        "name": "Brawler Bears #647",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/647.png",
        "dna": "4f4a83c959e27922d8ee3a223d026e7cd1a6e9d9",
        "edition": "647",
        "type": "Black Bear",
        "date": 1677695708321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 47,
        "maxHealth": 273,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "647",
        "rarity_score": 122.01549192391867,
        "rarity_level": "COMMON"
    },
    "2063": {
        "name": "Brawler Bears #2063",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2063.png",
        "dna": "1192ed0c47367e9fb69267856981e09b7ab6d553",
        "edition": "2063",
        "type": "Brown Bear",
        "date": 1677695709129,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "2063",
        "rarity_score": 297.3730847148951,
        "rarity_level": "RARE"
    },
    "1206": {
        "name": "Brawler Bears #1206",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1206.png",
        "dna": "6753a24bb967199bd7fef4094e7ae4f223965731",
        "edition": "1206",
        "type": "Galactic Cyborg",
        "date": 1677695710468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1206",
        "rarity_score": 293.4628950681523,
        "rarity_level": "RARE"
    },
    "1290": {
        "name": "Brawler Bears #1290",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1290.png",
        "dna": "e99f4394007b13f4e48cf21f91b15ad3a20d8c0f",
        "edition": "1290",
        "type": "Panda",
        "date": 1677695711580,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 51,
        "maxHealth": 292,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1290",
        "rarity_score": 201.43809734657327,
        "rarity_level": "COMMON"
    },
    "554": {
        "name": "Brawler Bears #554",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/554.png",
        "dna": "2a84f058a5f1992e0c24afdc59c4dd7a1b8dc479",
        "edition": "554",
        "type": "Brown Bear",
        "date": 1677695713158,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "554",
        "rarity_score": 300.71443313203156,
        "rarity_level": "RARE"
    },
    "1159": {
        "name": "Brawler Bears #1159",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1159.png",
        "dna": "3eea7b05802f091a56c3dc31f9e6f328143a9a12",
        "edition": "1159",
        "type": "Black Bear",
        "date": 1677695714681,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1159",
        "rarity_score": 204.45953084862458,
        "rarity_level": "COMMON"
    },
    "2183": {
        "name": "Brawler Bears #2183",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2183.png",
        "dna": "ba2da3621a0b09bcca2080e37e80ae5a7a3f9574",
        "edition": "2183",
        "type": "Black Bear",
        "date": 1677695715495,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 19,
        "maxHealth": 111,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "2183",
        "rarity_score": 315.9966116447594,
        "rarity_level": "RARE"
    },
    "670": {
        "name": "Brawler Bears #670",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/670.png",
        "dna": "0d27165ab7a60236544ac42e4a4d6d2ea22e1062",
        "edition": "670",
        "type": "Black Bear",
        "date": 1677695716320,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 42,
        "maxHealth": 242,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "670",
        "rarity_score": 248.93842699426284,
        "rarity_level": "COMMON"
    },
    "2075": {
        "name": "Brawler Bears #2075",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2075.png",
        "dna": "a9955acdc8ef5ffe22e89fdc81b75ccafb7c943f",
        "edition": "2075",
        "type": "Galaxy",
        "date": 1677695717600,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 36,
        "maxHealth": 211,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2075",
        "rarity_score": 252.93099109557153,
        "rarity_level": "COMMON"
    },
    "1898": {
        "name": "Brawler Bears #1898",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1898.png",
        "dna": "eaed3faf399b8e1c5d5d5d8441417de6fc8d37f7",
        "edition": "1898",
        "type": "Galactic Cyborg",
        "date": 1677695719042,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1898",
        "rarity_score": 255.04413546631065,
        "rarity_level": "COMMON"
    },
    "1081": {
        "name": "Brawler Bears #1081",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1081.png",
        "dna": "2af94b2d6768c971da69ac56eb719a444d893fd9",
        "edition": "1081",
        "type": "Panda",
        "date": 1677695719835,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1081",
        "rarity_score": 161.75918866185893,
        "rarity_level": "COMMON"
    },
    "334": {
        "name": "Brawler Bears #334",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/334.png",
        "dna": "6a391a7a86a1f87c2d4f082f584f4ba01695ff12",
        "edition": "334",
        "type": "Panda",
        "date": 1677695721120,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 24,
        "maxHealth": 141,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "334",
        "rarity_score": 171.99635051389262,
        "rarity_level": "COMMON"
    },
    "129": {
        "name": "Brawler Bears #129",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/129.png",
        "dna": "26029ff1efc5b494d5e72f1416f81444d76b5928",
        "edition": "129",
        "type": "Brown Bear",
        "date": 1677695722096,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "129",
        "rarity_score": 259.0567655241048,
        "rarity_level": "COMMON"
    },
    "840": {
        "name": "Brawler Bears #840",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/840.png",
        "dna": "afca9eca0629b6e16d04e4d5b66df725cfcee0e8",
        "edition": "840",
        "type": "Galactic Cyborg",
        "date": 1677695723153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "840",
        "rarity_score": 185.18662753009605,
        "rarity_level": "COMMON"
    },
    "2073": {
        "name": "Brawler Bears #2073",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2073.png",
        "dna": "18b29291b6050e5adcd2d293d43c0a1434280768",
        "edition": "2073",
        "type": "Cheetah",
        "date": 1677695723899,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 50,
        "maxHealth": 286,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2073",
        "rarity_score": 165.1116704713833,
        "rarity_level": "COMMON"
    },
    "2128": {
        "name": "Brawler Bears #2128",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2128.png",
        "dna": "2fea7b2b8ae34e88e2149b655588c129031b45af",
        "edition": "2128",
        "type": "Galaxy",
        "date": 1677695725316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "2128",
        "rarity_score": 227.5955015451415,
        "rarity_level": "COMMON"
    },
    "159": {
        "name": "Brawler Bears #159",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/159.png",
        "dna": "abd12b0ded09bea6513e99bd6fc28b817f782658",
        "edition": "159",
        "type": "Panda",
        "date": 1677695726242,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 29,
        "maxHealth": 170,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "159",
        "rarity_score": 220.5425312334079,
        "rarity_level": "COMMON"
    },
    "1998": {
        "name": "Brawler Bears #1998",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1998.png",
        "dna": "1fd0522527006de243e4dd583cae0261c16930e1",
        "edition": "1998",
        "type": "Black Bear",
        "date": 1677695727024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 30,
        "maxHealth": 174,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1998",
        "rarity_score": 196.74522894851236,
        "rarity_level": "COMMON"
    },
    "1573": {
        "name": "Brawler Bears #1573",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1573.png",
        "dna": "613bac2b346c8fd94232a39a5aeed5cc77066962",
        "edition": "1573",
        "type": "Brown Bear",
        "date": 1677695728394,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1573",
        "rarity_score": 280.52947620369406,
        "rarity_level": "RARE"
    },
    "680": {
        "name": "Brawler Bears #680",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/680.png",
        "dna": "8321d5b4ff8bf592cc7636205f7ca16983d0e6d8",
        "edition": "680",
        "type": "Galactic Cyborg",
        "date": 1677695730060,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "680",
        "rarity_score": 219.54247718005465,
        "rarity_level": "COMMON"
    },
    "1327": {
        "name": "Brawler Bears #1327",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1327.png",
        "dna": "03e15b394c8c48c2917ad9d753751829c3156d15",
        "edition": "1327",
        "type": "Brown Bear",
        "date": 1677695731150,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1327",
        "rarity_score": 257.777942781446,
        "rarity_level": "COMMON"
    },
    "1797": {
        "name": "Brawler Bears #1797",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1797.png",
        "dna": "4101474efba57122e7a11cfaf18076a9824a9005",
        "edition": "1797",
        "type": "Black Bear",
        "date": 1677695732356,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1797",
        "rarity_score": 283.0091800676219,
        "rarity_level": "RARE"
    },
    "750": {
        "name": "Brawler Bears #750",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/750.png",
        "dna": "5179582b674ef168302187a6de9f1d299e7f1df4",
        "edition": "750",
        "type": "Galaxy",
        "date": 1677695733955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "750",
        "rarity_score": 254.49299852567023,
        "rarity_level": "COMMON"
    },
    "236": {
        "name": "Brawler Bears #236",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/236.png",
        "dna": "83ae1d73dc55c9d86407b00e990dc25fe3e8c417",
        "edition": "236",
        "type": "Brown Bear",
        "date": 1677695734806,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "236",
        "rarity_score": 168.29634994276043,
        "rarity_level": "COMMON"
    },
    "1041": {
        "name": "Brawler Bears #1041",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1041.png",
        "dna": "ca5b3b697127f025ab43a96d1ccf46baad9eff87",
        "edition": "1041",
        "type": "Ice",
        "date": 1677695736025,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1041",
        "rarity_score": 230.03123571279508,
        "rarity_level": "COMMON"
    },
    "2020": {
        "name": "Brawler Bears #2020",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2020.png",
        "dna": "637da9eea0c5fda1e1cd9f0bbbcd57bef3a0aabc",
        "edition": "2020",
        "type": "Galaxy",
        "date": 1677695737646,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2020",
        "rarity_score": 180.21626564038496,
        "rarity_level": "COMMON"
    },
    "1834": {
        "name": "Brawler Bears #1834",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1834.png",
        "dna": "e813dd8a527f35bea47eda241c91e1b5e8673894",
        "edition": "1834",
        "type": "Brown Bear",
        "date": 1677695738405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1834",
        "rarity_score": 229.40801455136094,
        "rarity_level": "COMMON"
    },
    "1885": {
        "name": "Brawler Bears #1885",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1885.png",
        "dna": "ac36e5d6a94dbf3f02ee065f3134f114fb7d26cc",
        "edition": "1885",
        "type": "Black Bear",
        "date": 1677695738997,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1885",
        "rarity_score": 206.4636487451073,
        "rarity_level": "COMMON"
    },
    "2034": {
        "name": "Brawler Bears #2034",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2034.png",
        "dna": "ed12e0d27669e9bff953fa68add7a589b7491733",
        "edition": "2034",
        "type": "Brown Bear",
        "date": 1677695739801,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2034",
        "rarity_score": 212.58182343349463,
        "rarity_level": "COMMON"
    },
    "1722": {
        "name": "Brawler Bears #1722",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1722.png",
        "dna": "14a1f2e1471e1a09204a3504b53a0b48b595452e",
        "edition": "1722",
        "type": "Galactic Cyborg",
        "date": 1677695740777,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1722",
        "rarity_score": 211.25702569235634,
        "rarity_level": "COMMON"
    },
    "1929": {
        "name": "Brawler Bears #1929",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1929.png",
        "dna": "c29f5b7dfdb452b7e98764c92d45d553ff5336a6",
        "edition": "1929",
        "type": "Ice",
        "date": 1677695741608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1929",
        "rarity_score": 298.2637103879926,
        "rarity_level": "RARE"
    },
    "1098": {
        "name": "Brawler Bears #1098",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1098.png",
        "dna": "a6ef6abec19b946cd4f07ff61ed0eeaf0767d211",
        "edition": "1098",
        "type": "Acid Trip",
        "date": 1677695742531,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1098",
        "rarity_score": 316.40215559233087,
        "rarity_level": "RARE"
    },
    "953": {
        "name": "Brawler Bears #953",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/953.png",
        "dna": "7f39fd85a8fb68ffb2f3daf7e561f215468f6576",
        "edition": "953",
        "type": "Ice",
        "date": 1677695744182,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "953",
        "rarity_score": 269.85093157578615,
        "rarity_level": "COMMON"
    },
    "1141": {
        "name": "Brawler Bears #1141",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1141.png",
        "dna": "374f12fc88651b5ad98ee2d4caaa8f120d05ccd7",
        "edition": "1141",
        "type": "Lava",
        "date": 1677695744958,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 49,
        "maxHealth": 281,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1141",
        "rarity_score": 202.0407826665927,
        "rarity_level": "COMMON"
    },
    "206": {
        "name": "Brawler Bears #206",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/206.png",
        "dna": "852aee1cede87a8f8da2268a3008f1c393a46a01",
        "edition": "206",
        "type": "Galactic Cyborg",
        "date": 1677695746388,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "206",
        "rarity_score": 298.7381322297805,
        "rarity_level": "RARE"
    },
    "513": {
        "name": "Brawler Bears #513",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/513.png",
        "dna": "455e891929b01e76d117020d80323233fe508e9e",
        "edition": "513",
        "type": "Black Bear",
        "date": 1677695747955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "513",
        "rarity_score": 250.23317852227225,
        "rarity_level": "COMMON"
    },
    "1027": {
        "name": "Brawler Bears #1027",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1027.png",
        "dna": "fb5f9795db35a50b12283bb78daf40a3dd769b74",
        "edition": "1027",
        "type": "Deep Martian Cyborg",
        "date": 1677695748956,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1027",
        "rarity_score": 264.7059604095293,
        "rarity_level": "COMMON"
    },
    "1510": {
        "name": "Brawler Bears #1510",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1510.png",
        "dna": "4aaacd322f0cd3637e8fde35e3a718486bdd5c3b",
        "edition": "1510",
        "type": "Brown Bear",
        "date": 1677695749792,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1510",
        "rarity_score": 308.23524513699857,
        "rarity_level": "RARE"
    },
    "281": {
        "name": "Brawler Bears #281",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/281.png",
        "dna": "bdc83f09986af93bf4ef1984bc3ac6caae5655cd",
        "edition": "281",
        "type": "Brown Bear",
        "date": 1677695751171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "281",
        "rarity_score": 285.0093169501489,
        "rarity_level": "RARE"
    },
    "2057": {
        "name": "Brawler Bears #2057",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2057.png",
        "dna": "db67a16675d8899d674cfab71c879e73856c2303",
        "edition": "2057",
        "type": "Panda",
        "date": 1677695752510,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 61,
        "maxHealth": 349,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2057",
        "rarity_score": 99.5976370172967,
        "rarity_level": "COMMON"
    },
    "667": {
        "name": "Brawler Bears #667",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/667.png",
        "dna": "447b848027157cbe5a61012e50e62075058627c2",
        "edition": "667",
        "type": "Galaxy",
        "date": 1677695753723,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "667",
        "rarity_score": 278.20491034411344,
        "rarity_level": "RARE"
    },
    "2134": {
        "name": "Brawler Bears #2134",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2134.png",
        "dna": "d572651c985441339798ed1fc731774e717504fa",
        "edition": "2134",
        "type": "Brown Bear",
        "date": 1677695755238,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "2134",
        "rarity_score": 277.63741642924765,
        "rarity_level": "RARE"
    },
    "681": {
        "name": "Brawler Bears #681",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/681.png",
        "dna": "8b68f72bd42c14570096b32718202aefda0e4f6b",
        "edition": "681",
        "type": "Brown Bear",
        "date": 1677695756286,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "681",
        "rarity_score": 335.08342339582197,
        "rarity_level": "RARE"
    },
    "1338": {
        "name": "Brawler Bears #1338",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1338.png",
        "dna": "0aaf7c9575fc4c784dce1fe9f435158dc6a6db2d",
        "edition": "1338",
        "type": "Black Bear",
        "date": 1677695757837,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1338",
        "rarity_score": 234.14519150928527,
        "rarity_level": "COMMON"
    },
    "2279": {
        "name": "Brawler Bears #2279",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2279.png",
        "dna": "76bbbc7fb7159d1511b68749a0d4de7fa9de5b94",
        "edition": "2279",
        "type": "Black Bear",
        "date": 1677695758470,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "2279",
        "rarity_score": 214.10496146814924,
        "rarity_level": "COMMON"
    },
    "1451": {
        "name": "Brawler Bears #1451",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1451.png",
        "dna": "70410cda4827f3e0f80fdf6495d9a6c496bdeded",
        "edition": "1451",
        "type": "Galaxy",
        "date": 1677695759420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1451",
        "rarity_score": 286.59505576987834,
        "rarity_level": "RARE"
    },
    "1691": {
        "name": "Brawler Bears #1691",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1691.png",
        "dna": "6e2eab356d00fdd3f3cd17f9ca992d9e37b802aa",
        "edition": "1691",
        "type": "Galaxy",
        "date": 1677695760925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1691",
        "rarity_score": 242.52719558241714,
        "rarity_level": "COMMON"
    },
    "2215": {
        "name": "Brawler Bears #2215",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2215.png",
        "dna": "6e2416a90d48fcfa48d98c5ce34e6652916bf1e8",
        "edition": "2215",
        "type": "Brown Bear",
        "date": 1677695761962,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2215",
        "rarity_score": 271.8815564811412,
        "rarity_level": "COMMON"
    },
    "483": {
        "name": "Brawler Bears #483",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/483.png",
        "dna": "d88c652f174916a374f3b07e6d2fd82b99563713",
        "edition": "483",
        "type": "Brown Bear",
        "date": 1677695763078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "483",
        "rarity_score": 239.8991645538339,
        "rarity_level": "COMMON"
    },
    "1703": {
        "name": "Brawler Bears #1703",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1703.png",
        "dna": "8ebb3b40d58ba420d32b298fefc3a828814882b3",
        "edition": "1703",
        "type": "Ice",
        "date": 1677695764285,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1703",
        "rarity_score": 199.3638040327407,
        "rarity_level": "COMMON"
    },
    "191": {
        "name": "Brawler Bears #191",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/191.png",
        "dna": "934c9cc1d9a0677622c2cd6808f9bc2bf978bce3",
        "edition": "191",
        "type": "Galactic Cyborg",
        "date": 1677695765618,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "191",
        "rarity_score": 252.4653013148886,
        "rarity_level": "COMMON"
    },
    "2293": {
        "name": "Brawler Bears #2293",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2293.png",
        "dna": "197e8e4d98416c309d27045e669062ad40e35d21",
        "edition": "2293",
        "type": "Lava",
        "date": 1677695767350,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2293",
        "rarity_score": 182.88947454081324,
        "rarity_level": "COMMON"
    },
    "1584": {
        "name": "Brawler Bears #1584",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1584.png",
        "dna": "1e0f3ba47a9a081456a3fda82103f1e036211b88",
        "edition": "1584",
        "type": "Brown Bear",
        "date": 1677695768037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1584",
        "rarity_score": 224.60580138193075,
        "rarity_level": "COMMON"
    },
    "2309": {
        "name": "Brawler Bears #2309",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2309.png",
        "dna": "76dc35407217df6e0f1d7a8a38754b4d07e0d6a4",
        "edition": "2309",
        "type": "Panda",
        "date": 1677695768691,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "2309",
        "rarity_score": 321.58310909172525,
        "rarity_level": "RARE"
    },
    "330": {
        "name": "Brawler Bears #330",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/330.png",
        "dna": "262b822dd9f6cf30bf58dba9fcda7a8819221c5d",
        "edition": "330",
        "type": "Galaxy",
        "date": 1677695769668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "330",
        "rarity_score": 308.05725730775856,
        "rarity_level": "RARE"
    },
    "1724": {
        "name": "Brawler Bears #1724",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1724.png",
        "dna": "b1d1df9818d0490278b3f1d215dab209d8feb7dd",
        "edition": "1724",
        "type": "Lava",
        "date": 1677695770825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 12,
        "maxHealth": 69,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1724",
        "rarity_score": 302.6378740824393,
        "rarity_level": "RARE"
    },
    "1817": {
        "name": "Brawler Bears #1817",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1817.png",
        "dna": "cd399660943705198de0e8ccd7d77ecc80e8610b",
        "edition": "1817",
        "type": "Panda",
        "date": 1677695771540,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 34,
        "maxHealth": 196,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1817",
        "rarity_score": 297.1998853304063,
        "rarity_level": "RARE"
    },
    "1887": {
        "name": "Brawler Bears #1887",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1887.png",
        "dna": "846d5078fb1de05c01dff499de6200a7a175e17e",
        "edition": "1887",
        "type": "Brown Bear",
        "date": 1677695772614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 32,
        "maxHealth": 188,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1887",
        "rarity_score": 182.88248580523972,
        "rarity_level": "COMMON"
    },
    "390": {
        "name": "Brawler Bears #390",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/390.png",
        "dna": "56736d437a0071e04ea68b3ec98d86cee0d7aff2",
        "edition": "390",
        "type": "Black Bear",
        "date": 1677695773367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 37,
        "maxHealth": 217,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "390",
        "rarity_score": 214.60191296626627,
        "rarity_level": "COMMON"
    },
    "527": {
        "name": "Brawler Bears #527",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/527.png",
        "dna": "44946a913939a622ba683c48446ac18bc751ea9b",
        "edition": "527",
        "type": "Galactic Cyborg",
        "date": 1677695774043,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "527",
        "rarity_score": 284.0645951687591,
        "rarity_level": "RARE"
    },
    "1497": {
        "name": "Brawler Bears #1497",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1497.png",
        "dna": "9f61d7cda0b8849ef8d348c2f1143d84e7d47a66",
        "edition": "1497",
        "type": "Panda",
        "date": 1677695775285,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1497",
        "rarity_score": 331.70249995782547,
        "rarity_level": "RARE"
    },
    "2165": {
        "name": "Brawler Bears #2165",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2165.png",
        "dna": "9bcbb0dcb989420f0e35aa79690185dec7726919",
        "edition": "2165",
        "type": "Galaxy",
        "date": 1677695776466,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "2165",
        "rarity_score": 210.0050395659093,
        "rarity_level": "COMMON"
    },
    "597": {
        "name": "Brawler Bears #597",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/597.png",
        "dna": "4fe9f6dc08a149b22889f140621c1a25e6b3c18f",
        "edition": "597",
        "type": "Brown Bear",
        "date": 1677695777581,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 69,
        "maxHealth": 395,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "597",
        "rarity_score": 79.05972998743809,
        "rarity_level": "COMMON"
    },
    "1300": {
        "name": "Brawler Bears #1300",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1300.png",
        "dna": "7555d1d0209158291bf1aaae1e03d96840d34913",
        "edition": "1300",
        "type": "Galactic Cyborg",
        "date": 1677695778915,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1300",
        "rarity_score": 193.75116826839042,
        "rarity_level": "COMMON"
    },
    "1118": {
        "name": "Brawler Bears #1118",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1118.png",
        "dna": "759a251ab11776b4f8595b948c28bd204c1f75ba",
        "edition": "1118",
        "type": "Black Bear",
        "date": 1677695779902,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1118",
        "rarity_score": 255.7000125243906,
        "rarity_level": "COMMON"
    },
    "1645": {
        "name": "Brawler Bears #1645",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1645.png",
        "dna": "3bedb446e960da2fc28a936ecb0f3a61d88e9b97",
        "edition": "1645",
        "type": "Acid Trip",
        "date": 1677695780739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 29,
        "maxHealth": 169,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1645",
        "rarity_score": 234.01340757210926,
        "rarity_level": "COMMON"
    },
    "1909": {
        "name": "Brawler Bears #1909",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1909.png",
        "dna": "a9291311da1a4bceca3d1a8fdcec3405c9e4eab1",
        "edition": "1909",
        "type": "Lava",
        "date": 1677695781645,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1909",
        "rarity_score": 274.5254153556207,
        "rarity_level": "COMMON"
    },
    "333": {
        "name": "Brawler Bears #333",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/333.png",
        "dna": "e66bf69cdbb9a727fe20056b0c6d88b4ced5a2e7",
        "edition": "333",
        "type": "Deep Martian Cyborg",
        "date": 1677695782418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "333",
        "rarity_score": 327.3013846602719,
        "rarity_level": "RARE"
    },
    "1506": {
        "name": "Brawler Bears #1506",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1506.png",
        "dna": "2cfd7dd03adb9bd50f2c2d944e7df99ece859cc6",
        "edition": "1506",
        "type": "Panda",
        "date": 1677695783789,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 61,
        "maxHealth": 348,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1506",
        "rarity_score": 103.39171102423211,
        "rarity_level": "COMMON"
    },
    "778": {
        "name": "Brawler Bears #778",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/778.png",
        "dna": "263b497268a73dc66fd81f74409b5ff165149663",
        "edition": "778",
        "type": "Acid Trip",
        "date": 1677695785450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 51,
        "maxHealth": 292,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "778",
        "rarity_score": 184.822939895886,
        "rarity_level": "COMMON"
    },
    "520": {
        "name": "Brawler Bears #520",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/520.png",
        "dna": "95d7774022b94a7dcce55e8644bffb0dc34e1a72",
        "edition": "520",
        "type": "Acid Trip",
        "date": 1677695786378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 24,
        "maxHealth": 138,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "520",
        "rarity_score": 203.009112364805,
        "rarity_level": "COMMON"
    },
    "96": {
        "name": "Brawler Bears #96",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/96.png",
        "dna": "a0b35f3a5ce7e2cdfcaad99fb80d237fd3de3725",
        "edition": "96",
        "type": "Galactic Cyborg",
        "date": 1677695787735,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "96",
        "rarity_score": 298.57308206355947,
        "rarity_level": "RARE"
    },
    "462": {
        "name": "Brawler Bears #462",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/462.png",
        "dna": "7ebc5e52221cf646600041b54992c5042359a4ac",
        "edition": "462",
        "type": "Panda",
        "date": 1677695788643,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 31,
        "maxHealth": 177,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "462",
        "rarity_score": 196.5299556286459,
        "rarity_level": "COMMON"
    },
    "2007": {
        "name": "Brawler Bears #2007",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2007.png",
        "dna": "ce9123665a8bd647cd392115ed44ca8590f3f61a",
        "edition": "2007",
        "type": "Brown Bear",
        "date": 1677695789709,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "2007",
        "rarity_score": 270.4698121055965,
        "rarity_level": "COMMON"
    },
    "2124": {
        "name": "Brawler Bears #2124",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2124.png",
        "dna": "fbf1488495d5d2789a4533e5c0a4a7bf6cbfd2c2",
        "edition": "2124",
        "type": "Panda",
        "date": 1677695790633,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 24,
        "maxHealth": 139,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "2124",
        "rarity_score": 177.95321417029783,
        "rarity_level": "COMMON"
    },
    "831": {
        "name": "Brawler Bears #831",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/831.png",
        "dna": "2352b4830541c6fe88914ee79f8a9dee1dcc8d3d",
        "edition": "831",
        "type": "Acid Trip",
        "date": 1677695791539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 30,
        "maxHealth": 171,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "831",
        "rarity_score": 228.5862402881488,
        "rarity_level": "COMMON"
    },
    "406": {
        "name": "Brawler Bears #406",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/406.png",
        "dna": "a905a6db3412441dd201dc47a11cfeb590721563",
        "edition": "406",
        "type": "Ice",
        "date": 1677695792813,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "406",
        "rarity_score": 258.19523132089483,
        "rarity_level": "COMMON"
    },
    "1156": {
        "name": "Brawler Bears #1156",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1156.png",
        "dna": "f10a0759659c05465c6f5c3ff79259a9c0372a52",
        "edition": "1156",
        "type": "Panda",
        "date": 1677695794059,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1156",
        "rarity_score": 222.97866603527396,
        "rarity_level": "COMMON"
    },
    "1830": {
        "name": "Brawler Bears #1830",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1830.png",
        "dna": "d043339da82bc25e40a5a492b983b7d8eb4d73ab",
        "edition": "1830",
        "type": "Galactic Cyborg",
        "date": 1677695795082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1830",
        "rarity_score": 175.1236300904514,
        "rarity_level": "COMMON"
    },
    "674": {
        "name": "Brawler Bears #674",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/674.png",
        "dna": "2a31799fa6c29b92ec0d9c62286b3a62e4ee3a62",
        "edition": "674",
        "type": "Panda",
        "date": 1677695796595,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "674",
        "rarity_score": 226.12256911360836,
        "rarity_level": "COMMON"
    },
    "1305": {
        "name": "Brawler Bears #1305",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1305.png",
        "dna": "3abc94a77b724ad39f0dfb671fa704ec78262adf",
        "edition": "1305",
        "type": "Brown Bear",
        "date": 1677695797478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1305",
        "rarity_score": 229.343896620026,
        "rarity_level": "COMMON"
    },
    "465": {
        "name": "Brawler Bears #465",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/465.png",
        "dna": "f068eadfec52f1ed88cbf4fb97038e91e3414aca",
        "edition": "465",
        "type": "Galactic Cyborg",
        "date": 1677695798647,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "465",
        "rarity_score": 247.41882824848318,
        "rarity_level": "COMMON"
    },
    "1222": {
        "name": "Brawler Bears #1222",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1222.png",
        "dna": "ed4a4604f11e70a0844bc66f32415a7354fdc39d",
        "edition": "1222",
        "type": "Galaxy",
        "date": 1677695799624,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 63,
        "maxHealth": 363,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1222",
        "rarity_score": 86.77179554762763,
        "rarity_level": "COMMON"
    },
    "1230": {
        "name": "Brawler Bears #1230",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1230.png",
        "dna": "b1770a7857bb95471bb3adaee97afc72b66d12c9",
        "edition": "1230",
        "type": "Black Bear",
        "date": 1677695800421,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1230",
        "rarity_score": 245.06946395565916,
        "rarity_level": "COMMON"
    },
    "673": {
        "name": "Brawler Bears #673",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/673.png",
        "dna": "6edd782e7ffd39dc27d90d78e8b505565600a8de",
        "edition": "673",
        "type": "Galaxy",
        "date": 1677695802038,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 15,
        "maxHealth": 91,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "673",
        "rarity_score": 258.693282476439,
        "rarity_level": "COMMON"
    },
    "1335": {
        "name": "Brawler Bears #1335",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1335.png",
        "dna": "75f900ff1cc8699b2f29d140886aad8a33e43053",
        "edition": "1335",
        "type": "Galactic Cyborg",
        "date": 1677695802999,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1335",
        "rarity_score": 218.97062887820968,
        "rarity_level": "COMMON"
    },
    "1914": {
        "name": "Brawler Bears #1914",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1914.png",
        "dna": "071895ccdfb8b8e99e2a62f6a45b4b7697c89924",
        "edition": "1914",
        "type": "Galactic Cyborg",
        "date": 1677695804727,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1914",
        "rarity_score": 277.90894013445285,
        "rarity_level": "RARE"
    },
    "796": {
        "name": "Brawler Bears #796",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/796.png",
        "dna": "82a93499437423443b501247c41bd5129a97379f",
        "edition": "796",
        "type": "Black Bear",
        "date": 1677695806023,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 41,
        "maxHealth": 236,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "796",
        "rarity_score": 245.57740761092134,
        "rarity_level": "COMMON"
    },
    "1933": {
        "name": "Brawler Bears #1933",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1933.png",
        "dna": "3c8c0bf4fcf03dfc9de9a353f89cecfed84dad8c",
        "edition": "1933",
        "type": "Lava",
        "date": 1677695806870,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1933",
        "rarity_score": 282.9811514115433,
        "rarity_level": "RARE"
    },
    "1261": {
        "name": "Brawler Bears #1261",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1261.png",
        "dna": "f713398f6d15492bf9e21114469b06c33b66b296",
        "edition": "1261",
        "type": "Brown Bear",
        "date": 1677695807746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 20,
        "maxHealth": 117,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1261",
        "rarity_score": 288.1097055819507,
        "rarity_level": "RARE"
    },
    "638": {
        "name": "Brawler Bears #638",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/638.png",
        "dna": "323554d15617fa34500cb23822122c43e5b0008c",
        "edition": "638",
        "type": "Ice",
        "date": 1677695809095,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 24,
        "maxHealth": 141,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "638",
        "rarity_score": 194.31128265279426,
        "rarity_level": "COMMON"
    },
    "637": {
        "name": "Brawler Bears #637",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/637.png",
        "dna": "e492aa11c015dcda8e5da1d29980e509bd15fda7",
        "edition": "637",
        "type": "Galaxy",
        "date": 1677695809932,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "637",
        "rarity_score": 274.55097801976206,
        "rarity_level": "COMMON"
    },
    "776": {
        "name": "Brawler Bears #776",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/776.png",
        "dna": "fbe770f95620bfe4d7dfe1ada554a6c97d8ac71e",
        "edition": "776",
        "type": "Acid Trip",
        "date": 1677695810738,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 12,
        "maxHealth": 73,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "776",
        "rarity_score": 306.1546595583735,
        "rarity_level": "RARE"
    },
    "423": {
        "name": "Brawler Bears #423",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/423.png",
        "dna": "81107fb0d61dd266bca1e5cbe61e2f75af50638f",
        "edition": "423",
        "type": "Brown Bear",
        "date": 1677695811676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "423",
        "rarity_score": 203.8657571234522,
        "rarity_level": "COMMON"
    },
    "813": {
        "name": "Brawler Bears #813",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/813.png",
        "dna": "bc78104a248fe7ec59cec2053dbf97760ce55b61",
        "edition": "813",
        "type": "Ice",
        "date": 1677695813041,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "813",
        "rarity_score": 302.7376208130423,
        "rarity_level": "RARE"
    },
    "633": {
        "name": "Brawler Bears #633",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/633.png",
        "dna": "7d1ac8bdd2e09a84fe15cc6208d4e7a10958561b",
        "edition": "633",
        "type": "Galaxy",
        "date": 1677695814269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "633",
        "rarity_score": 215.89510350961243,
        "rarity_level": "COMMON"
    },
    "569": {
        "name": "Brawler Bears #569",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/569.png",
        "dna": "6f537318eb12b10f9c692f9c199e581e730dbffc",
        "edition": "569",
        "type": "Black Bear",
        "date": 1677695815298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "569",
        "rarity_score": 257.9293940507874,
        "rarity_level": "COMMON"
    },
    "1671": {
        "name": "Brawler Bears #1671",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1671.png",
        "dna": "8f2c987e9fceb39e4a55757b8c56140e65abd0ad",
        "edition": "1671",
        "type": "Acid Trip",
        "date": 1677695816211,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1671",
        "rarity_score": 204.3561954274637,
        "rarity_level": "COMMON"
    },
    "1897": {
        "name": "Brawler Bears #1897",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1897.png",
        "dna": "e0e0f038aea3adb7d263909060237c131f83ef3b",
        "edition": "1897",
        "type": "Black Bear",
        "date": 1677695816882,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 58,
        "maxHealth": 331,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1897",
        "rarity_score": 176.89097876318374,
        "rarity_level": "COMMON"
    },
    "460": {
        "name": "Brawler Bears #460",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/460.png",
        "dna": "f70ff049ff8d1c021504a3b0b5e541d8e600cc89",
        "edition": "460",
        "type": "Black Bear",
        "date": 1677695817569,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 41,
        "maxHealth": 235,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "460",
        "rarity_score": 267.8621606974083,
        "rarity_level": "COMMON"
    },
    "2108": {
        "name": "Brawler Bears #2108",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2108.png",
        "dna": "d5b86cc9fc8c98f8f2f865c94de15ac04740fe9b",
        "edition": "2108",
        "type": "Ice",
        "date": 1677695818411,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 30,
        "maxHealth": 175,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2108",
        "rarity_score": 225.44683716819117,
        "rarity_level": "COMMON"
    },
    "692": {
        "name": "Brawler Bears #692",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/692.png",
        "dna": "d298418d6100302400caf9d601bdf4e791d1b444",
        "edition": "692",
        "type": "Lava",
        "date": 1677695819645,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 13,
        "maxHealth": 75,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "692",
        "rarity_score": 321.1436135112264,
        "rarity_level": "RARE"
    },
    "1738": {
        "name": "Brawler Bears #1738",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1738.png",
        "dna": "80d5311225942e0db4234081fa54fe2ef63ca625",
        "edition": "1738",
        "type": "Galactic Cyborg",
        "date": 1677695820855,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1738",
        "rarity_score": 243.53434553403494,
        "rarity_level": "COMMON"
    },
    "67": {
        "name": "Brawler Bears #67",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/67.png",
        "dna": "d76bfc3b55b74fbe69f5c58f3ff2bf251d7a987c",
        "edition": "67",
        "type": "Galaxy",
        "date": 1677695822008,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "67",
        "rarity_score": 251.866275155883,
        "rarity_level": "COMMON"
    },
    "985": {
        "name": "Brawler Bears #985",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/985.png",
        "dna": "4b3b90b1a0291da4c6866fa8c6a6da4f86b19e72",
        "edition": "985",
        "type": "Ice",
        "date": 1677695823348,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "985",
        "rarity_score": 256.47211196731655,
        "rarity_level": "COMMON"
    },
    "2269": {
        "name": "Brawler Bears #2269",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2269.png",
        "dna": "c390842b6f0719e988b87795ed181fd9cd5dd9b6",
        "edition": "2269",
        "type": "Brown Bear",
        "date": 1677695824054,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2269",
        "rarity_score": 209.56254652441876,
        "rarity_level": "COMMON"
    },
    "57": {
        "name": "Brawler Bears #57",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/57.png",
        "dna": "b2807a3c7694b830221b24cf3f5f2a41f316c195",
        "edition": "57",
        "type": "Brown Bear",
        "date": 1677695825583,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 52,
        "maxHealth": 301,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "57",
        "rarity_score": 146.13775405864322,
        "rarity_level": "COMMON"
    },
    "2085": {
        "name": "Brawler Bears #2085",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2085.png",
        "dna": "75e612b21a60a9a1b84e5740ef31a1b602262b54",
        "edition": "2085",
        "type": "Black Bear",
        "date": 1677695827123,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 41,
        "maxHealth": 236,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2085",
        "rarity_score": 235.9879256981733,
        "rarity_level": "COMMON"
    },
    "713": {
        "name": "Brawler Bears #713",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/713.png",
        "dna": "0fbf284d5b05d5e59c424f8adf9a5fd3874362cb",
        "edition": "713",
        "type": "Ice",
        "date": 1677695828028,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 36,
        "maxHealth": 208,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "713",
        "rarity_score": 242.68083681135775,
        "rarity_level": "COMMON"
    },
    "889": {
        "name": "Brawler Bears #889",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/889.png",
        "dna": "ab956dd7e4275d08ba11adb4f99128e2e0fd921f",
        "edition": "889",
        "type": "Ice",
        "date": 1677695828784,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "889",
        "rarity_score": 141.40708953579136,
        "rarity_level": "COMMON"
    },
    "80": {
        "name": "Brawler Bears #80",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/80.png",
        "dna": "e1063062c0c2f1a85ef3b16ecb7efb8f3955c362",
        "edition": "80",
        "type": "Black Bear",
        "date": 1677695829410,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 58,
        "maxHealth": 335,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "80",
        "rarity_score": 163.07566054687751,
        "rarity_level": "COMMON"
    },
    "2298": {
        "name": "Brawler Bears #2298",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2298.png",
        "dna": "249994da5501225fdbdc4facb14d6fa16376f107",
        "edition": "2298",
        "type": "Galactic Cyborg",
        "date": 1677695830620,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "2298",
        "rarity_score": 126.75049415934328,
        "rarity_level": "COMMON"
    },
    "1868": {
        "name": "Brawler Bears #1868",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1868.png",
        "dna": "28c39d8ec703bb7cbbf4d711232964a777663395",
        "edition": "1868",
        "type": "Black Bear",
        "date": 1677695831729,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 42,
        "maxHealth": 242,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1868",
        "rarity_score": 270.4308926920256,
        "rarity_level": "COMMON"
    },
    "833": {
        "name": "Brawler Bears #833",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/833.png",
        "dna": "5394bd48f4b0f8ffd9d6e44776b8774c092fbd3c",
        "edition": "833",
        "type": "Galactic Cyborg",
        "date": 1677695832576,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "833",
        "rarity_score": 255.04588589940764,
        "rarity_level": "COMMON"
    },
    "1362": {
        "name": "Brawler Bears #1362",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1362.png",
        "dna": "d92d0e5b718aa54024d241503edfc12b45d7f89c",
        "edition": "1362",
        "type": "Deep Martian Cyborg",
        "date": 1677695833438,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1362",
        "rarity_score": 226.45768935676674,
        "rarity_level": "COMMON"
    },
    "1821": {
        "name": "Brawler Bears #1821",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1821.png",
        "dna": "1aaf25fad711e4b0625dfd830bac717d5f99318d",
        "edition": "1821",
        "type": "Ice",
        "date": 1677695834267,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 91,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1821",
        "rarity_score": 283.01918437271917,
        "rarity_level": "RARE"
    },
    "704": {
        "name": "Brawler Bears #704",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/704.png",
        "dna": "7867586305b515137ceb8866baacf2b5ed2b8c47",
        "edition": "704",
        "type": "Ice",
        "date": 1677695835509,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "704",
        "rarity_score": 266.2517536972964,
        "rarity_level": "COMMON"
    },
    "1713": {
        "name": "Brawler Bears #1713",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1713.png",
        "dna": "93e4120f7d37448fb6904e14432cc725e5fc459e",
        "edition": "1713",
        "type": "Acid Trip",
        "date": 1677695836457,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 30,
        "maxHealth": 174,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1713",
        "rarity_score": 204.84050584782779,
        "rarity_level": "COMMON"
    },
    "1401": {
        "name": "Brawler Bears #1401",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1401.png",
        "dna": "929d426e38400277c86f38ebd4cb2962e2bef4e1",
        "edition": "1401",
        "type": "Ice",
        "date": 1677695837357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1401",
        "rarity_score": 272.3914881403006,
        "rarity_level": "COMMON"
    },
    "979": {
        "name": "Brawler Bears #979",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/979.png",
        "dna": "eb291a83a434ee6992364cec42fa8338b29146b6",
        "edition": "979",
        "type": "Galaxy",
        "date": 1677695838235,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 54,
        "maxHealth": 311,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "979",
        "rarity_score": 157.72076043232363,
        "rarity_level": "COMMON"
    },
    "58": {
        "name": "Brawler Bears #58",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/58.png",
        "dna": "2fb6263c577012471308b626e74378b5627778cd",
        "edition": "58",
        "type": "Black Bear",
        "date": 1677695839110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "58",
        "rarity_score": 299.68021575994237,
        "rarity_level": "RARE"
    },
    "2190": {
        "name": "Brawler Bears #2190",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2190.png",
        "dna": "7b95f0406d8ba468a82ada4dcbdfee272ac73ad0",
        "edition": "2190",
        "type": "Galaxy",
        "date": 1677695840520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "2190",
        "rarity_score": 321.10514531858536,
        "rarity_level": "RARE"
    },
    "1537": {
        "name": "Brawler Bears #1537",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1537.png",
        "dna": "1e4d9fc7689bf2eb55edf2f92c5b9d10ed0d6a5a",
        "edition": "1537",
        "type": "Panda",
        "date": 1677695842203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1537",
        "rarity_score": 330.13605729066256,
        "rarity_level": "RARE"
    },
    "1161": {
        "name": "Brawler Bears #1161",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1161.png",
        "dna": "3211b08df24bb5c43ed43ed42a61ca5f9e5f35ed",
        "edition": "1161",
        "type": "Brown Bear",
        "date": 1677695843007,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1161",
        "rarity_score": 207.34267401341958,
        "rarity_level": "COMMON"
    },
    "652": {
        "name": "Brawler Bears #652",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/652.png",
        "dna": "db1cabfa2d94e78c08733bd9df7412b13415c846",
        "edition": "652",
        "type": "Galactic Cyborg",
        "date": 1677695843772,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "652",
        "rarity_score": 303.02332771838377,
        "rarity_level": "RARE"
    },
    "2224": {
        "name": "Brawler Bears #2224",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2224.png",
        "dna": "ff14407e40e242a2312513a6d528457e55a678ab",
        "edition": "2224",
        "type": "Black Bear",
        "date": 1677695844682,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 20,
        "maxHealth": 118,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "2224",
        "rarity_score": 249.65295894435607,
        "rarity_level": "COMMON"
    },
    "143": {
        "name": "Brawler Bears #143",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/143.png",
        "dna": "7f8d9e7f930f532eab4301737046760a582ea795",
        "edition": "143",
        "type": "Galactic Cyborg",
        "date": 1677695845548,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "143",
        "rarity_score": 216.78525759115345,
        "rarity_level": "COMMON"
    },
    "1036": {
        "name": "Brawler Bears #1036",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1036.png",
        "dna": "b6402ea1cbec13e94cd88eb71e094977d1615372",
        "edition": "1036",
        "type": "Ice",
        "date": 1677695846641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1036",
        "rarity_score": 230.2911461366383,
        "rarity_level": "COMMON"
    },
    "1518": {
        "name": "Brawler Bears #1518",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1518.png",
        "dna": "bade40b3f0782145c2e1a9cc4e5ba56205928e16",
        "edition": "1518",
        "type": "Lava",
        "date": 1677695847440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 29,
        "maxHealth": 169,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1518",
        "rarity_score": 219.49039185182096,
        "rarity_level": "COMMON"
    },
    "2066": {
        "name": "Brawler Bears #2066",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2066.png",
        "dna": "1d64233288a7689130be5a1743befa9e587057c3",
        "edition": "2066",
        "type": "Brown Bear",
        "date": 1677695848155,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2066",
        "rarity_score": 208.6764481993964,
        "rarity_level": "COMMON"
    },
    "2323": {
        "name": "Brawler Bears #2323",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2323.png",
        "dna": "f66163ecd91c8d3472ad6b16f44522cbab01c172",
        "edition": "2323",
        "type": "Brown Bear",
        "date": 1677695850115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2323",
        "rarity_score": 210.1750716214245,
        "rarity_level": "COMMON"
    },
    "665": {
        "name": "Brawler Bears #665",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/665.png",
        "dna": "6b3f6864e5caad3e3c4ef1a0bb15405995d7407f",
        "edition": "665",
        "type": "Acid Trip",
        "date": 1677695851215,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 15,
        "maxHealth": 85,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "665",
        "rarity_score": 255.95197926685776,
        "rarity_level": "COMMON"
    },
    "1647": {
        "name": "Brawler Bears #1647",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1647.png",
        "dna": "19892d963d9eebad54fa06fa639f67c86b6ada78",
        "edition": "1647",
        "type": "Acid Trip",
        "date": 1677695852698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1647",
        "rarity_score": 255.06447786397666,
        "rarity_level": "COMMON"
    },
    "1965": {
        "name": "Brawler Bears #1965",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1965.png",
        "dna": "2c243e1232424f78ba30c0a686e0c66ee01743ec",
        "edition": "1965",
        "type": "Galaxy",
        "date": 1677695854125,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1965",
        "rarity_score": 292.3170048042524,
        "rarity_level": "RARE"
    },
    "1195": {
        "name": "Brawler Bears #1195",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1195.png",
        "dna": "8e5064ea114d3d20a5e29a11a21df7e12887e0a9",
        "edition": "1195",
        "type": "Acid Trip",
        "date": 1677695854990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1195",
        "rarity_score": 149.90942838400008,
        "rarity_level": "COMMON"
    },
    "1641": {
        "name": "Brawler Bears #1641",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1641.png",
        "dna": "412de7ae2cd6c02d52fa6849c2851ee15cac39ec",
        "edition": "1641",
        "type": "Ice",
        "date": 1677695855981,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 25,
        "maxHealth": 148,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1641",
        "rarity_score": 178.5984436382097,
        "rarity_level": "COMMON"
    },
    "853": {
        "name": "Brawler Bears #853",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/853.png",
        "dna": "9ea127851bb358654e57a7601469b601fbd58938",
        "edition": "853",
        "type": "Black Bear",
        "date": 1677695856888,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 20,
        "maxHealth": 117,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "853",
        "rarity_score": 334.0066539380803,
        "rarity_level": "RARE"
    },
    "2058": {
        "name": "Brawler Bears #2058",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2058.png",
        "dna": "f7c1e15dcb572b41aba904eea1af9f302786e0ac",
        "edition": "2058",
        "type": "Black Bear",
        "date": 1677695857690,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 42,
        "maxHealth": 244,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2058",
        "rarity_score": 230.93809913584673,
        "rarity_level": "COMMON"
    },
    "1818": {
        "name": "Brawler Bears #1818",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1818.png",
        "dna": "3abd7f31ef094253ec19f9268558e7e18c8eaf3a",
        "edition": "1818",
        "type": "Ice",
        "date": 1677695858587,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1818",
        "rarity_score": 283.6316540530308,
        "rarity_level": "RARE"
    },
    "106": {
        "name": "Brawler Bears #106",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/106.png",
        "dna": "a5c154cb6f242c7f00a4b088f0da4b09192632d2",
        "edition": "106",
        "type": "Ice",
        "date": 1677695859539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "106",
        "rarity_score": 249.98887516469037,
        "rarity_level": "COMMON"
    },
    "403": {
        "name": "Brawler Bears #403",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/403.png",
        "dna": "c5a709fee0a675e569e71426a3e40e8aad9d39b5",
        "edition": "403",
        "type": "Brown Bear",
        "date": 1677695860613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "403",
        "rarity_score": 194.16282671754098,
        "rarity_level": "COMMON"
    },
    "716": {
        "name": "Brawler Bears #716",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/716.png",
        "dna": "2ac94617dc9e837831c9693e7ef5686a20a4ea0b",
        "edition": "716",
        "type": "Lava",
        "date": 1677695861629,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "716",
        "rarity_score": 282.8960044208474,
        "rarity_level": "RARE"
    },
    "1083": {
        "name": "Brawler Bears #1083",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1083.png",
        "dna": "0a6313b4742e9829c146fd4e74f6fc7b478ba12f",
        "edition": "1083",
        "type": "Black Bear",
        "date": 1677695862617,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            }
        ],
        "level": 21,
        "maxHealth": 120,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1083",
        "rarity_score": 257.3894839722829,
        "rarity_level": "COMMON"
    },
    "966": {
        "name": "Brawler Bears #966",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/966.png",
        "dna": "ff40ad21d2d8e63572b15e3ff1c67ec194e64041",
        "edition": "966",
        "type": "Deep Martian Cyborg",
        "date": 1677695863450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "966",
        "rarity_score": 262.523138504228,
        "rarity_level": "COMMON"
    },
    "165": {
        "name": "Brawler Bears #165",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/165.png",
        "dna": "4d49b8607fb445ca21d0fb6c0af690aceafc1af2",
        "edition": "165",
        "type": "Ice",
        "date": 1677695864703,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "165",
        "rarity_score": 288.8108339029392,
        "rarity_level": "RARE"
    },
    "113": {
        "name": "Brawler Bears #113",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/113.png",
        "dna": "23499ad580a4bd1dbe716d2bf957f2e632835599",
        "edition": "113",
        "type": "Acid Trip",
        "date": 1677695866210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "113",
        "rarity_score": 297.24593794035394,
        "rarity_level": "RARE"
    },
    "249": {
        "name": "Brawler Bears #249",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/249.png",
        "dna": "bd09b462cce70f7cb38d1df39b43bc40b584d7f1",
        "edition": "249",
        "type": "Galaxy",
        "date": 1677695867261,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "249",
        "rarity_score": 228.40295643656404,
        "rarity_level": "COMMON"
    },
    "1087": {
        "name": "Brawler Bears #1087",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1087.png",
        "dna": "543738692247962016988df6d128a35f9fa8776e",
        "edition": "1087",
        "type": "Cheetah",
        "date": 1677695868368,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1087",
        "rarity_score": 219.96633907919698,
        "rarity_level": "COMMON"
    },
    "651": {
        "name": "Brawler Bears #651",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/651.png",
        "dna": "16b765f73838c1a3846583d98a53bdfc377c5b86",
        "edition": "651",
        "type": "Deep Martian Cyborg",
        "date": 1677695869286,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "651",
        "rarity_score": 298.99278891780386,
        "rarity_level": "RARE"
    },
    "1074": {
        "name": "Brawler Bears #1074",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1074.png",
        "dna": "d09f5ab8d1adbfcbb7242f296b81b8bedba5da4a",
        "edition": "1074",
        "type": "Brown Bear",
        "date": 1677695870019,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1074",
        "rarity_score": 269.73488968595956,
        "rarity_level": "COMMON"
    },
    "1690": {
        "name": "Brawler Bears #1690",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1690.png",
        "dna": "179c9e3e71d8266a07ce3ef72d6f0f9975330379",
        "edition": "1690",
        "type": "Deep Martian Cyborg",
        "date": 1677695870945,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            }
        ],
        "level": 42,
        "maxHealth": 243,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1690",
        "rarity_score": 132.14759887721624,
        "rarity_level": "COMMON"
    },
    "335": {
        "name": "Brawler Bears #335",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/335.png",
        "dna": "f9ea6768568332455586ae398fee55f5fc06ad7f",
        "edition": "335",
        "type": "Deep Martian Cyborg",
        "date": 1677695872385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "335",
        "rarity_score": 278.4907141947913,
        "rarity_level": "RARE"
    },
    "1354": {
        "name": "Brawler Bears #1354",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1354.png",
        "dna": "f966a68e2a1cb78ffa01050b7f57ea0b62e88f4c",
        "edition": "1354",
        "type": "Black Bear",
        "date": 1677695873539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1354",
        "rarity_score": 274.01988843676804,
        "rarity_level": "COMMON"
    },
    "42": {
        "name": "Brawler Bears #42",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/42.png",
        "dna": "c5fd50a676d58aaa674292c8877f819abca7f5f7",
        "edition": "42",
        "type": "Panda",
        "date": 1677695874339,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            }
        ],
        "level": 29,
        "maxHealth": 168,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "42",
        "rarity_score": 278.02085474812844,
        "rarity_level": "RARE"
    },
    "1435": {
        "name": "Brawler Bears #1435",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1435.png",
        "dna": "08555e16330a6c988dbcbe8ba4369c4d6eaaa8d8",
        "edition": "1435",
        "type": "Ice",
        "date": 1677695875310,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1435",
        "rarity_score": 220.45107390451153,
        "rarity_level": "COMMON"
    },
    "1508": {
        "name": "Brawler Bears #1508",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1508.png",
        "dna": "182f02a5c0dc55744f406e80f34e7bfc86f59b93",
        "edition": "1508",
        "type": "Brown Bear",
        "date": 1677695876140,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 21,
        "maxHealth": 121,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1508",
        "rarity_score": 283.9110869243668,
        "rarity_level": "RARE"
    },
    "2255": {
        "name": "Brawler Bears #2255",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2255.png",
        "dna": "be3c09b273b40728c367472f86faaa0b26eb355c",
        "edition": "2255",
        "type": "Cheetah",
        "date": 1677695877065,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "2255",
        "rarity_score": 250.38503750662144,
        "rarity_level": "COMMON"
    },
    "1166": {
        "name": "Brawler Bears #1166",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1166.png",
        "dna": "10e2d7eda53d9d728af89e0203599dfcdab12e48",
        "edition": "1166",
        "type": "Black Bear",
        "date": 1677695877825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Mouth",
                "value": "TNT",
                "score": 31.58730158730159
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            }
        ],
        "level": 51,
        "maxHealth": 295,
        "defaultAttack": { "name": "TNT", "damage": 70, "power": 11 },
        "id": "1166",
        "rarity_score": 137.78173579517633,
        "rarity_level": "COMMON"
    },
    "1034": {
        "name": "Brawler Bears #1034",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1034.png",
        "dna": "e018cdb4e5d4af3bce4551fb8cb164a51575f0e1",
        "edition": "1034",
        "type": "Black Bear",
        "date": 1677695879312,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1034",
        "rarity_score": 271.09262196142595,
        "rarity_level": "COMMON"
    },
    "28": {
        "name": "Brawler Bears #28",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/28.png",
        "dna": "1579a5d34548fc1818f7e4c4db4a6eccca6f1675",
        "edition": "28",
        "type": "Black Bear",
        "date": 1677695880674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "28",
        "rarity_score": 194.58713677278075,
        "rarity_level": "COMMON"
    },
    "2089": {
        "name": "Brawler Bears #2089",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2089.png",
        "dna": "b7bce6df26b132c48131bdca695eff35a53bdd15",
        "edition": "2089",
        "type": "Black Bear",
        "date": 1677695881515,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            }
        ],
        "level": 21,
        "maxHealth": 124,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "2089",
        "rarity_score": 265.6342948786557,
        "rarity_level": "COMMON"
    },
    "170": {
        "name": "Brawler Bears #170",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/170.png",
        "dna": "57dce109664f769ae677b9271b2f2d19b250005c",
        "edition": "170",
        "type": "Panda",
        "date": 1677695882367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 24,
        "maxHealth": 141,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "170",
        "rarity_score": 191.51743983579283,
        "rarity_level": "COMMON"
    },
    "631": {
        "name": "Brawler Bears #631",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/631.png",
        "dna": "bf46a81cb98f06ccde4a6d84fcb81857bf56f05e",
        "edition": "631",
        "type": "Black Bear",
        "date": 1677695883179,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 20,
        "maxHealth": 119,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "631",
        "rarity_score": 298.62390287448875,
        "rarity_level": "RARE"
    },
    "2121": {
        "name": "Brawler Bears #2121",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2121.png",
        "dna": "b6388961351b4e58676f0a6354ca0c1d97510cce",
        "edition": "2121",
        "type": "Black Bear",
        "date": 1677695884130,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 41,
        "maxHealth": 235,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2121",
        "rarity_score": 250.8835075766348,
        "rarity_level": "COMMON"
    },
    "1434": {
        "name": "Brawler Bears #1434",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1434.png",
        "dna": "3e9c0eddb3675b156add29eecb2cd2d5a24ce24a",
        "edition": "1434",
        "type": "Brown Bear",
        "date": 1677695884907,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1434",
        "rarity_score": 255.2474006331442,
        "rarity_level": "COMMON"
    },
    "834": {
        "name": "Brawler Bears #834",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/834.png",
        "dna": "ec1e1f6553848f2b174ef79c357e280e794adb9a",
        "edition": "834",
        "type": "Galaxy",
        "date": 1677695886281,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "834",
        "rarity_score": 251.34817713827658,
        "rarity_level": "COMMON"
    },
    "1608": {
        "name": "Brawler Bears #1608",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1608.png",
        "dna": "2d4890b2f518bb12125a10eea6a60699dbf6f0dd",
        "edition": "1608",
        "type": "Black Bear",
        "date": 1677695887267,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Mouth",
                "value": "Pizza",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            }
        ],
        "level": 21,
        "maxHealth": 122,
        "defaultAttack": { "name": "Pizza", "damage": 71, "power": 11 },
        "id": "1608",
        "rarity_score": 262.06228074088085,
        "rarity_level": "COMMON"
    },
    "383": {
        "name": "Brawler Bears #383",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/383.png",
        "dna": "39fff1bdcca884a5db5362c025559ed99de3b961",
        "edition": "383",
        "type": "Lava",
        "date": 1677695888661,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            }
        ],
        "level": 12,
        "maxHealth": 73,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "383",
        "rarity_score": 271.26427872388444,
        "rarity_level": "COMMON"
    },
    "556": {
        "name": "Brawler Bears #556",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/556.png",
        "dna": "27d7e451c3242b16d240da94f9a78ea4b2505829",
        "edition": "556",
        "type": "Ice",
        "date": 1677695889539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            }
        ],
        "level": 24,
        "maxHealth": 142,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "556",
        "rarity_score": 214.10524023036433,
        "rarity_level": "COMMON"
    },
    "1611": {
        "name": "Brawler Bears #1611",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1611.png",
        "dna": "15bd1f915302abbdf80dd982fa80a5b92d3efb7a",
        "edition": "1611",
        "type": "Galactic Cyborg",
        "date": 1677695890890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1611",
        "rarity_score": 271.61852359366975,
        "rarity_level": "COMMON"
    },
    "510": {
        "name": "Brawler Bears #510",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/510.png",
        "dna": "820312f8182d6ea63dbaf0fd7082ab39be5090dd",
        "edition": "510",
        "type": "Galaxy",
        "date": 1677695892441,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "510",
        "rarity_score": 232.7410034494851,
        "rarity_level": "COMMON"
    },
    "1057": {
        "name": "Brawler Bears #1057",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1057.png",
        "dna": "0109e2858b9967957841d8f529ff82ea3599a9fb",
        "edition": "1057",
        "type": "Brown Bear",
        "date": 1677695893559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "1057",
        "rarity_score": 137.8245448331849,
        "rarity_level": "COMMON"
    },
    "1350": {
        "name": "Brawler Bears #1350",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1350.png",
        "dna": "7b7fdf43a95246ba1680fdeba4a1ab56cd1c885d",
        "edition": "1350",
        "type": "Lava",
        "date": 1677695894960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1350",
        "rarity_score": 142.75754444674013,
        "rarity_level": "COMMON"
    },
    "1376": {
        "name": "Brawler Bears #1376",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1376.png",
        "dna": "39846d8f506c70e7d1054b6fcfb9b825d2c750bb",
        "edition": "1376",
        "type": "Brown Bear",
        "date": 1677695895948,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 59,
        "maxHealth": 340,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1376",
        "rarity_score": 169.36448328167097,
        "rarity_level": "COMMON"
    },
    "895": {
        "name": "Brawler Bears #895",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/895.png",
        "dna": "8dec11766af56ad4fe377828840795f30376f560",
        "edition": "895",
        "type": "Galaxy",
        "date": 1677695897292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "895",
        "rarity_score": 266.8953178569164,
        "rarity_level": "COMMON"
    },
    "815": {
        "name": "Brawler Bears #815",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/815.png",
        "dna": "2aff019fe8a25997a91afdaee0a7e09b95fc59dd",
        "edition": "815",
        "type": "Deep Martian Cyborg",
        "date": 1677695898206,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Mouth",
                "value": "Wheat",
                "score": 33.728813559322035
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "815",
        "rarity_score": 287.90851872742303,
        "rarity_level": "RARE"
    },
    "404": {
        "name": "Brawler Bears #404",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/404.png",
        "dna": "a3325c757deb61d826007cf835b4cf11ec77a335",
        "edition": "404",
        "type": "Panda",
        "date": 1677695899764,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "404",
        "rarity_score": 222.12768748605652,
        "rarity_level": "COMMON"
    },
    "1688": {
        "name": "Brawler Bears #1688",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1688.png",
        "dna": "e8dc7a25fedd90937ad7aed127a398674a58927f",
        "edition": "1688",
        "type": "Ice",
        "date": 1677695900595,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Mouth",
                "value": "Scholar",
                "score": 34.91228070175438
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1688",
        "rarity_score": 308.90847132911045,
        "rarity_level": "RARE"
    },
    "2272": {
        "name": "Brawler Bears #2272",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2272.png",
        "dna": "474f5190079df906fd45bdb6b1ba15e18f0662f6",
        "edition": "2272",
        "type": "Deep Martian Cyborg",
        "date": 1677695901477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "2272",
        "rarity_score": 287.2890574175936,
        "rarity_level": "RARE"
    },
    "1826": {
        "name": "Brawler Bears #1826",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1826.png",
        "dna": "5707c4f4c778e73617629bebc26f11b53a384b03",
        "edition": "1826",
        "type": "Lava",
        "date": 1677695902253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Lover Zaddy", "score": 24.875 },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            }
        ],
        "level": 30,
        "maxHealth": 172,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1826",
        "rarity_score": 209.70135999972587,
        "rarity_level": "COMMON"
    },
    "1975": {
        "name": "Brawler Bears #1975",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1975.png",
        "dna": "29323e2af822996325b50ce197610aa5a0e47f2e",
        "edition": "1975",
        "type": "Panda",
        "date": 1677695903254,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Kunai",
                "score": 25.844155844155843
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1975",
        "rarity_score": 185.29719437182248,
        "rarity_level": "COMMON"
    },
    "2313": {
        "name": "Brawler Bears #2313",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2313.png",
        "dna": "3dc06d9b79c2f8412b5cee212cffb73eea44c0b0",
        "edition": "2313",
        "type": "Cheetah",
        "date": 1677695905076,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 23,
        "maxHealth": 131,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "2313",
        "rarity_score": 188.74844148146033,
        "rarity_level": "COMMON"
    },
    "188": {
        "name": "Brawler Bears #188",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/188.png",
        "dna": "fa5e2781641529416c94c017a0fda964cc307724",
        "edition": "188",
        "type": "Panda",
        "date": 1677695906533,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Mouth",
                "value": "Degen",
                "score": 29.264705882352942
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            }
        ],
        "level": 35,
        "maxHealth": 202,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "188",
        "rarity_score": 261.1355577440199,
        "rarity_level": "COMMON"
    },
    "12": {
        "name": "Brawler Bears #12",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/12.png",
        "dna": "a0ae2c0573e701f8f2901a5292fb7a281a2ef524",
        "edition": "12",
        "type": "Panda",
        "date": 1677695907896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sprinkles",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            }
        ],
        "level": 34,
        "maxHealth": 199,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "12",
        "rarity_score": 229.1923850375527,
        "rarity_level": "COMMON"
    },
    "1977": {
        "name": "Brawler Bears #1977",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1977.png",
        "dna": "7ff09f5467c9bf8e6be26b42c7691a261fefbc8e",
        "edition": "1977",
        "type": "Deep Martian Cyborg",
        "date": 1677695908622,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Pipin", "score": 31.09375 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1977",
        "rarity_score": 247.7436079214564,
        "rarity_level": "COMMON"
    },
    "1566": {
        "name": "Brawler Bears #1566",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1566.png",
        "dna": "f76dce8091f38475805cacf23a154a6596ae086d",
        "edition": "1566",
        "type": "Cheetah",
        "date": 1677695909664,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Mouth",
                "value": "Sinbad",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1566",
        "rarity_score": 153.62425763646837,
        "rarity_level": "COMMON"
    },
    "304": {
        "name": "Brawler Bears #304",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/304.png",
        "dna": "bc595088d1dcfdfbc173a8836218c3f715e263ea",
        "edition": "304",
        "type": "Brown Bear",
        "date": 1677695910332,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Mouth", "value": "Bamboo", "score": 39.8 },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            }
        ],
        "level": 58,
        "maxHealth": 336,
        "defaultAttack": { "name": "Bamboo", "damage": 72, "power": 11 },
        "id": "304",
        "rarity_score": 189.4493982038699,
        "rarity_level": "COMMON"
    },
    "1626": {
        "name": "Brawler Bears #1626",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1626.png",
        "dna": "8e3970db30050ed5d0857fb8cfdcd329cca40204",
        "edition": "1626",
        "type": "Galactic Cyborg",
        "date": 1677695911881,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Mouth",
                "value": "Aqua Zaddy Lover",
                "score": 35.535714285714285
            },
            { "trait_type": "Eyes", "value": "Sus", "score": 26.18421052631579 }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1626",
        "rarity_score": 237.0807551616489,
        "rarity_level": "COMMON"
    },
    "167": {
        "name": "Brawler Bears #167",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/167.png",
        "dna": "e11e420ca443e1f010d967a830f56f7a8f2a783b",
        "edition": "167",
        "type": "Black Bear",
        "date": 1677695913513,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "167",
        "rarity_score": 252.8521188747271,
        "rarity_level": "COMMON"
    },
    "1786": {
        "name": "Brawler Bears #1786",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1786.png",
        "dna": "a3751108e43daa480b7e2e6e663658a79ca1d2a1",
        "edition": "1786",
        "type": "Black Bear",
        "date": 1677695914969,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1786",
        "rarity_score": 299.9444499128862,
        "rarity_level": "RARE"
    },
    "1455": {
        "name": "Brawler Bears #1455",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1455.png",
        "dna": "cbe040b2a8f619c8e0e79bfc2f33ac473d86eb77",
        "edition": "1455",
        "type": "Cheetah",
        "date": 1677695915924,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1455",
        "rarity_score": 330.22825456385243,
        "rarity_level": "RARE"
    },
    "1984": {
        "name": "Brawler Bears #1984",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1984.png",
        "dna": "c28bba3a5e490368a6935d64d1af542ad5870a64",
        "edition": "1984",
        "type": "Ice",
        "date": 1677695917646,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1984",
        "rarity_score": 266.3368304507332,
        "rarity_level": "COMMON"
    },
    "2197": {
        "name": "Brawler Bears #2197",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2197.png",
        "dna": "402e652366634be91bfe3634ea740378ec8b092c",
        "edition": "2197",
        "type": "Acid Trip",
        "date": 1677695918436,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "2197",
        "rarity_score": 246.7053011197706,
        "rarity_level": "COMMON"
    },
    "471": {
        "name": "Brawler Bears #471",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/471.png",
        "dna": "4370b5ce6668a51e5512734dad6c50c59cdcb6b6",
        "edition": "471",
        "type": "Acid Trip",
        "date": 1677695919304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 31,
        "maxHealth": 177,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "471",
        "rarity_score": 281.6036964021912,
        "rarity_level": "RARE"
    },
    "1731": {
        "name": "Brawler Bears #1731",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1731.png",
        "dna": "d24a9d2edbc2ab6a41a49d0173c508044dda89a5",
        "edition": "1731",
        "type": "Lava",
        "date": 1677695920072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 36,
        "maxHealth": 205,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1731",
        "rarity_score": 265.07246672012883,
        "rarity_level": "COMMON"
    },
    "1753": {
        "name": "Brawler Bears #1753",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1753.png",
        "dna": "0bb810a1ccfa162682e51c65a232abca1645dc78",
        "edition": "1753",
        "type": "Brown Bear",
        "date": 1677695920918,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1753",
        "rarity_score": 304.6000786905997,
        "rarity_level": "RARE"
    },
    "1659": {
        "name": "Brawler Bears #1659",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1659.png",
        "dna": "c409a08e2006c2049fb386b5938c0956593ef32f",
        "edition": "1659",
        "type": "Deep Martian Cyborg",
        "date": 1677695921961,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1659",
        "rarity_score": 249.3453127686477,
        "rarity_level": "COMMON"
    },
    "340": {
        "name": "Brawler Bears #340",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/340.png",
        "dna": "806ba5ded80ac94c9041f7071d08707e51617705",
        "edition": "340",
        "type": "Black Bear",
        "date": 1677695923006,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 43,
        "maxHealth": 248,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "340",
        "rarity_score": 260.7445257436974,
        "rarity_level": "COMMON"
    },
    "1207": {
        "name": "Brawler Bears #1207",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1207.png",
        "dna": "2c93e8e19de102fc566827e672296d3a11c7ae49",
        "edition": "1207",
        "type": "Deep Martian Cyborg",
        "date": 1677695923901,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1207",
        "rarity_score": 241.88390339662982,
        "rarity_level": "COMMON"
    },
    "500": {
        "name": "Brawler Bears #500",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/500.png",
        "dna": "a4afed51137515e86ca79d709badf9c0ca9dac72",
        "edition": "500",
        "type": "Galactic Cyborg",
        "date": 1677695924904,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "500",
        "rarity_score": 262.79232962808356,
        "rarity_level": "COMMON"
    },
    "687": {
        "name": "Brawler Bears #687",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/687.png",
        "dna": "d1b83ba7fe7653474176816e289f28fe04aa5ac4",
        "edition": "687",
        "type": "Black Bear",
        "date": 1677695925853,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "687",
        "rarity_score": 220.07704049489192,
        "rarity_level": "COMMON"
    },
    "144": {
        "name": "Brawler Bears #144",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/144.png",
        "dna": "038abef30a9bcaef655016fa4cac755ed494ffe2",
        "edition": "144",
        "type": "Ice",
        "date": 1677695926648,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "144",
        "rarity_score": 246.22182408816596,
        "rarity_level": "COMMON"
    },
    "1567": {
        "name": "Brawler Bears #1567",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1567.png",
        "dna": "338cb19360eb6214656d916dec1c17c603350a0b",
        "edition": "1567",
        "type": "Black Bear",
        "date": 1677695927844,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1567",
        "rarity_score": 255.64131165462544,
        "rarity_level": "COMMON"
    },
    "1297": {
        "name": "Brawler Bears #1297",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1297.png",
        "dna": "43d92e0acade225431f399c9419130c7d1defa3d",
        "edition": "1297",
        "type": "Black Bear",
        "date": 1677695928923,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1297",
        "rarity_score": 337.06969232661675,
        "rarity_level": "RARE"
    },
    "596": {
        "name": "Brawler Bears #596",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/596.png",
        "dna": "87965c6e92d38318c3f477abf43d970e340e522c",
        "edition": "596",
        "type": "Galactic Cyborg",
        "date": 1677695929983,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "596",
        "rarity_score": 310.84336537048495,
        "rarity_level": "RARE"
    },
    "214": {
        "name": "Brawler Bears #214",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/214.png",
        "dna": "df7ab4ab40f20d3d3790f112270d405158b0b0b9",
        "edition": "214",
        "type": "Panda",
        "date": 1677695930804,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 26,
        "maxHealth": 149,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "214",
        "rarity_score": 221.82090315592967,
        "rarity_level": "COMMON"
    },
    "1452": {
        "name": "Brawler Bears #1452",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1452.png",
        "dna": "f2333f0e900bd6b18957907a76ed4e9b3f19d7c3",
        "edition": "1452",
        "type": "Galaxy",
        "date": 1677695931863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1452",
        "rarity_score": 378.08584425078215,
        "rarity_level": "RARE"
    },
    "1862": {
        "name": "Brawler Bears #1862",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1862.png",
        "dna": "06a62533f739db6dc19ba1b6a1ce84100cea34ee",
        "edition": "1862",
        "type": "Acid Trip",
        "date": 1677695932780,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 54,
        "maxHealth": 308,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1862",
        "rarity_score": 222.4939044356328,
        "rarity_level": "COMMON"
    },
    "1895": {
        "name": "Brawler Bears #1895",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1895.png",
        "dna": "cee4687a34d9b54d0b75eb13bc76fc70eb5c5c3e",
        "edition": "1895",
        "type": "Deep Martian Cyborg",
        "date": 1677695934111,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1895",
        "rarity_score": 329.6369210698276,
        "rarity_level": "RARE"
    },
    "263": {
        "name": "Brawler Bears #263",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/263.png",
        "dna": "0229a51a7ba030d8cdbc10fd18ef76fa778ff2ca",
        "edition": "263",
        "type": "Black Bear",
        "date": 1677695935074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "263",
        "rarity_score": 257.121116225273,
        "rarity_level": "COMMON"
    },
    "1417": {
        "name": "Brawler Bears #1417",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1417.png",
        "dna": "82600c9ac909fc8d5d255d760f93789c51759dc3",
        "edition": "1417",
        "type": "Brown Bear",
        "date": 1677695936716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 60,
        "maxHealth": 345,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1417",
        "rarity_score": 223.49652865289494,
        "rarity_level": "COMMON"
    },
    "2035": {
        "name": "Brawler Bears #2035",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2035.png",
        "dna": "8d55a812a9154ace14c0b6c61f19120dcfa87a71",
        "edition": "2035",
        "type": "Black Bear",
        "date": 1677695937367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 54,
        "maxHealth": 311,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2035",
        "rarity_score": 170.28047861585037,
        "rarity_level": "COMMON"
    },
    "1191": {
        "name": "Brawler Bears #1191",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1191.png",
        "dna": "6a83feeeb5b4f19d4be8201032d950e37b8d1476",
        "edition": "1191",
        "type": "Acid Trip",
        "date": 1677695939015,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 31,
        "maxHealth": 178,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1191",
        "rarity_score": 283.67945847347227,
        "rarity_level": "RARE"
    },
    "605": {
        "name": "Brawler Bears #605",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/605.png",
        "dna": "9fe654d0c433bbfa16edd20d314cbc8ebbddacaa",
        "edition": "605",
        "type": "Cheetah",
        "date": 1677695939933,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 41,
        "maxHealth": 238,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "605",
        "rarity_score": 172.59768481567878,
        "rarity_level": "COMMON"
    },
    "2161": {
        "name": "Brawler Bears #2161",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2161.png",
        "dna": "4daf215a8a6d17a2b075b67d62c624ec91405270",
        "edition": "2161",
        "type": "Lava",
        "date": 1677695940888,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2161",
        "rarity_score": 304.87098602580517,
        "rarity_level": "RARE"
    },
    "429": {
        "name": "Brawler Bears #429",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/429.png",
        "dna": "5c312d2d2bdb548396b0ead1c7a645630e59252f",
        "edition": "429",
        "type": "Ice",
        "date": 1677695942618,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "429",
        "rarity_score": 343.8579113353107,
        "rarity_level": "RARE"
    },
    "1604": {
        "name": "Brawler Bears #1604",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1604.png",
        "dna": "0f3d8b22411891f8b6aff40db2424a1a3c5752d4",
        "edition": "1604",
        "type": "Black Bear",
        "date": 1677695943376,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1604",
        "rarity_score": 226.33918634958346,
        "rarity_level": "COMMON"
    },
    "2043": {
        "name": "Brawler Bears #2043",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2043.png",
        "dna": "a65443b2ef3cce3b3e347ba41a877b32145e7c9e",
        "edition": "2043",
        "type": "Black Bear",
        "date": 1677695944243,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "2043",
        "rarity_score": 311.66266743807563,
        "rarity_level": "RARE"
    },
    "398": {
        "name": "Brawler Bears #398",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/398.png",
        "dna": "3706b87f6fc87858fc6db64cef4cac6823f8c892",
        "edition": "398",
        "type": "Deep Martian Cyborg",
        "date": 1677695945316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "398",
        "rarity_score": 329.63191433734073,
        "rarity_level": "RARE"
    },
    "1415": {
        "name": "Brawler Bears #1415",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1415.png",
        "dna": "f31287e7d096b27c8bf4739280acdaa6e8e7f602",
        "edition": "1415",
        "type": "Galactic Cyborg",
        "date": 1677695946982,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 48,
        "maxHealth": 277,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1415",
        "rarity_score": 176.47761519846975,
        "rarity_level": "COMMON"
    },
    "1819": {
        "name": "Brawler Bears #1819",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1819.png",
        "dna": "488288501c2c109c15e9c05764fde558813560f0",
        "edition": "1819",
        "type": "Panda",
        "date": 1677695947837,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 63,
        "maxHealth": 361,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1819",
        "rarity_score": 126.02593683544168,
        "rarity_level": "COMMON"
    },
    "806": {
        "name": "Brawler Bears #806",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/806.png",
        "dna": "4c54d7dc7ee2ce286e436da71f3f0966660ee108",
        "edition": "806",
        "type": "Deep Martian Cyborg",
        "date": 1677695948673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "806",
        "rarity_score": 261.5553370360523,
        "rarity_level": "COMMON"
    },
    "2185": {
        "name": "Brawler Bears #2185",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2185.png",
        "dna": "45b08f8f6e04827110f1f32bd6c1c7c90f5b3a1a",
        "edition": "2185",
        "type": "Galaxy",
        "date": 1677695950162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2185",
        "rarity_score": 244.17275998933772,
        "rarity_level": "COMMON"
    },
    "1360": {
        "name": "Brawler Bears #1360",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1360.png",
        "dna": "6494387167899190471411d2cf0c2cfdf96523bf",
        "edition": "1360",
        "type": "Galaxy",
        "date": 1677695951384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1360",
        "rarity_score": 330.5547066007494,
        "rarity_level": "RARE"
    },
    "1398": {
        "name": "Brawler Bears #1398",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1398.png",
        "dna": "670f8bc5bbf5d7a3a5d3370e18e699174df356d8",
        "edition": "1398",
        "type": "Ice",
        "date": 1677695952522,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 63,
        "maxHealth": 363,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1398",
        "rarity_score": 136.35963008527827,
        "rarity_level": "COMMON"
    },
    "2210": {
        "name": "Brawler Bears #2210",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2210.png",
        "dna": "98d40bbd7512977a8d82efab8d370416c66ecc02",
        "edition": "2210",
        "type": "Brown Bear",
        "date": 1677695953397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "2210",
        "rarity_score": 294.08295609676634,
        "rarity_level": "RARE"
    },
    "511": {
        "name": "Brawler Bears #511",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/511.png",
        "dna": "028eb266f395ef0f70e23b8929c726f686e3f89a",
        "edition": "511",
        "type": "Acid Trip",
        "date": 1677695954827,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 52,
        "maxHealth": 298,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "511",
        "rarity_score": 215.8526312445151,
        "rarity_level": "COMMON"
    },
    "103": {
        "name": "Brawler Bears #103",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/103.png",
        "dna": "3be1ad692aa3bf7c8cf598d6d97e26126bcc2f46",
        "edition": "103",
        "type": "Brown Bear",
        "date": 1677695956945,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "103",
        "rarity_score": 326.7429826468274,
        "rarity_level": "RARE"
    },
    "315": {
        "name": "Brawler Bears #315",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/315.png",
        "dna": "e101d0129ad5fb3283c40cdc586a8313ffb0acea",
        "edition": "315",
        "type": "Panda",
        "date": 1677695957765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "315",
        "rarity_score": 305.65690374930307,
        "rarity_level": "RARE"
    },
    "97": {
        "name": "Brawler Bears #97",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/97.png",
        "dna": "380c8f9de25c4cf3aa9da25683b412d7f18b3397",
        "edition": "97",
        "type": "Lava",
        "date": 1677695958627,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "97",
        "rarity_score": 275.1547666991457,
        "rarity_level": "RARE"
    },
    "1968": {
        "name": "Brawler Bears #1968",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1968.png",
        "dna": "8e35c28ea12d4c06cf2c48518f0779365b71ad3f",
        "edition": "1968",
        "type": "Brown Bear",
        "date": 1677695960210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1968",
        "rarity_score": 262.2674727759165,
        "rarity_level": "COMMON"
    },
    "611": {
        "name": "Brawler Bears #611",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/611.png",
        "dna": "5376e9dbecc8319ba418ae4c0df6fb361b6dc548",
        "edition": "611",
        "type": "Brown Bear",
        "date": 1677695961275,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 22,
        "maxHealth": 131,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "611",
        "rarity_score": 316.57730813943135,
        "rarity_level": "RARE"
    },
    "1220": {
        "name": "Brawler Bears #1220",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1220.png",
        "dna": "878cad3c9c1616b41c81858b04563816fe2746a2",
        "edition": "1220",
        "type": "Black Bear",
        "date": 1677695961981,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1220",
        "rarity_score": 223.43915487395398,
        "rarity_level": "COMMON"
    },
    "1657": {
        "name": "Brawler Bears #1657",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1657.png",
        "dna": "c90b5042630df39692b2e9e77d931c4d9c978119",
        "edition": "1657",
        "type": "Galactic Cyborg",
        "date": 1677695962971,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 18,
        "maxHealth": 105,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1657",
        "rarity_score": 287.94728132137004,
        "rarity_level": "RARE"
    },
    "1399": {
        "name": "Brawler Bears #1399",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1399.png",
        "dna": "419e721aa63263732687d4c025664e092e72325b",
        "edition": "1399",
        "type": "Cheetah",
        "date": 1677695964865,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1399",
        "rarity_score": 384.4391803143146,
        "rarity_level": "RARE"
    },
    "1849": {
        "name": "Brawler Bears #1849",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1849.png",
        "dna": "37329b887ccbaf6f5e0c9d33fad63f21e1a33f8b",
        "edition": "1849",
        "type": "Black Bear",
        "date": 1677695965690,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 59,
        "maxHealth": 339,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1849",
        "rarity_score": 196.35188470338034,
        "rarity_level": "COMMON"
    },
    "112": {
        "name": "Brawler Bears #112",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/112.png",
        "dna": "1dacbf8185713583bc5f267fc7af281d2f999983",
        "edition": "112",
        "type": "Deep Martian Cyborg",
        "date": 1677695966741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "112",
        "rarity_score": 306.4076922459799,
        "rarity_level": "RARE"
    },
    "1218": {
        "name": "Brawler Bears #1218",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1218.png",
        "dna": "ca6fb99a1bed2c6f2b7b2a088fc1650d5288f30a",
        "edition": "1218",
        "type": "Brown Bear",
        "date": 1677695967469,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 24,
        "maxHealth": 139,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1218",
        "rarity_score": 299.3341201087571,
        "rarity_level": "RARE"
    },
    "581": {
        "name": "Brawler Bears #581",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/581.png",
        "dna": "51bf35391b7b9a6bfdf7c9b219f3cee688d18fd6",
        "edition": "581",
        "type": "Black Bear",
        "date": 1677695968346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "581",
        "rarity_score": 229.71890952520343,
        "rarity_level": "COMMON"
    },
    "1140": {
        "name": "Brawler Bears #1140",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1140.png",
        "dna": "6519e9769f1c8e296a7676bc0d4af0c544877f6e",
        "edition": "1140",
        "type": "Cheetah",
        "date": 1677695969365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1140",
        "rarity_score": 313.79669426581563,
        "rarity_level": "RARE"
    },
    "1378": {
        "name": "Brawler Bears #1378",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1378.png",
        "dna": "898dd5ec0aa8ae9f3d0a7bb51398647cdc06e561",
        "edition": "1378",
        "type": "Brown Bear",
        "date": 1677695970997,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1378",
        "rarity_score": 266.6243615675025,
        "rarity_level": "COMMON"
    },
    "591": {
        "name": "Brawler Bears #591",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/591.png",
        "dna": "19e876e7295730957e0b5d53d3300ea91d01c016",
        "edition": "591",
        "type": "Panda",
        "date": 1677695972271,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "591",
        "rarity_score": 265.8817758873235,
        "rarity_level": "COMMON"
    },
    "959": {
        "name": "Brawler Bears #959",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/959.png",
        "dna": "4af269e50dcd5814de2a6c4288c58601795b1e75",
        "edition": "959",
        "type": "Brown Bear",
        "date": 1677695973470,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "959",
        "rarity_score": 281.7641200157205,
        "rarity_level": "RARE"
    },
    "1164": {
        "name": "Brawler Bears #1164",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1164.png",
        "dna": "2bb897bce6773638e5ad0a05843e47a74268475a",
        "edition": "1164",
        "type": "Galactic Cyborg",
        "date": 1677695974488,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1164",
        "rarity_score": 312.5211998477849,
        "rarity_level": "RARE"
    },
    "1270": {
        "name": "Brawler Bears #1270",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1270.png",
        "dna": "194f598023657383fbda37723d38c8bbb2fe0c57",
        "edition": "1270",
        "type": "Galactic Cyborg",
        "date": 1677695975716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 18,
        "maxHealth": 106,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1270",
        "rarity_score": 299.7077015245442,
        "rarity_level": "RARE"
    },
    "427": {
        "name": "Brawler Bears #427",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/427.png",
        "dna": "9a6ec6495326192e7be483cfdbf1aca7eb87eeb7",
        "edition": "427",
        "type": "Deep Martian Cyborg",
        "date": 1677695976461,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 48,
        "maxHealth": 278,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "427",
        "rarity_score": 179.78505651466838,
        "rarity_level": "COMMON"
    },
    "888": {
        "name": "Brawler Bears #888",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/888.png",
        "dna": "c22e94317d4d11b2139d8a63cc6ed92512f8f2b4",
        "edition": "888",
        "type": "Deep Martian Cyborg",
        "date": 1677695977160,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 55,
        "maxHealth": 314,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "888",
        "rarity_score": 194.27098494942018,
        "rarity_level": "COMMON"
    },
    "1234": {
        "name": "Brawler Bears #1234",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1234.png",
        "dna": "a92f0f1ed73a98f51302378b2a296e2e52518171",
        "edition": "1234",
        "type": "Galactic Cyborg",
        "date": 1677695977980,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1234",
        "rarity_score": 319.7026147205992,
        "rarity_level": "RARE"
    },
    "1067": {
        "name": "Brawler Bears #1067",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1067.png",
        "dna": "576155772868f0d5ab2d303d107b4359fb28614c",
        "edition": "1067",
        "type": "Black Bear",
        "date": 1677695978888,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1067",
        "rarity_score": 223.96989744122862,
        "rarity_level": "COMMON"
    },
    "52": {
        "name": "Brawler Bears #52",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/52.png",
        "dna": "7d0831b1c83ae5954ca959ace9473e60f0c8b605",
        "edition": "52",
        "type": "Brown Bear",
        "date": 1677695979763,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "52",
        "rarity_score": 309.44600329249766,
        "rarity_level": "RARE"
    },
    "1477": {
        "name": "Brawler Bears #1477",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1477.png",
        "dna": "558738f7de4ac1cda2a028216a5d6cccd42af9d0",
        "edition": "1477",
        "type": "Cheetah",
        "date": 1677695980758,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1477",
        "rarity_score": 250.5648297560597,
        "rarity_level": "COMMON"
    },
    "1666": {
        "name": "Brawler Bears #1666",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1666.png",
        "dna": "ec2d44ba44c76e4826a5742653c801e6b8f85ea9",
        "edition": "1666",
        "type": "Brown Bear",
        "date": 1677695981769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 39,
        "maxHealth": 228,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1666",
        "rarity_score": 298.1838548836787,
        "rarity_level": "RARE"
    },
    "152": {
        "name": "Brawler Bears #152",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/152.png",
        "dna": "d7f51d91ba6afe04a4ee76e8f8e13e95a513358e",
        "edition": "152",
        "type": "Galactic Cyborg",
        "date": 1677695982725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 18,
        "maxHealth": 104,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "152",
        "rarity_score": 284.8689735042603,
        "rarity_level": "RARE"
    },
    "1825": {
        "name": "Brawler Bears #1825",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1825.png",
        "dna": "b3a182cf533312697b48b6394ebca0be09fa7957",
        "edition": "1825",
        "type": "Deep Martian Cyborg",
        "date": 1677695983439,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1825",
        "rarity_score": 284.66507035611846,
        "rarity_level": "RARE"
    },
    "715": {
        "name": "Brawler Bears #715",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/715.png",
        "dna": "055776ba099a022dbc16a7386d0edc6d95fda2a6",
        "edition": "715",
        "type": "Brown Bear",
        "date": 1677695984733,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "715",
        "rarity_score": 353.15403641502934,
        "rarity_level": "RARE"
    },
    "251": {
        "name": "Brawler Bears #251",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/251.png",
        "dna": "d54c65e28e07ebbe8d3c881107bb6d98a628d908",
        "edition": "251",
        "type": "Deep Martian Cyborg",
        "date": 1677695985816,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "251",
        "rarity_score": 252.40113014755013,
        "rarity_level": "COMMON"
    },
    "190": {
        "name": "Brawler Bears #190",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/190.png",
        "dna": "a03d9d5ef28f0177b4c21ddb1e6a3a5824c48020",
        "edition": "190",
        "type": "Lava",
        "date": 1677695986744,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "190",
        "rarity_score": 336.11406841295,
        "rarity_level": "RARE"
    },
    "461": {
        "name": "Brawler Bears #461",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/461.png",
        "dna": "06267bfa6802a35a6e295eda96d035fec5e5e95c",
        "edition": "461",
        "type": "Black Bear",
        "date": 1677695987463,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 60,
        "maxHealth": 344,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "461",
        "rarity_score": 180.93442587930014,
        "rarity_level": "COMMON"
    },
    "305": {
        "name": "Brawler Bears #305",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/305.png",
        "dna": "7f1e67b29ae45e18b3154059120f080fe21f8a39",
        "edition": "305",
        "type": "Cheetah",
        "date": 1677695988339,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 62,
        "maxHealth": 356,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "305",
        "rarity_score": 134.5599274392767,
        "rarity_level": "COMMON"
    },
    "1574": {
        "name": "Brawler Bears #1574",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1574.png",
        "dna": "7accde3c525f1ca218a708832c236f2f020b2971",
        "edition": "1574",
        "type": "Panda",
        "date": 1677695989339,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 26,
        "maxHealth": 150,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1574",
        "rarity_score": 229.63271847694256,
        "rarity_level": "COMMON"
    },
    "559": {
        "name": "Brawler Bears #559",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/559.png",
        "dna": "88f9b46390cf3828fc623492d55100647dba767a",
        "edition": "559",
        "type": "Panda",
        "date": 1677695990272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "559",
        "rarity_score": 281.49757562809657,
        "rarity_level": "RARE"
    },
    "1371": {
        "name": "Brawler Bears #1371",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1371.png",
        "dna": "68c29ed34202821947a58fb3adf0eed1188ec742",
        "edition": "1371",
        "type": "Cheetah",
        "date": 1677695991651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 25,
        "maxHealth": 146,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1371",
        "rarity_score": 215.98177749051774,
        "rarity_level": "COMMON"
    },
    "1639": {
        "name": "Brawler Bears #1639",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1639.png",
        "dna": "a61ff16535a82c000e8a13eb76ee0a02a95c0de5",
        "edition": "1639",
        "type": "Deep Martian Cyborg",
        "date": 1677695992447,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1639",
        "rarity_score": 274.50510844790256,
        "rarity_level": "COMMON"
    },
    "2179": {
        "name": "Brawler Bears #2179",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2179.png",
        "dna": "cbe3b63fad57706515725edc47aa969948fea483",
        "edition": "2179",
        "type": "Panda",
        "date": 1677695993469,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 62,
        "maxHealth": 358,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2179",
        "rarity_score": 146.6096077314544,
        "rarity_level": "COMMON"
    },
    "1893": {
        "name": "Brawler Bears #1893",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1893.png",
        "dna": "548cf66438129985fd1ad1df39653d853c44c915",
        "edition": "1893",
        "type": "Deep Martian Cyborg",
        "date": 1677695994537,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1893",
        "rarity_score": 270.1934833992924,
        "rarity_level": "COMMON"
    },
    "1810": {
        "name": "Brawler Bears #1810",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1810.png",
        "dna": "8cab4b53df94e25af51cae30e5df5cf7eae04b5c",
        "edition": "1810",
        "type": "Brown Bear",
        "date": 1677695995536,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1810",
        "rarity_score": 265.34462147136054,
        "rarity_level": "COMMON"
    },
    "893": {
        "name": "Brawler Bears #893",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/893.png",
        "dna": "8e36db1f883556e03d720d0a780840cdc173802e",
        "edition": "893",
        "type": "Acid Trip",
        "date": 1677695996506,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "893",
        "rarity_score": 241.06498729429308,
        "rarity_level": "COMMON"
    },
    "2036": {
        "name": "Brawler Bears #2036",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2036.png",
        "dna": "2b4b5a7df817a36c3ca93c2f2c601ac624862850",
        "edition": "2036",
        "type": "Lava",
        "date": 1677695997359,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2036",
        "rarity_score": 272.66814541147846,
        "rarity_level": "COMMON"
    },
    "84": {
        "name": "Brawler Bears #84",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/84.png",
        "dna": "e5073605533b021ad9883876bbfbbac1c2cd1d9d",
        "edition": "84",
        "type": "Brown Bear",
        "date": 1677695998457,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "84",
        "rarity_score": 278.9144715572508,
        "rarity_level": "RARE"
    },
    "2040": {
        "name": "Brawler Bears #2040",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2040.png",
        "dna": "e0b37774c5738d489461c27fd70632c91edc080a",
        "edition": "2040",
        "type": "Brown Bear",
        "date": 1677695999427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "2040",
        "rarity_score": 317.6694765100002,
        "rarity_level": "RARE"
    },
    "1430": {
        "name": "Brawler Bears #1430",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1430.png",
        "dna": "00d4728629f13722cd5e0e9344d0e8080ded042d",
        "edition": "1430",
        "type": "Black Bear",
        "date": 1677696000250,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1430",
        "rarity_score": 283.1142693448378,
        "rarity_level": "RARE"
    },
    "1115": {
        "name": "Brawler Bears #1115",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1115.png",
        "dna": "61aec2a3f85c3dc76237da7936c60f0a114a3e39",
        "edition": "1115",
        "type": "Acid Trip",
        "date": 1677696001829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 62,
        "maxHealth": 357,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1115",
        "rarity_score": 149.4376195254624,
        "rarity_level": "COMMON"
    },
    "1511": {
        "name": "Brawler Bears #1511",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1511.png",
        "dna": "b442a1115da5015729338b503ef94bbf0a55047e",
        "edition": "1511",
        "type": "Panda",
        "date": 1677696003125,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "1511",
        "rarity_score": 308.0787285732938,
        "rarity_level": "RARE"
    },
    "993": {
        "name": "Brawler Bears #993",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/993.png",
        "dna": "4041eaa19ec41efa0915e2a51c8d9bd122f91ee3",
        "edition": "993",
        "type": "Ice",
        "date": 1677696004456,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "993",
        "rarity_score": 285.46026920404285,
        "rarity_level": "RARE"
    },
    "740": {
        "name": "Brawler Bears #740",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/740.png",
        "dna": "38fcd5f68692a3b9dc115e25c5bc104f75296c34",
        "edition": "740",
        "type": "Galactic Cyborg",
        "date": 1677696005540,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "740",
        "rarity_score": 328.7868140139797,
        "rarity_level": "RARE"
    },
    "1329": {
        "name": "Brawler Bears #1329",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1329.png",
        "dna": "8ae97c5ad7fe3344c5bdfa97adabd2cbd47fa3cc",
        "edition": "1329",
        "type": "Black Bear",
        "date": 1677696006634,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 22,
        "maxHealth": 125,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1329",
        "rarity_score": 321.7504639589801,
        "rarity_level": "RARE"
    },
    "378": {
        "name": "Brawler Bears #378",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/378.png",
        "dna": "170fb959e98c956234d900d258fc82653190b3a2",
        "edition": "378",
        "type": "Black Bear",
        "date": 1677696008356,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "378",
        "rarity_score": 326.71708966991474,
        "rarity_level": "RARE"
    },
    "1503": {
        "name": "Brawler Bears #1503",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1503.png",
        "dna": "fbbbe8a4e930f250a9f6c740bc6d4f5310eb1837",
        "edition": "1503",
        "type": "Black Bear",
        "date": 1677696009192,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 69,
        "maxHealth": 396,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1503",
        "rarity_score": 128.7910749474578,
        "rarity_level": "COMMON"
    },
    "921": {
        "name": "Brawler Bears #921",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/921.png",
        "dna": "1f6e3809b987672f08c5db69f56d59ef5022598d",
        "edition": "921",
        "type": "Brown Bear",
        "date": 1677696010302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 45,
        "maxHealth": 257,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "921",
        "rarity_score": 273.20232183709635,
        "rarity_level": "COMMON"
    },
    "195": {
        "name": "Brawler Bears #195",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/195.png",
        "dna": "94ce3de90cfe23a75d8a228d78ae0178a12717a9",
        "edition": "195",
        "type": "Black Bear",
        "date": 1677696011649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "195",
        "rarity_score": 327.98258006230674,
        "rarity_level": "RARE"
    },
    "26": {
        "name": "Brawler Bears #26",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/26.png",
        "dna": "b79708d309b0ef7c5d48ae66066ba193737a865b",
        "edition": "26",
        "type": "Deep Martian Cyborg",
        "date": 1677696012727,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "26",
        "rarity_score": 257.5303968102361,
        "rarity_level": "COMMON"
    },
    "1773": {
        "name": "Brawler Bears #1773",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1773.png",
        "dna": "cf848b4d118a50823cc2d6bf9b98efd68845397a",
        "edition": "1773",
        "type": "Galaxy",
        "date": 1677696014378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1773",
        "rarity_score": 236.2925353454447,
        "rarity_level": "COMMON"
    },
    "1999": {
        "name": "Brawler Bears #1999",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1999.png",
        "dna": "76faaa535b511e674ff859fc4e223b13ca87422b",
        "edition": "1999",
        "type": "Ice",
        "date": 1677696015491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1999",
        "rarity_score": 178.02459042593827,
        "rarity_level": "COMMON"
    },
    "38": {
        "name": "Brawler Bears #38",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/38.png",
        "dna": "22beee3a2e682df3f76c0c31a1ea5cb93a5d508d",
        "edition": "38",
        "type": "Brown Bear",
        "date": 1677696016790,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "38",
        "rarity_score": 257.90198719528166,
        "rarity_level": "COMMON"
    },
    "1734": {
        "name": "Brawler Bears #1734",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1734.png",
        "dna": "87704f6119fa8c0af88f189ac736f9c12c42e8f3",
        "edition": "1734",
        "type": "Acid Trip",
        "date": 1677696017520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1734",
        "rarity_score": 276.0564028293349,
        "rarity_level": "RARE"
    },
    "1310": {
        "name": "Brawler Bears #1310",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1310.png",
        "dna": "bbbae5d86650370deab417650df2c5237f35a22b",
        "edition": "1310",
        "type": "Black Bear",
        "date": 1677696018404,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 42,
        "maxHealth": 242,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1310",
        "rarity_score": 311.9110667393714,
        "rarity_level": "RARE"
    },
    "2090": {
        "name": "Brawler Bears #2090",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2090.png",
        "dna": "b1d81780d32e2bd7507634d09c5f992dfe22b62b",
        "edition": "2090",
        "type": "Acid Trip",
        "date": 1677696019164,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 62,
        "maxHealth": 356,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2090",
        "rarity_score": 143.58477770022603,
        "rarity_level": "COMMON"
    },
    "937": {
        "name": "Brawler Bears #937",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/937.png",
        "dna": "f1a9221f2bd49e4a9f0ae6ae0ba6e0adcac34897",
        "edition": "937",
        "type": "Brown Bear",
        "date": 1677696020629,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "937",
        "rarity_score": 310.24119453027515,
        "rarity_level": "RARE"
    },
    "178": {
        "name": "Brawler Bears #178",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/178.png",
        "dna": "39964b7e3be9aba76f98462cf550815e5bf9182d",
        "edition": "178",
        "type": "Brown Bear",
        "date": 1677696021385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 69,
        "maxHealth": 399,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "178",
        "rarity_score": 134.62846342425073,
        "rarity_level": "COMMON"
    },
    "366": {
        "name": "Brawler Bears #366",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/366.png",
        "dna": "86b90b0740b3f55a6ea0d7415b5aaaa2b301137c",
        "edition": "366",
        "type": "Black Bear",
        "date": 1677696022426,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "366",
        "rarity_score": 174.94055809993364,
        "rarity_level": "COMMON"
    },
    "2204": {
        "name": "Brawler Bears #2204",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2204.png",
        "dna": "87b46fcec840ede90bda7b480770e66a0971383d",
        "edition": "2204",
        "type": "Ice",
        "date": 1677696023718,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2204",
        "rarity_score": 246.99689974492512,
        "rarity_level": "COMMON"
    },
    "1951": {
        "name": "Brawler Bears #1951",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1951.png",
        "dna": "9a0e72195f6ee8fa1708ecb02b7dc0989536ffb4",
        "edition": "1951",
        "type": "Galaxy",
        "date": 1677696024845,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 18,
        "maxHealth": 107,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1951",
        "rarity_score": 274.3075942168049,
        "rarity_level": "COMMON"
    },
    "1949": {
        "name": "Brawler Bears #1949",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1949.png",
        "dna": "1dee24b9afd724690806b3b239e07b1e7fd351d6",
        "edition": "1949",
        "type": "Galaxy",
        "date": 1677696026404,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1949",
        "rarity_score": 323.866983136701,
        "rarity_level": "RARE"
    },
    "1466": {
        "name": "Brawler Bears #1466",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1466.png",
        "dna": "15015ae4171458eb8f69980b99fdbee2abd7dff5",
        "edition": "1466",
        "type": "Panda",
        "date": 1677696027358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1466",
        "rarity_score": 316.32852876708296,
        "rarity_level": "RARE"
    },
    "1651": {
        "name": "Brawler Bears #1651",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1651.png",
        "dna": "c3cbd2f7320540af7d4773aa7c4bb796ccf4d113",
        "edition": "1651",
        "type": "Galaxy",
        "date": 1677696028613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 54,
        "maxHealth": 312,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1651",
        "rarity_score": 216.11423504671953,
        "rarity_level": "COMMON"
    },
    "1655": {
        "name": "Brawler Bears #1655",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1655.png",
        "dna": "bf339ba00854c4642b36ffdde7f704fadcf25d38",
        "edition": "1655",
        "type": "Deep Martian Cyborg",
        "date": 1677696029749,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1655",
        "rarity_score": 285.97535975046253,
        "rarity_level": "RARE"
    },
    "862": {
        "name": "Brawler Bears #862",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/862.png",
        "dna": "1e7f821afb4a57b2171ebb62f5687bfc098352ed",
        "edition": "862",
        "type": "Panda",
        "date": 1677696030954,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "862",
        "rarity_score": 316.98669018503233,
        "rarity_level": "RARE"
    },
    "1953": {
        "name": "Brawler Bears #1953",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1953.png",
        "dna": "3a0fbb6443a035e3d5c813246cec6a4d18086412",
        "edition": "1953",
        "type": "Galaxy",
        "date": 1677696032460,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1953",
        "rarity_score": 311.6210587938202,
        "rarity_level": "RARE"
    },
    "320": {
        "name": "Brawler Bears #320",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/320.png",
        "dna": "f0988768a5b91dbc535eee32167828b6ad2c9e54",
        "edition": "320",
        "type": "Black Bear",
        "date": 1677696033174,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 42,
        "maxHealth": 242,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "320",
        "rarity_score": 303.1881745709853,
        "rarity_level": "RARE"
    },
    "1896": {
        "name": "Brawler Bears #1896",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1896.png",
        "dna": "6223ddb952d4b8e671953a89cf26d5b3345110c3",
        "edition": "1896",
        "type": "Galaxy",
        "date": 1677696034688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 27,
        "maxHealth": 157,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1896",
        "rarity_score": 241.45847499973772,
        "rarity_level": "COMMON"
    },
    "474": {
        "name": "Brawler Bears #474",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/474.png",
        "dna": "463127456f4c654875622f4ed78a7be4ad98baba",
        "edition": "474",
        "type": "Deep Martian Cyborg",
        "date": 1677696035943,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 54,
        "maxHealth": 310,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "474",
        "rarity_score": 248.8195960752875,
        "rarity_level": "COMMON"
    },
    "1358": {
        "name": "Brawler Bears #1358",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1358.png",
        "dna": "44c701de94973a836e886926939de76b66dc2412",
        "edition": "1358",
        "type": "Galaxy",
        "date": 1677696037082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1358",
        "rarity_score": 258.88259556501663,
        "rarity_level": "COMMON"
    },
    "1638": {
        "name": "Brawler Bears #1638",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1638.png",
        "dna": "78ce7d579fc32bdcc0ac44f04ce75096c40400c7",
        "edition": "1638",
        "type": "Ice",
        "date": 1677696038773,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1638",
        "rarity_score": 247.5641614920026,
        "rarity_level": "COMMON"
    },
    "568": {
        "name": "Brawler Bears #568",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/568.png",
        "dna": "ea3d03cdb6f6248a1b2c954345af82766200c57c",
        "edition": "568",
        "type": "Acid Trip",
        "date": 1677696039723,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "568",
        "rarity_score": 316.1740608533798,
        "rarity_level": "RARE"
    },
    "1642": {
        "name": "Brawler Bears #1642",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1642.png",
        "dna": "791a3662e74d1b3dfe49ab1c890551c7894d1ed7",
        "edition": "1642",
        "type": "Black Bear",
        "date": 1677696041156,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1642",
        "rarity_score": 300.632387021714,
        "rarity_level": "RARE"
    },
    "1216": {
        "name": "Brawler Bears #1216",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1216.png",
        "dna": "1e3a4abdcc4fc8942e3f0ad823a4fdecf018b3b0",
        "edition": "1216",
        "type": "Brown Bear",
        "date": 1677696042411,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1216",
        "rarity_score": 283.73153910141605,
        "rarity_level": "RARE"
    },
    "1607": {
        "name": "Brawler Bears #1607",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1607.png",
        "dna": "8304e3038549972fb57915ad9b674005aa12aeb2",
        "edition": "1607",
        "type": "Acid Trip",
        "date": 1677696043334,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 14,
        "maxHealth": 82,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1607",
        "rarity_score": 315.3767408720669,
        "rarity_level": "RARE"
    },
    "1184": {
        "name": "Brawler Bears #1184",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1184.png",
        "dna": "9ee197b082bfb3643a7e8aad043f66a0718d2f75",
        "edition": "1184",
        "type": "Acid Trip",
        "date": 1677696044605,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 31,
        "maxHealth": 181,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1184",
        "rarity_score": 283.4412413860847,
        "rarity_level": "RARE"
    },
    "1427": {
        "name": "Brawler Bears #1427",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1427.png",
        "dna": "4300d0f46d61093fe43774859fe05117e983ffa3",
        "edition": "1427",
        "type": "Brown Bear",
        "date": 1677696045901,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 61,
        "maxHealth": 352,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1427",
        "rarity_score": 189.73512705977475,
        "rarity_level": "COMMON"
    },
    "2122": {
        "name": "Brawler Bears #2122",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2122.png",
        "dna": "1b5d7337542b280a602313040863fc27d1502f8d",
        "edition": "2122",
        "type": "Black Bear",
        "date": 1677696046577,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "2122",
        "rarity_score": 238.80401328842473,
        "rarity_level": "COMMON"
    },
    "2135": {
        "name": "Brawler Bears #2135",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2135.png",
        "dna": "50b62af4019c6be24f21ca8e70fa7817c44e9f5b",
        "edition": "2135",
        "type": "Brown Bear",
        "date": 1677696047765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2135",
        "rarity_score": 258.5009396695468,
        "rarity_level": "COMMON"
    },
    "216": {
        "name": "Brawler Bears #216",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/216.png",
        "dna": "696f9afc6490e93def48556d5c3dcf075c952ea1",
        "edition": "216",
        "type": "Lava",
        "date": 1677696049549,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 15,
        "maxHealth": 85,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "216",
        "rarity_score": 304.0604909149432,
        "rarity_level": "RARE"
    },
    "2026": {
        "name": "Brawler Bears #2026",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2026.png",
        "dna": "5101fc878ae062aebf2539815c7928cab5071b4f",
        "edition": "2026",
        "type": "Acid Trip",
        "date": 1677696050861,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 52,
        "maxHealth": 302,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2026",
        "rarity_score": 190.25400939063678,
        "rarity_level": "COMMON"
    },
    "1978": {
        "name": "Brawler Bears #1978",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1978.png",
        "dna": "7e38b25539ad5784ab624797a187d7fe52ca28b9",
        "edition": "1978",
        "type": "Lava",
        "date": 1677696051666,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 51,
        "maxHealth": 294,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1978",
        "rarity_score": 251.78944861898904,
        "rarity_level": "COMMON"
    },
    "73": {
        "name": "Brawler Bears #73",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/73.png",
        "dna": "c166c69bb641fe1eb9df3cdb4aef055bb9530843",
        "edition": "73",
        "type": "Galaxy",
        "date": 1677696053039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "73",
        "rarity_score": 262.4184405816518,
        "rarity_level": "COMMON"
    },
    "2216": {
        "name": "Brawler Bears #2216",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2216.png",
        "dna": "87f1dea42236da93d15a72598e1d43b773538029",
        "edition": "2216",
        "type": "Galactic Cyborg",
        "date": 1677696053851,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "2216",
        "rarity_score": 171.72340725253937,
        "rarity_level": "COMMON"
    },
    "2166": {
        "name": "Brawler Bears #2166",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2166.png",
        "dna": "e814bac9a4920a23c46605458e88a07322449bb1",
        "edition": "2166",
        "type": "Ice",
        "date": 1677696054663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2166",
        "rarity_score": 316.28903731386606,
        "rarity_level": "RARE"
    },
    "452": {
        "name": "Brawler Bears #452",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/452.png",
        "dna": "63d1b10c9829e37db78c32f76c51ae276339c0af",
        "edition": "452",
        "type": "Deep Martian Cyborg",
        "date": 1677696056533,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 48,
        "maxHealth": 274,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "452",
        "rarity_score": 186.01261761773455,
        "rarity_level": "COMMON"
    },
    "1465": {
        "name": "Brawler Bears #1465",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1465.png",
        "dna": "7d7bfc93d1849168923e444ba2bc53a50ce7e6c5",
        "edition": "1465",
        "type": "Galaxy",
        "date": 1677696058028,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 17,
        "maxHealth": 102,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1465",
        "rarity_score": 278.85055985698074,
        "rarity_level": "RARE"
    },
    "1592": {
        "name": "Brawler Bears #1592",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1592.png",
        "dna": "dd1f161dc8c1e4cd327de1d00e29b813c4454dcb",
        "edition": "1592",
        "type": "Galactic Cyborg",
        "date": 1677696058821,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 17,
        "maxHealth": 100,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1592",
        "rarity_score": 304.65063901931154,
        "rarity_level": "RARE"
    },
    "2074": {
        "name": "Brawler Bears #2074",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2074.png",
        "dna": "67883c0e6d7a199b9c7af244932771d043e11874",
        "edition": "2074",
        "type": "Brown Bear",
        "date": 1677696060357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 60,
        "maxHealth": 346,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2074",
        "rarity_score": 254.33558914147395,
        "rarity_level": "COMMON"
    },
    "1332": {
        "name": "Brawler Bears #1332",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1332.png",
        "dna": "5d21767166ce6e1f494c19166d84bbd13a8978e9",
        "edition": "1332",
        "type": "Brown Bear",
        "date": 1677696061495,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 24,
        "maxHealth": 138,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1332",
        "rarity_score": 307.17391003055246,
        "rarity_level": "RARE"
    },
    "1407": {
        "name": "Brawler Bears #1407",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1407.png",
        "dna": "c908753a41a3cc2f63d731b7b4882fb336dea673",
        "edition": "1407",
        "type": "Lava",
        "date": 1677696062608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 30,
        "maxHealth": 175,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1407",
        "rarity_score": 300.96760614372215,
        "rarity_level": "RARE"
    },
    "1962": {
        "name": "Brawler Bears #1962",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1962.png",
        "dna": "56696c38f0ba1d008c0b4dedc8cde43fa98d8c84",
        "edition": "1962",
        "type": "Deep Martian Cyborg",
        "date": 1677696063907,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1962",
        "rarity_score": 293.4182159149507,
        "rarity_level": "RARE"
    },
    "823": {
        "name": "Brawler Bears #823",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/823.png",
        "dna": "433aba74492004efc4dd205d444fd76f97587a01",
        "edition": "823",
        "type": "Cheetah",
        "date": 1677696064847,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "823",
        "rarity_score": 350.61195570570567,
        "rarity_level": "RARE"
    },
    "2013": {
        "name": "Brawler Bears #2013",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2013.png",
        "dna": "f632dc2f798ee5f49d80c07a45d2e8ca057c1aa0",
        "edition": "2013",
        "type": "Acid Trip",
        "date": 1677696066032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 30,
        "maxHealth": 176,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2013",
        "rarity_score": 287.97590298719865,
        "rarity_level": "RARE"
    },
    "947": {
        "name": "Brawler Bears #947",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/947.png",
        "dna": "aff8d5d3233c8f0f005751ae391824ce0a2e5b3e",
        "edition": "947",
        "type": "Brown Bear",
        "date": 1677696067627,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "947",
        "rarity_score": 327.3476558188209,
        "rarity_level": "RARE"
    },
    "790": {
        "name": "Brawler Bears #790",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/790.png",
        "dna": "fd791e85de8de00f964ab6d6fba8ca60031a30d9",
        "edition": "790",
        "type": "Acid Trip",
        "date": 1677696068811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "790",
        "rarity_score": 270.29923679456635,
        "rarity_level": "COMMON"
    },
    "1476": {
        "name": "Brawler Bears #1476",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1476.png",
        "dna": "b1dfc4da65e56b2a27f25847518067cd95f60f78",
        "edition": "1476",
        "type": "Panda",
        "date": 1677696070236,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 41,
        "maxHealth": 239,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1476",
        "rarity_score": 177.24726669507416,
        "rarity_level": "COMMON"
    },
    "693": {
        "name": "Brawler Bears #693",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/693.png",
        "dna": "c5f9dfa5052b61b38a896414e8426cd695ad4c6a",
        "edition": "693",
        "type": "Deep Martian Cyborg",
        "date": 1677696071220,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "693",
        "rarity_score": 295.63502254294553,
        "rarity_level": "RARE"
    },
    "8": {
        "name": "Brawler Bears #8",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/8.png",
        "dna": "2b2f1d8059db2f6930edabd7626b2e4cb7e55348",
        "edition": "8",
        "type": "Black Bear",
        "date": 1677696071953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "8",
        "rarity_score": 191.17070200797852,
        "rarity_level": "COMMON"
    },
    "1180": {
        "name": "Brawler Bears #1180",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1180.png",
        "dna": "482cd8304944896ac080187af7941af97f27be42",
        "edition": "1180",
        "type": "Brown Bear",
        "date": 1677696072895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1180",
        "rarity_score": 358.5659287077395,
        "rarity_level": "RARE"
    },
    "116": {
        "name": "Brawler Bears #116",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/116.png",
        "dna": "509146f4ef846c10619df1890e2c1fdca95d57a0",
        "edition": "116",
        "type": "Panda",
        "date": 1677696074148,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "116",
        "rarity_score": 316.15538086722796,
        "rarity_level": "RARE"
    },
    "540": {
        "name": "Brawler Bears #540",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/540.png",
        "dna": "22ba1ad8699c7b723aca69aa0ec837d83d0a3d96",
        "edition": "540",
        "type": "Lava",
        "date": 1677696075725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "540",
        "rarity_score": 371.1890040962622,
        "rarity_level": "RARE"
    },
    "926": {
        "name": "Brawler Bears #926",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/926.png",
        "dna": "cc04e1aaeee81332b8e6e125e723cc246192f634",
        "edition": "926",
        "type": "Brown Bear",
        "date": 1677696076798,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "926",
        "rarity_score": 316.6510249268424,
        "rarity_level": "RARE"
    },
    "298": {
        "name": "Brawler Bears #298",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/298.png",
        "dna": "6418d937ad08c2e892d60492b5c837a7a172f0d1",
        "edition": "298",
        "type": "Black Bear",
        "date": 1677696077550,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 49,
        "maxHealth": 280,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "298",
        "rarity_score": 163.92671189385925,
        "rarity_level": "COMMON"
    },
    "2259": {
        "name": "Brawler Bears #2259",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2259.png",
        "dna": "7d558af6b304fd8fe5429ed970b5802d048213e3",
        "edition": "2259",
        "type": "Black Bear",
        "date": 1677696079340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "2259",
        "rarity_score": 249.43474897695035,
        "rarity_level": "COMMON"
    },
    "1783": {
        "name": "Brawler Bears #1783",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1783.png",
        "dna": "536684f52d2760b8a265c55448b8f9bc1690a327",
        "edition": "1783",
        "type": "Acid Trip",
        "date": 1677696080192,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1783",
        "rarity_score": 256.26037505054575,
        "rarity_level": "COMMON"
    },
    "234": {
        "name": "Brawler Bears #234",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/234.png",
        "dna": "4da092f413aaec694983254ffeedc3a34fd9dcf3",
        "edition": "234",
        "type": "Deep Martian Cyborg",
        "date": 1677696081399,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "234",
        "rarity_score": 322.08694060921346,
        "rarity_level": "RARE"
    },
    "1309": {
        "name": "Brawler Bears #1309",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1309.png",
        "dna": "0492a6f28e59c83caa093a1993c19841e47f4b2e",
        "edition": "1309",
        "type": "Deep Martian Cyborg",
        "date": 1677696082201,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1309",
        "rarity_score": 268.63800159825485,
        "rarity_level": "COMMON"
    },
    "1202": {
        "name": "Brawler Bears #1202",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1202.png",
        "dna": "b1dd0d050b7bcf7a6ea2819b9bb0b59f49703a69",
        "edition": "1202",
        "type": "Lava",
        "date": 1677696083142,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1202",
        "rarity_score": 336.2167068420568,
        "rarity_level": "RARE"
    },
    "2318": {
        "name": "Brawler Bears #2318",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2318.png",
        "dna": "ace82744525c34922a401cb28f9539c414a6a9cc",
        "edition": "2318",
        "type": "Brown Bear",
        "date": 1677696083968,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2318",
        "rarity_score": 253.22333678813212,
        "rarity_level": "COMMON"
    },
    "856": {
        "name": "Brawler Bears #856",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/856.png",
        "dna": "a0151908622e37b38c10d6670dc517b6c179349e",
        "edition": "856",
        "type": "Black Bear",
        "date": 1677696085009,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "856",
        "rarity_score": 192.84663033241918,
        "rarity_level": "COMMON"
    },
    "577": {
        "name": "Brawler Bears #577",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/577.png",
        "dna": "e9ba59e2bd6a17ec4b084e70d13230fa0a762c56",
        "edition": "577",
        "type": "Black Bear",
        "date": 1677696085746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 49,
        "maxHealth": 284,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "577",
        "rarity_score": 161.49465991390036,
        "rarity_level": "COMMON"
    },
    "902": {
        "name": "Brawler Bears #902",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/902.png",
        "dna": "c7e96aa423c5c6b655e852b99210781288368d17",
        "edition": "902",
        "type": "Brown Bear",
        "date": 1677696087083,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "902",
        "rarity_score": 325.92997716085034,
        "rarity_level": "RARE"
    },
    "564": {
        "name": "Brawler Bears #564",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/564.png",
        "dna": "726afc9dda407b71325f70c8350e5bb83c892b69",
        "edition": "564",
        "type": "Galaxy",
        "date": 1677696088200,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "564",
        "rarity_score": 182.3314825850561,
        "rarity_level": "COMMON"
    },
    "155": {
        "name": "Brawler Bears #155",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/155.png",
        "dna": "9391ebf1ac927e10f424e22b342677414dad10fe",
        "edition": "155",
        "type": "Black Bear",
        "date": 1677696089599,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "155",
        "rarity_score": 227.49687721548955,
        "rarity_level": "COMMON"
    },
    "337": {
        "name": "Brawler Bears #337",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/337.png",
        "dna": "3e3158e962a16cfc4e3bbc9e91620999d63a5eb7",
        "edition": "337",
        "type": "Black Bear",
        "date": 1677696090946,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "337",
        "rarity_score": 268.3804383847175,
        "rarity_level": "COMMON"
    },
    "1059": {
        "name": "Brawler Bears #1059",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1059.png",
        "dna": "2bd42dc298701542176107331c3e73349eebc9ac",
        "edition": "1059",
        "type": "Lava",
        "date": 1677696092173,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 45,
        "maxHealth": 260,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1059",
        "rarity_score": 182.8195245238925,
        "rarity_level": "COMMON"
    },
    "1492": {
        "name": "Brawler Bears #1492",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1492.png",
        "dna": "421970c6378a4b680da21970c2871d0a458c7c68",
        "edition": "1492",
        "type": "Deep Martian Cyborg",
        "date": 1677696093469,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1492",
        "rarity_score": 306.71591933822526,
        "rarity_level": "RARE"
    },
    "1321": {
        "name": "Brawler Bears #1321",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1321.png",
        "dna": "ba1046fb0c4b8885afda34c48ceb6ae6012a6190",
        "edition": "1321",
        "type": "Black Bear",
        "date": 1677696094146,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1321",
        "rarity_score": 198.31848552418026,
        "rarity_level": "COMMON"
    },
    "467": {
        "name": "Brawler Bears #467",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/467.png",
        "dna": "2593c6e4609681528af8d6c026013e65bddd5790",
        "edition": "467",
        "type": "Galactic Cyborg",
        "date": 1677696095161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "467",
        "rarity_score": 254.5241758929098,
        "rarity_level": "COMMON"
    },
    "395": {
        "name": "Brawler Bears #395",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/395.png",
        "dna": "152bc459d508a37c4220ce745cfdac5fa7ee2f86",
        "edition": "395",
        "type": "Panda",
        "date": 1677696095830,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "395",
        "rarity_score": 261.85813605986795,
        "rarity_level": "COMMON"
    },
    "1750": {
        "name": "Brawler Bears #1750",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1750.png",
        "dna": "d698e354a3a398f45270f90be0ad298113500274",
        "edition": "1750",
        "type": "Ice",
        "date": 1677696096998,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1750",
        "rarity_score": 284.52346130476315,
        "rarity_level": "RARE"
    },
    "2068": {
        "name": "Brawler Bears #2068",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2068.png",
        "dna": "b5843cd152690cc52718020a485f941bc4a06494",
        "edition": "2068",
        "type": "Galactic Cyborg",
        "date": 1677696098080,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "2068",
        "rarity_score": 359.3630232297518,
        "rarity_level": "RARE"
    },
    "1307": {
        "name": "Brawler Bears #1307",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1307.png",
        "dna": "640c9f67674d99d9ef4d331e6709ffaf66444ed3",
        "edition": "1307",
        "type": "Brown Bear",
        "date": 1677696099258,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1307",
        "rarity_score": 384.94494103373825,
        "rarity_level": "RARE"
    },
    "1456": {
        "name": "Brawler Bears #1456",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1456.png",
        "dna": "6b5b073104f60e97be57939f8bc1896f7d2069bf",
        "edition": "1456",
        "type": "Ice",
        "date": 1677696100897,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1456",
        "rarity_score": 289.4554095164641,
        "rarity_level": "RARE"
    },
    "1543": {
        "name": "Brawler Bears #1543",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1543.png",
        "dna": "11f048d547579df847476016b1994686a2437db0",
        "edition": "1543",
        "type": "Acid Trip",
        "date": 1677696102141,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 13,
        "maxHealth": 77,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1543",
        "rarity_score": 395.281880713155,
        "rarity_level": "RARE"
    },
    "361": {
        "name": "Brawler Bears #361",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/361.png",
        "dna": "1e67f0dbae54ef90b26670d57789e2c89eb84435",
        "edition": "361",
        "type": "Deep Martian Cyborg",
        "date": 1677696103302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 54,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "361",
        "rarity_score": 194.49127357420795,
        "rarity_level": "COMMON"
    },
    "1754": {
        "name": "Brawler Bears #1754",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1754.png",
        "dna": "55e1a64f001d5abda218e9019e1ddb2ee9584c9a",
        "edition": "1754",
        "type": "Black Bear",
        "date": 1677696104145,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1754",
        "rarity_score": 267.9747460287518,
        "rarity_level": "COMMON"
    },
    "1814": {
        "name": "Brawler Bears #1814",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1814.png",
        "dna": "ed42b952d9729645eb8f35ed41e0ee8edbd12e11",
        "edition": "1814",
        "type": "Galaxy",
        "date": 1677696105063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1814",
        "rarity_score": 278.55205848838824,
        "rarity_level": "RARE"
    },
    "505": {
        "name": "Brawler Bears #505",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/505.png",
        "dna": "5d39a8bba682bf7838245732a8a2c61bedb8531a",
        "edition": "505",
        "type": "Brown Bear",
        "date": 1677696105810,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 40,
        "maxHealth": 233,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "505",
        "rarity_score": 237.576479657261,
        "rarity_level": "COMMON"
    },
    "566": {
        "name": "Brawler Bears #566",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/566.png",
        "dna": "41bbf5bccc01db83e552ddc7a3cee752cdb7fc10",
        "edition": "566",
        "type": "Brown Bear",
        "date": 1677696106490,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 61,
        "maxHealth": 352,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "566",
        "rarity_score": 225.88946427924884,
        "rarity_level": "COMMON"
    },
    "53": {
        "name": "Brawler Bears #53",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/53.png",
        "dna": "84d2bbfc3da47357b8f772b2dbbb88a95581df90",
        "edition": "53",
        "type": "Deep Martian Cyborg",
        "date": 1677696107336,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 63,
        "maxHealth": 364,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "53",
        "rarity_score": 138.31940664238135,
        "rarity_level": "COMMON"
    },
    "1852": {
        "name": "Brawler Bears #1852",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1852.png",
        "dna": "6ad61649aad1fc07856ee114d0bb5c49baaf2061",
        "edition": "1852",
        "type": "Black Bear",
        "date": 1677696108397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1852",
        "rarity_score": 332.04935606712365,
        "rarity_level": "RARE"
    },
    "62": {
        "name": "Brawler Bears #62",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/62.png",
        "dna": "8576bedbb2ea1f150292a829ace71ea430c5bc95",
        "edition": "62",
        "type": "Brown Bear",
        "date": 1677696109420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 23,
        "maxHealth": 131,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "62",
        "rarity_score": 312.77410345107126,
        "rarity_level": "RARE"
    },
    "662": {
        "name": "Brawler Bears #662",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/662.png",
        "dna": "7fd631546fcd57a63eadafe1f0148141619a8101",
        "edition": "662",
        "type": "Black Bear",
        "date": 1677696110284,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "662",
        "rarity_score": 248.06597446100469,
        "rarity_level": "COMMON"
    },
    "105": {
        "name": "Brawler Bears #105",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/105.png",
        "dna": "c00dd1c83a3ca8be69a266dcee295ab3b6e5d519",
        "edition": "105",
        "type": "Galaxy",
        "date": 1677696111526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "105",
        "rarity_score": 348.28779255332586,
        "rarity_level": "RARE"
    },
    "2164": {
        "name": "Brawler Bears #2164",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2164.png",
        "dna": "7b5502039f58eb01e00802e499ef1963006ca173",
        "edition": "2164",
        "type": "Galaxy",
        "date": 1677696113042,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 28,
        "maxHealth": 162,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "2164",
        "rarity_score": 209.28912687057374,
        "rarity_level": "COMMON"
    },
    "2107": {
        "name": "Brawler Bears #2107",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2107.png",
        "dna": "ceafe37d20a7260be6f588723c1605dbe2b11a2f",
        "edition": "2107",
        "type": "Acid Trip",
        "date": 1677696114058,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2107",
        "rarity_score": 366.74070864460737,
        "rarity_level": "RARE"
    },
    "2110": {
        "name": "Brawler Bears #2110",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2110.png",
        "dna": "e27daae039df08e6eb363462085d3782ffa28e38",
        "edition": "2110",
        "type": "Black Bear",
        "date": 1677696115326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "2110",
        "rarity_score": 293.1641718910684,
        "rarity_level": "RARE"
    },
    "1515": {
        "name": "Brawler Bears #1515",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1515.png",
        "dna": "b9e7083fa858945d91fe3779c007915421ffe5a0",
        "edition": "1515",
        "type": "Ice",
        "date": 1677696116570,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1515",
        "rarity_score": 371.8600589225589,
        "rarity_level": "RARE"
    },
    "1204": {
        "name": "Brawler Bears #1204",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1204.png",
        "dna": "c283899a7cfbacfbf159c8109fddc23553605782",
        "edition": "1204",
        "type": "Brown Bear",
        "date": 1677696118046,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 44,
        "maxHealth": 256,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1204",
        "rarity_score": 272.45817094219507,
        "rarity_level": "COMMON"
    },
    "1616": {
        "name": "Brawler Bears #1616",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1616.png",
        "dna": "02536cc1d48de7514b04f60e079b08f3490daa79",
        "edition": "1616",
        "type": "Deep Martian Cyborg",
        "date": 1677696119499,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1616",
        "rarity_score": 286.3877076425129,
        "rarity_level": "RARE"
    },
    "949": {
        "name": "Brawler Bears #949",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/949.png",
        "dna": "a1c3780b89f5576778f46ec4d3698b3b14baaa21",
        "edition": "949",
        "type": "Panda",
        "date": 1677696120415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 26,
        "maxHealth": 150,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "949",
        "rarity_score": 226.1905221447942,
        "rarity_level": "COMMON"
    },
    "121": {
        "name": "Brawler Bears #121",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/121.png",
        "dna": "63e9386d32de35ec75e841f59c5969ae2c8cdb53",
        "edition": "121",
        "type": "Lava",
        "date": 1677696121404,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "121",
        "rarity_score": 271.387932844508,
        "rarity_level": "COMMON"
    },
    "1066": {
        "name": "Brawler Bears #1066",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1066.png",
        "dna": "e4d9ea10097cb9b1bbc344054984403771e0bacb",
        "edition": "1066",
        "type": "Brown Bear",
        "date": 1677696122371,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1066",
        "rarity_score": 329.05971216464286,
        "rarity_level": "RARE"
    },
    "1606": {
        "name": "Brawler Bears #1606",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1606.png",
        "dna": "04c82ee807f6694559a4d63b9b8f9042d688473e",
        "edition": "1606",
        "type": "Galactic Cyborg",
        "date": 1677696123583,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1606",
        "rarity_score": 275.1775884660244,
        "rarity_level": "RARE"
    },
    "676": {
        "name": "Brawler Bears #676",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/676.png",
        "dna": "aeea1a0d33452ea4c13aa84b8533ea0603a0417a",
        "edition": "676",
        "type": "Black Bear",
        "date": 1677696124750,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "676",
        "rarity_score": 234.72483347890588,
        "rarity_level": "COMMON"
    },
    "1564": {
        "name": "Brawler Bears #1564",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1564.png",
        "dna": "cf78d0f6b4a5919dcefdafcc89cebf265e8e5637",
        "edition": "1564",
        "type": "Deep Martian Cyborg",
        "date": 1677696125722,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "1564",
        "rarity_score": 243.29358560998833,
        "rarity_level": "COMMON"
    },
    "998": {
        "name": "Brawler Bears #998",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/998.png",
        "dna": "d186b605cfaf669f61fa21617c8a4b061836cc30",
        "edition": "998",
        "type": "Galaxy",
        "date": 1677696126990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "998",
        "rarity_score": 348.2258138687479,
        "rarity_level": "RARE"
    },
    "1858": {
        "name": "Brawler Bears #1858",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1858.png",
        "dna": "0d43b5c8ca4fed6a0bb623acb2c7c11eff8f941b",
        "edition": "1858",
        "type": "Brown Bear",
        "date": 1677696128135,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 24,
        "maxHealth": 139,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1858",
        "rarity_score": 283.9696558392375,
        "rarity_level": "RARE"
    },
    "94": {
        "name": "Brawler Bears #94",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/94.png",
        "dna": "f06a2e1e88d4fec2d64659db97eb2643e95abfb4",
        "edition": "94",
        "type": "Galactic Cyborg",
        "date": 1677696128876,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 54,
        "maxHealth": 312,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "94",
        "rarity_score": 249.4376177762016,
        "rarity_level": "COMMON"
    },
    "201": {
        "name": "Brawler Bears #201",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/201.png",
        "dna": "302213ba07fceb5b2bd2c327630e0c1b69092e80",
        "edition": "201",
        "type": "Ice",
        "date": 1677696129700,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 47,
        "maxHealth": 269,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "201",
        "rarity_score": 187.3719571353072,
        "rarity_level": "COMMON"
    },
    "654": {
        "name": "Brawler Bears #654",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/654.png",
        "dna": "3864c21bc5127eca4d19d5891d50d8eaa29655c0",
        "edition": "654",
        "type": "Brown Bear",
        "date": 1677696130472,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 44,
        "maxHealth": 256,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "654",
        "rarity_score": 299.8253467312521,
        "rarity_level": "RARE"
    },
    "2203": {
        "name": "Brawler Bears #2203",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2203.png",
        "dna": "884fa47cf4b1d3cf8f270c0ef6143bc3144ec723",
        "edition": "2203",
        "type": "Black Bear",
        "date": 1677696131241,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "2203",
        "rarity_score": 231.84697621036577,
        "rarity_level": "COMMON"
    },
    "992": {
        "name": "Brawler Bears #992",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/992.png",
        "dna": "9cd00963a36bf313949412e13eee1cc051cbb2a4",
        "edition": "992",
        "type": "Ice",
        "date": 1677696132327,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "992",
        "rarity_score": 309.61009455016807,
        "rarity_level": "RARE"
    },
    "117": {
        "name": "Brawler Bears #117",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/117.png",
        "dna": "75bdf9ea43f878dcd282c8ada1fbb8410ddd3bde",
        "edition": "117",
        "type": "Black Bear",
        "date": 1677696133171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 22,
        "maxHealth": 127,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "117",
        "rarity_score": 310.0252016011689,
        "rarity_level": "RARE"
    },
    "2119": {
        "name": "Brawler Bears #2119",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2119.png",
        "dna": "20f18789816e5fc29ed340994f094da3ebe22a76",
        "edition": "2119",
        "type": "Galaxy",
        "date": 1677696134085,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 34,
        "maxHealth": 196,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "2119",
        "rarity_score": 298.75928670949605,
        "rarity_level": "RARE"
    },
    "187": {
        "name": "Brawler Bears #187",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/187.png",
        "dna": "2097fabdceb3968f189494d48091adf204593f81",
        "edition": "187",
        "type": "Panda",
        "date": 1677696134776,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "187",
        "rarity_score": 326.67336914440574,
        "rarity_level": "RARE"
    },
    "1475": {
        "name": "Brawler Bears #1475",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1475.png",
        "dna": "5196fcfcba2a36ecdfb7a4442b3dd9e753849d00",
        "edition": "1475",
        "type": "Brown Bear",
        "date": 1677696135885,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1475",
        "rarity_score": 398.88189582731087,
        "rarity_level": "RARE"
    },
    "288": {
        "name": "Brawler Bears #288",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/288.png",
        "dna": "bc51f108df1691d9e81e120c52c418f90321f2c4",
        "edition": "288",
        "type": "Deep Martian Cyborg",
        "date": 1677696136624,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "288",
        "rarity_score": 248.6847186478065,
        "rarity_level": "COMMON"
    },
    "1255": {
        "name": "Brawler Bears #1255",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1255.png",
        "dna": "eebc4427ff505c7ae01a649b2fd46d3b6df479a5",
        "edition": "1255",
        "type": "Lava",
        "date": 1677696137450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 51,
        "maxHealth": 294,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1255",
        "rarity_score": 264.2962437865849,
        "rarity_level": "COMMON"
    },
    "945": {
        "name": "Brawler Bears #945",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/945.png",
        "dna": "d377bae80031fd2c405ffe78eb964ae1a0c12091",
        "edition": "945",
        "type": "Deep Martian Cyborg",
        "date": 1677696138762,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "945",
        "rarity_score": 247.51290390378153,
        "rarity_level": "COMMON"
    },
    "126": {
        "name": "Brawler Bears #126",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/126.png",
        "dna": "777a6d32b7ec87bf620b2f9526b4da37cf3c73ea",
        "edition": "126",
        "type": "Cheetah",
        "date": 1677696139708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "126",
        "rarity_score": 367.7153880964533,
        "rarity_level": "RARE"
    },
    "923": {
        "name": "Brawler Bears #923",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/923.png",
        "dna": "38219a2bab04767438b2fb2e7e55a16b0e934d22",
        "edition": "923",
        "type": "Galaxy",
        "date": 1677696140673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 34,
        "maxHealth": 196,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "923",
        "rarity_score": 259.6266902099731,
        "rarity_level": "COMMON"
    },
    "2297": {
        "name": "Brawler Bears #2297",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2297.png",
        "dna": "227ba57c6c44a7c8a220c4c46d8508753977c2f5",
        "edition": "2297",
        "type": "Ice",
        "date": 1677696141837,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 26,
        "maxHealth": 153,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2297",
        "rarity_score": 227.4295992260969,
        "rarity_level": "COMMON"
    },
    "745": {
        "name": "Brawler Bears #745",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/745.png",
        "dna": "ef990365d00ad40bf214a5b2ec39efa2faf0fd02",
        "edition": "745",
        "type": "Brown Bear",
        "date": 1677696142584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 61,
        "maxHealth": 349,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "745",
        "rarity_score": 198.6987108195355,
        "rarity_level": "COMMON"
    },
    "402": {
        "name": "Brawler Bears #402",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/402.png",
        "dna": "a45d19cb9f90755d3837f61917d991e30746aca7",
        "edition": "402",
        "type": "Brown Bear",
        "date": 1677696143730,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "402",
        "rarity_score": 311.81407156949695,
        "rarity_level": "RARE"
    },
    "346": {
        "name": "Brawler Bears #346",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/346.png",
        "dna": "01266bc9ed6dcdbfbda0af9a7906cb9ba267c377",
        "edition": "346",
        "type": "Galaxy",
        "date": 1677696144858,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "346",
        "rarity_score": 324.4916204314852,
        "rarity_level": "RARE"
    },
    "1764": {
        "name": "Brawler Bears #1764",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1764.png",
        "dna": "3d5b088ac61e875ae5ea7e8bd0328ce55ad3cfde",
        "edition": "1764",
        "type": "Cheetah",
        "date": 1677696145954,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1764",
        "rarity_score": 231.59218022591077,
        "rarity_level": "COMMON"
    },
    "1790": {
        "name": "Brawler Bears #1790",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1790.png",
        "dna": "0a80995e6d5225019bafec9d3eb54093b71cfbb9",
        "edition": "1790",
        "type": "Galactic Cyborg",
        "date": 1677696146882,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "1790",
        "rarity_score": 260.1687017322798,
        "rarity_level": "COMMON"
    },
    "1844": {
        "name": "Brawler Bears #1844",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1844.png",
        "dna": "d94fbcbd504f44d8a919053e5c0d2069f4c3c085",
        "edition": "1844",
        "type": "Ice",
        "date": 1677696148302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1844",
        "rarity_score": 311.4007265856319,
        "rarity_level": "RARE"
    },
    "2242": {
        "name": "Brawler Bears #2242",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2242.png",
        "dna": "83f50df350285b2f055107d9ba13491e69c315d1",
        "edition": "2242",
        "type": "Black Bear",
        "date": 1677696149444,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "2242",
        "rarity_score": 271.35591043398637,
        "rarity_level": "COMMON"
    },
    "1536": {
        "name": "Brawler Bears #1536",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1536.png",
        "dna": "831b890fcf6212cd8989b2a20b266968620440c7",
        "edition": "1536",
        "type": "Deep Martian Cyborg",
        "date": 1677696150318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1536",
        "rarity_score": 306.702946596563,
        "rarity_level": "RARE"
    },
    "1438": {
        "name": "Brawler Bears #1438",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1438.png",
        "dna": "8c78ef2a17c6a4afa0dfc91924f5f9a1a565245d",
        "edition": "1438",
        "type": "Panda",
        "date": 1677696152295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1438",
        "rarity_score": 396.0557503053412,
        "rarity_level": "RARE"
    },
    "579": {
        "name": "Brawler Bears #579",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/579.png",
        "dna": "a698ae0c13ce7e279b0ee2d19a742761db128436",
        "edition": "579",
        "type": "Ice",
        "date": 1677696154051,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "579",
        "rarity_score": 378.8275394727461,
        "rarity_level": "RARE"
    },
    "2038": {
        "name": "Brawler Bears #2038",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2038.png",
        "dna": "f87e5dccddaf016c8165df39b77c068c5e862646",
        "edition": "2038",
        "type": "Brown Bear",
        "date": 1677696154808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 60,
        "maxHealth": 344,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2038",
        "rarity_score": 224.06240921888246,
        "rarity_level": "COMMON"
    },
    "2044": {
        "name": "Brawler Bears #2044",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2044.png",
        "dna": "572291359cce6069330e59dedd79d54e1fd34891",
        "edition": "2044",
        "type": "Galaxy",
        "date": 1677696155768,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2044",
        "rarity_score": 309.27116825174426,
        "rarity_level": "RARE"
    },
    "1576": {
        "name": "Brawler Bears #1576",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1576.png",
        "dna": "b894ac59743cfbea80fb8468fe532fcf303526a6",
        "edition": "1576",
        "type": "Galactic Cyborg",
        "date": 1677696156713,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1576",
        "rarity_score": 276.45611551823566,
        "rarity_level": "RARE"
    },
    "1013": {
        "name": "Brawler Bears #1013",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1013.png",
        "dna": "69d02a20933ec463b6c6c180de456dd08b19c62b",
        "edition": "1013",
        "type": "Black Bear",
        "date": 1677696158361,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1013",
        "rarity_score": 257.0409251012298,
        "rarity_level": "COMMON"
    },
    "1244": {
        "name": "Brawler Bears #1244",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1244.png",
        "dna": "b4ca4a64dab51f8626bed50ec9be89d283e21860",
        "edition": "1244",
        "type": "Ice",
        "date": 1677696159531,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1244",
        "rarity_score": 331.3987657812619,
        "rarity_level": "RARE"
    },
    "265": {
        "name": "Brawler Bears #265",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/265.png",
        "dna": "556167961e32ce3923182e935e8b10c6adf0077e",
        "edition": "265",
        "type": "Galaxy",
        "date": 1677696160639,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "265",
        "rarity_score": 252.09054608752714,
        "rarity_level": "COMMON"
    },
    "641": {
        "name": "Brawler Bears #641",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/641.png",
        "dna": "61d0487ccc6af86d2fca04cf238d577864f8f3a1",
        "edition": "641",
        "type": "Panda",
        "date": 1677696161618,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 37,
        "maxHealth": 213,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "641",
        "rarity_score": 264.37546321703957,
        "rarity_level": "COMMON"
    },
    "1627": {
        "name": "Brawler Bears #1627",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1627.png",
        "dna": "4943b21f3c19a739f03ec12851ac3dd220553631",
        "edition": "1627",
        "type": "Galaxy",
        "date": 1677696162893,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 27,
        "maxHealth": 155,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1627",
        "rarity_score": 217.36730067445748,
        "rarity_level": "COMMON"
    },
    "951": {
        "name": "Brawler Bears #951",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/951.png",
        "dna": "c154409ab52efbf1ef6bac8bfeb99e62de131e2a",
        "edition": "951",
        "type": "Brown Bear",
        "date": 1677696163779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "951",
        "rarity_score": 315.55495768933804,
        "rarity_level": "RARE"
    },
    "1269": {
        "name": "Brawler Bears #1269",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1269.png",
        "dna": "346bfef6bd5b7146e42ad70550db698cab99c4d1",
        "edition": "1269",
        "type": "Black Bear",
        "date": 1677696164910,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1269",
        "rarity_score": 296.9035232146871,
        "rarity_level": "RARE"
    },
    "1468": {
        "name": "Brawler Bears #1468",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1468.png",
        "dna": "bf04f70c63031890e62e56e679b9d79852500776",
        "edition": "1468",
        "type": "Lava",
        "date": 1677696166349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1468",
        "rarity_score": 306.56662155130914,
        "rarity_level": "RARE"
    },
    "127": {
        "name": "Brawler Bears #127",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/127.png",
        "dna": "1e52db080b3e6c11a9684356d38fcbbde3796d5d",
        "edition": "127",
        "type": "Galaxy",
        "date": 1677696168121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 27,
        "maxHealth": 158,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "127",
        "rarity_score": 223.73481924731823,
        "rarity_level": "COMMON"
    },
    "905": {
        "name": "Brawler Bears #905",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/905.png",
        "dna": "979d6c9f068a643311d0207e3c86b5e8873de73c",
        "edition": "905",
        "type": "Black Bear",
        "date": 1677696169085,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "905",
        "rarity_score": 300.74303285057283,
        "rarity_level": "RARE"
    },
    "2191": {
        "name": "Brawler Bears #2191",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2191.png",
        "dna": "389f51381fc282d2d1cc5a7665af1b771d94b98d",
        "edition": "2191",
        "type": "Lava",
        "date": 1677696170345,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 46,
        "maxHealth": 264,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2191",
        "rarity_score": 177.1966080302664,
        "rarity_level": "COMMON"
    },
    "327": {
        "name": "Brawler Bears #327",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/327.png",
        "dna": "581d44c3fe062f1d7da2900ba53e430356f2770e",
        "edition": "327",
        "type": "Black Bear",
        "date": 1677696171403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "327",
        "rarity_score": 239.22525987699683,
        "rarity_level": "COMMON"
    },
    "212": {
        "name": "Brawler Bears #212",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/212.png",
        "dna": "06ceec97a8c70e64f09531eae9088fb765267c97",
        "edition": "212",
        "type": "Black Bear",
        "date": 1677696172493,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "212",
        "rarity_score": 299.40562122236753,
        "rarity_level": "RARE"
    },
    "1348": {
        "name": "Brawler Bears #1348",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1348.png",
        "dna": "902f33517ae29d5c059538abfb1fc22cc463f7ef",
        "edition": "1348",
        "type": "Ice",
        "date": 1677696173850,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1348",
        "rarity_score": 312.00673299650447,
        "rarity_level": "RARE"
    },
    "1090": {
        "name": "Brawler Bears #1090",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1090.png",
        "dna": "55ff86acba0c67d65b6effca517a52abbfb8e80d",
        "edition": "1090",
        "type": "Galaxy",
        "date": 1677696175405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 17,
        "maxHealth": 100,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1090",
        "rarity_score": 306.65269425901516,
        "rarity_level": "RARE"
    },
    "619": {
        "name": "Brawler Bears #619",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/619.png",
        "dna": "dcbb44c66f128b638ef04e288ee2541e490acba1",
        "edition": "619",
        "type": "Ice",
        "date": 1677696176608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "619",
        "rarity_score": 279.45022562186193,
        "rarity_level": "RARE"
    },
    "1064": {
        "name": "Brawler Bears #1064",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1064.png",
        "dna": "91d9e39c6c0397f7d24d441a9788b385286dae88",
        "edition": "1064",
        "type": "Galactic Cyborg",
        "date": 1677696177644,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 53,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1064",
        "rarity_score": 226.65573105150972,
        "rarity_level": "COMMON"
    },
    "2277": {
        "name": "Brawler Bears #2277",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2277.png",
        "dna": "947d71493c51434a405b6fbab7329ade7721fba9",
        "edition": "2277",
        "type": "Acid Trip",
        "date": 1677696178377,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 45,
        "maxHealth": 262,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2277",
        "rarity_score": 170.94633046882876,
        "rarity_level": "COMMON"
    },
    "137": {
        "name": "Brawler Bears #137",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/137.png",
        "dna": "6d87c072e2a3280bce553bd4ae1cf221287eee76",
        "edition": "137",
        "type": "Galactic Cyborg",
        "date": 1677696179521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "137",
        "rarity_score": 334.6129243209561,
        "rarity_level": "RARE"
    },
    "2046": {
        "name": "Brawler Bears #2046",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2046.png",
        "dna": "6b7b678c5b44a075eccded0bb87aad3c436cf6d0",
        "edition": "2046",
        "type": "Ice",
        "date": 1677696180335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2046",
        "rarity_score": 329.65145098323404,
        "rarity_level": "RARE"
    },
    "1727": {
        "name": "Brawler Bears #1727",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1727.png",
        "dna": "77cf5fb3bbe6d585d2ccc24dec4c7701f9b0e746",
        "edition": "1727",
        "type": "Galactic Cyborg",
        "date": 1677696181472,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1727",
        "rarity_score": 347.9590440735917,
        "rarity_level": "RARE"
    },
    "640": {
        "name": "Brawler Bears #640",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/640.png",
        "dna": "4612e7114da82be3e24c39fe76feab59dfe833b2",
        "edition": "640",
        "type": "Brown Bear",
        "date": 1677696182298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 39,
        "maxHealth": 228,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "640",
        "rarity_score": 263.05863638200657,
        "rarity_level": "COMMON"
    },
    "1219": {
        "name": "Brawler Bears #1219",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1219.png",
        "dna": "96cdb116c962e77b26c05b48e123c1ee3e587d00",
        "edition": "1219",
        "type": "Galactic Cyborg",
        "date": 1677696183698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 16,
        "maxHealth": 97,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1219",
        "rarity_score": 307.5422308557024,
        "rarity_level": "RARE"
    },
    "1055": {
        "name": "Brawler Bears #1055",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1055.png",
        "dna": "be5ae0c991b2ebfcd669af231db62a16651bcbaf",
        "edition": "1055",
        "type": "Ice",
        "date": 1677696184526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 54,
        "maxHealth": 308,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1055",
        "rarity_score": 289.99384228278916,
        "rarity_level": "RARE"
    },
    "613": {
        "name": "Brawler Bears #613",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/613.png",
        "dna": "194fdb50e699542d380ed6a418f1ae2444aac216",
        "edition": "613",
        "type": "Black Bear",
        "date": 1677696186288,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "613",
        "rarity_score": 174.85327497154145,
        "rarity_level": "COMMON"
    },
    "2152": {
        "name": "Brawler Bears #2152",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2152.png",
        "dna": "5267add57f01c5b5390f8a8106468c7e5b32908d",
        "edition": "2152",
        "type": "Black Bear",
        "date": 1677696186969,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 53,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2152",
        "rarity_score": 184.63327616970213,
        "rarity_level": "COMMON"
    },
    "153": {
        "name": "Brawler Bears #153",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/153.png",
        "dna": "dc719c0584e6c5c4810b082cbd61e77cc8c64c11",
        "edition": "153",
        "type": "Black Bear",
        "date": 1677696188350,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "153",
        "rarity_score": 313.2472372034434,
        "rarity_level": "RARE"
    },
    "1428": {
        "name": "Brawler Bears #1428",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1428.png",
        "dna": "1259bc3e84441f4ef252039bc849f69870849e78",
        "edition": "1428",
        "type": "Cheetah",
        "date": 1677696189608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1428",
        "rarity_score": 317.55292053131774,
        "rarity_level": "RARE"
    },
    "45": {
        "name": "Brawler Bears #45",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/45.png",
        "dna": "e4bdae59f4134afe10ef2a7d3b0c5cd4b72ea060",
        "edition": "45",
        "type": "Acid Trip",
        "date": 1677696190517,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "45",
        "rarity_score": 336.1993732427502,
        "rarity_level": "RARE"
    },
    "1127": {
        "name": "Brawler Bears #1127",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1127.png",
        "dna": "fc90c1a5dc450960d93a9e433d9d9a0cedc3fcce",
        "edition": "1127",
        "type": "Brown Bear",
        "date": 1677696191812,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 33,
        "maxHealth": 194,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1127",
        "rarity_score": 224.4525644504128,
        "rarity_level": "COMMON"
    },
    "72": {
        "name": "Brawler Bears #72",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/72.png",
        "dna": "d976574b638eeebfe5740bb8751536e48dd6cc0c",
        "edition": "72",
        "type": "Galaxy",
        "date": 1677696192741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "72",
        "rarity_score": 338.30207447093204,
        "rarity_level": "RARE"
    },
    "883": {
        "name": "Brawler Bears #883",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/883.png",
        "dna": "bf4d1fbf4f7cab7d86ef61df38d86d0b282d54a0",
        "edition": "883",
        "type": "Brown Bear",
        "date": 1677696193881,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "883",
        "rarity_score": 333.4374641816168,
        "rarity_level": "RARE"
    },
    "1189": {
        "name": "Brawler Bears #1189",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1189.png",
        "dna": "1ef4c6ba4febee402f2be7349f48544d42eb9893",
        "edition": "1189",
        "type": "Brown Bear",
        "date": 1677696194828,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 39,
        "maxHealth": 227,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1189",
        "rarity_score": 240.00315019435328,
        "rarity_level": "COMMON"
    },
    "694": {
        "name": "Brawler Bears #694",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/694.png",
        "dna": "5a5b1eae0ca0c5a648720178220328cc04e8b0fd",
        "edition": "694",
        "type": "Galaxy",
        "date": 1677696195791,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 26,
        "maxHealth": 153,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "694",
        "rarity_score": 251.0375201139394,
        "rarity_level": "COMMON"
    },
    "1007": {
        "name": "Brawler Bears #1007",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1007.png",
        "dna": "59ca2438232dcaeda1c4272b2bc2ae2da24641c3",
        "edition": "1007",
        "type": "Galaxy",
        "date": 1677696197131,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1007",
        "rarity_score": 282.4683899042341,
        "rarity_level": "RARE"
    },
    "1275": {
        "name": "Brawler Bears #1275",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1275.png",
        "dna": "bcaf93fd59e974516c6853c1098dd908ee82e330",
        "edition": "1275",
        "type": "Galaxy",
        "date": 1677696198641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1275",
        "rarity_score": 290.7251481960996,
        "rarity_level": "RARE"
    },
    "1925": {
        "name": "Brawler Bears #1925",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1925.png",
        "dna": "00235e0dfcdfbccf666250717204eb0fd5c0005a",
        "edition": "1925",
        "type": "Deep Martian Cyborg",
        "date": 1677696200118,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1925",
        "rarity_score": 340.89118683877297,
        "rarity_level": "RARE"
    },
    "2016": {
        "name": "Brawler Bears #2016",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2016.png",
        "dna": "68d97d3ea888583fdfb18e64c30f822af301939f",
        "edition": "2016",
        "type": "Deep Martian Cyborg",
        "date": 1677696201237,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 27,
        "maxHealth": 155,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2016",
        "rarity_score": 230.9806732644977,
        "rarity_level": "COMMON"
    },
    "1847": {
        "name": "Brawler Bears #1847",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1847.png",
        "dna": "478a0a605de701dcf9f71a5fb014d3151182eb5c",
        "edition": "1847",
        "type": "Ice",
        "date": 1677696202233,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1847",
        "rarity_score": 307.00006510573274,
        "rarity_level": "RARE"
    },
    "1128": {
        "name": "Brawler Bears #1128",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1128.png",
        "dna": "1314b6142906f29574760255aff4481dabb12115",
        "edition": "1128",
        "type": "Lava",
        "date": 1677696202999,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1128",
        "rarity_score": 316.00684566035534,
        "rarity_level": "RARE"
    },
    "1622": {
        "name": "Brawler Bears #1622",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1622.png",
        "dna": "ff5b7e480a67867d7685d00a86785217314a8a33",
        "edition": "1622",
        "type": "Lava",
        "date": 1677696204002,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1622",
        "rarity_score": 329.4365261825592,
        "rarity_level": "RARE"
    },
    "1328": {
        "name": "Brawler Bears #1328",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1328.png",
        "dna": "fbef122a56e91fbe3e4307ae871cee7dfec494cf",
        "edition": "1328",
        "type": "Deep Martian Cyborg",
        "date": 1677696205655,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1328",
        "rarity_score": 272.58797297834803,
        "rarity_level": "COMMON"
    },
    "2102": {
        "name": "Brawler Bears #2102",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2102.png",
        "dna": "a4692b18ececa3557bcc2e8c6e909ebcb7e36e98",
        "edition": "2102",
        "type": "Brown Bear",
        "date": 1677696206611,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 24,
        "maxHealth": 138,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "2102",
        "rarity_score": 282.54390500818715,
        "rarity_level": "RARE"
    },
    "456": {
        "name": "Brawler Bears #456",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/456.png",
        "dna": "a439681d9795ddccadb1f00e6d57b09874989923",
        "edition": "456",
        "type": "Black Bear",
        "date": 1677696208167,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "456",
        "rarity_score": 325.6714795967484,
        "rarity_level": "RARE"
    },
    "13": {
        "name": "Brawler Bears #13",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/13.png",
        "dna": "89c8e7e4f1f3711f13fdb99db25b8bde63ed98fb",
        "edition": "13",
        "type": "Panda",
        "date": 1677696209718,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "13",
        "rarity_score": 311.30106586444236,
        "rarity_level": "RARE"
    },
    "433": {
        "name": "Brawler Bears #433",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/433.png",
        "dna": "54afe46b63c373e32a890aba83051860c75d3999",
        "edition": "433",
        "type": "Galactic Cyborg",
        "date": 1677696210473,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 47,
        "maxHealth": 272,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "433",
        "rarity_score": 197.69380865387512,
        "rarity_level": "COMMON"
    },
    "1873": {
        "name": "Brawler Bears #1873",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1873.png",
        "dna": "4246deb9be68dca93b205c71829fdee19634136a",
        "edition": "1873",
        "type": "Acid Trip",
        "date": 1677696211355,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1873",
        "rarity_score": 330.19204892627926,
        "rarity_level": "RARE"
    },
    "1631": {
        "name": "Brawler Bears #1631",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1631.png",
        "dna": "6231ccd5ee0b96ee04f4ac155a5280297ff2b80f",
        "edition": "1631",
        "type": "Ice",
        "date": 1677696212210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 46,
        "maxHealth": 266,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1631",
        "rarity_score": 213.93140964379415,
        "rarity_level": "COMMON"
    },
    "2155": {
        "name": "Brawler Bears #2155",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2155.png",
        "dna": "72b07e72f06f1d6f5b30ab1e8ae22b98a3a694ca",
        "edition": "2155",
        "type": "Lava",
        "date": 1677696213351,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "2155",
        "rarity_score": 306.28082233623576,
        "rarity_level": "RARE"
    },
    "1008": {
        "name": "Brawler Bears #1008",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1008.png",
        "dna": "efb271eaf263804fa4d4888d5bda491e0a5b2e7c",
        "edition": "1008",
        "type": "Black Bear",
        "date": 1677696214405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1008",
        "rarity_score": 203.73175514673585,
        "rarity_level": "COMMON"
    },
    "278": {
        "name": "Brawler Bears #278",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/278.png",
        "dna": "9c9f6cf3215a4356bafc4e4b5eea606d8cb3159f",
        "edition": "278",
        "type": "Galaxy",
        "date": 1677696215492,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "278",
        "rarity_score": 325.4685660539057,
        "rarity_level": "RARE"
    },
    "655": {
        "name": "Brawler Bears #655",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/655.png",
        "dna": "a82510800d2544d448286baf345b4690d40effe5",
        "edition": "655",
        "type": "Cheetah",
        "date": 1677696216675,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "655",
        "rarity_score": 307.06129801888125,
        "rarity_level": "RARE"
    },
    "555": {
        "name": "Brawler Bears #555",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/555.png",
        "dna": "d5be24ed342c6c91c228ce8628699185ca7e6297",
        "edition": "555",
        "type": "Cheetah",
        "date": 1677696218575,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "555",
        "rarity_score": 323.2155796341803,
        "rarity_level": "RARE"
    },
    "157": {
        "name": "Brawler Bears #157",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/157.png",
        "dna": "017a73ec2d9da01f59dacce0368ba7537390aa7f",
        "edition": "157",
        "type": "Black Bear",
        "date": 1677696220418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "157",
        "rarity_score": 293.0364566248909,
        "rarity_level": "RARE"
    },
    "1779": {
        "name": "Brawler Bears #1779",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1779.png",
        "dna": "23b8da75e94640fabdd82d03c6aa6f4cbcc5bc10",
        "edition": "1779",
        "type": "Galactic Cyborg",
        "date": 1677696221775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1779",
        "rarity_score": 312.56755982537027,
        "rarity_level": "RARE"
    },
    "1188": {
        "name": "Brawler Bears #1188",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1188.png",
        "dna": "7c5552f6c2859a5db8c0556c8f50a4cf8d8b761d",
        "edition": "1188",
        "type": "Black Bear",
        "date": 1677696223365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1188",
        "rarity_score": 181.14886854016723,
        "rarity_level": "COMMON"
    },
    "2159": {
        "name": "Brawler Bears #2159",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2159.png",
        "dna": "2fbe0b453b0e68f067353e4d30229857877d1bd2",
        "edition": "2159",
        "type": "Galactic Cyborg",
        "date": 1677696224274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 28,
        "maxHealth": 160,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "2159",
        "rarity_score": 211.3578260072758,
        "rarity_level": "COMMON"
    },
    "1003": {
        "name": "Brawler Bears #1003",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1003.png",
        "dna": "5164bef3e5bf376d89b3e631571ad0908b77926a",
        "edition": "1003",
        "type": "Galactic Cyborg",
        "date": 1677696225055,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1003",
        "rarity_score": 318.2549958970035,
        "rarity_level": "RARE"
    },
    "451": {
        "name": "Brawler Bears #451",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/451.png",
        "dna": "0aa9e7a278b2c053a76a7c153ebfa772bd098791",
        "edition": "451",
        "type": "Black Bear",
        "date": 1677696226049,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 59,
        "maxHealth": 337,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "451",
        "rarity_score": 228.1051698801286,
        "rarity_level": "COMMON"
    },
    "1910": {
        "name": "Brawler Bears #1910",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1910.png",
        "dna": "2e2b5b45a55093b199773ecdd7af3d3029e6b468",
        "edition": "1910",
        "type": "Brown Bear",
        "date": 1677696227569,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1910",
        "rarity_score": 247.53877363102794,
        "rarity_level": "COMMON"
    },
    "1209": {
        "name": "Brawler Bears #1209",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1209.png",
        "dna": "01f076db6130ace0f6ece378c9352ca070b0f667",
        "edition": "1209",
        "type": "Black Bear",
        "date": 1677696229263,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1209",
        "rarity_score": 181.76888288099698,
        "rarity_level": "COMMON"
    },
    "1770": {
        "name": "Brawler Bears #1770",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1770.png",
        "dna": "a994903dd4a55e71a32260d9ebd58da5d9b49e26",
        "edition": "1770",
        "type": "Galactic Cyborg",
        "date": 1677696230453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1770",
        "rarity_score": 249.50673220417454,
        "rarity_level": "COMMON"
    },
    "1457": {
        "name": "Brawler Bears #1457",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1457.png",
        "dna": "3dffe13fc5a8f85edce8a860067c184d7a58770f",
        "edition": "1457",
        "type": "Brown Bear",
        "date": 1677696231546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 44,
        "maxHealth": 256,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1457",
        "rarity_score": 301.35820535458544,
        "rarity_level": "RARE"
    },
    "408": {
        "name": "Brawler Bears #408",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/408.png",
        "dna": "200d9568dce24062bd00fd3572961de3a9d27efb",
        "edition": "408",
        "type": "Galaxy",
        "date": 1677696232497,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "408",
        "rarity_score": 241.80151255987772,
        "rarity_level": "COMMON"
    },
    "2247": {
        "name": "Brawler Bears #2247",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2247.png",
        "dna": "2ef46a051746f54d217a95cb7acae7bbc75af52d",
        "edition": "2247",
        "type": "Black Bear",
        "date": 1677696233609,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2247",
        "rarity_score": 292.05423481873663,
        "rarity_level": "RARE"
    },
    "2278": {
        "name": "Brawler Bears #2278",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2278.png",
        "dna": "ae25bd736c1c05a8814db62a1724e8f464215610",
        "edition": "2278",
        "type": "Brown Bear",
        "date": 1677696235115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 23,
        "maxHealth": 131,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2278",
        "rarity_score": 324.4162780072822,
        "rarity_level": "RARE"
    },
    "2168": {
        "name": "Brawler Bears #2168",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2168.png",
        "dna": "0dc14a928c829d99998650551d420c45dac079b5",
        "edition": "2168",
        "type": "Panda",
        "date": 1677696235970,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 26,
        "maxHealth": 149,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "2168",
        "rarity_score": 225.8663114866842,
        "rarity_level": "COMMON"
    },
    "77": {
        "name": "Brawler Bears #77",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/77.png",
        "dna": "f1e25666f45633d0cb90c0468b35eb6fb69f5cb7",
        "edition": "77",
        "type": "Ice",
        "date": 1677696236914,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 37,
        "maxHealth": 217,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "77",
        "rarity_score": 255.68317107143326,
        "rarity_level": "COMMON"
    },
    "222": {
        "name": "Brawler Bears #222",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/222.png",
        "dna": "a7a18ac1694e858eb03c88898484040f494901c4",
        "edition": "222",
        "type": "Brown Bear",
        "date": 1677696238596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "222",
        "rarity_score": 315.86834021920754,
        "rarity_level": "RARE"
    },
    "1707": {
        "name": "Brawler Bears #1707",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1707.png",
        "dna": "53a4c0f99c54a470dd019bc289ff559b203dbf98",
        "edition": "1707",
        "type": "Galaxy",
        "date": 1677696239494,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1707",
        "rarity_score": 262.50863216094126,
        "rarity_level": "COMMON"
    },
    "1928": {
        "name": "Brawler Bears #1928",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1928.png",
        "dna": "4cc1989d8ae47841ecf37c9d32bb703680780f55",
        "edition": "1928",
        "type": "Ice",
        "date": 1677696241006,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1928",
        "rarity_score": 386.05805481081376,
        "rarity_level": "RARE"
    },
    "2133": {
        "name": "Brawler Bears #2133",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2133.png",
        "dna": "d595ef42e3ff730903fbc9a95f1013099ea0f5ae",
        "edition": "2133",
        "type": "Brown Bear",
        "date": 1677696242582,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 24,
        "maxHealth": 138,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "2133",
        "rarity_score": 291.59308926401883,
        "rarity_level": "RARE"
    },
    "1145": {
        "name": "Brawler Bears #1145",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1145.png",
        "dna": "aff82263f025c4c3dbe24b3557c5da2188e455c1",
        "edition": "1145",
        "type": "Galactic Cyborg",
        "date": 1677696244086,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 18,
        "maxHealth": 103,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1145",
        "rarity_score": 303.9840469031422,
        "rarity_level": "RARE"
    },
    "1855": {
        "name": "Brawler Bears #1855",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1855.png",
        "dna": "b68ad42be85d606b6601ac517ab1495ecda54418",
        "edition": "1855",
        "type": "Black Bear",
        "date": 1677696244970,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1855",
        "rarity_score": 235.0886307417479,
        "rarity_level": "COMMON"
    },
    "1343": {
        "name": "Brawler Bears #1343",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1343.png",
        "dna": "828661f652b4c5ef78ca33c211b9f4318fa69c94",
        "edition": "1343",
        "type": "Acid Trip",
        "date": 1677696245849,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1343",
        "rarity_score": 231.68199643942592,
        "rarity_level": "COMMON"
    },
    "2207": {
        "name": "Brawler Bears #2207",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2207.png",
        "dna": "5d83d06c7007e5072a5e66daa1bef667956096db",
        "edition": "2207",
        "type": "Brown Bear",
        "date": 1677696247141,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 45,
        "maxHealth": 257,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2207",
        "rarity_score": 256.5669360805655,
        "rarity_level": "COMMON"
    },
    "1803": {
        "name": "Brawler Bears #1803",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1803.png",
        "dna": "d999ecc25c659984eab758da1306f19d88d12ac0",
        "edition": "1803",
        "type": "Acid Trip",
        "date": 1677696248287,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1803",
        "rarity_score": 345.0549437403164,
        "rarity_level": "RARE"
    },
    "970": {
        "name": "Brawler Bears #970",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/970.png",
        "dna": "2e7159256b78adef71e3a18c84749b6f373bdee6",
        "edition": "970",
        "type": "Galaxy",
        "date": 1677696249368,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Star Gaurdian",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "970",
        "rarity_score": 374.99740231032604,
        "rarity_level": "RARE"
    },
    "729": {
        "name": "Brawler Bears #729",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/729.png",
        "dna": "5c3d328df51c70e3c2ebc546835bdeb189ed65e7",
        "edition": "729",
        "type": "Black Bear",
        "date": 1677696250286,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "729",
        "rarity_score": 236.90090959599175,
        "rarity_level": "COMMON"
    },
    "1458": {
        "name": "Brawler Bears #1458",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1458.png",
        "dna": "70838deaa07b8c3c0a7d7fd25d75ef4fd2a2eac3",
        "edition": "1458",
        "type": "Galaxy",
        "date": 1677696251319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1458",
        "rarity_score": 269.438443874591,
        "rarity_level": "COMMON"
    },
    "983": {
        "name": "Brawler Bears #983",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/983.png",
        "dna": "a2380a3a81cbe87ed56b333ca6e086e096f0598b",
        "edition": "983",
        "type": "Ice",
        "date": 1677696252418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "983",
        "rarity_score": 359.40870138921576,
        "rarity_level": "RARE"
    },
    "407": {
        "name": "Brawler Bears #407",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/407.png",
        "dna": "539abbcea991e7276bd1d50501051ac0520940c5",
        "edition": "407",
        "type": "Panda",
        "date": 1677696253746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "407",
        "rarity_score": 282.4264949329596,
        "rarity_level": "RARE"
    },
    "954": {
        "name": "Brawler Bears #954",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/954.png",
        "dna": "d1fa92a290983a511605a1918fb53692d72074b7",
        "edition": "954",
        "type": "Deep Martian Cyborg",
        "date": 1677696254712,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 26,
        "maxHealth": 150,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "954",
        "rarity_score": 234.84207568803848,
        "rarity_level": "COMMON"
    },
    "1449": {
        "name": "Brawler Bears #1449",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1449.png",
        "dna": "2ea0fc825248de8d4027f4d715060e7265148664",
        "edition": "1449",
        "type": "Brown Bear",
        "date": 1677696255846,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1449",
        "rarity_score": 316.34563170061324,
        "rarity_level": "RARE"
    },
    "20": {
        "name": "Brawler Bears #20",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/20.png",
        "dna": "9d339b82db87f0e8ba669aa9903af78b3968a1a5",
        "edition": "20",
        "type": "Galaxy",
        "date": 1677696257325,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "20",
        "rarity_score": 320.0887179285858,
        "rarity_level": "RARE"
    },
    "1246": {
        "name": "Brawler Bears #1246",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1246.png",
        "dna": "9e09c814767bafef10c14078958f5e41ad292f93",
        "edition": "1246",
        "type": "Deep Martian Cyborg",
        "date": 1677696258178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1246",
        "rarity_score": 328.034232558343,
        "rarity_level": "RARE"
    },
    "1116": {
        "name": "Brawler Bears #1116",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1116.png",
        "dna": "6e883085cf490c125298b48a6cf2b2bb4388b3f8",
        "edition": "1116",
        "type": "Galaxy",
        "date": 1677696259204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1116",
        "rarity_score": 321.67948153718845,
        "rarity_level": "RARE"
    },
    "890": {
        "name": "Brawler Bears #890",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/890.png",
        "dna": "1d35dcc2c37227801aedb391c8a9becbd6086da9",
        "edition": "890",
        "type": "Black Bear",
        "date": 1677696260292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "890",
        "rarity_score": 347.3445874813222,
        "rarity_level": "RARE"
    },
    "357": {
        "name": "Brawler Bears #357",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/357.png",
        "dna": "912a40bbb330d0c7a9866e826313f40d13111d21",
        "edition": "357",
        "type": "Black Bear",
        "date": 1677696261163,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 54,
        "maxHealth": 313,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "357",
        "rarity_score": 179.73544698750501,
        "rarity_level": "COMMON"
    },
    "1776": {
        "name": "Brawler Bears #1776",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1776.png",
        "dna": "780505131e5b5f0a36ef212e84831a90c023d04a",
        "edition": "1776",
        "type": "Brown Bear",
        "date": 1677696262784,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1776",
        "rarity_score": 262.46085567471886,
        "rarity_level": "COMMON"
    },
    "1017": {
        "name": "Brawler Bears #1017",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1017.png",
        "dna": "00ed42223499623f795f08fafd6e5399ce30b622",
        "edition": "1017",
        "type": "Galaxy",
        "date": 1677696264248,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1017",
        "rarity_score": 244.3922722579438,
        "rarity_level": "COMMON"
    },
    "1495": {
        "name": "Brawler Bears #1495",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1495.png",
        "dna": "8d3cce017334106b8b5c4092b9c49977e503ccdd",
        "edition": "1495",
        "type": "Lava",
        "date": 1677696265416,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Maid",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1495",
        "rarity_score": 340.2097117025429,
        "rarity_level": "RARE"
    },
    "442": {
        "name": "Brawler Bears #442",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/442.png",
        "dna": "a65c42c37dd902e158600122f219f19fc3c88574",
        "edition": "442",
        "type": "Brown Bear",
        "date": 1677696266421,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "442",
        "rarity_score": 284.1102839982281,
        "rarity_level": "RARE"
    },
    "1337": {
        "name": "Brawler Bears #1337",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1337.png",
        "dna": "5300c119f8dc8ca3c7036a689cffaad1c6ed20a0",
        "edition": "1337",
        "type": "Galaxy",
        "date": 1677696267418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 18,
        "maxHealth": 102,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1337",
        "rarity_score": 300.16399115603593,
        "rarity_level": "RARE"
    },
    "1319": {
        "name": "Brawler Bears #1319",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1319.png",
        "dna": "ea6f4c8f5c6f7ba975c8a02b2de12fda3e86dcf3",
        "edition": "1319",
        "type": "Galactic Cyborg",
        "date": 1677696269075,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "1319",
        "rarity_score": 335.323856799882,
        "rarity_level": "RARE"
    },
    "125": {
        "name": "Brawler Bears #125",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/125.png",
        "dna": "cd614a74ff17e1397dad0d4e43f290c1835ae8fe",
        "edition": "125",
        "type": "Ice",
        "date": 1677696269832,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "125",
        "rarity_score": 273.50840630379724,
        "rarity_level": "COMMON"
    },
    "414": {
        "name": "Brawler Bears #414",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/414.png",
        "dna": "5733f7dae389638e54307d4e3a425cad0e299d10",
        "edition": "414",
        "type": "Brown Bear",
        "date": 1677696270876,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "414",
        "rarity_score": 337.3523826959473,
        "rarity_level": "RARE"
    },
    "310": {
        "name": "Brawler Bears #310",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/310.png",
        "dna": "a7760213baf0f16cff4dfdf82a264ec750ec4787",
        "edition": "310",
        "type": "Brown Bear",
        "date": 1677696271833,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "310",
        "rarity_score": 288.73239837553575,
        "rarity_level": "RARE"
    },
    "1545": {
        "name": "Brawler Bears #1545",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1545.png",
        "dna": "01c433e96b9cee406c083fbdd0afc44031c082f8",
        "edition": "1545",
        "type": "Galaxy",
        "date": 1677696272581,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1545",
        "rarity_score": 254.85858507318764,
        "rarity_level": "COMMON"
    },
    "630": {
        "name": "Brawler Bears #630",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/630.png",
        "dna": "bde7decbea0249dbb2e1eea4149d2cdb74dc48e2",
        "edition": "630",
        "type": "Acid Trip",
        "date": 1677696273685,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 37,
        "maxHealth": 211,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "630",
        "rarity_score": 270.84071432745407,
        "rarity_level": "COMMON"
    },
    "2267": {
        "name": "Brawler Bears #2267",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2267.png",
        "dna": "1dce5d4cb7cb3754b4ca87b3657a49329fc5b75a",
        "edition": "2267",
        "type": "Panda",
        "date": 1677696274694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "2267",
        "rarity_score": 260.8261498325397,
        "rarity_level": "COMMON"
    },
    "1387": {
        "name": "Brawler Bears #1387",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1387.png",
        "dna": "84e20f892e5e13f1f919b22aac8c706d7d5dddb3",
        "edition": "1387",
        "type": "Black Bear",
        "date": 1677696275386,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1387",
        "rarity_score": 252.6880694393556,
        "rarity_level": "COMMON"
    },
    "1747": {
        "name": "Brawler Bears #1747",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1747.png",
        "dna": "b57f0efb62d184398a3fca646516ec2913ef78a8",
        "edition": "1747",
        "type": "Black Bear",
        "date": 1677696276355,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1747",
        "rarity_score": 294.37648202716355,
        "rarity_level": "RARE"
    },
    "185": {
        "name": "Brawler Bears #185",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/185.png",
        "dna": "c766cf16a034479c81a52e06f6fa180c982ff62d",
        "edition": "185",
        "type": "Deep Martian Cyborg",
        "date": 1677696277199,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 27,
        "maxHealth": 158,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "185",
        "rarity_score": 212.71061774215872,
        "rarity_level": "COMMON"
    },
    "393": {
        "name": "Brawler Bears #393",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/393.png",
        "dna": "827592580479fccc2cdbda5289728c868d3dca03",
        "edition": "393",
        "type": "Brown Bear",
        "date": 1677696278284,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "393",
        "rarity_score": 220.20926189681762,
        "rarity_level": "COMMON"
    },
    "1129": {
        "name": "Brawler Bears #1129",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1129.png",
        "dna": "7335db69faeb9079a9944e1ec1a24dd152cf1c8a",
        "edition": "1129",
        "type": "Lava",
        "date": 1677696279783,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 40,
        "maxHealth": 231,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1129",
        "rarity_score": 199.46905221258308,
        "rarity_level": "COMMON"
    },
    "1002": {
        "name": "Brawler Bears #1002",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1002.png",
        "dna": "844de197e411a8a337175020ea27ad54ceeedf83",
        "edition": "1002",
        "type": "Acid Trip",
        "date": 1677696280953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 36,
        "maxHealth": 205,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1002",
        "rarity_score": 282.7879929211299,
        "rarity_level": "RARE"
    },
    "198": {
        "name": "Brawler Bears #198",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/198.png",
        "dna": "f30e5bba091c4ce42ee1d3ecc18912b982720052",
        "edition": "198",
        "type": "Galaxy",
        "date": 1677696282365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "198",
        "rarity_score": 263.87962984878106,
        "rarity_level": "COMMON"
    },
    "664": {
        "name": "Brawler Bears #664",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/664.png",
        "dna": "b0c8ce12b61b936ebde3d8032fa3d3a6196647a8",
        "edition": "664",
        "type": "Galactic Cyborg",
        "date": 1677696283543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "664",
        "rarity_score": 259.4593056859176,
        "rarity_level": "COMMON"
    },
    "338": {
        "name": "Brawler Bears #338",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/338.png",
        "dna": "0c151c94998bfea92fe096c8cd0a1e0c9b7115bc",
        "edition": "338",
        "type": "Galactic Cyborg",
        "date": 1677696284461,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "338",
        "rarity_score": 277.5515563620552,
        "rarity_level": "RARE"
    },
    "479": {
        "name": "Brawler Bears #479",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/479.png",
        "dna": "c68ffd2c58ce9748b80d02874716fce6dfda87a1",
        "edition": "479",
        "type": "Ice",
        "date": 1677696285698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "479",
        "rarity_score": 340.7114490240991,
        "rarity_level": "RARE"
    },
    "449": {
        "name": "Brawler Bears #449",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/449.png",
        "dna": "abe93c84477c065c60e11cf22b041f1d6c1faeef",
        "edition": "449",
        "type": "Acid Trip",
        "date": 1677696286652,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "449",
        "rarity_score": 261.3354314976597,
        "rarity_level": "COMMON"
    },
    "2170": {
        "name": "Brawler Bears #2170",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2170.png",
        "dna": "ea96f7422cd4fa84f663ebd888ca0eb981bd62f8",
        "edition": "2170",
        "type": "Brown Bear",
        "date": 1677696287473,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 137,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "2170",
        "rarity_score": 320.6898835479169,
        "rarity_level": "RARE"
    },
    "1911": {
        "name": "Brawler Bears #1911",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1911.png",
        "dna": "90a8cc5c01d0a603ea27428bfed64d4755330f14",
        "edition": "1911",
        "type": "Deep Martian Cyborg",
        "date": 1677696288224,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 64,
        "maxHealth": 370,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1911",
        "rarity_score": 125.78311026236621,
        "rarity_level": "COMMON"
    },
    "686": {
        "name": "Brawler Bears #686",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/686.png",
        "dna": "3d57ed84a3521bd96a6e46b306efa66914637d95",
        "edition": "686",
        "type": "Lava",
        "date": 1677696289022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 51,
        "maxHealth": 294,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "686",
        "rarity_score": 254.1640531297275,
        "rarity_level": "COMMON"
    },
    "2027": {
        "name": "Brawler Bears #2027",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2027.png",
        "dna": "f95dee14532a59915975f845179daf61963bb64d",
        "edition": "2027",
        "type": "Brown Bear",
        "date": 1677696289870,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 61,
        "maxHealth": 349,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2027",
        "rarity_score": 213.03757613217928,
        "rarity_level": "COMMON"
    },
    "2223": {
        "name": "Brawler Bears #2223",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2223.png",
        "dna": "7dcccdd827dd6c0719a7c6638d6c913f2a7d093b",
        "edition": "2223",
        "type": "Panda",
        "date": 1677696291199,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2223",
        "rarity_score": 306.3229100169457,
        "rarity_level": "RARE"
    },
    "492": {
        "name": "Brawler Bears #492",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/492.png",
        "dna": "531fdffa0fb669c48cfa6ee94878f7b75ef25b33",
        "edition": "492",
        "type": "Brown Bear",
        "date": 1677696292202,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "492",
        "rarity_score": 220.74100560333926,
        "rarity_level": "COMMON"
    },
    "1122": {
        "name": "Brawler Bears #1122",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1122.png",
        "dna": "fb902cca500a14925dd1949eb311521e1a78a4d6",
        "edition": "1122",
        "type": "Brown Bear",
        "date": 1677696293078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1122",
        "rarity_score": 273.6218086771047,
        "rarity_level": "COMMON"
    },
    "174": {
        "name": "Brawler Bears #174",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/174.png",
        "dna": "5104d2537677f22128a16ced17c8da6815068ee6",
        "edition": "174",
        "type": "Black Bear",
        "date": 1677696293867,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Ser",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 22,
        "maxHealth": 126,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "174",
        "rarity_score": 319.5443166590828,
        "rarity_level": "RARE"
    },
    "213": {
        "name": "Brawler Bears #213",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/213.png",
        "dna": "c02e5f99018a6f64965ecc2e36b595a48f16b705",
        "edition": "213",
        "type": "Cheetah",
        "date": 1677696294626,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "213",
        "rarity_score": 270.2872552535971,
        "rarity_level": "COMMON"
    },
    "295": {
        "name": "Brawler Bears #295",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/295.png",
        "dna": "015be313ce4a4649a62fce652189ed4a3bbe3a73",
        "edition": "295",
        "type": "Brown Bear",
        "date": 1677696295460,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "295",
        "rarity_score": 271.5142438788815,
        "rarity_level": "COMMON"
    },
    "29": {
        "name": "Brawler Bears #29",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/29.png",
        "dna": "02840d743a8fe8db58648a109169ac3f1eb3cd1b",
        "edition": "29",
        "type": "Galactic Cyborg",
        "date": 1677696296573,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "29",
        "rarity_score": 319.1202451307279,
        "rarity_level": "RARE"
    },
    "64": {
        "name": "Brawler Bears #64",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/64.png",
        "dna": "9b387d26a3c6dc311375c296ce5ad50014d6de52",
        "edition": "64",
        "type": "Black Bear",
        "date": 1677696298129,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "64",
        "rarity_score": 276.1366354309223,
        "rarity_level": "RARE"
    },
    "227": {
        "name": "Brawler Bears #227",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/227.png",
        "dna": "ab003a8aa43b9d53bdaeeb9490884c9b201d1947",
        "edition": "227",
        "type": "Panda",
        "date": 1677696300160,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "227",
        "rarity_score": 347.62561592824756,
        "rarity_level": "RARE"
    },
    "788": {
        "name": "Brawler Bears #788",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/788.png",
        "dna": "683cf1c4a26361c6349948e78959d7e9865e2340",
        "edition": "788",
        "type": "Black Bear",
        "date": 1677696301442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "788",
        "rarity_score": 314.36092164278506,
        "rarity_level": "RARE"
    },
    "2049": {
        "name": "Brawler Bears #2049",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2049.png",
        "dna": "07ff174637945d543037fe93f3d7635946517b77",
        "edition": "2049",
        "type": "Panda",
        "date": 1677696302750,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "2049",
        "rarity_score": 263.2465345137193,
        "rarity_level": "COMMON"
    },
    "68": {
        "name": "Brawler Bears #68",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/68.png",
        "dna": "c425f99d8a15d8a29f15a531fe52ed20b4cc678b",
        "edition": "68",
        "type": "Deep Martian Cyborg",
        "date": 1677696303930,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 54,
        "maxHealth": 312,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "68",
        "rarity_score": 246.9259438097493,
        "rarity_level": "COMMON"
    },
    "1555": {
        "name": "Brawler Bears #1555",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1555.png",
        "dna": "fe44ae187c0f0a4f3bfe4f6dae006700932a4569",
        "edition": "1555",
        "type": "Brown Bear",
        "date": 1677696305663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1555",
        "rarity_score": 260.23902929855876,
        "rarity_level": "COMMON"
    },
    "1121": {
        "name": "Brawler Bears #1121",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1121.png",
        "dna": "1cf7aca367a5ca4b2db6d43d87797300f2a9efde",
        "edition": "1121",
        "type": "Galaxy",
        "date": 1677696306660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 18,
        "maxHealth": 102,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1121",
        "rarity_score": 297.9370108100711,
        "rarity_level": "RARE"
    },
    "2138": {
        "name": "Brawler Bears #2138",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2138.png",
        "dna": "41d6957c4e02b3489296c1c1d0bd5a2c9edb4c94",
        "edition": "2138",
        "type": "Brown Bear",
        "date": 1677696308059,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "2138",
        "rarity_score": 336.8531578910482,
        "rarity_level": "RARE"
    },
    "1393": {
        "name": "Brawler Bears #1393",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1393.png",
        "dna": "dbda5f187e2eb7def2dc3239aec33a2675d6437f",
        "edition": "1393",
        "type": "Galactic Cyborg",
        "date": 1677696309249,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1393",
        "rarity_score": 299.69347957985644,
        "rarity_level": "RARE"
    },
    "1293": {
        "name": "Brawler Bears #1293",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1293.png",
        "dna": "3a83df2f9f29f177398837ca717a866ce3cabb04",
        "edition": "1293",
        "type": "Brown Bear",
        "date": 1677696310110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 61,
        "maxHealth": 349,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1293",
        "rarity_score": 223.51944365488902,
        "rarity_level": "COMMON"
    },
    "140": {
        "name": "Brawler Bears #140",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/140.png",
        "dna": "6d5cff3be4b25af90463893c1b9f133344c38a5c",
        "edition": "140",
        "type": "Acid Trip",
        "date": 1677696311427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "140",
        "rarity_score": 314.57557102516836,
        "rarity_level": "RARE"
    },
    "1292": {
        "name": "Brawler Bears #1292",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1292.png",
        "dna": "4c63324353cca82f92ad6382e13075e689a16cdc",
        "edition": "1292",
        "type": "Brown Bear",
        "date": 1677696312420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1292",
        "rarity_score": 204.1736911620014,
        "rarity_level": "COMMON"
    },
    "2139": {
        "name": "Brawler Bears #2139",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2139.png",
        "dna": "e03bde300f1b637f0e97c7934eaabf75cf7d9483",
        "edition": "2139",
        "type": "Deep Martian Cyborg",
        "date": 1677696313552,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 27,
        "maxHealth": 159,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "2139",
        "rarity_score": 225.75011722231156,
        "rarity_level": "COMMON"
    },
    "830": {
        "name": "Brawler Bears #830",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/830.png",
        "dna": "a500750f2ea073df321b1f41481161e9672783e3",
        "edition": "830",
        "type": "Black Bear",
        "date": 1677696315420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "830",
        "rarity_score": 292.6226034376242,
        "rarity_level": "RARE"
    },
    "1857": {
        "name": "Brawler Bears #1857",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1857.png",
        "dna": "602e03a414da8404b5a2ff4d5a777547a4689f66",
        "edition": "1857",
        "type": "Deep Martian Cyborg",
        "date": 1677696316583,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 32,
        "maxHealth": 187,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1857",
        "rarity_score": 271.75009390003623,
        "rarity_level": "COMMON"
    },
    "942": {
        "name": "Brawler Bears #942",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/942.png",
        "dna": "d8c1ef7fb0b87ae418454fb9ad0c89bcee029130",
        "edition": "942",
        "type": "Cheetah",
        "date": 1677696317562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 29,
        "maxHealth": 170,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "942",
        "rarity_score": 288.6770577979073,
        "rarity_level": "RARE"
    },
    "836": {
        "name": "Brawler Bears #836",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/836.png",
        "dna": "f2ad185cc3e965485ee061ab5369d8224063cc1f",
        "edition": "836",
        "type": "Galactic Cyborg",
        "date": 1677696318673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "836",
        "rarity_score": 311.4488881711809,
        "rarity_level": "RARE"
    },
    "2218": {
        "name": "Brawler Bears #2218",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2218.png",
        "dna": "9dcbdbb5b028d30143b66c7502e9a4050799ba2f",
        "edition": "2218",
        "type": "Ice",
        "date": 1677696319701,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2218",
        "rarity_score": 337.52145133279987,
        "rarity_level": "RARE"
    },
    "360": {
        "name": "Brawler Bears #360",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/360.png",
        "dna": "40fb4856ecebf9e3ece4be2034e1534e6f1d1de9",
        "edition": "360",
        "type": "Black Bear",
        "date": 1677696320640,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "360",
        "rarity_score": 315.289578647472,
        "rarity_level": "RARE"
    },
    "1981": {
        "name": "Brawler Bears #1981",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1981.png",
        "dna": "45a4a30c02b3b930688e66bcf7bb48d0fe90d14f",
        "edition": "1981",
        "type": "Galaxy",
        "date": 1677696322242,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1981",
        "rarity_score": 362.35422935120164,
        "rarity_level": "RARE"
    },
    "1369": {
        "name": "Brawler Bears #1369",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1369.png",
        "dna": "3f23b0a428988c5dc5a1b0a29ea1e5e965e37b58",
        "edition": "1369",
        "type": "Brown Bear",
        "date": 1677696323521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 33,
        "maxHealth": 188,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1369",
        "rarity_score": 230.0470957546884,
        "rarity_level": "COMMON"
    },
    "912": {
        "name": "Brawler Bears #912",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/912.png",
        "dna": "93f226fa1f9b8ccc3f26550cd5f027667386cc52",
        "edition": "912",
        "type": "Black Bear",
        "date": 1677696324966,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "912",
        "rarity_score": 281.89754180324127,
        "rarity_level": "RARE"
    },
    "1587": {
        "name": "Brawler Bears #1587",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1587.png",
        "dna": "b9b492d3eeaa13e3ea37b5326bff15e344f684e2",
        "edition": "1587",
        "type": "Galaxy",
        "date": 1677696326024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1587",
        "rarity_score": 259.7227958081908,
        "rarity_level": "COMMON"
    },
    "1459": {
        "name": "Brawler Bears #1459",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1459.png",
        "dna": "6c1779ff395a9d4c273e98143d354a5c0445380b",
        "edition": "1459",
        "type": "Galactic Cyborg",
        "date": 1677696327204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 34,
        "maxHealth": 196,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "1459",
        "rarity_score": 253.53132299153384,
        "rarity_level": "COMMON"
    },
    "61": {
        "name": "Brawler Bears #61",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/61.png",
        "dna": "df794d1f4091e605b10fab3abf129db14ba0df82",
        "edition": "61",
        "type": "Ice",
        "date": 1677696328555,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "61",
        "rarity_score": 307.3001971453593,
        "rarity_level": "RARE"
    },
    "844": {
        "name": "Brawler Bears #844",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/844.png",
        "dna": "797d067ff5c71a4aed04df521357c853fd5cc3d1",
        "edition": "844",
        "type": "Black Bear",
        "date": 1677696330040,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "844",
        "rarity_score": 300.35649555446116,
        "rarity_level": "RARE"
    },
    "1905": {
        "name": "Brawler Bears #1905",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1905.png",
        "dna": "73a7c38fb5cd2f5a6975fa45d13f04a4fbf05fca",
        "edition": "1905",
        "type": "Cheetah",
        "date": 1677696331945,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1905",
        "rarity_score": 358.9134583739686,
        "rarity_level": "RARE"
    },
    "86": {
        "name": "Brawler Bears #86",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/86.png",
        "dna": "3ebf7a43a7e6f2a10a8a39750760454881b0c434",
        "edition": "86",
        "type": "Black Bear",
        "date": 1677696333210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "86",
        "rarity_score": 243.26223566837103,
        "rarity_level": "COMMON"
    },
    "1525": {
        "name": "Brawler Bears #1525",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1525.png",
        "dna": "8363a73e88da9aa29dc35969454c4659d15e546a",
        "edition": "1525",
        "type": "Galaxy",
        "date": 1677696334175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1525",
        "rarity_score": 248.43916091747323,
        "rarity_level": "COMMON"
    },
    "476": {
        "name": "Brawler Bears #476",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/476.png",
        "dna": "0d3d0d97730c8c56e5da473fb83e7f7ea5bad0dd",
        "edition": "476",
        "type": "Brown Bear",
        "date": 1677696335069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "476",
        "rarity_score": 283.93703074550524,
        "rarity_level": "RARE"
    },
    "444": {
        "name": "Brawler Bears #444",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/444.png",
        "dna": "86f84dec4fe261291b6b81fc2852f84127a9c3b3",
        "edition": "444",
        "type": "Black Bear",
        "date": 1677696335931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "444",
        "rarity_score": 318.1958369428877,
        "rarity_level": "RARE"
    },
    "1535": {
        "name": "Brawler Bears #1535",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1535.png",
        "dna": "14ac92c4017d5e57be13dd44cbea146475ee8010",
        "edition": "1535",
        "type": "Brown Bear",
        "date": 1677696336979,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1535",
        "rarity_score": 271.1551947517189,
        "rarity_level": "COMMON"
    },
    "1385": {
        "name": "Brawler Bears #1385",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1385.png",
        "dna": "c5b800bde4d348f6eb0238579977d9e8e0d85d87",
        "edition": "1385",
        "type": "Galaxy",
        "date": 1677696338133,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 53,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1385",
        "rarity_score": 262.7967593112487,
        "rarity_level": "COMMON"
    },
    "1069": {
        "name": "Brawler Bears #1069",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1069.png",
        "dna": "c54b7118f8c78eabb6fa8d9761f68cc864ec6f02",
        "edition": "1069",
        "type": "Brown Bear",
        "date": 1677696339348,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1069",
        "rarity_score": 262.1754330628704,
        "rarity_level": "COMMON"
    },
    "2281": {
        "name": "Brawler Bears #2281",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2281.png",
        "dna": "f522efdcb18c5dba4d47c6b1c7bee46322b8cc5d",
        "edition": "2281",
        "type": "Brown Bear",
        "date": 1677696340499,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 60,
        "maxHealth": 347,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2281",
        "rarity_score": 208.35752657725496,
        "rarity_level": "COMMON"
    },
    "585": {
        "name": "Brawler Bears #585",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/585.png",
        "dna": "408c08bc1c2939bbbe0860229331c71d7aef9ea8",
        "edition": "585",
        "type": "Galactic Cyborg",
        "date": 1677696342324,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "585",
        "rarity_score": 283.9398569602631,
        "rarity_level": "RARE"
    },
    "193": {
        "name": "Brawler Bears #193",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/193.png",
        "dna": "b77965e2a96edb71c376aba73e1e2e0826a1eaa3",
        "edition": "193",
        "type": "Galaxy",
        "date": 1677696343690,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "193",
        "rarity_score": 331.32252465263593,
        "rarity_level": "RARE"
    },
    "1304": {
        "name": "Brawler Bears #1304",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1304.png",
        "dna": "37e6a02f2e3fe6b827ad4724a206c62ae3d42fb6",
        "edition": "1304",
        "type": "Ice",
        "date": 1677696346278,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 53,
        "maxHealth": 305,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1304",
        "rarity_score": 201.24579705078926,
        "rarity_level": "COMMON"
    },
    "343": {
        "name": "Brawler Bears #343",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/343.png",
        "dna": "7ab3da80d459dbaee7d4bd1414e4c98b3d72f230",
        "edition": "343",
        "type": "Lava",
        "date": 1677696347227,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "343",
        "rarity_score": 364.318872883389,
        "rarity_level": "RARE"
    },
    "370": {
        "name": "Brawler Bears #370",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/370.png",
        "dna": "6eb3a689a8d7961919226d9a9ca31f5adbc9333a",
        "edition": "370",
        "type": "Black Bear",
        "date": 1677696347942,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "370",
        "rarity_score": 274.9274307219563,
        "rarity_level": "RARE"
    },
    "1560": {
        "name": "Brawler Bears #1560",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1560.png",
        "dna": "650f3ba0ba38a15ce703e84be4e02a2dd697eff9",
        "edition": "1560",
        "type": "Panda",
        "date": 1677696349258,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "1560",
        "rarity_score": 257.98587397498414,
        "rarity_level": "COMMON"
    },
    "809": {
        "name": "Brawler Bears #809",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/809.png",
        "dna": "d2800ee5d33cfdfe0d9b88deb5311a4d0823b7ad",
        "edition": "809",
        "type": "Galactic Cyborg",
        "date": 1677696350656,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 48,
        "maxHealth": 277,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "809",
        "rarity_score": 198.61335931915755,
        "rarity_level": "COMMON"
    },
    "910": {
        "name": "Brawler Bears #910",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/910.png",
        "dna": "7d19d767f5ea49d3086801bf965313472a3afbd4",
        "edition": "910",
        "type": "Galactic Cyborg",
        "date": 1677696352733,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "910",
        "rarity_score": 341.8243805127811,
        "rarity_level": "RARE"
    },
    "2011": {
        "name": "Brawler Bears #2011",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2011.png",
        "dna": "8d5b7977a7c17a24050c6b08208ad38b2bf0716b",
        "edition": "2011",
        "type": "Galaxy",
        "date": 1677696353694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 44,
        "maxHealth": 251,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "2011",
        "rarity_score": 163.52703205370338,
        "rarity_level": "COMMON"
    },
    "391": {
        "name": "Brawler Bears #391",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/391.png",
        "dna": "cc5fb510163714eec25e9f629f5c5cc384b95aa2",
        "edition": "391",
        "type": "Black Bear",
        "date": 1677696354703,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 23,
        "maxHealth": 131,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "391",
        "rarity_score": 291.2357847936939,
        "rarity_level": "RARE"
    },
    "485": {
        "name": "Brawler Bears #485",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/485.png",
        "dna": "f2922553225942c4ed3fad3c8a7b73d575368d3d",
        "edition": "485",
        "type": "Black Bear",
        "date": 1677696356118,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "485",
        "rarity_score": 273.5868056243726,
        "rarity_level": "COMMON"
    },
    "659": {
        "name": "Brawler Bears #659",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/659.png",
        "dna": "e1e90c37f724d664d7f3133c0595cf3b604d6efb",
        "edition": "659",
        "type": "Galaxy",
        "date": 1677696358175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "659",
        "rarity_score": 186.1784147648704,
        "rarity_level": "COMMON"
    },
    "2222": {
        "name": "Brawler Bears #2222",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2222.png",
        "dna": "1f2488358ff2952c5d369668e28b7d1fc533b6b0",
        "edition": "2222",
        "type": "Ice",
        "date": 1677696359322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2222",
        "rarity_score": 249.677942638815,
        "rarity_level": "COMMON"
    },
    "2004": {
        "name": "Brawler Bears #2004",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2004.png",
        "dna": "52218bdac0cb5476e32f8d4e4c8cfb6f06c809ec",
        "edition": "2004",
        "type": "Lava",
        "date": 1677696360762,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2004",
        "rarity_score": 353.8967496022269,
        "rarity_level": "RARE"
    },
    "415": {
        "name": "Brawler Bears #415",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/415.png",
        "dna": "a9ebcf61c57d91c9c9da01b7c5fbcd8a96b4c95b",
        "edition": "415",
        "type": "Brown Bear",
        "date": 1677696362557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "415",
        "rarity_score": 308.05615421537186,
        "rarity_level": "RARE"
    },
    "123": {
        "name": "Brawler Bears #123",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/123.png",
        "dna": "86a597fdc62dc8be127f81d26b408e87b99de98d",
        "edition": "123",
        "type": "Galactic Cyborg",
        "date": 1677696364096,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 18,
        "maxHealth": 103,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "123",
        "rarity_score": 326.51921892299583,
        "rarity_level": "RARE"
    },
    "2097": {
        "name": "Brawler Bears #2097",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2097.png",
        "dna": "5019546dd6ecba6b2d473d9a138f8762bcaadd35",
        "edition": "2097",
        "type": "Galactic Cyborg",
        "date": 1677696365671,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2097",
        "rarity_score": 376.14812431524956,
        "rarity_level": "RARE"
    },
    "589": {
        "name": "Brawler Bears #589",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/589.png",
        "dna": "3a7f9474d0935dddbe52df324cb68bc32540fc98",
        "edition": "589",
        "type": "Brown Bear",
        "date": 1677696366837,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "589",
        "rarity_score": 232.56961351681096,
        "rarity_level": "COMMON"
    },
    "780": {
        "name": "Brawler Bears #780",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/780.png",
        "dna": "b5b4e4efb15cb248e19501f055beba50d5104f81",
        "edition": "780",
        "type": "Deep Martian Cyborg",
        "date": 1677696368579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 47,
        "maxHealth": 273,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "780",
        "rarity_score": 183.79945634131414,
        "rarity_level": "COMMON"
    },
    "2140": {
        "name": "Brawler Bears #2140",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2140.png",
        "dna": "6b9f5945e9bb145bec6de70263142f8379e65c67",
        "edition": "2140",
        "type": "Deep Martian Cyborg",
        "date": 1677696369442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Plaid Track",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2140",
        "rarity_score": 310.0153319172457,
        "rarity_level": "RARE"
    },
    "1469": {
        "name": "Brawler Bears #1469",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1469.png",
        "dna": "cbde32c5f6bebf5e67d204f57900e743965a1686",
        "edition": "1469",
        "type": "Galaxy",
        "date": 1677696370384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1469",
        "rarity_score": 325.43328791281823,
        "rarity_level": "RARE"
    },
    "7": {
        "name": "Brawler Bears #7",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/7.png",
        "dna": "c114d464689e5d6fbbe3aaca7fefe6bd7a7524af",
        "edition": "7",
        "type": "Ice",
        "date": 1677696371341,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "7",
        "rarity_score": 350.8478618947231,
        "rarity_level": "RARE"
    },
    "63": {
        "name": "Brawler Bears #63",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/63.png",
        "dna": "b5ad012e6c916b1fbbf4a0c3cc4ea65913c854cd",
        "edition": "63",
        "type": "Black Bear",
        "date": 1677696372793,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 39,
        "maxHealth": 227,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "63",
        "rarity_score": 246.09743346899725,
        "rarity_level": "COMMON"
    },
    "1339": {
        "name": "Brawler Bears #1339",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1339.png",
        "dna": "a05f5bb7c696b0130e89b0b004818f33c5fb26ea",
        "edition": "1339",
        "type": "Acid Trip",
        "date": 1677696374371,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 30,
        "maxHealth": 174,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1339",
        "rarity_score": 282.37397850313107,
        "rarity_level": "RARE"
    },
    "79": {
        "name": "Brawler Bears #79",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/79.png",
        "dna": "004f9776159c212cc99bb362afd920ccd5dd315e",
        "edition": "79",
        "type": "Brown Bear",
        "date": 1677696376377,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "79",
        "rarity_score": 286.09358647816725,
        "rarity_level": "RARE"
    },
    "822": {
        "name": "Brawler Bears #822",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/822.png",
        "dna": "6f923e06979fb96dabf6db50089c3418cfe12206",
        "edition": "822",
        "type": "Black Bear",
        "date": 1677696378083,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 59,
        "maxHealth": 340,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "822",
        "rarity_score": 213.9066006010433,
        "rarity_level": "COMMON"
    },
    "2314": {
        "name": "Brawler Bears #2314",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2314.png",
        "dna": "8f1d6aff0a498228daa7eab3bd1493420b3801a7",
        "edition": "2314",
        "type": "Deep Martian Cyborg",
        "date": 1677696380032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 26,
        "maxHealth": 153,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2314",
        "rarity_score": 230.51678805184724,
        "rarity_level": "COMMON"
    },
    "1833": {
        "name": "Brawler Bears #1833",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1833.png",
        "dna": "5f8baa55d77d6a09b31200a158469b4970602065",
        "edition": "1833",
        "type": "Black Bear",
        "date": 1677696380919,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1833",
        "rarity_score": 255.9882818819035,
        "rarity_level": "COMMON"
    },
    "1117": {
        "name": "Brawler Bears #1117",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1117.png",
        "dna": "8dc86f0f34e612056262e5d4a8937ea359211a24",
        "edition": "1117",
        "type": "Galactic Cyborg",
        "date": 1677696381981,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1117",
        "rarity_score": 268.43754533611127,
        "rarity_level": "COMMON"
    },
    "1934": {
        "name": "Brawler Bears #1934",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1934.png",
        "dna": "5f0133aaca0ea1f591ff70419bd2a7d2cf480a20",
        "edition": "1934",
        "type": "Black Bear",
        "date": 1677696382770,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 60,
        "maxHealth": 343,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1934",
        "rarity_score": 239.3047692279767,
        "rarity_level": "COMMON"
    },
    "1772": {
        "name": "Brawler Bears #1772",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1772.png",
        "dna": "ffebbc13394a714572afc128ab757953dafc09f7",
        "edition": "1772",
        "type": "Lava",
        "date": 1677696383606,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1772",
        "rarity_score": 328.68552061973116,
        "rarity_level": "RARE"
    },
    "1861": {
        "name": "Brawler Bears #1861",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1861.png",
        "dna": "4db5a8b8f561b27d651e13078fa0760da37169ca",
        "edition": "1861",
        "type": "Brown Bear",
        "date": 1677696384982,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 70,
        "maxHealth": 402,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1861",
        "rarity_score": 137.9204372313174,
        "rarity_level": "COMMON"
    },
    "1351": {
        "name": "Brawler Bears #1351",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1351.png",
        "dna": "b192144628bc537ba3031d9ff1ea78a1c8ba4851",
        "edition": "1351",
        "type": "Black Bear",
        "date": 1677696385939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 42,
        "maxHealth": 245,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1351",
        "rarity_score": 279.6217105703245,
        "rarity_level": "RARE"
    },
    "2115": {
        "name": "Brawler Bears #2115",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2115.png",
        "dna": "2c7fae15e13b758f6ea9fc480c5c949d93c4b8a9",
        "edition": "2115",
        "type": "Cheetah",
        "date": 1677696387140,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2115",
        "rarity_score": 349.05131168368524,
        "rarity_level": "RARE"
    },
    "35": {
        "name": "Brawler Bears #35",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/35.png",
        "dna": "0a9d20d3e79d924d86065e141a7bd0102e46dcaf",
        "edition": "35",
        "type": "Ice",
        "date": 1677696388198,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "35",
        "rarity_score": 353.64756374709077,
        "rarity_level": "RARE"
    },
    "1777": {
        "name": "Brawler Bears #1777",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1777.png",
        "dna": "2975ed99a4afff8bc9799015e6c6897fd0878cda",
        "edition": "1777",
        "type": "Black Bear",
        "date": 1677696389496,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Prime Minister",
                "score": 124.375
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1777",
        "rarity_score": 305.1737498379814,
        "rarity_level": "RARE"
    },
    "728": {
        "name": "Brawler Bears #728",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/728.png",
        "dna": "42fcf127f2796ca480b73d6f688e07e1d44ce4a6",
        "edition": "728",
        "type": "Panda",
        "date": 1677696390419,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "728",
        "rarity_score": 333.3290126145309,
        "rarity_level": "RARE"
    },
    "699": {
        "name": "Brawler Bears #699",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/699.png",
        "dna": "448c9f6143b07f4ebd5ae66d4f7ee2cb8a865337",
        "edition": "699",
        "type": "Galactic Cyborg",
        "date": 1677696391274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "699",
        "rarity_score": 276.1484871978041,
        "rarity_level": "RARE"
    },
    "1355": {
        "name": "Brawler Bears #1355",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1355.png",
        "dna": "0754c1f30c84ea49eca79267a17cf024c58a4f04",
        "edition": "1355",
        "type": "Galactic Cyborg",
        "date": 1677696392323,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1355",
        "rarity_score": 280.2600632800767,
        "rarity_level": "RARE"
    },
    "1683": {
        "name": "Brawler Bears #1683",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1683.png",
        "dna": "2c11cdf69c46ad68537c9407ee387a239e26264b",
        "edition": "1683",
        "type": "Black Bear",
        "date": 1677696394317,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1683",
        "rarity_score": 237.87046966021785,
        "rarity_level": "COMMON"
    },
    "2104": {
        "name": "Brawler Bears #2104",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2104.png",
        "dna": "e939135307d06e596f1b584c6f2961d639f68d4a",
        "edition": "2104",
        "type": "Ice",
        "date": 1677696395204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 28,
        "maxHealth": 160,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2104",
        "rarity_score": 210.79714524222274,
        "rarity_level": "COMMON"
    },
    "612": {
        "name": "Brawler Bears #612",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/612.png",
        "dna": "c5d8e5b8ee3c67ef9e751521c631dcd7db7276a1",
        "edition": "612",
        "type": "Galactic Cyborg",
        "date": 1677696395991,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 17,
        "maxHealth": 100,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "612",
        "rarity_score": 279.0343494105697,
        "rarity_level": "RARE"
    },
    "1187": {
        "name": "Brawler Bears #1187",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1187.png",
        "dna": "b739d3fd4e9b296f3d7de4d809c70690034ac24b",
        "edition": "1187",
        "type": "Black Bear",
        "date": 1677696397118,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1187",
        "rarity_score": 283.63937824022895,
        "rarity_level": "RARE"
    },
    "1570": {
        "name": "Brawler Bears #1570",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1570.png",
        "dna": "fb1f4ad1ae3c3351c9ab02d4d7f82b9bf3073321",
        "edition": "1570",
        "type": "Black Bear",
        "date": 1677696398694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1570",
        "rarity_score": 280.11306393775027,
        "rarity_level": "RARE"
    },
    "927": {
        "name": "Brawler Bears #927",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/927.png",
        "dna": "3aa923f465e0b30f9b23528c20ff53db5d17cb3f",
        "edition": "927",
        "type": "Brown Bear",
        "date": 1677696399947,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 39,
        "maxHealth": 228,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "927",
        "rarity_score": 223.8728804791151,
        "rarity_level": "COMMON"
    },
    "1051": {
        "name": "Brawler Bears #1051",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1051.png",
        "dna": "dacdf7851f6d08070f5668e3538b174a766de5e1",
        "edition": "1051",
        "type": "Deep Martian Cyborg",
        "date": 1677696401592,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 48,
        "maxHealth": 275,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1051",
        "rarity_score": 183.9994392167918,
        "rarity_level": "COMMON"
    },
    "437": {
        "name": "Brawler Bears #437",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/437.png",
        "dna": "05f15c7954598c84d6b301444f439f8e22520b6e",
        "edition": "437",
        "type": "Cheetah",
        "date": 1677696402990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Fire Fighter",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 51,
        "maxHealth": 295,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "437",
        "rarity_score": 210.5621805102049,
        "rarity_level": "COMMON"
    },
    "299": {
        "name": "Brawler Bears #299",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/299.png",
        "dna": "0b2a284aaed86f60c14c991fd1863af87a26c2f6",
        "edition": "299",
        "type": "Brown Bear",
        "date": 1677696404072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 23,
        "maxHealth": 132,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "299",
        "rarity_score": 311.83909605410344,
        "rarity_level": "RARE"
    },
    "759": {
        "name": "Brawler Bears #759",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/759.png",
        "dna": "a28ff1a233689b7c24c9b1a3a48ec67bc36dff1f",
        "edition": "759",
        "type": "Cheetah",
        "date": 1677696405249,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "759",
        "rarity_score": 344.5587650700038,
        "rarity_level": "RARE"
    },
    "1072": {
        "name": "Brawler Bears #1072",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1072.png",
        "dna": "29bd5f86772557dfc186266750a11ee67f11dfa6",
        "edition": "1072",
        "type": "Panda",
        "date": 1677696406806,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "1072",
        "rarity_score": 319.7383910800872,
        "rarity_level": "RARE"
    },
    "967": {
        "name": "Brawler Bears #967",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/967.png",
        "dna": "b6d30a44cc24316def1d7bb85ef695e12fc2a871",
        "edition": "967",
        "type": "Deep Martian Cyborg",
        "date": 1677696408108,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "967",
        "rarity_score": 334.6308752752977,
        "rarity_level": "RARE"
    },
    "425": {
        "name": "Brawler Bears #425",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/425.png",
        "dna": "b2972bb05cc5dce508d45a8d3fb8304cbfeba680",
        "edition": "425",
        "type": "Brown Bear",
        "date": 1677696409370,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "425",
        "rarity_score": 220.96378129014158,
        "rarity_level": "COMMON"
    },
    "1785": {
        "name": "Brawler Bears #1785",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1785.png",
        "dna": "ef72d236b6fe7041a9b9033cf1e40415d4117c43",
        "edition": "1785",
        "type": "Cheetah",
        "date": 1677696410257,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1785",
        "rarity_score": 269.196483070972,
        "rarity_level": "COMMON"
    },
    "275": {
        "name": "Brawler Bears #275",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/275.png",
        "dna": "1ee0ff1c99e0682c41e43dbd462e78b31e8aa5fb",
        "edition": "275",
        "type": "Deep Martian Cyborg",
        "date": 1677696411466,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "275",
        "rarity_score": 270.8126485029579,
        "rarity_level": "COMMON"
    },
    "1955": {
        "name": "Brawler Bears #1955",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1955.png",
        "dna": "5bafca0b28d930e1b7071559b4b333e2c241b253",
        "edition": "1955",
        "type": "Lava",
        "date": 1677696412801,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 29,
        "maxHealth": 170,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1955",
        "rarity_score": 284.6613155053293,
        "rarity_level": "RARE"
    },
    "367": {
        "name": "Brawler Bears #367",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/367.png",
        "dna": "8b32e47f7c6953e1906f9bf411a1e6071c53e5d5",
        "edition": "367",
        "type": "Panda",
        "date": 1677696414781,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 25,
        "maxHealth": 145,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "367",
        "rarity_score": 250.73253830252276,
        "rarity_level": "COMMON"
    },
    "1182": {
        "name": "Brawler Bears #1182",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1182.png",
        "dna": "de66cc0417f3bb0d13de89f3cb4fddfecd4143f8",
        "edition": "1182",
        "type": "Galaxy",
        "date": 1677696416051,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1182",
        "rarity_score": 281.6343313498166,
        "rarity_level": "RARE"
    },
    "1095": {
        "name": "Brawler Bears #1095",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1095.png",
        "dna": "24f79c7b94042c1a07e2cf7dca4c2f116ecb5ec1",
        "edition": "1095",
        "type": "Brown Bear",
        "date": 1677696417321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 55,
        "maxHealth": 316,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1095",
        "rarity_score": 177.41203057185652,
        "rarity_level": "COMMON"
    },
    "549": {
        "name": "Brawler Bears #549",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/549.png",
        "dna": "edc22fb51f2aa90346595258956c08a178bd4921",
        "edition": "549",
        "type": "Acid Trip",
        "date": 1677696418078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 53,
        "maxHealth": 304,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "549",
        "rarity_score": 202.5583565125399,
        "rarity_level": "COMMON"
    },
    "791": {
        "name": "Brawler Bears #791",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/791.png",
        "dna": "7dd463b346e2d82bba8666cd323886d5db9f3b38",
        "edition": "791",
        "type": "Black Bear",
        "date": 1677696419207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 48,
        "maxHealth": 279,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "791",
        "rarity_score": 174.706566856763,
        "rarity_level": "COMMON"
    },
    "2251": {
        "name": "Brawler Bears #2251",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2251.png",
        "dna": "a4d0218adbd3fd61611c85295b99b10e7a465b02",
        "edition": "2251",
        "type": "Black Bear",
        "date": 1677696420513,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2251",
        "rarity_score": 285.8469330821344,
        "rarity_level": "RARE"
    },
    "2219": {
        "name": "Brawler Bears #2219",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2219.png",
        "dna": "9ed231a14570e7d654f36a8423678c1cef4e02bc",
        "edition": "2219",
        "type": "Galaxy",
        "date": 1677696421827,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2219",
        "rarity_score": 220.6736853186698,
        "rarity_level": "COMMON"
    },
    "1181": {
        "name": "Brawler Bears #1181",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1181.png",
        "dna": "33b8be65f6617c583a86e55d312b80b50c48517d",
        "edition": "1181",
        "type": "Brown Bear",
        "date": 1677696423094,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1181",
        "rarity_score": 294.7777191667475,
        "rarity_level": "RARE"
    },
    "1983": {
        "name": "Brawler Bears #1983",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1983.png",
        "dna": "0779e6fab9b2babcd399e89e7f8c3c9355bcb6dd",
        "edition": "1983",
        "type": "Galaxy",
        "date": 1677696424499,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1983",
        "rarity_score": 375.8856293142623,
        "rarity_level": "RARE"
    },
    "1906": {
        "name": "Brawler Bears #1906",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1906.png",
        "dna": "7d6f42ff155f727ddd9b21c2a70f5523a5dc11fa",
        "edition": "1906",
        "type": "Acid Trip",
        "date": 1677696426585,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1906",
        "rarity_score": 237.9941495189707,
        "rarity_level": "COMMON"
    },
    "1242": {
        "name": "Brawler Bears #1242",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1242.png",
        "dna": "6f92fca4e9d404a120108edaf6c6269afb496d42",
        "edition": "1242",
        "type": "Brown Bear",
        "date": 1677696428343,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 43,
        "maxHealth": 251,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1242",
        "rarity_score": 270.5717821332056,
        "rarity_level": "COMMON"
    },
    "349": {
        "name": "Brawler Bears #349",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/349.png",
        "dna": "65d6193100be25bcda41ee4e087537927458fe19",
        "edition": "349",
        "type": "Cheetah",
        "date": 1677696429523,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 14,
        "maxHealth": 85,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "349",
        "rarity_score": 289.591392560537,
        "rarity_level": "RARE"
    },
    "891": {
        "name": "Brawler Bears #891",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/891.png",
        "dna": "e043077ec9307701c53265612c6c62678538d2bc",
        "edition": "891",
        "type": "Ice",
        "date": 1677696430561,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "891",
        "rarity_score": 230.39701232678732,
        "rarity_level": "COMMON"
    },
    "1957": {
        "name": "Brawler Bears #1957",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1957.png",
        "dna": "344f9326b1cf83ab96a3b06e4037a06fa9673cd9",
        "edition": "1957",
        "type": "Cheetah",
        "date": 1677696431877,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 14,
        "maxHealth": 80,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1957",
        "rarity_score": 319.2450972188213,
        "rarity_level": "RARE"
    },
    "1824": {
        "name": "Brawler Bears #1824",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1824.png",
        "dna": "38ebb4adb177896ecb8f91700993b87c76c3faf0",
        "edition": "1824",
        "type": "Lava",
        "date": 1677696432969,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1824",
        "rarity_score": 296.1380853969104,
        "rarity_level": "RARE"
    },
    "2162": {
        "name": "Brawler Bears #2162",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2162.png",
        "dna": "971bb080cfd4084f4fbd23f4a41bec817af1dfa2",
        "edition": "2162",
        "type": "Galaxy",
        "date": 1677696434340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 27,
        "maxHealth": 159,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "2162",
        "rarity_score": 229.669239626149,
        "rarity_level": "COMMON"
    },
    "696": {
        "name": "Brawler Bears #696",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/696.png",
        "dna": "aebcf1c3a93bbe990cb095975b8b9f9ff6a06162",
        "edition": "696",
        "type": "Ice",
        "date": 1677696435611,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "696",
        "rarity_score": 327.3562172218367,
        "rarity_level": "RARE"
    },
    "1471": {
        "name": "Brawler Bears #1471",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1471.png",
        "dna": "c4e93867687e557a59f3b0f63df8bd3ec24f5558",
        "edition": "1471",
        "type": "Black Bear",
        "date": 1677696436431,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1471",
        "rarity_score": 279.6264116808919,
        "rarity_level": "RARE"
    },
    "1648": {
        "name": "Brawler Bears #1648",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1648.png",
        "dna": "78d3316f525aa3ebe3953c6659a9f0fdcd793ba0",
        "edition": "1648",
        "type": "Deep Martian Cyborg",
        "date": 1677696438391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1648",
        "rarity_score": 253.20044574391133,
        "rarity_level": "COMMON"
    },
    "1357": {
        "name": "Brawler Bears #1357",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1357.png",
        "dna": "a25c4fe4729436de7bd95808629a0379fceb768d",
        "edition": "1357",
        "type": "Ice",
        "date": 1677696439201,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 54,
        "maxHealth": 310,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1357",
        "rarity_score": 220.6463667998865,
        "rarity_level": "COMMON"
    },
    "297": {
        "name": "Brawler Bears #297",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/297.png",
        "dna": "b0ade296504507d3586c5826d2418a860bf65899",
        "edition": "297",
        "type": "Galaxy",
        "date": 1677696440707,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 48,
        "maxHealth": 278,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "297",
        "rarity_score": 172.48201975729805,
        "rarity_level": "COMMON"
    },
    "2184": {
        "name": "Brawler Bears #2184",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2184.png",
        "dna": "1b117ea48d7c177d3fab5242c8715bc22cdc7a71",
        "edition": "2184",
        "type": "Brown Bear",
        "date": 1677696441825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "2184",
        "rarity_score": 296.7504241086443,
        "rarity_level": "RARE"
    },
    "1502": {
        "name": "Brawler Bears #1502",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1502.png",
        "dna": "b18fed0ffb794a1a3bc66669b00801be3f693615",
        "edition": "1502",
        "type": "Galaxy",
        "date": 1677696443132,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 17,
        "maxHealth": 102,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1502",
        "rarity_score": 323.9085655110295,
        "rarity_level": "RARE"
    },
    "365": {
        "name": "Brawler Bears #365",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/365.png",
        "dna": "880be67fa7b3c500910a72ed558943bd8e4cad43",
        "edition": "365",
        "type": "Ice",
        "date": 1677696444042,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "365",
        "rarity_score": 275.47371031552257,
        "rarity_level": "RARE"
    },
    "1363": {
        "name": "Brawler Bears #1363",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1363.png",
        "dna": "988f06d08900a4cfdba597d9be7730150e40fbe0",
        "edition": "1363",
        "type": "Brown Bear",
        "date": 1677696445591,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 61,
        "maxHealth": 348,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1363",
        "rarity_score": 192.7849698596407,
        "rarity_level": "COMMON"
    },
    "1200": {
        "name": "Brawler Bears #1200",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1200.png",
        "dna": "3003a733d88df0efc70bfef7f3cb9cf2db3924a7",
        "edition": "1200",
        "type": "Cheetah",
        "date": 1677696447014,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 52,
        "maxHealth": 299,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1200",
        "rarity_score": 206.8493371674684,
        "rarity_level": "COMMON"
    },
    "136": {
        "name": "Brawler Bears #136",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/136.png",
        "dna": "a47606c12869c215b37d717eb1ca38b3f335a753",
        "edition": "136",
        "type": "Ice",
        "date": 1677696448793,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "136",
        "rarity_score": 269.89451263061164,
        "rarity_level": "COMMON"
    },
    "161": {
        "name": "Brawler Bears #161",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/161.png",
        "dna": "a36b6cac7d6371bfa5b1f81f87822457eb6ae998",
        "edition": "161",
        "type": "Galactic Cyborg",
        "date": 1677696449797,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "161",
        "rarity_score": 285.1878109706554,
        "rarity_level": "RARE"
    },
    "2081": {
        "name": "Brawler Bears #2081",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2081.png",
        "dna": "e73194a5490b8137c14c196ef88c84363aa3c218",
        "edition": "2081",
        "type": "Deep Martian Cyborg",
        "date": 1677696450984,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "2081",
        "rarity_score": 169.10589831676035,
        "rarity_level": "COMMON"
    },
    "1872": {
        "name": "Brawler Bears #1872",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1872.png",
        "dna": "deb03175eb08ebf65e5922f7fb3f0c2d0b4f24f6",
        "edition": "1872",
        "type": "Lava",
        "date": 1677696452345,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1872",
        "rarity_score": 355.3731128638386,
        "rarity_level": "RARE"
    },
    "1563": {
        "name": "Brawler Bears #1563",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1563.png",
        "dna": "1a05b742e993d58a3c9d271b39acb0f50c3942c2",
        "edition": "1563",
        "type": "Galactic Cyborg",
        "date": 1677696453247,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1563",
        "rarity_score": 343.9125270207666,
        "rarity_level": "RARE"
    },
    "653": {
        "name": "Brawler Bears #653",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/653.png",
        "dna": "3cde4c4893fb0a2c9a939d8dcc79e4ace90bcf6e",
        "edition": "653",
        "type": "Brown Bear",
        "date": 1677696454109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Prince",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "653",
        "rarity_score": 318.16594138525966,
        "rarity_level": "RARE"
    },
    "1170": {
        "name": "Brawler Bears #1170",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1170.png",
        "dna": "c041898604a997091a8921da4159fb01b38a3426",
        "edition": "1170",
        "type": "Galactic Cyborg",
        "date": 1677696455150,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1170",
        "rarity_score": 262.9529329474094,
        "rarity_level": "COMMON"
    },
    "2077": {
        "name": "Brawler Bears #2077",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2077.png",
        "dna": "d7dffb12b2da19749f1d799e31c3f66da6a8d111",
        "edition": "2077",
        "type": "Brown Bear",
        "date": 1677696455962,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 39,
        "maxHealth": 227,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "2077",
        "rarity_score": 241.55835237039526,
        "rarity_level": "COMMON"
    },
    "672": {
        "name": "Brawler Bears #672",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/672.png",
        "dna": "e5a6bad0003b66598aed36bd19a29784e2553183",
        "edition": "672",
        "type": "Cheetah",
        "date": 1677696457366,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 31,
        "maxHealth": 177,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "672",
        "rarity_score": 228.7016461582257,
        "rarity_level": "COMMON"
    },
    "15": {
        "name": "Brawler Bears #15",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/15.png",
        "dna": "9c3e26129f798f0a15133c2976bf8843f0dcb0cb",
        "edition": "15",
        "type": "Brown Bear",
        "date": 1677696458636,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "15",
        "rarity_score": 327.50054389631646,
        "rarity_level": "RARE"
    },
    "2127": {
        "name": "Brawler Bears #2127",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2127.png",
        "dna": "7c5ddbc6d4b93a28c135bb8cd6a6dbf124b8ba09",
        "edition": "2127",
        "type": "Deep Martian Cyborg",
        "date": 1677696460290,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "2127",
        "rarity_score": 260.49102411467743,
        "rarity_level": "COMMON"
    },
    "575": {
        "name": "Brawler Bears #575",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/575.png",
        "dna": "84cf90a8ffd8c288ad1c4a1ebb4ad1af8e81cc3b",
        "edition": "575",
        "type": "Brown Bear",
        "date": 1677696461390,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 60,
        "maxHealth": 348,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "575",
        "rarity_score": 194.91857280905523,
        "rarity_level": "COMMON"
    },
    "482": {
        "name": "Brawler Bears #482",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/482.png",
        "dna": "9424c8f7ae29ebeab8febe35e2cd64c34ecbb85b",
        "edition": "482",
        "type": "Cheetah",
        "date": 1677696462609,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 34,
        "maxHealth": 197,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "482",
        "rarity_score": 323.2879342135504,
        "rarity_level": "RARE"
    },
    "1571": {
        "name": "Brawler Bears #1571",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1571.png",
        "dna": "7d30a8955d10f8d5f6ac3b4efbf3ee2f40722030",
        "edition": "1571",
        "type": "Black Bear",
        "date": 1677696463692,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1571",
        "rarity_score": 328.1375354993068,
        "rarity_level": "RARE"
    },
    "592": {
        "name": "Brawler Bears #592",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/592.png",
        "dna": "d3a4bb39507c60ff472b43bb32affb517207de28",
        "edition": "592",
        "type": "Cheetah",
        "date": 1677696465025,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Aqua General",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "592",
        "rarity_score": 331.0463076556123,
        "rarity_level": "RARE"
    },
    "868": {
        "name": "Brawler Bears #868",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/868.png",
        "dna": "c31545708df1e9ba9a9805a2c70c4404d50c85d1",
        "edition": "868",
        "type": "Galaxy",
        "date": 1677696466433,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "868",
        "rarity_score": 277.0350212768623,
        "rarity_level": "RARE"
    },
    "1752": {
        "name": "Brawler Bears #1752",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1752.png",
        "dna": "3b05212dd1f49e7a8105074c4a617fcb19abc5c9",
        "edition": "1752",
        "type": "Black Bear",
        "date": 1677696467359,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1752",
        "rarity_score": 297.8259771201393,
        "rarity_level": "RARE"
    },
    "2149": {
        "name": "Brawler Bears #2149",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2149.png",
        "dna": "13e8431c31c58f51299ebe919b6f87606d009560",
        "edition": "2149",
        "type": "Brown Bear",
        "date": 1677696468940,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "2149",
        "rarity_score": 363.7677529357152,
        "rarity_level": "RARE"
    },
    "514": {
        "name": "Brawler Bears #514",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/514.png",
        "dna": "ffda57d6b32d838094c0e9ff0010b3de6c85d0d9",
        "edition": "514",
        "type": "Black Bear",
        "date": 1677696470540,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "514",
        "rarity_score": 219.30795324610423,
        "rarity_level": "COMMON"
    },
    "1312": {
        "name": "Brawler Bears #1312",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1312.png",
        "dna": "be2a6a078459a4f25abd5963742b70f1b46350c3",
        "edition": "1312",
        "type": "Acid Trip",
        "date": 1677696472107,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1312",
        "rarity_score": 307.35751949753626,
        "rarity_level": "RARE"
    },
    "108": {
        "name": "Brawler Bears #108",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/108.png",
        "dna": "dd7dc6341ac0a381e8f3d5306c70853be5ba252c",
        "edition": "108",
        "type": "Brown Bear",
        "date": 1677696473185,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "108",
        "rarity_score": 397.43766790528076,
        "rarity_level": "RARE"
    },
    "101": {
        "name": "Brawler Bears #101",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/101.png",
        "dna": "2881b54af4664d686d23c612b6c7bec2c8558368",
        "edition": "101",
        "type": "Acid Trip",
        "date": 1677696474288,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 35,
        "maxHealth": 203,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "101",
        "rarity_score": 286.3302100158499,
        "rarity_level": "RARE"
    },
    "1708": {
        "name": "Brawler Bears #1708",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1708.png",
        "dna": "03bb6e9b2ab78177871e131996cc7bbee0f4d161",
        "edition": "1708",
        "type": "Brown Bear",
        "date": 1677696475140,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Casual",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 59,
        "maxHealth": 340,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1708",
        "rarity_score": 223.16205847281452,
        "rarity_level": "COMMON"
    },
    "1599": {
        "name": "Brawler Bears #1599",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1599.png",
        "dna": "343d9f1ded14794d207ac8a10e8a9848aa867e9e",
        "edition": "1599",
        "type": "Black Bear",
        "date": 1677696476083,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "1599",
        "rarity_score": 362.74312096582497,
        "rarity_level": "RARE"
    },
    "1283": {
        "name": "Brawler Bears #1283",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1283.png",
        "dna": "2de543c56f977ff560ea0d63fd2a7c220a647211",
        "edition": "1283",
        "type": "Acid Trip",
        "date": 1677696476925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1283",
        "rarity_score": 312.4265538431042,
        "rarity_level": "RARE"
    },
    "1482": {
        "name": "Brawler Bears #1482",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1482.png",
        "dna": "113968a4bdb55354dce3c60a2b3ea45a038497c5",
        "edition": "1482",
        "type": "Brown Bear",
        "date": 1677696478249,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1482",
        "rarity_score": 259.3812199818498,
        "rarity_level": "COMMON"
    },
    "1800": {
        "name": "Brawler Bears #1800",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1800.png",
        "dna": "e4ba47a390a3c4eabbf007d5749ec2c8ca85587c",
        "edition": "1800",
        "type": "Cheetah",
        "date": 1677696479068,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1800",
        "rarity_score": 178.3982818944779,
        "rarity_level": "COMMON"
    },
    "1109": {
        "name": "Brawler Bears #1109",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1109.png",
        "dna": "b2a0844410d72cc7ef10db4c192adcfe1ce3b35d",
        "edition": "1109",
        "type": "Black Bear",
        "date": 1677696479809,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 60,
        "maxHealth": 345,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1109",
        "rarity_score": 201.87052698064534,
        "rarity_level": "COMMON"
    },
    "303": {
        "name": "Brawler Bears #303",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/303.png",
        "dna": "bfb09331e40fefd909fabaf0eee03c603682d86e",
        "edition": "303",
        "type": "Galactic Cyborg",
        "date": 1677696481327,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "303",
        "rarity_score": 262.9119040467503,
        "rarity_level": "COMMON"
    },
    "132": {
        "name": "Brawler Bears #132",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/132.png",
        "dna": "2aff756a2730fdcc2557dde94676d255ef6366d4",
        "edition": "132",
        "type": "Galaxy",
        "date": 1677696482718,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "132",
        "rarity_score": 325.07622577042866,
        "rarity_level": "RARE"
    },
    "803": {
        "name": "Brawler Bears #803",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/803.png",
        "dna": "6e827dfebd1893d0f75ea649b92c6afa891552a3",
        "edition": "803",
        "type": "Lava",
        "date": 1677696484050,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "803",
        "rarity_score": 349.9923139875186,
        "rarity_level": "RARE"
    },
    "319": {
        "name": "Brawler Bears #319",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/319.png",
        "dna": "f15d906f322b4c14a27e486710279821722e37dd",
        "edition": "319",
        "type": "Galaxy",
        "date": 1677696485256,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "319",
        "rarity_score": 312.6582695775623,
        "rarity_level": "RARE"
    },
    "765": {
        "name": "Brawler Bears #765",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/765.png",
        "dna": "2cf7c8c3028588f1c3b75b5c4a1c2df91a3bb332",
        "edition": "765",
        "type": "Brown Bear",
        "date": 1677696486442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "765",
        "rarity_score": 327.20191102824833,
        "rarity_level": "RARE"
    },
    "1702": {
        "name": "Brawler Bears #1702",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1702.png",
        "dna": "4f7eaaa6125481db77e91e92399a709a5eb6c4fa",
        "edition": "1702",
        "type": "Panda",
        "date": 1677696488659,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "1702",
        "rarity_score": 351.7425788734579,
        "rarity_level": "RARE"
    },
    "580": {
        "name": "Brawler Bears #580",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/580.png",
        "dna": "d0f7e972e268bea100d4ac95297dc92c3a04c856",
        "edition": "580",
        "type": "Brown Bear",
        "date": 1677696489936,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 60,
        "maxHealth": 343,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "580",
        "rarity_score": 231.40559679865154,
        "rarity_level": "COMMON"
    },
    "528": {
        "name": "Brawler Bears #528",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/528.png",
        "dna": "1687b43d275418aee155b3194c37f58a0d142cfe",
        "edition": "528",
        "type": "Panda",
        "date": 1677696491864,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "528",
        "rarity_score": 320.8152054862675,
        "rarity_level": "RARE"
    },
    "277": {
        "name": "Brawler Bears #277",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/277.png",
        "dna": "8747ff363039158dfd4cf790e3453fec419e80e4",
        "edition": "277",
        "type": "Brown Bear",
        "date": 1677696493209,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "277",
        "rarity_score": 317.566292145257,
        "rarity_level": "RARE"
    },
    "353": {
        "name": "Brawler Bears #353",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/353.png",
        "dna": "6a28eb6b573160f2fa03365e0889328b7630fb50",
        "edition": "353",
        "type": "Lava",
        "date": 1677696494469,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 24,
        "maxHealth": 139,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "353",
        "rarity_score": 236.61542294525754,
        "rarity_level": "COMMON"
    },
    "1227": {
        "name": "Brawler Bears #1227",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1227.png",
        "dna": "8dac38a810bf2627612fa64f836a12f03482b9fa",
        "edition": "1227",
        "type": "Black Bear",
        "date": 1677696495388,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Tech Bat",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1227",
        "rarity_score": 293.2981727347255,
        "rarity_level": "RARE"
    },
    "2001": {
        "name": "Brawler Bears #2001",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2001.png",
        "dna": "5f7b0dbb301212ce0d3fb31501d82992063e7ec8",
        "edition": "2001",
        "type": "Black Bear",
        "date": 1677696496357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "2001",
        "rarity_score": 236.53718544126886,
        "rarity_level": "COMMON"
    },
    "66": {
        "name": "Brawler Bears #66",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/66.png",
        "dna": "567eec9b0fdae1792696d1581bc518554baefd49",
        "edition": "66",
        "type": "Panda",
        "date": 1677696497505,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Papi",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "66",
        "rarity_score": 354.7159600032584,
        "rarity_level": "RARE"
    },
    "1886": {
        "name": "Brawler Bears #1886",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1886.png",
        "dna": "d859fb5aa7ee8591c4ac71147811c206c029d53b",
        "edition": "1886",
        "type": "Galactic Cyborg",
        "date": 1677696499001,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1886",
        "rarity_score": 339.6962387811414,
        "rarity_level": "RARE"
    },
    "1432": {
        "name": "Brawler Bears #1432",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1432.png",
        "dna": "040dca4c5d75bb4d1217937e5bd2ba4febe12a7c",
        "edition": "1432",
        "type": "Ice",
        "date": 1677696500042,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 17,
        "maxHealth": 102,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1432",
        "rarity_score": 295.42798094960807,
        "rarity_level": "RARE"
    },
    "1634": {
        "name": "Brawler Bears #1634",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1634.png",
        "dna": "1f685b3e6617d052bced94afd8fc44ab5688c027",
        "edition": "1634",
        "type": "Deep Martian Cyborg",
        "date": 1677696501886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Prince Robe",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1634",
        "rarity_score": 265.1726638025202,
        "rarity_level": "COMMON"
    },
    "497": {
        "name": "Brawler Bears #497",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/497.png",
        "dna": "b412c5b3ac708e6affb3183f6345e7a7a642ef96",
        "edition": "497",
        "type": "Black Bear",
        "date": 1677696503322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "497",
        "rarity_score": 268.5645307941577,
        "rarity_level": "COMMON"
    },
    "2000": {
        "name": "Brawler Bears #2000",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2000.png",
        "dna": "b3fb06c6e89df2acb06312252f97438f4f48648b",
        "edition": "2000",
        "type": "Brown Bear",
        "date": 1677696504149,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 60,
        "maxHealth": 344,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2000",
        "rarity_score": 218.34507263323997,
        "rarity_level": "COMMON"
    },
    "1301": {
        "name": "Brawler Bears #1301",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1301.png",
        "dna": "847a98730101bb25f3eeb8affc985b2d4f38a1f6",
        "edition": "1301",
        "type": "Brown Bear",
        "date": 1677696505088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1301",
        "rarity_score": 222.51755512909014,
        "rarity_level": "COMMON"
    },
    "89": {
        "name": "Brawler Bears #89",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/89.png",
        "dna": "411be5fc52d8d32438afe3d86852b2710e1f0789",
        "edition": "89",
        "type": "Deep Martian Cyborg",
        "date": 1677696505898,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "89",
        "rarity_score": 267.41795971244716,
        "rarity_level": "COMMON"
    },
    "232": {
        "name": "Brawler Bears #232",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/232.png",
        "dna": "dafc968deba93835e031427cb6ab26c4d6dff2ea",
        "edition": "232",
        "type": "Panda",
        "date": 1677696507101,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Ninja Instructor",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "232",
        "rarity_score": 321.40702905202767,
        "rarity_level": "RARE"
    },
    "457": {
        "name": "Brawler Bears #457",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/457.png",
        "dna": "94f64b9f26b91b7f3757c00746ce64b1b59fcf2e",
        "edition": "457",
        "type": "Deep Martian Cyborg",
        "date": 1677696508077,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "457",
        "rarity_score": 282.80293369841627,
        "rarity_level": "RARE"
    },
    "60": {
        "name": "Brawler Bears #60",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/60.png",
        "dna": "acfe8ff610640c72e4a20cef4cdfc115c4cc3b4e",
        "edition": "60",
        "type": "Deep Martian Cyborg",
        "date": 1677696509035,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "60",
        "rarity_score": 376.96056194401905,
        "rarity_level": "RARE"
    },
    "614": {
        "name": "Brawler Bears #614",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/614.png",
        "dna": "712210ae1f301b42eb872da6c3f5fbe77a35a364",
        "edition": "614",
        "type": "Lava",
        "date": 1677696510545,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 30,
        "maxHealth": 175,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "614",
        "rarity_score": 278.1560456091719,
        "rarity_level": "RARE"
    },
    "1843": {
        "name": "Brawler Bears #1843",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1843.png",
        "dna": "c6d0c122125b6d65f71e3ff5192d3ab6f99fb4d9",
        "edition": "1843",
        "type": "Black Bear",
        "date": 1677696511415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1843",
        "rarity_score": 325.689117247172,
        "rarity_level": "RARE"
    },
    "1224": {
        "name": "Brawler Bears #1224",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1224.png",
        "dna": "236e8a9aa291b4b06ed9d0c98510fcf32e217e5f",
        "edition": "1224",
        "type": "Deep Martian Cyborg",
        "date": 1677696512384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1224",
        "rarity_score": 405.85636966786194,
        "rarity_level": "RARE"
    },
    "78": {
        "name": "Brawler Bears #78",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/78.png",
        "dna": "52c2b112315e1c10199f0e0daf8b6429dd8b9b74",
        "edition": "78",
        "type": "Black Bear",
        "date": 1677696513629,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "78",
        "rarity_score": 239.2120770768423,
        "rarity_level": "COMMON"
    },
    "1912": {
        "name": "Brawler Bears #1912",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1912.png",
        "dna": "d792963f1d909a72f638781ebc51de7a72602618",
        "edition": "1912",
        "type": "Deep Martian Cyborg",
        "date": 1677696515044,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 16,
        "maxHealth": 92,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1912",
        "rarity_score": 358.7076854368987,
        "rarity_level": "RARE"
    },
    "431": {
        "name": "Brawler Bears #431",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/431.png",
        "dna": "5974ecc5a52032fc51d33f3b193f82e6b1e1d241",
        "edition": "431",
        "type": "Cheetah",
        "date": 1677696515900,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 35,
        "maxHealth": 200,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "431",
        "rarity_score": 332.0563940038491,
        "rarity_level": "RARE"
    },
    "1789": {
        "name": "Brawler Bears #1789",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1789.png",
        "dna": "4e51baf55ca5a914d281554b9425988d4028dab5",
        "edition": "1789",
        "type": "Galactic Cyborg",
        "date": 1677696516815,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1789",
        "rarity_score": 315.95872542523585,
        "rarity_level": "RARE"
    },
    "747": {
        "name": "Brawler Bears #747",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/747.png",
        "dna": "7b839b520772a8beb23757783cb14ed3e07ec870",
        "edition": "747",
        "type": "Ice",
        "date": 1677696517696,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 33,
        "maxHealth": 193,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "747",
        "rarity_score": 268.52652721116124,
        "rarity_level": "COMMON"
    },
    "2220": {
        "name": "Brawler Bears #2220",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2220.png",
        "dna": "e389bbe5530ef20c0cd14a71f8b117603f37ab69",
        "edition": "2220",
        "type": "Panda",
        "date": 1677696519029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "2220",
        "rarity_score": 324.6642134135767,
        "rarity_level": "RARE"
    },
    "867": {
        "name": "Brawler Bears #867",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/867.png",
        "dna": "6fcc644e8fcf8a3fcea3cec318de2da18fbe4308",
        "edition": "867",
        "type": "Acid Trip",
        "date": 1677696519995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "867",
        "rarity_score": 303.1521231872175,
        "rarity_level": "RARE"
    },
    "2024": {
        "name": "Brawler Bears #2024",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2024.png",
        "dna": "18d20fb70d7ada0a39d4866d39c3daa2808d1b26",
        "edition": "2024",
        "type": "Galactic Cyborg",
        "date": 1677696521188,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2024",
        "rarity_score": 310.81913877908113,
        "rarity_level": "RARE"
    },
    "1199": {
        "name": "Brawler Bears #1199",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1199.png",
        "dna": "239f333b5ca267765742185792cb0087628b1077",
        "edition": "1199",
        "type": "Ice",
        "date": 1677696522282,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1199",
        "rarity_score": 363.1808098931387,
        "rarity_level": "RARE"
    },
    "372": {
        "name": "Brawler Bears #372",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/372.png",
        "dna": "52b7dc97f8ac9609bc3a42f32aed9e5fda24bea7",
        "edition": "372",
        "type": "Deep Martian Cyborg",
        "date": 1677696523342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 26,
        "maxHealth": 152,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "372",
        "rarity_score": 235.0766288263365,
        "rarity_level": "COMMON"
    },
    "1044": {
        "name": "Brawler Bears #1044",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1044.png",
        "dna": "d5d3d8e30de0cab78c7f11d5039b1263b814c287",
        "edition": "1044",
        "type": "Galactic Cyborg",
        "date": 1677696524194,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1044",
        "rarity_score": 279.96334542037084,
        "rarity_level": "RARE"
    },
    "1788": {
        "name": "Brawler Bears #1788",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1788.png",
        "dna": "45e50d588f055ad2b420a25491f6c0e5a80c94e7",
        "edition": "1788",
        "type": "Ice",
        "date": 1677696525397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Coffee Dates",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 33,
        "maxHealth": 188,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1788",
        "rarity_score": 338.5221570145417,
        "rarity_level": "RARE"
    },
    "530": {
        "name": "Brawler Bears #530",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/530.png",
        "dna": "53c773d9d3bb225397620867f4af94583ed44523",
        "edition": "530",
        "type": "Galactic Cyborg",
        "date": 1677696526478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "530",
        "rarity_score": 278.7830015727342,
        "rarity_level": "RARE"
    },
    "1735": {
        "name": "Brawler Bears #1735",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1735.png",
        "dna": "e72732738c8d5a4df8fc0d0d62cb873fec29a760",
        "edition": "1735",
        "type": "Ice",
        "date": 1677696527868,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 26,
        "maxHealth": 149,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1735",
        "rarity_score": 233.57751643033583,
        "rarity_level": "COMMON"
    },
    "1366": {
        "name": "Brawler Bears #1366",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1366.png",
        "dna": "8c3ed111223dd8510afebb983066c8e1d307c642",
        "edition": "1366",
        "type": "Galactic Cyborg",
        "date": 1677696528828,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1366",
        "rarity_score": 239.56501204944283,
        "rarity_level": "COMMON"
    },
    "1876": {
        "name": "Brawler Bears #1876",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1876.png",
        "dna": "70342a66daa91765c3756cfd9e7cba1fed4538f6",
        "edition": "1876",
        "type": "Black Bear",
        "date": 1677696529671,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 48,
        "maxHealth": 275,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1876",
        "rarity_score": 182.57820233381918,
        "rarity_level": "COMMON"
    },
    "734": {
        "name": "Brawler Bears #734",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/734.png",
        "dna": "19a6729d6ead44d1de4009c0f8f1d04b2d6d072f",
        "edition": "734",
        "type": "Brown Bear",
        "date": 1677696531100,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "734",
        "rarity_score": 312.7826822137108,
        "rarity_level": "RARE"
    },
    "1372": {
        "name": "Brawler Bears #1372",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1372.png",
        "dna": "abe7ece9511a48e12d13a2400b2c78257da12734",
        "edition": "1372",
        "type": "Ice",
        "date": 1677696532609,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1372",
        "rarity_score": 285.7066833643809,
        "rarity_level": "RARE"
    },
    "495": {
        "name": "Brawler Bears #495",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/495.png",
        "dna": "a9a9a95560f5701754181719450b0d6411bf647f",
        "edition": "495",
        "type": "Cheetah",
        "date": 1677696533435,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Retro Comfort",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 31,
        "maxHealth": 182,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "495",
        "rarity_score": 270.1577727679702,
        "rarity_level": "COMMON"
    },
    "2217": {
        "name": "Brawler Bears #2217",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2217.png",
        "dna": "d1d29746ea9ccf616fc3ccbe5874c52aa18426f4",
        "edition": "2217",
        "type": "Galactic Cyborg",
        "date": 1677696534231,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 53,
        "maxHealth": 308,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2217",
        "rarity_score": 232.63426692094117,
        "rarity_level": "COMMON"
    },
    "2017": {
        "name": "Brawler Bears #2017",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2017.png",
        "dna": "8d5ff38db01678c7a4da987f047681021b4a5476",
        "edition": "2017",
        "type": "Ice",
        "date": 1677696535075,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2017",
        "rarity_score": 243.8270135296709,
        "rarity_level": "COMMON"
    },
    "1594": {
        "name": "Brawler Bears #1594",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1594.png",
        "dna": "0221acfaccf98fc4579365d6577432ed6564ffb9",
        "edition": "1594",
        "type": "Galaxy",
        "date": 1677696536736,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1594",
        "rarity_score": 258.61535225307244,
        "rarity_level": "COMMON"
    },
    "717": {
        "name": "Brawler Bears #717",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/717.png",
        "dna": "01fd25821036e8414a88f811ed4e56591cf10f26",
        "edition": "717",
        "type": "Lava",
        "date": 1677696537880,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "717",
        "rarity_score": 300.4953104108564,
        "rarity_level": "RARE"
    },
    "139": {
        "name": "Brawler Bears #139",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/139.png",
        "dna": "a779e5b0986328335a27d1863c0313f0d0e6569d",
        "edition": "139",
        "type": "Deep Martian Cyborg",
        "date": 1677696539131,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "139",
        "rarity_score": 174.92283523546257,
        "rarity_level": "COMMON"
    },
    "2232": {
        "name": "Brawler Bears #2232",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2232.png",
        "dna": "73a2a08d186bf61d297da67cf00cf3e83b4f3c13",
        "edition": "2232",
        "type": "Galaxy",
        "date": 1677696539951,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 16,
        "maxHealth": 96,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2232",
        "rarity_score": 339.837613700208,
        "rarity_level": "RARE"
    },
    "82": {
        "name": "Brawler Bears #82",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/82.png",
        "dna": "9c8d7ae8cc45f35444c605ec0d50bed3288d311f",
        "edition": "82",
        "type": "Panda",
        "date": 1677696541178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 26,
        "maxHealth": 152,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "82",
        "rarity_score": 229.48749162377135,
        "rarity_level": "COMMON"
    },
    "314": {
        "name": "Brawler Bears #314",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/314.png",
        "dna": "c5772c558aa6f0277ea48c12aec336013fa2be70",
        "edition": "314",
        "type": "Panda",
        "date": 1677696541839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 63,
        "maxHealth": 364,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "314",
        "rarity_score": 139.53096438149316,
        "rarity_level": "COMMON"
    },
    "2196": {
        "name": "Brawler Bears #2196",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2196.png",
        "dna": "704e854b515c1ba49414da20ea5c7762e43148e6",
        "edition": "2196",
        "type": "Deep Martian Cyborg",
        "date": 1677696542776,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Illusionist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 219,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "2196",
        "rarity_score": 259.9069041287447,
        "rarity_level": "COMMON"
    },
    "2060": {
        "name": "Brawler Bears #2060",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2060.png",
        "dna": "5a901e2cd1df83d8b389c124b3815332dea2318c",
        "edition": "2060",
        "type": "Panda",
        "date": 1677696543642,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "2060",
        "rarity_score": 351.5658905658906,
        "rarity_level": "RARE"
    },
    "2275": {
        "name": "Brawler Bears #2275",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2275.png",
        "dna": "9a1224033bbd2bf96e86c2985503b41ffc172c05",
        "edition": "2275",
        "type": "Brown Bear",
        "date": 1677696544775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "2275",
        "rarity_score": 253.52556081212026,
        "rarity_level": "COMMON"
    },
    "118": {
        "name": "Brawler Bears #118",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/118.png",
        "dna": "f5abd391b4e16b46a9074e3ce244d0e140bd9d19",
        "edition": "118",
        "type": "Brown Bear",
        "date": 1677696545582,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "118",
        "rarity_score": 299.09947535897874,
        "rarity_level": "RARE"
    },
    "422": {
        "name": "Brawler Bears #422",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/422.png",
        "dna": "e12cc2b31c40d288e02ea09fcdba9d449d314163",
        "edition": "422",
        "type": "Ice",
        "date": 1677696546361,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 32,
        "maxHealth": 188,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "422",
        "rarity_score": 289.834308673198,
        "rarity_level": "RARE"
    },
    "2150": {
        "name": "Brawler Bears #2150",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2150.png",
        "dna": "75d435284398923dd9db1ec17f99821ea3a1a1f0",
        "edition": "2150",
        "type": "Black Bear",
        "date": 1677696547986,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 44,
        "maxHealth": 254,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2150",
        "rarity_score": 243.8394770944722,
        "rarity_level": "COMMON"
    },
    "1577": {
        "name": "Brawler Bears #1577",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1577.png",
        "dna": "19b7f2bf32a34bbe7740125f0e0476d02cf64109",
        "edition": "1577",
        "type": "Brown Bear",
        "date": 1677696549674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1577",
        "rarity_score": 215.89647403293685,
        "rarity_level": "COMMON"
    },
    "1152": {
        "name": "Brawler Bears #1152",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1152.png",
        "dna": "91ea85b748d1c71ff0b0a58703c47a3cafa0988a",
        "edition": "1152",
        "type": "Lava",
        "date": 1677696550866,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 25,
        "maxHealth": 146,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1152",
        "rarity_score": 221.75091210323987,
        "rarity_level": "COMMON"
    },
    "1956": {
        "name": "Brawler Bears #1956",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1956.png",
        "dna": "9c9af588b3b51e9d8c94c65dff2a4368bc4c8b37",
        "edition": "1956",
        "type": "Galactic Cyborg",
        "date": 1677696552526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Astro Of The Night",
                "score": 104.73684210526316
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Dual Night Blades",
            "damage": 71,
            "power": 11
        },
        "id": "1956",
        "rarity_score": 326.4424124534596,
        "rarity_level": "RARE"
    },
    "1144": {
        "name": "Brawler Bears #1144",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1144.png",
        "dna": "6a5f5c70e42fe77c074734f7bd89a1918a36060e",
        "edition": "1144",
        "type": "Black Bear",
        "date": 1677696553274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Jade Warrior",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1144",
        "rarity_score": 311.31377570209645,
        "rarity_level": "RARE"
    },
    "364": {
        "name": "Brawler Bears #364",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/364.png",
        "dna": "7295a65de53ab65f419fdd70da4bf0fdef2df6a7",
        "edition": "364",
        "type": "Ice",
        "date": 1677696554637,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "364",
        "rarity_score": 343.8501502582385,
        "rarity_level": "RARE"
    },
    "1489": {
        "name": "Brawler Bears #1489",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1489.png",
        "dna": "d3559bbb2cfe4a04c38a0f5fbbbb4a8d38614ba7",
        "edition": "1489",
        "type": "Black Bear",
        "date": 1677696555803,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Denim",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 52,
        "maxHealth": 300,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1489",
        "rarity_score": 217.51957866036196,
        "rarity_level": "COMMON"
    },
    "973": {
        "name": "Brawler Bears #973",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/973.png",
        "dna": "909801ae532a38b893cbf4da949de1147294781d",
        "edition": "973",
        "type": "Deep Martian Cyborg",
        "date": 1677696557724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "973",
        "rarity_score": 255.36123502989398,
        "rarity_level": "COMMON"
    },
    "409": {
        "name": "Brawler Bears #409",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/409.png",
        "dna": "0c632476e3379cc111f37f72a45566f8e1d89026",
        "edition": "409",
        "type": "Galactic Cyborg",
        "date": 1677696558841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "409",
        "rarity_score": 334.3463214619091,
        "rarity_level": "RARE"
    },
    "786": {
        "name": "Brawler Bears #786",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/786.png",
        "dna": "a02089c7ba746f726669e4474a3fdf40b5eb5cfa",
        "edition": "786",
        "type": "Black Bear",
        "date": 1677696559800,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 31,
        "maxHealth": 180,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "786",
        "rarity_score": 246.86153386710188,
        "rarity_level": "COMMON"
    },
    "2221": {
        "name": "Brawler Bears #2221",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2221.png",
        "dna": "2399ae69997d03a8f07ebf55a5c7a76f1edcd221",
        "edition": "2221",
        "type": "Black Bear",
        "date": 1677696561019,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 59,
        "maxHealth": 337,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2221",
        "rarity_score": 214.42651091446794,
        "rarity_level": "COMMON"
    },
    "885": {
        "name": "Brawler Bears #885",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/885.png",
        "dna": "3e65403d85f8c31671105821bb2853d2b55c6c46",
        "edition": "885",
        "type": "Brown Bear",
        "date": 1677696562865,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "885",
        "rarity_score": 315.3415007652905,
        "rarity_level": "RARE"
    },
    "172": {
        "name": "Brawler Bears #172",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/172.png",
        "dna": "dfba7040a8ff7068f60c43c93783e7d1bcc55e95",
        "edition": "172",
        "type": "Deep Martian Cyborg",
        "date": 1677696563834,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 37,
        "maxHealth": 214,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "172",
        "rarity_score": 287.60747649918744,
        "rarity_level": "RARE"
    },
    "1149": {
        "name": "Brawler Bears #1149",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1149.png",
        "dna": "b00b24a5477378191de1736ac7fd5ae42765db3f",
        "edition": "1149",
        "type": "Acid Trip",
        "date": 1677696565074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 14,
        "maxHealth": 84,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1149",
        "rarity_score": 338.66974541506517,
        "rarity_level": "RARE"
    },
    "648": {
        "name": "Brawler Bears #648",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/648.png",
        "dna": "da9769cd3915cfdb28b341b332e2a392f805ce5b",
        "edition": "648",
        "type": "Brown Bear",
        "date": 1677696566104,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 39,
        "maxHealth": 227,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "648",
        "rarity_score": 290.62236121182644,
        "rarity_level": "RARE"
    },
    "1252": {
        "name": "Brawler Bears #1252",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1252.png",
        "dna": "86c63482a15c6dc542dfdfcc5d24c841b6b5f07c",
        "edition": "1252",
        "type": "Brown Bear",
        "date": 1677696567325,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1252",
        "rarity_score": 304.3155675219188,
        "rarity_level": "RARE"
    },
    "1740": {
        "name": "Brawler Bears #1740",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1740.png",
        "dna": "b0c39b917770d5dc999ad92ab3f4ee06eb0e7d1a",
        "edition": "1740",
        "type": "Acid Trip",
        "date": 1677696569258,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1740",
        "rarity_score": 321.7724010673204,
        "rarity_level": "RARE"
    },
    "1649": {
        "name": "Brawler Bears #1649",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1649.png",
        "dna": "c3ee742503d303dfd8e8be075f201068f9a250d2",
        "edition": "1649",
        "type": "Brown Bear",
        "date": 1677696570074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 61,
        "maxHealth": 348,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1649",
        "rarity_score": 203.16725547219758,
        "rarity_level": "COMMON"
    },
    "1173": {
        "name": "Brawler Bears #1173",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1173.png",
        "dna": "9170235714b456a393b9bb897920b84981413515",
        "edition": "1173",
        "type": "Deep Martian Cyborg",
        "date": 1677696571446,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1173",
        "rarity_score": 346.79492942892455,
        "rarity_level": "RARE"
    },
    "547": {
        "name": "Brawler Bears #547",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/547.png",
        "dna": "2da771f63300472effbb4e21dbfca683d102df46",
        "edition": "547",
        "type": "Panda",
        "date": 1677696572270,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "547",
        "rarity_score": 297.2153590308643,
        "rarity_level": "RARE"
    },
    "199": {
        "name": "Brawler Bears #199",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/199.png",
        "dna": "7a3c0b0f20389770ea4168ebfbe8ba52c4fb6638",
        "edition": "199",
        "type": "Acid Trip",
        "date": 1677696573890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "199",
        "rarity_score": 282.2428820303163,
        "rarity_level": "RARE"
    },
    "546": {
        "name": "Brawler Bears #546",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/546.png",
        "dna": "254aa1954697fecf2d165ca71a10626818391a63",
        "edition": "546",
        "type": "Acid Trip",
        "date": 1677696574973,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "546",
        "rarity_score": 168.47698928170354,
        "rarity_level": "COMMON"
    },
    "1012": {
        "name": "Brawler Bears #1012",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1012.png",
        "dna": "4d695027ab262cf5e996d763cfb3c32c5dea6990",
        "edition": "1012",
        "type": "Ice",
        "date": 1677696576875,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 53,
        "maxHealth": 303,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1012",
        "rarity_score": 238.5141350297187,
        "rarity_level": "COMMON"
    },
    "639": {
        "name": "Brawler Bears #639",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/639.png",
        "dna": "600a87fd84ba76b0908535846b51e317249ed154",
        "edition": "639",
        "type": "Black Bear",
        "date": 1677696578516,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 69,
        "maxHealth": 397,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "639",
        "rarity_score": 133.9523740243848,
        "rarity_level": "COMMON"
    },
    "1653": {
        "name": "Brawler Bears #1653",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1653.png",
        "dna": "4e45b2021d4f5f703a86e72b9d306c9910438c45",
        "edition": "1653",
        "type": "Galactic Cyborg",
        "date": 1677696579462,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1653",
        "rarity_score": 323.60124026056184,
        "rarity_level": "RARE"
    },
    "1021": {
        "name": "Brawler Bears #1021",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1021.png",
        "dna": "60657b689450c035c0185bed6b0785eae4ddf30a",
        "edition": "1021",
        "type": "Brown Bear",
        "date": 1677696580982,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1021",
        "rarity_score": 375.3509723590808,
        "rarity_level": "RARE"
    },
    "1241": {
        "name": "Brawler Bears #1241",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1241.png",
        "dna": "d1dbc827da36d0ec48ac17551f0b64656f335522",
        "edition": "1241",
        "type": "Galactic Cyborg",
        "date": 1677696582146,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1241",
        "rarity_score": 224.8296058646104,
        "rarity_level": "COMMON"
    },
    "2039": {
        "name": "Brawler Bears #2039",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2039.png",
        "dna": "bd5c767745601d4bb28a8564ac7f60cf655de442",
        "edition": "2039",
        "type": "Brown Bear",
        "date": 1677696583543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 45,
        "maxHealth": 258,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2039",
        "rarity_score": 250.10306362065455,
        "rarity_level": "COMMON"
    },
    "326": {
        "name": "Brawler Bears #326",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/326.png",
        "dna": "68581aed7ad1d07d91b89a9f37b65f3281a5a8d8",
        "edition": "326",
        "type": "Black Bear",
        "date": 1677696585073,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "326",
        "rarity_score": 356.9666502573068,
        "rarity_level": "RARE"
    },
    "961": {
        "name": "Brawler Bears #961",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/961.png",
        "dna": "b891295d862d8dd5e3241dd092147d87fa3a9958",
        "edition": "961",
        "type": "Black Bear",
        "date": 1677696586539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "961",
        "rarity_score": 257.46256576274936,
        "rarity_level": "COMMON"
    },
    "2112": {
        "name": "Brawler Bears #2112",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2112.png",
        "dna": "9d6ee113e8ef1cada2d550186a422aa6c72c8cca",
        "edition": "2112",
        "type": "Ice",
        "date": 1677696587380,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2112",
        "rarity_score": 313.83375076940723,
        "rarity_level": "RARE"
    },
    "1739": {
        "name": "Brawler Bears #1739",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1739.png",
        "dna": "5cd8116c4dced88e92eb4f290fc0e5087afb8f76",
        "edition": "1739",
        "type": "Galactic Cyborg",
        "date": 1677696588954,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1739",
        "rarity_score": 275.8426365999612,
        "rarity_level": "RARE"
    },
    "712": {
        "name": "Brawler Bears #712",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/712.png",
        "dna": "9fd30d80e1468f98e950bf9e27776c080ebfa4db",
        "edition": "712",
        "type": "Brown Bear",
        "date": 1677696589860,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 40,
        "maxHealth": 231,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "712",
        "rarity_score": 215.0946226779496,
        "rarity_level": "COMMON"
    },
    "1582": {
        "name": "Brawler Bears #1582",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1582.png",
        "dna": "0fdf7dae04e7f69e70799ed0c307a3a346d9a11d",
        "edition": "1582",
        "type": "Brown Bear",
        "date": 1677696591176,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1582",
        "rarity_score": 321.3964591919309,
        "rarity_level": "RARE"
    },
    "133": {
        "name": "Brawler Bears #133",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/133.png",
        "dna": "b4aa7d892d71d5e540b4952bba6a917a43cd3574",
        "edition": "133",
        "type": "Brown Bear",
        "date": 1677696592058,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 44,
        "maxHealth": 251,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "133",
        "rarity_score": 279.8465852354626,
        "rarity_level": "RARE"
    },
    "2316": {
        "name": "Brawler Bears #2316",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2316.png",
        "dna": "60b44165aa34de2c22a7e5c9367cf3efc0115637",
        "edition": "2316",
        "type": "Cheetah",
        "date": 1677696593564,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "2316",
        "rarity_score": 255.88315165005153,
        "rarity_level": "COMMON"
    },
    "184": {
        "name": "Brawler Bears #184",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/184.png",
        "dna": "23d62648e4a7d36cf738535797889bf609101b4a",
        "edition": "184",
        "type": "Deep Martian Cyborg",
        "date": 1677696594728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "184",
        "rarity_score": 252.89787855603916,
        "rarity_level": "COMMON"
    },
    "525": {
        "name": "Brawler Bears #525",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/525.png",
        "dna": "351b1c224430f84d6cf0ab199c60edc594214079",
        "edition": "525",
        "type": "Deep Martian Cyborg",
        "date": 1677696596385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 47,
        "maxHealth": 272,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "525",
        "rarity_score": 208.70438898452642,
        "rarity_level": "COMMON"
    },
    "218": {
        "name": "Brawler Bears #218",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/218.png",
        "dna": "0d19889b63ece4dc5d666164bdcc88595dbf59bf",
        "edition": "218",
        "type": "Black Bear",
        "date": 1677696598162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "218",
        "rarity_score": 298.33116913314836,
        "rarity_level": "RARE"
    },
    "1646": {
        "name": "Brawler Bears #1646",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1646.png",
        "dna": "15aee1d6156e7fdf1a08266e4e86be84a41e9884",
        "edition": "1646",
        "type": "Cheetah",
        "date": 1677696599570,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 14,
        "maxHealth": 83,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1646",
        "rarity_score": 342.46386210244907,
        "rarity_level": "RARE"
    },
    "1534": {
        "name": "Brawler Bears #1534",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1534.png",
        "dna": "c12291c26b4bee60ee8920db9a2f15e3d5409c01",
        "edition": "1534",
        "type": "Galaxy",
        "date": 1677696600697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1534",
        "rarity_score": 304.5181443271955,
        "rarity_level": "RARE"
    },
    "2288": {
        "name": "Brawler Bears #2288",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2288.png",
        "dna": "6fa1eede6aa185d47f4ec2f3482ee91468e71bf7",
        "edition": "2288",
        "type": "Brown Bear",
        "date": 1677696602154,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 24,
        "maxHealth": 139,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "2288",
        "rarity_score": 289.4980621729598,
        "rarity_level": "RARE"
    },
    "804": {
        "name": "Brawler Bears #804",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/804.png",
        "dna": "954d5d9784608b2b627d00e47fb54dd1325ab127",
        "edition": "804",
        "type": "Ice",
        "date": 1677696602921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "804",
        "rarity_score": 344.4765284742084,
        "rarity_level": "RARE"
    },
    "424": {
        "name": "Brawler Bears #424",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/424.png",
        "dna": "7b8601a54a581b191c7350dcc6773b9a34c0d0b3",
        "edition": "424",
        "type": "Panda",
        "date": 1677696604223,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "424",
        "rarity_score": 249.4887198829505,
        "rarity_level": "COMMON"
    },
    "1853": {
        "name": "Brawler Bears #1853",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1853.png",
        "dna": "cb7e95426b928c666d6631b6876c7ac051e3162d",
        "edition": "1853",
        "type": "Black Bear",
        "date": 1677696605166,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 59,
        "maxHealth": 342,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1853",
        "rarity_score": 268.44012731715793,
        "rarity_level": "COMMON"
    },
    "1838": {
        "name": "Brawler Bears #1838",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1838.png",
        "dna": "aaaa3477a948374f5954f0c09fe14408f32621e6",
        "edition": "1838",
        "type": "Deep Martian Cyborg",
        "date": 1677696606468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 28,
        "maxHealth": 160,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "1838",
        "rarity_score": 212.5965769962393,
        "rarity_level": "COMMON"
    },
    "183": {
        "name": "Brawler Bears #183",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/183.png",
        "dna": "966fa3a017470ab7bf285a3930db19b7f741220c",
        "edition": "183",
        "type": "Acid Trip",
        "date": 1677696607292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "183",
        "rarity_score": 289.44476100541374,
        "rarity_level": "RARE"
    },
    "821": {
        "name": "Brawler Bears #821",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/821.png",
        "dna": "d6dda470fa9d21039689590ac152cd7939fa187e",
        "edition": "821",
        "type": "Deep Martian Cyborg",
        "date": 1677696608568,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "821",
        "rarity_score": 307.50184391118455,
        "rarity_level": "RARE"
    },
    "1561": {
        "name": "Brawler Bears #1561",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1561.png",
        "dna": "1f50f0f912012257f5fa6c9b4f40ff2374b83a12",
        "edition": "1561",
        "type": "Galactic Cyborg",
        "date": 1677696610147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "1561",
        "rarity_score": 239.07248105296776,
        "rarity_level": "COMMON"
    },
    "736": {
        "name": "Brawler Bears #736",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/736.png",
        "dna": "e483737e04cb6704295be4fd4d1b42f5019f84b0",
        "edition": "736",
        "type": "Acid Trip",
        "date": 1677696611390,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Money Track",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Construction",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "736",
        "rarity_score": 334.53582022020737,
        "rarity_level": "RARE"
    },
    "1619": {
        "name": "Brawler Bears #1619",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1619.png",
        "dna": "203d3e1705768322a017046f3900bd1756df3030",
        "edition": "1619",
        "type": "Black Bear",
        "date": 1677696612349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Cyborg",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 21,
        "maxHealth": 123,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1619",
        "rarity_score": 368.17900670277925,
        "rarity_level": "RARE"
    },
    "2094": {
        "name": "Brawler Bears #2094",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2094.png",
        "dna": "35f05b1dafa77271b7a665c4c0c59dcd0f1067cc",
        "edition": "2094",
        "type": "Galactic Cyborg",
        "date": 1677696613153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 34,
        "maxHealth": 198,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "2094",
        "rarity_score": 243.6645414806057,
        "rarity_level": "COMMON"
    },
    "503": {
        "name": "Brawler Bears #503",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/503.png",
        "dna": "428f8cf3b7c7ba1b7389bd83c34d2038b11bb3ad",
        "edition": "503",
        "type": "Black Bear",
        "date": 1677696614231,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "503",
        "rarity_score": 211.3368431211388,
        "rarity_level": "COMMON"
    },
    "1994": {
        "name": "Brawler Bears #1994",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1994.png",
        "dna": "d5a815ce7955a3d9e8e9f47a2a8d81f726068bca",
        "edition": "1994",
        "type": "Acid Trip",
        "date": 1677696615063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 62,
        "maxHealth": 356,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1994",
        "rarity_score": 138.26065642069176,
        "rarity_level": "COMMON"
    },
    "1972": {
        "name": "Brawler Bears #1972",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1972.png",
        "dna": "33209dbe45135a53bc58d5bab0806d6730599333",
        "edition": "1972",
        "type": "Acid Trip",
        "date": 1677696615963,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1972",
        "rarity_score": 279.30685964243486,
        "rarity_level": "RARE"
    },
    "2029": {
        "name": "Brawler Bears #2029",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2029.png",
        "dna": "b4bdbe571c691b40679a4d6b4e07122022730a80",
        "edition": "2029",
        "type": "Deep Martian Cyborg",
        "date": 1677696617069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 47,
        "maxHealth": 270,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2029",
        "rarity_score": 204.21762891082122,
        "rarity_level": "COMMON"
    },
    "242": {
        "name": "Brawler Bears #242",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/242.png",
        "dna": "a4840a2b0aaaa3a6961766518360058a2a6b0f2d",
        "edition": "242",
        "type": "Deep Martian Cyborg",
        "date": 1677696617836,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "242",
        "rarity_score": 297.83758723922244,
        "rarity_level": "RARE"
    },
    "261": {
        "name": "Brawler Bears #261",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/261.png",
        "dna": "36536b1128bc760b4da84c4f15e3993c4903e26e",
        "edition": "261",
        "type": "Black Bear",
        "date": 1677696618501,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 69,
        "maxHealth": 397,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "261",
        "rarity_score": 135.5792651863543,
        "rarity_level": "COMMON"
    },
    "59": {
        "name": "Brawler Bears #59",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/59.png",
        "dna": "2d2028a0694d613f944813be753f7824ad4d6fb1",
        "edition": "59",
        "type": "Cheetah",
        "date": 1677696619684,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 15,
        "maxHealth": 88,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "59",
        "rarity_score": 282.9483282849342,
        "rarity_level": "RARE"
    },
    "1133": {
        "name": "Brawler Bears #1133",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1133.png",
        "dna": "18e38f21f16f0471feebfbb16768ed0415e6f54e",
        "edition": "1133",
        "type": "Galactic Cyborg",
        "date": 1677696620783,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Golden Papi Butler",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1133",
        "rarity_score": 330.7182254161364,
        "rarity_level": "RARE"
    },
    "377": {
        "name": "Brawler Bears #377",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/377.png",
        "dna": "6cf8bbe1229a9424e022a7eda18cf3667317fc12",
        "edition": "377",
        "type": "Brown Bear",
        "date": 1677696622467,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "377",
        "rarity_score": 262.963531984599,
        "rarity_level": "COMMON"
    },
    "1980": {
        "name": "Brawler Bears #1980",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1980.png",
        "dna": "ba39e9dc6cac38f3573e53c8d450087719882329",
        "edition": "1980",
        "type": "Ice",
        "date": 1677696624029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1980",
        "rarity_score": 325.4249441913219,
        "rarity_level": "RARE"
    },
    "1840": {
        "name": "Brawler Bears #1840",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1840.png",
        "dna": "04fd82d26e224d846fb43512a8fae4cb3c684d80",
        "edition": "1840",
        "type": "Galactic Cyborg",
        "date": 1677696625099,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Captain",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Wiz",
                "score": 71.07142857142857
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1840",
        "rarity_score": 355.9544873495942,
        "rarity_level": "RARE"
    },
    "1706": {
        "name": "Brawler Bears #1706",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1706.png",
        "dna": "d960b29fa266cb67fb596ea19c48b88dc7b1a118",
        "edition": "1706",
        "type": "Galaxy",
        "date": 1677696626330,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Warrior Class",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1706",
        "rarity_score": 327.1870089094933,
        "rarity_level": "RARE"
    },
    "1262": {
        "name": "Brawler Bears #1262",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1262.png",
        "dna": "53e765d84aecae81762f7d1d60c7f02aa9225981",
        "edition": "1262",
        "type": "Black Bear",
        "date": 1677696627221,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Camp Fire", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 38,
        "maxHealth": 217,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "1262",
        "rarity_score": 257.81317262164356,
        "rarity_level": "COMMON"
    },
    "1578": {
        "name": "Brawler Bears #1578",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1578.png",
        "dna": "79b8757cad62c270cb30cadb030281b14b7dc4a9",
        "edition": "1578",
        "type": "Galaxy",
        "date": 1677696628187,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Oceanic Outlaw",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1578",
        "rarity_score": 255.37467440496675,
        "rarity_level": "COMMON"
    },
    "2056": {
        "name": "Brawler Bears #2056",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2056.png",
        "dna": "07f546808b4a9b9e23c5bd94147030769f90a1cf",
        "edition": "2056",
        "type": "Black Bear",
        "date": 1677696629156,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2056",
        "rarity_score": 284.7237736397924,
        "rarity_level": "RARE"
    },
    "561": {
        "name": "Brawler Bears #561",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/561.png",
        "dna": "a856ed2b51dc015bc9eed9a7b76ef7f29d407cb0",
        "edition": "561",
        "type": "Black Bear",
        "date": 1677696630280,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 38,
        "maxHealth": 222,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "561",
        "rarity_score": 260.7471725530152,
        "rarity_level": "COMMON"
    },
    "588": {
        "name": "Brawler Bears #588",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/588.png",
        "dna": "9e6fbb5e06e372dc8f0afe3ff38801e87dc1d359",
        "edition": "588",
        "type": "Black Bear",
        "date": 1677696631562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "588",
        "rarity_score": 269.067065350745,
        "rarity_level": "COMMON"
    },
    "1094": {
        "name": "Brawler Bears #1094",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1094.png",
        "dna": "01e390e80f1316896bb763d956d4ca7cf605d2f3",
        "edition": "1094",
        "type": "Brown Bear",
        "date": 1677696632374,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Guitar",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": { "name": "Guitar", "damage": 75, "power": 11 },
        "id": "1094",
        "rarity_score": 318.68662839049927,
        "rarity_level": "RARE"
    },
    "627": {
        "name": "Brawler Bears #627",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/627.png",
        "dna": "a4c3d4f84f5d3399e23721a5e623577d9f578a3e",
        "edition": "627",
        "type": "Black Bear",
        "date": 1677696633103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "627",
        "rarity_score": 247.72247028548057,
        "rarity_level": "COMMON"
    },
    "1765": {
        "name": "Brawler Bears #1765",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1765.png",
        "dna": "c0018a0cf09f175cfe0ec7cee988e117c182847e",
        "edition": "1765",
        "type": "Brown Bear",
        "date": 1677696634083,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 39,
        "maxHealth": 227,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1765",
        "rarity_score": 254.5030838388766,
        "rarity_level": "COMMON"
    },
    "498": {
        "name": "Brawler Bears #498",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/498.png",
        "dna": "f72aca8a3f032894df8616510d94c27821ef48f1",
        "edition": "498",
        "type": "Black Bear",
        "date": 1677696635876,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 32,
        "maxHealth": 185,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "498",
        "rarity_score": 222.81828198537534,
        "rarity_level": "COMMON"
    },
    "607": {
        "name": "Brawler Bears #607",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/607.png",
        "dna": "97fe77db0faa3d2cfec3c6f1a328eda98342506c",
        "edition": "607",
        "type": "Cheetah",
        "date": 1677696636744,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 51,
        "maxHealth": 294,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "607",
        "rarity_score": 271.70920554454233,
        "rarity_level": "COMMON"
    },
    "1148": {
        "name": "Brawler Bears #1148",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1148.png",
        "dna": "d2ce9a5ed4ef750aa908e972b858d3f31b3c0493",
        "edition": "1148",
        "type": "Brown Bear",
        "date": 1677696637635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 61,
        "maxHealth": 349,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1148",
        "rarity_score": 190.45806502404852,
        "rarity_level": "COMMON"
    },
    "1263": {
        "name": "Brawler Bears #1263",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1263.png",
        "dna": "00eeec7d19abebf5a9bfee403e630cb5d0f0e598",
        "edition": "1263",
        "type": "Ice",
        "date": 1677696638458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Purp Royalty",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 17,
        "maxHealth": 100,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1263",
        "rarity_score": 325.79405596542955,
        "rarity_level": "RARE"
    },
    "2235": {
        "name": "Brawler Bears #2235",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2235.png",
        "dna": "4a79fc2ad03d389341844d8a1c41e9a2b447e29b",
        "edition": "2235",
        "type": "Black Bear",
        "date": 1677696639738,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Cryptonite Reaper",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": {
            "name": "Cryptonite Reaper",
            "damage": 74,
            "power": 11
        },
        "id": "2235",
        "rarity_score": 304.55897330457464,
        "rarity_level": "RARE"
    },
    "684": {
        "name": "Brawler Bears #684",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/684.png",
        "dna": "90a21f1c7202cedf2d384b2676781f66c96d0623",
        "edition": "684",
        "type": "Black Bear",
        "date": 1677696640963,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 23,
        "maxHealth": 135,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "684",
        "rarity_score": 283.3232665968854,
        "rarity_level": "RARE"
    },
    "384": {
        "name": "Brawler Bears #384",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/384.png",
        "dna": "64762c41f65a59bb42a73d9420a74134423f6ade",
        "edition": "384",
        "type": "Galactic Cyborg",
        "date": 1677696641818,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 39,
        "maxHealth": 225,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "384",
        "rarity_score": 266.1789171956387,
        "rarity_level": "COMMON"
    },
    "2120": {
        "name": "Brawler Bears #2120",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2120.png",
        "dna": "5f1536d3c63d0a86bd583faa2e764880b04d3b8b",
        "edition": "2120",
        "type": "Deep Martian Cyborg",
        "date": 1677696642801,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Black Axe",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Umpire", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Black Axe", "damage": 73, "power": 11 },
        "id": "2120",
        "rarity_score": 270.0745769118323,
        "rarity_level": "COMMON"
    },
    "1633": {
        "name": "Brawler Bears #1633",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1633.png",
        "dna": "0532f13dbc07c21714362bff74f178416b1475f5",
        "edition": "1633",
        "type": "Brown Bear",
        "date": 1677696643598,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 45,
        "maxHealth": 262,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1633",
        "rarity_score": 306.30807224861815,
        "rarity_level": "RARE"
    },
    "1178": {
        "name": "Brawler Bears #1178",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1178.png",
        "dna": "600e9042f4297d10e90ba7e20b50ef38cabc54a7",
        "edition": "1178",
        "type": "Black Bear",
        "date": 1677696644448,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1178",
        "rarity_score": 263.50300774758193,
        "rarity_level": "COMMON"
    },
    "1879": {
        "name": "Brawler Bears #1879",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1879.png",
        "dna": "2170dcbe08b2340f26e6ab57e599f8162ee05ae5",
        "edition": "1879",
        "type": "Galactic Cyborg",
        "date": 1677696645350,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 48,
        "maxHealth": 275,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1879",
        "rarity_score": 202.9642915885916,
        "rarity_level": "COMMON"
    },
    "849": {
        "name": "Brawler Bears #849",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/849.png",
        "dna": "ad900678c48e7f00b8c4f8c53c9e916252660429",
        "edition": "849",
        "type": "Lava",
        "date": 1677696646369,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 14,
        "maxHealth": 81,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "849",
        "rarity_score": 325.3015140727752,
        "rarity_level": "RARE"
    },
    "362": {
        "name": "Brawler Bears #362",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/362.png",
        "dna": "97dd72e3e8557fba4e659304a8e947e1814c9dd2",
        "edition": "362",
        "type": "Panda",
        "date": 1677696647415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 42,
        "maxHealth": 240,
        "defaultAttack": { "name": "Machine Gun", "damage": 74, "power": 11 },
        "id": "362",
        "rarity_score": 173.81048117772616,
        "rarity_level": "COMMON"
    },
    "44": {
        "name": "Brawler Bears #44",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/44.png",
        "dna": "659b92ac0d4da90b7dc42a64cb03f27f3e456181",
        "edition": "44",
        "type": "Ice",
        "date": 1677696648398,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "44",
        "rarity_score": 306.6669812496572,
        "rarity_level": "RARE"
    },
    "235": {
        "name": "Brawler Bears #235",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/235.png",
        "dna": "d4b294202809116d00de9b554fb8fcfa9c695024",
        "edition": "235",
        "type": "Brown Bear",
        "date": 1677696649896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 60,
        "maxHealth": 345,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "235",
        "rarity_score": 231.69135487082707,
        "rarity_level": "COMMON"
    },
    "1031": {
        "name": "Brawler Bears #1031",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1031.png",
        "dna": "1915cba524f86ad6e8b91bfb989e429f26f75bb7",
        "edition": "1031",
        "type": "Black Bear",
        "date": 1677696651414,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "1031",
        "rarity_score": 303.38938160972293,
        "rarity_level": "RARE"
    },
    "1313": {
        "name": "Brawler Bears #1313",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1313.png",
        "dna": "8a6321d639212f4bb114a8196a221dc2b99757f7",
        "edition": "1313",
        "type": "Cheetah",
        "date": 1677696652710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1313",
        "rarity_score": 279.71674243498984,
        "rarity_level": "RARE"
    },
    "976": {
        "name": "Brawler Bears #976",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/976.png",
        "dna": "74855bd77ae36b1df19ec49b078548e1cd2a325c",
        "edition": "976",
        "type": "Panda",
        "date": 1677696653728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Cresent Assassin",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "976",
        "rarity_score": 308.4183650122022,
        "rarity_level": "RARE"
    },
    "181": {
        "name": "Brawler Bears #181",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/181.png",
        "dna": "b5e293c2cffa87075ad6de7b136734af5499d0b1",
        "edition": "181",
        "type": "Deep Martian Cyborg",
        "date": 1677696654532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Polo",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "181",
        "rarity_score": 205.8966684123069,
        "rarity_level": "COMMON"
    },
    "562": {
        "name": "Brawler Bears #562",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/562.png",
        "dna": "007d566cc499650eec401fabecfc1da93e7b8796",
        "edition": "562",
        "type": "Black Bear",
        "date": 1677696655485,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "562",
        "rarity_score": 284.8188947024993,
        "rarity_level": "RARE"
    },
    "1964": {
        "name": "Brawler Bears #1964",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1964.png",
        "dna": "d740a3106dad7bb8b8724ac796a92dc2e3899a38",
        "edition": "1964",
        "type": "Deep Martian Cyborg",
        "date": 1677696657054,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Butler",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1964",
        "rarity_score": 311.824491395946,
        "rarity_level": "RARE"
    },
    "459": {
        "name": "Brawler Bears #459",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/459.png",
        "dna": "8a167cfc4225047bcbe6e4c966ead281c730d3a4",
        "edition": "459",
        "type": "Galaxy",
        "date": 1677696658542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Outlaw", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "459",
        "rarity_score": 293.79730039866905,
        "rarity_level": "RARE"
    },
    "1680": {
        "name": "Brawler Bears #1680",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1680.png",
        "dna": "79002194a096a44132b4b52cb82a1241caf88f09",
        "edition": "1680",
        "type": "Cheetah",
        "date": 1677696659538,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Aqua Fox Spirit Animal",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Nurse",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 13,
        "maxHealth": 75,
        "defaultAttack": {
            "name": "Aqua Fox Spirit Animal",
            "damage": 74,
            "power": 11
        },
        "id": "1680",
        "rarity_score": 342.7550572550573,
        "rarity_level": "RARE"
    },
    "2205": {
        "name": "Brawler Bears #2205",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2205.png",
        "dna": "f7ab815a2fea499d697e013932368b0e955f3791",
        "edition": "2205",
        "type": "Brown Bear",
        "date": 1677696660766,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2205",
        "rarity_score": 268.3254959576263,
        "rarity_level": "COMMON"
    },
    "332": {
        "name": "Brawler Bears #332",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/332.png",
        "dna": "39679a325a4bf05b060fb64860367b1851b7fd6b",
        "edition": "332",
        "type": "Brown Bear",
        "date": 1677696661649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Tile Track",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "332",
        "rarity_score": 273.4163728272366,
        "rarity_level": "COMMON"
    },
    "1433": {
        "name": "Brawler Bears #1433",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1433.png",
        "dna": "cb852c4b639a8b3dc5e01bbd3e1f4a333c17aa7b",
        "edition": "1433",
        "type": "Brown Bear",
        "date": 1677696662529,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 50,
        "maxHealth": 285,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "1433",
        "rarity_score": 182.2474095196724,
        "rarity_level": "COMMON"
    },
    "359": {
        "name": "Brawler Bears #359",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/359.png",
        "dna": "74160283570e7a36796af46895dbb915961cc8c4",
        "edition": "359",
        "type": "Acid Trip",
        "date": 1677696664354,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 36,
        "maxHealth": 207,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "359",
        "rarity_score": 298.4689594150413,
        "rarity_level": "RARE"
    },
    "944": {
        "name": "Brawler Bears #944",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/944.png",
        "dna": "ccdc2247f871e91eee32be33295cf8362a7de889",
        "edition": "944",
        "type": "Galactic Cyborg",
        "date": 1677696665513,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "944",
        "rarity_score": 339.5438169786303,
        "rarity_level": "RARE"
    },
    "1682": {
        "name": "Brawler Bears #1682",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1682.png",
        "dna": "ae2c673bdcefc82b646e78a8beb1d95c97831932",
        "edition": "1682",
        "type": "Black Bear",
        "date": 1677696667034,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 38,
        "maxHealth": 218,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "1682",
        "rarity_score": 256.0754939127674,
        "rarity_level": "COMMON"
    },
    "1258": {
        "name": "Brawler Bears #1258",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1258.png",
        "dna": "2bc0d10e9d1b1249ae5735a53316d9aecb21ce37",
        "edition": "1258",
        "type": "Panda",
        "date": 1677696667823,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Commander",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 52,
        "maxHealth": 298,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1258",
        "rarity_score": 233.97885487808875,
        "rarity_level": "COMMON"
    },
    "1859": {
        "name": "Brawler Bears #1859",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1859.png",
        "dna": "c44eeed62582994fc090debbb586bd6abefea734",
        "edition": "1859",
        "type": "Brown Bear",
        "date": 1677696668923,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Gramps", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "1859",
        "rarity_score": 326.10869152249217,
        "rarity_level": "RARE"
    },
    "677": {
        "name": "Brawler Bears #677",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/677.png",
        "dna": "f435bbceb819531ee9014498f4322f5a79d3e829",
        "edition": "677",
        "type": "Black Bear",
        "date": 1677696669976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Stereo",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 48,
        "maxHealth": 279,
        "defaultAttack": { "name": "Stereo", "damage": 71, "power": 11 },
        "id": "677",
        "rarity_score": 153.34173605917078,
        "rarity_level": "COMMON"
    },
    "92": {
        "name": "Brawler Bears #92",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/92.png",
        "dna": "4533fbe3dd82cd5e295ad6d8b05442158a920c2f",
        "edition": "92",
        "type": "Ice",
        "date": 1677696671082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 33,
        "maxHealth": 191,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "92",
        "rarity_score": 240.74337371705886,
        "rarity_level": "COMMON"
    },
    "1784": {
        "name": "Brawler Bears #1784",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1784.png",
        "dna": "8a964056ff93223a1760f80a55f03e8e11c5008d",
        "edition": "1784",
        "type": "Brown Bear",
        "date": 1677696672643,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Night King",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1784",
        "rarity_score": 253.60338803544437,
        "rarity_level": "COMMON"
    },
    "2322": {
        "name": "Brawler Bears #2322",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2322.png",
        "dna": "2b55d5b09e16dd9cd167fc222cee77fd979c3e67",
        "edition": "2322",
        "type": "Galaxy",
        "date": 1677696673619,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 65,
        "maxHealth": 373,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2322",
        "rarity_score": 139.47668308021412,
        "rarity_level": "COMMON"
    },
    "258": {
        "name": "Brawler Bears #258",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/258.png",
        "dna": "1e239809482df5b439f30d9107c3a22335f9e3aa",
        "edition": "258",
        "type": "Galaxy",
        "date": 1677696674497,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Nurse", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 32,
        "maxHealth": 186,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "258",
        "rarity_score": 311.0258171670333,
        "rarity_level": "RARE"
    },
    "27": {
        "name": "Brawler Bears #27",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/27.png",
        "dna": "9cb56f01207bc84f39c2c407ebbb64eee3a9fecd",
        "edition": "27",
        "type": "Brown Bear",
        "date": 1677696675664,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "27",
        "rarity_score": 301.1424024467065,
        "rarity_level": "RARE"
    },
    "446": {
        "name": "Brawler Bears #446",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/446.png",
        "dna": "567757f45eb153733876e4561af4677b48334a1f",
        "edition": "446",
        "type": "Galactic Cyborg",
        "date": 1677696676521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "446",
        "rarity_score": 266.9596686407056,
        "rarity_level": "COMMON"
    },
    "1636": {
        "name": "Brawler Bears #1636",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1636.png",
        "dna": "b98811b8b5ddcf0b7c2a1764de8ea13855d0c365",
        "edition": "1636",
        "type": "Deep Martian Cyborg",
        "date": 1677696678423,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Rogue Love",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "CaptainX2", "damage": 74, "power": 11 },
        "id": "1636",
        "rarity_score": 321.65522657163876,
        "rarity_level": "RARE"
    },
    "827": {
        "name": "Brawler Bears #827",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/827.png",
        "dna": "2007862d139aba5d43c79c5a4bcd44ecb446d028",
        "edition": "827",
        "type": "Acid Trip",
        "date": 1677696679867,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Mont",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "827",
        "rarity_score": 288.9607708259729,
        "rarity_level": "RARE"
    },
    "2079": {
        "name": "Brawler Bears #2079",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2079.png",
        "dna": "e0fdaf7c70e9cad309bb9a709e999d602ea09e42",
        "edition": "2079",
        "type": "Cheetah",
        "date": 1677696681065,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "2079",
        "rarity_score": 277.1918987560666,
        "rarity_level": "RARE"
    },
    "1697": {
        "name": "Brawler Bears #1697",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1697.png",
        "dna": "d6151257752f927cedd740d24c97bb291261efe6",
        "edition": "1697",
        "type": "Brown Bear",
        "date": 1677696681844,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 49,
        "maxHealth": 285,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1697",
        "rarity_score": 170.76925629264622,
        "rarity_level": "COMMON"
    },
    "1947": {
        "name": "Brawler Bears #1947",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1947.png",
        "dna": "9221b1748b4bb999079b6a865de3aaeb56320085",
        "edition": "1947",
        "type": "Brown Bear",
        "date": 1677696683172,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 45,
        "maxHealth": 258,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1947",
        "rarity_score": 260.98658280401764,
        "rarity_level": "COMMON"
    },
    "1365": {
        "name": "Brawler Bears #1365",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1365.png",
        "dna": "7ab490b75cd1d1972feda9b3df670b0a298359fe",
        "edition": "1365",
        "type": "Acid Trip",
        "date": 1677696684508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Cyborg",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 46,
        "maxHealth": 264,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1365",
        "rarity_score": 183.2216161100434,
        "rarity_level": "COMMON"
    },
    "1546": {
        "name": "Brawler Bears #1546",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1546.png",
        "dna": "e261d92bdd609ae3f37067bc455d50894d8c9d1c",
        "edition": "1546",
        "type": "Panda",
        "date": 1677696685693,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Youth Pastor",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "1546",
        "rarity_score": 317.1921889946431,
        "rarity_level": "RARE"
    },
    "1749": {
        "name": "Brawler Bears #1749",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1749.png",
        "dna": "9b494bb399b76f6c28560a63320659433894decf",
        "edition": "1749",
        "type": "Galaxy",
        "date": 1677696686613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Comfy",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1749",
        "rarity_score": 256.4126082050482,
        "rarity_level": "COMMON"
    },
    "1913": {
        "name": "Brawler Bears #1913",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1913.png",
        "dna": "3ef95911cc5266644908232d29aeeb344e9ea99b",
        "edition": "1913",
        "type": "Brown Bear",
        "date": 1677696687624,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1913",
        "rarity_score": 286.9751634526087,
        "rarity_level": "RARE"
    },
    "186": {
        "name": "Brawler Bears #186",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/186.png",
        "dna": "64cea55a983503869038fcc71245946763f71a64",
        "edition": "186",
        "type": "Galactic Cyborg",
        "date": 1677696689206,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "186",
        "rarity_score": 164.35991393473552,
        "rarity_level": "COMMON"
    },
    "2160": {
        "name": "Brawler Bears #2160",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2160.png",
        "dna": "b9a975e46663f3c12f68741f646a85579fbe8443",
        "edition": "2160",
        "type": "Acid Trip",
        "date": 1677696690162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "CaptainX2",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2160",
        "rarity_score": 237.27546587132184,
        "rarity_level": "COMMON"
    },
    "621": {
        "name": "Brawler Bears #621",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/621.png",
        "dna": "7509a31e45b1cb4e2714c901d112e6f61956f1d9",
        "edition": "621",
        "type": "Ice",
        "date": 1677696691240,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "621",
        "rarity_score": 321.88776391736917,
        "rarity_level": "RARE"
    },
    "16": {
        "name": "Brawler Bears #16",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/16.png",
        "dna": "c9d5410d5933475027c2a5a838efe7a4cd0eaf49",
        "edition": "16",
        "type": "Black Bear",
        "date": 1677696692410,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Prime Minister",
                "score": 62.1875
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 43,
        "maxHealth": 249,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "16",
        "rarity_score": 277.2256449233925,
        "rarity_level": "RARE"
    },
    "1992": {
        "name": "Brawler Bears #1992",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1992.png",
        "dna": "be005e4e60e7adea88fd6cfa6dbace82bb30de59",
        "edition": "1992",
        "type": "Brown Bear",
        "date": 1677696693426,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "1992",
        "rarity_score": 308.9965124761482,
        "rarity_level": "RARE"
    },
    "1201": {
        "name": "Brawler Bears #1201",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1201.png",
        "dna": "097e11987ef931940c35f47fbe92ae2b7db63dd0",
        "edition": "1201",
        "type": "Ice",
        "date": 1677696694894,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Mister Formal",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1201",
        "rarity_score": 346.6579707798579,
        "rarity_level": "RARE"
    },
    "1111": {
        "name": "Brawler Bears #1111",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1111.png",
        "dna": "05dc2a29ff338feda509ef2ac2cb4769858302c4",
        "edition": "1111",
        "type": "Black Bear",
        "date": 1677696696492,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Emperor",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1111",
        "rarity_score": 205.69225111463837,
        "rarity_level": "COMMON"
    },
    "220": {
        "name": "Brawler Bears #220",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/220.png",
        "dna": "3e612ad7764cc634f5c902a284f8b990fd6c83ca",
        "edition": "220",
        "type": "Brown Bear",
        "date": 1677696697593,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 39,
        "maxHealth": 223,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "220",
        "rarity_score": 331.37846815098516,
        "rarity_level": "RARE"
    },
    "784": {
        "name": "Brawler Bears #784",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/784.png",
        "dna": "b322f14478df338e93b4b47842055424ac76e43a",
        "edition": "784",
        "type": "Acid Trip",
        "date": 1677696698715,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 15,
        "maxHealth": 87,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "784",
        "rarity_score": 341.02622917327807,
        "rarity_level": "RARE"
    },
    "5": {
        "name": "Brawler Bears #5",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/5.png",
        "dna": "b85c47e5706d1f123cef503be0daaaf50f7678a5",
        "edition": "5",
        "type": "Brown Bear",
        "date": 1677696700398,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Ice King",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "5",
        "rarity_score": 267.87743279711054,
        "rarity_level": "COMMON"
    },
    "396": {
        "name": "Brawler Bears #396",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/396.png",
        "dna": "5d355fdb4846bac6c09b45690ec0d5368ba6e9c5",
        "edition": "396",
        "type": "Panda",
        "date": 1677696701821,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "396",
        "rarity_score": 346.81587878044064,
        "rarity_level": "RARE"
    },
    "1106": {
        "name": "Brawler Bears #1106",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1106.png",
        "dna": "4c60d66021581510cb38cee0ad1efc87583707a3",
        "edition": "1106",
        "type": "Brown Bear",
        "date": 1677696702834,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 24,
        "maxHealth": 137,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1106",
        "rarity_score": 302.9277399924145,
        "rarity_level": "RARE"
    },
    "2147": {
        "name": "Brawler Bears #2147",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2147.png",
        "dna": "744d721a5f15de0aab7f38c55a3c9c6130a12a0e",
        "edition": "2147",
        "type": "Acid Trip",
        "date": 1677696703972,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Palm Spring",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 32,
        "maxHealth": 183,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "2147",
        "rarity_score": 265.16873443383815,
        "rarity_level": "COMMON"
    },
    "441": {
        "name": "Brawler Bears #441",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/441.png",
        "dna": "e91a768045d4c601566e4e35ccffdc5a219ad893",
        "edition": "441",
        "type": "Galactic Cyborg",
        "date": 1677696705259,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 48,
        "maxHealth": 276,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "441",
        "rarity_score": 188.88709321547464,
        "rarity_level": "COMMON"
    },
    "88": {
        "name": "Brawler Bears #88",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/88.png",
        "dna": "a51abac197048d3412e8f350112118e8e669be0d",
        "edition": "88",
        "type": "Galactic Cyborg",
        "date": 1677696706542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Knight",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 17,
        "maxHealth": 100,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "88",
        "rarity_score": 328.77630591287925,
        "rarity_level": "RARE"
    },
    "794": {
        "name": "Brawler Bears #794",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/794.png",
        "dna": "2cbe84b561cdd5a1f2e3eeca55b90c175614568a",
        "edition": "794",
        "type": "Brown Bear",
        "date": 1677696707525,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Rogue Cyborg",
                "score": 53.78378378378378
            }
        ],
        "level": 25,
        "maxHealth": 143,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "794",
        "rarity_score": 278.53171963966565,
        "rarity_level": "RARE"
    },
    "493": {
        "name": "Brawler Bears #493",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/493.png",
        "dna": "cf6ea96a46d41bdd80112f7e82e0be13857899a4",
        "edition": "493",
        "type": "Galaxy",
        "date": 1677696709270,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Construction",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Sargent",
                "score": 68.62068965517241
            },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Ice Blades", "damage": 72, "power": 11 },
        "id": "493",
        "rarity_score": 329.1963138917727,
        "rarity_level": "RARE"
    },
    "2320": {
        "name": "Brawler Bears #2320",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2320.png",
        "dna": "e3fc7e5bff0a6f420ebb1e39260b1dc59a3062fc",
        "edition": "2320",
        "type": "Brown Bear",
        "date": 1677696710440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Botanist",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 44,
        "maxHealth": 253,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2320",
        "rarity_score": 264.1601595950994,
        "rarity_level": "COMMON"
    },
    "146": {
        "name": "Brawler Bears #146",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/146.png",
        "dna": "256742f9cb5b341ee92560a93a0215e49a41d534",
        "edition": "146",
        "type": "Panda",
        "date": 1677696711546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Piapple Lovin",
                "score": 82.91666666666667
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 15,
        "maxHealth": 90,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "146",
        "rarity_score": 342.94351743025805,
        "rarity_level": "RARE"
    },
    "347": {
        "name": "Brawler Bears #347",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/347.png",
        "dna": "2a99fdf9c3fb6c289d2881ad8639224ab453aba6",
        "edition": "347",
        "type": "Brown Bear",
        "date": 1677696712718,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Brisk Walk",
                "score": 142.14285714285714
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "347",
        "rarity_score": 359.4539817929844,
        "rarity_level": "RARE"
    },
    "1679": {
        "name": "Brawler Bears #1679",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1679.png",
        "dna": "5c6f1704e62019e2148e72fbbe5f4d9e663847ff",
        "edition": "1679",
        "type": "Ice",
        "date": 1677696713729,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1679",
        "rarity_score": 263.44044698522623,
        "rarity_level": "COMMON"
    },
    "2084": {
        "name": "Brawler Bears #2084",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2084.png",
        "dna": "76b4bcbfa46648a19cee4c433be9a3b6a5476c9b",
        "edition": "2084",
        "type": "Galactic Cyborg",
        "date": 1677696715072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Samurai",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Trousers",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 38,
        "maxHealth": 221,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2084",
        "rarity_score": 300.2311681420168,
        "rarity_level": "RARE"
    },
    "1729": {
        "name": "Brawler Bears #1729",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1729.png",
        "dna": "a5673271d411aabdeddf673c6d6a616d555a9be1",
        "edition": "1729",
        "type": "Galaxy",
        "date": 1677696717068,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1729",
        "rarity_score": 232.0771009120194,
        "rarity_level": "COMMON"
    },
    "1548": {
        "name": "Brawler Bears #1548",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1548.png",
        "dna": "20407376f62c86efe5e9f1d967f5dad1bb2584cd",
        "edition": "1548",
        "type": "Galaxy",
        "date": 1677696718246,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "University Polo", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "1548",
        "rarity_score": 316.8591421723261,
        "rarity_level": "RARE"
    },
    "2234": {
        "name": "Brawler Bears #2234",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2234.png",
        "dna": "182149b7c16e2a50529c84326c5451823d1fc93c",
        "edition": "2234",
        "type": "Ice",
        "date": 1677696719101,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Floral Track",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 47,
        "maxHealth": 274,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "2234",
        "rarity_score": 186.96931712035695,
        "rarity_level": "COMMON"
    },
    "870": {
        "name": "Brawler Bears #870",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/870.png",
        "dna": "edc24cefaaf5326e5a562e59ed02373c95b705bb",
        "edition": "870",
        "type": "Deep Martian Cyborg",
        "date": 1677696720669,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Shuriken",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 33,
        "maxHealth": 190,
        "defaultAttack": { "name": "Shuriken", "damage": 73, "power": 11 },
        "id": "870",
        "rarity_score": 264.1040795914571,
        "rarity_level": "COMMON"
    },
    "1700": {
        "name": "Brawler Bears #1700",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1700.png",
        "dna": "91d85b5bed66f5fc14f8dd79411c0382fcc99add",
        "edition": "1700",
        "type": "Cheetah",
        "date": 1677696721898,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 30,
        "maxHealth": 174,
        "defaultAttack": { "name": "Butterfly Net", "damage": 73, "power": 11 },
        "id": "1700",
        "rarity_score": 264.32255854422465,
        "rarity_level": "COMMON"
    },
    "1602": {
        "name": "Brawler Bears #1602",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1602.png",
        "dna": "3e4f3d34c1aec1ddda4f1db6e745730b75066986",
        "edition": "1602",
        "type": "Galactic Cyborg",
        "date": 1677696722895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Night King",
                "score": 153.07692307692307
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "1602",
        "rarity_score": 311.19453216830584,
        "rarity_level": "RARE"
    },
    "4": {
        "name": "Brawler Bears #4",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/4.png",
        "dna": "c91c7395b9444f33d0bd62d0efa448617fe7d240",
        "edition": "4",
        "type": "Galaxy",
        "date": 1677696724778,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Dual Fire Axes",
                "score": 40.61224489795919
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dazzed",
                "score": 24.26829268292683
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 44,
        "maxHealth": 251,
        "defaultAttack": {
            "name": "Dual Fire Axes",
            "damage": 72,
            "power": 11
        },
        "id": "4",
        "rarity_score": 158.7756085644022,
        "rarity_level": "COMMON"
    },
    "877": {
        "name": "Brawler Bears #877",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/877.png",
        "dna": "c3aae16e98f4ce7a6b8c4c8e18e9d82f653c9c57",
        "edition": "877",
        "type": "Panda",
        "date": 1677696725621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Sahara", "score": 99.5 },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Locked In",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 15,
        "maxHealth": 91,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "877",
        "rarity_score": 332.6567191774189,
        "rarity_level": "RARE"
    },
    "381": {
        "name": "Brawler Bears #381",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/381.png",
        "dna": "dc23e2e481337fbf056468180f0a469c756d6164",
        "edition": "381",
        "type": "Brown Bear",
        "date": 1677696726721,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Gas Mask",
                "score": 64.19354838709677
            }
        ],
        "level": 60,
        "maxHealth": 348,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "381",
        "rarity_score": 242.77131675388205,
        "rarity_level": "COMMON"
    },
    "1048": {
        "name": "Brawler Bears #1048",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1048.png",
        "dna": "9fbae9d01f445915256e4f5d81234db31920ea0d",
        "edition": "1048",
        "type": "Galaxy",
        "date": 1677696727789,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Bamboo Samurai Swords",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": {
            "name": "Dual Bamboo Samurai Swords",
            "damage": 73,
            "power": 11
        },
        "id": "1048",
        "rarity_score": 243.53300600625485,
        "rarity_level": "COMMON"
    },
    "322": {
        "name": "Brawler Bears #322",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/322.png",
        "dna": "0b819787c895880cc95c7d419b895e5e18700f3a",
        "edition": "322",
        "type": "Black Bear",
        "date": 1677696729158,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 22,
        "maxHealth": 128,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "322",
        "rarity_score": 319.4825339065371,
        "rarity_level": "RARE"
    },
    "1022": {
        "name": "Brawler Bears #1022",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1022.png",
        "dna": "cbba1c675dd6198669eb08cbcf171b3d84524a70",
        "edition": "1022",
        "type": "Black Bear",
        "date": 1677696730136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 21,
        "maxHealth": 125,
        "defaultAttack": {
            "name": "Mad Scientist Of The Forest",
            "damage": 73,
            "power": 11
        },
        "id": "1022",
        "rarity_score": 343.7212929530815,
        "rarity_level": "RARE"
    },
    "705": {
        "name": "Brawler Bears #705",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/705.png",
        "dna": "6c8ecc41076b35db69cdf939b6d52b5209e0cc03",
        "edition": "705",
        "type": "Galaxy",
        "date": 1677696731441,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Formal Nomad",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Animated Rage",
                "score": 30.151515151515152
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 17,
        "maxHealth": 97,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "705",
        "rarity_score": 322.02837151847734,
        "rarity_level": "RARE"
    },
    "958": {
        "name": "Brawler Bears #958",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/958.png",
        "dna": "826100748bc012bebc0e25c6439eb0634d7d134d",
        "edition": "958",
        "type": "Panda",
        "date": 1677696732350,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Brisk Evenings",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 31,
        "maxHealth": 179,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "958",
        "rarity_score": 321.2543104525952,
        "rarity_level": "RARE"
    },
    "526": {
        "name": "Brawler Bears #526",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/526.png",
        "dna": "2d561e73f60cd5c205223f7699e8b78ea15404a0",
        "edition": "526",
        "type": "Deep Martian Cyborg",
        "date": 1677696733385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Tile Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Warrior",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "526",
        "rarity_score": 311.4024949044976,
        "rarity_level": "RARE"
    },
    "271": {
        "name": "Brawler Bears #271",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/271.png",
        "dna": "59fa56198446fa7b78128d67ea1c8e97c2065881",
        "edition": "271",
        "type": "Panda",
        "date": 1677696734050,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Plaid Track",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "271",
        "rarity_score": 284.09321551792254,
        "rarity_level": "RARE"
    },
    "534": {
        "name": "Brawler Bears #534",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/534.png",
        "dna": "52536af38bb07b5fe20253f109153d2d0f768e99",
        "edition": "534",
        "type": "Ice",
        "date": 1677696735482,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            { "trait_type": "Body", "value": "Groomsman", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "534",
        "rarity_score": 236.60688390284642,
        "rarity_level": "COMMON"
    },
    "230": {
        "name": "Brawler Bears #230",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/230.png",
        "dna": "7e57a00dcfd13465bb24e91eb656e976df2ec699",
        "edition": "230",
        "type": "Panda",
        "date": 1677696736333,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Colonel",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 36,
        "maxHealth": 206,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "230",
        "rarity_score": 270.62107177397127,
        "rarity_level": "COMMON"
    },
    "2023": {
        "name": "Brawler Bears #2023",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2023.png",
        "dna": "e99c8307be186aa5eb3da44d97f17bf495c7d994",
        "edition": "2023",
        "type": "Galactic Cyborg",
        "date": 1677696737946,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 64,
        "maxHealth": 369,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2023",
        "rarity_score": 131.86067549316374,
        "rarity_level": "COMMON"
    },
    "1248": {
        "name": "Brawler Bears #1248",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1248.png",
        "dna": "4f75d663d14844fd8b41b7de81849d256538a6f1",
        "edition": "1248",
        "type": "Brown Bear",
        "date": 1677696738848,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            { "trait_type": "Eyes", "value": "Annoyed", "score": 31.09375 },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 53,
        "maxHealth": 307,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1248",
        "rarity_score": 185.1431613718678,
        "rarity_level": "COMMON"
    },
    "1382": {
        "name": "Brawler Bears #1382",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1382.png",
        "dna": "8fddda7605a81504e6afd9f52bf78c70b4ec5789",
        "edition": "1382",
        "type": "Brown Bear",
        "date": 1677696739711,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Violet Outlaw",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 44,
        "maxHealth": 255,
        "defaultAttack": { "name": "Red Electric", "damage": 67, "power": 14 },
        "id": "1382",
        "rarity_score": 293.63286117305154,
        "rarity_level": "RARE"
    },
    "1937": {
        "name": "Brawler Bears #1937",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1937.png",
        "dna": "0bfd2045e245fbe50b724d8864e764bcac01c312",
        "edition": "1937",
        "type": "Cheetah",
        "date": 1677696740458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 35,
        "maxHealth": 202,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1937",
        "rarity_score": 254.3727666431499,
        "rarity_level": "COMMON"
    },
    "1046": {
        "name": "Brawler Bears #1046",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1046.png",
        "dna": "07fbe6b089c66fbbc0af8a2187278296e66958c5",
        "edition": "1046",
        "type": "Black Bear",
        "date": 1677696741506,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": {
            "name": "Dual Night Light",
            "damage": 72,
            "power": 11
        },
        "id": "1046",
        "rarity_score": 285.5586897365272,
        "rarity_level": "RARE"
    },
    "2070": {
        "name": "Brawler Bears #2070",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2070.png",
        "dna": "1ba88c3699fef9dc27daff653cd20596c66deb3e",
        "edition": "2070",
        "type": "Black Bear",
        "date": 1677696742676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            { "trait_type": "Body", "value": "Knight", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Samurai",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 23,
        "maxHealth": 133,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "2070",
        "rarity_score": 303.79041283237353,
        "rarity_level": "RARE"
    },
    "2042": {
        "name": "Brawler Bears #2042",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2042.png",
        "dna": "0c7facf8fb3a60a0dd70d42e306ed3e3adcb75bc",
        "edition": "2042",
        "type": "Black Bear",
        "date": 1677696743406,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Vibes",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2042",
        "rarity_score": 258.3386463008466,
        "rarity_level": "COMMON"
    },
    "1597": {
        "name": "Brawler Bears #1597",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1597.png",
        "dna": "716b8b9924dbbf5d303b511acf21404f1eded41f",
        "edition": "1597",
        "type": "Cheetah",
        "date": 1677696744412,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 51,
        "maxHealth": 294,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1597",
        "rarity_score": 235.577563506012,
        "rarity_level": "COMMON"
    },
    "2002": {
        "name": "Brawler Bears #2002",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2002.png",
        "dna": "cf92116c1d2375916ecf3fb27ddf8ed2a18e5262",
        "edition": "2002",
        "type": "Cheetah",
        "date": 1677696746010,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Camo",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 35,
        "maxHealth": 205,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2002",
        "rarity_score": 251.32010298421855,
        "rarity_level": "COMMON"
    },
    "753": {
        "name": "Brawler Bears #753",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/753.png",
        "dna": "1bf74820f2de5fec7570b60a56176f8425c6f358",
        "edition": "753",
        "type": "Galactic Cyborg",
        "date": 1677696747137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Green Colonel",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 16,
        "maxHealth": 94,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "753",
        "rarity_score": 346.1824626148139,
        "rarity_level": "RARE"
    },
    "671": {
        "name": "Brawler Bears #671",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/671.png",
        "dna": "7f80ebe3830d33e3b68b5e4eeaa1259441b86293",
        "edition": "671",
        "type": "Lava",
        "date": 1677696748936,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            { "trait_type": "Back", "value": "Machine Gun", "score": 49.75 },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 29,
        "maxHealth": 170,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "671",
        "rarity_score": 292.9849082195767,
        "rarity_level": "RARE"
    },
    "2240": {
        "name": "Brawler Bears #2240",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2240.png",
        "dna": "fac2b8f7967bde881da97455a5f5e7527c4f1949",
        "edition": "2240",
        "type": "Deep Martian Cyborg",
        "date": 1677696749739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 63,
        "maxHealth": 364,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2240",
        "rarity_score": 136.53957370820126,
        "rarity_level": "COMMON"
    },
    "291": {
        "name": "Brawler Bears #291",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/291.png",
        "dna": "1f1eec9f8abe36789c18cd7f066a921ee4d2a788",
        "edition": "291",
        "type": "Deep Martian Cyborg",
        "date": 1677696750978,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 33,
        "maxHealth": 192,
        "defaultAttack": {
            "name": "Blue Lightning",
            "damage": 74,
            "power": 11
        },
        "id": "291",
        "rarity_score": 263.8028360971326,
        "rarity_level": "COMMON"
    },
    "339": {
        "name": "Brawler Bears #339",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/339.png",
        "dna": "3ccb5eeaf2b51703afc1c2629ae407a2a2568283",
        "edition": "339",
        "type": "Brown Bear",
        "date": 1677696751916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Cowboy",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "339",
        "rarity_score": 278.0536355665395,
        "rarity_level": "RARE"
    },
    "300": {
        "name": "Brawler Bears #300",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/300.png",
        "dna": "9d3b44d89a37892b293a35ce330636d2dd72a8be",
        "edition": "300",
        "type": "Ice",
        "date": 1677696753235,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Blades",
                "score": 36.18181818181818
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "General",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 17,
        "maxHealth": 99,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "300",
        "rarity_score": 293.0038611412171,
        "rarity_level": "RARE"
    },
    "875": {
        "name": "Brawler Bears #875",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/875.png",
        "dna": "fafe80f8369d83dbb97d1fb2cde4b96c43c99f43",
        "edition": "875",
        "type": "Deep Martian Cyborg",
        "date": 1677696754204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Spirit Lion",
                "score": 51.02564102564103
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Night Floral",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 16,
        "maxHealth": 93,
        "defaultAttack": { "name": "Spirit Lion", "damage": 74, "power": 11 },
        "id": "875",
        "rarity_score": 301.7761177015683,
        "rarity_level": "RARE"
    },
    "1071": {
        "name": "Brawler Bears #1071",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1071.png",
        "dna": "e0065f298dd504566be27fb35286c91ae319497a",
        "edition": "1071",
        "type": "Brown Bear",
        "date": 1677696755346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 61,
        "maxHealth": 349,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1071",
        "rarity_score": 207.25291556120754,
        "rarity_level": "COMMON"
    },
    "841": {
        "name": "Brawler Bears #841",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/841.png",
        "dna": "8deab7f9b5ba7eb52b399986a709e2ce82c1730b",
        "edition": "841",
        "type": "Brown Bear",
        "date": 1677696756293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Unammused",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "841",
        "rarity_score": 311.12112571758007,
        "rarity_level": "RARE"
    },
    "177": {
        "name": "Brawler Bears #177",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/177.png",
        "dna": "803afc94f43e018a09a799c683e9b51de014f5af",
        "edition": "177",
        "type": "Panda",
        "date": 1677696757507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Single Looking To Mingle",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 37,
        "maxHealth": 212,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "177",
        "rarity_score": 279.0612671697302,
        "rarity_level": "RARE"
    },
    "576": {
        "name": "Brawler Bears #576",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/576.png",
        "dna": "e3b31560afb87737b4292354fc7706d385323494",
        "edition": "576",
        "type": "Ice",
        "date": 1677696759041,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Ice Blades",
                "score": 40.61224489795919
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "Boujee Nomad",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Ninja",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 17,
        "maxHealth": 101,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "576",
        "rarity_score": 283.5830632782694,
        "rarity_level": "RARE"
    },
    "1422": {
        "name": "Brawler Bears #1422",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1422.png",
        "dna": "d3a578bbe9ec5d73b7e09bcd4dae2cb6f61385b8",
        "edition": "1422",
        "type": "Galactic Cyborg",
        "date": 1677696760021,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Locked",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 34,
        "maxHealth": 195,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "1422",
        "rarity_score": 256.5297419507053,
        "rarity_level": "COMMON"
    },
    "1232": {
        "name": "Brawler Bears #1232",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1232.png",
        "dna": "aa4791dae955158833c1cc9065be9cc5646290f8",
        "edition": "1232",
        "type": "Deep Martian Cyborg",
        "date": 1677696761052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Karate",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "War Lord",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1232",
        "rarity_score": 288.2257447276669,
        "rarity_level": "RARE"
    },
    "2009": {
        "name": "Brawler Bears #2009",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2009.png",
        "dna": "372f5131cde350259ead3af414afcdea5efcecba",
        "edition": "2009",
        "type": "Galaxy",
        "date": 1677696762272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Full Rage",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 43,
        "maxHealth": 246,
        "defaultAttack": { "name": "Mad Scientist", "damage": 73, "power": 11 },
        "id": "2009",
        "rarity_score": 185.23660878206607,
        "rarity_level": "COMMON"
    },
    "1562": {
        "name": "Brawler Bears #1562",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1562.png",
        "dna": "82c780d58468aece4533f4067dac5ac4ca3f1c51",
        "edition": "1562",
        "type": "Black Bear",
        "date": 1677696763061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Night Blade",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Wintertime Kimono",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Blood",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Night Blade", "damage": 73, "power": 11 },
        "id": "1562",
        "rarity_score": 262.1128214909406,
        "rarity_level": "COMMON"
    },
    "2283": {
        "name": "Brawler Bears #2283",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2283.png",
        "dna": "7fd14e39469cae6a41a830a1e8bc2c7129cd4313",
        "edition": "2283",
        "type": "Lava",
        "date": 1677696764456,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Sophistication",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 13,
        "maxHealth": 78,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "2283",
        "rarity_score": 329.4988088013301,
        "rarity_level": "RARE"
    },
    "1593": {
        "name": "Brawler Bears #1593",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1593.png",
        "dna": "6f538c62442915b07623ce2f339f2dca843f8c95",
        "edition": "1593",
        "type": "Brown Bear",
        "date": 1677696765319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Astro",
                "score": 132.66666666666666
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 59,
        "maxHealth": 339,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1593",
        "rarity_score": 257.79907285145435,
        "rarity_level": "COMMON"
    },
    "2098": {
        "name": "Brawler Bears #2098",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2098.png",
        "dna": "d7f3d045ab511cd699734e82fecc5327c5fdefbb",
        "edition": "2098",
        "type": "Acid Trip",
        "date": 1677696766321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 46,
        "maxHealth": 266,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2098",
        "rarity_score": 189.3006123950577,
        "rarity_level": "COMMON"
    },
    "453": {
        "name": "Brawler Bears #453",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/453.png",
        "dna": "c0f25d3bc73bd977b854643dfdbe6a59086d66e3",
        "edition": "453",
        "type": "Black Bear",
        "date": 1677696768050,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Spring Nights",
                "score": 64.19354838709677
            },
            {
                "trait_type": "Legs",
                "value": "Kimona",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Internally Blank",
                "score": 27.26027397260274
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            { "trait_type": "Head", "value": "Oni", "score": 64.19354838709677 }
        ],
        "level": 22,
        "maxHealth": 130,
        "defaultAttack": {
            "name": "Fire Mad Scientist",
            "damage": 71,
            "power": 11
        },
        "id": "453",
        "rarity_score": 284.1704741110537,
        "rarity_level": "RARE"
    },
    "223": {
        "name": "Brawler Bears #223",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/223.png",
        "dna": "8b674e218d63bc5ec4f382c67e7605f4de800729",
        "edition": "223",
        "type": "Black Bear",
        "date": 1677696769672,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Steel Batton",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Knighted Warrioir Class",
                "score": 73.70370370370371
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Dork",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 23,
        "maxHealth": 131,
        "defaultAttack": { "name": "Steel Batton", "damage": 73, "power": 11 },
        "id": "223",
        "rarity_score": 276.42409156365636,
        "rarity_level": "RARE"
    },
    "169": {
        "name": "Brawler Bears #169",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/169.png",
        "dna": "112f858df004d8d7b13524bf80f70659f62a8c8e",
        "edition": "169",
        "type": "Brown Bear",
        "date": 1677696771194,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Yellow Lightning",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Rogue Cyborg",
                "score": 76.53846153846153
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheesing",
                "score": 23.13953488372093
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 23,
        "maxHealth": 136,
        "defaultAttack": {
            "name": "Yellow Lightning",
            "damage": 73,
            "power": 11
        },
        "id": "169",
        "rarity_score": 300.75106900260437,
        "rarity_level": "RARE"
    },
    "440": {
        "name": "Brawler Bears #440",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/440.png",
        "dna": "e3bc6ed57f26246a2d31e00c594350c1f5c662eb",
        "edition": "440",
        "type": "Galactic Cyborg",
        "date": 1677696772507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Horizons",
                "score": 10.815217391304348
            },
            {
                "trait_type": "Back",
                "value": "Golf Bag",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Knight Of The Darkness",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 28,
        "maxHealth": 162,
        "defaultAttack": { "name": "Golf Bag", "damage": 73, "power": 11 },
        "id": "440",
        "rarity_score": 210.93807212278645,
        "rarity_level": "COMMON"
    },
    "583": {
        "name": "Brawler Bears #583",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/583.png",
        "dna": "a0cc4dcd9c47995d7534759dd4b575f5a516acca",
        "edition": "583",
        "type": "Galactic Cyborg",
        "date": 1677696773738,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Violet Rain Champ",
                "score": 56.857142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            {
                "trait_type": "Head",
                "value": "Bandana",
                "score": 56.857142857142854
            }
        ],
        "level": 27,
        "maxHealth": 157,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "583",
        "rarity_score": 235.29028366209218,
        "rarity_level": "COMMON"
    },
    "352": {
        "name": "Brawler Bears #352",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/352.png",
        "dna": "64713e4b4177f20f18dcc051804bcc58cef85f96",
        "edition": "352",
        "type": "Galaxy",
        "date": 1677696775043,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Electro Biker",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Nomadic Merchant",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 37,
        "maxHealth": 216,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "352",
        "rarity_score": 278.9502907903902,
        "rarity_level": "RARE"
    },
    "373": {
        "name": "Brawler Bears #373",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/373.png",
        "dna": "bcc10e2d6010b846f3f0080af617026c89b5b0d8",
        "edition": "373",
        "type": "Deep Martian Cyborg",
        "date": 1677696776396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Mad Scientist Of The Forest",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            { "trait_type": "Body", "value": "Black Ops", "score": 124.375 },
            {
                "trait_type": "Legs",
                "value": "Fire Mage",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "373",
        "rarity_score": 361.45180509907107,
        "rarity_level": "RARE"
    },
    "943": {
        "name": "Brawler Bears #943",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/943.png",
        "dna": "20ff66f1404ab36e0451098ee0d76d5cc1e39ce8",
        "edition": "943",
        "type": "Cheetah",
        "date": 1677696777820,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            { "trait_type": "Back", "value": "Fire Axe", "score": 62.1875 },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Rockstar", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Outlaw",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Eyes",
                "value": "Sus",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Down",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 13,
        "maxHealth": 79,
        "defaultAttack": { "name": "Fire Axe", "damage": 75, "power": 11 },
        "id": "943",
        "rarity_score": 328.02331545192453,
        "rarity_level": "RARE"
    },
    "539": {
        "name": "Brawler Bears #539",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/539.png",
        "dna": "f85db3a29563c2d19d28a928ab0dfb7129725206",
        "edition": "539",
        "type": "Deep Martian Cyborg",
        "date": 1677696779163,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Aqua Ring",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Cape",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Rogue Cyborg",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 37,
        "maxHealth": 215,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "539",
        "rarity_score": 279.21115846549833,
        "rarity_level": "RARE"
    },
    "1917": {
        "name": "Brawler Bears #1917",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1917.png",
        "dna": "00e25778c4d8eb56c93f519edd4323c5ee3eab4b",
        "edition": "1917",
        "type": "Brown Bear",
        "date": 1677696780672,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "Fishing Rod",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Maroon Excellency",
                "score": 165.83333333333334
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Cheeky",
                "score": 21.630434782608695
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 40,
        "maxHealth": 230,
        "defaultAttack": { "name": "Fishing Rod", "damage": 73, "power": 11 },
        "id": "1917",
        "rarity_score": 314.08999172997886,
        "rarity_level": "RARE"
    },
    "1464": {
        "name": "Brawler Bears #1464",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1464.png",
        "dna": "0a86440c43f08e8e0f099f15e5fcd65f09556245",
        "edition": "1464",
        "type": "Galactic Cyborg",
        "date": 1677696781552,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Aqua Gaurdian",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Shock",
                "score": 25.189873417721518
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 55,
        "maxHealth": 314,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1464",
        "rarity_score": 205.5072050932458,
        "rarity_level": "COMMON"
    },
    "987": {
        "name": "Brawler Bears #987",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/987.png",
        "dna": "2f2c806a629de68f43dbc3fa9ee4bd916d6de935",
        "edition": "987",
        "type": "Cheetah",
        "date": 1677696782381,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Professor Papi", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "General",
                "score": 53.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Pause",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Mouth",
                "value": "Cheerful",
                "score": 20.1010101010101
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 36,
        "maxHealth": 211,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "987",
        "rarity_score": 257.3923060613812,
        "rarity_level": "COMMON"
    },
    "1225": {
        "name": "Brawler Bears #1225",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1225.png",
        "dna": "8db14f2fb81468203797c535d6390a45d3d4d01b",
        "edition": "1225",
        "type": "Galactic Cyborg",
        "date": 1677696784073,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "Flossy",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 38,
        "maxHealth": 220,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1225",
        "rarity_score": 291.30268622314185,
        "rarity_level": "RARE"
    },
    "762": {
        "name": "Brawler Bears #762",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/762.png",
        "dna": "0ce518ac5874f50cf0ff2966e9021b90ff949bd4",
        "edition": "762",
        "type": "Black Bear",
        "date": 1677696785140,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Home Games",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            { "trait_type": "Eyes", "value": "Electric", "score": 24.875 },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Electric", "damage": 68, "power": 14 },
        "id": "762",
        "rarity_score": 267.66885944119565,
        "rarity_level": "COMMON"
    },
    "87": {
        "name": "Brawler Bears #87",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/87.png",
        "dna": "1cbd99daebdb3ec73070c1cd0bf825a7b4e88e45",
        "edition": "87",
        "type": "Galaxy",
        "date": 1677696785956,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Go Time",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Based",
                "score": 21.170212765957448
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 39,
        "maxHealth": 226,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "87",
        "rarity_score": 271.7521152541443,
        "rarity_level": "COMMON"
    },
    "1391": {
        "name": "Brawler Bears #1391",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1391.png",
        "dna": "8861b403f296b17eafbed9ccf02b6e1172c3e74c",
        "edition": "1391",
        "type": "Brown Bear",
        "date": 1677696786712,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Officer",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Gas Chamber",
                "score": 38.26923076923077
            }
        ],
        "level": 54,
        "maxHealth": 313,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "1391",
        "rarity_score": 171.27461482069594,
        "rarity_level": "COMMON"
    },
    "219": {
        "name": "Brawler Bears #219",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/219.png",
        "dna": "235dbbdae713a5890e094f37318a7c3d4a479cc1",
        "edition": "219",
        "type": "Brown Bear",
        "date": 1677696787627,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Super Star",
                "score": 110.55555555555556
            },
            {
                "trait_type": "Legs",
                "value": "Champ",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Snake",
                "score": 28.840579710144926
            },
            {
                "trait_type": "Mouth",
                "value": "Peppermint Bubble",
                "score": 22.873563218390803
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "219",
        "rarity_score": 278.9615722154026,
        "rarity_level": "RARE"
    },
    "793": {
        "name": "Brawler Bears #793",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/793.png",
        "dna": "a33a78471cdda101b668bd81908437d61b09196c",
        "edition": "793",
        "type": "Galaxy",
        "date": 1677696789414,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Emperor",
                "score": 94.76190476190476
            },
            { "trait_type": "Legs", "value": "Warrior", "score": 49.75 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 17,
        "maxHealth": 100,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "793",
        "rarity_score": 299.8047742312334,
        "rarity_level": "RARE"
    },
    "2171": {
        "name": "Brawler Bears #2171",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2171.png",
        "dna": "ce91496de284e85f9aaa21060f9020bfb4ceaa35",
        "edition": "2171",
        "type": "Galactic Cyborg",
        "date": 1677696790620,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Samurai Blade",
                "score": 52.36842105263158
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "How You Doing?",
                "score": 31.09375
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 26,
        "maxHealth": 151,
        "defaultAttack": { "name": "Samurai Blade", "damage": 74, "power": 11 },
        "id": "2171",
        "rarity_score": 255.611198214099,
        "rarity_level": "COMMON"
    },
    "757": {
        "name": "Brawler Bears #757",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/757.png",
        "dna": "7ce5e66e2f842623c9d2363750e063f13f4c8001",
        "edition": "757",
        "type": "Black Bear",
        "date": 1677696791447,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Aqua Polo",
                "score": 117.05882352941177
            },
            {
                "trait_type": "Legs",
                "value": "Steel Rogue",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 22,
        "maxHealth": 129,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "757",
        "rarity_score": 330.8936208189357,
        "rarity_level": "RARE"
    },
    "202": {
        "name": "Brawler Bears #202",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/202.png",
        "dna": "e975500a741e4f19e434b47f0753e7d681dcd5de",
        "edition": "202",
        "type": "Panda",
        "date": 1677696793304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ancient",
                "score": 11.775147928994082
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Yellow Lightning",
                "score": 82.91666666666667
            },
            {
                "trait_type": "Legs",
                "value": "Black Ops",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 36,
        "maxHealth": 209,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "202",
        "rarity_score": 276.7399624585401,
        "rarity_level": "RARE"
    },
    "782": {
        "name": "Brawler Bears #782",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/782.png",
        "dna": "c2d643743556fcfcdc86b1ce826c4e780f5466b8",
        "edition": "782",
        "type": "Black Bear",
        "date": 1677696794538,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Formal",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Fragile",
                "score": 26.18421052631579
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 43,
        "maxHealth": 250,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "782",
        "rarity_score": 268.2188865402692,
        "rarity_level": "COMMON"
    },
    "375": {
        "name": "Brawler Bears #375",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/375.png",
        "dna": "9656601eb1ecc4091af7beb2f2c754ba4b0d299e",
        "edition": "375",
        "type": "Brown Bear",
        "date": 1677696795484,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Anchor Axe",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 39,
        "maxHealth": 224,
        "defaultAttack": { "name": "Anchor Axe", "damage": 73, "power": 11 },
        "id": "375",
        "rarity_score": 242.48872957203125,
        "rarity_level": "COMMON"
    },
    "2101": {
        "name": "Brawler Bears #2101",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2101.png",
        "dna": "f372ac9acf5021d64f269b7251d37d82dc9a2ce0",
        "edition": "2101",
        "type": "Cheetah",
        "date": 1677696796269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            { "trait_type": "Body", "value": "Floral Track", "score": 79.6 },
            {
                "trait_type": "Legs",
                "value": "Cowboy",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Sphynx", "score": 49.75 }
        ],
        "level": 35,
        "maxHealth": 201,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "2101",
        "rarity_score": 278.0558538365217,
        "rarity_level": "RARE"
    },
    "1768": {
        "name": "Brawler Bears #1768",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1768.png",
        "dna": "b01273f8f187cbb41e4b4872a0ee03e7a574d162",
        "edition": "1768",
        "type": "Acid Trip",
        "date": 1677696797204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Violet Strength",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            {
                "trait_type": "Body",
                "value": "Ser",
                "score": 66.33333333333333
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Dialed In",
                "score": 29.70149253731343
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Full Ai",
                "score": 52.36842105263158
            }
        ],
        "level": 51,
        "maxHealth": 294,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1768",
        "rarity_score": 208.77942325688417,
        "rarity_level": "COMMON"
    },
    "1676": {
        "name": "Brawler Bears #1676",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1676.png",
        "dna": "6b38dd8165f4ceb06b857bfdd0fa8366ff0f25b5",
        "edition": "1676",
        "type": "Deep Martian Cyborg",
        "date": 1677696798342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength K",
                "score": 12.594936708860759
            },
            {
                "trait_type": "Back",
                "value": "Fox Spirit Animal",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Cosmic Gaurdian",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Grrrr",
                "score": 23.97590361445783
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 16,
        "maxHealth": 95,
        "defaultAttack": {
            "name": "Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1676",
        "rarity_score": 303.9017279512448,
        "rarity_level": "RARE"
    },
    "832": {
        "name": "Brawler Bears #832",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/832.png",
        "dna": "15d165b6d67c563f42e0d164d87a064f12e743eb",
        "edition": "832",
        "type": "Cheetah",
        "date": 1677696799385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Cheetah",
                "score": 17.92792792792793
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Khakis",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            { "trait_type": "Mouth", "value": "Heh", "score": 24.875 },
            { "trait_type": "Head", "value": "Astro", "score": 62.1875 }
        ],
        "level": 45,
        "maxHealth": 262,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "832",
        "rarity_score": 235.80381494897242,
        "rarity_level": "COMMON"
    },
    "1598": {
        "name": "Brawler Bears #1598",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1598.png",
        "dna": "5ef2d6d25d12f3ca5b33fff2582f63cc4028af63",
        "edition": "1598",
        "type": "Panda",
        "date": 1677696800463,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Night Light",
                "score": 46.27906976744186
            },
            {
                "trait_type": "Base",
                "value": "Panda",
                "score": 11.845238095238095
            },
            {
                "trait_type": "Body",
                "value": "Ice King",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Spartan",
                "score": 55.27777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Ouch",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Cyborg",
                "score": 51.02564102564103
            }
        ],
        "level": 16,
        "maxHealth": 91,
        "defaultAttack": { "name": "Night Light", "damage": 73, "power": 11 },
        "id": "1598",
        "rarity_score": 330.0673621853783,
        "rarity_level": "RARE"
    },
    "1276": {
        "name": "Brawler Bears #1276",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1276.png",
        "dna": "f80b1bb09afaa4201b63167cab48a9a6fd15171b",
        "edition": "1276",
        "type": "Galactic Cyborg",
        "date": 1677696802413,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Rocky Cascades",
                "score": 12.838709677419354
            },
            {
                "trait_type": "Back",
                "value": "Blue Lightning",
                "score": 58.529411764705884
            },
            {
                "trait_type": "Base",
                "value": "Galactic Cyborg",
                "score": 8.68995633187773
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Red Electric",
                "score": 24.567901234567902
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 43,
        "maxHealth": 247,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1276",
        "rarity_score": 179.4484171651439,
        "rarity_level": "COMMON"
    },
    "1685": {
        "name": "Brawler Bears #1685",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1685.png",
        "dna": "dd9a20926f44f95ee9935eec088a571b6438e50c",
        "edition": "1685",
        "type": "Lava",
        "date": 1677696803774,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "White Waters",
                "score": 11.179775280898877
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Nomadic Merchant",
                "score": 86.52173913043478
            },
            {
                "trait_type": "Legs",
                "value": "Umpire",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Tears",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            { "trait_type": "Head", "value": "Aqua Davidson", "score": 49.75 }
        ],
        "level": 35,
        "maxHealth": 204,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1685",
        "rarity_score": 263.45146561325885,
        "rarity_level": "COMMON"
    },
    "1239": {
        "name": "Brawler Bears #1239",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1239.png",
        "dna": "08190fb59bd95d9c7ff534011af86c00faa88310",
        "edition": "1239",
        "type": "Brown Bear",
        "date": 1677696804653,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Black Axes",
                "score": 36.851851851851855
            },
            {
                "trait_type": "Base",
                "value": "Brown Bear",
                "score": 4.225053078556264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            { "trait_type": "Legs", "value": "Golden Warrior", "score": 79.6 },
            {
                "trait_type": "Eyes",
                "value": "Satisfied",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Big Baby",
                "score": 21.397849462365592
            },
            {
                "trait_type": "Head",
                "value": "Knight Cap",
                "score": 52.36842105263158
            }
        ],
        "level": 34,
        "maxHealth": 194,
        "defaultAttack": {
            "name": "Dual Black Axes",
            "damage": 71,
            "power": 11
        },
        "id": "1239",
        "rarity_score": 236.37633775369173,
        "rarity_level": "COMMON"
    },
    "1120": {
        "name": "Brawler Bears #1120",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1120.png",
        "dna": "3294d9f618cdb9c528103ccc823c9505b8e80109",
        "edition": "1120",
        "type": "Galaxy",
        "date": 1677696805992,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "Dual Fox Spirit Animal",
                "score": 44.22222222222222
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            {
                "trait_type": "Body",
                "value": "Officer",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Tease",
                "score": 22.613636363636363
            },
            {
                "trait_type": "Head",
                "value": "Yellow Lightning",
                "score": 44.22222222222222
            }
        ],
        "level": 33,
        "maxHealth": 189,
        "defaultAttack": {
            "name": "Dual Fox Spirit Animal",
            "damage": 73,
            "power": 11
        },
        "id": "1120",
        "rarity_score": 266.0087296273836,
        "rarity_level": "COMMON"
    },
    "438": {
        "name": "Brawler Bears #438",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/438.png",
        "dna": "1d39841bbaa08aa39e68bdb689ada9b50e59a68e",
        "edition": "438",
        "type": "Deep Martian Cyborg",
        "date": 1677696806993,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Deep Waters",
                "score": 12.515723270440251
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Deep Martian Cyborg",
                "score": 9.342723004694836
            },
            {
                "trait_type": "Body",
                "value": "Saucy Prodigy",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "None",
                "score": 2.9136163982430454
            },
            {
                "trait_type": "Eyes",
                "value": "Firey Snake",
                "score": 30.615384615384617
            },
            {
                "trait_type": "Mouth",
                "value": "Vengence",
                "score": 20.729166666666668
            },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 54,
        "maxHealth": 309,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "438",
        "rarity_score": 215.47262488345064,
        "rarity_level": "COMMON"
    },
    "1526": {
        "name": "Brawler Bears #1526",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1526.png",
        "dna": "d789541bdbea40192b5adaa14360be88ae97bcac",
        "edition": "1526",
        "type": "Ice",
        "date": 1677696808091,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength A",
                "score": 14.214285714285714
            },
            {
                "trait_type": "Back",
                "value": "Butterfly Net",
                "score": 45.22727272727273
            },
            { "trait_type": "Base", "value": "Ice", "score": 10.0 },
            {
                "trait_type": "Body",
                "value": "LumberJack",
                "score": 104.73684210526316
            },
            {
                "trait_type": "Legs",
                "value": "Jockey",
                "score": 47.38095238095238
            },
            {
                "trait_type": "Eyes",
                "value": "Smittened",
                "score": 26.533333333333335
            },
            {
                "trait_type": "Mouth",
                "value": "Pout",
                "score": 27.63888888888889
            },
            { "trait_type": "Head", "value": "Slay", "score": 62.1875 }
        ],
        "level": 15,
        "maxHealth": 89,
        "defaultAttack": { "name": "Ice", "damage": 77, "power": 11 },
        "id": "1526",
        "rarity_score": 337.9190751499962,
        "rarity_level": "RARE"
    },
    "2141": {
        "name": "Brawler Bears #2141",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2141.png",
        "dna": "0ac5843f4d54e3d9eed7bdd0f52a80e6f54344a6",
        "edition": "2141",
        "type": "Black Bear",
        "date": 1677696809224,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Ring Of Fire",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Violet Spirit Lion",
                "score": 41.458333333333336
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Ninja Instructor",
                "score": 60.303030303030305
            },
            {
                "trait_type": "Eyes",
                "value": "Ocean Eyes",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Astro Of The Night",
                "score": 55.27777777777778
            }
        ],
        "level": 32,
        "maxHealth": 184,
        "defaultAttack": {
            "name": "Violet Spirit Lion",
            "damage": 72,
            "power": 11
        },
        "id": "2141",
        "rarity_score": 239.27035652534343,
        "rarity_level": "COMMON"
    },
    "1015": {
        "name": "Brawler Bears #1015",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1015.png",
        "dna": "cb3eefa0524c96a23eeb17353ded0d318b9f38d4",
        "edition": "1015",
        "type": "Lava",
        "date": 1677696810135,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Fire Mad Scientist",
                "score": 34.310344827586206
            },
            {
                "trait_type": "Base",
                "value": "Lava",
                "score": 17.767857142857142
            },
            {
                "trait_type": "Body",
                "value": "Brisk Mornings",
                "score": 90.45454545454545
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "Forrest Eyes",
                "score": 23.41176470588235
            },
            {
                "trait_type": "Mouth",
                "value": "Bring It",
                "score": 27.63888888888889
            },
            {
                "trait_type": "Head",
                "value": "Knight Of Darkness",
                "score": 51.02564102564103
            }
        ],
        "level": 15,
        "maxHealth": 86,
        "defaultAttack": { "name": "Lava", "damage": 78, "power": 11 },
        "id": "1015",
        "rarity_score": 304.4524455930729,
        "rarity_level": "RARE"
    },
    "2284": {
        "name": "Brawler Bears #2284",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2284.png",
        "dna": "a55252e551c1b0351aaabdf3a1f908ebbcfad370",
        "edition": "2284",
        "type": "Black Bear",
        "date": 1677696811039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Might C",
                "score": 11.306818181818182
            },
            {
                "trait_type": "Back",
                "value": "Captain",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Heating Up",
                "score": 66.33333333333333
            },
            { "trait_type": "Legs", "value": "Aqua Mage", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Rage",
                "score": 22.11111111111111
            },
            {
                "trait_type": "Mouth",
                "value": "Game Time",
                "score": 20.515463917525775
            },
            { "trait_type": "Head", "value": "Knight", "score": 62.1875 }
        ],
        "level": 23,
        "maxHealth": 134,
        "defaultAttack": { "name": "Captain", "damage": 72, "power": 11 },
        "id": "2284",
        "rarity_score": 291.51518168845956,
        "rarity_level": "RARE"
    },
    "1521": {
        "name": "Brawler Bears #1521",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1521.png",
        "dna": "6270fc9115a67eac3f09fe0eaba61a82c345a4db",
        "edition": "1521",
        "type": "Acid Trip",
        "date": 1677696812078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Strength J",
                "score": 10.0
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Acid Trip",
                "score": 13.355704697986576
            },
            { "trait_type": "Body", "value": "Papi", "score": 99.5 },
            {
                "trait_type": "Legs",
                "value": "Fire Fighter",
                "score": 45.22727272727273
            },
            {
                "trait_type": "Eyes",
                "value": "Opps",
                "score": 36.18181818181818
            },
            {
                "trait_type": "Mouth",
                "value": "Joyful",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Head",
                "value": "Assassin",
                "score": 42.340425531914896
            }
        ],
        "level": 36,
        "maxHealth": 210,
        "defaultAttack": { "name": "Acid Trip", "damage": 77, "power": 11 },
        "id": "1521",
        "rarity_score": 275.04311229950974,
        "rarity_level": "RARE"
    },
    "1547": {
        "name": "Brawler Bears #1547",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/1547.png",
        "dna": "2ab7f1a1dd76dc0ba69c4779fce4c159e497ae8a",
        "edition": "1547",
        "type": "Black Bear",
        "date": 1677696813834,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Galactic Twin Dragons",
                "score": 12.756410256410257
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "None",
                "score": 4.693396226415095
            },
            {
                "trait_type": "Legs",
                "value": "Money Track",
                "score": 48.53658536585366
            },
            {
                "trait_type": "Eyes",
                "value": "lifeless",
                "score": 26.533333333333335
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Black Ops",
                "score": 42.340425531914896
            }
        ],
        "level": 53,
        "maxHealth": 306,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "1547",
        "rarity_score": 166.52186096088508,
        "rarity_level": "COMMON"
    },
    "2015": {
        "name": "Brawler Bears #2015",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/2015.png",
        "dna": "d1281e3267ce64ff9069caf7ddfc95c81a5dd507",
        "edition": "2015",
        "type": "Galaxy",
        "date": 1677696814783,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "Dual Night Light",
                "score": 42.340425531914896
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 8.577586206896552
            },
            { "trait_type": "Body", "value": "Prodigy", "score": 99.5 },
            { "trait_type": "Legs", "value": "Karate", "score": 62.1875 },
            {
                "trait_type": "Eyes",
                "value": "Aqua Snake",
                "score": 35.535714285714285
            },
            { "trait_type": "Mouth", "value": "Bubble Yum", "score": 24.875 },
            {
                "trait_type": "Head",
                "value": "Cherry Assassin",
                "score": 46.27906976744186
            }
        ],
        "level": 17,
        "maxHealth": 98,
        "defaultAttack": { "name": "Bubble Yum", "damage": 68, "power": 14 },
        "id": "2015",
        "rarity_score": 330.35085134752313,
        "rarity_level": "RARE"
    },
    "657": {
        "name": "Brawler Bears #657",
        "description": "Brawler Bears",
        "image": "https://arkade-prod.s3.amazonaws.com/brawler-bears/657.png",
        "dna": "51394ea6e4c0d61b132f8910bf53a66a043d81d2",
        "edition": "657",
        "type": "Black Bear",
        "date": 1677696815565,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Tears Of The Sun Magnified",
                "score": 11.055555555555555
            },
            {
                "trait_type": "Back",
                "value": "None",
                "score": 2.2536806342015856
            },
            {
                "trait_type": "Base",
                "value": "Black Bear",
                "score": 4.533029612756264
            },
            {
                "trait_type": "Body",
                "value": "Dragon Warrior",
                "score": 94.76190476190476
            },
            {
                "trait_type": "Legs",
                "value": "Aqua War Lord",
                "score": 43.26086956521739
            },
            {
                "trait_type": "Eyes",
                "value": "Galaxy Eyes",
                "score": 26.18421052631579
            },
            {
                "trait_type": "Mouth",
                "value": "Pretty Boi",
                "score": 23.41176470588235
            },
            { "trait_type": "Head", "value": "Jewel Dealer", "score": 49.75 }
        ],
        "level": 44,
        "maxHealth": 252,
        "defaultAttack": { "name": "Tackle", "damage": 25, "power": 20 },
        "id": "657",
        "rarity_score": 255.21101536183366,
        "rarity_level": "COMMON"
    }
}


export default arkade_brawler_bears_metadata
import { Box, Flex, Skeleton } from "@chakra-ui/react";
import React from "react";

const LoadingCard = () => {
    return (
        <Box
            style={{ cursor: "pointer" }}
            className="latestDesign"
            maxW={["100%", "220px"]}
        >
            <Skeleton
                height="220px"
                align="center"
                justify="center"
                rounded="8px"

                // bgColor="#010001"
            >
                {/* <Box
                    src={dummyImage}
                    alt="nftCard"
                    width={"100%"}
                    height="296px"
                    className="cardImage"
                    bgColor={"#757575"}
                    rounded="0.5rem"
                >
                    {" "}
                </Box> */}
                {/* <Skeleton height="296px"></Skeleton> */}
            </Skeleton>
            <Box py="7px">
                <Skeleton h="23px" rounded="8px" w="100%">
                    {" "}
                </Skeleton>

                <Flex gap="1rem" mt="14px">
                    <Skeleton h="23px" rounded="8px" w="100%">
                        {" "}
                    </Skeleton>

                    <Skeleton h="23px" rounded="8px" w="100%">
                        {" "}
                    </Skeleton>
                </Flex>
            </Box>
        </Box>
    );
};

export default LoadingCard;

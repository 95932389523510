import { useContext } from "react";
import { PactContext } from "./PactContextProvider";
import { executePactContract } from "./PactUtils";
import { DEFAULT_GAS_LIMIT } from "../utils/Constants";

export async function queryBlockchainForData(pactContext, pactCode, chainId, node = null) {
    let parameters = {
        chainId: chainId,
        metaData: pactContext.defaultMeta,
        networkUrl: pactContext.networkUrl,
        readFromContract: pactContext.readFromContract
    };

    if (pactContext?.account?.account) {
        parameters["account"] = pactContext.account.account;
    }

    let result = await executePactContract(parameters, pactCode);


    return result;
}

function useQueryBlockchainForData() {
    const { account, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);

    // return async (pactCode, chainId, callback = null, gasLimit = DEFAULT_GAS_LIMIT, platform = null) => {
    return async (pactCode, chainId, callback = null, gasLimit = 1000000, platform = null) => {
        let parameters = {
            chainId: chainId,
            metaData: defaultMeta,
            networkUrl: networkUrl,
            readFromContract: readFromContract
        };

        if (account?.account) {
            parameters["account"] = account.account;
        }

        const result = await executePactContract(parameters, pactCode, null, gasLimit, platform);

        return result;
    }
}

export {
    useQueryBlockchainForData
}
import { Link } from "react-router-dom";
import { FaDiscord, FaLink, FaTwitter } from "react-icons/fa";
import { Flex } from "@chakra-ui/layout";

const SocialLinkGroup = ({ projectConfig }) => {
    return (
        <Flex className="mintSocials">
            <Link
                to={
                    projectConfig &&
                    projectConfig["socials"]["twitter"]
                }
                target="_blank"
            >
                <FaTwitter />
            </Link>

            <Link
                to={
                    projectConfig &&
                    projectConfig["socials"]["discord"]
                }
                target="_blank"
            >
                <FaDiscord />
            </Link>

            <Link
                to={
                    projectConfig &&
                    projectConfig["socials"]["website"]
                }
                target="_blank"
            >
                <FaLink />
            </Link>
        </Flex>
    )
}


export {
    SocialLinkGroup
}
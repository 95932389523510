import Pact from "pact-lang-api";
import { toast } from "react-toastify";
import { createEzBuyCommandFromExec } from "../utils/transformers";
import { fetchAccountDetailsWithChainId } from "../pact/PactUtils";
import { DEFAULT_GAS_LIMIT, DEFAULT_GAS_PRICE } from "../utils/Constants";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";

function useMarmaladeV2Provider(collectionConfig, ezBuy, pactContext) {

    async function transfer(nftData, receiver, transactionCompleteCallback = null) {
        let chainId = nftData["chain-id"];
        let pactCode = `(marmalade-v2.ledger.transfer-create "${nftData["token-id"]}" "${pactContext.account.account}" "${nftData["receiver"]}" (read-keyset "user-ks") 1.0)`;

        const receiverAccountDetails = await fetchAccountDetailsWithChainId({
            account: receiver,
            chainId: nftData["chain-id"],
            gasPrice: DEFAULT_GAS_PRICE,
            gasLimit: DEFAULT_GAS_LIMIT,
        });

        if (!receiverAccountDetails) {
            toast.error(`Transfer failed, please ensure some KDA exists on chain ${nftData["chain-id"]}`);
            return;
        }

        const envData = {
            "user-ks": receiverAccountDetails["guard"],
            account: nftData["receiver"],
            "Marketplace Facilitator": "Isoko LLC",
            classification: "transfer"
        };

        let caps = [];
        // caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "Transfer",
                "transfer",
                "marmalade-v2.ledger.TRANSFER",
                [
                    nftData["nft-id-on-chain"],
                    pactContext.account.account,
                    nftData["receiver"],
                    1.0,
                ]
            )
        );

        const txData = createEzBuyCommandFromExec(
            pactCode,
            nftData["chain-id"],
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            envData,
            caps,
            "exec",
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            `Transferring ${nftData["nft-id"]} to ${receiver}`,
            null, //TODO: to be removed as a param
            transactionCompleteCallback
        );

        ezBuy.shouldExecuteQuickBuy(txData, 0, chainId, transactionCompleteCallback);
    }

    async function list(nftData, lister, transactionCompleteCallback = null) {
        let chainId = nftData["chain-id"];
        let pactCode = `(marmalade-v2.ledger.offer "${nftData["nft-id-on-chain"]}" "${lister}" 1.0)`;

        const listingAccountDetails = await fetchAccountDetailsWithChainId({
            account: lister,
            chainId: chainId,
            gasPrice: DEFAULT_GAS_PRICE,
            gasLimit: DEFAULT_GAS_LIMIT,
        });

        if (!listingAccountDetails) {
            toast.error("Failed to list your NFT, please check your account");
            return;
        }

        let caps = [];
        let envData = {
            quote: {
                "price": parseFloat(nftData["price"]),
                "recipient": lister,
                "recipient-guard": listingAccountDetails["guard"]
            },
            "Marketplace Facilitator": "Isoko LLC",
        };

        console.log(envData);
        caps.push(
            Pact.lang.mkCap(
                "DEBIT",
                "Marmalade Debit",
                `marmalade.ledger.DEBIT`,
                [nftData["nft-id-on-chain"], nftData["current-owner"]]
            )
        );
        caps.push(
            Pact.lang.mkCap(
                "OFFER",
                "Marmalade Offer",
                `marmalade.ledger.OFFER`,
                [
                    nftData["nft-id-on-chain"],
                    nftData["current-owner"],
                    1.0,
                    { int: nftData["timeout"] },
                ]
            )
        );

        const txData = createEzBuyCommandFromExec(
            pactCode,
            nftData["chain-id"],
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            envData,
            caps,
            "exec",
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            `Listing ${nftData["nft-id"]} for ${nftData["price"]}`,
            null,
            transactionCompleteCallback
        );

        ezBuy.shouldExecuteQuickBuy(txData, 0, chainId, transactionCompleteCallback);
    }

    return {
        transfer
    }
}

export {
    useMarmaladeV2Provider
}

import {
    BUTTER_TYPE,
    SGK_WEAPONS_NAME_KEY,
    KADCARS_PROJECT_NAME_KEY,
    BATTLE_HEROES_PROJECT_NAME_KEY,
    BOXING_BADGER_PROJECT_NAME_KEY,
    ISOKO_TEST_COLLECTION_NAME_KEY,
    ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY,
    ISOKO_TEST_STACKED_COLLECTION_NAME_KEY,
    SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
    MARMALADE_TYPE,
    STACKED_TYPE,
    MARMALADE_V2_TYPE,
} from "../utils/Constants";
import { useKadcarsProvider } from "./KadcarsProvider";
import { useBoxingBadgerProvider } from './BoxingBadgerProvider';
import { useSecretGardenOfKadenaProvider } from "./SecretGardenOfKadenaProvider";
import { useIslandsOfDamayaProvider } from "./IslandsOfDamayaProvider";
import { useBattleHeroesProvider } from "./BattleHeroesProvider";
import { useIsokoTestCollectionProvider } from "./IsokoTestCollectionProvider";
import { useIsokoStackedCollectionProvider } from "./IsokoTestStackedCollectionProvider";
import { useSecretGardenOfKadenaWeaponsProvider } from "./SecretGardenOfKadenaWeapons";
import { useButterProvider } from "./ButterCollectionProvider";
import useMarmaladeCollectionProvider from "./MarmaladeCollectionProvider";
import useMarmaladeV2CollectionProvider from "./MarmaladeV2CollectionProvider";
import useStackedCollectionProvider from "./StackedCollectionProvider";

//TODO: constant 
function useCollectionProviderFactory(collectionName, projectName, collectionConfig) {
    const MarmaladeCollectionProvider = useMarmaladeCollectionProvider(collectionConfig);
    const MarmaladeV2CollectionProvider = useMarmaladeV2CollectionProvider(collectionConfig);
    const StackedCollectionProvider = useStackedCollectionProvider(collectionConfig)
    const ButterCollectionProvider = useButterProvider(collectionConfig);

    if (collectionConfig["policy-info"]["standard"] === BUTTER_TYPE) {
        return ButterCollectionProvider;
    } else if (collectionConfig["policy-info"]["standard"] === MARMALADE_TYPE) {
        return MarmaladeCollectionProvider;
    } else if (collectionConfig["policy-info"]["standard"] === MARMALADE_V2_TYPE) {
        return MarmaladeV2CollectionProvider;
    } else if (collectionConfig["policy-info"]["standard"] === STACKED_TYPE) {
        return StackedCollectionProvider;
    }
}

export {
    useCollectionProviderFactory
}
import { buildContCmd, buildExecCommand, getPactDecimal } from "../pact/PactUtils";
import { DEFAULT_GAS_LIMIT, DEFAULT_GAS_LIMIT_X_CHAIN_EXEC, DEFAULT_GAS_PRICE, DEFAULT_GAS_PRICE_ISOKO_GAS_STATION, DEFAULT_GAS_PRICE_X_CHAIN_EXEC, GAS_STATION, ISOKO_GAS_STATION, ISOKO_NAMESPACE, ISOKO_QUICKBUY_FEE_COLLECTOR_ADDRESS, MAINNET_NETWORK_ID, NETWORK_ID, QUICKBUY_PROVIDER_TX_FEE } from "../utils/Constants";
import Pact from "pact-lang-api";

function addEzBuyCapabilityToBusinessTransaction(account, businessTxDetails, targetChainId) {
    let txFee = parseFloat(QUICKBUY_PROVIDER_TX_FEE.toFixed(8))
    let txFeePact = getPactDecimal(txFee);
    let pactCodeToTransferFunds = `(coin.transfer "${account.account}" "${ISOKO_QUICKBUY_FEE_COLLECTOR_ADDRESS}" ${txFee})`;
    let transferCapability = Pact.lang.mkCap(
        "Transfer Capability",
        "Transfer Capability for Quickbuy Provider",
        "coin.TRANSFER",
        [account.account, ISOKO_QUICKBUY_FEE_COLLECTOR_ADDRESS, txFee]
    );

    if (businessTxDetails["cmdType"] === "exec") {
        addEzBuyToExecBusinessTransaction(account, businessTxDetails, transferCapability, pactCodeToTransferFunds);
    } else {
        addEzBuyToContBusinessTransaction(account, businessTxDetails, transferCapability, pactCodeToTransferFunds, targetChainId);
    }
}

function addEzBuyToExecBusinessTransaction(account, businessTxDetails, transferCapability, pactCodeToTransferFunds) {
    //Modify the exec business command
    businessTxDetails.bzCommand.cmd.signers[0].clist.push(transferCapability.cap);
    businessTxDetails.bzCommand.cmd.payload.exec.code += pactCodeToTransferFunds;
}

function addEzBuyToContBusinessTransaction(account, businessTxDetails, transferCapability, pactCodeToTransferFunds, targetChainId) {
    //Create exec transaction for the ezbuy transaction
    let execCommand = buildExecCommand(
        pactCodeToTransferFunds,
        targetChainId,
        account.account,
        account.accountGuard.keys[0],
        {},
        [transferCapability],
        DEFAULT_GAS_PRICE,
        DEFAULT_GAS_LIMIT
    );

    execCommand["payload"]["exec"]["code"] = pactCodeToTransferFunds;

    businessTxDetails["ezBuyCommand"] = execCommand
}

function buildListOfExecCmdsForXChainFundTransfer(account, chainsWithFunds, targetChain) {
    let cmdList = [];

    for (const [chain, amount] of Object.entries(chainsWithFunds)) {
        let pactCode = `(coin.transfer-crosschain "${account.account}" "${account.account}" 
                (read-keyset "user-ks") "${targetChain}" ${parseFloat(amount).toFixed(12)})`

        let envData = {
            "user-ks": account.accountGuard,
            account: account.account,
        };

        let clist = [
            Pact.lang.mkCap(
                "Transfer cap",
                "transfer",
                "coin.TRANSFER_XCHAIN",
                [
                    account.account,
                    account.account,
                    { decimal: parseFloat(amount).toFixed(12) },
                    targetChain,
                ]
            ),
        ];

        let execCommand = buildExecCommand(
            pactCode,
            chain,
            GAS_STATION,
            account.accountGuard.keys[0],
            envData,
            clist,
            DEFAULT_GAS_PRICE_X_CHAIN_EXEC,
            DEFAULT_GAS_LIMIT_X_CHAIN_EXEC
        );
        execCommand["payload"]["exec"]["code"] = pactCode;

        let cmdPubKeyObj = {
            cmd: execCommand,
            pubKey: account.accountGuard.keys[0],
        };

        cmdList.push(cmdPubKeyObj);
    }

    return cmdList;
}

function addGasStationCapability(account, businessTxDetails) {
    let tempContCmd = null;
    let businessCmd = null;
    let caps = null;
    let signers = null;
    let sender = ISOKO_GAS_STATION;
    let costOfGas = 0.0;
    let gasStationCap = Pact.lang.mkCap(
        "Gas Station Capability",
        "Isoko Gas Station to pay for tx gas",
        `${NETWORK_ID === MAINNET_NETWORK_ID
            ? ISOKO_NAMESPACE + ".isoko-gas-station.GAS_PAYER"
            : "free.isoko-gas-station.GAS_PAYER"
        }`,
        [account.account, { int: 100000 }, 0.1]
    );
    
    

    if (businessTxDetails["cmdType"] === "exec") {
        caps = businessTxDetails["cmd"].caps;
        businessTxDetails["cmd"].caps.push(gasStationCap);
        businessTxDetails["cmd"].sender = ISOKO_GAS_STATION;
        businessTxDetails["cmd"].gasPrice = DEFAULT_GAS_PRICE_ISOKO_GAS_STATION;
        signers = [
            {
                publicKey: account.accountGuard.keys[0],
                clist: caps.map((cap) => {
                    return cap.cap;
                }),
            },
        ];
        signers[0].clist.push(gasStationCap.cap);
        businessCmd = businessTxDetails["cmd"]
    } else {
        tempContCmd = JSON.parse(businessTxDetails["cmd"]["cmds"][0]["cmd"]);
        console.log(tempContCmd["signers"][0]["clist"]);

        let clist = [
            {
                publicKey: account.accountGuard.keys[0],
                clist: tempContCmd["signers"][0]["clist"].map((cap) => {
                    return cap;
                }),
            },
        ];
        clist[0]["clist"].push(gasStationCap.cap)
        businessCmd = buildContCmd(
            tempContCmd["payload"]["cont"]["pactId"],
            ISOKO_GAS_STATION,
            tempContCmd["meta"]["chainId"],
            tempContCmd["payload"]["cont"]["step"],
            tempContCmd["payload"]["cont"]["rollback"],
            tempContCmd["payload"]["cont"]["proof"],
            tempContCmd["payload"]["cont"]["data"],
            clist,
            DEFAULT_GAS_PRICE_ISOKO_GAS_STATION,
            tempContCmd["meta"]["gasLimit"]
        );
        businessCmd["cmds"][0]["pubKey"] = account.accountGuard.keys[0];
        console.log(businessCmd)
    }

    let modifiedObject = {
        newCmd: businessCmd,
        signers: signers
    }

    return modifiedObject;
}

function getIsInsufficientBalanceErrorWithSufficientBalance(res) {
    if (res?.result?.error?.message === "Insufficient funds") {
        //TODO: CRITICAL : Check user balance is > amount to send. Currently not done because this requires
        // another fetch local call, and we are being throttled
        return true;
    }
}

export {
    addEzBuyCapabilityToBusinessTransaction,
    buildListOfExecCmdsForXChainFundTransfer,
    addGasStationCapability,
    getIsInsufficientBalanceErrorWithSufficientBalance
}
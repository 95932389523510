import React from 'react'
import { Box, SimpleGrid, Text } from '@chakra-ui/react'
import DummyCard from '../Cards/DummyCard'
import { SECRET_GARDEN_OF_KADENA } from '../utils/Constants';
import LaunchPadCard from '../Cards/LaunchPadCard';


const ComingSoon = ({ projects, cardClickCallback }) => {

    return (
        <Box pb="4.38rem">
            <Box>
                <Text fontSize={["24px", null, "36px"]} fontWeight={"900"} >
                    Upcoming Mints
                </Text>
            </Box>
            {/* Cards */}

            <SimpleGrid columns={[1, 2, 3, 4]} mt="30px" className="upcomingCards">
                {
                    projects &&
                    Object.entries(projects).map(([projectName, projectConfig]) => {
                        return (
                            <LaunchPadCard
                                key={projectName}
                                clickable={true}
                                type={"startingSoon"}
                                projectConfig={projectConfig}
                                cardCallback={() => cardClickCallback(projectConfig)}
                            />
                        );
                    })
                }
            </SimpleGrid>

        </Box>
    )
}

export default ComingSoon
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Divider } from "@chakra-ui/react";
import NavBar from "../CommonComponents/NavBar";
import ScreenSize from "../layouts/ScreenSize";
import Ended from "./Ended";
import Footer from "../CommonComponents/Footer";
import Hero from "./Hero";
import LiveMint from "./LiveMint";
import Upcoming from "./Upcoming";
import { useInterval } from '../utils/utils';
import { ATRIUM, ISOKO, ISOKO_TEST_COLLECTION_NAME_KEY, ISOKO_TEST_STACKED_COLLECTION_NAME_KEY, KADCARS_PROJECT_NAME_KEY, LONG_POLLING_TIMER, PLATFORM, SGK_WEAPONS_NAME_KEY } from '../utils/Constants';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { IsokoCollectionsContext } from '../IsokoContextProvider/IsokoCollectionsContextProvider';

const LAUNCHPAD_POLLING_TIMER = 10000;

//TODO: create context provider to manage projects on launchpad
const LaunchPad = () => {
    const [liveProjects, setLiveProjects] = useState([]);
    const [upcomingProjects, setUpcomingProjects] = useState([]);
    const [endedProjects, setEndedProjects] = useState([]);
    const isokoCollectionsContext = useContext(IsokoCollectionsContext);
    const [collections] = useLocalStorage("collections", JSON.parse(localStorage.getItem("collections")));

    useInterval(
        () => {
            refreshCollectionStatus();
        },
        LONG_POLLING_TIMER,
        false
    );

    useEffect(() => {
        Object.entries(isokoCollectionsContext.collections).map(([projectName, projectConfig], index) => {
            projectConfig.map((collection) => {
                handleLaunchpadInclusion(projectName, collection);
            })
        })
    }, []);

    function checkMintWindowStatus(projectName, projectConfig) {
        let projectEntry = {
            projectName: projectConfig["projectName"],
            projectConfig: projectConfig
        };

        let mintStartTime = isokoCollectionsContext.getNetworkSpecificParameterFromProjectConfigDirectly(projectConfig, "mint_start_time");
        let mintEndTime = isokoCollectionsContext.getNetworkSpecificParameterFromProjectConfigDirectly(projectConfig, "mint_end_time");

        //TODO: stop duplication
        if (Date.now() < Date.parse(mintStartTime)) {
            setUpcomingProjects(upcomingProjects => [...upcomingProjects, projectConfig]);
        } else if (Date.now() > Date.parse(mintEndTime)) {
            setEndedProjects(endedProjects => [...endedProjects, projectEntry]);
        } else if (Date.now() > Date.parse(mintStartTime) && Date.now() < Date.parse(mintEndTime)) {
            setLiveProjects(liveprojects => [...liveprojects, projectEntry]);
        }
    }

    function refreshCollectionStatus() {
        setLiveProjects([]);
        setEndedProjects([]);
        setUpcomingProjects([]);
        Object.entries(isokoCollectionsContext.collections).map(([projectName, projectConfig], index) => {
            projectConfig.map((collection) => {
                // collection["has_launchpad"] && checkMintWindowStatus(projectConfig.path, collection);
                handleLaunchpadInclusion(projectName, collection)
            })
        });
    }

    function handleLaunchpadInclusion(projectName, collectionConfig) {
        if (collectionConfig["has_launchpad"]) {
            if (PLATFORM === ATRIUM) {
                checkMintWindowStatus(projectName, collectionConfig);
            } else if (PLATFORM === ISOKO) {
                checkMintWindowStatus(projectName, collectionConfig);
            }
        }
        else if (
            collectionConfig["policy-info"]["collection-name"] === ISOKO_TEST_COLLECTION_NAME_KEY ||
            collectionConfig["policy-info"]["collection-name"] === ISOKO_TEST_STACKED_COLLECTION_NAME_KEY ||
            collectionConfig["policy-info"]["collection-name"] === KADCARS_PROJECT_NAME_KEY ||
            collectionConfig["policy-info"]["collection-name"] === SGK_WEAPONS_NAME_KEY
        ) {
            if (PLATFORM === ATRIUM) {
                checkMintWindowStatus(projectName, collectionConfig);
            }
        }
    }

    return (
        <div className="launchPad">
            <Hero />
            {
                liveProjects?.length > 0 &&
                <>
                    <ScreenSize>
                        <Divider mt="44px" border="1px" borderColor="#5E1E78" />
                    </ScreenSize>

                    <LiveMint projects={liveProjects} />
                </>
            }
            {
                upcomingProjects?.length > 0 &&
                <>
                    <ScreenSize>
                        <Divider mt="44px" border="1px" borderColor="#5E1E78" />
                    </ScreenSize>

                    <Upcoming projects={upcomingProjects} />
                </>
            }
            {
                endedProjects?.length > 0 &&
                <>
                    <ScreenSize>
                        <Divider mt="44px" border="1px" borderColor="#5E1E78" />
                    </ScreenSize>

                    <Ended projects={endedProjects} />
                </>
            }
        </div>
    );
};

export default LaunchPad;

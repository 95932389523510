import { } from "react-icons/bs";

import {
    Box,
    Button,
    CloseButton,
    Flex,
    Icon,
    Image,
    Input,
    Modal,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
} from "@chakra-ui/react";
import {
    CHAINWEAVER_WALLET_NAME,
    ECKO_WALLET_NAME,
    NETWORK_ID,
    WALLET_CONNECT_V2,
    ZELCORE_WALLET_NAME,
} from "../utils/Constants";
import { getAccounts, openZelcore } from "../utils/ZelcoreUtils";
import { useContext, useState } from "react";

import Chainweaver from "../assets/images/chain.png";
import Echo from "../assets/images/echo.png";
import { FaTimes } from "react-icons/fa";
import Kola from "../assets/images/kola.png";
import { PactContext } from "../pact/PactContextProvider";
import Zelco from "../assets/images/zelco.png";
import { checkIfNullOrUndefined } from "../utils/utils";
import { getAccountTokenBalance } from "../pact/PactUtils";
import { throttle } from "throttle-debounce";
import { toast } from "react-toastify";

export default function ConnectWalletModal({ isOpen, onClose }) {
    const { setConnectedWallet } = useContext(PactContext);
    const [localAccount, setLocalAccount] = useState(null);
    const [showChainweaverModal, setShowChainweaverModal] = useState(false);
    const [showZelcoreModal, setShowZelcoreModal] = useState(false);

    async function handleWalletOptModalClose() {
        setShowChainweaverModal(false);
        setShowZelcoreModal(false);
        setLocalAccount(null);
        onClose();
    }

    async function handleWalletConnect(walletType) {
        let walletName = null;
        if (walletType === ECKO_WALLET_NAME) {
            if (window?.kadena?.isKadena === false) {
                toast.error("Please Install eckoWALLET Chrome Extension");
                return;
            }
        }

        if (walletType === WALLET_CONNECT_V2) {
            // connectWallet()
            //     .then(async (responseNullable) => {
            //         console.log(responseNullable)
            //         if (responseNullable && responseNullable.accounts.length > 0) {
            //             const wcAccounts = await requestGetAccounts(NETWORK_ID, responseNullable.accounts);
            //             console.log(wcAccounts)
            //             // call getAccounts
            //             const resultAccounts = [];
            //             wcAccounts.accounts.forEach((wcAcc) => wcAcc.kadenaAccounts.forEach((kAcc) => resultAccounts.push(kAcc.name)));
            //         }
            //     })
            // let { accounts } = await connectWallet();
            // walletName = accounts[0];
        }
        console.log(localAccount);
        walletName = !checkIfNullOrUndefined(localAccount)
            ? localAccount
            : null;
        await setConnectedWallet(
            walletType,
            handleWalletOptModalClose,
            walletName
        );
    }

    async function handleZelcoreClick() {
        setShowZelcoreModal(true);
        let accountWallet = null;

        await openZelcore();
        const { res, pRes } = await getAccounts();

        if (res.statusText === "Not Found") {
            toast.error("Error retrieving accounts from Zelcore server");
            return;
        }
        if (pRes.status === "success") {
            accountWallet = pRes.data[0];
            setLocalAccount(accountWallet);
        }
    }

    const onAccountNameChange = throttle(500, async (e) => {
        setLocalAccount(null);
        const list = ["0", "1", "8"];
        const accountName = e.target.value;
        if (accountName?.length === 0) {
            return;
        }

        let tempAccount = null;
        for (const chain of list) {
            tempAccount = await getAccountTokenBalance(
                "coin",
                NETWORK_ID,
                accountName,
                chain
            );
            if (!checkIfNullOrUndefined(tempAccount)) {
                break;
            }
        }

        if (!checkIfNullOrUndefined(tempAccount)) {
            setLocalAccount(tempAccount.account);
        }
    });

    const conectWalletData = [
        {
            id: 1,
            title: "EckoWALLET",
            icon: Echo,
            onClickFunc: () => {
                handleWalletConnect(ECKO_WALLET_NAME);
            },
        },
        {
            id: 2,
            title: "Zelcore",
            icon: Zelco,
            onClickFunc: () => {
                handleZelcoreClick();
            },
        },
        // {
        //     id: 3,
        //     title: "Koala Wallet",
        //     icon: Kola,
        //     onClickFunc: () => {
        //         handleWalletConnect(WALLET_CONNECT_V2);
        //     },
        // },
        {
            id: 4,
            title: "Chainweaver",
            icon: Chainweaver,
            onClickFunc: () => setShowChainweaverModal(true),
        },
    ];

    return (
        <Modal
            blockScrollOnMount={false}
            isOpen={isOpen}
            onClose={handleWalletOptModalClose}
            // size="sm"
            trapFocus={false}
        >
            <CloseButton onClick={() => handleWalletOptModalClose()} />
            <ModalOverlay />
            <ModalContent bgColor="transparent" className="latestDesign">
                {!showChainweaverModal && !showZelcoreModal ? (
                    <Box
                        bgColor="#160D1A"
                        borderRadius="2rem"
                        py="3rem"
                        px="2rem"
                        maxW="26.25rem"
                    >
                        <Flex gap="20px" justify="space-between">
                            <Text fontSize="2.25rem" fontWeight="900">
                                Connect wallet
                            </Text>

                            <Icon
                                as={FaTimes}
                                pos="relative"
                                boxSize={"1.5rem"}
                                onClick={() => handleWalletOptModalClose()}
                                cursor="pointer"
                            />
                        </Flex>

                        <Box mt="1rem">
                            <Text fontSize="0.875rem">
                                Choose which wallet you’d like to continue with
                            </Text>
                        </Box>
                        <Stack mt="1rem" spacing="0.25rem">
                            {conectWalletData.map(
                                ({ id, title, icon, onClickFunc }) => {
                                    return (
                                        <Flex
                                            key={id}
                                            gap="0.75rem"
                                            align="center"
                                            minH="2.75rem"
                                            as="button"
                                            id="eckoWallet"
                                            outline={"none"}
                                            rounded="0.5rem"
                                            w="100%"
                                            py="0.5rem"
                                            px="1rem"
                                            bgColor="#2A1E2F"
                                            className="walletBtn"
                                            onClick={onClickFunc}
                                            fontSize={"0.875rem"}
                                            fontWeight="700"
                                        >
                                            <Image
                                                src={icon}
                                                alt={title}
                                                w="1.75rem"
                                                h="1.75rem"
                                                objectFit={"contain"}
                                            />
                                            <span style={{ color: "white" }}>
                                                {title}
                                            </span>
                                        </Flex>
                                    );
                                }
                            )}
                            {/* <Button
                                outline={"none"}
                                border="2px"
                                borderColor="#BD3DF0"
                                w="100%"
                                py="20px"
                                bgColor="transparent"
                                _hover={{
                                    bgColor: "rgba(189, 61, 240, 0.33)",
                                }}
                                mb="28px"
                                onClick={() =>
                                    handleWalletConnect(WALLET_CONNECT_V2)
                                }
                            >
                                <span style={{ color: "white" }}>
                                    Wallet Connect V2
                                </span>
                            </Button> */}
                            {/* <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() =>
                                        handleWalletConnect(KOALA_WALLET_NAME)
                                    }
                                >
                                    Koala Wallet
                                </Button>

                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{ bgColor: "rgba(189, 61, 240, 0.33)" }}
                                    mb="28px"
                                    onClick={() => handleWalletConnect(WALLET_CONNECT_V2)}
                                >
                                    Wallet Connect V2
                                </Button> */}

                            {/* <Popover
                                    trigger="hover"
                                    arrowShadowColor="#BC3CF0"
                                    placement="right"
                                    border="none"
                                >
                                    <PopoverTrigger>
                                        <Button
                                            outline={"none"}
                                            border="2px"
                                            borderColor="#BD3DF0"
                                            w="100%"
                                            py="20px"
                                            bgColor="transparent"
                                            _hover={{
                                                bgColor: "rgba(189, 61, 240, 0.33)",
                                            }}
                                            mb="28px"
                                            onClick={() =>
                                                handleWalletConnect(KOALA_WALLET_NAME)
                                            }
                                        >
                                            Koala Wallet
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent bgColor="#BC3CF0" border="none">
                                        <PopoverArrow bgColor="#BC3CF0" color="#BC3CF0" />
                                        <Text py="4px" textAlign="center">
                                            Coming Soon
                                        </Text>
                                    </PopoverContent>
                                </Popover> */}

                            {/* <Button
                                outline={"none"}
                                border="2px"
                                borderColor="#BD3DF0"
                                w="100%"
                                py="20px"
                                bgColor="transparent"
                                _hover={{
                                    bgColor: "rgba(189, 61, 240, 0.33)",
                                }}
                                mb="28px"
                                onClick={() => handleZelcoreClick()}
                            >
                                <span style={{ color: "white" }}>Zelcore</span>
                            </Button> */}

                            {/* <Button
                                outline={"none"}
                                border="2px"
                                borderColor="#BD3DF0"
                                w="100%"
                                py="20px"
                                bgColor="transparent"
                                _hover={{
                                    bgColor: "rgba(189, 61, 240, 0.33)",
                                }}
                                mb="28px"
                                onClick={() => setShowChainweaverModal(true)}
                            >
                                <span style={{ color: "white" }}>
                                    Chainweaver
                                </span>
                            </Button> */}
                        </Stack>
                    </Box>
                ) : (
                    <Box
                        bgColor="#160D1A"
                        borderRadius="2rem"
                        py="3rem"
                        px="2rem"
                        maxW="26.25rem"
                    >
                        <Flex gap="20px" justify="space-between">
                            <Text fontSize="2.25rem" fontWeight="900">
                                {showChainweaverModal &&
                                    "Enter your K-Wallet Address"}
                                {showZelcoreModal &&
                                    "Sign into Zelcore and click below"}{" "}
                            </Text>
                            {/* <FaTimes
                                color="#fff"
                                fontSize="16px"
                                onClick={() => handleWalletOptModalClose()}
                                cursor="pointer"
                            /> */}

                            <Icon
                                as={FaTimes}
                                pos="relative"
                                boxSize={"1.5rem"}
                                onClick={() => handleWalletOptModalClose()}
                                cursor="pointer"
                            />
                        </Flex>
                        {showChainweaverModal && (
                            <Input
                                marginTop={"20px"}
                                placeholder="e.g. k:1234..."
                                onChange={(event) => onAccountNameChange(event)}
                            />
                        )}
                        <Flex marginTop={"2rem"} gap="1rem" align="center">
                            <Button
                                outline={"none"}
                                border="2px"
                                borderColor="#BD3DF0"
                                w="100%"
                                py="20px"
                                bgColor="transparent"
                                _hover={{
                                    bgColor: "rgba(189, 61, 240, 0.33)",
                                }}
                                // mb="28px"
                                onClick={() => {
                                    if (showChainweaverModal) {
                                        setShowChainweaverModal(false);
                                    } else if (showZelcoreModal) {
                                        setShowZelcoreModal(false);
                                    }
                                }}
                            >
                                <span style={{ color: "white" }}>Cancel</span>
                            </Button>

                            <Button
                                outline={"none"}
                                border="2px"
                                borderColor="#BD3DF0"
                                w="100%"
                                py="20px"
                                bgColor="transparent"
                                _hover={{
                                    bgColor: "rgba(189, 61, 240, 0.33)",
                                }}
                                onClick={() => {
                                    if (showChainweaverModal) {
                                        if (localAccount === "") {
                                            toast.error(
                                                "Account name cannot be empty"
                                            );
                                            return;
                                        }
                                        handleWalletConnect(
                                            CHAINWEAVER_WALLET_NAME
                                        );
                                    } else if (showZelcoreModal) {
                                        handleWalletConnect(
                                            ZELCORE_WALLET_NAME
                                        );
                                    }
                                }}
                            >
                                {showChainweaverModal && (
                                    <span style={{ color: "white" }}>
                                        Submit
                                    </span>
                                )}
                                {showZelcoreModal && (
                                    <span style={{ color: "white" }}>
                                        Connect Zelcore
                                    </span>
                                )}
                            </Button>

                            {/* I'm leaving this commented code out here just for refrence, It can be deleted if not needed */}
                            {/* <Button
                               
                                w="100%"
                                py="0.38rem"
                                px="1rem"
                                h="auto"
                                maxH="2rem"

                                fontSize="0.875rem"
                                bgColor="#BC3CF0"
                                // _hover={{
                                //     bgColor: "rgba(189, 61, 240, 0.33)",
                                // }}
                                rounded="1rem"
                                onClick={() => {
                                    if (showChainweaverModal) {
                                        if (localAccount === "") {
                                            toast.error(
                                                "Account name cannot be empty"
                                            );
                                            return;
                                        }
                                        handleWalletConnect(
                                            CHAINWEAVER_WALLET_NAME
                                        );
                                    } else if (showZelcoreModal) {
                                        handleWalletConnect(
                                            ZELCORE_WALLET_NAME
                                        );
                                    }
                                }}
                            >
                                {showChainweaverModal && (
                                    <span style={{ color: "white" }}>
                                        Submit
                                    </span>
                                )}
                                {showZelcoreModal && (
                                    <span style={{ color: "white" }}>
                                        Connect Zelcore
                                    </span>
                                )}
                            </Button> */}
                        </Flex>
                    </Box>
                )}
            </ModalContent>
        </Modal>
    );
}

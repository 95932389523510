import Pact from "pact-lang-api";
import { toast } from "react-toastify";
import {
    DEFAULT_CHAIN_ID,
    NETWORK_ID,
    TESTNET_NETWORK_ID,
    MAINNET_NETWORK_ID,
    DEFAULT_GAS_PRICE,
    DEFAULT_GAS_LIMIT,
    PLATFORM,
    ISOKO,
    CONNECTED_WALLET_TYPE,
    KOALA_WALLET_NAME,
    WALLET_CONNECT_V2,
} from "../utils/Constants";
import { checkIfNullOrUndefined, creationTime, getTimestamp, tryLoadLocal, wait } from "../utils/utils";
import { S_TO_MS_MULTIPLIER, POLL_INTERVAL_S } from "../utils/Constants";
import { useEffect, useState } from "react";

const defaultMeta = (chainId, gasPrice = null, gasLimit = null) => {
    return Pact.lang.mkMeta(
        "",
        chainId,
        gasPrice ?? DEFAULT_GAS_PRICE,
        gasLimit ?? DEFAULT_GAS_LIMIT,
        creationTime(),
        7200
    );
};

async function fetchPactLocal(pactCode, chainId, networkUrl, account = null, callback = null) {
    let parameters = {
        chainId: chainId,
        metaData: defaultMeta,
        networkUrl: networkUrl,
        readFromContract: readFromContract
    };

    if (account) {
        parameters["account"] = account;
    }

    const result = await executePactContract(parameters, pactCode);

    return result;
}

//Function to execute given pact command
async function executePactContract(pactContextObject, pactCmd, poll = false, gasLimit = DEFAULT_GAS_LIMIT, platform = null) {
    const pactCode = pactCmd;
    // const meta = pactContextObject.metaData(DEFAULT_GAS_LIMIT);
    const meta = Pact.lang.mkMeta(
        "",
        pactContextObject["chainId"],
        DEFAULT_GAS_PRICE,
        gasLimit,
        creationTime(),
        600
    );
    // const contractOutput = await pactContextObject.readFromContract({ pactCode, meta });
    let networkUrl = getNetworkUrlWithChainId(
        NETWORK_ID,
        pactContextObject["chainId"],
        platform
    );
    // console.log(pactCmd)
    // console.log(networkUrl)
    let contractOutput = await readFromContract(
        { pactCode, meta },
        networkUrl,
        "Failed to Execute Pact Contract",
        poll
    );

    if (checkIfNullOrUndefined(contractOutput)) {
        networkUrl = getNetworkUrlWithChainId(
            NETWORK_ID,
            pactContextObject["chainId"],
            ISOKO
        )

        contractOutput = await readFromContract(
            { pactCode, meta },
            networkUrl,
            "Failed to Execute Pact Contract",
            poll
        );
    }

    return contractOutput;
}

//Get the URL using the provided network ID
function getNetworkUrl(netId) {
    if (netId == null) {
        return;
    }
    if (netId === TESTNET_NETWORK_ID) {
        return `https://api.testnet.chainweb.com/chainweb/0.0/${TESTNET_NETWORK_ID}/chain/${DEFAULT_CHAIN_ID}/pact`;
    } else if (netId === MAINNET_NETWORK_ID) {
        return `https://api.chainweb.com/chainweb/0.0/${MAINNET_NETWORK_ID}/chain/${DEFAULT_CHAIN_ID}/pact`;
    }
    throw new Error(
        "networkId must be testnet, please select testnet in your eckoWALLET"
    );
}

function getNetworkUrlWithChainId(netId, chainId, node = null) {
    if (netId == null) {
        return;
    }
    if (netId === TESTNET_NETWORK_ID) {
        return `https://api.testnet.chainweb.com/chainweb/0.0/${TESTNET_NETWORK_ID}/chain/${chainId}/pact`;
    } else if (netId === MAINNET_NETWORK_ID) {
        // if (!checkIfNullOrUndefined(node)) {
        //     return `http://isoko.io:1848/chainweb/0.0/${MAINNET_NETWORK_ID}/chain/${chainId}/pact`;
        // }
        return `https://api.chainweb.com/chainweb/0.0/${MAINNET_NETWORK_ID}/chain/${chainId}/pact`;
    }
    throw new Error(
        "networkId must be testnet, please select testnet in your eckoWALLET"
    );
}

const getAccountTokenBalance = async (coinCode, networkId, account, chainId = null) => {
    if (account) {
        const res = await readFromContract(
            {
                pactCode: `(${coinCode}.details "${account}")`,
                meta: defineMetaData(
                    chainId ? chainId : DEFAULT_CHAIN_ID,
                    DEFAULT_GAS_PRICE,
                    150000
                ),
            },
            getNetworkUrlWithChainId(networkId, chainId ? chainId : DEFAULT_CHAIN_ID),
            "Error retrieving balance for this wallet"
        );
        return res;
    } else {
        toast.error(
            "Invalid account, cannot fetch balance for account: " + account
        );
        // return { result: { status: 'failure' } };
        return "failure";
    }
};

//TODO add toast
const pollRequestKey = async (requestKey, targetChainId) => {
    let reqKeyPreview = requestKey.slice(0, 10);
    let time_spent_polling_s = 0;
    let pollRes = null;

    while (time_spent_polling_s < 600) {
        await wait(POLL_INTERVAL_S * S_TO_MS_MULTIPLIER);
        try {
            pollRes = await Pact.fetch.poll(
                { requestKeys: [requestKey] },
                NETWORK_ID
            );
        } catch (e) {
            console.log(e);
            toast.error("Attempting transaction update again...");
            continue;
        }
        if (Object.keys(pollRes).length !== 0) {
            break;
        }
        time_spent_polling_s += POLL_INTERVAL_S;
        let waitingText = `Waiting ${time_spent_polling_s + POLL_INTERVAL_S
            }s for transaction ${reqKeyPreview}`;
        toast.update(`${waitingText}`);
    }

    return pollRes[requestKey];
};

const defineMetaData = (chainId, gasPrice, gasLimit) => {
    return Pact.lang.mkMeta(
        "",
        chainId ? chainId : DEFAULT_CHAIN_ID,
        gasPrice ? gasPrice : DEFAULT_GAS_PRICE,
        gasLimit ?? 150000,
        creationTime(),
        600
    );
};

const fetchAccountDetails = async (metaData) => {
    return await readFromContract(
        {
            pactCode: `(coin.details ${JSON.stringify(metaData.account)})`,
            meta: defineMetaData(
                metaData.chainId,
                metaData.gasPrice,
                metaData.gasLimit
            ),
        },
        getNetworkUrl(NETWORK_ID)
    );
};

const fetchAccountDetailsWithChainId = async (metaData) => {
    return await readFromContract(
        {
            pactCode: `(coin.details ${JSON.stringify(metaData.account)})`,
            meta: defineMetaData(
                metaData.chainId,
                metaData.gasPrice,
                metaData.gasLimit
            ),
        },
        getNetworkUrlWithChainId(NETWORK_ID, metaData.chainId, PLATFORM)
    );
};

const readFromContract = async (cmd, networkUrl, returnError, poll = false) => {
    try {
        let data = await Pact.fetch.local(cmd, networkUrl);
        if (data?.result?.status === "success") {
            if (poll) {
                return data;
            }
            return data.result.data;
        } else {
            if (returnError === true) {
                return data?.result?.error?.message;
            } else {
                return null;
            }
        }
    } catch (e) {
        toast.error("Had trouble fetching data from the blockchain", {
            toastId: "Fetch Local Error",
        });
        console.log(e);
    }
    return null;
};

const pollForTransaction = async (requestObj, displayResult = false) => {
    var requestKey = requestObj.requestKey;
    var networkUrl = getNetworkUrlWithChainId(
        NETWORK_ID,
        requestObj.targetChainId
    );
    var pollingDuration = 0;
    var pollRes = null;

    while (pollingDuration < 600) {
        // await wait(POLL_INTERVAL_S * S_TO_MS_MULTIPLIER);
        // await wait(1 * S_TO_MS_MULTIPLIER);
        await wait(15000);
        try {
            pollRes = await Pact.fetch.poll(
                { requestKeys: [requestKey] },
                networkUrl
            );
        } catch (e) {
            console.log(e);
            toast.error("Attempting transaction update again...");
            continue;
        }
        if (Object.keys(pollRes).length !== 0) {
            break;
        }
        pollingDuration += POLL_INTERVAL_S;
    }

    if (pollRes[requestKey].result.status === "success") {
        return pollRes[requestKey];
    } else {
        toast.error(`Transaction ${requestKey} failed.. :(`);
        return null;
    }
};

const pollForTransactionProgress = async (requestKey, chainId = null) => {
    let reqKeyPreview = requestKey.slice(0, 10);
    let time_spent_polling_s = 0;
    let pollRes = null;

    let waitingText = (
        <span
            onClick={() =>
                window.open(
                    `https://explorer.chainweb.com/mainnet/txdetail/${requestKey}`,
                    "_blank"
                )
            }
        >
            {`Waiting ${POLL_INTERVAL_S}s for transaction ${reqKeyPreview}`}
        </span>
    );
    toast.info(waitingText, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        draggable: true,
        toastId: requestKey,
    });

    let network = "";
    if (chainId) {
        network = getNetworkUrlWithChainId(NETWORK_ID, chainId);
    }
    while (time_spent_polling_s < 600) {
        await wait(POLL_INTERVAL_S * S_TO_MS_MULTIPLIER);
        try {
            pollRes = await Pact.fetch.poll(
                { requestKeys: [requestKey] },
                network
            );
        } catch (e) {
            console.log(e);
            toast.error("Attempting transaction update again...");
            continue;
        }
        if (Object.keys(pollRes).length !== 0) {
            break;
        }
        time_spent_polling_s += POLL_INTERVAL_S;
        waitingText = `Waiting ${time_spent_polling_s + POLL_INTERVAL_S
            }s for transaction ${reqKeyPreview}...`;
        toast.update(requestKey, { render: waitingText });
    }
    console.log(pollRes);
    if (pollRes[requestKey].result.status === "success") {
        toast.update(requestKey, {
            render: `Transaction ${reqKeyPreview}...  completed!`,
            type: "success",
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
        });
        return pollRes[requestKey].result.status;
    } else {
        console.log(pollRes);
        toast.error(
            `Transaction ${requestKey}... failed, please try again`,
            {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
            }
        );
    }
    return null;
};

function calculateTotalFundsOnAllChains(chainMapData) {
    let totalChainSum = 0.0;
    // console.log(chainMapData)
    chainMapData.forEach((chainAccountData) => {
        if (chainAccountData.accountData.balance.decimal) {
            totalChainSum += parseFloat(
                chainAccountData.accountData.balance.decimal
            );
        } else {
            totalChainSum += chainAccountData.accountData.balance;
        }
    });

    return totalChainSum?.toFixed(2);
}

function createUniqueTransferCapabilities(capDataList) {
    let capDict = {};
    let caps = [];
    console.log(capDataList);
    capDataList.forEach((capDataObj) => {
        let key = `${capDataObj.sender} ${capDataObj.receiver}`;
        if (!(key in capDict)) {
            capDict[`${capDataObj.sender} ${capDataObj.receiver}`] = {
                role: "",
                description: "",
                amount: 0.0,
                sender: "",
                receiver: "",
            };
        }
        capDict[`${capDataObj.sender} ${capDataObj.receiver}`]["role"].concat(
            capDataObj.name
        );
        capDict[`${capDataObj.sender} ${capDataObj.receiver}`][
            "description"
        ].concat(capDataObj.description);

        if (!checkIfNullOrUndefined(capDict[`${capDataObj.sender} ${capDataObj.receiver}`]["amount"]["decimal"])) {
            if (!checkIfNullOrUndefined(capDataObj["amount"]["decimal"])) {
                capDict[`${capDataObj.sender} ${capDataObj.receiver}`]["amount"]["decimal"] += parseFloat(capDataObj["amount"]["decimal"]);
            } else {
                capDict[`${capDataObj.sender} ${capDataObj.receiver}`]["amount"]["decimal"] += parseFloat(capDataObj["amount"]);
            }
        } else {
            if (!checkIfNullOrUndefined(capDataObj["amount"]["decimal"])) {
                capDict[`${capDataObj.sender} ${capDataObj.receiver}`]["amount"] += capDataObj["amount"]["decimal"];
            } else {
                capDict[`${capDataObj.sender} ${capDataObj.receiver}`]["amount"] += capDataObj["amount"];
            }
        }

        capDict[`${capDataObj.sender} ${capDataObj.receiver}`]["sender"] = capDataObj.sender;
        capDict[`${capDataObj.sender} ${capDataObj.receiver}`]["receiver"] = capDataObj.receiver;
    });

    console.log(capDict);

    caps = Object.entries(capDict).map(([key, value]) => {
        let cap = Pact.lang.mkCap(
            value["role"],
            value["description"],
            "coin.TRANSFER",
            [
                value["sender"],
                value["receiver"],
                parseFloat(value["amount"]),
            ]
        );
        return cap;
    });
    console.log(caps);
    return caps;
}

function buildContCmd(pactId, sender, chainId, step, rollback, proof = "", envData = null, clist = [], gasPrice = null, gasLimit = null) {
    const m = Pact.lang.mkMeta(
        sender,
        chainId,
        gasPrice ? gasPrice : DEFAULT_GAS_PRICE,
        gasLimit ? gasLimit : DEFAULT_GAS_LIMIT,
        parseFloat(getTimestamp().toFixed(2)),
        600
    );

    let walletType = tryLoadLocal(CONNECTED_WALLET_TYPE);
    proof = (walletType === KOALA_WALLET_NAME || walletType === WALLET_CONNECT_V2) ? "null" : null;

    const cmd = {
        type: "cont",
        keyPairs: clist,
        pactId,
        rollback: rollback,
        step: step,
        meta: m,
        envData,
        proof,
        networkId: NETWORK_ID,
    };

    const contCmd = Pact.simple.cont.createCommand(
        cmd.keyPairs,
        undefined,
        cmd.step,
        cmd.pactId,
        cmd.rollback,
        cmd.envData,
        cmd.meta,
        cmd.proof,
        cmd.networkId
    );

    return contCmd;
}

function buildExecCommand(pactCode, chainId, sender, publicKey, envData, caps = [], gasPrice = null, gasLimit = null) {
    let signers = [
        {
            publicKey: publicKey,
            clist: caps.map((cap) => {
                return cap.cap;
            }),
        },
    ];
    let meta = Pact.lang.mkMeta(
        sender,
        chainId,
        gasPrice ? gasPrice : DEFAULT_GAS_PRICE,
        gasLimit ? gasLimit : DEFAULT_GAS_LIMIT,
        parseFloat((Date.now() / 1000).toFixed(2)),
        1200
    );
    let execCommand = JSON.parse(
        Pact.simple.exec.createCommand(
            signers,
            (Date.now() / 1000).toFixed(2),
            pactCode,
            envData,
            meta,
            NETWORK_ID
        ).cmds[0]["cmd"]
    );

    return execCommand;
}

function getPactInt(value) {
    let pactInt = null;

    pactInt = {
        int: value
    }

    return pactInt;
}

function getPactDecimal(value) {
    let pactDecimal = null;
    value = parseFloat(value.toFixed(8));
    pactDecimal = {
        decimal: value
    }

    return pactDecimal;
}

async function getBlockHeight(chainId = null) {
    let timeData = null;

    try {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        // await fetch(`${HEROKU_PROXY}https://${ISOKO_CHAINWEB_IP}:${ISOKO_CHAINWEB_PORT}/chainweb/0.0/${NETWORK_ID}/cut`)
        await fetch(`https://estats.chainweb.com/chainweb/0.0/mainnet01/cut`)
            .then((result) => result.json())
            .then((result) => {
                console.log(result);
                timeData = result["hashes"][0]["height"];
                if (chainId) {
                    timeData = result["hashes"][chainId]["height"];
                }
            });
    } catch (e) {
        console.log(e);
    }

    return timeData;
}

async function getBlockHeightForChain(chainId) {
    let timeData = null;

    try {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        // await fetch(`${HEROKU_PROXY}https://${ISOKO_CHAINWEB_IP}:${ISOKO_CHAINWEB_PORT}/chainweb/0.0/${NETWORK_ID}/cut`)
        await fetch(`https://estats.chainweb.com/chainweb/0.0/mainnet01/cut`)
            .then((result) => result.json())
            .then((result) => {
                timeData = result["hashes"][0]["height"];
                if (chainId) {
                    timeData = result["hashes"][chainId]["height"];
                }
            });
    } catch (e) {
        console.log(e);
    }

    return timeData;
}

function useGetBlockHeightForChain(chainId) {
    const [height, setHeight] = useState(null);

    useEffect(() => {
        async function getHeight() {
            let currHeight = await getBlockHeightForChain(chainId);
            setHeight(currHeight);
        }
        getHeight();
    }, [chainId]);

    return height;
}

export {
    buildContCmd,
    buildExecCommand,
    pollRequestKey,
    getNetworkUrl,
    defineMetaData,
    readFromContract,
    pollForTransaction,
    pollForTransactionProgress,
    fetchAccountDetails,
    executePactContract,
    getAccountTokenBalance,
    fetchAccountDetailsWithChainId,
    calculateTotalFundsOnAllChains,
    createUniqueTransferCapabilities,
    getNetworkUrlWithChainId,
    getPactDecimal,
    getPactInt,
    getBlockHeight,
    getBlockHeightForChain,
    useGetBlockHeightForChain,
    fetchPactLocal
};

import { useContext } from "react";
import {
    BASE_URL_KEY,
    CHAIN_ID_KEY,
    COLLECTION_HAS_MARKETPLACE,
    COLLECTION_NAME_KEY,
    COLLECTION_TRAITS_KEY,
    COLLECTION_TRAIT_DISTRIBUTION_KEY,
    DEFAULT_GAS_LIMIT,
    DEFAULT_GAS_PRICE,
    FEE_PERCENTAGE,
    ISLANDS_OF_DAMAYA,
    ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY,
    ISOKO_CONTRACTS,
    ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
    ISOKO_NAMESPACE,
    LOCAL_ACCOUNT_KEY,
    MAINNET_NETWORK_ID,
    MARMALADE_TYPE,
    MINT_AMOUNT_LIMIT_KEY,
    MINT_PHASES_KEY,
    NETWORK_ID,
    NEW_ADMIN_ADDRESS,
    NFT_SUPPLY_KEY,
    TESTNET_NETWORK_ID
} from "../utils/Constants";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";
import Pact from "pact-lang-api";
import { PactContext } from "../pact/PactContextProvider";
import { useQueryBlockchainForData } from "../pact/ContractHooks";
import { checkIfFundsOnChainAreSufficient, tryLoadLocal } from "../utils/utils";
import { createUniqueTransferCapabilities } from "../pact/PactUtils";
import pitBullBushidoMetaData from "../project_config_files/pit_bull_bushido_metadata";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";

const ISLANDS_OF_DAMAYA_CONTRACTS = "free.pit-bull-bushido-policy";
const ISLANDS_OF_DAMAYA_TESTNET_CONTRACTS = "free.pit-bull-bushido-policy-v1";
const ISLANDS_OF_DAMAYA_CREATOR_WALLET = "k:37a2a7aa843e1d012caa9854df8040654705dffd092f5211150ae32a93127ece";
const ISLANDS_OF_DAMAYA_TESTNET_CREATOR_WALLET = "k:04e21d9dbe39517574a3772de910d606baa00a84359ba6c468206c76695650d4";
const ISLANDS_OF_DAMAYA_ADMIN_ADDRESS = "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46";
const ISLANDS_OF_DAMAYA_BASE_URL = "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com";
const ISLANDS_OF_DAMAYA_COLLECTION_NAME = "pit-bull-bushido";
const ISLANDS_OF_DAMAYA_COLLECTION_NAME_TESTNET = "pit-bull-bushido-v1";
const ISLANDS_OF_DAMAYA_FEE_PERCENTAGE = 0.05;

function useIslandsOfDamayaProvider() {
    const quickBuy = useQuickBuy();
    const pactContext = useContext(PactContext);
    const queryBlockChainForData = useQueryBlockchainForData();
    const { getNetworkSpecificParameterFromProjectConfig, getParameterFromProjectConfig } = useContext(IsokoCollectionsContext);


    /**
     * 
     ******************************************
     ********* Marketplace Functions **********
     ******************************************
     *
     */
    async function mint(amount, priceToPay, transactionCompleteCallback = null) {
        let priceToPayContract = 0.95 * priceToPay;
        let chainId = getChain();
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        let priceToPayLPFee = getTransactionFee(priceToPay);
        let totalPrice = priceToPay;

        let pactCode = `(${ISOKO_CONTRACTS}.mint-bulk ${amount} "${ISLANDS_OF_DAMAYA_COLLECTION_NAME}" "${pactContext.account.account}" (read-keyset "user-ks"))`
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISOKO_CONTRACTS}.mint-bulk ${amount} "${ISLANDS_OF_DAMAYA_COLLECTION_NAME_TESTNET}" "${pactContext.account.account}" (read-keyset "user-ks"))`
        }
        let pactCodeToTransferFunds = `(coin.transfer "${pactContext.account.account}" "${ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS}" ${parseFloat(priceToPayLPFee)})`;
        pactCode = pactCode + pactCodeToTransferFunds;

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };

        const capDataList = [
            {
                role: "Launchpad fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
                amount: parseFloat(priceToPayLPFee) //TODO CRITICAL: THIS WILL FAIL ON INTEGER: CRITICAL
            },
            {
                role: "Project mint fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: NETWORK_ID === MAINNET_NETWORK_ID ?
                    ISLANDS_OF_DAMAYA_CREATOR_WALLET :
                    ISLANDS_OF_DAMAYA_TESTNET_CREATOR_WALLET,
                amount: priceToPayContract.toFixed(8)
            },
        ]
        let caps = createUniqueTransferCapabilities(capDataList);
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "Account Guard",
                "Account Guard Capability",
                `${NETWORK_ID === MAINNET_NETWORK_ID ?
                    ISLANDS_OF_DAMAYA_CONTRACTS :
                    ISLANDS_OF_DAMAYA_TESTNET_CONTRACTS}.ACCOUNT-GUARD`,
                [accountDetails.account]
            )
        );

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = (
            <p>Training {amount} Badger to box for {parseFloat((totalPrice).toFixed(4))} KDA</p>
        );

        //For quickbuy
        let signers = [{
            publicKey: accountDetails.accountGuard.keys[0],
            clist: caps.map((cap) => {
                return cap.cap
            })
        }];
        let meta = Pact.lang.mkMeta(
            pactContext.account.account,
            chainId,
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            parseFloat((Date.now() / 1000).toFixed(2)),
            1200,
        );
        let bzCommand = JSON.parse(Pact.simple.exec.createCommand(signers, (Date.now() / 1000).toString(), pactCode, envData, meta, NETWORK_ID).cmds[0]["cmd"]);
        let cmdPubKeyObj = {
            cmd: bzCommand,
            pubKey: accountDetails.accountGuard.keys[0]
        }

        let txData = {
            cmdType: "exec",
            cmd: cmd,
            bzCommand: cmdPubKeyObj,
            previewContent: previewContent,
            transactionMessage: `Honoring ${amount} Pit Bull Bushido ${amount > 1 ? "s" : ""} for ${parseFloat(totalPrice.toFixed(4))} KDA`,
            callback: transactionCompleteCallback,
            errorHandler: transactionCompleteCallback,
            errorMessage: "",
            shouldSign: true
        };

        quickBuy.shouldExecuteQuickBuy(txData, totalPrice, chainId);
    }

    async function mintBulk(amount) {
        let chainId = getChain();
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);

        let pactCode = `(${ISOKO_CONTRACTS}.mint-bulk-reserved 10 "${ISLANDS_OF_DAMAYA_COLLECTION_NAME}" "${pactContext.account.account}" (read-keyset "user-ks"))`
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISOKO_CONTRACTS}.mint-bulk-reserved 10 "${ISLANDS_OF_DAMAYA_COLLECTION_NAME_TESTNET}" "${pactContext.account.account}" (read-keyset "user-ks"))`
        }

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };


        let caps = [];
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(Pact.lang.mkCap("reserved", "reserved",
            "n_f1c962776331c4773136dc1587a8355c9957eae1.isoko-orchestrator.ADMIN-OR-COL-OWNER", [ISLANDS_OF_DAMAYA_COLLECTION_NAME]))

        const previewContent = (
            <p>Training {amount} Badger to box for  KDA</p>
        );

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        pactContext.sendTransaction(
            cmd,
            previewContent,
            "",
            null,
            null,
            null,
            true
        );
    }

    /**
     * 
     **************************************************
     ********* Collection Provider Functions **********
     **************************************************
     *
     */

    function getBestOffer() {

    }

    async function getMintedTotal() {
        let pactCode = `(${ISLANDS_OF_DAMAYA_CONTRACTS}.get-minted-supply)`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISLANDS_OF_DAMAYA_TESTNET_CONTRACTS}.get-minted-supply)`;
        }

        let chainId = getChain();

        let mintedTotal = await queryBlockChainForData(pactCode, chainId);

        return mintedTotal;
    }

    function getNonMintedTokens() {

    }

    function getMintPriceForNextPhase() {

    }

    async function getNextPrice(amount, accountWlData) {
        let mintPhases = await getNetworkSpecificParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, MINT_PHASES_KEY);
        let price = mintPhases["public_sale"]["base_price"];

        return price;
    }

    async function buyNftCallback(data) {

    }

    function getTransactionFee(price) {
        let txFee = (price * ISLANDS_OF_DAMAYA_FEE_PERCENTAGE).toFixed(10);
        return parseFloat(txFee);
    }

    function getRoyaltyFeeFromPrice(price) {

    }

    function getCollectionName() {
        let collectionName = getParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, COLLECTION_NAME_KEY);

        return collectionName;
    }

    function getBaseUrl() {
        let baseUrl = getParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, BASE_URL_KEY);

        return baseUrl;
    }

    function getChain() {
        let chainId = getNetworkSpecificParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, CHAIN_ID_KEY);

        return chainId;
    }

    function getTotalSupply() {
        let totalSupply = getNetworkSpecificParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, NFT_SUPPLY_KEY);

        return totalSupply;
    }

    async function getTotalVolumeForCollection() {
        let pactCode = `(${ISLANDS_OF_DAMAYA_CONTRACTS}.get-count "total-volume-count-key")`;
        let chainId = getNetworkSpecificParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, CHAIN_ID_KEY);
        let blockchainResponse = queryBlockChainForData(pactCode, chainId);

        if (blockchainResponse) {
            return blockchainResponse;
        }

        return null;
    }

    function getNftImage(nftId) {
        let image = pitBullBushidoMetaData[nftId]["uri"]["data"];

        return image;
    }

    function getCollectionTraits() {
        let traitsList = getNetworkSpecificParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, COLLECTION_TRAITS_KEY);

        return traitsList;
    }

    function getMintPhases() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, MINT_PHASES_KEY);

        return mintPhases;
    }

    function getNftAmountLimit() {
        let amountLimit = getNetworkSpecificParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, MINT_AMOUNT_LIMIT_KEY);

        return amountLimit;
    }

    async function getNftsForUser(account) {
        let chainId = getChain();

        let pactCode = `(${ISLANDS_OF_DAMAYA_CONTRACTS}.get-nfts-by-owner "${account.account}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISLANDS_OF_DAMAYA_TESTNET_CONTRACTS}.get-nfts-by-owner "${account.account}")`;
        }

        let userNfts = await queryBlockChainForData(pactCode, chainId);
        let formattedUserNftIds = userNfts?.map((nft) => {
            let obj = {
                nftId: nft["token-id"].split("#")[1]
            }
            return obj
        })

        return formattedUserNftIds;
    }

    async function getAccountWhitelistData(account) {
        let chainId = getChain();
        let pactCode = `(${ISLANDS_OF_DAMAYA_CONTRACTS}.get-account-whitelist-info "${account}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISLANDS_OF_DAMAYA_TESTNET_CONTRACTS}.get-account-whitelist-info "${account}")`;
        }

        let response = await queryBlockChainForData(pactCode, chainId);

        return response;
    }

    async function getPhaseParticipationRequirement(account, phase) {
        let requirementDescription = "";
        let whitelistInfo = null;

        if (phase === "public_sale") {
            requirementDescription = "No Special Requirements";
            return requirementDescription
        } else if (phase === "private_wl") {
            requirementDescription = "Whitelist Required";
        }

        if (account) {
            whitelistInfo = await getAccountWhitelistData(account);
        } else {
            return requirementDescription
        }

        if (phase === "private_wl") {
            if (whitelistInfo["role"] === "private-wl-role") {
                requirementDescription = "Account is Whitelisted!";
            } else {
                requirementDescription = "Account is Not Whitelisted";
            }
        }

        return requirementDescription;
    }
    function getNftData(nftId) {
        let fullMetadataCopy = { ...pitBullBushidoMetaData };
        let nftData = fullMetadataCopy[nftId];
        console.log(nftData)
        return nftData;
    }

    function getCollectionTraits() {
        let traitsList = getNetworkSpecificParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, COLLECTION_TRAITS_KEY);

        return traitsList;
    }

    function formatNftTraits(nftTraits) {
        let formattedTraits = {}
        let traitsList = getCollectionTraits();

        nftTraits.forEach((traitObject) => {
            formattedTraits[traitObject["trait_type"]] = traitObject["value"];
        });

        return formattedTraits;
    }

    function formatNftTraitsInNftDataObject(nftData) {
        let formattedTraits = {};
        let formattedSkills = {};
        let traitsList = getCollectionTraits();
        let nftTraits = nftData["datum"]["datum"]["attributes"];
        let nftScore = nftData["datum"]["datum"]["rarity_score"];
        console.log(nftData)

        nftTraits.forEach((traitObject) => {
            let traitFrequency = getTraitDistribution(traitObject["trait_type"], traitObject["value"]);

            formattedTraits[traitObject["trait_type"]] = {
                name: traitObject["value"],
                frequency: traitFrequency
            };
        });

        console.log(formattedTraits)

        let formattedNftData = {
            id: nftData["datum"]["datum"]["id"],
            name: nftData["datum"]["datum"]["name"],
            attributes: formattedTraits,
            // image: nftData["datum"]["datum"]["thumbnailUrl"],
            image: nftData["uri"]["data"],
            rarity_rank: "COMMON",
            rarity_score: (nftScore).toFixed(3),
            rarity_level: nftData["datum"]["datum"]["rarity_level"],
            collection_traits: getCollectionTraits(),
            extras: {
                description: nftData["datum"]["datum"]["description"],
            }
        }

        return formattedNftData;
    }

    function formatNftDataForNftPopup(nftId) {
        let data = getNftData(nftId);
        let formattedData = formatNftTraitsInNftDataObject(data);

        return formattedData;
    }

    function getTraitDistribution(trait, traitValue) {
        console.log(trait)
        console.log(traitValue)
        let totalSupply = getTotalSupply();
        let traitCountMap = getParameterFromProjectConfig(ISLANDS_OF_DAMAYA, ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY, COLLECTION_TRAIT_DISTRIBUTION_KEY);
        // let traitFrequency = (traitCountMap[trait][traitValue] / traitCountMap[trait]["total"] * 100.0).toFixed(2);
        let traitFrequency = (traitCountMap[trait][traitValue] / totalSupply * 100.0).toFixed(2);

        console.log(traitCountMap[trait][traitValue])
        console.log(traitCountMap[trait]["total"])
        console.log(`${trait} : ${traitValue} = ${traitFrequency}`)

        return traitFrequency;
    }

    function getAllNftsMetadata() {
        let fullCollectionMetadata = pitBullBushidoMetaData;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            fullCollectionMetadata = pitBullBushidoMetaData;
        }

        return fullCollectionMetadata;
    }

    function getCollectionStandard() {
        return MARMALADE_TYPE;
    }

    /**
     * 
     **************************
     ***** Error Handlers ***** 
     **************************
     *
     */
    function transferNftErrorHandler() {

    }

    async function buyNftErrorHandler() {

    }

    function mintNftErrorHandler() {

    }

    function getPolicyInfo() {
        let policy = {
            "standard": MARMALADE_TYPE,
            "project-name": ISLANDS_OF_DAMAYA,
            "collection-name": ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY,
            "policy-name": ISLANDS_OF_DAMAYA_CONTRACTS,
            "creator-wallet": ISLANDS_OF_DAMAYA_CREATOR_WALLET,
            "chain-id": getChain(),
            "nft-metadata": pitBullBushidoMetaData,
            "base_image_url": ISLANDS_OF_DAMAYA_BASE_URL
        };

        return policy;
    }

    return {
        mint,
        getNextPrice,
        getChain,
        getTotalSupply,
        getMintPriceForNextPhase,
        getCollectionStandard,
        getMintedTotal,
        getBestOffer,
        getTotalVolumeForCollection,
        getBaseUrl,
        getCollectionName,
        getAllNftsMetadata,
        getNftImage,
        getCollectionTraits,
        formatNftTraits,
        getRoyaltyFeeFromPrice,
        getTransactionFee,
        getCollectionName,
        getMintPhases,
        getNftAmountLimit,
        getPhaseParticipationRequirement,
        getNftsForUser,
        getAccountWhitelistData,
        formatNftDataForNftPopup,
        mintBulk,
        getAllNftsMetadata,
        getPolicyInfo
    };
}

export {
    useIslandsOfDamayaProvider
}